.schematic-canvas-component-content {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #fafafa;
  border-radius: 4px;
  width: 140px;
  height: fit-content;
  box-shadow: #d8d8d8 0px 0px 10px 4px;
}

.schematic-canvas-component-icon {
  width: 30px;
  height: 30px;
  /*  background-color: #e7e7e7; */
  position: absolute;
  right: -2px;
  top: 25px;
  /* calc(50% - 15px); */
  text-align: center;
  line-height: 30px;
  z-index: 2;
}

.schematic-canvas-component-content .schematic-canvas-component-right-icon {
  top: calc(50% - 15px);
  right: 0px;
}

.schematic-canvas-component-icon .anticon {
  font-size: 16px;
  color: #1890ff;
  cursor: pointer;
}

.sierra-pre-layout-comp-info-panel {
  position: absolute;
  left: 0px;
  top: 0px;
}

/* .sierra-pre-layout-comp-info-panel.sierra-pre-layout-comp-info-ele-panel {
  left: 0px;
}
 */
.sierra-pre-layout-comp-info-content,
.sierra-pre-layout-template-content {
  padding: 6px 10px;
  overflow: auto;
}

.sierra-pre-layout-comp-info-content .sierra-pre-layout-comp-info-item {
  display: flex;
  width: 100%;
  line-height: 34px;
  height: 34px;
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item {
  display: flex;
  margin: 8px;
  line-height: 28px;
  height: 28px;
}

.sierra-pre-layout-comp-info-content .sierra-pre-layout-comp-info-item>span {
  width: 80px;
}

.sierra-pre-layout-comp-info-content .sierra-pre-layout-comp-info-item>.aurora-input,
.sierra-pre-layout-comp-info-content .sierra-pre-layout-comp-info-item>.aurora-select {
  width: calc(100% - 80px);
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item .pre-layout-template-label {
  width: 90px;
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item>.aurora-input,
.sierra-pre-layout-template-content .sierra-pre-layout-template-item>.aurora-select {
  width: calc(100% - 90px);
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item .aurora-input .ant-input-group-addon .ant-select {
  width: 60px;
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item .aurora-input .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 16px;
}

.schematic-canvas-container-content {
  width: 100%;
  padding: 8px 10px;
  height: 72px;
  padding-bottom: 2px;
  position: absolute;
  left: 0px;
  top: 0px;
  padding-right: 26px;
}

.schematic-canvas-container-item {
  display: flex;
  justify-content: space-between;
  height: 28px;
  line-height: 28px;
  margin-bottom: 6px;
  width: 100%;
}

.schematic-canvas-container-content>.schematic-canvas-container-item>.aurora-input {
  width: calc(100% - 20px);
}

.schematic-canvas-container-item>span {
  width: 20px;
}

.sierra-pre-layout-template-panel.aurora-library-panel .library-png img {
  width: 80%;
}

.sierra-template-tooltip {
  width: 350px;
  max-width: 350px;
}

.sierra-pre-layout-signals-main {
  min-height: 28px;
  line-height: 28px;
  /*   background-color: #fafafa;
  border-radius: 4px; */
  width: 140px;
  height: fit-content;
  /*   box-shadow: #d8d8d8 0px 0px 10px 4px; */
  position: absolute;
  left: 0px;
  top: 257px;
  /* min-height: 60px; */
  padding: 0px 10px;
  padding-bottom: 10px;
}

.sierra-pre-layout-signals-title {
  height: 28px;
  line-height: 28px;
  font-weight: 600;
  position: relative;
}

.sierra-report-pre-layout-schematic-setup-canvas .sierra-pre-layout-signals-title {
  width: 120px;
}

.sierra-report-pre-layout-schematic-setup-canvas .schematic-canvas-container-item {
  width: 104px;
}

.sierra-pre-layout-signals-del-icon {
  color: #1890ff;
  position: absolute;
  right: 0px;
  top: 7px;
  cursor: pointer;
}

.sierra-report-pre-layout-schematic-setup-canvas .sierra-pre-layout-signals-content {
  width: 120px;
}

.sierra-pre-layout-signals-content-names {
  min-height: 28px;
  line-height: 28px;
  padding-left: 4px;
  position: relative;
}

.sierra-pre-layout-signals-content-names>.sierra-pre-layout-signals-section-name {
  display: block;
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1890ff;
}

.sierra-pre-layout-signals-content-names>.sierra-pre-layout-signals-del-icon {
  color: rgb(0, 0, 0, 0.55);
  cursor: pointer;
  right: 0px;
  font-size: 12px;
}

.sierra-pre-layout-signals-content-names:hover .sierra-pre-layout-signals-del-icon {
  color: #1890ff;
}

.sierra-pre-layout-template-content .model-name-error-msg {
  height: 30px;
  padding: 4px;
  padding-left: 10px;
}