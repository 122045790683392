.sierra-sweeping-add-icon,
.sierra-sweeping-delete-icon {
  vertical-align: middle;
}

.sierra-sweeping-table .ant-table-thead>tr>th,
.sierra-sweeping-table .aurora-table-body>tr>td {
  text-align: center;
}

.sierra-sweeping-select-dropdown-menu {
  text-align: left;
}

/* columns */

.sweeping-columns-checkbox {
  padding-left: 6px !important;
}

.sierra-sweeping-pin-panel {
  margin-top: 126px;
  margin-left: -440px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.sierra-sweeping-pin-panel .sierra-sweeping-pin-table {
  margin: 10px;
}

.sierra-sweeping-table-title {
  position: relative;
}

.sierra-sweeping-table-title .sierra-sweeping-title-value {
  cursor: pointer;
}

.sierra-sweeping-table-title .sierra-sweeping-title-value i {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
  color: #1890ff;
  font-size: 12px;
}

.sierra-sweep-close-icon {
  visibility: hidden;
  position: absolute;
  margin-top: 5px !important;
  font-size: 14px !important;
  margin-left: 5px !important;
}

.sierra-sweeping-table .ant-table-thead>tr>th:hover .sierra-sweeping-table-title .sierra-sweep-close-icon {
  visibility: visible;
}


.sierra-sweeping-display-comp {
  position: absolute;
  width: 140px;
  background-color: #fff;
  margin-left: 20px;
  border-radius: 0 0 2px 2px;
  z-index: 999;
  overflow: auto;
  box-shadow: 1px 4px 6px rgb(0 0 0 / 31%);
}

.sierra-sweeping-display-comp ul {
  margin: 0;
  padding: 0;
}

.sierra-sweeping-display-comp ul li {
  width: 100%;
  text-align: left;
  padding: 4px 23px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sierra-sweeping-column-name {
  line-height: 24px;
  vertical-align: middle;
  position: relative;
}

.sierra-sweeping-column-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 16px;
}

.sierra-sweeping-column-name .anticon {
  color: #1890ff;
  position: absolute;
  visibility: hidden;
  top: 0px;
  right: 5px;
}

.sierra-sweeping-table .aurora-table-body tr:hover .sierra-sweeping-column-name .anticon {
  visibility: visible;
}

.sweeping-model-not-exsit td,
.sweeping-table-td-not-exsit {
  background-color: #fafafa;
  cursor: not-allowed;
}

.sweeping-table-td-not-used {
  background-color: #fafafa;
}

.sweeping-table-td-not-used .editable-cell-value-wrap {
  border: 1px solid #fafafa;
}

.sweeping-table-td-font-color {
  color: #1890ff;
}

.sweeping-model-exsit {
  background-color: #ffffff !important
}

.sierra-sweeping-table .ant-table-thead>tr>th {
  position: relative;
}

.sierra-sweep-table-add-icon {
  position: absolute;
  line-height: unset;
  margin-top: -4px;
  right: 10px;
  font-size: 16px !important;
  z-index: 20;
}

.sierra-sweeping-table .ant-table .aurora-table-body .ant-table-tbody tr .sweeping-table-td-padding {
  padding-left: 14px;
}

.sierra-sweeping-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr>td:last-child {
  text-align: center;
}

.sweep-pre-layout-schematic-setup-main {
  width: fit-content;
  /*   padding: 10px 14px; */
  display: flex;
  justify-content: space-between;
}

.sweep-pre-layout-schematic-setup-left {
  min-width: 320px;
  width: fit-content;
  min-height: 100%;
  padding: 10px;
  padding-left: 14px;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: unset;
}

.sweep-pre-layout-schematic-setup-left .sweep-pre-layout-length-left-title {
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-weight: 600;
  color: #0b3b94;
  display: flex;
}

.sweep-pre-layout-length-left-add-icon {
  color: #1890ff;
  cursor: pointer;
  margin-left: 10px;
  font-size: 15px;
}

.sweep-pre-layout-schematic-setup-left .sweep-pre-layout-schematic-length-list {
  width: fit-content;
  min-width: 100%;
  padding-left: 8px;
}

.sweep-pre-layout-schematic-select-section {
  min-height: 33px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  color: #1890ff;
}

.sweep-pre-layout-schematic-select-section>span {
  width: 100px;
}

.sweep-pre-layout-schematic-select-section>.aurora-select,
.sweep-pre-layout-schematic-select-section>.aurora-input {
  width: calc(100% - 100px);
}

.sweep-pre-layout-schematic-select-section>.aurora-select {
  height: fit-content;
  min-height: 28px;
}

/* .sweep-pre-layout-schematic-setup-canvas .sierra-pre-layout-canvas .pre-layout-canvas {
  transform: scale(0.9);
} */

.sweep-pre-layout-schematic-setup-left .sweep-pre-layout-length-left-title .sweep-create-btn {
  width: 75px;
}

.sweep-pre-layout-schematic-setup-left .ant-divider {
  margin: 10px 0px;
}

.sweep-pre-layout-length-left-button {
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}

.sierra-sweeping-pre-layout-schematic-panel {
  margin-top: 80px;
}

.sierra-sweep-content-setting .aurora-table.sierra-sweeping-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  overflow: auto;
}

.sweep-pre-layout-schematic-setup-canvas {
  padding: 10px;
  padding-right: 14px;
  padding-left: 0px;
}

.sweep-measurement-tooltip {
  /* white-space: pre; */
  max-width: 600px;
}

.sierra-sweep-content-setting .aurora-table.sierra-sweeping-table .aurora-table-body .ant-table-row>td.sierra-measurement-title-td>.editable-cell-value-wrap,
.sierra-sweep-content-setting .aurora-table.sierra-sweeping-table .ant-table-content>.aurora-table-body td.sierra-measurement-title-td .editable-cell-value-wrap {
  overflow: hidden;
  padding: 0px 0px;
}

.sierra-sweep-content-setting .sierra-sweep-measurement-title {
  width: 100%;
  height: 100%;
  max-height: 88px;
  overflow: auto;
  padding: 0px 5px;
  min-height: 30px;
}

.sierra-sweep-measurement-tooltip-title {
  font-weight: 600;
}

.sierra-sweep-measurement-tooltip-item {
  text-indent: 6px;
}