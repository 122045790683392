.cascade-sign-off-template-content {
  padding: 20px 20px;
  min-width: 1610px;
  width: fit-content;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body>table>.ant-table-thead>tr>th {
  color: #003b91;
}

.cascade-sign-off-template-table .cascade-sign-off-table-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: block;
  margin-left: 3px;
}

.cascade-sign-off-template-table .pre-layout-fold-icon {
  margin-top: 0px;
}

.cascade-sign-off-table-title-span {
  display: inline-block;
  cursor: pointer;
}

.cascade-sign-off-template-table .cascade-components-table tbody>tr>td .cascade-sign-off-impedance-value-span {
  display: inline-block;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-column-title {
  padding-left: 0px;
}

.cascade-sign-off-soc-span {
  color: #0d76e4;
  font-size: 12px;
}

.cascade-sign-off-pmic-span {
  color: #c98301;
  font-size: 12px;
}

.cascade-sign-off-file-box {
  float: left;
  padding-left: 20px;
  width: fit-content;
  color: #003b91;
  line-height: 32px;
}

.cascade-sign-off-top-title {
  display: flex;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table table {
  border-collapse: collapse;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body .cascade-sign-off-pmic-comp-div {
  border-bottom: 1px solid #a8c4e6;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body .cascade-sign-off-pmic-comp-div>td,
.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body tr>.cascade-sign-off-pmic-comp-div-td {
  border-bottom: none;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body tr>td.cascade-sign-off-table-ground-pin-div,
.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body>table>.ant-table-thead>tr>.cascade-sign-off-table-ground-pin-title {
  border-right: 2px solid #d4d4d4;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body>table>.ant-table-thead>tr th {
  text-align: center;
}

.cascade-template-add-icon {
  color: #0d76e4;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 50%;
  margin-top: -7px;
}

.cascade-sign-off-template-download-icon {
  color: #0d76e4;
  cursor: pointer;
  margin-left: 12px;
}

.cascade-sign-off-template-table .cascade-component-model-span {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cascade-top-template-option-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-top-template-option-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.cascade-top-template-option-panel-main {
  margin: 15px 20px;
}

.template-title-item-main {
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0px;
}

.template-title-item {
  line-height: 28px;
  font-size: 14px;
  width: 300px;
  display: inline-block;
  margin: 0px 20px 0px 0px;
}

.template-rl-spec-span {
  width: 100px;
}

.template-rl-spec-input {
  width: calc(100% - 120px)
}

.template-title-item-input {
  width: 200px;
}

.template-title-item-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.template-title-item-sub-span {
  padding-left: 20px;
}

.template-radio-item,
.template-option-item {
  margin: 5px 0px;
  margin-left: 20px;
}

.cascade-template-sparameter-download-icon {
  color: #1890ff;
  cursor: pointer;
}

.cascade-template-sparameter-download-td {
  text-align: center;
}

.cascade-template-add-button {
  width: 166px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
  margin: 2px 0px;
}

.cascade-template-add-PMIC-button {
  width: 200px;
  margin-left: 10px;
}

.cascade-template-add-button-row {
  margin-bottom: 10px;
}

.cascade-template-add-button-flex {
  display: flex;
  justify-content: space-between;
  width: 376px;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .template-power-delete {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -6px;
  color: #ccc;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content>.aurora-table-body .ant-table-row td:hover .template-power-delete {
  color: #1890ff;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td:first-child {
  padding-right: 22px;
  padding-left: 22px;
}

.cascade-sign-off-template-table .cascade-components-table .template-power-net-td {
  min-width: 180px;
  position: relative;
}

.template-impedance-add-icon {
  color: #1890ff;
  cursor: pointer;
  margin-left: 10px;
}

.impedance-freq-popover {
  min-width: 200px;
  max-width: 600px;
}

.impedance-freq-popover .ant-popover-inner-content {
  padding: 8px;
}

.impedance-freq-button-content {
  position: relative;
  width: 100%;
  height: 32px;
}

.template-coupling-result-popover .ant-popover-inner-content {
  padding: 8px;
}

.impedance-freq-popover .impedance-spec-rl-checkbox {
  margin-top: 8px;
}

.impedance-freq-popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}

.impedance-freq-tagsInput {
  width: calc(100% - 46px);
}

.impedance-freq-button {
  padding: 0px 10px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  position: absolute;
  right: 10px;
  bottom: 8px;
  top: 8px;
}

.template-impedance-delete-freq-icon {
  margin-left: 6px;
  cursor: pointer;
  color: #1890ff;
}

.impedance-rl-result-failed {
  color: #e51212;
  display: block;
  font-size: 18px;
}

.impedance-rl-result-passed {
  color: #03d703;
  display: block;
  font-size: 18px;
}

.template-coupling-descriptions {
  max-width: 300px;
}

.template-coupling-descriptions .ant-descriptions-row .ant-descriptions-item {
  display: block;
}

.template-coupling-descriptions .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.template-coupling-descriptions .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-label {
  vertical-align: middle;
}

.template-coupling-descriptions .ant-descriptions-row .template-coupling-descriptions-power .ant-descriptions-item-content {
  width: calc(100% - 90px);
}

.template-coupling-descriptions .ant-descriptions-row .template-coupling-descriptions-gnd .ant-descriptions-item-content {
  width: calc(100% - 98px);
}

/* .template-coupling-result-info-icon {
  color: #1890ff;
  cursor: pointer;
} */

.template-imp-target-radio {
  display: block;
  margin: 0 8px;
  height: 20px;
  margin-bottom: 8px;
  margin-left: 0px;
}

.cascade-top-template-option-item {
  margin-bottom: 6px;
}

.cascade-template-pdf-choose-content {
  min-height: 400px;
  padding: 5px;
}

.cascade-template-pdf-choose-table tbody>tr>td>span {
  display: block;
  max-height: 100px;
  overflow: auto
}

.cascade-signoff-stick-row {
  position: sticky;
  left: 20px;
  z-index: 200;
}