.sparameter-display-mode {
  padding: 4px 12px;
  height: 35px;
}

.sparameter-display-mode label.ant-radio-wrapper {
  line-height: 30px;
}

.sparameter-result-file-name {
  transition: all 0.3s;
  font-weight: bold;
  margin-top: 5px;
  padding-left: 5px;
  cursor: pointer;
  line-height: 24px;
  height: 24px;
  position: unset !important;
}

.sparameter-result-file-name .ant-col {
  position: absolute !important;
}

/* .sparameter-result-file-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
} */

.sparameter-file-expand-icon {
  padding-right: 4px;
  font-size: 10px;
  padding-left: 7px;
  vertical-align: baseline;
}

.sparameter-file-error-icon {
  color: #f12727;
}

.sparameter-file-download-icon {
  position: absolute;
  right: 8px;
  font-size: 18px;
  color: #40a9ff;
  margin-top: 2px;
  display: none;
}

.result-opt-icon {
  margin-left: 10px;
  color: #40a9ff;
  font-size: 16px;
  vertical-align: text-bottom;
}

.sparameter-result-file-name:hover .sparameter-file-download-icon,
.sparameter-result-file-name:hover .sparameter-file-delete-icon,
.sparameter-compare-title:hover .sparameter-file-delete-icon {
  display: inline-block;
}

.sparameter-span-file-name {
  width: calc(100% - 55px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.sparameter-result-ports-list {
  padding-left: 10px;
  position: unset !important;
}

.sparameter-channel-port-name {
  line-height: 24px;
  height: 24px;
  padding-left: 4px;
  cursor: pointer;
  margin-left: 0px;
}

.sparameter-channel-port-name .ant-checkbox-wrapper {
  line-height: 24px;
  height: 24px;
}

.sparameter-channel-port-name-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #7b7b7b;
  border-color: #7b7b7b;
}

.sparameter-channel-port-name-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: #7b7b7b;
}

.sparameter-channel-port-name-general {
  padding-left: 6px;
  margin-left: 0px;
}

.sparameter-channel-port-name:hover {
  background: #efefef;
}

.sierra-waveform-result-list .sparameter-channel-port-name:hover {
  background: #f9f9f9;
}

.sparameter-result-checkbox-group-name-single {
  padding-left: 23px;
}

.sparameter-channel-display-row {
  display: inline-flex;
  width: calc(100% - 16px);
  position: absolute;
  align-items: center;
}

.sparameter-channel-display-row>span:not(.anticon) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: calc(100% - 26px);
}

.sparameter-channel-display-multi-row>span {
  font-weight: normal;
}

.sparameter-sweep-channel-display-row {
  background: #f9f9f9;
  display: flex;
  align-items: center;
}

.sierra-waveform-result-list .sparameter-channel-port-name .sparameter-channel-display-row.sparameter-sweep-channel-display-row {
  left: 40px;
  min-width: 200px;
  max-width: 200px;
}

.sparameter-channel-port-name .sparameter-channel-display-row:hover {
  background: #efefef;
}

.sparameter-result-checkbox-group-name {
  line-height: 20px;
}

.sparameter-result-checkbox-group-name>label {
  color: #000;
  vertical-align: middle;
  margin-top: 1px;
}

.sparameter-result-sweep-group>label:nth-child(2) {
  width: calc(100% - 20px);
}

.sparameter-result-sweep-group>label .checkbox-group-title {
  width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sparameter-result-checkbox-group-name .checkbox-group-usage {
  width: 90px;
  font-weight: bold;
  display: inline-block;
  flex-shrink: 0;
}

.sparameter-result-checkbox-group-name>.aurora-color-picker-small {
  vertical-align: middle;
}

.sparameter-result-checkbox-group {
  padding-left: 4px;
}

.sparameter-result-checkbox-group>.ant-checkbox {
  top: 2px;
}

.sparameter-channel-result-content {
  padding: 0 12px 6px 4px;
  overflow-x: auto;
}

.sparameter-result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
}

.result-curve-current {
  margin-left: 10px;
  vertical-align: middle;
  font-weight: bold;
}

.sparameter-channel-result-content .ant-divider {
  top: unset;
  left: 0;
  padding-left: 10px;
}

.sparameter-channel-result-content .ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 0%;
}

.sparameter-sweep-checkbox {
  width: 70px;
  min-width: 70px;
  margin-left: 0px;
  margin-right: 10px;
  text-align: left;
  position: relative;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  padding-right: 10px;
}

.sparameter-sweep-checkbox .ant-color-picker-trigger.aurora-color-picker-small {
  margin-right: 0px;
  margin-left: 5px;
}

.sparameter-sweep-result-group {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.sweep-color-select {
  position: absolute;
  top: 2px;
  border-radius: 4px;
}

.sparameter-result-sweep-group {
  width: 240px;
  min-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  background-color: #f9f9f9;
  z-index: 999;
  left: 0;
  padding-left: 35px;
}

.sparameter-sweep-checkbox-group {
  margin-top: -2px;
  padding-right: 5px;
}

.sparameter-result-checkbox-group-name .checkbox-group-pcb {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
  margin-left: 10px;
  vertical-align: middle;
  margin-top: -3px;
}

.sparameter-result-checkbox-group-name .checkbox-group-name {
  min-width: 80px;
  display: inline-block;
}

.sparameter-all-sweep-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #464646;
  border-color: #464646;
}

.sparameter-all-sweep-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background: #464646;
}

.sparameter-signal-sweep-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #7b7b7b;
  border-color: #7b7b7b;
}

.sparameter-signal-sweep-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background: #7b7b7b;
}

.sparameter-channel-display-inline-flex {
  display: inline-flex;
  /* display: flex; */
  align-items: center;
  width: 100%;
  padding-left: 0;
}

.sparameter-result-file-name .sparameter-result-file-name-checked-title-history {
  left: 10px;
}

.sparameter-result-file-name .sparameter-result-file-name-checked-title {
  min-width: 240px;
  width: unset;
  position: absolute;
  z-index: 999;
  background-color: #f9f9f9;
  left: 0;
  padding-left: 10px;
  max-width: 260px;
  height: 24px;
}

.sparameter-channel-result-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.sparameter-channel-display-inline-flex .sparameter-channel-result-vertically-divider {
  min-width: 240px;
  width: unset;
  position: absolute;
  z-index: 999;
  background-color: #f9f9f9;
}

.result-checkbox-group-name-flex {
  width: 100%;
  height: 24px;
}

.ant-divider-horizontal.ant-divider-with-text-left.sparameter-channel-result-post-divider {
  min-width: 255px;
  width: unset;
  position: absolute;
  background-color: #f9f9f9;
  z-index: 999;
}

.sparameter-result-menu-post-title {
  width: 80px;
  margin-left: 0;
  margin-right: 0px;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080;
  cursor: auto;
}

.sparameter-result-menu-post-title-divider {
  padding-left: 14px;
  /* borderLeft: '1px solid #e8e8e8' */
}

.sparameter-sweep-post-title-box {
  min-width: 160px;
}

/* .sparameter-sweep-post-title-box .sparameter-result-menu-post-title-divider {
  padding-left: 14px;
} */

.sparameter-result-checkbox-group-left {
  position: absolute;
  width: 270px;
  min-width: 260px;
  background-color: #f9f9f9;
}

.result-checkbox-group-name-flex .sparameter-result-checkbox-group-name .checkbox-group-pcb {
  width: 70px;
  display: inline-block;
}

.sparameter-post-process-checkbox {
  width: 80px;
  min-width: 80px;
  margin-left: 0px;
  margin-right: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080;
}

.sparameter-signal-sweep-checkbox .sparameter-post-process-checkbox {
  min-width: 50px;
}

.sparameter-channel-display-inline-flex .sparameter-post-process-checkbox {
  min-width: 50px;
  margin-right: 31px;
}

.sparameter-sweep-checkbox .sparameter-sweep-table-icon {
  position: absolute;
  width: 19px;
  height: 23px;
  top: -1px;
  border-radius: 4px;
  left: 44px;
}

.sparameter-file-delete-icon {
  font-size: 13px;
  color: #40a9ff;
  display: none;
}

.sparameter-file-upload-button {
  margin-left: 10px;
  margin-top: 0px;
  color: #40a9ff;
  font-size: 15px;
  cursor: pointer;
  padding-top: 4px
}

#sparameter-import-divider .ant-divider-inner-text {
  padding-right: 100px !important
}

.sparameter-file-icon-disabled {
  color: #ccc
}

.sparameter-file-compare-cascader {
  margin-left: 13px;
  margin-top: 5px;
  width: calc(100% - 50px);
}

.sparameter-cascader-close-icon {
  display: unset;
  margin-left: 10px;
}

.sparameter-compare-title {
  margin-left: 5px;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.sparameter-compare-title .sparameter-file-delete-icon {
  margin-left: 16px;
}

.sparameter-compare-content {
  margin-left: 10px;
  margin-top: -5px;
}

.sparameter-result-file-content {
  margin-left: 10px;
}

.anticon.anticon-table.aurora-result-highLight-table-icon {
  color: #1890ff;
}

.sparameter-result-curve-title-pcb1,
.end-to-end-sparameter-result .sparameter-result-curve-title-rl-pcb1 {
  color: #3689d6;
}

.sparameter-result-curve-title-pcb2,
.end-to-end-sparameter-result .sparameter-result-curve-title-rl-pcb2 {
  color: #20a34e;
}

.end-to-end-sparameter-result .sparameter-channel-display-row {
  position: static;
  cursor: pointer;
}

.end-to-end-sparameter-result .sparameter-result-checkbox-group,
.end-to-end-sparameter-result .sparameter-channel-port-name {
  padding-left: 0px;
}

.end-to-end-sparameter-result .sparameter-result-file-content {
  margin-top: 5px;
}

.end-to-end-sparameter-result {
  min-height: 50px;
}

.sparameter-channel-display-multi-row .sparameter-result-file-name .sparameter-result-file-name-checked-title {
  padding-left: 27px;
}

.sparameter-result-file-content .ant-checkbox-group {
  display: block;
}

.sparameter-result-file-name .ant-col {
  width: 95%;
}

.sparameter-sweep-exp-title-icon {
  margin-left: 4px;
  font-size: 12px;
}