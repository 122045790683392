.aurora-library-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.aurora-library-panel .library-content-box {
  padding: 8px 14px;
  min-width: 500px;
}

.aurora-library-panel .library-edit-value-label {
  display: inline-block;
  width: 90px;
  vertical-align: middle;
}

.library-edit-value-label-via {
  width: 150px;
}

.aurora-library-panel .library-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  height: 28px;
}

.aurora-library-panel .library-select,
.aurora-library-panel .library-input {
  width: calc(100% - 90px);
}

.aurora-library-panel .library-input-via {
  width: calc(100% - 150px);
}

.aurora-library-panel .library-png {
  margin: 24px 0px;
  width: 100%;
  text-align: center;
  padding: 0px 16px;
}

.aurora-library-panel .library-png img {
  width: 84%;
}

.aurora-library-panel .library-item-half {
  width: calc(50% - 16px);
  float: left;
  margin-bottom: 8px;
  margin-top: 0px;
}

.aurora-library-panel .library-zd .library-item-zd-half-right {
  width: calc(50% - 44px);
}

.aurora-library-panel .library-item-half .library-edit-value-label {
  width: 76px;
}

.aurora-library-panel .library-item-half .library-select,
.aurora-library-panel .library-item-half .library-input {
  width: calc(100% - 76px);
}

.aurora-library-panel .library-table {
  margin: 8px 8px 16px 6px;
}

.aurora-library-panel .library-item-zd .library-edit-value-label {
  text-align: center;
}

.aurora-library-panel .library-item-zd .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.pre-layout-layers-unit-selection .ant-select-selection--single {
  height: 26px;
}

.pre-layout-layers-unit-selection .ant-select-selection__rendered {
  line-height: 24px;
}

.pre-layout-layers-unit-selection .ant-select-arrow {
  right: 6px;
}

.pre-layout-layers-unit-option {
  z-index: 2000;
}

.aurora-library-panel .library-png.library-png-margin {
  margin: 0px 0px;
}

.aurora-library-panel .library-zd {
  margin: 0px;
  margin-bottom: 4px;
  height: 32px;
  line-height: 28px;
  position: relative;
}

.aurora-library-panel .library-zd .library-zd-calculate-icon {
  width: 20px;
  margin-left: 10px;
  color: #1890ff;
  cursor: pointer;
  line-height: 28px;
  font-size: 16px;
  position: absolute;
  top: 6px;
  right: calc(50% - 20px);
}

.aurora-library-panel .library-zd .library-zd-calculate-log-icon {
  right: calc(50% - 44px);
}

.aurora-library-panel .library-zd .ant-spin-nested-loading {
  height: 28px;
  line-height: 28px;
  width: 100%;
}

.aurora-library-panel .library-zd .library-zd-calculate-icon.library-zd-calculate-log-half-icon {
  right: 8px;
}

.aurora-library-panel .library-zd .library-zd-calculate-icon.library-zd-calculate-half-icon {
  right: 32px;
}

.aurora-trace-log-panel .aurora-msg-dialog-item {
  color: rgba(0, 0, 0, 0.85);
}

.aurora-trace-log-panel .aurora-msg-dialog-main {
  padding: 8px;
}

.aurora-library-panel .library-item-zd-half,
.aurora-library-panel .library-item-zd {
  margin-bottom: 0px;
}