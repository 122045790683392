.rocky-eye-diagram-main {
  padding: 10px;
  position: relative;
  height: auto;
}

.aurora-result-eyediagram .aurora-eye-item {
  flex: auto;
  border: 1px solid #a8c4e6;
  border-radius: 3px;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 1100px;
}

.aurora-result-eyediagram .aurora-eye-item-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.aurora-eye-item-flex .eye-title {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.aurora-result-eyediagram .signal-select {
  position: absolute;
  left: 76px;
  right: 10px;
  margin-top: 7px;
}

/* .aurora-result-eyediagram .signal-select . {
  height: 26px;
}

.aurora-result-eyediagram .signal-select . .ant-select-selector {
  margin-right: 14px;
  margin-left: 12px;
  line-height: 24px;
  height: 24px;
} */

.signal-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  padding: 4px 12px;
}

.aurora-result-eyediagram .signal-select .ant-select-arrow {
  right: 5px;
  font-size: 10px;
}

.aurora-result-eyediagram .eyediagram-signals,
.aurora-result-eyediagram .eyediagram-result-select {
  height: 40px;
  line-height: 40px;
  padding-left: 16px;
  background: #fafafa;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  border-radius: 4px 4px 0px 0px;
  position: relative;
}

.aurora-result-eyediagram .eyediagram-signals {
  height: 47px;
  line-height: 47px;
}