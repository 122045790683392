.sierra-pre-layout-panel {
  top: 130px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-pre-layout-panel .pre-layout-setting .sub-extraction-input .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.sierra-pre-layout-panel .pre-layout-pcie-select .pre-layout-span {
  width: 240px;
}

.sierra-pre-layout-panel .pre-layout-pcie-select .pre-layout-select,
.sierra-pre-layout-panel .pre-layout-pcie-select .pre-layout-input {
  width: calc(100% - 180px);
  font-size: 14px;
}

.sierra-pre-layout-panel .pre-layout-pcie-select .ant-select-single .ant-select-selector {
  color: rgba(0, 0, 0, 0.85);
}

.sierra-pre-layout-body {
  padding: 20px;
  min-width: 1350px;
  width: fit-content;
}

.sierra-pre-layout-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.ant-table-small tr.ant-table-expanded-row td>.sierra-pre-layout-pin-table.ant-table-wrapper,
.sierra-pre-layout-pin-table .ant-table-small tr.ant-table-expanded-row td>.ant-table-wrapper {
  margin: 0px;
}

.sierra-pre-layout-pin-table .ant-table-thead>tr>th,
.sierra-pre-layout-pin-table .aurora-table-body>tr>td {
  border-right: 0px;
}

.sierra-pre-layout-pin-table .ant-table {
  margin-block: 0px !important;
  margin-inline: 34px 0px !important;
}

.pre-layout-component-table .sierra-pre-layout-pin-table .aurora-table-body>tr>td:last-child {
  border-right: 0px !important;
}

.sierra-pre-layout-pin-table .ant-table-small .ant-table-content {
  border-right: 0px;
}

.sierra-pre-layout-pin-table .ant-table-small {
  border: 0px;
}

.sierra-pre-layout-component-table .ant-table .ant-table-thead>tr>th:first-child,
.sierra-pre-layout-component-table .ant-table .aurora-table-body>tr>td:first-child {
  border-right: 0px;
}

.sierra-pre-layout-component-table tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

.sierra-pre-layout-component-table tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.sierra-pre-layout-canvas {
  min-height: 80px;
  min-width: 1000px;
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  position: relative;
  margin-top: 10px;
}

.sierra-pre-layout-canvas .pre-layout-canvas {
  text-align: center;
  vertical-align: middle;
  min-width: 100%
}

.sierra-pre-layout-canvas .port-select {
  position: absolute;
  font-size: 12px;
}

.sierra-pre-layout-canvas .port-select .spice-pin-input {
  font-size: 12px;
  width: 50px;
  height: 20px;
  max-width: 50px;
  line-height: 20px;
}

.sierra-pre-layout-canvas .port-select .port-empty-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #afafaf;
}

.sierra-pre-layout-canvas .port-select .spice-pin-input .spice-pin-node-delete-icon {
  top: 5px;
  right: -10px;
}

/* === pcb model === */
.sierra-pre-layout-body .pcb-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 4px 10px;
  position: relative;
}

.sierra-pre-layout-body .pcb-model-selection {
  width: calc(100% - 100px);
  margin-right: 32px;
  height: 24px;
}

.sierra-pre-layout-body .pcb-model-select-dropdown {
  z-index: 10000000;
}

.sierra-pre-layout-body .pcb-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 22px;
}

.sierra-pre-layout-body .pcb-model-title {
  font-size: 14px;
  font-weight: 500;
}

.sierra-pre-layout-body .pcb-model-select .ant-select-selector,
.sierra-pre-layout-body .pcb-model-select .ant-select-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 7px;
}

.sierra-pre-layout-body .pcb-model-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 13px;
  padding: 2px 12px;
}

.sierra-pre-layout-pcb-model-select {
  float: left;
  width: calc(50% - 50px);
  padding-right: 10px;
  font-size: 13px;
}

.sierra-pre-layout-pcb-model-select-right {
  margin-right: 22px;
  height: 24px;
}

.sierra-pre-layout-pcb-model-select .ant-select-arrow:hover {
  color: #1890ff;
  text-transform: none;
}

.sierra-pre-layout-pcb-model-select .ant-select-arrow {
  text-transform: none !important;
  transform: none !important;
  transition: none !important;
  margin-right: 10px;
}

.sierra-pre-layout-model-delete-icon {
  position: absolute;
  right: 12px;
}

.sierra-pre-layout-cursor {
  cursor: pointer;
}

.sierra-pre-layout-value-box {
  display: inline-flex;
  align-items: baseline;
}

.sierra-pre-layout-empty-unit {
  margin-left: 5px;
}