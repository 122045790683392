.create-data-content {
  padding: 2px 12px 0px;
}

.sierra-interface-creation-main .create-content-input-pcb {
  padding: 8px 12px;
}

.create-data-content,
.sierra-interface-create-panel .create-data-content>.aurora-create-advanced-content {
  background-color: #ebebeb;
  border-radius: 4px;
}

.sierra-create-identification-panel .identification-input,
.sierra-create-identification-panel .identification-select {
  width: calc(100% - 80px);
}

.create-data-content {
  padding-bottom: 8px;
}

.create-content-input-item,
.create-content-interface-name-item {
  padding: 8px;
  display: flex;
  line-height: 28px;
  justify-content: space-between;
}

.create-content-interface-name-item {
  margin: 8px 14px;
}

.create-content-input-item .create-edit-input-label {
  width: 94px;
  height: 28px;
  line-height: 28px;
}

.create-content-interface-name-item .create-edit-input-label {
  width: 116px;
}

.create-content-input-item .create-input,
.create-content-input-item .create-select {
  width: calc(100% - 94px);
}

.sierra-interface-create-panel .create-content-input,
.sierra-interface-create-panel .create-content-select {
  width: calc(100% - 80px);
}

.sierra-interface-create-panel .create-content-interface-name-item .create-content-input {
  width: calc(100% - 116px);
}

.cerate-interface-footer .create-content-interface-name-item {
  margin-bottom: 0px;
  margin-top: 0px;
}

.sierra-interface-create-panel .create-panel-msg-box {
  margin: 0px 8px 8px;
  line-height: 28px;
  padding: 0px 0px;
}

.sierra-interface-create-panel .select-content-msg-box {
  margin: 0 0 10px;
}

.create-panel-msg-box .aurora-model-name-error-msg {
  width: 100%;
  padding: 0 10px;
  border-radius: 4px;
  text-align: left;
}

.create-footer-content {
  display: flex;
  justify-content: center;
  border-radius: 0 0 4px 4px;
}

.create-footer-content .ant-btn {
  height: 28px;
  width: 75px;
}

.interface-identification-select-content {
  padding: 10px 0px;
}

.sierra-interface-create-panel .ant-spin-nested-loading>div>.ant-spin {
  max-height: 600px;
}

.sierra-interface-create-panel .select-checkbox-title-proportion,
.sierra-create-interface-confirm-panel .select-checkbox-title-proportion {
  margin-left: 8px;
}

.create-interface-merge-content {
  width: 100%;
  display: flex;
  padding: 0 14px;
  padding-right: 0px;
}

.modal-foot-position .create-interface-merge-content {
  padding: 5px 14px 5px 2px;
}

.create-interface-merge-checkbox {
  width: 30px;
  text-align: left;
  line-height: 28px;
}

.create-interface-merge-content .create-interface-merge {
  width: calc(100% - 30px)
}

.sierra-interface-create-panel .creation-interface-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 8px 10px;
  padding-left: 30px;
}

.sierra-interface-create-panel .creation-interface-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 10px;
}

.sierra-interface-create-panel .creation-interface-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px 16px;
}

.create-content-input-pcb .aurora-select {
  height: fit-content;
}

.create-content-input-pcb .aurora-select .ant-select-selector {
  height: fit-content;
}

.interface-identification-select-content .identification-select-table .ant-table-small,
.interface-creation-content .identification-select-table .ant-table-small {
  border-color: #a8c4e6;
  border-right: 1px solid #a8c4e6;
}

.creation-interface-main {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  height: 100%;
}

.creation-interface-main .creation-interface-collapse-content {
  border: 1px solid #a8c4e6;
  width: 50%;
  border-radius: 4px;
  position: relative;
}

.interface-creation-content {
  border: 1px solid #a8c4e6;
  border-radius: 4px;
  min-width: 382px;
  margin-left: 10px;
  width: 50%;
  position: relative;
  min-height: 300px;
}

.interface-creation-content-item {
  overflow: auto;
}

.identification-creation-table {
  margin: 8px;
}

.sierra-interface-creation-main {
  width: 100%;
  height: fit-content;
  overflow: auto;
  height: 100%;
}

.sierra-interface-creation-main>.ant-spin-nested-loading,
.sierra-interface-creation-main>.ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}

.creation-interface-collapse-main,
.interface-creation-table-main {
  overflow: auto;
}

.interface-creation-table-main {
  border-bottom: 1px solid #d6dae0;
  height: calc(100% - 40px);
}

.selection-signals-content {
  display: flex;
  padding: 8px 16px 10px 8px;
  justify-content: right;
}

.connect-selected-signal-button {
  margin-left: 10px;
}

.selection-signals-content .ant-btn {
  font-size: 12px;
  line-height: 12px;
}

.interface-creation-connections-button {
  padding: 8px 10px;
  text-align: right;
  /*   float: right; */
  padding-bottom: 0px;
  position: absolute;
  left: 324px;
  bottom: 34px;
}

.interface-creation-connections-table-button {
  float: right;
  position: static;
}

.cerate-interface-footer {
  /*   position: absolute; */
  /*   bottom: 6px;
  right: 0px; */
  width: 100%;
  height: 40px;
  padding: 6px 0px;
}

.create-interface-merge-content .aurora-select {
  margin-left: 10px;
}

.create-interface-merge-content>div {
  line-height: 28px;
}

/* .create-interface-merge-content .combine-selected-signals-button-content {
  line-height: 28px;
} */

.combine-selected-signals-button {
  margin-left: 10px;
}

.identification-select-table .ant-table .ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
}

.interface-creation-content .aurora-success-msg,
.interface-creation-content .aurora-model-name-error-msg {
  text-align: left;
  margin: 6px 8px;
}

.create-one-pcb-interface-content {
  display: flex;
  justify-content: space-between;
}

.create-one-pcb-interface-content .selection-signals-content {
  padding-top: 8px;
}

.sierra-creation-interface-dropdown {
  z-index: 100000;
}

/* .creation-interface-tabs.ant-tabs.ant-tabs-card .aurora-input {
  max-width: calc(100% - 24px);
} */

.sierra-interface-delete-icon {
  color: rgba(0, 0, 0, 0.45);
  margin-left: 8px;
  cursor: pointer;
  display: none;
}

.sierra-interface-creation-name-edit {
  cursor: pointer;
}

.sierra-interface-creation-name-main {
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.sierra-interface-creation-name-edit:hover .sierra-interface-delete-icon {
  display: inline-block;
}

.sierra-interface-delete-icon:hover {
  color: #40a9ff;
}

.interface_creation-table-label {
  font-size: 14px;
  color: #0b3694;
  font-weight: bold;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  margin: 8px 8px;
  margin-bottom: 0px;
}

.merge-selected-signal-button-div {
  text-align: right;
  width: fit-content;
  padding-left: 10px;
  padding-right: 8px;
  display: flex;
}

.merge-selected-signal-button {
  font-size: 12px;
  line-height: 12px;
}

.creation-interface-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 0px 8px;
}

.creation-interface-tabs.ant-tabs.ant-tabs-card .sierra-interface-delete-icon {
  margin-right: 0px;
}

.identification-creation-interface-table {
  padding: 0px 8px;
}

.creation-interface-tabs.ant-tabs.ant-tabs-card {
  margin-left: 4px;
}

/* merge signals */
.sierra-merge-signals-main {
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
}

.sierra-merge-signals-left,
.sierra-merge-signals-right {
  border: 1px solid #a8c4e6;
  padding: 6px;
}

.sierra-merge-signals-arrow-right {
  line-height: 200px;
}

.sierra-merge-signals-arrow-right>.anticon {
  font-size: 30px;
  color: #40a9ff;
  padding: 0px 4px;
}

.sierra-merge-signals-nets-td {
  max-height: 80px;
  overflow: auto;
}

.sierra-interface-creation-main .creation-interface-collapse {
  background-color: #fff;
}

.creation-interface-pcb-expand-title {
  display: flex;
  justify-content: space-between;
}

.creation-interface-pcb-expand-title>.sierra-pcb-setup-title {
  display: flex;
  width: calc(100% - 112px);
}

.creation-interface-pcb-expand-title>.sierra-pcb-setup-create-title {
  width: 100%;
}

.creation-interface-pcb-expand-title>.sierra-pcb-setup-title>span,
.creation-interface-pcb-expand-title>.sierra-pcb-setup-create-title>span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.creation-interface-pcb-expand-title>.sierra-pcb-setup-create-title>span {
  width: 100%;
}

.creation-interface-nets-eye-disabled-icon.anticon {
  margin-left: 10px;
  cursor: not-allowed;
}

.creation-interface-nets-eye-icon {
  color: #40a9ff;
  cursor: pointer;
  margin-left: 10px;
}

.creation-interface-pcb-eye-icon>.anticon {
  color: #40a9ff;
  cursor: pointer;
}

.interface-creation-retweet-icon {
  color: #40a9ff;
  cursor: pointer;
  margin-left: 4px;
}

.creation-interface-pcb-expand-main {
  position: absolute;
  right: -12px;
  top: 4px;
  z-index: 100000000;
  bottom: 6px;
  width: 10px;
  cursor: pointer;
}

.creation-interface-pcb-expand-icon {
  color: #40a9ff;
  font-size: 18px;
  position: absolute;
  right: -2px;
  top: 50%;
  margin-top: -18px;
}

.creation-interface-conn-expand-icon {
  color: #40a9ff;
  font-size: 18px;
  position: absolute;
  left: -2px;
  top: 50%;
  margin-top: -18px;
}

.creation-interface-conn-expand-main {
  position: absolute;
  left: -12px;
  top: 4px;
  bottom: 6px;
  width: 10px;
  cursor: pointer;
}

.creation-interface-pcb-expand-main:hover,
.creation-interface-conn-expand-main:hover {
  background: #efefef;
  border-radius: 4px;
}

.creation-interface-collapse-content .creation-interface-pcb-not-expand-main {
  left: 8px;
}

.creation-interface-collapse-content .creation-interface-pcb-not-expand-main .creation-interface-pcb-expand-icon {
  right: -5px;
}

.interface-creation-content .creation-interface-conn-not-expand-main {
  right: 8px;
  left: unset;
}

.interface-creation-content .creation-interface-conn-not-expand-main .creation-interface-conn-expand-icon {
  left: -5px;
}

.identification-creation-table .aurora-table-body>tr>td:nth-child(2),
.identification-creation-interface-table .aurora-table-body>tr>td:first-child,
.identification-creation-table .aurora-table-body>tr>td:nth-child(1) {
  position: relative;
  padding-right: 22px !important;
}

.identification-creation-table .ant-table-small>.ant-table-content .aurora-table-body>tr>.interface-creation-table-check-td {
  padding-right: 8px !important;
}

.interface-creation-conn-signal-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.interface-creation-conn-signal-content:hover .interface-creation-delete-signal-icon {
  color: #40a9ff;
}

.interface-creation-delete-signal-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pcb-setup-footer {
  border-top: 1px solid #d6dae0;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 146px;
  position: relative;
}

.pcb-setup-single-footer {
  height: 74px;
}

.creation-interface-main-single-pcb,
.creation-interface-main-single-pcb .creation-interface-collapse-content {
  height: 100%;
}

.sierra-pcb-search-log-info-icon {
  color: #40a9ff;
  padding: 4px 6px;
  font-size: 15px;
}


.sierra-interface-create-panel .aurora-create-advanced-content .aurora-create-advanced-input-item .aurora-tags-input-content,
.sierra-interface-create-panel .aurora-create-advanced-content .aurora-create-advanced-input-item .aurora-select-regular-input {
  width: calc(100% - 140px);
}

.create-selected-signal-button {
  /*   float: left; */
  margin-right: 14px;
  height: 28px;
  width: 75px;
}

.create-interface-single-content {
  justify-content: space-between;
}

.create-interface-single-checkbox-content {
  display: flex;
  /*  margin-left: 10px; */
}

.pcb-setup-single-footer .create-interface-single-checkbox-content {
  margin-left: 14px;
}

.create-interface-single-create-button {
  line-height: 28px;
  height: 28px;
  margin-left: 14px;
  /*  padding: 2px 0px; */
}

.pcb-interface-creation-radio {
  display: flex;
  padding: 2px 6px;
  padding-left: 14px;
}

.create-interface-single-checkbox-content>div {
  height: 28px;
  line-height: 28px;
}

.sierra-interface-create-panel .create-data-content .aurora-create-advanced-input-item .create-content-input {
  width: calc(100% - 140px);
}

.create-nets-keywords-clear-icon {
  position: absolute;
  right: 26px;
  height: 26px;
  line-height: 26px;
  margin: 2px;
  color: #bebebe;
  display: none;
}

.sierra-interface-creation-main .aurora-select-regular-input:hover .create-nets-keywords-clear-icon,
.sierra-interface-creation-main .aurora-not-select-regular-input:hover .create-nets-keywords-clear-icon {
  display: block;
  color: #9f9f9f;
}

.sierra-create-interface-confirm-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin-top: 80px;
}

.sierra-create-interface-confirm-panel .sierra-create-interface-confirm-content {
  padding: 8px 12px;
  overflow: auto;
}

.sierra-re-create-cancel-button {
  margin-left: 30px;
}

/* .create-footer-content .sierra-re-create-ok-button {
  width: 90px;
} */

.sierra-create-interface-confirm-content-title {
  height: 28px;
  line-height: 22px;
  padding-bottom: 6px;
}

.sierra-create-interface-confirm-table .ant-table-content .ant-table-body tr td .sierra-re-create-interface-checkbox {
  text-align: center;
}

.sierra-create-log-panel .aurora-msg-dialog-item {
  color: rgba(0, 0, 0, 0.85);
}

.aurora-msg-dialog-info-panel.sierra-create-log-panel {
  top: 60px;
}