.color-hint {
  width: 12px;
  height: 12px;
  display: inline-block;
}

.wave-range {
  background-color: #fff;
  border: 1px solid #ccc;
  width: 80px;
}

.waveform {
  width: 100%;
  height: 100%;
}

.waveform-clear {
  zoom: 1;
}

.waveform-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.sierra-waveform-plot {
  float: left;
  width: 600px;
  height: 500px;
}

.sierra-waveform-setting>.ant-checkbox-group>.ant-checkbox-wrapper {
  margin-left: 0px;
  display: inline-block;
  width: 100%;
}

.sierra-waveform-setting .sierra-eyediagram {
  width: 100%;
}

.sierra-waveform-setting .sierra-eyediagram .ant-radio-group {
  width: 100%;
}

.sierra-waveform-setting .sierra-eyediagram>.ant-radio-group>.ant-radio-wrapper {
  width: 100%;
}

/* 2D curve plotting */

.plot .curve {
  fill: none;
}

.plot .tick line {
  stroke: #ccc;
}

.selector-slide {
  cursor: move;
  fill: #337ab7;
}

.curve-mark {
  fill: #ff0000;
}

.mark-text {
  font-size: 10px;
  text-anchor: middle;
}

tr.ant-table-expanded-row>td {
  padding: 0px 0px 0px 0px !important;
}

tr.ant-table-expanded-row table {
  padding: 0 !important;
}

tr.ant-table-expanded-row .ant-table {
  border-left-width: 0 !important;
}

.impedence .ant-tabs-bar {
  margin: 0px;
}

.sierra-input-start {
  position: absolute;
  bottom: 0;
  left: 0;
}

.sierra-input-end {
  position: absolute;
  bottom: 0;
  left: 700px;
}

.sierra-waveform-driver {
  margin-left: 10px;
  width: 100%;
}

.impedance-expand-table {
  margin: 0px !important;
}

.impedance-expand-table .ant-table-small>.ant-table-content>.aurora-table-body {
  padding: 0px;
  margin: 0px;
}

.impedance-expand-table .ant-table-small,
.impedance-expand-table .ant-table-small.ant-table-bordered .ant-table-content {
  border: 0px !important;
}

.sierra-waveform-design-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
}

.sierra-waveform-design-name {
  max-width: calc(100% - 230px);
}

.sierra-waveform-design-name-current {
  max-width: calc(100% - 290px)
}

.sierra-waveform-checkbox .ant-checkbox {
  float: left;
  margin-top: 4px;
  margin-right: 5px;
  margin-left: 5px;
}

.sierra-waveform-checkbox:hover .color-select {
  background-color: #dbdbdb;
}

.sierra-waveform-checkbox span {
  padding: 0px;
}

.sierra-waveform-checkbox-lable {
  width: 100%;
  height: 20px;
  display: block;
}

.sierra-waveform-tooltip {
  width: 100%;
  height: 20px;
  display: block;
}

.sierra-waveform-checkbox:hover {
  background: #dbdbdb;
}

.sierra-waveform-left {
  height: 100%;
  float: left;
  width: 100%;
}

.sierra-waveform-setting {
  border-left-color: #dcdcdc;
  border-left-width: 1px;
  border-left-style: solid;
  background-color: #ffffff;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;
  min-width: 320px;
}

.sierra-waveform-left .sierra-waveform-svg {
  height: 100%;
  width: 100%;
}

.axis-title,
.setting-input-lable,
.display-mode-title {
  font-weight: bold;
  margin-right: 8px;
}

.sierra-setting-input {
  width: 60%;
}

.sierra-waveform-checkboxgroup {
  padding-left: 10px;
  width: 100%;
  height: 56%;
  overflow-y: auto;
  background-color: #fafafa;
}

.sierra-waveform-setting-box {
  margin-left: 10px;
  max-width: 450px;
  height: 40%;
  overflow: auto;
}

.sierra-waveform-setting .dividingLine {
  height: 100%;
}

.sierra-result-main .ant-menu-item {
  margin-right: 10px;
}

.sierra-waveform-svg .domain {
  display: none;
}

.sierra-result-main .ant-spin-nested-loading,
.sierra-result-main .ant-spin-container,
.sierra-result-main {
  height: 100%;
  box-sizing: border-box;
}

.sierra-result-eye-diagram-select {
  float: left;
  margin: 8px 10px;
}

.sierra-result-eye-diagram-button {
  margin: 8px 10px;
  float: left;
}

.sierra-result-eye-diagram-progress {
  margin: 12px 10px;
  width: 240px;
  margin-left: 0;
  float: left;
}

.sierra-result-eye-diagram-btn {
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 100px;
  padding: 0;
}

.sierra-result-img {
  /*   margin-left: -10px;
  margin-top: -10px; */
  width: 100%;
  height: 100%;
}

.sierra-result-eye-diagram {
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.sierra-result-content-box {
  padding: 20px 20px;
}

/* Eye diagram */

.sierra-eye-diagram-main {
  padding: 10px;
  height: 100%;
  position: relative;
  overflow: auto;
  width: 100%;
}

.sierra-eye-diagram-left {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 400px;
  transition: 0.3s;
  min-width: 200px;
}

.sierra-eye-diagram-right {
  position: absolute;
  left: calc(100% - 400px);
  top: 0px;
  bottom: 0px;
  right: 0px;
  border-left: 1px solid #e8e8e8;
  background-color: #fafafa;
  transition: 0.3s;
}

.sierra-eye-diagram-button {
  margin: 8px 12px;
}

.sierra-eye-diagram-btn {
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 100%;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sierra-eye-diagram-config-main {
  padding-top: 14px;
  padding-right: 10px;
}

.sierra-eye-diagram-config-main .sierra-eye-diagram-config-content {
  min-width: 324px;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content>.ant-collapse-item {
  background: #fafafa;
  border-radius: 0px;
  margin-bottom: 0px;
  border: 0px;
  overflow: hidden;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content>.ant-collapse-item>.ant-collapse-header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  padding: 0px 10px;
  padding-left: 8px;
}

.sierra-eye-diagram-config-main .sierra-eye-diagram-right .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
  padding-inline-end: 8px;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content>.ant-collapse-item>.ant-collapse-header:hover {
  background: #dbdbdb;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  font-size: 10px;
  color: rgba(0, 0, 0, .44);
  left: 6px;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content .ant-collapse-content>.ant-collapse-content-box {
  padding-bottom: 0px;
  padding-top: 0px !important;
  padding-right: 6px;
}

.sierra-eye-diagram-config-item {
  margin: 10px 0px;
  margin-left: 15px;
}

.sierra-eye-diagram-config-clock-item {
  margin: 8px 0px;
  margin-left: 33px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-span {
  display: inline-block;
  width: 140px;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  font-weight: 700;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-select {
  display: inline-block;
  width: calc(100% - 140px);
  line-height: 24px;
  font-size: 13px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-select .ant-select-selection--multiple {
  min-height: 24px;
  padding-bottom: 1px;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-clock-item .sierra-eye-diagram-config-item-select {
  width: calc(100% - 122px);
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-clock-item .sierra-eye-diagram-config-item-span {
  width: 122px;
  font-style: italic;
  color: rgb(0, 0, 0, 0.85);
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-input {
  display: inline-block;
  width: calc(100% - 140px);
  height: 24px;
  line-height: 24px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-sub-input {
  display: inline-block;
  width: calc((100% - 152px) / 2);
  height: 24px;
  line-height: 24px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-line {
  display: inline-block;
  width: 6px;
  margin: 0px 3px;
  height: 5px;
  background-color: #a6a6a6;
  margin-bottom: 4px;
  transform: scaleY(0.2);
}

.sierra-eye-diagram-config-sub-content-header {
  width: calc(100% - 13px);
  height: 12px;
  background-color: #b9b9b9;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  margin-left: 12px;
  margin-top: 6px;
}

.sierra-eye-diagram-config-sub-content-header .sierra-eye-diagram-config-sub-icon {
  font-size: 10px;
  position: absolute;
  left: 50%;
  top: 1px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-input .ant-input-group-addon,
.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-sub-input .ant-input-group-addon {
  font-size: 12px;
  padding: 0px 8px;
  /*   width: 68px; */
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-input-padding .ant-input-group-addon {
  padding: 0px 13px;
}

.sierra-eye-diagram-config-item .ant-input-group-addon .sierra-eye-diagram-down-select {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  margin: -2px -11px;
}

.sierra-eye-diagram-down-select .ant-select-arrow .ant-select-suffix {
  font-size: 10px;
}

.sierra-eye-diagram-down-select .ant-select-arrow {
  margin-top: -5px;
  right: 6px;
}

.sierra-eye-diagram-down-select .ant-select-selector {
  height: 22px;
  line-height: 22px;
  margin-right: 18px;
  margin-left: 8px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-down-select {
  margin: 0px;
  height: 24px;
  line-height: 24px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-select .ant-select-selection--multiple .ant-select-selector li {
  font-size: 12px;
  height: 18px;
  line-height: 16px;
}

.sierra-eye-diagram-btn-progress {
  background: #82c2fd;
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  right: 100%;
  transition: 0.3s;
}

.sierra-eye-diagram-btn-span {
  position: relative;
  line-height: 30px;
}

.sierra-eye-diagram-error-msg {
  display: block;
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.sierra-download-eye-diagram-log-panel {
  top: 46px;
}

.sierra-download-eye-diagram-log-panel .aurora-msg-dialog-item {
  color: rgba(0, 0, 0, 0.85);
}

.sierra-download-eye-diagram-log-panel .aurora-msg-dialog-main {
  padding: 8px;
}

.sierra-eye-diagram-log-icon {
  margin-left: 10px;
  color: #1896ff;
}

.sierra-eye-diagram-error-msg-content {
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.sierra-eye-diagram-error-msg-content>span {
  display: block;
}

.sierra-eye-diagram-config-main .sierra-eye-diagram-config-advanced {
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: -2px;
  margin-left: 15px;
  width: calc(100% - 16px);
  min-width: calc(100% - 16px);
}

.sierra-eye-diagram-config-main .sierra-eye-diagram-config-divider {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 10px;
  min-width: calc(100% - 10px);
  width: calc(100% - 10px);
}

.sierra-eye-diagram-main .setting-menu {
  margin: 8px;
  padding: 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 32px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.sierra-eye-diagram-main .setting-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.sierra-result-main .sierra-result-bar {
  width: 100%;
  display: flex;
}

.sierra-result-main .sierra-result-bar .ant-menu-horizontal {
  width: 100%;
  overflow: hidden;
}

.sierra_eye-diagram-config-sub-item {
  margin-left: 33px;
  font-style: italic;
}

.sierra-eye-diagram-main .sierra_eye-diagram-config-sub-item .sierra_eye-diagram-config-sub-span {
  width: 154px;
}

.sierra-eye-diagram-config-item-select-dropdown {
  right: 0px;
}

.sierra-touchstone-result-main {
  height: 100%;
}

.sierra-result-content {
  overflow-y: auto;
  overflow-x: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 45px;
}

.sierra-result-waveform .waveform-check-lable {
  width: 84px;
}

.sierra-result-waveform.waveform .waveform-current {
  width: 70px;
}

.sierra-result-waveform .sierra-waveform-comp-name {
  display: block;
  float: left;
  color: #3b70a7;
}

.sierra-result-experiment-display {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  /*  border-bottom: 1px solid #e8e8e8; */
  min-width: 350px;
  float: right;
}

.sierra-result-experiment-select {
  width: 330px;
  margin-right: 20px;
}

.sweep-delay-table-td,
.slerra-delay-table .aurora-table-body .ant-table-thead tr:nth-child(2) th {
  text-align: center;
}

.sierra-waveform-result-list {
  overflow: auto;
  height: calc(100% - 220px);
  margin: 5px 10px;
}

.sierra-waveform-result-list .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text {
  padding-left: 2px;
  padding-right: 10px;
}

.sierra-waveform-result-list .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text .sparameter-result-menu-title {
  padding-right: 30px;
}

.sierra-waveform-result-list .waveform-post-process-box .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text {
  padding-left: 8px;
}

/* .sierra-waveform-result-list .ant-divider-inner-text {
  padding-left: 2px !important;
  padding-right: 30px !important;
} */

.sierra-waveform-result-list .sparameter-channel-result-content .ant-divider {
  padding-left: 0px;
}

.sierra-waveform-result-list .sparameter-channel-result-content .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0px;
}

.sierra-waveform-result-list .sparameter-channel-result-content .sparameter-sweep-result-group .sparameter-result-sweep-group {
  padding-left: 25px;
}

.sierra-waveform-result-list .sparameter-channel-result-content .sparameter-result-checkbox-group>.ant-checkbox {
  top: 0px;
}

.sweep-waveform-pcb-legend {
  margin-left: 21px;
  height: 24px;
}

.sweep-waveform-pcb-legend .sweep-waveform-pcb-legend-color {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  margin-top: -2px;
  vertical-align: middle;
  display: inline-block;
}

.sierra-result-content .sparameter-channel-result-content {
  min-height: calc(100% - 50px);
}

.sparameter-sweep-result-group .checkbox-group-table-box {
  min-width: 160px;
}

.sparameter-result-sweep-group .checkout-group-table-data-content {
  display: flex;
}

.sparameter-sweep-result-group span.checkout-group-table-data-content-span {
  display: inline-block;
  width: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sierra-waveform-result-list .sparameter-result-checkbox-group-left .sparameter-result-checkbox-group-name .checkbox-group-name {
  width: calc(100% - 90px);
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
}

.sierra-waveform-result-list .sparameter-result-checkbox-group-name>label>span {
  display: flex;
  justify-content: left;
}

.sierra-waveform-result-list .sparameter-channel-port-name .sparameter-channel-port-name-checkbox {
  position: absolute;
  left: 4px;
}

.sierra-waveform-result-list .sparameter-channel-display-inline-flex .sparameter-channel-port-name-checkbox {
  left: 0;
  padding-left: 25px;
  z-index: 1000;
  background: #f9f9f9;
}

.sierra-waveform-result-list .sparameter-channel-port-name .sparameter-channel-display-row {
  left: 20px;
  max-width: 220px;
}

.sierra-touchstone-result-main .sparameter-channel-display-inline-flex .sparameter-channel-port-name-checkbox {
  z-index: 1000;
  position: absolute;
  left: 35px;
}

.result-checkbox-group-name .checkbox-group-table-box {
  display: inline-block;
  padding-left: 5px;
  margin-left: 279px;
}

.sparameter-sweep-result-group .checkout-group-table-data-content {
  height: 20px;
  line-height: 20px;
}

.sierra-result-sweep-content-box {
  padding: 20px;
}

.sierra-result-sweep-content-box .sierra-result-content-box {
  padding: 0px;
}

.sierra-result-sweep-content-box .sierra-result-sweep-content-title {
  margin-bottom: 8px;
}

.sierra-trace-capacitance-content {
  min-width: 788px;
  height: 100%;
}

.sierra-result-content .result-parameter-setting-box .result-sp-unit-select .ant-select-selection {
  color: rgba(0, 0, 0, 0.85);
}

.sierra-result-content .result-right {
  min-height: 640px;
  overflow-y: auto;
}

.result-top-menu .result-top-menu-content .sierra-top-menu-right-item {
  float: right;
}

.result-top-menu .result-top-menu-content .sierra-top-menu-right-item .menu-item-title {
  position: relative;
}

.result-top-menu .result-top-menu-content .sierra-top-menu-right-item .sierra-result-top-menu-setting-icon {
  margin: -3px 8px 0px 0px;
  font-size: 16px;
  vertical-align: middle;
}

.result-top-menu .sierra-result-post-processing-progress {
  width: 0px;
  height: 30px;
  background-color: #82c2fd;
  position: absolute;
  overflow: visible;
  top: 0;
  z-index: 1;
  opacity: 0.3;
  left: 0px;
}

.result-top-menu .sierra-result-post-processing-progress-span {
  height: 30px;
  display: block;
  float: right;
  line-height: 30px;
  margin-left: 6px;
}

.sierra-eye-diagram-select-dropdown {
  max-height: 500px;
}

.sierra-eye-diagram-select-dropdown .ant-select-tree li {
  margin: 2px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-tree-select .ant-select-selection__rendered {
  line-height: 24px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-tree-select .ant-select-selection--multiple .ant-select-selection__clear,
.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-tree-select .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}