/** antd 5 add panel css */
.panel-ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, .45);
  filter: alpha(opacity=50);
}

.panel-ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  pointer-events: auto;
}

.panel-ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, .45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color .3s
}

.panel-ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.panel-ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, .65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.panel-ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, .85);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.panel-ant-modal-title:empty {
  height: 44px;
  line-height: 44px;
}

.panel-ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.panel-ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

/** panel css */
.panel-close {
  display: block;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
}

.panel-body {
  padding: 0 !important;
}

.panel-center,
.panel-top-left,
.panel-top-right,
.panel-bottom-left,
.panel-bottom-right,
.panel-center-left,
.panel-center-max,
.panel-center-transform,
.panel-center-top,
.panel-top-right-50,
.panel-center-top-40 {
  position: absolute !important;
}

.panel-center {
  top: 40px;
  left: 50%;
  margin-left: -500px;
}

.panel-center-top {
  top: 80px;
  left: 50%;
  margin-left: -200px;
}

.panel-center-top-40 {
  top: 40px;
  left: 50%;
  margin-left: -200px;
}

.panel-top-left {
  top: 5px;
  left: 5px;
}

.panel-center-left {
  top: 150px;
  left: 50%;
  margin-left: -300px;
}

.panel-top-right {
  top: 5px;
  right: 5px;
}

.panel-top-right-50 {
  top: 50px;
  right: 5px;
}

.panel-bottom-left {
  bottom: 5px;
  left: 5px;
}

.panel-bottom-right {
  bottom: 5px;
  right: 5px;
}

.panel-center-max {
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
}

.panel-center-transform {
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0) !important;
}

.rightBorder {
  position: absolute;
  z-index: 1;
  top: 0;
  right: -2px;
  width: 2px;
  height: 100%;
  cursor: e-resize;
}

.leftBorder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -2px;
  width: 2px;
  height: 100%;
  cursor: e-resize;
}

.bottomBorder {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  cursor: s-resize;
}

.topBorder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  cursor: n-resize;
}

.lowerRightCorner {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  overflow: hidden;
  cursor: se-resize;
}

.lowerLeftCorner {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  overflow: hidden;
  cursor: sw-resize;
}

.upprRightCorner {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5px;
  height: 5px;
  overflow: hidden;
  cursor: ne-resize;
}

.upprLeftCorner {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5px;
  height: 5px;
  overflow: hidden;
  cursor: nw-resize;
}

.hidden {
  display: none;
}

.modal-foot-position {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 52px;
  /* border-top: 1px solid #e8e8e8; */
  box-sizing: border-box;
}

.panel-modal-header {
  padding: 10px 20px !important;
}

.panel-modal-header .ant-modal-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.panel-modal-close-x {
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
}

.panel-modal-minimize-button {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.panel-modal-minimize {
  display: block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.panel-modal-minimize-button:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
  transition: 0.3s;
}

.panel-mask {
  z-index: 1000000 !important;
}

.panel-tooltip {
  z-index: 1000000;
}

.panel-border-color {
  background-color: #999999;
}

.aurora-x-scroll-hidden .rightBorder {
  right: 0px;
}

.aurora-x-scroll-hidden .leftBorder {
  left: 0px;
}

.panel-box-shadow {
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px !important;
}

.panel-graph-maximize {
  color: #1890ff;
  font-size: 18px;
  margin-right: 46px;
  float: right;
  cursor: pointer;
  width: 42px;
  height: 42px;
  line-height: 46px;
}
.aurora-tree-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.aurora-tree-pop-confirm .ant-popover-buttons {
  margin-top: 0px;
  position: relative;
  height: 18px;
}

.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn-primary,
.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn {
  height: 18px;
  font-size: 12px;
  padding: 0px 5px;
  line-height: 16px;
  color: #1890ff;
  background-color: #fff;
  border-color: #1890ff;
  position: absolute;
}


.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn-primary {
  left: 20px;
  top: 0px;
}

.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn {
  right: 20px;
  top: 0px;
}

.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn-primary:hover,
.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn:hover {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}

.aurora-tree-pop-confirm .ant-popover-inner-content {
  padding: 6px 8px;
  width: 300px;
}

.aurora-tree-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.aurora-tree-pop-confirm .ant-popover-message-title {
  padding-left: 0;
  display: block;
  font-size: 14px;
  padding: 10px 0px;
}

.aurora-tree-confirm-title {
  text-align: center;
}

.aurora-tree-confirm-warning-png {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.aurora-tree-confirm-title-span {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 500;
}

/* === confirm panel ===  */

.aurora-confirm-panel {
  top: 200px;
}

.aurora-confirm-mask-panel {
  z-index: 100000000 !important;
}

.aurora-confirm-panel .ant-modal-header,
.aurora-confirm-panel .ant-modal-close {
  display: none;
}

.aurora-confirm-panel .rightBorder,
.aurora-confirm-panel .bottomBorder,
.aurora-confirm-panel .lowerRightCorner {
  cursor: unset;
}

.aurora-confirm-main {
  padding: 10px;
}

.aurora-confirm-title {
  text-align: center;
}

.aurora-confirm-warning-png {
  width: 24px;
  height: 24px;
  /*  margin-right: 8px; */
}

.aurora-confirm-title-span {
  font-size: 16px;
  vertical-align: middle;
  font-weight: 500;
}

.aurora-confirm-title {
  margin: 10px 0px;
}

.aurora-confirm-content {
  padding: 10px 8px;
  text-align: center;
}

.aurora-confirm-button {
  padding: 0px 10px;
  height: 26px;
  margin: 10px 0px;
}

.aurora-confirm-ok-button,
.aurora-confirm-cancel-button {
  height: 26px;
  font-size: 14px;
  padding: 0px 8px;
  line-height: 26px;
  border: none;
  width: 50%;
  box-shadow: unset;
}

.aurora-confirm-button>.ant-btn.aurora-confirm-ok-button:hover,
.aurora-confirm-button>.ant-btn.aurora-confirm-cancel-button:hover {
  color: #6f6f6f;
  background-color: #eee;
}

.aurora-confirm-cancel-none {
  display: none;
}

.aurora-confirm-Tips-icon {
  font-size: 22px;
}

.aurora-confirm-close-icon {
  font-size: 15px;
  float: right;
  color: rgba(0, 0, 0, 0.45);
}
.transparent-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100000000;
}
 .aurora-tags-input-content {
   display: inline-block;
   width: calc(100% - 120px);
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   vertical-align: middle;
   padding: 0px 3px;
   cursor: pointer;
   background-color: #ffffff;
   position: relative;
   box-sizing: border-box;
   min-height: 28px;
 }

 .aurora-tags-input-content .tags-input-list-ul {
   overflow: hidden;
   padding: 0;
   margin: 0;
   min-height: 26px;
   line-height: 26px;
 }

 .aurora-tags-input-content .tags-input-tag {
   cursor: pointer;
   margin-top: 1.5px;
   float: left;
   line-height: 21px;
 }

 .aurora-tags-input-content .tags-input-tag .ant-tag .anticon-close {
   font-size: 11px;
 }

 .aurora-tags-input-content .tags-input-tag-input-li {
   width: auto;
   float: left;
   height: 26px;
 }

 .aurora-tags-input-content .tags-input-tag-input {
   border: none;
   height: 26px;
   position: static;
   padding: 0;
   width: .75em;
   max-width: 100%;
   float: left;
 }

 .aurora-tags-input-content .tags-input-tag-input .ant-input-group,
 .aurora-tags-input-content .tags-input-tag-input .ant-input-group .ant-input {
   height: 23px;
   font-size: 12px;
   margin-top: 1px;
 }

 .tags-input-tag-input:hover,
 .tags-input-tag-input:focus {
   border-right-width: 0px !important;
   border-width: 0px;
   box-shadow: 0px 0px 0px #ffffff;
   border-color: #fff;
 }

 .tags-input-list-ul>span:not(.anticon) {
   position: absolute;
   left: 11px;
   right: 11px;
   width: calc(100% - 22px);
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-size: 12px;
 }

 .tab-input-disabled,
 .tab-input-disabled .tags-input-tag-input {
   cursor: not-allowed;
   background-color: #f5f5f5;
   color: rgba(0, 0, 0, 0.25);
 }

 .aurora-tag-input-after-unit-content {
   display: inline-block;
   width: 46px;
   border: 1px solid #d9d9d9;
   border-radius: 0px 4px 4px 0px;
   vertical-align: middle;
   border-left: none;
   padding: 0px 4px;
   cursor: pointer;
   background-color: #fafafa;
   position: relative;
   box-sizing: border-box;
   min-height: 28px;
   text-align: center;
   line-height: 26px;
   font-size: 12px;
 }

 .aurora-tags-input-unit-content {
   border-radius: 4px 0px 0px 4px;
 }

 .tags-input-list-ul>.tags-input-list-placeholder {
   color: rgba(0, 0, 0, 0.25);
   font-weight: 500;
   font-size: 14px;
 }
body {
  margin: 0;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  font-feature-settings: "tnum", "tnum", "tnum";
}

.body {
  font-family: "Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
}

.aurora-layout {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.aurora-sider {
  position: relative;
  width: 240px;
  background: #f9f9f9;
  height: 100%;
  border-right: 1px solid #e2dfdf;
}

.aurora-sider-menu {
  margin: 12px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.aurora-sider-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.aurora-select-dropdown {
  z-index: 10000000;
}

.aurora-select-dropdown .ant-select-dropdown-menu-item {
  /* padding: 4px 11px; */
  font-size: 13px;
  line-height: 24px;
}

.aurora-select-dropdown.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode {
  padding-bottom: 4px;
  margin-bottom: 0px;
}

.aurora-tooltip-span {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.aurora-project-create-name {
  width: 100%;
}

.aurora-project-create-name .ant-input-group {
  float: left;
  width: 100%;
}

.aurora-project-name-input {
  width: 100%;
  height: 24px;
}

.aurora-project-name-input .ant-input-group-addon {
  width: 50px;
  padding: 0 5px;
}

.aurora-project-name-input .ant-input {
  height: 24px;
}

.aurora-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.aurora-row-background {
  background-color: #edf7ff;
}

.aurora-record-background,
.aurora-record-background .editable-cell-value-wrap.ant-table-cell-fix-left,
.aurora-record-background .editable-cell-value-wrap.ant-table-cell-fix-right {
  background-color: #dcecff;
}

.aurora-record-background .editable-cell-value-wrap {
  border-color: #dcecff;
}

.aurora-model-name-error-msg {
  display: block;
  padding: 6px 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.aurora-model-name-error-msg .aurora-model-error-item,
.aurora-model-name-warning-msg .aurora-model-warning-item {
  line-height: 26px;
  height: 26px;
  margin-left: 10px;
}

.aurora-model-name-warning-msg {
  display: block;
  padding: 6px 10px;
  color: #fa8c16;
  background: #fff7e6;
  border-radius: 8px;
  border: 1px solid #ffd591;
}

.aurora-success-msg {
  display: block;
  padding: 6px 10px;
  color: #1b8409;
  background: #f6ffed;
  border-radius: 8px;
  border: 1px solid #b7eb8f;
}

.aurora-table-comp {
  width: 100%;
}

.aurora-placeHolder {
  color: #bfbfbf;
}

.aurora-cursor {
  cursor: pointer;
}

.aurora-disabled-color {
  color: #949494 !important;
}

.aurora-cursor-disabled {
  cursor: not-allowed !important;
}

.panel-x-scroll-hidden {
  overflow-x: hidden !important;
}

.panel-x-scroll-hidden .rightBorder {
  right: 0px;
}

.panel-x-scroll-hidden .leftBorder {
  left: 0px;
}

.aurora-table-tr-light {
  border: 1px solid #40a9ff;
  box-shadow: 0px 0px 4px 1px #8fcafb;
  position: absolute;
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 4px;
}

.aurora-file-check-div {
  color: #f30518;
  position: relative;
}

.aurora-file-text {
  color: rgba(0, 0, 0, 0.65);
}

.aurora-file-check-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -9px;
  color: #f13140;
  font-size: 18px;
}

.aurora-layout-split-screen {
  /* background-color: #c0c0c0; */
  z-index: 1000;
}

.aurora-layout-split-screen+.dividingLine-border {
  display: block;
}

.no-scroll .ant-table-container .aurora-table-body {
  overflow-x: hidden !important;
}

.aurora-table-row-disabled {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f3f3f3;
  cursor: not-allowed;
}

.aurora-extraction-tabs.ant-tabs-card {
  margin-top: 10px;
}

.aurora-extraction-tabs.ant-tabs-card .ant-tabs-nav {
  height: 30px;
  margin-bottom: 0px;
}

.aurora-extraction-tabs.ant-tabs-card .ant-tabs-content {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-top: 0;
  padding: 10px;
  border-radius: 4px;
}

.aurora-extraction-tabs .ant-tabs-nav-list .ant-tabs-tab {
  margin-right: 4px;
  height: 32px;
  padding: 0 16px;
  line-height: 30px;
  background-color: #dedede;
}

.aurora-extraction-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  color: #1890ff;
  font-weight: 500;
  height: 32px;
  line-height: 30px;
  border-radius: 5px 5px 0px 0px;
  background-color: #ffffff;
}

.aurora-extraction-tabs .ant-tabs-ink-bar-animated {
  display: none !important;
}

.aurora-top-bar-vertical-line {
  height: 16px;
  background-color: #c7c7c7;
  width: 1px;
  margin-right: 28px;
  display: inline-block;
}

.system-library-tooltip {
  width: 350px;
  max-width: 350px;
}

.text-popup {
  animation: textPopup 1s both;
  transform: translateY(-20px);
  color: #01cf97;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
  position: absolute;
  z-index: 999999999999999;
}

@keyframes textPopup {

  0%,
  100% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  100% {
    transform: translateY(-50px);
  }
}

.aurora-top-bar-stackup-icon {
  font-size: 14px;
  font-weight: 600;
  margin-top: 1px;
  margin-right: 8px;
}

.aurora-top-bar-advanced-icon {
  font-size: 20px;
  font-weight: 500;
  margin-right: 8px;
  vertical-align: middle;
}

.aurora-setup-title-color {
  color: #0b3694;
}

.layout-canvas-input,
.layout-canvas-select {
  transform: rotate(-180deg) scale(-1, 1);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
}

.layout-canvas-select {
  padding-left: 6px;
}

.layout-canvas-select:focus,
.layout-canvas-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 2px 2px rgba(24, 144, 255, 0.2);
  outline: 0;
}

.layout-canvas-include-unit-input {
  border-radius: 4px 0px 0px 4px;
}

.layout-canvas-include-unit-select {
  border-radius: 0px 4px 4px 0px;
  border-left: none;
}

.canvas-foreignObject {
  padding: 2px;
  position: relative;
}

/* .layout-canvas-selection-option {
  background-color: #ffffff !important;
  height: 30px;
  line-height: 30px;
}

.layout-canvas-selection-option:hover {
  background-color: #0d87f7;
} */

.ant-color-picker-trigger.aurora-color-picker-small {
  padding: 2px;
  min-width: 12px;
  min-height: 12px;
  margin-right: 5px;
  vertical-align: middle;
}

.ant-color-picker-trigger.aurora-color-picker-small .ant-color-picker-color-block {
  width: 12px;
  height: 12px;
}

.canvas-not-display-pin {
  display: none;
}
.header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 54px;
  line-height: 54px;
  background: #001529;
  padding: 0 50px
}

.header .header-menu {
  float: right;
  padding: 0px;
  margin: 0px;
}

.header .header-menu li {
  float: left;
  font-size: 16px;
  margin-right: 40px;
}


.header .header-menu li .dld_login {
  display: none;
  color: rgba(255, 255, 255);
}

@media only screen and (max-width: 1050px) {
  .dld_login {
    display: inline-block !important;
    color: #ffffff !important;
    cursor: pointer;
    line-height: 60px;
    height: 54px;
  }

  .dld_login:hover {
    opacity: 1;
  }

  .dld {
    display: none;
  }

  .header-has-download-menu .aurora-menu-icon {
    display: inline-block;
    color: #ffffff;
    cursor: pointer;
    opacity: 0.7;
    line-height: 60px;
    height: 54px;
  }

  .header-has-download-menu .aurora-menu-icon:hover {
    opacity: 1;
  }

  .header-has-download-menu .aurora-menu-a {
    display: none;
  }
}


.header .header-menu li .dld {
  color: rgba(255, 255, 255);
}

@media (max-width: 1024px) {
  .header .header-menu li {
    margin-right: 20px;
  }
}

.header .header-menu li span {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.header .header-menu li span:hover {
  color: #fff;
}

.logo {
  width: 142px;
  height: 54px;
  float: left;
  line-height: 54px;
  opacity: 0.8;
}

.logo img {
  height: 40px;
  margin-right: 10px;
  /* margin-bottom: 14px; */
  margin-bottom: 8px;
}

.logo h1 {
  color: #fff;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 21px;
  /*  font-size: 30px; */
  /* font-weight: bold;
  line-height: 60px;
  background-image: linear-gradient(to right, yellow, #f070ff, #00ffa1);
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.logo:hover {
  opacity: 1;
}

.account {
  float: right;
  color: #fff;
  opacity: 0.7;
  cursor: pointer;
}

.account:hover {
  opacity: 1;
}

.account .avatar {
  margin-right: 10px;
  background-color: #ccc;
}

img.header-lcfc-icon {
  height: 54px;
  padding: 13px 10px 13px 0;
  float: left;
}

.lcfc-account .avatar {
  background: white;
}

.lcfc-account .ant-avatar>img {
  width: 80%;
  height: 80%;
}

.header-lcfc-Separator {
  height: 18px;
  margin: 18px 10px 0px 0;
  float: left;
  border-right: 1px solid #767676;
}

.account .download {

  margin-top: 4px;
  margin-right: 43px;
  color: #fff;

}

.ant-avatar-download-Icon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

@media only screen and (max-width:530px) {
  .account .download {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0px;
    margin-right: 117px;
  }

  .account .download-Icon {
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    top: 0;
    right: 0;
    margin-top: 13px;
    margin-right: 186px;
  }
}

.account a {
  color: #fff;
}

.account a:active {
  color: #fff;
}

.account a:link {
  text-decoration: none;
}

.AccountMenuItem .ant-dropdown-menu-item {
  height: 32px;
  line-height: 32px;
  padding: 0px;
}

.AccountMenuItem span {
  display: inline-block;
}

.log-in-btn {
  display: inline-block;
}

.ant-layout .develop-panel {
  transform: translate3d(75%, 120px, 0px);
}

.ant-layout .account-panel {
  transform: translate3d(75%, 120px, 0px);
}

.development-name {
  font-size: 16px;
  font-weight: bold;
}

.develop-content {
  margin: 20px;
}

.develop-content-item,
.debug-content-item {
  width: 100%;
  margin-bottom: 12px;
}

.debug-content-title {
  font-size: 14px;
  font-weight: bold;
  vertical-align: top;
}

.debug-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
}

.develop-content-radio {
  font-size: 14px;
}

.develop-content-render {
  margin: 10px;
}

.account-password .account-password-item-1 {
  margin: 6px 0px 0px;
}

.account-password-title {
  font-size: 14px;
  font-weight: bold;
}

.debug-switch {
  margin-left: 8px;
}

.debug-content {
  display: inline-block;
  margin: 8px 10px 10px 0;
}

.setting-version {
  float: left;
  height: 32px;
  line-height: 32px;
}

.header-product-version {
  float: left;
  color: #fff;
  opacity: 0.7;
  line-height: 54px;
  font-size: 14px;
  height: 54px;
}

.translation-switch,
.develop-switch {
  display: inline-block;
  margin: 0px 10px;
}

.translation-content-title,
.develop-content-title,
.user-default-setting-title {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}

.user-setup-couplingMinFreqValue {
  margin-left: 20px;
}

.translation-content-title-cursor {
  cursor: pointer;
}

.translation-content-input {
  width: 200px;
  margin-left: 20px;
}

.translation-content-input input {
  height: 28px;
}

.translation-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}


/* .logo-name-aurora {
  font-family: 'IndieFlower';
} */

.header-product-name {
  font-family: 'IndieFlower';
  font-weight: bold;
  font-size: 22px;
  margin: 0px 20px 0 110px;
  display: block;
  line-height: 54px;
  height: 54px;
  padding-top: 3px;
  float: left;
}

@media only screen and (max-width: 890px) {
  .header-product-name {
    margin: 0px 20px 0 20px;
  }
}


@media only screen and (max-width: 930px) {
  .header-version {
    display: none;
  }
}

@media only screen and (max-width: 560px) {
  .ant-layout-header {
    padding: 0 30px;
  }
}

.aurora-menu {
  display: inline-block;
}

.aurora-menu-icon {
  display: none;
}

@media only screen and (max-width: 610px) {
  .aurora-menu-icon {
    display: inline-block;
    color: #ffffff;
    cursor: pointer;
    opacity: 0.7;
    line-height: 60px;
    height: 54px;
  }

  .aurora-menu-icon:hover {
    opacity: 1;
  }

  .aurora-menu-a {
    display: none;
  }
}

.user-setup-panel-advanced {
  font-weight: normal;
  margin-left: 6px;
}

.user-setup-panel-advanced-icon {
  font-size: 12px;
}

.user-setup-panel-advanced-box {
  display: flex;
  line-height: 28px;
}

/* email setting */
.email-setting-content {
  margin-bottom: 12px;
  width: 100%;
}

.email-setting-content .email-setting-item {
  margin: 6px 0px;
  display: flex;
  margin-left: 20px;
}

.email-setting-item .email-setting-label {
  display: inline-block;
  width: 70px;
  line-height: 28px;
}

.email-setting-item .aurora-tags-input-content {
  padding: 0px 5px;
}

.email-setting-input,
.email-setting-select {
  width: calc(100% - 80px);
  font-size: 12px;
}

.email-setting-content .email-setting-notification {
  margin-left: 0px;
}

.email-setting-content .email-setting-notification .email-setting-label {
  width: 130px;
  font-weight: bold;
}

.email-setting-content .email-setting-notification>div {
  line-height: 26px;
}

.user-default-setting-title {
  margin-left: 5px;
  cursor: pointer;
}

.user-default-setting-item {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  width: 190px;
}

.user-default-setting-content .user-default-setting-title-item {
  display: inline-block;
  width: 280px;
}

.user-default-setting-content {
  padding-left: 20px;
}

.license-time {
  font-weight: normal;
  color: #767676;
  font-size: 12px;
  display: inline-block;
  padding-left: 10px;
}

.license-time-icon {
  margin-left: 5px;
  color: #1890ff;
}

.user-default-setting-div {
  margin: 10px 0px;
}

.level-setting-input {
  width: 200px;
}

.user-default-setting-ports-content {
  margin-left: 12px;
}

.user-default-setting-ports-content .signal-port-edit-item>label {
  width: 180px;
}
.project-menu {
  top: 58px !important;
  margin: 0 10px;
}
.virtual-list-menu {
  position: absolute;
  height: 200px;
  width: 100%;
  overflow: auto;
  z-index: 9999;
  background-color: #fff;
  overflow-anchor: none;
}

.menu-no-result {
  padding-left: 15px;
  font-weight: 700;
  position: absolute;
  height: 30px;
  width: 100%;
  margin-top: 5px;
  z-index: 9999;
  line-height: 28px;
}

.menu-item-check {
  margin-right: 6px;
  visibility: hidden;
}

.virtual-list-menu .menu-list {
  margin: 2px;
  white-space: normal;
  padding-inline-start: 0px
}

.virtual-list-menu .menu-list li {
  padding: 3px 2px;
  width: 100%;
  white-space: nowrap;
  color: rgba(0, 0, 0, .85)
}

.virtual-list-menu .menu-list li:hover {
  background: #E6F7FF;
}

.virtual-list-menu .menu-list li:hover .menu-item-check {
  visibility: visible;
}

.menu-selected-item {
  background: #fafafa;
  font-weight: 600;
}

.menu-selected-item .menu-item-check {
  visibility: visible;
  color: #1890FF;
}

.menu-selecting-item {
  background: #d3eaff;
}

.menu-selecting-item .menu-item-check {
  visibility: hidden;
}

.aurora-virtual-list {
  z-index: 9999;
  padding: 0px;
  overflow: visible;
}

.virtual-list-select-show {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 32px;
  background: #fbfbfb;
  color: #188fff;
  cursor: pointer;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 5px 7px 2px rgba(0, 0, 0, 0.15);
  z-index: 100000;
  font-weight: 800;
}

.menu-selecting-item-error {
  background: #fff;
  color: rgba(0, 0, 0, .25);
  cursor: not-allowed !important;
}

.virtual-list-menu .menu-list li.menu-selecting-item-error:hover {
  color: rgba(0, 0, 0, .25);
  cursor: not-allowed !important;
}

.virtual-list-menu .menu-list li.menu-selecting-item-error:hover .menu-item-check {
  visibility: hidden;
}

.vlrtual-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px; 
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  line-height: 28px;
  margin: -4px;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  width: 100%;
}

.editTable-select.ant-select-multiple .ant-select-selector {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.aurora-table .ant-table-row:hover>td,
.aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap {
  background-color: #e6f7ff;
}

.aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap,
.aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap.ant-table-cell {
  border-color: #d9d9d9
}

.aurora-table table td .ant-form-item .ant-form-item-control-input {
  min-height: 20px;
}

.edit-form-item {
  margin: 0 !important;
}

.edit-form-item .ant-form-item-control {
  line-height: 1;
}

.select-width {
  width: 100%;
}

.select-width .ant-select-selection {
  height: 30px;
  line-height: 28px;
  margin: -4px;
  padding: 0 4px;
  box-sizing: border-box;
}

.select-width .ant-select-selection .ant-select-selector {
  line-height: 28px;
  margin: 0px;
}

.editable-input {
  width: 100%;
}

.editTable-select.ant-select-multiple .ant-select-selector {
  min-height: 30px;
  height: auto;
}

.editTable-select.ant-select-multiple .ant-select-selector li {
  height: 22px;
  line-height: 20px;
}

.aurora-option-label-icon {
  color: #a0a0a0;
  cursor: pointer;
  margin-right: 0px;
  margin-left: 7px;
  font-size: 10px;
}

.input-select-after {
  min-width: 58px;
  z-index: 2000;
}

.aurora-table-tree-select .aurora-tree-select {
  min-height: 28px;
  height: auto;
  width: 100%;
}

.aurora-table-select-selected {
  background: rgb(231, 231, 231);
}

.aurora-table .ant-table {
  border: 1px solid #e8e8e8;
  border-right: 0px;
}

.aurora-padding-table .ant-table {
  border-right: 1px solid #e8e8e8;
}

.aurora-padding-table .ant-table.ant-table.ant-table-bordered .ant-table-header {
  margin: 0px 20px;
}

.aurora-padding-table .ant-table.ant-table.ant-table-bordered .ant-table-thead .ant-table-cell-scrollbar {
  border-right: 0px;
}

.aurora-padding-table .ant-table.ant-table.ant-table-bordered .aurora-table-body {
  margin: 0px 20px;
}

.aurora-table .ant-table.ant-table-bordered>.ant-table-container {
  border: 0px;
}

.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-thead>tr>th {
  border-bottom: 1px solid #e8e8e8;
  background-color: initial;
}

.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-thead>tr>th:not(:last-child)::before {
  content: none;
}

.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr:last-child:td {
  border-bottom: 0px;
}

.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-thead>tr>th,
.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr>td {
  border-right: 1px solid #e8e8e8;
}

.aurora-padding-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-thead>tr>th:last-child,
.aurora-padding-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr>td:last-child {
  border-right: 0px;
}

.aurora-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  padding: 0px 5px;
}

.aurora-table .aurora-table-body .ant-table-row>td {
  position: relative;
}

.aurora-table .ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow-y: auto;
  white-space: unset;
  min-height: 30px;
  position: unset;
  height: auto;
}

.aurora-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap,
.aurora-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap>div {
  height: auto;
  min-height: 28px;
}

.aurora-expanded-table .ant-table {
  margin-block: 0px !important;
}

.editTable-auto-complete {
  width: 100%;
}

.editTable-auto-complete-dropdown {
  z-index: 1000000;
}

.aurora-table .input-with-select .ant-select .ant-select-selector .ant-select-selection-item {
  padding-inline-end: 2px;
}
.login-form {
  max-width: 300px;
}

.login-form-button {
  width: 100%;
}
.login {
  width: 300px;
  top: 180px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.load {
  position: absolute;
  top: 54px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  min-width: 560px;
  overflow: auto;
}

.download-content {
  position: relative;
  width: 832px;
  margin: 15px auto;
}

.download-content .installer {
  margin-top: 20px;
}

.download-content .service h2,
.installer h2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-remark {
  font-size: 12px;
  margin-left: 5px;
  color: #1282d7;
}

.download-content .download-content-main {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.download-content>h2 {
  position: absolute;
  top: -25px;
  left: 200px;
}

.load .download-window,
.load .download-linux {
  overflow: hidden;
  position: relative;
  width: 366px;
  height: 180px;
  line-height: 15px;
  margin-bottom: 18px;
  color: #000000;
  border-radius: 10px;
  background: #e6e6e6;
}

.load .download-window {
  margin-right: 50px;
}

.load .download-linux {
  margin-left: 50px;
}

.load .download-guide {
  width: 100%;
  height: 50px;
  line-height: 15px;
  color: #000000;
  border-radius: 10px;
  background: #e6e6e6;
  margin-top: 20px;
  background: transparent;
}

.load .download-header {
  position: relative;
  height: 140px;
}

.load .download-content img {
  position: absolute;
  top: 4px;
  left: 50%;
  margin-left: -38px;
  width: 76px;
  font-size: 40px;
  height: 76px;
  color: #1282d7;
}

.load .version-note-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.load .show-old-version:last-child .show-note-list div {
  border-bottom: none;
}

.load .show-note-list ul {
  max-width: 750px;
  margin-left: 60px;
}

.load .show-note-list ul li {
  padding-top: 3px;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 5px;
  list-style-type: disc !important;
}

.load .download-guide h2 {
  margin-left: 10px;
  margin-left: 0;
  font-size: 20px;
  color: #1282d7;
}

.load .download-guide .download-box {
  position: relative;
  top: 5px;
  display: flex;
  margin-left: 0;
  left: 0
}

.load .download-guide .download-box button {
  margin: 0 0 0 -14px;
}

.download-box {
  position: absolute;
  width: 270px;
  height: 42px;
  left: 50%;
  top: 76px;
  margin-left: -135px;
  border-radius: 4px;
}

.aurora-download-button {
  display: block;
  font-size: 16px;
  border: none;
  margin: 6px auto 0;
  color: #1282d7;
  background-color: transparent;
}

.a-link {
  color: #1282d7;
}

.aurora-download-button:hover {
  color: #23527c;
  background-color: transparent;
}

.aurora-download-button h2:hover {
  color: #23527c;
  background-color: transparent;
}

.aurora-download-button:active {
  color: #1282d7;
  background-color: transparent;
}

.aurora-download-button:focus {
  color: #1282d7;
  background-color: transparent;
}

.download-box-guide-icon {
  height: 32px;
  line-height: 32px;
  color: #1282d7;
  font-size: 18px;
  margin: -2px 0 0 25px;
}

.download-box-guide-icon:hover {
  color: #23527c;
}

button.ant-btn.aurora-download-button.aurora-download-version-button.ant-btn-primary {
  height: 52px;
}

.aurora-download-version-button-single {
  height: 32px;
  display: block;
  font-size: 16px;
  border: none;
  margin: 6px auto 0;
  color: #1282d7;
  background-color: transparent;
}
.aurora-download-version-button-second{
  height: 26px;
  display: block;
  font-size: 16px;
  border: none;
  margin: 6px auto 0;
  color: #1282d7;
  background-color: transparent;
}

.aurora-download-version-button:hover {
  background-color: #bae4f1;
}

.aurora-download-version-button-single:hover {
  background-color: #bae4f1;
}
.aurora-download-version-button-second:hover {
  background-color: #bae4f1;
}

button.ant-btn.aurora-download-button.aurora-download-version-button.ant-btn-primary>div {
  font-size: 14px;
}

.load .download-guide .download-box .aurora-download-icon {
  margin-top: 2px;
}

.note {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.load .download-content .show-note-list {
  padding: 0 0 30px;
}

.show-old-version {
  margin-bottom: 40px;
}

.show-old-version .title {
  display: flex;
  justify-content: flex-start;
  font-size: 23px;
  font-weight: bold;
}

.show-old-version .old-version h2 {
  display: inline-block;
  margin-right: 3px;
}

.show-old-version .old-version {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.show-old-version .old-version .documentation {
  font-size: 18px;
  color: #1282d7;
  cursor: pointer;
}

.show-old-version .old-version .documentation:hover {
  color: #23527c;
}

.show-old-version .installer-service-select {
  font-size: 12px;
}

.show-old-version .old-version span {
  margin-left: 5px;
  font-size: 17px;
}

.show-old-version .old-version .span-content {
  color: #1282d7;
}

.show-old-version .old-version .span-content:hover {
  color: #23527c;
  cursor: pointer;
}

@media (max-width:947px) {
  .download-content {
    width: 100%;
  }

  .load .download-window {
    margin-left: 50px;
  }

  .load .download-linux {
    margin-right: 50px;
  }

  .load .download-guide .download-box {
    width: 500px;
  }
}

@media (max-width:657px) {
  .load {
    padding-bottom: 40px;
  }

  .load .show-note-list ul {
    margin-left: 10px;
    width: 570px;
  }

  .load .download-content {
    overflow-x: scroll;
  }
}
@font-face {
  font-family: "IndieFlower";
  src: url("/fonts/IndieFlower-Regular.ttf")format("truetype")
}

@font-face {
  font-family: "PingFang";
  src: url("/fonts/PingFang-SC-Regular.ttf")format("truetype")
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.aurora-home-page {
  position: relative;
  background-color: #f4f4f4;
  margin-top: 63px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.aurora-home-page-en {
  position: absolute;
  top: 54px;
  bottom: 0;
  width: 100%;
}

.aurora-home-page-en>.home-banner,
.aurora-home-page-en>.home-banner>.overlay {
  height: 100%;
}

.overlay {
  position: absolute;
  background-color: #212529;
  height: 550px;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.home-banner h1,
.home-banner h2,
.home-banner h3 {
  color: #ffffff;
}

.home-banner h1 {
  font-size: 48px;
  margin-left: 20%;
  top: 130px;
}

.home-banner h2,
.home-banner .home-banner-comming {
  font-size: 32px;
  margin-left: 20%;
  top: 230px;
  opacity: 0.8;
}

.home-banner .home-banner-comming {
  top: 350px;
}

.home-banner {
  width: 100%;
  height: 550px;
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  /* display: flex;
  justify-content: center;
  vertical-align: middle; */
  display: relative;
}

.home-banner-background {
  background: url("/lib/imgs/aurora-bg.png") no-repeat center center;
  background-size: cover;
}

.home-banner-lcfc {
  background: url("/lib/imgs/lcfc_bg.png") no-repeat center center;
  background-size: cover;
}

.aurora-home-page-en>.home-banner-lcfc>.overlay {
  opacity: 0;
}

.home-footer {
  text-align: center;
  width: 100%;
}

.home-banner-title,
.home-banner-content,
.home-banner-comming {
  position: absolute;
  z-index: 100;
}

.home-content-2 {
  background: #ffffff;
}

.home-content-description {
  padding: 32px;
}

.comming-soon {
  position: relative;
  width: 100%;
  background: url("/lib/imgs/aurora-bg.png") no-repeat center center;
  background-size: cover;
  text-align: center;
  height: 300px;
}

.comming-soon h3 {
  position: absolute;
  font-size: 38px;
  color: #ffffff;
  width: 100%;
  top: 112px;
  text-align: center;
}

.overlay-comming {
  position: absolute;
  background-color: #212529;
  height: 300px;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.home-footer {
  text-align: center;
  padding: 64px 0;
}

.home-footer>ul {
  width: 100px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
}

.home-footer>ul>li {
  float: left;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
}

.home-footer p {
  margin-bottom: 0px;
}

.home-page-about {
  margin-top: 64px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.home-page-about {
  background: #ffffff;
  padding: 50px;
}

.home-page-about h1 {
  font-size: 42px;
}

.home-page-about p {
  font-size: 16px;
}

.homt-about-team {
  width: 350px;
}

.home-page-about h2 {
  font-size: 32px;
}

.homt-about-team img {
  margin: 0 auto;
}

.home-icon {
  font-size: 70px;
  color: #1890ff;
}

.home-content-1 {
  text-align: center;
  margin: 112px auto;
}

.home-content-1 h3,
.home-content-2 h3 {
  font-size: 28px;
  color: #212529;
}

.home-content-2 h3 {
  text-align: left;
}

.home-content-1 h3 {
  text-align: center;
  margin-top: 14px;
}

.home-content-1 p,
.home-content-2 p {
  color: #212529;
  font-size: 18px;
}

.sierra-img {
  width: 300px;
  transition: 0.3s;
  border-radius: 20px;
  padding: 10px 15px;
}

.sierra-img img {
  width: 100%;
  opacity: 0.8;
}

.sierra-img:hover,
.pdn-img:hover,
.sierra-name:hover,
.pdn-name:hover,
.home-page-name:hover,
.rocky-name:hover,
.andes-v2-name:hover,
.andes-v2-logo:hover,
.andes-img:hover,
.fastpi-name:hover,
.cascade-name:hover,
.himalayas-logo:hover,
.himalayas-name:hover {
  background: #ffffff10;
  transition: 0.3s;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 4px 2px, rgba(255, 255, 255, 0.2) 0px 0px 2px 2px;
}

.sierra-img:hover img {
  opacity: 1;
}

.pdn-img {
  width: 300px;
  transition: 0.3s;
  border-radius: 20px;
  padding: 10px 15px;
}

.pdn-img img {
  width: 100%;
  opacity: 0.8;
}

.pdn-img:hover img {
  opacity: 1;
}

.sierra-name {
  position: absolute;
  left: 50%;
  width: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.3s;
  border-radius: 20px;
}

.sierra-name img {
  width: 100%;
  opacity: 0.8;
}

.sierra-name:hover img {
  opacity: 1;
}

.pdn-name {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.3s;
  border-radius: 20px;
}

.pdn-name img {
  width: 100%;
  opacity: 0.8;
}

.pdn-name:hover img {
  opacity: 1;
}

.aurora-home {
  padding-top: 20px;
  margin-left: 90px;
  position: relative;
  /* width: 300px; */
}

.aurora-home-page-en .aurora-home-flex {
  flex-wrap: wrap;
  display: flex;
}

.aurora-home-page-en .aurora-home-flex .home-page {
  width: 33.33%;
}

.aurora-home-page-en .aurora-home-flex .home-page-name {
  margin: 0px 16px;
}

/* .aurora-home .rocky-logo {
  width: 214px;
}

.aurora-home .andes-logo {
  width: 245px;
}

.aurora-home .cascade-logo {
  width: 312px;
} */

.aliyunHome {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
}

.aliyunHome-beianhao {
  display: inline-block;
  text-decoration: none;
  height: 40px;
  line-height: 40px;
  color: #939393;
}

.aliyunHome-beianhao-1 {
  display: inline-block;
  text-decoration: underline;
  height: 40px;
  line-height: 40px;
  color: #939393;
  margin-left: 10px;
}

.home-page-name {
  transition: 0.3s;
  border-radius: 20px;
  height: 80px;
  padding: 10px 22px 10px 15px;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

.home-page-name div {
  font-family: 'IndieFlower';
  font-size: 70px;
  transition: 0.3s;
  line-height: 80px;
  height: 60px;
  color: #ed7d30;
  opacity: 0.8;
}

.home-page-name img,
.rocky-name img {
  width: 100%;
  opacity: 0.8;
}

.home-page-name:hover img,
.home-page-name:hover div,
.rocky-name:hover img {
  opacity: 1;
}

.rocky-name,
.andes-v2-home,
.fastpi-name,
.cascade-name,
.himalayas-name {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.3s;
  border-radius: 20px;
  text-align: center;
  padding: 0px;
  cursor: pointer;
}

.fastpi-name {
  width: 54%;
  max-width: 988px;
  min-width: 340px;
}

.cascade-name {
  width: 66%;
  max-width: 1230px;
  min-width: 340px;
}

.rocky-name {
  width: 44%;
  max-width: 850px;
  text-align: center;
  padding: 0px;
}

.andes-v2-name {
  width: 44%;
  max-width: 850px;
  text-align: center;
  padding: 0px;
}

.himalayas-name {
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  text-align: center;
  padding: 0px;
}

.andes-img,
.andes-v2-logo {
  margin-top: 40px;
  width: 354px;
  transition: 0.3s;
  border-radius: 20px;
  padding: 10px 15px;
}

.andes-img img,
.andes-name img {
  width: 100%;
  opacity: 0.8;
}

.andes-img:hover img,
.andes-name:hover img {
  opacity: 1;
}

.andes-name {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.3s;
  border-radius: 20px;
}


.home-page-img-div,
.home-page-img-div-fastpi {
  font-family: 'IndieFlower';
  transition: 0.3s;
  border-radius: 20px;
  padding: 10px 15px;
  height: 250px;
  line-height: 274px;
  color: #ed7d30;
  opacity: 0.8;
  font-size: 300px;
  transition: font-size 0.2s ease-out;
}

.home-page-img-div-fastpi {
  height: 280px;
  line-height: 330px;
  padding-left: 25px;
}

.rocky-name:hover div,
.andes-v2-name:hover div,
.fastpi-name:hover div,
.cascade-name:hover div {
  opacity: 1;
}

.home-page {
  position: relative;
  margin-top: 30px;
}

.home-description {
  position: absolute;
  color: #eee4b4;
  font-size: 19px;
  width: 800px;
  left: 318px;
  /*  top: 26px; */
  top: 0px;
  height: 80px;
  line-height: 80px;
}

.home-description>span {
  display: inline-block;
  line-height: 20px;
}

.home-description-below {
  position: absolute;
  color: #eee4b4;
  font-size: 19px;
  width: 58%;
  top: 59%;
  left: 34%;
}

.home-page:hover .home-description {
  text-shadow: 0 0 0px #f0e715d1, 0 0 1px #f0e715d1, 0 0 0px #f0e715d1, 0 0 0px #f0e715d1;
  transition: 0.3s;
}

.home-banner-lcfc .aurora-home {
  margin-left: 150px
}

.home-banner-lcfc .home-page-name div {
  color: rgba(69, 246, 255, 1);
  font-family: PingFang;
  font-size: 60px;
  line-height: 60px;
}

.home-banner-lcfc .home-page-name {
  text-align: center;
  border-radius: 40px;
}

.home-banner-lcfc .home-page-name:hover {
  background: rgba(66, 190, 255, 0.1);
  box-shadow: rgba(131, 213, 255, 0.4) 0px 0px 4px 2px, rgba(131, 213, 255, 0.4) 0px 0px 2px 2px;
}

@media only screen and (max-width: 1850px) {
  .home-page-img-div {
    font-size: 294px;
    height: 234px;
    line-height: 258px;
  }

  .home-page-img-div-fastpi {
    font-size: 294px;
    height: 264px;
    line-height: 320px;
  }

  .home-description-below {
    left: 36%;
  }
}

@media only screen and (max-width: 1800px) {
  .home-page-img-div {
    font-size: 288px;
    height: 234px;
    line-height: 258px;
  }

  .home-page-img-div-fastpi {
    font-size: 288px;
    height: 254px;
    line-height: 310px;
  }

  .home-description-below {
    left: 35%;
  }
}

@media only screen and (max-width: 1760px) {

  .home-page-img-div {
    font-size: 280px;
    height: 234px;
    line-height: 258px;
  }

  .home-page-img-div-fastpi {
    font-size: 268px;
    height: 254px;
    line-height: 300px;
  }
}

@media only screen and (max-width: 1700px) {
  .home-page-img-div {
    font-size: 268px;
    height: 224px;
    line-height: 250px;
  }

  .home-page-img-div-fastpi {
    height: 244px;
    line-height: 290px;
  }
}

@media only screen and (max-width: 1650px) {
  .home-page-img-div {
    font-size: 260px;
    height: 224px;
    line-height: 250px;
  }

  .home-page-img-div-fastpi {
    font-size: 260px;
    height: 244px;
    line-height: 290px;
  }
}

@media only screen and (max-width: 1600px) {

  .home-page-img-div {
    font-size: 254px;
    height: 220px;
    line-height: 244px;
  }

  .home-page-img-div-fastpi {
    font-size: 254px;
    height: 240px;
    line-height: 294px;
  }

  .home-description-below {
    left: 32%;
  }
}

@media only screen and (max-width: 1550px) {

  .home-page-img-div {
    font-size: 244px;
    height: 220px;
    line-height: 244px;
  }

  .home-page-img-div-fastpi {
    font-size: 244px;
    height: 230px;
    line-height: 264px;
  }
}

@media only screen and (max-width: 1500px) {

  .home-page-img-div {
    font-size: 238px;
    height: 220px;
    line-height: 244px;
  }

  .home-page-img-div-fastpi {
    font-size: 238px;
    height: 230px;
    line-height: 274px;
  }
}

@media only screen and (max-width: 1480px) {

  .home-page-img-div {
    font-size: 230px;
    height: 200px;
    line-height: 215px;
  }

  .home-page-img-div-fastpi {
    font-size: 230px;
    height: 220px;
    line-height: 255px;
  }
}

@media only screen and (max-width: 1450px) {

  .home-page-img-div {
    font-size: 227px;
    height: 200px;
    line-height: 215px;
  }

  .home-page-img-div-fastpi {
    font-size: 227px;
    height: 220px;
    line-height: 255px;
  }
}

@media only screen and (max-width: 1400px) {

  .home-page-img-div {
    font-size: 220px;
    height: 200px;
    line-height: 215px;
  }

  .home-page-img-div-fastpi {
    font-size: 220px;
    height: 210px;
    line-height: 245px;
  }
}


@media only screen and (max-width: 1350px) {

  .home-page-img-div {
    font-size: 208px;
    height: 180px;
    line-height: 204px;
  }

  .home-page-img-div-fastpi {
    font-size: 208px;
    height: 200px;
    line-height: 230px;
  }
}

@media only screen and (max-width: 1300px) {

  .home-page-img-div {
    font-size: 200px;
    height: 176px;
    line-height: 200px;
  }

  .home-page-img-div-fastpi {
    font-size: 200px;
    height: 190px;
    line-height: 225px;
  }
}

@media only screen and (max-width: 1250px) {

  .home-page-img-div {
    font-size: 188px;
    height: 156px;
    line-height: 174px;
  }

  .home-page-img-div-fastpi {
    font-size: 188px;
    height: 180px;
    line-height: 214px;
  }
}

@media only screen and (max-width: 1200px) {

  .home-page-img-div {
    font-size: 182px;
    height: 150px;
    line-height: 168px;
  }

  .home-page-img-div-fastpi {
    font-size: 182px;
    height: 170px;
    line-height: 200px;
  }
}

@media only screen and (max-width: 1150px) {
  .home-page-img-div {
    font-size: 178px;
    height: 150px;
    line-height: 166px;
  }

  .home-page-img-div-fastpi {
    font-size: 178px;
    height: 170px;
    line-height: 196px;
  }
}

@media only screen and (max-width: 1100px) {
  .home-page-img-div {
    font-size: 166px;
    height: 140px;
    line-height: 152px;
  }

  .home-page-img-div-fastpi {
    font-size: 166px;
    height: 160px;
    line-height: 186px;
  }
}

@media only screen and (max-width: 1050px) {
  .home-page-img-div {
    font-size: 162px;
    height: 135px;
    line-height: 142px;
  }

  .home-page-img-div-fastpi {
    font-size: 162px;
    height: 150px;
    line-height: 176px;
  }
}

@media only screen and (max-width: 1000px) {

  .home-page-img-div,
  .home-page-img-div-fastpi {
    font-size: 156px;
    height: 135px;
    line-height: 142px;
  }

  .home-page-img-div-fastpi {
    font-size: 145px;
    height: 150px;
    line-height: 176px;
  }
}

@media only screen and (max-width: 950px) {
  .home-page-img-div {
    font-size: 145px;
    height: 128px;
    line-height: 138px;
  }

  .home-page-img-div-fastpi {
    font-size: 145px;
    height: 138px;
    line-height: 156px;
  }

  .home-description {
    width: 500px;
  }
}

@media only screen and (max-width: 900px) {

  .home-page-img-div,
  .home-page-img-div-fastpi {
    font-size: 138px;
    height: 128px;
    line-height: 138px;
  }

  .home-page-img-div-fastpi {
    font-size: 138px;
    height: 136px;
    line-height: 150px;
  }
}

@media only screen and (max-width: 850px) {

  .home-page-img-div {
    font-size: 130px;
    height: 120px;
    line-height: 130px;
  }

  .home-page-img-div-fastpi {
    font-size: 130px;
    height: 130px;
    line-height: 140px;
  }

  .home-description {
    width: 430px;
  }

  .aurora-home-page-en .aurora-home-flex .home-page {
    width: 50%;
  }
}


@media only screen and (max-width: 800px) {
  .home-page-img-div {
    font-size: 120px;
    height: 110px;
    line-height: 116px;
  }

  .home-page-img-div-fastpi {
    font-size: 120px;
    height: 120px;
    line-height: 130px;
  }

  .home-description {
    width: 400px;
  }
}

@media only screen and (max-width: 750px) {

  .home-page-img-div {
    font-size: 114px;
    height: 105px;
    line-height: 110px;
  }

  .home-page-img-div-fastpi {
    font-size: 114px;
    height: 110px;
    line-height: 120px;
  }

  .home-description {
    width: 360px;
  }
}

@media only screen and (max-width: 700px) {

  .home-page-img-div,
  .home-page-img-div-fastpi {
    font-size: 104px;
    height: 100px;
    line-height: 104px;
  }

  .home-page-img-div-fastpi {
    font-size: 104px;
    height: 100px;
    line-height: 104px;
  }

  .home-description {
    width: 300px;
  }
}

@media only screen and (max-width: 650px) {

  .home-page-img-div {
    font-size: 95px;
    height: 80px;
    line-height: 80px;
  }

  .home-page-img-div-fastpi {
    font-size: 95px;
    height: 90px;
    line-height: 90px;
  }
}


@media only screen and (max-width: 600px) {

  .home-page-img-div,
  .home-page-img-div-fastpi {
    font-size: 85px;
    height: 75px;
    line-height: 75px;
  }
}

@media only screen and (max-height: 1000px) {

  .home-banner-lcfc .aurora-home {
    margin-left: 150px;
  }

  .home-banner-lcfc .home-page-name div {
    font-size: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-height: 900px) {

  .home-banner-lcfc .aurora-home {
    margin-left: 150px;
  }

  .home-banner-lcfc .home-page-name {
    height: 70px;
  }

  .home-banner-lcfc .home-page-name div {
    font-size: 45px;
    line-height: 45px;
  }
}

@media only screen and (max-height: 850px) {

  .home-page-name {
    height: 64px;
  }

  .home-page-name div {
    font-size: 50px;
    line-height: 50px;
  }

  .home-banner-lcfc .home-page-name {
    height: 64px;
  }

  .home-banner-lcfc .home-page-name div {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-height: 800px) {

  .home-page-name {
    height: 50px;
  }

  .home-page-name div {
    font-size: 40px;
    line-height: 40px;
  }

  .home-banner-lcfc .home-page-name {
    height: 60px;
  }

  .home-banner-lcfc .home-page-name div {
    font-size: 36px;
    line-height: 36px;
  }
}
.my-tree {
  position: absolute;
  bottom: 42px;
  top: 0px;
  left: 4px;
  right: 0px;
  background: #f9f9f9;
  overflow: hidden;
  margin-top: 7px;
}

.my-tree:hover {
  overflow-y: auto;
}

.my-tree .expand-icon-large {
  font-weight: bold;
  color: #001529;
}

.my-tree .expand-icon-middle {
  font-weight: 500;
}

.my-tree .tree-node {
  line-height: 24px !important;
  height: 24px;
  padding: 0px;
  margin-bottom: 4px;
}


/* .my-tree .tree-node .ant-tree-indent .ant-tree-indent-unit {
  width: 10px;
} */

.my-tree .tree-node .ant-tree-node-content-wrapper {
  padding-left: 4px;
}

.my-tree .tree-node .ant-tree-node-content-wrapper,
.my-tree .tree-node .ant-tree-title {
  height: 24px;
}

.my-tree .ant-tree-iconEle,
.my-tree .ant-tree-switcher {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.65);
}

.my-tree .ant-tree-switcher {
  margin-inline-end: 0px;
  height: 24px;
  line-height: 24px;
}

.my-tree .ant-tree-switcher:before {
  height: 24px;
  line-height: 24px;
}

.tree-node {
  width: 100%;
  display: block;
}

.tree-node {
  position: relative;
}

.tree-node .ant-tree-title {
  width: 100%;
  display: inline-block;
  /* padding-right: 16px; */
}

.tree-node .ant-tree-title>span:not(.anticon) {
  display: inline-block;
  width: 100%;
  line-height: 24px;
  height: 24px;
  /*   overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.tree-node .ant-tree-title .tooltip-span {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.my-tree .tree-node div .ant-tree-node-content-wrapper {
  cursor: unset;
}

.my-tree .my-tree-switch-icon {
  vertical-align: middle;
  font-size: 12px;
  color: rgb(151, 151, 151);
  margin-top: 0px;
}

.my-tree .tree-icon-node .ant-tree-switcher {
  line-height: 24px !important;
}

.my-tree .my-tree-opened-icon {
  color: rgba(60, 95, 165);
}

.tree-file-folder-icon {
  font-size: 14px !important;
  padding-top: 3px;
  display: inline-block;
}

/* Modify the antd default styled  */
.my-tree .tree-node-project-name[draggable],
.my-tree .tree-node-project-name[draggable='true'] {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

/* Repair cannot drag the node is not selected but the background color appears*/
.my-tree .ant-tree-treenode-switcher-close.ant-tree-treenode-selected>.ant-tree-node-content-wrapper.draggable {
  background-color: #bae7ff;
}

.my-tree .ant-tree-treenode-switcher-open.ant-tree-treenode-selected>.ant-tree-node-content-wrapper.draggable {
  background-color: #bae7ff;
}

.my-tree div .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected {
  background-color: transparent;
}

.ant-tree .ant-tree-treenode-selected.andes-pcb-channel-node-name .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected,
.my-tree .ant-tree-treenode-selected.andes-end-to-end-channel-children .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected {
  background-color: #bae7ff;
}

.my-tree .tree-node .ant-tree-node-content-wrapper {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-tree .andes-pcb-channel-node-name .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected {
  background-color: #bae7ff;
}

.ant-tree .ant-tree-treenode-selected.andes-end-to-end-channel-children .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected {
  background-color: #bae7ff;
}

.my-tree .expand-icon-large>.ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 12px !important;
}

.my-tree .ant-tree-drop-indicator {
  bottom: 0px !important;
  left: 14px !important;
}

.my-tree .ant-tree-child-tree>li:first-child {
  padding-top: 0px;
}

.my-tree .my-tree-folder-icon {
  cursor: pointer;
}

.my-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging:after {
  border: 0px;
}
.pcb-upload-progress-bar {
  width: 100%;
  padding: 10px 0px;
}

.pcb-upload-progress-bar .ant-progress-outer {
  padding-right: 150px;
}

.pcb-upload-progress-bar .ant-progress-text {
  position: absolute;
  right: 0px;
  width: 140px;
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}

.upload-PCB-content {
  padding: 10px 16px;
  min-height: 100px;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 800px;
  max-height: 600px;
}

.upload-pcb-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

.upload-pcb-success-icon {
  color: #52c419;
  font-size: 20px !important;
}

.pcb-name-main {
  width: calc(100% - 26px);
}

.upload-pcb-vendor-item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  margin: 6 0px;
  display: flex;
  justify-content: center;
}

.upload-pcb-vendor-item>span {
  width: 116px;
  height: 28px;
  line-height: 28px;
}

.upload-pcb-vendor-item>.aurora-select {
  width: calc(100% - 116px);
  line-height: 28px;
}

.upload-pcb-list {
  display: block;
  width: 100%;
  height: 120%;
  max-height: 320px;
  min-height: 10px;
  margin: 4px;
  background: #f9f9fa;
  padding: 0;
  padding-top: 10px;
  overflow-y: auto;
}

.upload-pcb-item {
  width: calc(100% - 12px);
  height: 60px;
  background: #fff;
  border-radius: 4px;
  margin: 6px;
  box-shadow: 0 0 8px 0 rgba(12, 12, 13, 0.1);
  border: 0 solid #dae1e7;
  display: flex;
}

.pcb-name-span {
  display: inline-block;
  margin-left: 4px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.85;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 38px);
  color: #000000a6;
}

.upload-pcb-del-icon {
  float: right;
  font-size: 16px;
  font-weight: bold;
  line-height: 62px;
  margin-right: 8px;
  margin-left: 2px;
  cursor: pointer;
}

.upload-pcb-del-icon:hover {
  opacity: 0.75;
}

.upload-pcb-file-icon {
  float: left;
  margin-left: 10px;
  line-height: 60px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 18px;
}

.pcb-file-name-content {
  cursor: pointer;
  color: #40a9ff;
}

.pcb-file-name-input {
  height: 26px;
  width: 72%;
}

.upload-pcb-box {
  display: block;
  margin: 0px;
  margin-top: 10px;
}

.upload-pcb-box .ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 14px;
}

.upload-pcb-file-button {
  margin: 6px;
}

.upload-pcb-button {
  width: 100%;
}

.pcb-file-name-error-content {
  color: #cb2431;
}

.upload-pcb-sub-option {
  margin-left: 10px;
}
.upload-dropdown-button .ant-dropdown-menu-item:hover {
  color: #1890ff;
}

.aurora-PCB-upload,
.aurora-PCB-replace {
  display: block;
  float: right;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #1890ff;
  width: 16px;
}

.pcb-icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.pcb-icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

.pcb-icon-tooltip {
  max-width: 300px;
}

.pcb-pre-layout {
  color: #1890ff !important;
}
.collection-icon {
  transform: rotate(90deg);
  font-size: 20px !important;
  margin-top: -2px;
}

.collection-menu-icon {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  margin-right: 4px !important;
}

.tree-icon-collection-dropdown {
  z-index: 2001;
}
.aurora-rich-text-editor {
  max-width: 500px;
  max-height: 500px
}

.aurora-rich-text-editor .bf-content {
  height: 300px;
}

.text-editor-popover .ant-popover-content {
  box-shadow: 0 2px 8px 8px rgb(0 0 0 / 15%);
  ;
}

.rich-text-tooltip {
  max-height: 300px;
  max-width: 300px;
  text-overflow: ellipsis;
}

.text-editor-tooltip {
  overflow: auto;
  max-width: 300px;
  max-height: 300px;
}

.text-editor-tooltip p {
  margin: 0;
  padding: 0;
}
.upload-box {
  display: block;
  height: 100%;
  margin: 16px;
}

.upload-icon-add {
  font-size: 36px !important;
}

.upload-icon {
  margin-bottom: 8px !important;
}

.file-list-box {
  display: block;
  min-height: 120px;
  margin: 16px;
  background: #f9f9fa;
  border-radius: 4px;
}

.upload-file-list {
  display: block;
  width: calc(100% - 8px);
  height: 120%;
  max-height: 320px;
  min-height: 10px;
  margin: 4px;
  background: #f9f9fa;
  padding: 0;
  padding-top: 10px;
  overflow-y: auto;
}

.upload-file-item {
  width: calc(100% - 12px);
  height: 60px;
  background: #fff;
  border-radius: 4px;
  margin: 6px;
  box-shadow: 0 0 8px 0 rgba(12, 12, 13, 0.1);
  border: 0 solid #dae1e7;
  display: flex;
}

.file-name-span {
  display: inline-block;
  margin-left: 4px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.85;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 38px);
  color: #000000a6;
}

.upload-del-icon {
  float: right;
  font-size: 16px;
  font-weight: bold;
  line-height: 62px;
  margin-right: 8px;
  margin-left: 2px;
  cursor: pointer;
}

.upload-del-icon:hover {
  opacity: 0.75;
}

.upload-file-icon {
  float: left;
  margin-left: 10px;
  line-height: 60px;
  font-size: 24px;
  font-weight: bold;
  height: 60px;
}

.upload-text {
  font-size: 14px;
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
}

.upload-button {
  width: 100%;
}

.upload-move-button {
  margin: 10px 0;
  float: left;
  height: 26px;
  line-height: 26px;
  cursor: pointer;
}

.import-file-icon {
  font-size: 21px;
  color: #40a9ff;
  vertical-align: middle;
}

.import-file-span {
  display: inline-block;
  padding: 0 4px;
  font-size: 14px;
  vertical-align: middle;
  line-height: 26px;
}

.upload-file-button {
  margin: 6px;
}

.upload-drag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  list-style: none;
  outline: 0;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.upload-drag .upload-btn {
  display: table;
  height: 100%;
  padding: 26px 0;
  width: 100%;
  outline: none;
}

.upload-drag .upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}

.upload-drag .upload-drag-icon {
  color: #40a9ff;
  margin-bottom: 8px;
}

.file-name-input {
  height: 26px;
  width: 72%;
}

.file-name-content {
  cursor: pointer;
  color: #40a9ff;
}

.color-red {
  color: red;
}

.upload-file-error-msg {
  display: block;
  margin: 0 10px;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.file-list-box .ant-spin-nested-loading {
  position: static;
}

.upload-file-select-div {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  margin-left: 10px;
  width: 69px;
}

.upload-model-type-select-div {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  width: 160px;
}

.upload-file-select-div {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  width: 69px;
}

.upload-file-select-div .ant-select-selector,
.upload-model-type-select-div .ant-select-selector {
  padding-right: 15px;
  line-height: 24px;
  margin-left: 6px;
}

.upload-file-select-div .ant-select-arrow,
.upload-model-type-select-div .ant-select-arrow {
  right: 4px;
  margin-top: -5px;
  font-size: 10px;
}

.library-upload-progress-bar {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
}

.library-upload-progress-bar .ant-progress-text {
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}

.upload-success-icon {
  color: #52c419;
  font-size: 20px !important;
}

.library-upload-msg-bar {
  width: 100% !important;
  margin-left: 20px;
  margin-top: 14px;
}

.import-folder-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 8px;
  max-width: calc(100% - 40px);
}

.upload-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.file-name-main,
.upload-file-title {
  width: calc(100% - 26px);
}

.file-list-has-option-box .file-name-main,
.upload-file-name-title {
  width: calc(66% - 26px)
}

.file-list-has-option-box .upload-file-option-main {
  width: 34%;
  height: 60px;
  line-height: 60px;
}

.upload-file-title {
  display: flex;
  margin-left: 10px;
  height: 30px;
}

.upload-file-option-select {
  width: calc(100% - 12px);
  margin: 0px 6px;
}

.upload-file-option-title,
.upload-file-name-title {
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}

.upload-file-option-title {
  width: 34%;
}

.upload-file-option-dropdown {
  z-index: 9999;
}

.file-list-has-option-box .upload-file-list {
  padding-top: 0px;
}

.upload-file-check-msg {
  white-space: pre-wrap;
  max-height: 200px;
  overflow-y: auto;
}

.file-name-content.file-name-no-edit-content {
  color: #000000a6;
  cursor: auto;
}
.sierra-library-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

#sierra-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.sierra-file-panel {
  min-height: 200px;
  min-width: 200px;
}

.sierra-file-panel .panel-body {
  position: relative;
}

.sierra-file-panel .dividingLine {
  top: 0px;
}

.sierra-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  height: 400px;
}

.vector-value-box {
  margin: 16px;
}

.vector-input,
.vector-value-input {
  margin: 16px 0;
  display: block;
}

.vector-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.vector-input-tooltip {
  z-index: 3000;
}

.file-content-panel {
  box-sizing: content-box;
}

.file-content-panel .panel-body {
  position: relative;
  padding: 16px !important;
}

.file-content-panel .dividingLine {
  top: 0px;
}

.file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}

#file-content-view {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.library-edit-upload-progress-bar {
  width: 100%;
}

.library-edit-upload-progress-bar .ant-progress-outer {
  padding-right: 40px;
}

.library-edit-upload-progress-bar .ant-progress-text {
  position: absolute;
  right: 0px;
  width: 30px;
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}

.vector-value-input .ant-input-group-addon {
  width: 50px;
}

.sierra-vector-radio-group {
  display: block;
  margin: 0 16px;
  height: 20px;
  margin-top: 16px;
}

.sierra-vector-radio-upload {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.sierra-vector-radio-edit {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.sierra-show-models .show-models-tip {
  margin: 10px 20px 5px 20px;
  font-size: 15px;
}

.sierra-show-models .show-models-list {
  margin: 0px 16px 16px 16px;
  background: #f5f5f5;
  max-height: 300px;
  overflow: auto;
  border: 1px dashed #d9d9d9;
}

.sierra-show-models .show-models-list ul {
  margin: 0;
  padding: 12px 16px;
}

.sierra-library-help-icon {
  color: #1890ff;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -8px;
}

.sierra-library-panel .panel-ant-modal-title {
  position: relative;
}

.sierra-repeater-edit-icon {
  font-size: 15px;
  color: #1890ff;
  margin-right: 8px;
}

.file-textarea {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  padding: 16px;
}

.edit-button-group {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.edit-button-group button:nth-child(1),
.edit-button-group button:nth-child(2),
.edit-button-group button:nth-child(3) {
  margin-right: 10px;
  margin-left: 10px;
}

.file-title {
  display: inline-block;
  margin-right: 10px;
}

.file-title-span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.vector-edit-value-input .ant-input-group-addon {
  width: 50px;
}

.vector-edit-input,
.vector-edit-value-input {
  margin: 8px 0;
  display: inline-block;
  width: calc(100% - 80px);
  vertical-align: middle;
}

.vector-edit-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.vector-edit-input-tooltip {
  z-index: 3000;
}

.vector-edit-value-span {
  display: inline-block;
  width: 80px;
  margin: 8px 0;
  vertical-align: middle;
}

.vector-edit-value-input .ant-input-group-addon .ant-select .ant-select-selection .ant-select-arrow {
  right: 8px;
}

textarea.ant-input.vector-edit-value-input {
  vertical-align: middle;
  max-height: 100px !important;
}
/* === Sample panel === */
.vector-sample-file-panel .panel-body {
  background: #f1f1f1;
}

.vector-sample-file-panel .panel-body {
  position: relative;
}

.vector-sample-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  height: 400px;
}

.vector-sample-file-download {
  margin-left: 20px;
  font-size: 16px;
  color: #1890ff;
}

.vector-sample-icon-tooltip {
  z-index: 2010;
}

.vector-sample-icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.vector-sample-icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

#vector-sample-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}
.create-data-content {
  padding: 2px 12px 0px;
}

.sierra-interface-creation-main .create-content-input-pcb {
  padding: 8px 12px;
}

.create-data-content,
.sierra-interface-create-panel .create-data-content>.aurora-create-advanced-content {
  background-color: #ebebeb;
  border-radius: 4px;
}

.sierra-create-identification-panel .identification-input,
.sierra-create-identification-panel .identification-select {
  width: calc(100% - 80px);
}

.create-data-content {
  padding-bottom: 8px;
}

.create-content-input-item,
.create-content-interface-name-item {
  padding: 8px;
  display: flex;
  line-height: 28px;
  justify-content: space-between;
}

.create-content-interface-name-item {
  margin: 8px 14px;
}

.create-content-input-item .create-edit-input-label {
  width: 94px;
  height: 28px;
  line-height: 28px;
}

.create-content-interface-name-item .create-edit-input-label {
  width: 116px;
}

.create-content-input-item .create-input,
.create-content-input-item .create-select {
  width: calc(100% - 94px);
}

.sierra-interface-create-panel .create-content-input,
.sierra-interface-create-panel .create-content-select {
  width: calc(100% - 80px);
}

.sierra-interface-create-panel .create-content-interface-name-item .create-content-input {
  width: calc(100% - 116px);
}

.cerate-interface-footer .create-content-interface-name-item {
  margin-bottom: 0px;
  margin-top: 0px;
}

.sierra-interface-create-panel .create-panel-msg-box {
  margin: 0px 8px 8px;
  line-height: 28px;
  padding: 0px 0px;
}

.sierra-interface-create-panel .select-content-msg-box {
  margin: 0 0 10px;
}

.create-panel-msg-box .aurora-model-name-error-msg {
  width: 100%;
  padding: 0 10px;
  border-radius: 4px;
  text-align: left;
}

.create-footer-content {
  display: flex;
  justify-content: center;
  border-radius: 0 0 4px 4px;
}

.create-footer-content .ant-btn {
  height: 28px;
  width: 75px;
}

.interface-identification-select-content {
  padding: 10px 0px;
}

.sierra-interface-create-panel .ant-spin-nested-loading>div>.ant-spin {
  max-height: 600px;
}

.sierra-interface-create-panel .select-checkbox-title-proportion,
.sierra-create-interface-confirm-panel .select-checkbox-title-proportion {
  margin-left: 8px;
}

.create-interface-merge-content {
  width: 100%;
  display: flex;
  padding: 0 14px;
  padding-right: 0px;
}

.modal-foot-position .create-interface-merge-content {
  padding: 5px 14px 5px 2px;
}

.create-interface-merge-checkbox {
  width: 30px;
  text-align: left;
  line-height: 28px;
}

.create-interface-merge-content .create-interface-merge {
  width: calc(100% - 30px)
}

.sierra-interface-create-panel .creation-interface-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 8px 10px;
  padding-left: 30px;
}

.sierra-interface-create-panel .creation-interface-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 10px;
}

.sierra-interface-create-panel .creation-interface-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px 16px;
}

.create-content-input-pcb .aurora-select {
  height: -moz-fit-content;
  height: fit-content;
}

.create-content-input-pcb .aurora-select .ant-select-selector {
  height: -moz-fit-content;
  height: fit-content;
}

.interface-identification-select-content .identification-select-table .ant-table-small,
.interface-creation-content .identification-select-table .ant-table-small {
  border-color: #a8c4e6;
  border-right: 1px solid #a8c4e6;
}

.creation-interface-main {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  height: 100%;
}

.creation-interface-main .creation-interface-collapse-content {
  border: 1px solid #a8c4e6;
  width: 50%;
  border-radius: 4px;
  position: relative;
}

.interface-creation-content {
  border: 1px solid #a8c4e6;
  border-radius: 4px;
  min-width: 382px;
  margin-left: 10px;
  width: 50%;
  position: relative;
  min-height: 300px;
}

.interface-creation-content-item {
  overflow: auto;
}

.identification-creation-table {
  margin: 8px;
}

.sierra-interface-creation-main {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  overflow: auto;
  height: 100%;
}

.sierra-interface-creation-main>.ant-spin-nested-loading,
.sierra-interface-creation-main>.ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}

.creation-interface-collapse-main,
.interface-creation-table-main {
  overflow: auto;
}

.interface-creation-table-main {
  border-bottom: 1px solid #d6dae0;
  height: calc(100% - 40px);
}

.selection-signals-content {
  display: flex;
  padding: 8px 16px 10px 8px;
  justify-content: right;
}

.connect-selected-signal-button {
  margin-left: 10px;
}

.selection-signals-content .ant-btn {
  font-size: 12px;
  line-height: 12px;
}

.interface-creation-connections-button {
  padding: 8px 10px;
  text-align: right;
  /*   float: right; */
  padding-bottom: 0px;
  position: absolute;
  left: 324px;
  bottom: 34px;
}

.interface-creation-connections-table-button {
  float: right;
  position: static;
}

.cerate-interface-footer {
  /*   position: absolute; */
  /*   bottom: 6px;
  right: 0px; */
  width: 100%;
  height: 40px;
  padding: 6px 0px;
}

.create-interface-merge-content .aurora-select {
  margin-left: 10px;
}

.create-interface-merge-content>div {
  line-height: 28px;
}

/* .create-interface-merge-content .combine-selected-signals-button-content {
  line-height: 28px;
} */

.combine-selected-signals-button {
  margin-left: 10px;
}

.identification-select-table .ant-table .ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
}

.interface-creation-content .aurora-success-msg,
.interface-creation-content .aurora-model-name-error-msg {
  text-align: left;
  margin: 6px 8px;
}

.create-one-pcb-interface-content {
  display: flex;
  justify-content: space-between;
}

.create-one-pcb-interface-content .selection-signals-content {
  padding-top: 8px;
}

.sierra-creation-interface-dropdown {
  z-index: 100000;
}

/* .creation-interface-tabs.ant-tabs.ant-tabs-card .aurora-input {
  max-width: calc(100% - 24px);
} */

.sierra-interface-delete-icon {
  color: rgba(0, 0, 0, 0.45);
  margin-left: 8px;
  cursor: pointer;
  display: none;
}

.sierra-interface-creation-name-edit {
  cursor: pointer;
}

.sierra-interface-creation-name-main {
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.sierra-interface-creation-name-edit:hover .sierra-interface-delete-icon {
  display: inline-block;
}

.sierra-interface-delete-icon:hover {
  color: #40a9ff;
}

.interface_creation-table-label {
  font-size: 14px;
  color: #0b3694;
  font-weight: bold;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  margin: 8px 8px;
  margin-bottom: 0px;
}

.merge-selected-signal-button-div {
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 10px;
  padding-right: 8px;
  display: flex;
}

.merge-selected-signal-button {
  font-size: 12px;
  line-height: 12px;
}

.creation-interface-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 0px 8px;
}

.creation-interface-tabs.ant-tabs.ant-tabs-card .sierra-interface-delete-icon {
  margin-right: 0px;
}

.identification-creation-interface-table {
  padding: 0px 8px;
}

.creation-interface-tabs.ant-tabs.ant-tabs-card {
  margin-left: 4px;
}

/* merge signals */
.sierra-merge-signals-main {
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
}

.sierra-merge-signals-left,
.sierra-merge-signals-right {
  border: 1px solid #a8c4e6;
  padding: 6px;
}

.sierra-merge-signals-arrow-right {
  line-height: 200px;
}

.sierra-merge-signals-arrow-right>.anticon {
  font-size: 30px;
  color: #40a9ff;
  padding: 0px 4px;
}

.sierra-merge-signals-nets-td {
  max-height: 80px;
  overflow: auto;
}

.sierra-interface-creation-main .creation-interface-collapse {
  background-color: #fff;
}

.creation-interface-pcb-expand-title {
  display: flex;
  justify-content: space-between;
}

.creation-interface-pcb-expand-title>.sierra-pcb-setup-title {
  display: flex;
  width: calc(100% - 112px);
}

.creation-interface-pcb-expand-title>.sierra-pcb-setup-create-title {
  width: 100%;
}

.creation-interface-pcb-expand-title>.sierra-pcb-setup-title>span,
.creation-interface-pcb-expand-title>.sierra-pcb-setup-create-title>span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.creation-interface-pcb-expand-title>.sierra-pcb-setup-create-title>span {
  width: 100%;
}

.creation-interface-nets-eye-disabled-icon.anticon {
  margin-left: 10px;
  cursor: not-allowed;
}

.creation-interface-nets-eye-icon {
  color: #40a9ff;
  cursor: pointer;
  margin-left: 10px;
}

.creation-interface-pcb-eye-icon>.anticon {
  color: #40a9ff;
  cursor: pointer;
}

.interface-creation-retweet-icon {
  color: #40a9ff;
  cursor: pointer;
  margin-left: 4px;
}

.creation-interface-pcb-expand-main {
  position: absolute;
  right: -12px;
  top: 4px;
  z-index: 100000000;
  bottom: 6px;
  width: 10px;
  cursor: pointer;
}

.creation-interface-pcb-expand-icon {
  color: #40a9ff;
  font-size: 18px;
  position: absolute;
  right: -2px;
  top: 50%;
  margin-top: -18px;
}

.creation-interface-conn-expand-icon {
  color: #40a9ff;
  font-size: 18px;
  position: absolute;
  left: -2px;
  top: 50%;
  margin-top: -18px;
}

.creation-interface-conn-expand-main {
  position: absolute;
  left: -12px;
  top: 4px;
  bottom: 6px;
  width: 10px;
  cursor: pointer;
}

.creation-interface-pcb-expand-main:hover,
.creation-interface-conn-expand-main:hover {
  background: #efefef;
  border-radius: 4px;
}

.creation-interface-collapse-content .creation-interface-pcb-not-expand-main {
  left: 8px;
}

.creation-interface-collapse-content .creation-interface-pcb-not-expand-main .creation-interface-pcb-expand-icon {
  right: -5px;
}

.interface-creation-content .creation-interface-conn-not-expand-main {
  right: 8px;
  left: unset;
}

.interface-creation-content .creation-interface-conn-not-expand-main .creation-interface-conn-expand-icon {
  left: -5px;
}

.identification-creation-table .aurora-table-body>tr>td:nth-child(2),
.identification-creation-interface-table .aurora-table-body>tr>td:first-child,
.identification-creation-table .aurora-table-body>tr>td:nth-child(1) {
  position: relative;
  padding-right: 22px !important;
}

.identification-creation-table .ant-table-small>.ant-table-content .aurora-table-body>tr>.interface-creation-table-check-td {
  padding-right: 8px !important;
}

.interface-creation-conn-signal-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.interface-creation-conn-signal-content:hover .interface-creation-delete-signal-icon {
  color: #40a9ff;
}

.interface-creation-delete-signal-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pcb-setup-footer {
  border-top: 1px solid #d6dae0;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 146px;
  position: relative;
}

.pcb-setup-single-footer {
  height: 74px;
}

.creation-interface-main-single-pcb,
.creation-interface-main-single-pcb .creation-interface-collapse-content {
  height: 100%;
}

.sierra-pcb-search-log-info-icon {
  color: #40a9ff;
  padding: 4px 6px;
  font-size: 15px;
}


.sierra-interface-create-panel .aurora-create-advanced-content .aurora-create-advanced-input-item .aurora-tags-input-content,
.sierra-interface-create-panel .aurora-create-advanced-content .aurora-create-advanced-input-item .aurora-select-regular-input {
  width: calc(100% - 140px);
}

.create-selected-signal-button {
  /*   float: left; */
  margin-right: 14px;
  height: 28px;
  width: 75px;
}

.create-interface-single-content {
  justify-content: space-between;
}

.create-interface-single-checkbox-content {
  display: flex;
  /*  margin-left: 10px; */
}

.pcb-setup-single-footer .create-interface-single-checkbox-content {
  margin-left: 14px;
}

.create-interface-single-create-button {
  line-height: 28px;
  height: 28px;
  margin-left: 14px;
  /*  padding: 2px 0px; */
}

.pcb-interface-creation-radio {
  display: flex;
  padding: 2px 6px;
  padding-left: 14px;
}

.create-interface-single-checkbox-content>div {
  height: 28px;
  line-height: 28px;
}

.sierra-interface-create-panel .create-data-content .aurora-create-advanced-input-item .create-content-input {
  width: calc(100% - 140px);
}

.create-nets-keywords-clear-icon {
  position: absolute;
  right: 26px;
  height: 26px;
  line-height: 26px;
  margin: 2px;
  color: #bebebe;
  display: none;
}

.sierra-interface-creation-main .aurora-select-regular-input:hover .create-nets-keywords-clear-icon,
.sierra-interface-creation-main .aurora-not-select-regular-input:hover .create-nets-keywords-clear-icon {
  display: block;
  color: #9f9f9f;
}

.sierra-create-interface-confirm-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin-top: 80px;
}

.sierra-create-interface-confirm-panel .sierra-create-interface-confirm-content {
  padding: 8px 12px;
  overflow: auto;
}

.sierra-re-create-cancel-button {
  margin-left: 30px;
}

/* .create-footer-content .sierra-re-create-ok-button {
  width: 90px;
} */

.sierra-create-interface-confirm-content-title {
  height: 28px;
  line-height: 22px;
  padding-bottom: 6px;
}

.sierra-create-interface-confirm-table .ant-table-content .ant-table-body tr td .sierra-re-create-interface-checkbox {
  text-align: center;
}

.sierra-create-log-panel .aurora-msg-dialog-item {
  color: rgba(0, 0, 0, 0.85);
}

.aurora-msg-dialog-info-panel.sierra-create-log-panel {
  top: 60px;
}
.aurora-create-panel .aurora-create-advanced-content {
  background-color: #fff;
}

.aurora-create-panel .aurora-create-advanced-content>.ant-collapse-item {
  border: 0;
}

.aurora-create-panel .aurora-create-advanced-content>.ant-collapse-item>.ant-collapse-header {
  padding: 0px 8px;
  display: flex;
  align-items: center;
}

.aurora-create-advanced-content .ant-collapse-content-box {
  padding-bottom: 8px;
  padding-right: 0;
}

.aurora-create-advanced-content .aurora-create-advanced-input-item,
.aurora-create-advanced-content .aurora-create-input-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  justify-content: space-between;
}

.aurora-create-advanced-content .aurora-create-advanced-checkbox-item {
  justify-content: left;
}

.aurora-create-advanced-content .aurora-create-advanced-checkbox-item>.create-edit-input-label {
  width: 120px;
}

.aurora-create-advanced-content .aurora-create-advanced-input-item .create-content-input {
  width: calc(100% - 120px);
  cursor: pointer;
}

.aurora-create-advanced-input-item .create-advanced-switch {
  margin: 8px 0px;
}

.aurora-select-regular-input span.ant-input-group-addon:hover {
  cursor: pointer;
}

.aurora-select-regular-input span.ant-input-group-addon {
  background-color: #d6dadd;
}

.aurora-not-select-regular-input span.ant-input-group-addon {
  background-color: #fafafa;
}

.aurora-not-select-regular-input .regular-icon {
  display: inline-block;
  position: absolute;
  right: 0px;
  height: 20px;
  line-height: 20px;
  padding: 0px 6px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 4px;
}

.aurora-select-regular-input.ant-input-affix-wrapper .ant-input-suffix {
  right: 4px;
  background-color: #bee1ff;
  border-radius: 4px;
  font-size: 14px;
}

.aurora-select-regular-input.ant-input-affix-wrapper .ant-input-suffix div {
  padding: 0 6px;
}

.aurora-select-regular-input .regular-icon-selected {
  display: inline-block;
  position: absolute;
  right: 0px;
  height: 20px;
  line-height: 20px;
  padding: 0px 6px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 4px;
  background-color: #bee1ff;
}

.aurora-create-advanced-input-item label.create-input-radio.ant-radio-wrapper {
  margin-left: 10px;
  top: 4px;
}

.aurora-create-advanced-content .aurora-create-advanced-input-item .create-advanced-switch {
  margin-left: 10px;
}

.aurora-create-advanced-content .aurora-create-advanced-input-switch {
  justify-content: flex-start;
}

.aurora-create-advanced-input-item.aurora-create-advanced-input-checkout {
  margin-left: 30px;
}
.sierra-connections-main {
  padding: 10px 14px;
}

.sierra-connections-buttons {
  padding-bottom: 6px;
  overflow: hidden;
  /*   display: flex;
  justify-content: left; */
}

.sierra-connections-buttons .sierra-connection-download-button,
.sierra-connections-buttons .sierra-connection-upload-button,
.sierra-connections-buttons .sierra-connection-add-button {
  /* margin-left: 10px; */
  padding: 0px 8px;
}

.sierra-connections-buttons .ant-divider-vertical {
  margin: 0px 4px;
}

.sierra-connections-footer-content {
  display: flex;
  justify-content: center;
  border-radius: 0 0 4px 4px;
}

.sierra-connections-footer-content .ant-btn {
  height: 28px;
  width: 75px;
}

.sierra-connections-buttons .ant-btn {
  height: 28px;
}

.connections-board-title {
  width: 100%;
  /*  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.connections-board-title>span:not(.anticon) {
  display: block;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sierra-connections-main .aurora-model-name-error-msg,
.sierra-connections-main .aurora-success-msg {
  margin: 6px 8px;
  margin-top: 0px;
}

.connections-table-main {
  padding: 6px;
  border: 1px solid #a8c4e6;
  border-radius: 4px;
}

.connections-table-main>label {
  color: #0b3694;
}

.connections-table-main>.sierra-connections-table {
  margin-top: 6px;
}

.connections-project-table-main {
  margin-top: 6px;
}

.sierra-connections-options-content>span {
  margin: 0px 8px;
  display: inline-block;
  height: 28px;
  line-height: 28px;
}

.connection-delete-icon {
  position: absolute;
  right: -17px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.sierra-connections-table .ant-table-small .ant-table-content .ant-table-body tr:hover .connection-delete-icon {
  color: #1890ff;
}

.sierra-connections-table .ant-table-small .ant-table-content .ant-table-body tr {
  position: relative;
}

.sierra-connections-panel {
  top: 80px;
}
.aurora-msg-dialog-panel,
.aurora-msg-dialog-info-panel {
  top: 200px;
}

.aurora-msg-dialog-mask-panel {
  z-index: 100000000 !important;
}

.aurora-msg-dialog-info-panel .panel-modal-header {
  padding: 0px !important;
}

.aurora-msg-dialog-panel .ant-modal-header,
.aurora-msg-dialog-panel .ant-modal-close {
  display: none;
}

.aurora-msg-dialog-panel .rightBorder,
.aurora-msg-dialog-panel .bottomBorder,
.aurora-msg-dialog-panel .lowerRightCorner {
  cursor: unset;
}

.aurora-msg-dialog-main {
  padding: 10px 14px;
  /* max-height: 600px; */
  /*  overflow: auto; */
  width: 100%;
  height: 100%;
}

.aurora-msg-dialog-info-panel .aurora-msg-dialog-main {
  padding: 14px;
}

.aurora-msg-dialog-title {
  text-align: center;
}

.aurora-msg-dialog-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  height: 100%;
}

.aurora-msg-dialog-info-panel .aurora-msg-dialog-main .aurora-msg-dialog-content {
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  padding: 6px 16px;
  height: calc(100% - 36px);
}

.aurora-msg-dialog-item,
.aurora-msg-dialog-info-item {
  margin: 6px 0px;
  font-size: 14px;
  color: #1ca4f3;
  /*   text-align: center; */
  width: 100%;
}

.aurora-msg-dialog-content .aurora-msg-dialog-info-item {
  color: rgb(0, 0, 0, 0.65);
}

.message-dialog-ready-icon {
  color: #57f618;
  margin-right: 10px;
}

.message-dialog-loading-icon {
  color: #1ca4f3;
  margin-right: 10px;
}

.aurora-msg-footer-main {
  text-align: center;
}

.aurora-msg-footer-main .ant-btn {
  height: 28px;
  width: 75px;
  /* border: none; */
  /*  color: #6f6f6f;
  line-height: 26px;
  border-color: #eee; */
}

/* .aurora-msg-footer-main .ant-btn:hover {
  color: #6f6f6f;
  background-color: #eee;
} */

.aurora-msg-dialog-main .aurora-error-msg-dialog-item {
  color: #f3231c;
  text-align: center;
}

.aurora-msg-warning-png {
  width: 24px;
  height: 24px;
}

.aurora-msg-error-dialog-title {
  padding-bottom: 10px;
  text-align: center;
}

.aurora-msg-dialog-panel .modal-foot-position {
  height: 40px !important;
}

.aurora-msg-dialog-panel .ant-modal-footer {
  padding: 6px 16px;
}

.aurora-msg-Tips-icon {
  font-size: 22px;
}

.aurora-msg-error-dialog-title>span {
  font-weight: 600;
}

.aurora-msg-footer-main .ant-btn.aurora-msg-dialog-download-button {
  width: 100px;
}

.aurora-msg-dialog-info-download {
  color: #1890ff;
  font-size: 17px;
  float: right;
  cursor: pointer;
  width: 42px;
  height: 42px;
  line-height: 46px;
}

.aurora-msg-dialog-title {
  height: 42px;
  line-height: 42px;
  display: inline-block;
}

.aurora-msg-dialog-search {
  margin-bottom: 8px;
}

.aurora-msg-dialog-search .aurora-input {
  width: 100%;
}

.aurora-msg-children-dialog-item {
  padding-left: 10px;
  color: rgb(0, 0, 0, 0.65);
}
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
  width: -moz-fit-content;
  width: fit-content;
}

.icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}


/* Margin */
.margin-top-14 {
  margin-top: 14px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-6 {
  margin-top: 6px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-top-0 {
  margin-top: 0px;
}

.display-inline-block {
  display: inline-block;
}

.font-bold {
  font-weight: bold;
}

.space-10 {
  margin-top: 10px;
}

.width-25 {
  width: 25%;
}

.mg-r-8 {
  margin-right: 8px;
}

/* Select */
.aurora-select {
  height: 28px;
}

.aurora-select .ant-select-selector,
.aurora-tree-select .ant-select-selector {
  min-height: 24px;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 26px;
  height: auto;
}

.aurora-select .ant-select-selector>ul>li,
.aurora-select .ant-select-selector .ant-select-selector>ul>li {
  height: 20px;
  margin-top: 3px;
  line-height: 18px;
}

.aurora-select .ant-select-arrow,
.aurora-select .ant-select-clear {
  right: 6px;
}

.aurora-select .ant-select-selection--multiple {
  height: auto;
}

.ant-select-selection--multiple {
  padding-bottom: 3px;
}

.aurora-select-top .ant-select-arrow,
.aurora-select-top .ant-select-clear {
  margin-top: 4px;
}

.padding-left-20 {
  padding-left: 20px;
}

/* result input color */
.color-select {
  padding: 0px;
  background-color: #fafafa;
  border: 0px;
  width: 19px;
  cursor: pointer;
  display: inline-block;
  outline: none;
}

.aurora-input {
  height: 28px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.aurora-input .ant-input {
  font-size: 13px;
  height: 28px;
}

.aurora-input-disable[disabled] {
  color: rgba(0, 0, 0, 0.75);
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
}

.icon-disabled {
  cursor: not-allowed !important;
  color: #909294 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.signal-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.signal-delete-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.vertical-align-middle {
  vertical-align: middle;
}

.out-title-color {
  color: #0b3b94;
}

/* box-shadow */
.aurora-box-shadow {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.vector-help-icon {
  color: #1890ff;
  font-size: 16px;
  float: right;
  margin-right: 26px;
  margin-top: 2px;
}

/* switch */
.aurora-switch-small {
  min-width: 26px;
  height: 14px;
  line-height: 12px;
}

.aurora-switch-small .ant-switch-handle {
  width: 10px;
  height: 10px;
}

.aurora-error-msg-span {
  display: block;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 5px 16px 4px 10px;
}

.row-disabled {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f3f3f3;
  cursor: not-allowed;
}

.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 0%;
}

.out-error-title-color {
  color: rgba(0, 0, 0, 0.65);
}

.aurora-column-select-row,
.aurora-column-select-row>td {
  background-color: #adddfd !important;
}

.aurora-column-select-row:hover>td,
.aurora-column-select-row:hover .editable-cell-value-wrap {
  background-color: #adddfd !important;
}

.aurora-column-select-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.aurora-column-merge-row,
.aurora-column-merge-row>td {
  background-color: #fffbdd !important;
}

.aurora-column-merge-row:hover>td,
.aurora-column-merge-row:hover .editable-cell-value-wrap {
  background-color: #fffbdd !important;
}

.aurora-column-merge-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.merge-part-popover .ant-popover-content,
.merge-path-popover .ant-popover-content {
  z-index: 1000;
}

.merge-part-popover .ant-popover-content .ant-popover-inner-content,
.merge-path-popover .ant-popover-content .ant-popover-inner-content {
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid#ffadd2;
  color: #eb2f96;
  background: #fff0f6;
}

.merge-part-popover .ant-popover-content .ant-popover-arrow,
.merge-path-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
}

.merge-part-popover .ant-popover-inner-content:hover .ant-popover-arrow,
.merge-path-popover .ant-popover-inner-content:hover .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
  bottom: 6.2px;
}

.z-index-100000 {
  z-index: 100000;
}

.display-none {
  display: none !important;
}
.ant-select-dropdown.export-content-drop-down-tree {
  max-height: 300px !important;
  z-index: 3000;
}

.export-content-footer {
  margin-top: 17px;
  position: relative;
  min-height: 27px;
  margin-bottom: 10px;
}

.export-content-footer .export-data-button {
  position: absolute;
  right: 0;
  bottom: 2px;
}

.export-content-footer .export-folder-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 8px;
  max-width: calc(100% - 90px);
}

.export-content-tip .export-content-footer .download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.export-content-tip .export-content-footer .download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.ant-radio-group.ant-radio-group-outline.import-export-radio-group {
  display: block;
  height: 20px;
  margin: 16px 16px 0;
}

.import-export-radio-edit.ant-radio-wrapper.ant-radio-wrapper-checked {
  float: left;
  line-height: 20px;
  width: 120px;
  margin: 0 !important;
}

.import-export-Panel .export-content-tip {
  margin: 10px 20px 5px;
  font-size: 15px;
}

.import-export-radio-edit.ant-radio-wrapper {
  float: left;
  line-height: 20px;
  width: 120px;
  margin: 0 !important;
}

.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-default.export-content-progress {
  margin-bottom: 5px;
}

.export-content-progress .ant-progress-outer .ant-progress-inner {
  background-color: #c7e0f4;
}

.export-data-tree {
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  margin-top: 5px;
}
.component-RLC-content {
  height: 100%;
  width: 100%;
  padding: 16px;
}

.component-RLC-item {
  width: 100%;
  height: 33.33%;
  padding: 10px 0px;
  position: relative;
}

.component-RLC-item-content {
  display: inline-block;
  width: 75%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  vertical-align: middle;
  padding: 0px 11px;
  cursor: pointer;
}

.package-RLC-item-content {
  padding: 0px 5px;
}

.component-RLC-title {
  font-size: 14px;
  display: inline-block;
  width: 25%;
  vertical-align: middle;
}

.component-RLC-item-select {
  width: 100%;
}

.component-RLC-item-input {
  border: none;
  height: 30px;
  /* float: left; */
  position: static;
  padding: 0;
  width: .75em;
  max-width: 100%;
  float: left;
}

.component-RLC-item-tag {
  cursor: pointer;
  margin-top: 4px;
  float: left;
}

.component-RLC-tags {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.component-RLC-item-input:hover,
.component-RLC-item-input:focus {
  border-right-width: 0px !important;
  border-width: 0px;
  box-shadow: 0px 0px 0px #ffffff;
  border-color: #fff;
}

.component-RLC-item-content .component-RLC-tags .component-RLC-item-tag .anticon-close {
  font-size: 10px;
}

.component-RLC-item-input-box {
  width: auto;
  float: left;
  height: 30px;
}

.component-RLC-sub-content {
  margin-left: 12px;
}

.component-RLC-sub-title {
  font-weight: bold;
}

.component-PMIC-panel {
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.component-PMIC-item-content {
  width: 75%;
  border-radius: 4px;
}

.component-RLC-divider {
  margin: 10px 0px;
}

.component-apply-title {
  font-size: 14px;
  display: inline-block;
  width: 265px;
  vertical-align: middle;
}

.component-apply-checkbox {
  vertical-align: middle;
}

.component-pcb-passive-table,
.component-pcb-pin-map-table {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  position: relative;
}

.component-pcb-power-nets-table {
  height: 100%;
  min-width: 806px;
  position: relative;
}

.component-pcb-pin-map-table {
  margin: 20px;
}

.component-pmic-pin-library-value {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  position: relative;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px 5px;
  cursor: pointer;
  height: 28px;
}

.component-pmic-pin-library-value:not(.buck-converter):hover {
  border-color: #d9d9d9;
}

.component-pmic-pin-title-color {
  vertical-align: middle;
  color: #0b3694;
}

.comp-pmic-pin-edit-icon {
  font-size: 16px;
  color: #1ca4f3;
  position: absolute;
  right: 3px;
  top: 6px;
}

#components-setting-dialog,
#components-setting-dialog .ant-select-dropdown {
  z-index: 2000
}

.component-stuff-content {
  width: 100%;
  height: 90%;
  height: 472px;
}

.component-stuff-content .components-stuff-transfer,
.component-stuff-content .aurora-transfer {
  font-weight: 500;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.component-stuff-content .components-stuff-transfer .ant-transfer-list,
.component-stuff-content .aurora-transfer .ant-transfer-list {
  width: 100%;
  height: 100%;
}

.component-stuff-content .components-stuff-transfer.ant-transfer-customize-list .ant-transfer-list {
  width: 48%;
  height: 100%;
}

.component-stuff-content .ant-table-small .ant-table-container .aurora-table-body>table>.aurora-table-body>tr>td {
  background: white;
  padding: 6px 0 !important;
  border: none;
}

.component-stuff-content .anticon.component-stuff-upload-icon {
  margin-right: 10px;
  color: #1890ff;
}

#component-stuff-buck-content {
  position: absolute;
  height: 200px;
  width: 100%;
  overflow: auto;
  margin-top: 34px;
  z-index: 2002;
  background-color: #fff;
  overflow-anchor: none;
  box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.15);
}

.component-buck-list-item {
  display: inline-block;
  padding: 10px;
  width: 100%
}

.component-buck-list-select {
  width: 100px;
  display: inline-block;
}

.component-buck-list-divider {
  width: calc(100% - 240px);
  margin: 14px 5px;
  min-width: unset;
  display: inline-block;
  background-color: #1ca4f3;
}

.component-buck-list-select-dropdown {
  z-index: 100000;
}

.component-buck-list-item .component-buck-delete-icon {
  margin-left: 10px;
}

.component-buck-list-item .component-buck-delete-icon:hover {
  color: #1ca4f3;
}

.component-PMIC-panel .panel-body {
  position: relative;
}

.pin-connection-select-dropdown-menu {
  z-index: 2001 !important;
}

.component-connector-item-content .ant-select-selection--multiple .ant-select-selector {
  padding: 0 6px;
}

.component-classification-cascade-footer {
  width: 100%;
}

.component-classification-cascade-footer .left-button {
  position: absolute;
  left: 20px;
}

.component-animation-classfication-left {
  animation: moveLeft 0.5s;
}

@keyframes moveLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.component-animation-classfication-right {
  animation: moveRight 0.5s;
}

@keyframes moveRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.component-classification-cascade-footer .menu-button {
  position: absolute;
  left: 42%;
  padding-top: 4px
}

.component-classification-cascade-footer .menu-button .menu-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #cccccc;
  cursor: pointer;
  margin: 0 5px;
}

.component-classification-cascade-footer .menu-button .current-point {
  background-color: #1ca4f3;
}

.component-pin-map-add-row-icon {
  margin-left: 10px;
  cursor: pointer;
}

.component-pin-map-add-row-icon:hover {
  color: #1ca4f3;
}

.component-pmic-pin-library-cell {
  position: relative;
  height: 28px !important;
  overflow: hidden;
}

.component-pmic-pin-part-cell>div {
  margin-right: 30px;
}

.component-pmic-pin-part-cell .edit-part {
  color: #0b3694;
  cursor: pointer;
}

.component-pmic-pin-part-cell>.delete-icon {
  position: absolute;
  right: 2px;
  top: calc(50% - 6px);
  font-size: 12px;
  cursor: pointer;
}

.component-pmic-pin-part-cell>.add-icon {
  position: absolute;
  right: 22px;
  top: calc(50% - 7px);
  font-size: 13px;
  cursor: pointer;
}

.component-pmic-pin-part-cell>.delete-icon:hover,
.component-pmic-pin-part-cell>.add-icon:hover {
  color: #1ca4f3;
}

.component-pcb-passive-table tbody>tr>td,
.component-pcb-pin-map-table tbody>tr>td,
.component-pcb-power-nets-table tbody>tr>td {
  position: relative;
}

.classification-left {
  width: 205px;
}

.classification-left>ul {
  height: 100%;
}

.classification-right {
  width: calc(100% - 215px);
  margin-left: 15px;
  margin-bottom: 10px;
}

.classification-right .component-animation-classfication {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.classification-content {
  display: flex;
  height: 100%;
  max-height: 875px;
  overflow-x: hidden;
}

.component-pcb-power-search-net-content,
.component-pcb-transfer-search-net-content {
  width: 100%;
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 8px;
}

.component-pcb-power-search-net-log {
  color: #1890ff;
  line-height: 26px;
  margin-left: 110px;
}

.component-pcb-transfer-search-net-content .aurora-tags-input-content {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}

.component-pcb-power-search-net-content .ant-divider-vertical {
  margin: 0 4px;
  top: 8px;
}

.component-pcb-power-nets-table .ant-divider-vertical {
  margin: 0 8px;
}

.component-pcb-transfer-search-net-content .aurora-input {
  width: 100%;
  height: 28px;
}

.component-pcb-transfer-search-net-content .aurora-input .ant-input {
  font-size: 14px;
  height: 18px;
}

.component-pcb-transfer-voltage-content .aurora-input .ant-input {
  height: 28px;
}

.power-pcb-select-regular-input span.ant-input-group-addon:hover {
  cursor: pointer;
}

.power-pcb-select-regular-input span.ant-input-group-addon {
  background-color: #d6dadd;
}

.power-pcb-select-regular-input.ant-input-affix-wrapper .ant-input-suffix {
  right: 4px;
  background-color: #bee1ff;
  border-radius: 4px;
  font-size: 14px;
}

.power-pcb-select-regular-input.ant-input-affix-wrapper .ant-input-suffix div {
  padding: 0 6px;
}

.power-pcb-select-regular-input .regular-icon-selected {
  display: inline-block;
  position: absolute;
  right: 24px;
  height: 22px;
  line-height: 20px;
  padding: 0px 6px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 4px;
  background-color: #bee1ff;
}

.power-pcb-select-no-regular-input span.ant-input-group-addon {
  background-color: #fafafa;
}

.power-pcb-select-no-regular-input .regular-icon {
  display: inline-block;
  position: absolute;
  right: 24px;
  height: 20px;
  line-height: 20px;
  padding: 0px 6px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 4px;
}

.power-pcb-power-search-button {
  margin-left: 0;
  margin-top: 2px;
  font-size: 12px;
  min-width: 110px;
  line-height: 22px;
  padding: 0px 6px;
}

.power-pcb-power-search-log-button {
  margin-left: 2px;
  margin-top: 2px;
  font-size: 12px;
  min-width: 36px;
  line-height: 22px;
  padding: 0px 2px;
  margin-right: 4px;
}

.pcb-power-table-item .delete-icon {
  position: absolute;
  right: 2px;
  top: 12px;
  cursor: pointer;
  display: none;
}

.pcb-power-table-item .delete-icon:hover {
  color: #1890ff;
}

.pcb-power-nets-left-table .aurora-table-body>tr.ant-table-row-selected td,
.pcb-power-nets-right-table .aurora-table-body>tr.ant-table-row-selected td {
  background-color: #fff;
}

.pcb-power-nets-table .ant-table-container .aurora-table-body>table>.aurora-table-body>tr>td:nth-child(1) {
  text-align: center;
}

.pcb-power-nets-table .ant-table-container .aurora-table-body>table>.aurora-table-body>tr>td .pcb-power-table-item {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.component-RLC-content .custom-rlc-tabs .ant-tabs-ink-bar {
  width: 95px !important;
  margin-left: 22px;
}

.component-RLC-content .ant-tabs-nav .ant-tabs-tab {
  margin: 0 20px 0 0;
  padding: 12px 12px;
}

.component-RLC-content .aurora-top-bar-vertical-line {
  margin-right: 44px;
  vertical-align: text-bottom;
}

.component-pcb-power-nets-table .ant-table-content .ant-table-thead>tr>th {
  position: relative;
}

.power-nets-table-content {
  overflow: auto;
  height: 300px;
}

.power-nets-table-item {
  height: 28px;
  line-height: 28px;
}

.power-nets-table-target-item {
  height: 28px;
  line-height: 28px;
  border: 1px solid #ccc;
  border-top: none;
}

.component-pcb-power-nets-table .ant-transfer {
  height: 100%;
}

.ant-transfer.ant-transfer-customize-list .ant-transfer-list {
  width: 48%;
  height: 100%;
}

.component-pcb-power-nets-table .pcb-power-net-search-checkbox {
  line-height: 28px;
  font-size: 12px;
  min-width: 196px;
  padding-left: 10px;
}

.component-pcb-power-nets-table .pcb-power-net-search-checkbox .ant-checkbox+span {
  padding-right: 0px;
  padding-left: 6px;
}

.pcb-power-nets-filter-icon,
.pcb-power-nets-log-icon {
  height: 26px;
  line-height: 26px;
  margin-right: 10px;
  color: #1890ff;
}

.pcb-power-nets-log-icon {
  margin-right: 2px;
}

.pcb-power-nets-filter-icon-select,
.pcb-power-nets-log-icon-select {
  color: #2b98ff;
}

.pcb-power-nets-left-table .ant-table-small .ant-table-container .aurora-table-body>table>.aurora-table-body>tr>td {
  border: none;
}

.pcb-power-nets-clear-icon {
  position: absolute;
  right: 48px;
  height: 26px;
  line-height: 26px;
  margin: 2px;
  color: #bebebe;
  display: none;
}

.power-pcb-select-regular-input:hover .pcb-power-nets-clear-icon,
.power-pcb-select-no-regular-input:hover .pcb-power-nets-clear-icon {
  display: block;
  color: #9f9f9f;
}

/* .component-pcb-power-nets-table .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  border-right: 0;
} */

.pcb-power-nets-left-table .ant-table-bordered .ant-table-thead>tr>th {
  border-right: 0;
}

.component-pcb-power-nets-table.ant-transfer-customize-list .ant-table-wrapper .ant-table-small>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th {
  background-color: #fff;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list:nth-child(1) .ant-transfer-list-body-search-wrapper {
  display: none;
}

.pcb-power-nets-search-icon {
  position: absolute;
  right: 4px;
  height: 22px;
  line-height: 22px;
  margin: 2px;
  color: #bebebe;
}

.pcb-power-nets-search-icon:hover {
  color: #1890ff;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-search {
  height: 28px;
  line-height: 28px;
}

.component-pcb-transfer-search-net-content .aurora-input .ant-input-suffix {
  cursor: pointer;
  width: 20px;
  right: 4px;
}

.component-pcb-transfer-search-net-content .aurora-input .ant-input-suffix .regular-icon {
  width: 20px;
  text-align: center;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper,
.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  padding: 8px;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  padding-bottom: 0px;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-search-action {
  top: 6px;
}

.component-pcb-power-nets-table .ant-transfer-list .ant-transfer-list-header-selected>span {
  font-weight: 500;
}

.component-pmic-pin-error-value {
  color: red;
}

.component-pmic-tooltip-here-span {
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
}

.passive-table-part-title {
  vertical-align: middle;
}

.passive-table-part-name-text {
  word-break: break-all;
}
.pmic-part-search-layout {
  width: 100%;
  height: 100%;
}

.pmic-part-search-loading {
  width: 100%;
  height: 100%;
}

.pmic-part-search-sider {
  background-color: #f9f9f9;
}

.pmic-part-search-content {
  background-color: #fff;
  position: relative;
}

.pmic-part-search-body {
  height: 100%;
  overflow-x: auto;
}

.pmic-part-search-menu {
  height: calc(100% - 42px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f9f9f9;
}

.pmic-part-search-menu.ant-menu .ant-menu-item {
  margin-bottom: 0px;
  height: 32px;
  line-height: 32px;
}

.pmic-part-search-border {
  width: calc(100% - 20px);
  margin: 10px;
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.pmic-part-search-partname {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.pmic-part-search-partname-title {
  color: #0b3694;
  font-weight: bold;
  display: inline-block;
  width: 100px;
}

.pmic-part-search-partname-item {
  display: inline-block;
  width: calc(100% - 105px);
  font-weight: 500;
}

.pmic-part-search-pinmap {
  height: -moz-fit-content;
  height: fit-content;
}

.pmic-part-search-collapse {
  margin: 15px 0px;
}

.pmic-part-panel-extra-icon .anticon {
  margin: 0 5px;
}

.pmic-part-panel-extra-icon .anticon:last-child {
  margin-right: 0px;
}

.pmic-part-panel-extra-icon .anticon:hover,
.pmic-part-add-pinmap-icon:hover,
.pmic-part-search-partname-delete-icon:hover {
  color: #40a9ff;
  cursor: pointer;
}

.pmic-part-click-tip-editable-cell {
  color: #9f9f9f;
}

.pmic-part-click-tip-editable-cell:hover {
  color: #40a9ff;
}

.pmic-part-power-transfer .ant-transfer-operation {
  margin: 0 5px;
  width: 25px
}

.pmic-part-power-select-panel {
  box-shadow: 2px 4px 7px 5px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.pmic-part-power-select-panel .power-select-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  width: 100%;
  display: inline-block;
}

.pmic-part-power-select-panel .power-select-content .power-select-apply {
  width: calc(100% - 100px);
  float: left;
  padding-top: 2px;
}

.pmic-part-power-select-panel .power-select-content .power-select-apply .power-select-apply-tip {
  margin-right: 20px
}

.pmic-part-power-select-panel .power-select-content .power-select-apply .power-select-apply-select {
  width: calc(100% - 85px);
}

.pmic-part-power-select-panel .power-select-comp-select {
  padding: 10px 16px;
  padding-bottom: 0px;
}

.pmic-part-power-select-panel .power-select-comp-select>span {
  display: inline-block;
  width: 150px
}

.pmic-part-power-select-panel .power-select-comp-select>.aurora-select {
  width: calc(100% - 160px);
}

.pmic-part-power-select-apply-select-dropdown {
  z-index: 2000;
}

.pmic-part-power-select-panel .ant-spin-nested-loading,
.pmic-part-power-select-panel .ant-spin-container {
  width: 100%;
  height: 100%;
}

.pmic-part-power-select-title {
  font-size: 16px;
  font-weight: 500;
}

.pmic-part-power-select-btn {
  height: 28px;
}

.pmic-part-power-select-panel .pmic-part-power-transfer-outside {
  display: inline-flex;
  width: 100%;
  height: 90%;
  padding: 16px;
}

.pmic-part-power-select-panel .pmic-part-power-transfer-outside-has-select {
  height: 80%
}

.pmic-part-power-transfer-outside .pmic-part-power-transfer {
  font-weight: 500;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* .pmic-part-power-transfer-outside .ant-btn {
  margin: 20px 0px;
} */

.pmic-part-power-select-panel .power-select-content-button {
  text-align: right;
  padding: 0;
  padding-bottom: 16px;
  padding-right: 16px;
}

.pmic-part-pin-map-content-table .ant-table-small>.ant-table-content>.ant-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 28px;
  position: relative;
}

.pmic-part-search-input-search {
  padding: 5px;
  border-right: 1px solid #e8e8e8;
}

.pmic-part-search-pinmap-title {
  width: 60px;
}

.pmic-part-panel-header-input {
  width: calc(100% - 120px);
}


.pmic-part-panel-header-title {
  display: inline-flex;
  width: calc(100% - 100px);
}

.pmic-part-panel-extra-icon {
  margin-top: 5px;
  display: inline-flex;
}

.pmic-part-panel-header-title>span {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pmic-part-pin-map-table {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  position: relative;
  margin: 20px;
}

.pmic-part-pin-map-table .pmic-part-table-content {
  margin-top: 10px;
}

.pmic-part-quick-setup-title-icon {
  font-size: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.pmic-part-quick-setup-title {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.pmic-part-quick-setup-radio {
  display: flex;
  align-items: center;
  flex: 1 1
}

.pmic-part-quick-setup-radio-content>span {
  display: inline-block;
  width: 40px;
  text-align: center;
}

.pmic-part-quick-setup-radio-content>span:last-child {
  border-left: 1px solid #9f9f9f;
}

.pmic-part-quick-setup-radio-group {
  display: inline-flex;
  margin-top: 10px;
  width: 100%;
}

.pmic-part-quick-setup-radio-group-button {
  text-align: right;
  margin-top: 10px;
  width: 100%;
}

.pmic-part-quick-setup-radio-group-button>button {
  margin: 0 5px;
}

.pmic-part-search-menu-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pmic-part-select-map-checkbox {
  margin-right: 5px;
}

.pmic-part-search-collapse .ant-collapse-header-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-select-dropdown {
  padding: 5px;
  overflow: auto;
  max-height: 400px;
}

.tree-select-file-folder,
.tree-select-folder {
  height: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  padding: 2px 0px;
  cursor: pointer;
}

.tree-select-folder-icon {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.45);
}

.tree-select-file {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 2px 0px;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}

.tree-select-file:hover,
.tree-select-file-ul li:hover {
  background: #d5ecff;
}

.tree-select-file-ul {
  margin: 0px;
  padding-left: 0px;
}

.tree-select-file-ul li {
  background-color: #f4f4f4;
  padding-left: 17px;
  line-height: 22px;
  font-size: 13px;
  cursor: pointer;
}

.tree-select-file-folder-icon {
  font-size: 13px;
}

.tree-selected-file {
  background: #b9ecff;
}

.tree-select-file-background {
  background-color: #f4f4f4;
}
.spice-netlist-model-content .ant-input {
  height: 28px;
  font-size: 12px;
}

.spice-netlist-model-content {
  height: calc(100% - 50px);
}

.spice-connector-content {
  display: flex;
  font-weight: 500;
  padding: 6px 10px;
}

.spice-model-single-select .spice-netlist-model-select {
  width: calc(100% - 10px);
}

.spice-netlist-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 5px 10px;
}

.spice-netlist-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

.spice-netlist-model-select>*:last-child {
  width: calc(100% - 100px);
  display: inline-block;
}

.spice-netlist-model-select .spice-netlist-model-selection,
.spice-netlist-model-select .spice-netlist-model-selection .ant-select-selection {
  height: 24px;
  line-height: 22px;
}

.spice-netlist-model-select .ant-select-selector,
.spice-netlist-model-select .spice-netlist-model-selection-item,
.spice-netlist-model-select .ant-select-selection {
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.spice-netlist-model-dropdown,
.nodes-list-file-dropdown {
  z-index: 2002 !important;
}

.nodes-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.nodes-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.node-list-body-with-search {
  height: 100%;
}

.node-list-body-search-wrapper {
  width: 100%;
}

.node-list-body-search-wrapper .ant-input-affix-wrapper {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.node-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 20px;
  line-height: 20px;
  font-size: 13px;
}

.node-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.node-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.node-list-ul>li:hover {
  background-color: #cfedff;
}


.node-list-ul>.current-pin-selected-node-li:hover {
  background-color: #93e6ff;
}

.spice-connect-content {
  display: flex;
  font-weight: 500;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-height: 100px;
  margin: 10px 10px;
  margin-bottom: 0px;
  margin-top: 6px;
  min-width: 630px;
}

.spice-pin-list-ul {
  height: 100%;
  margin: 0;
  padding: 8px 10px;
  list-style: none;
  /* min-width: 630px; */
  width: -moz-fit-content;
  width: fit-content;
}

.spice-pin-connect-node-item {
  display: flex;
  justify-content: left;
  height: 56px;
  width: 100%;
  /* min-width: 610px; */
  align-items: center;
  font-size: 12px;
  width: -moz-fit-content;
  width: fit-content;
}

.spice-pin-list-ul .spice-pin-connect-node-item-center {
  /* justify-content: center; */
  padding: 0 10px;
  /* position: relative; */
}

.spice-pin-list-ul .spice-pin-connect-node-item-center-model {
  justify-content: center;
}

.spice-pin-node-item-center {
  justify-content: center;
}

.spice-box {
  flex: 0 0 auto;
  width: 200px;
  min-width: 160px;
  background-color: #eeeeee;
  height: 100%;
  border-bottom: 1px solid #d9d9d9;
  text-align: center;
  font-size: 12px;
  padding: 8px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: grid;
  align-content: center;
}

.spice-box>.spice-box-signal-text {
  display: block;
  line-height: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 4px;
}

.spice-box>.spice-box-net-text {
  display: block;
  line-height: 20px;
  color: #005a9e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 4px;
  max-width: 200px;
}

.spice-box-top {
  border-radius: 4px 4px 0px 0px;
}

.spice-box-bottom {
  border-radius: 0px 0px 4px 4px;
  border-bottom: none;
}

.spice-pin-die-box {
  margin: 0px 8px;
  flex: 0 0 auto;
  width: 80px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spice-pin-box {
  margin: 0px 8px;
  flex: 0 0 auto;
  width: 80px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spice-pin-line {
  width: 20px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  transform: scaleY(0.2);
}

.spice-pin-half-line {
  width: 10px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  transform: scaleY(0.2);
}

.spice-pin-circle {
  width: 8px;
  height: 8px;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
}

.spice-pin-input,
.spice-pin-select {
  flex: 0 0 auto;
  text-align: center;
  max-width: 80px;
  width: 80px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
}

.spice-pin-input .ant-input:not(:last-child) {
  padding-right: 11px;
}

.spice-pin-input:hover>span:not(.anticon) {
  max-width: 65px;
}

.spice-pin-input>span:not(.anticon) {
  display: inline-block;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* node select model */
.spice-node-select-Popover {
  z-index: 2001;
  display: flex;
}

.spice-port-select-Popover {
  z-index: 2001;
}

.spice-node-select-Popover .ant-popover-inner-content,
.spice-port-select-Popover .ant-popover-inner-content {
  padding: 0px 0px;
}

.spice-node-select-Popover .ant-popover-content,
.spice-port-select-Popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}

.node-list-body-search-wrapper .ant-input {
  border-radius: 4px 4px 0px 0px;
}

.node-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.node-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.node-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.node-list-ul>.node-li-selected,
.node-list-ul>.node-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.node-list-ul>.current-pin-selected-node-li {
  background-color: #93e6ff;
}

.spice-pin-die-value-box,
.spice-pin-value-box {
  height: 28px;
}

.spice-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
}

.spice-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.spice-pin-input:hover .spice-pin-node-delete-icon {
  display: inline-block;
}

.nodes-list-file-select {
  width: 100%;
  font-size: 12px;
  margin-bottom: 6px;
  height: 28px;
}

.nodes-list-file-select {
  height: 28px;
}

.nodes-list-file-select .ant-select-selector {
  line-height: 26px;
}

.spice-model-add-file-icon {
  margin-left: 10px;
  color: #3da4fa;
  font-size: 14px;
  vertical-align: text-bottom;
}

.spice-model-multi-select {
  display: flex;
  position: relative;
  width: 100%;
}

.spice-netlist-model-select-content {
  width: calc(100% + 10px);
  ;
}

.spice-model-single-select {
  position: relative;
  width: 100%;
}

.spice-model-file-error-msg {
  display: block;
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.spice-model-single-select:hover .spice-model-delete-file-icon,
.spice-model-multi-select:hover .spice-model-delete-file-icon {
  visibility: visible;
}

.spice-model-delete-file-icon {
  color: #3da4fa;
  font-size: 13px;
  visibility: hidden;
  position: absolute;
  right: 2px;
  top: 10px;
}

.spice-netlist-model-select .Subckt {
  width: 0px;
}

.spice-netlist-model-select .Subckt_select {
  width: 100%;
}

.spice-model-multi-select .spice-netlist-model-select {
  padding-left: 5px;
}

.spice-model-multi-select>*:first-child {
  padding-right: 5px;
  padding-left: 10px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-title {
  font-size: 14px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-list {
  background: #f2f2f2;
}

/* multi rank */
.spice-netlist-model-select .ant-switch {
  width: unset;
  display: inline-block;
}

.spice-multi-rank-setting {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 5px 10px;
}

.spice-multi-rank-setting .spice-multi-rank-span {
  width: 100px;
  height: 24px;
  line-height: 22px;
  text-align: right;
  padding-right: 10px;
}

.spice-multi-rank-setting .ant-input {
  height: 22px;
  width: calc(50% - 110px);
}

.spice-multi-rank-setting .ant-select {
  width: calc(50% - 100px);
}

.spice-multi-rank-setting .ant-select .ant-select-selection {
  height: 24px;
  line-height: 22px;
}

.spice-total-pin-box {
  display: inline-flex;
  align-items: center;
  height: 32px;
}

.spice-multi-rank-setting .ant-select .ant-select-selection .ant-select-selector {
  line-height: 22px;
  font-size: 13px;
}

.spice-netlist-model-title {
  display: flex;
  /*   justify-content: center; */
  align-items: flex-start;
}

.spice-netlist-model-title>.spice-netlist-model-title-div {
  width: calc(50% - 100px);
  color: #005a9e;
  line-height: 32px;
  height: 32px;
  text-align: center;
}

.spice-netlist-model-title>.spice-netlist-model-title-new {
  padding-left: 20px;
  width: 200px;
  position: relative;
}

.spice-netlist-model-title>.spice-netlist-model-title-net {
  /* width: 200px; */
  width: 33px;
}

.spice-netlist-model-title-div .spice-netlist-model-title-pcb-add-icon {
  color: #0d87f7;
  font-size: 19px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: text-bottom;
  position: absolute;
  right: -9px;
  top: 7px;
  z-index: 20;
}

.spice-netlist-model-title-div .spice-netlist-model-title-pcb-add-icon-disabled {
  color: rgba(0, 0, 0, 0.55);
  cursor: not-allowed;
}

.spice-netlist-model-title-pcb-delete-icon {
  color: rgba(0, 0, 0, 0.55);
  font-size: 13px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: text-top;
}

.spice-netlist-model-title-div:hover .spice-netlist-model-title-pcb-delete-icon {
  color: #0d87f7;
}

.spice-netlist-model-menu {
  z-index: 1000000000;
}

.spice-netlist-model-ports {
  display: flex;
  justify-content: center;
  height: 62px;
  align-items: center;
}

.spice-netlist-model-ports-background {
  background: #efefef;
}

.spice-netlist-last-model-ports {
  padding-right: 10px;
}

.spice-box-rename-input {
  margin: auto;
  max-width: 80px;
  /* width: 80px;
  min-width: 50px; */
}

/* .spice-box-display {
  min-width: 105px;
} */

.spice-search-node-icon {
  color: rgba(0, 0, 0, .25);
}

.node-list-ul>.node-list-ul {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  list-style: none;
  min-width: 20px;
  max-height: 100px;
  border-radius: unset;
}

.node-list-ul>.node-list-ul>li {
  padding-left: 18px;
}

.node-list-ul>.node-list-group-title {
  height: 24px;
  line-height: 24px;
  padding: 0px 6px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  background-color: #f0f0f0;
}
.netlist-model-selection {
  width: 100%;
}

.netlist-model-row .ant-select-selector,
.netlist-model-row .netlist-model-selection,
.netlist-model-row .netlist-model-selection-item,
.netlist-model-row .ant-select-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.netlist-model-dropdown {
  z-index: 10000000;
}

.netlist-model-item-select {
  width: 50%;
}

.netlist-model-content .ant-input {
  height: 24px;
}

.netlist-model-row {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-weight: 500;
}

.netlist-model-row>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
}

.netlist-model-row>*:last-child {
  width: calc(100% - 100px);
}

.netlist-model-table {
  padding: 10px 10px 0 10px;
}

.netlist-model-simall-row {
  display: inline-block;
  width: 50%;
}

.netlist-model-table-rows {
  margin-top: 10px;
}

.netlist-model-table-rows th,
.netlist-model-table-rows td {
  padding: 0 8px !important;
  font-size: 13px;
  font-weight: 500;
  text-align: center !important;
  height: 33px;
  line-height: 33px;
  color: rgba(0, 0, 0, 0.65) !important;
}

.netlist-model-table-rows .editable-cell-value-wrap {
  height: 24px;
  line-height: 23px;
}

.spice-pin-node-pairs-item-center .spice-total-pin-box {
  height: 40px;
}

.spice-pin-model-TX-main,
.spice-pin-model-RX-main {
  width: 50px;
  height: 80px;
  position: relative;
}

.spice-pin-model-Channel-main {
  width: 70px;
  height: 80px;
  position: relative;
}

.spice-pin-model-TX-content,
.spice-pin-model-RX-content {
  width: 0;
  height: 0;
  border-left: 48px solid skyblue;
  border-top: 35px solid transparent;
  border-bottom: 35px solid transparent;
  position: relative;
  margin-top: 5px;
}

.spice-pin-model-TX-title,
.spice-pin-model-RX-title {
  width: -moz-fit-content;
  width: fit-content;
  height: 80px;
  line-height: 80px;
  position: absolute;
  left: 2px;
  top: 0px;
}

.spice-pin-model-TX-title>span,
.spice-pin-model-RX-title>span {
  white-space: nowrap;
  font-size: 12px;
}

.spice-pin-model-Channel-content {
  width: 70px;
  height: 80px;
  background-color: skyblue;
  text-align: center;
  line-height: 80px;
}

.spice-pin-model-Channel-title {
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 12px;
}

.spice-pin-node-pairs-item-center .spice-box {
  width: 100px;
  min-width: 100px;
}

.spice-pin-node-pairs-item-center .spice-pin-select {
  min-width: 60px;
  width: 70px;
  max-width: 100px;
}

.spice-pin-model-tx-rx-main {
  margin-left: -32px;
}

.spice-pin-model-left-main,
.spice-pin-model-right-main {
  display: flex;
  height: 80px;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 19px;
  justify-content: space-between;
}

.spice-pin-model-left-main .spice-pin-line,
.spice-pin-model-right-main .spice-pin-line {
  width: 40px;
}

.spice-pin-model-line-TX-main>.spice-pin-line,
.spice-pin-model-line-RX-main>.spice-pin-line {
  width: 130px;
}

.spice-pin-node-pairs-item-center {
  margin: 0 auto;
}

.spice-pin-node-pairs-item-center .spice-pin-list-li-left,
.spice-pin-node-pairs-item-center .spice-pin-list-li-right {
  width: min-content;
  display: flex;
  flex-direction: column;
  height: 80px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 200px;
  background-color: #eeeeee;
  height: 100%;
  text-align: center;
  padding: 0px 8px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-box-signal-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  width: 100%;
  max-width: 100%;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-box-title {
  height: 80px;
  text-align: center;
  line-height: 80px;
  width: 48px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-pin-list-li-left .spice-pin-die-box,
.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-pin-list-li-right .spice-pin-die-box {
  position: absolute;
  width: 128px;
  text-align: center;
  padding: 0px 4px;
  margin: 0px 0px;
  margin-bottom: 18px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-pin-list-li-left .spice-pin-die-box {
  left: -128px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-pin-list-li-right .spice-pin-die-box {
  right: -128px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content>.spice-pin-list-li-left .spice-pin-die-bottom-box,
.spice-pin-node-pairs-item-center .spice-pin-connect-model-content>.spice-pin-list-li-right .spice-pin-die-bottom-box {
  margin-top: 18px;
  margin-bottom: 0px;
}

.netlist-node-current {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #687A8F;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.rlc-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.rlc-model-title {
  font-size: 14px;
  font-weight: 500;
}

.rlc-model-panel .rlc-model-content {
  margin: 10px;
  overflow: hidden;
  font-size: 13px;
}

.rlc-model-selection {
  width: 100%;
}

.rlc-model-row .rlc-model-selection .ant-select-selector,
.rlc-model-row .rlc-model-selection,
.rlc-model-row .rlc-model-selection .ant-select-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.rlc-model-row .rlc-model-selection .ant-select-selection--multiple {
  min-height: 24px;
}

.rlc-model-row .ant-select-selection--multiple .ant-select-selection__choice__content {
  font-size: 12px;
}

.rlc-model-row .rlc-model-selection .ant-select-selection--multiple .ant-select-selector>ul>.ant-select-search--inline {
  margin-top: 0px;
  font-size: 14px;
}

.rlc-model-select-dropdown {
  z-index: 10000000;
}

.sierra-package-model-item-select {
  width: 50%;
}

.rlc-model-panel .ant-input {
  height: 24px;
}

.rlc-model-row {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-weight: 600;
}

.rlc-model-row>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
}

.rlc-model-row>*:last-child {
  width: calc(100% - 100px);
}

.rlc-sweep-create {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 0 10px;
  margin-top: 10px;
  justify-content: flex-end;
}

.rlc-value-tagsInput {
  width: 100%;
}

.rlc-sweep-create-btn {
  height: 28px;
}
.virtual-table {
  margin: 0px;
  margin-right: 15px;
  max-height: 40px !important;
}

.virtual-table .ant-table-placeholder {
  display: none;
}

.virtual-table .ant-table-empty .aurora-table-body {
  overflow-x: auto;
}

.virtual-table-without-scroll-Y {
  margin-right: 0px !important;
}

/* .virtual-table-body {
  overflow: hidden;
  text-overflow: ellipsis;
} */

#virtual-table-body {
  overflow-anchor: none
}

.virtual-table .ant-table-row:hover>td {
  background-color: #e6f7ff;
}

.virtual-table .ant-table-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9
}

.virtual-table .ant-table-row>td>.editable-cell-value-wrap.ant-table-cell {
  padding: 0 8px;
}

.sierra-part-library-panel {
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.part-library-content {
  width: 100%;
  padding: 10px 12px;
  min-width: 300px;
}

.part-library-upload-content {
  /* width: 100%; */
  /*   display: flex; */
  /*  justify-content: space-between; */
  /*   justify-content: flex-end; */
  float: right;
  /*   height: 28px; */
  /*  margin: 8px 0px; */
  margin-right: 10px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  text-align: center;
}

.part-library-upload-button,
.part-library-add-button,
.part-library-check-button {
  /*  color: #1890ff; */
  padding: 0px 8px;
}

.part-library-content .aurora-model-name-error-msg,
.part-library-content .aurora-success-msg {
  margin-bottom: 6px;
}

.part-repeater-pin-library-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  position: relative;
  width: 100%;
}

.part-library-dialog-search {
  margin-bottom: 8px;
}

.part-library-dialog-search .aurora-input {
  width: 100%;
}

.sierra-part-library-check-file-icon {
  color: #1890ff;
  cursor: pointer;
  margin-left: 10px;
  vertical-align: text-bottom;
}

.sierra-part-library-help-icon,
.sierra-part-library-delete-icon,
.sierra-part-library-position-add-icon {
  color: #1890ff;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 2px;
  top: 6px;
}

.sierra-part-library-table-part-text .sierra-part-library-delete-icon {
  color: #ccc;
  font-size: 14px;
  display: none;
  top: 7px;
}

.sierra-part-library-table-part-text:hover .sierra-part-library-delete-icon {
  color: #1890ff;
  display: block;
}

.sierra-part-library-table-part-text .sierra-part-library-position-add-icon {
  color: #ccc;
  font-size: 14px;
  display: none;
  top: 4px;
  /*   right: 22px; */
}

.sierra-part-library-table-part-text:hover .sierra-part-library-position-add-icon {
  color: #1890ff;
  display: block;
}

.sierra-part-library-table-text,
.sierra-part-library-table-part-text {
  width: 100%;
  position: relative;
  min-height: 22px;
}

.sierra-part-library-table-text>span:not(.anticon),
.sierra-part-library-table-part-text>span:not(.anticon) {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sierra-part-library-table-part-text:hover>span:not(.anticon) {
  padding-right: 17px;
}

.sierra-part-library-add-icon {
  cursor: pointer;
  color: #1890ff;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 8px;
}

.sierra-part-repeater-part-title {
  vertical-align: middle;
}

/* check file panel */

.part-library-check-files-content {
  padding: 8px 10px;
  font-weight: 600;
  background: #f0f0f0;
  border: 1px dashed #ccc;
  border-radius: 4px;
}

.part-library-check-files-content .part-library-check-files-no-data {
  color: #ccc;
  font-weight: normal;
  text-align: center;
}

/* repeater connections panel */
.sierra-repeater-connections-table tbody>tr>td:last-child {
  position: relative;
}

.sierra-repeater-connections-table .delete-icon {
  position: absolute;
  right: 2px;
  top: calc(50% - 6px);
}

.sierra-repeater-connections-table .delete-icon:hover {
  color: #1890ff;
}

.sierra-repeater-connections-table tbody>tr>td:last-child .editable-cell-value-wrap {
  width: calc(100% - 6px);
}

.sierra-repeater-connections-content {
  padding: 10px 14px;
}

.sierra-repeater-connections-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-repeater-connections-add-button {
  height: 24px;
  font-size: 13px;
}

.repeater-model-pin-input,
.repeater-model-pin-output {
  display: flex;
  width: calc(50% - 30px);
}

.repeater-model-pin-input {
  margin-right: 10px;
  justify-content: right;
}

.repeater-model-pin-output {
  margin-left: 10px;
  justify-content: left;
}

.repeater-model-pin-content {
  margin: 0;
  padding: 0;
  border: 1px dashed #ccc;
  background: #f0f0f0;
  border-radius: 4px;
  margin: 5px 5px;
}

.sierra-repeater-connections-content .spice-model-single-select .spice-netlist-model-select {
  width: 100%;
  padding: 5px 5px;
  padding-right: 20px;
}

.repeater-model-pin-item {
  display: flex;
  justify-content: center;
  height: 28px;
  line-height: 28px;
  margin: 10px 0px;
  align-items: center;
}

.repeater-model-pin-title {
  height: 28px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80px;
  min-width: 20px;
  margin: 0px 10px;
  text-align: center;
}

/* .repeater-model-pin-input .repeater-model-pin-title {
  text-align: left;
}

.repeater-model-pin-output .repeater-model-pin-title {
  text-align: right;
} */

.repeater-model-pin-item-title .repeater-model-pin-input {
  margin-right: 30px;
}

.repeater-model-pin-item-title .repeater-model-pin-output {
  margin-left: 30px;
}

.repeater-model-pin-content .spice-pin-line {
  width: 40px;
}


.repeater-model-pin-content .repeater-spice-pin-input {
  min-width: 80px;
  font-size: 12px;
}

.repeater-model-pin-content .repeater-spice-pin-input .ant-input {
  text-align: center;
}

.repeater-model-pin-content .repeater-spice-pin-input:hover .ant-input-clear-icon {
  visibility: visible;
}

.repeater-model-pin-content .repeater-spice-pin-input .ant-input-clear-icon {
  visibility: hidden;
}

/* .node-list-ul>.node-list-ul {
  height: fit-content;
  width: 100%;
  list-style: none;
  min-width: 20px;
  max-height: 100px;
  border-radius: unset;
}

.node-list-ul>.node-list-ul>li {
  padding-left: 18px;
}

.node-list-ul>div {
  height: 24px;
  line-height: 24px;
  padding: 0px 6px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  background-color: #f0f0f0;
}
 */
.sierra-repeater-connections-content .spice-model-multi-select>*:first-child {
  padding-left: 5px;
}

.repeater-model-pin-item .spice-pin-input .ant-input {
  height: 28px;
  line-height: 28px;
  padding: 4px 6px;
}

.repeater-model-pin-item .spice-pin-input .ant-input-suffix {
  right: 6px;
}

.repeater-model-pin-item .spice-pin-input .ant-input-suffix {
  display: none;
}

.repeater-model-pin-item .spice-pin-input:hover .ant-input-suffix {
  display: flex;
}

.repeater-model-pin-copy-content {
  padding-left: 12px;
  display: none;
}

.repeater-model-pin-copy-icon {
  color: #1890ff;
  cursor: pointer;
}

.repeater-model-pin-item:hover .repeater-model-pin-copy-content {
  display: block;
}

.part-library-menu-list {
  margin-bottom: 8px;
}

.part-library-menu-list .ant-divider-vertical {
  margin: 0px 4px;
}
.sierra-comp-pin-map-content {
  padding: 10px 14px;
}

.sierra-comp-pin-map-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.component-pmic-pin-library-cell .component-pin-map-value {
  width: 100%;
}

.sierra-components-pin-map-columns {
  width: 100%;
  height: 100%;
}

.sierra-components-pin-map-columns-select-dropdown {
  z-index: 30000;
}

.sierra-comp-pin-map-table tbody>tr>td:last-child {
  position: relative;
}

.sierra-comp-pin-map-table .delete-icon {
  position: absolute;
  right: 2px;
  top: calc(50% - 6px);
  display: none;
}

.sierra-component-pcb-repeater-table .component-pmic-pin-part-cell>.add-icon,
.sierra-component-pcb-repeater-table .component-pmic-pin-part-cell>.delete-icon {
  display: none;
}

.sierra-component-pcb-repeater-table .component-pmic-pin-part-cell>div {
  margin-right: 16px;
}

.sierra-component-pcb-repeater-table tbody>tr:hover .component-pmic-pin-part-cell>.delete-icon,
.sierra-component-pcb-repeater-table tbody>tr:hover .component-pmic-pin-part-cell>.add-icon,
.sierra-comp-pin-map-table tbody>tr:hover .delete-icon {
  display: block;
}

.sierra-comp-pin-map-table .delete-icon:hover {
  color: #1890ff;
}

.sierra-comp-pin-map-table tbody>tr>td:last-child .editable-cell-value-wrap {
  width: calc(100% - 6px);
}

.sierra-component-pcb-repeater-table tbody>tr>td:nth-child(3) {
  max-height: 80px;
  overflow: auto;
}

.sierra-comp-pin-map-add-button {
  height: 24px;
  font-size: 13px;
  /*  margin: 8px 0px; */
}

.sierra-component-pcb-repeater-table {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  position: relative;
}

.sierra-component-pcb-repeater-table tbody>tr>td {
  position: relative;
}

.sierra-comp-repeater-part-switch-icon {
  color: #3da4fa;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.sierra-comp-repeater-part-title {
  vertical-align: middle;
}

.sierra-pmic-pin-library-value {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  position: relative;
  cursor: pointer;
  height: 28px;
}

.sierra-nets-filter-library-panel {
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.nets-filter-library-content {
  width: 100%;
  padding: 10px 12px;
  min-width: 300px;
}

.nets-filter-library-upload-button,
.nets-filter-library-add-button {
  padding: 0px 8px;
}

.nets-filter-library-content .aurora-model-name-error-msg,
.nets-filter-library-content .aurora-success-msg {
  margin-bottom: 6px;
}

.nets-filter-menu-list {
  margin-bottom: 8px;
}

.nets-filter-menu-list .ant-divider-vertical {
  margin: 0px 4px;
}

.sierra-nets-filter-library-table-part-text {
  width: 100%;
  min-height: 22px;
}

.sierra-nets-filter-library-table-part-text>span:not(.anticon) {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nets-filter-library-content .nets-filter-library-search-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(1) {
  text-align: center;
}

/* .nets-filter-library-content .nets-filter-library-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(1):hover, */
.nets-filter-library-content .nets-filter-library-search-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(2):hover {
  padding-right: 17px;
}

.nets-filter-library-content .nets-filter-library-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(1),
.nets-filter-library-content .nets-filter-library-search-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(2) {
  position: relative;
}

.nets-filter-library-content .nets-filter-library-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(1):hover .sierra-nets-filter-library-delete-icon,
.nets-filter-library-content .nets-filter-library-search-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(2):hover .sierra-nets-filter-library-delete-icon {
  color: #1890ff;
  display: block;
}

.sierra-nets-filter-library-table-part-text .sierra-nets-filter-library-delete-icon {
  color: #ccc;
  font-size: 14px;
  display: none;
  top: 12px;
  position: absolute;
  right: 2px;
}


.sierra-nets-filter-item {
  height: 28px;
  line-height: 28px;
  margin: 6px 0px;
}

.sierra-nets-filter-item .ant-checkbox-wrapper {
  margin-right: 10px;
}

.sierra-nets-filter-apply-item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  text-align: center;
}

.sierra-nets-filter-item .ant-btn {
  height: 28px;
  padding: 0 10px
}

.nets-filter-library-table .aurora-table-body>tr.ant-table-row-selected td {
  background-color: #fff;
}
.tree-node .ant-btn-disabled,
.tree-node .ant-btn.disabled,
.tree-node .ant-btn[disabled],
.tree-node .ant-btn-disabled:hover,
.tree-node .ant-btn.disabled:hover,
.tree-node .ant-btn[disabled]:hover,
.tree-node .ant-btn-disabled:focus,
.tree-node .ant-btn.disabled:focus,
.tree-node .ant-btn[disabled]:focus,
.tree-node .ant-btn-disabled:active,
.tree-node .ant-btn.disabled:active,
.tree-node .ant-btn[disabled]:active,
.tree-node .ant-btn-disabled.active,
.tree-node .ant-btn.disabled.active,
.tree-node .ant-btn[disabled].active {
  color: #a7a7a7;
  border-color: #a9a9a9;
  cursor: not-allowed;
  background-color: #fff;
}

.aurora-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  --antd-arrow-background-color: #fff;
}

.aurora-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
  padding: 5px;
}

.aurora-tooltip .ant-tooltip-arrow::before {
  background-color: #fff;
}

.aurora-tooltip,
.aurora-warning-tooltip,
.aurora-error-tooltip {
  z-index: 10000000;
  max-width: 400px;
}

.aurora-warning-tooltip .ant-tooltip-inner {
  color: #fa8c16;
  background-color: #fff;
  padding: 5px;
}

.aurora-error-tooltip .ant-tooltip-inner {
  color: #ff0000;
  background-color: #fff;
  padding: 5px;
}

.aurora-tree-add-icon,
.aurora-tree-edit-icon,
.aurora-tree-del-icon,
.aurora-tree-download-icon,
.aurora-tree-rename-icon,
.aurora-tree-copy-icon,
.aurora-tree-project-rename-icon,
.aurora-tree-set-icon,
.aurora-tree-simulate-icon,
.aurora-tree-more-icon,
.aurora-tree-info-icon,
.aurora-tree-menu-icon,
.aurora-tree-sweep-rename-icon,
.aurora-tree-pcb-setting-icon,
.aurora-tree-batch-delete-icon,
.aurora-tree-combine-interface-icon,
.aurora-tree-upload-icon,
.aurora-tree-refresh-icon,
.aurora-tree-project-save-icon,
.aurora-tree-project-edit-icon,
.aurora-tree-multi-interface-setup-icon,
.aurora-tree-file-text-icon,
.aurora-tree-layout-check-icon,
.aurora-tree-layout-check-loading-icon {
  text-align: center;
  line-height: 24px;
  font-size: 15px;
  color: #1890ff;
  display: inline-block;
  padding-top: 2px;
}

.aurora-tree-more-icon {
  line-height: 26px;
}

.copy-loading-icon-visible {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #1890ff;
  display: inline-block;
  visibility: visible !important;
  right: 12px !important;
  padding-top: 2px;
}

.cascade-sider-menu-tree .ant-tree-title:hover .copy-loading-icon-visible {
  margin-right: 24px !important;
}

.aurora-menu-item-icon {
  float: none;
  line-height: 22px;
}

.aurora-tree-refresh-icon {
  margin-right: 10px;
  font-size: 14px;
}

.aurora-tree-warning-icon {
  text-align: center;
  line-height: 36px;
  font-size: 15px;
  color: rgb(255, 0, 0);
  float: right;
}

.aurora-tree-simulate-icon {
  position: absolute;
  right: 14px;
}

.aurora-tree-edit-icon {
  margin-right: 8px;
}

.aurora-tree-copy-icon {
  margin-right: 24px;
  font-size: 14px;
}

.aurora-tree-project-rename-icon,
.aurora-tree-project-edit-icon {
  margin-right: 48px;
  font-size: 14px;
  cursor: pointer;
}

.aurora-tree-sweep-rename-icon {
  margin-right: 22px;
  font-size: 14px;
  cursor: pointer;
}

.aurora-tree-rename-icon {
  margin-right: 24px;
  font-size: 14px;
  cursor: pointer;
}

.aurora-tree-create-icon {
  display: block;
  float: right;
  text-align: center;
  line-height: 24px;
  height: 22px;
  color: #1890ff;
}

.aurora-status-span {
  font-size: 15px;
  font-weight: 700;
  width: 16px;
  text-align: center;
  position: absolute;
  right: 14px;
}

.aurora-status-icon {
  font-size: 14px;
  font-weight: 500;
  float: right;
  width: 16px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}

.aurora-replace-icon {
  font-size: 13px;
  font-weight: 500;
  float: right;
  margin-right: -7px;
  width: 16px;
  text-align: center;
  line-height: 28px;
  color: #1890ff;
  cursor: pointer;
}

.icon-import-export {
  font-size: 18px;
  font-weight: 500;
  float: right;
  margin-right: -7px;
  width: 16px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}

.tree-node .ant-tree-title .aurora-tree-loading-icon {
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 14px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  margin-top: 4px;
}

.icon-loading3:before {
  display: inline-block;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg) translateZ(0);
  }

  to {
    transform: rotate(360deg) translateZ(0);
  }
}

.aurora-status-waiting {
  font-size: 13px;
}

.aurora-error-msg-tooltip .ant-tooltip-inner {
  color: #cb2431;
  background-color: #ffdce0;
  padding: 5px;
}

.aurora-error-msg-tooltip .ant-tooltip-arrow::before {
  background-color: #ffdce0;
}

.aurora-error-msg-tooltip {
  z-index: 3000;
  max-width: 320px;
}

.aurora-error-color {
  color: #cb2431;
}

/* .aurora-error-tooltip {
  width: auto;
} */

/* simulation button */

.simulation-btn {
  line-height: 18px;
  width: 68px;
  height: 20px;
  padding: 0 6px;
  margin-top: 6px;
  border: 1px solid #1890ff;
  color: #1890ff;
  position: absolute;
  right: 14px;
}

.simulation-btn-disable {
  position: absolute;
  right: 14px;
}

.simulation-btn-disable {
  line-height: 18px;
  width: 68px;
  height: 20px;
  padding: 0 6px;
  margin-top: 6px;
  border: 1px solid #1890ff;
  color: #1890ff;
}

.aurora-content {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 240px;
  right: 0px;
  bottom: 0px;
}

#aurora-switch-icon-selected {
  color: #0d87f7;
}

.aurora-layout-button-1 {
  display: inline-grid;
  width: 20px;
  height: 18px;
  border: 1px #a5a5a5 solid;
  margin-right: 8px;
  grid-template-columns: 50% 50%;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  margin-left: 7px;
  margin-top: 5px;
}

.aurora-layout-button-left {
  background: #fff;
  text-align: center;
  line-height: 36px;
  font-size: 10px;
}

.aurora-layout-button-right {
  background: #b5b5b5;
  text-align: center;
  line-height: 36px;
  font-size: 10px;
}

.aurora-layout-button-2 {
  display: inline-grid;
  width: 20px;
  height: 18px;
  border: 1px #a5a5a5 solid;
  grid-template-rows: 50% 50%;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 2px;
  margin-left: 7px;
  margin-top: 5px;
}

.aurora-layout-button-top {
  background: #b5b5b5;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
}

.aurora-layout-button-bottom {
  background: #fff;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
}

.aurora-layout-selected {
  border-color: #63aef3;
  border-style: solid;
}

.aurora-layout-selected .aurora-layout-button-right,
.aurora-layout-selected .aurora-layout-button-top {
  background: #85bcff;
}

.aurora-layout-selected-3 {
  border-color: #63aef3;
  border-style: solid;
  background: #c0def9;
}

.layout-icon-disabled .aurora-layout-button-right,
.layout-icon-disabled .aurora-layout-button-top {
  background: #c5c5c5;
}

.sider-switch-icon {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  color: #737373;
}

.sider-switch-vertical-icon {
  padding: 0 17px;
  padding-bottom: 4px;
}

.sider-switch-vertical-icon .pcb-icon {
  margin-left: -3px;
}

.sider-layout-div {
  display: inline-block;
}

.sider-layout-vertical-div {
  padding: 0 10px;
  padding-bottom: 4px;
}

.sider-layout-vertical-div .aurora-layout-button-1 {
  margin-bottom: 9px;
  margin-top: 3px;
}

.sider-layout-vertical-div .aurora-layout-button-2 {
  margin-bottom: 4px;
}

.aurora-icon-color:hover {
  color: #1890ff;
}

.aurora-icon-hover-color {
  color: #1890ff;
}

.aurora-sider-menu-tree .ant-tree-title .anticon {
  position: absolute;
  right: 14px;
  top: 0px;
  visibility: hidden;
}

.aurora-sider-menu-tree .ant-tree-title:hover .anticon {
  visibility: visible;
}

.aurora-sider-menu-tree .ant-tree-title .aurora-tree-checked-box-span {
  margin-left: 20px;
}

.aurora-sider-menu-tree .aurora-project-failed .ant-tree-title .aurora-tree-checked-box-span {
  margin-left: 0px;
}

.simulate-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.aurora-copy-popover {
  width: 300px;
}

.aurora-copy-popover .ant-popover-inner-content {
  padding: 8px;
}

.aurora-sider-menu-tree .download-progress-bar {
  height: 36px;
  width: 100%;
  float: left;
  overflow: visible;
  position: relative;
}

.aurora-sider-menu-tree .ant-tree-title .download-progress-bar .anticon {
  right: 9px;
}

.aurora-sider-menu-tree .tree-library-file .ant-tree-title .download-progress-bar .tree-library-content {
  padding-right: 40px;
}

.aurora-sider-menu-tree .tree-library-file .ant-tree-title:hover .download-progress-bar .tree-library-content {
  padding-right: 20px;
}

.aurora-sider-menu-tree .download-progress-bar::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #2486ff;
  text-align: right;
  white-space: nowrap;
  opacity: 0.4;
  overflow: hidden;
  color: #fff;
  counter-reset: progress var(--percent);
  content: "";
  transition: 0.6s;
  width: calc(1%*var(--percent));
}

.aurora-sider-menu-tree .tree-library-file .download-progress-bar-success::before {
  content: counter(progress) '%\2002';
}

.aurora-sider-menu-tree .ant-tree-title:hover .aurora-interface-type-tag,
.aurora-sider-menu-tree .ant-tree-title:hover .aurora-library-type-tag {
  display: none;
}

.aurora-interface-type-tag {
  position: absolute;
  right: 30px;
  line-height: 16px;
  height: 18px;
  font-size: 10px;
  z-index: 20;
  margin-top: 2px;
  text-align: center;
}

.aurora-library-type-tag {
  position: absolute;
  right: 5px;
  line-height: 16px;
  height: 18px;
  font-size: 10px;
  z-index: 20;
  margin-top: 6px;
  text-align: center;
}

.aurora-tag-gold {
  color: #03a341;
  background: #e8fff1;
  border-color: #009f3d;
}

/* .aurora-tag-gold {
  color: #da9100;
  background: #fff9d6;
  border-color: #da9100;
} */

.aurora-tag-green {
  color: #2f8804;
  background: #f2fde6;
  border-color: #79ab52;
}

.aurora-tag-cyan {
  color: #07a9a9;
  background: #e6fffb;
  border-color: #6ecec4;
}

.aurora-tag-brown {
  color: #9f6804;
  background-color: #fdf7e7 !important;
  border-color: #c28311;
}

.aurora-tag-purple {
  color: #9d00ff;
  /* background-color: #d380ff29 !important; */
  border-color: #7923d570;
}

.aurora-tree-verification-span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.aurora-sider-menu-tree .ant-tree-title .aurora-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.tree-node .ant-tree-title>span.aurora-add-interface-action {
  color: #ff610b;
  position: relative;
  cursor: pointer;
  line-height: 24px;
  height: 24px;
  border: 1px solid #ff610b;
  text-align: center;
  border-radius: 4px;
}

.tree-node .ant-tree-title>.aurora-add-interface-action:hover {
  color: #f87e3b;
  border: 1px solid #f87e3b;
}

.aurora-sider-menu-tree .aurora-add-interface-action>.aurora-add-interface-icon {
  position: static;
  visibility: visible;
  font-size: 11px;
  color: #ff610b;
  vertical-align: top;
  height: 22px;
  line-height: 22px;
}

.aurora-sider-menu-tree .aurora-add-interface-action>.aurora-tree-verification-span {
  width: auto;
  font-size: 12px;
  line-height: normal;
  max-width: calc(100% - 20px);
  height: 24px;
  line-height: 22px;
}

.ant-tree .aurora-tree-add-action .ant-tree-node-content-wrapper:hover {
  background-color: #f9f9f9;
}

.aurora-sider-menu-tree .aurora-tree-add-action .ant-tree-node-content-wrapper {
  margin-left: -11px;
}

.aurora-default-tree-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.aurora-default-tree-title:hover {
  width: 80%;
}

span.anticon.anticon-exclamation-circle.debug-design-icon {
  visibility: initial;
  font-size: 15px;
  line-height: 28px;
  color: #ff0000;
  display: inline-block;
}

span.anticon.anticon-exclamation-circle.identify-failed-design-icon {
  color: #fa8c16;
  visibility: visible;
  font-size: 15px;
  line-height: 36px;
  display: inline-block;
}

span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .debug-design-icon,
span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .identify-failed-design-icon {
  right: 40px;
}

span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .rocky-card .debug-design-icon,
span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .rocky-card .identify-failed-design-icon {
  right: 64px;
}
.aurora-library-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.aurora-library-panel .library-content-box {
  padding: 8px 14px;
  min-width: 500px;
}

.aurora-library-panel .library-edit-value-label {
  display: inline-block;
  width: 90px;
  vertical-align: middle;
}

.library-edit-value-label-via {
  width: 150px;
}

.aurora-library-panel .library-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  height: 28px;
}

.aurora-library-panel .library-select,
.aurora-library-panel .library-input {
  width: calc(100% - 90px);
}

.aurora-library-panel .library-input-via {
  width: calc(100% - 150px);
}

.aurora-library-panel .library-png {
  margin: 24px 0px;
  width: 100%;
  text-align: center;
  padding: 0px 16px;
}

.aurora-library-panel .library-png img {
  width: 84%;
}

.aurora-library-panel .library-item-half {
  width: calc(50% - 16px);
  float: left;
  margin-bottom: 8px;
  margin-top: 0px;
}

.aurora-library-panel .library-zd .library-item-zd-half-right {
  width: calc(50% - 44px);
}

.aurora-library-panel .library-item-half .library-edit-value-label {
  width: 76px;
}

.aurora-library-panel .library-item-half .library-select,
.aurora-library-panel .library-item-half .library-input {
  width: calc(100% - 76px);
}

.aurora-library-panel .library-table {
  margin: 8px 8px 16px 6px;
}

.aurora-library-panel .library-item-zd .library-edit-value-label {
  text-align: center;
}

.aurora-library-panel .library-item-zd .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.pre-layout-layers-unit-selection .ant-select-selection--single {
  height: 26px;
}

.pre-layout-layers-unit-selection .ant-select-selection__rendered {
  line-height: 24px;
}

.pre-layout-layers-unit-selection .ant-select-arrow {
  right: 6px;
}

.pre-layout-layers-unit-option {
  z-index: 2000;
}

.aurora-library-panel .library-png.library-png-margin {
  margin: 0px 0px;
}

.aurora-library-panel .library-zd {
  margin: 0px;
  margin-bottom: 4px;
  height: 32px;
  line-height: 28px;
  position: relative;
}

.aurora-library-panel .library-zd .library-zd-calculate-icon {
  width: 20px;
  margin-left: 10px;
  color: #1890ff;
  cursor: pointer;
  line-height: 28px;
  font-size: 16px;
  position: absolute;
  top: 6px;
  right: calc(50% - 20px);
}

.aurora-library-panel .library-zd .library-zd-calculate-log-icon {
  right: calc(50% - 44px);
}

.aurora-library-panel .library-zd .ant-spin-nested-loading {
  height: 28px;
  line-height: 28px;
  width: 100%;
}

.aurora-library-panel .library-zd .library-zd-calculate-icon.library-zd-calculate-log-half-icon {
  right: 8px;
}

.aurora-library-panel .library-zd .library-zd-calculate-icon.library-zd-calculate-half-icon {
  right: 32px;
}

.aurora-trace-log-panel .aurora-msg-dialog-item {
  color: rgba(0, 0, 0, 0.85);
}

.aurora-trace-log-panel .aurora-msg-dialog-main {
  padding: 8px;
}

.aurora-library-panel .library-item-zd-half,
.aurora-library-panel .library-item-zd {
  margin-bottom: 0px;
}
/*TODO*/

.simulation-progress .ant-progress-inner {
  background: #ddd;
}

.simulation-progress .ant-progress-text {
  color: rgba(0, 0, 0, 0.6);
}

.setting-signal-expand {
  margin: 0px !important;
}

.setting-signal-expand .ant-table-small>.ant-table-content>.aurora-table-body {
  padding: 0px;
  margin: 0px;
}

.setting-signal-expand .ant-table-small,
.setting-signal-expand .ant-table-small.ant-table-bordered .ant-table-content {
  border: 0px !important;
}

.component-pin-tabs .ant-tabs-bar {
  margin: 0px;
}

.pintopin-signal-row {
  margin-top: 20px;
}

.component-pin-tabs .ant-tabs-tab {
  font-weight: bold;
}

.pintopin-warning-dialog {
  padding: 10px;
  border: 2px solid #ff9898;
  border-radius: 4px;
  background: #f9f9f9;
  position: absolute;
  top: 45px;
  bottom: 6px;
  width: 98%;
}

.pintopin-warning-title {
  color: #ff4848;
  margin-top: 10px;
  display: inline-block;
}

.footer-monitor {
  width: 99%;
  background: #f4f4f4;
  min-width: 980px;
}

.log-box {
  float: right;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.monitor-log {
  overflow: auto;
  border: 2px solid rgb(182, 182, 182);
  margin-top: 10px;
  background: #fafafa;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  top: 35px;
  left: -2px;
  right: -10px;
}

.error-box {
  width: 49%;
  height: 100%;
  position: absolute;
}

.closed-message {
  display: inline-block;
  width: 100%;
}

.debugButton {
  position: relative;
  z-index: 2;
}

.logButton {
  position: relative;
  z-index: 2;
}

.button-content {
  text-indent: 4px;
}

.button-icon {
  font-size: 18px;
}

.span-tooltip {
  display: inline-block;
  width: 100%;
}

.verify-footer-name {
  font-weight: bold;
  line-height: 45px;
  display: block;
  float: left;
}

.icon-explores {
  color: #0055a2;
  font-size: 20px;
  font-weight: bolder;
  font-family: serif;
  display: block;
  float: right;
  line-height: 20px;
}

.sierra-component-table .ant-table-thead>tr>th .ant-table-header-column,
.sierra-pin-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.pintopin-pin-corner-box {
  margin: -4px;
}

.pintopin-pin-corner {
  padding: 0 5px;
  line-height: 36px;
}

.pintopin-pin-corner-explor {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 6px;
  transition: 0.5s all;
  height: 14px;
  line-height: 16px;
}

.sierra-pin-table tbody>tr>td:nth-child(6),
.sierra-pin-table tbody>tr>td:nth-child(5),
.sierra-pin-table tbody>tr>td:nth-child(4),
.sierra-component-table tbody>tr>td:nth-child(4),
.sierra-component-table tbody>tr>td:nth-child(5),
.sierra-component-table tbody>tr>td:nth-child(6) {
  position: relative;
}

.sierra-component-table .ant-table-content>.aurora-table-body tbody>tr>td:nth-child(6) .editable-cell-value-wrap {
  position: relative;
}

.sierra-component-table tbody>tr>td:nth-child(6) .editable-cell-value-wrap .sierra-component-probes-td {
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 0px;
  top: 0px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pintopin-pin-corner-cell,
.pintopin-pin-stimulus-cell {
  margin-right: 26px !important;
  width: auto !important;
}

.component-pin-tabs .ant-tabs-tab,
.component-pin-tabs .ant-tabs-tab-active {
  font-weight: bold !important;
}

.log-button {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}

.download-button {
  text-indent: 24px;
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.info-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.title-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  float: left;
}

.pcb-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.verify-content-footer {
  overflow: auto;
}

.verify-footer-button {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.progress-footer-span {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
}

.debug-footer-col {
  padding-left: 30px !important;
  text-align: center;
}

.log-footer-col {
  padding-left: 5px !important;
  text-align: center;
}

.result-footer-col {
  padding-left: 0 !important;
  text-align: center;
}

.verify-footer-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.btn-width {
  width: 20%;
  max-width: 80px;
  margin-left: 10px;
  display: block;
  float: left;
}

.btn-width button {
  width: 100%;
}

.btn-width .btn-width-tooltip {
  width: 100%;
}

.monitor-button {
  min-width: 629px;
}

.monitor-top {
  margin: 0px;
  padding: 0px;
  line-height: 45px;
}

.monitor-top-col {
  padding: 0px;
}

.btn-width-12 {
  width: 12%;
}

.btn-width-max-120 {
  max-width: 110px;
}

.verify-cancel-btn {
  background: #797979;
  border: 0px;
  color: #f4f4f4;
}

.verify-cancel-btn:hover,
.verify-cancel-btn:focus {
  background: #969696;
  color: #ffffff;
}

.signal-expand-icon {
  position: absolute;
  right: 20px;
}

/* ============== PinToPin setting =================*/
.sierra-content-setting {
  min-width: 1200px;
  padding: 20px;
}

.sierra-multi-setup-content-setting {
  padding-top: 30px;
}

.sierra-sweep-content-setting {
  position: absolute;
}

.sierra-setup-title-color {
  color: #0b3694;
}

.sierra-content-block-border {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #a8c4e6;
}

.width-33 {
  width: 33%;
}

.width-25 {
  width: 25%;
}

.sierra-signal-nets-table {
  margin-top: 10px;
}

.sierra-select-dropdown-menu {
  position: absolute;
}

.sierra-select-dropdown-menu .ant-select-dropdown-menu-item {
  position: relative;
  padding-left: 30px;
}

.sierra-select-dropdown-menu .ant-select-dropdown-menu-item .ant-select-selected-icon {
  text-align: left !important;
  left: 10px !important;
  font-weight: bold;
}

.sierra-signal-nets-table .editable-cell-value-wrap,
.sierra-component-table .editable-cell-value-wrap,
/* .sierra-PG-table .editable-cell-value-wrap, */
.sierra-power-comps-table .editable-cell-value-wrap,
.sierra-pin-table .editable-cell-value-wrap,
.sierra-connector-table .editable-cell-value-wrap,
.sierra-sweeping-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.sierra-signal-nets-table .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.sierra-signal-nets-table tbody>tr>td>span {
  display: block;
  max-height: 80px;
  overflow: auto;
}

.sierra-signal-nets-table tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.sierra-signal-nets-table tbody>tr>td .ant-select-selection--multiple .ant-select-selector>ul>li {
  height: 24px;
  line-height: 24px;
}

.sierra-signal-nets-table tbody>tr>td #nets {
  position: relative;
}

.margin-top-20 {
  margin-top: 20px;
}

.sierra-pin-table .usage-repeater {
  padding: 0 5px;
}

.sierra-decap-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.sierra-decap-close {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.sierra-decap-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.sierra-model {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.sierra-model-last {
  padding-bottom: 5px;
}

.sierra-model-selection {
  float: left;
  width: 100%;
}

.sierra-model-input {
  margin-top: 5px;
}

.sierra-advance-panel {
  margin-top: 30px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-pcb-content {
  background: #f2f7fa;
  padding: 20px;
  padding-top: 0;
  position: relative;
  margin-top: 20px;
}

.sierra-pcb-menu {
  background: #f2f7fa;
  margin-left: 48px;
}

.sierra-component-table,
.sierra-pin-table,
.sierra-power-comps-table,
.sierra-content-setting .virtual-component-table {
  background: #ffffff;
}

.sierra-setup-pcb-title {
  position: absolute;
  left: 20px;
  top: 13px;
}

.padding-top-10 {
  padding-top: 10px;
}

.extraction-sub-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 8px;
}

.sierra-advance-panel .extraction-input .ant-input-group-addon {
  width: 68px;
}

.sierra-sub-extraction-box {
  margin-left: 26px;
  display: inline-block;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.sierra-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

.sierra-advance-content {
  /*  margin: 0px 10px 0 10px; */
  min-width: 810px;
}

.sierra-advance-title,
.sierra-optimization-title {
  font-weight: bold;
}

.sierra-advance-option {
  display: inline-block;
  padding: 0 20px;
  vertical-align: middle;
}

.sierra-advance-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 18px;
}

.sierra-extraction-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 18px;
}

.space-10 {
  margin-top: 10px;
}

/* extraction options */
.sierra-extraction-main {
  margin-left: 20px;
}

.sierra-extraction-content {
  line-height: 28px;
  margin-bottom: 5px;
}

.sierra-extraction-content .extraction-input,
.sierra-extraction-content .extraction-select {
  display: inline-block;
  width: 44%;
  vertical-align: middle;
  height: 28px;
}

.sierra-extraction-content .extraction-select {
  width: 53%;
}

.sierra-extraction-main .extraction-port-setup {
  margin-bottom: 14px;
}

.sierra-extraction-select-dropdown {
  z-index: 100000000;
}

.extraction-input .ant-input-group,
.extraction-input .ant-input-group .ant-input,
.extraction-input .ant-input-group .ant-input-group-addon,
.extraction-vrm-input .ant-input-group,
.extraction-vrm-input .ant-input-group .ant-input,
.extraction-vrm-input .ant-input-group .ant-input-group-addon {
  height: 28px;
}

.extraction-vrm-input {
  width: 40%;
}

.sierra-extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  padding-right: 10px;
}

.sierra-extraction-name {
  font-size: 16px;
  font-weight: 500;
}

.sub-sierra-extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  text-align: 20px;
  font-style: italic;
}

.sider-switch-icon {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  color: #737373;
}

/* sider button */
.sider-switch-vertical-icon {
  padding: 0 17px;
  padding-bottom: 4px;
}

.sider-switch-vertical-icon .pcb-icon {
  margin-left: -3px;
}

.sider-layout-div {
  display: inline-block;
}

.sider-layout-vertical-div {
  padding: 0 10px;
  padding-bottom: 4px;
}

.sider-layout-vertical-div .pdn-layout-button-1 {
  margin-bottom: 9px;
  margin-top: 3px;
}

.sider-layout-vertical-div .pdn-layout-button-2 {
  margin-bottom: 4px;
}

.icon-setup {
  font-size: 19px;
  cursor: pointer;
}

.pcb-icon {
  font-size: 25px;
  line-height: 32px;
  cursor: pointer;
}

.pdn-view-popover .ant-popover-inner-content {
  padding: 0;
}

.icon-Monitor {
  font-size: 20px;
  cursor: pointer;
}

.sierra-file-check-div {
  color: #f30518;
  position: relative;
}

.sierra-file-text {
  color: rgba(0, 0, 0, 0.65);
}

.sierra-file-check-span {
  position: absolute;
  right: 0px;
  top: 4px;
  width: 20px;
  height: 20px;
  background-color: #e0e0e0;
  color: #f30518;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
}

.sierra-file-check-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -9px;
  color: #f13140;
  font-size: 18px;
}

/* === extraction options === */

.sierra-extraction-panel-content {
  padding: 10px 16px;
  min-width: 800px;
  padding-bottom: 0px;
}

.sierra-extraction-panel-content .ant-divider-horizontal {
  margin: 10px 0px;
}

.sierra-extraction-panel-content .extraction-radio-group .ant-divider-horizontal {
  margin: 10px 0px;
}

.sierra-extraction-panel-content .extraction-radio-group {
  margin: 8px 0px;
  display: block;
}

.sierra-exc-options-crosstalk {
  display: inline-block;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.sierra-extraction-option-crosstalk-item {
  display: inline-block;
  margin: 8px 20px;
  position: relative;
  margin-bottom: 0px;
  margin-left: 33px;
}

.sierra-extraction-option-sub-item {
  margin: 0px 0px 0px 30px;
  position: relative;
  display: inline-block;
}

.sierra-extraction-option-sub-item .sierra-extraction-option-name {
  font-size: 13px;
}

.sierra-extraction-option-name {
  font-weight: 500;
  line-height: 28px;
  width: 80px;
  display: inline-block;
  font-size: 13px;
  vertical-align: middle;
}

.sierra-extraction-options {
  display: inline-block;
}

.sierra-extraction-options-main {
  margin-left: 14px;
  margin-bottom: 10px;
  font-size: 13px;
}

.sierra-extraction-options-main .sierra-extraction-content {
  margin: 4px 0px;
  margin-left: 10px;
  line-height: 28px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-content-body,
.sierra-extraction-options-main .sierra-extraction-content .comp-extraction-option-content-body,
.sierra-extraction-options-main .comp-extraction-option-content .comp-extraction-option-content-body {
  font-size: 13px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 150px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  height: 28px;
}

.sierra-extraction-options-main .extraction-input .ant-input-group .ant-input,
.sierra-extraction-options-main .sub-extraction-input .ant-input-group .ant-input {
  height: 28px;
}

.sierra-extraction-options-main .extraction-radio-clip-group {
  margin: 0px 10px;
  margin-left: 87px;
}

.sierra-extraction-options-main .extraction-radio-clip-group .extraction-clip-radio-item {
  vertical-align: middle;
  margin-left: 8px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input .ant-input-group-addon,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon {
  width: 56px;
  padding: 0px 6px;
  font-size: 13px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input .ant-input-group-addon .ant-select,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon .ant-select {
  font-size: 13px;
  height: 28px;
}

.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon .ant-select .ant-select-arrow {
  font-size: 10px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input .ant-input-group-addon .ant-select,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon .ant-select {
  height: 28px;
  line-height: 28px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input .ant-input-group-addon .ant-select .ant-select-selector,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon .ant-select .ant-select-selector {
  line-height: 26px;
  height: 26px;
}

.sierra-extraction-options-main .sub-extraction-input {
  margin-left: 10px;
}

.sierra-extraction-panel-content .sierra-extraction-options-main .sierra-extraction-gray {
  color: #9c9c9c;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-width {
  width: calc(100% - 378px);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-step-width {
  width: 112px;
}


.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-item {
  width: calc((100% - 300px) * 0.35);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-item-sub {
  width: calc((100% - 300px) * 0.3);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-exact-dc {
  margin-left: 87px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-body-width {
  width: 394px;
}

.sierra-extraction-options-main .sierra-extraction-content .comp-extraction-option-content-body {
  padding-left: 0px;
  width: 340px;
}

.sierra-extraction-options-main .sierra-extraction-content .comp-extraction-option-next-sub-span {
  padding-left: 20px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-maxF-input {
  width: calc(100% - 264px);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-width .ant-select-selection__clear {
  margin-top: -8px;
}

.sierra-pcbloading-icon {
  color: #1890ff;
  margin-left: 15px;
}

.sierra-signal-nets-table .select-width .label-input .ant-select-selection {
  border: none;
  background-color: #fafafa;
  padding: 0;
}

.select-width .label-input .ant-select-selection .ant-select-selector {
  margin-right: 15px;
}

.sierra-signal-nets-table .select-width .label-input .ant-select-selection .ant-select-arrow {
  top: 15px;
  right: 0px;
}

.sierra-pcb-replace-panel .sierra-pcb-replace-content {
  padding: 16px 16px 10px;
}

.sierra-pcb-replace-content .pcb-replace-box-title {
  font-weight: 500;
  margin-right: 10px;
  width: 96px;
  font-size: 13px;
  line-height: 28px;
}

.sierra-pcb-replace-content .pcb-replace-input-box {
  line-height: 32px;
  justify-content: center;
}

.sierra-pcb-replace-content .pcb-replace-select {
  width: calc(100% - 96px);
  height: 28px;
  line-height: 28px;
}

.sierra-pcb-replace-content .pcb-replace-button-box {
  display: flex;
  padding: 10px 16px 0;
  justify-content: space-around;
}

.sierra-pcb-replace-content .pcb-replace-button-box .ant-btn {
  height: 28px;
  width: 75px;
}

.sierra-pcb-replace-msg-box {
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px;
  max-height: 200px;
  overflow: auto;
}

.sierra-pcb-replace-msg-box .pcb-replace-msg {
  margin-bottom: 4px;
}

.sierra-pcb-replace-msg-box .pcb-replace-previewInfo {
  white-space: pre;
}

.sierra-select-label-content {
  position: relative;
}

.sierra-select-label-content .aurora-option-label-icon {
  position: absolute;
  right: 0px;
  top: 0px;
}


.sierra-content-setting .aurora-setup-border {
  background-color: #f2f7fa;
}

.advanced-select-pcb-item {
  padding: 0 32px;
}

.sierra-advance-content .ant-tabs-bar {
  margin: 0;
}

.sierra-advance-content .ant-tabs.ant-tabs-top .ant-tabs-nav {
  margin: 0px 16px;
}

.sierra-advance-content .extraction-ports-auto-setup .ant-tabs.ant-tabs-top .ant-tabs-nav {
  margin: 0px 0px;
}

.sierra-advance-content .sierra-extraction-content-item {
  padding: 0px 16px 10px;
}

.sierra-advance-content .sierra-extraction-content-item label.ant-checkbox-wrapper {
  width: 30px;
  text-align: left;
  line-height: 28px;
}

.sierra-extraction-content-item>span {
  line-height: 28px;
}

.sierra-advance-content .sierra-advance-apply-diver {
  margin: 10px 0px;
}

.sierra-extraction-options-main .extraction-content-hpc .sierra-extraction-content {
  padding-left: 10px;
}

.sierra-extraction-options-main .extraction-content-hpc .sierra-extraction-content .sierra-extraction-body-width {
  width: 330px;
}

.sierra-extraction-options-main .extraction-content-hpc .sierra-extraction-content .sierra-extraction-input-width {
  width: calc(100% - 358px);
}

.sierra-component-setting-update-content {
  text-align: start;
}

.sierra-component-setting-update-content .component-type-title {
  font-weight: 600;
}

.sierra-component-setting-update-content .sierra-component-setting-update-component {
  padding-left: 14px;
}

.component-type-pcb {
  padding-left: 6px;
}

.sierra-component-setting-update-component-content {
  max-height: 412px;
  overflow: auto;
  border: 1px dashed #bfbfbf;
  border-radius: 4px;
  padding: 6px;
  background: #f7f7f7;
}

.sierra-component-setting-update-panel .aurora-confirm-content {
  padding-bottom: 0px;
}

.sierra-component-connector-update-panel .aurora-confirm-content {
  padding-bottom: 0px;
  text-align: left;
}

.sierra-extraction-options-main .sierra-extraction-content.extraction-content-passive {
  width: 430px;
}

.sierra-extraction-options-main .extraction-content-passive .sierra-extraction-content-body {
  width: 394px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-title-width {
  width: 394px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-sub-span {
  padding-left: 20px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-width {
  width: calc(100% - 422px);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-step-width {
  width: 112px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-advanced {
  width: calc(100% - 446px);
  padding-left: 20px;
}

.sierra-extraction-options-main .comp-extraction-option-content .comp-extraction-option-sub-span {
  width: 384px;
  padding-left: 20px;
  padding-right: 0;
}

.sierra-extraction-options-main .comp-extraction-option-content .comp-extraction-option-content-input {
  width: calc(50% - 227px);
}

.sierra-extraction-option-default-content {
  margin-bottom: 14px;
}

.auto-assign-model-loading-icon {
  margin-left: 8px;
  color: #1890ff;
  cursor: pointer;
}


.sierra-comp-table-part-switch-icon {
  color: #3da4fa;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.sierra-comp-table-part-title {
  vertical-align: middle;
}

#sierra-pcb-connections-content {
  position: relative;
}

#sierra-pcb-connections-content .camera-icon:hover {
  color: #1890ff;
}

#sierra-pcb-connections-content .camera-icon {
  position: absolute;
  right: 12px;
  top: 8px;
  font-size: 18px;
}

.signal-interface-design-info-icon {
  color: #cb2431;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.signal-interface-design-loading-info-icon {
  color: #1890ff;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.sierra-replace-tooltip {
  max-width: 600px;
}

.pcb-replace-button-box {
  text-align: center;
  padding-top: 8px;
}

.pcb-replace-button-box .ant-btn {
  width: 75px;
  height: 28px;
  padding: 0px 10px;
}

.pcb-replace-button-box .ant-btn:nth-child(1) {
  margin-right: 10px;
}

.sierra-replace-tooltip .sierra-pcb-replace-msg-box .pcb-replace-previewInfo {
  padding-left: 6px;
}
/* === spice model panel === */
.spice-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.spice-model-panel .panel-body {
  position: relative;
}

.spice-model-panel .spice-model-panel-title {
  font-size: 14px;
  font-weight: 500;
}

.spice-model-panel .spice-model-content {
  font-size: 13px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  padding: 10px 14px;
  overflow: auto;
  /* overflow-x: hidden; */
}

.spice-model-panel .spice-model-content .spice-model-content-sub-box {
  min-width: 650px;
}

.spice-model-stimulus-error {
  display: block;
  padding: 6px 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 5px 10px 0px;
  line-height: 18px;
}

.title-remove-float {
  float: none !important;
}

.stimulus-setup-model-radio {
  height: 32px;
  line-height: 32px;
  font-size: 13px;
  padding-left: 100px;
  /* width: 100%; */
}

.stimulus-setup-model-radio-group {
  width: 100%;
}

.stimulus-setup-model-radio-content {
  display: flex;
  font-size: 13px;
}

.stimulus-setup-model-radio-title {
  flex: 1 1;
/*   margin-right: 10px; */
}

.stimulus-setup-model-radio-title-dutyCycle {
  flex: 2 1;
  /* margin-right: 10px; */
}

.stimulus-setup-model-radio-input {
  width: calc(100% - 210px);
  vertical-align: middle;
  margin-top: 6px;
  flex: 2 1;
}

.stimulus-setup-model-radio .stimulus-setup-model-radio-duty-cycle-input {
  width: calc(100% - 309px);
}

.stimulus-question-icon {
  color: #1890ff;
  margin-left: 8px;
}

.stimulus-setup-tooltop {
  z-index: 9999;
  max-width: 600px !important;
}
.stimulus-setup-model {
  width: 100%;
  padding: 5px 10px;
}

.stimulus-setup-model-title {
  float: left;
  line-height: 24px;
  width: 100px;
}

.stimulus-setup-model .stimulus-setup-model-select {
  width: calc(100% - 100px);
  font-size: 13px;
}

.stimulus-setup-model-select.ant-select-single.ant-select-sm .ant-select-selector {
  font-size: 13px;
}

.stimulus-setup-model-select-dropdown {
  z-index: 100000000;
}

.stimulus-apply-model-title {
  width: 330px;
  display: inline-block;
  line-height: 24px;
}

.stimulus-setup-model-driver .stimulus-apply-model-title {
  width: 210px;
}
.tag-box {
  width: 100%;
  border: 1px solid #63b4ff;
  height: auto;
  border-radius: 4px;
  line-height: 30px;
  background: #fff;
  box-shadow: rgba(9, 128, 247, 0.14) 0px 0px 0px 2px,
    rgba(13, 151, 249, 0.1) 0px 0px 7px 0px;
  padding: 0px 5px;
}

.tag-item {
  margin: 4px 4px;
  cursor: pointer;
}

.tag-box .tag-item:hover {
  border-color: #91d5ff;
}

.tag-box .ant-tag-checkable {
  color: rgba(0, 0, 0, 0.65);
  border-color: #ccc;
  background-color: #fff;
}

.tag-box .ant-tag-checkable-checked {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.check-tag-popover .ant-popover-inner-content {
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid#ffadd2;
  color: #eb2f96;
  background: #fff0f6;
}

.check-tag-popover .ant-popover-inner-content:hover {
  color: #eb58a9;
}

.check-tag-popover .ant-popover-content {
  border-radius: 4px;
}

.check-tag-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
}

.check-tag-popover .ant-popover-inner-content:hover .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
  bottom: 6.2px;
}

.check-tab-popover-content {
  cursor: pointer;
}

.un-select-node {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.select-tab-popover-content {
  display: inline-block;
  padding: 0px 4px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid#ffadd2;
  color: #eb2f96;
  background: #fff0f6;
  margin: 0px 4px;
}

#my-tag-box .selected-node {
  background: #1890ff;
  color: #e6f7ff;
  border-color: #1890ff;
}

.select-tag-popover {
  min-width: 251px;
}

.select-tag-popover .ant-popover-inner-content {
  padding: 6px;
}

.select-tag-popover .ant-popover-content {
  box-shadow: rgba(197, 197, 197, 0.78) 0px 0px 6px 2px,
    rgba(158, 157, 157, 0.1) 1px 0px 7px 0px;
}

.select-tab-popover-content:hover {
  color: #eb58a9;
}

.select-tag-popover .ant-popover-content .ant-popover-inner {
  padding: 0px;
}

.select-tag-popover .tag-disabled {
  border: 1px solid#c5c5c5;
  color: #696969;
  background: #f9f9f9;
  cursor: no-drop;
}

.tag-tooltip {
  max-width: 260px;
}

.select-tag-popover {
  z-index: 1000000;
}

#tab-help-main {
  width: 100%;
  overflow: auto;
  padding: 10px;
}

.tab-help-item {
  line-height: 22px;
  width: 100%;
  margin: 4px 0px;
}

.tab-help-item>span {
  color: #0b3694;
}

.tab-help-Popover {
  z-index: 1000000000;
}

.tab-help-Popover .ant-popover-inner-content {
  padding: 0px 0px;
}

.tab-help-Popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}
.automatic-match-ports-panel .automatic-model-file-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 8px 10px;
}

.automatic-match-ports-panel .automatic-model-file-add-box {
  width: 90px;
  line-height: 24px;
}

.automatic-match-ports-panel .automatic-model-file-select .automatic-model-file-type-box {
  line-height: 20px;
}

.automatic-match-ports-panel .automatic-model-selection-div {
  display: flex;
  width: calc(100% - 100px);
}

.automatic-match-ports-panel .automatic-model-selection-div .automatic-model-selection.ant-select {
  width: 100%;
  height: 24px;
  line-height: 22px;
}

.automatic-match-ports-panel .automatic-model-selection-div .automatic-model-selection.ant-select:last-child {
  margin-left: 10px;
}

.automatic-match-ports-panel .automatic-model-selection-div .automatic-model-selection.ant-select .ant-select-selection-item {
  height: 24px;
  line-height: 22px;
}

.automatic-model-selection-div .automatic-model-selection.ant-select .ant-select-selector {
  line-height: 22px;
}

.automatic-match-ports-radio label.ant-radio-wrapper,
.automatic-match-ports-radio-bottom label.ant-radio-wrapper,
.automatic-match-ports-radio-bottom>div {
  position: relative;
  width: 90px;
}

.automatic-match-ports-panel .radio-item-content {
  position: absolute;
  top: 0;
  left: 15px;
}

.automatic-match-ports-panel .radio-item-div {
  display: flex;
}

.automatic-match-ports-panel .radio-item-div div {
  padding: 0 15px;
  width: 38px;
}

.automatic-match-ports-panel .radio-item-div .radio-item-div-right {
  border-left: 1px solid;
}

.automatic-match-ports-content {
  padding: 7px 5px;
}

.ant-radio-group.ant-radio-group-outline .automatic-match-ports-radio,
.automatic-match-ports-radio-bottom {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.ant-radio-group.ant-radio-group-outline .automatic-match-ports-radio {
  height: 100px;
}

/* .automatic-match-ports-radio-group {
  width: 100%;
} */

.automatic-footer-content {
  display: flex;
  padding: 10px 16px 5px;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
  /*   margin-top: 80px; */
}

.automatic-footer-content .ant-btn {
  height: 28px;
  width: 75px;
}

.automatic-match-ports-radio-group {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 50px;
  column-gap: 50px;
  width: 100%;
}

.automatic-match-ports-radio-group>.ant-radio-wrapper {
  position: relative;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(33.33% - 50px);
  height: 90px;
}

.automatic-match-ports-radio-group>.ant-radio-wrapper.radio-text {
  height: auto;
}

.automatic-match-ports-radio-content {
  display: flex;
  position: absolute;
  top: 0px;
  left: 10px
}

.automatic-match-ports-vLine {
  width: 0px;
  height: 84px;
  border-left: 1px solid black;
}

.automatic-match-ports-vLine-left,
.automatic-match-ports-vLine-right {
  width: 10px;
  word-wrap: break-word;
  white-space: normal;
  margin: 0 15px;
}
/* === package model panel === */
.package-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 4px 10px;
}

.package-model-selection {
  width: calc(100% - 100px);
}

.package-model-selection .ant-select-arrow {
  right: 11px !important;
}

.package-model-select-dropdown {
  z-index: 10000000;
}

.package-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 22px;
}

.package-model-title {
  font-size: 14px;
  font-weight: 500;
}

.package-model-select .package-model-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 16px;
}

.spice-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}


.spice-model-panel .panel-body {
  position: relative;
}

.spice-model-panel .spice-model-panel-title {
  font-size: 14px;
  font-weight: 500;
}

.pkg-panel .spice-model-content>div {
  min-width: 650px;
}

.pkg-panel .spice-pin-list-ul {
  padding: 3px 10px;
}

.pkg-panel .find-package-layout-errorMsg-box {
  margin: 4px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 7px;
  max-height: 130px;
  overflow: auto;
  font-size: 14px;
}

.pkg-panel .find-package-layout-errorMsg-box .errorMsg-box-div {
  padding-left: 5px;
}

.pkg-panel .find-package-layout-errorMsg-box .errorMsg-box-span {
  padding-left: 15px;
}

.spice-pin-list-ul .spice-pin-connect-node-title {
  width: 100%;
  text-align: center;
  color: #005a9e;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  padding-left: 8px;
}

.spice-pin-list-ul .spice-pin-connect-node-title .anticon-delete {
  margin-left: 8px;
}
/* === Select Model panel === */
.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.IC-select-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.IC-select-model-title {
  font-size: 14px;
  font-weight: 500;
}

.IC-select-model-panel .IC-select-model-content {
  padding: 10px 14px;
  overflow: auto;
  font-size: 13px;
  height: 100%;
}

.IC-select-model-select-dropdown {
  z-index: 10000000;
}

.IC-select-model-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 13px;
  padding: 2px 12px;
}

.IC-model-type-select.ant-select {
  float: left;
  width: calc(100% - 100px);
  font-size: 13px;
}

.IC-select-model-input {
  float: left;
  width: 161px;
}

.IC-select-model-input-odt {
  width: 161px;
  margin-left: 10px;
}

.IC-select-model-input .ant-input {
  font-size: 13px;
}

.IC-select-dialog {
  padding: 5px 10px;
  width: 50%;
  float: left;
  line-height: 24px;
  position: relative;
  overflow: hidden;
}

.IC-select-dialog-ami-model {
  width: 100% !important;
}

.IC-select-dialog:after,
.IC-select-model-content-main:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.IC-multi-spice-select-dialog {
  width: 100%;
  float: none;
}

.IC-multi-spice-select-dialog>.IC-select-model-title-item {
  width: 100px;
}

/* .IC-multi-spice-select-dialog>.IC-model-type-select {
  width: calc(100% - 100px);
  height: 24px;
  line-height: 26px;
} */

/* .IC-multi-spice-select-dialog>.IC-model-type-select . {
  height: 28px;
}

.IC-multi-spice-select-dialog>.IC-model-type-select .ant-select-selector {
  line-height: 26px;
} */

.IC-select-model-title-item,
.IC-spice-model-title,
.IC-apply-model-title,
.IC-apply-memory-model-title {
  float: left;
  line-height: 24px;
  font-weight: 500;
  width: 100px;
}

.IC-buffer-template-info {
  font-weight: 400;
  cursor: pointer;
  margin-left: 36px;
  font-size: 16px;
  color: #1890ff;
}

.IC-apply-dialog {
  padding: 5px 10px;
  width: 100%;
  line-height: 24px;
  float: left;
}

.IC-apply-model-title {
  width: 220px;
}

.IC-apply-signal-title,
.IC-apply-model-component-title {
  width: 350px;
}

.IC-apply-model-types-title {
  width: 190px;
}

.IC-apply-memory-model-title {
  width: 382px;
  font-size: 13px;
}

.IC-apply-interface-title {
  display: inline-block;
}

.IC-apply-interface-title-radio {
  line-height: 22px;
  font-size: 13px;
  display: block;
}

.IC-apply-interface-title-radio>span {
  vertical-align: middle;
}

.IC-model-apply-checkbox {
  margin-left: 15px;
 /*  margin-right: 40px; */
}

.IC-select-dialog .IC-model-apply-checkbox-pre {
  margin-right: 8px;
  margin-left: 0px;
}

.IC-select-model-name-box {
  padding: 6px;
  overflow: auto;
  background-color: #eee;
  border-radius: 6px;
  margin: 10px 10px 8px 8px;
}

.IC-select-model-input-after .ant-select-selection .ant-select-selector {
  margin: 0px 6px;
}

.IC-select-model-input-after .ant-select-selection .ant-select-arrow {
  right: 5px;
}

.IC-select-model-input-after .ant-select-selection .ant-select-selection-selected-value {
  padding-right: 15px;
}

.IC-select-model-input-after {
  font-size: 13px;
}

.IC-select-model-dropdown {
  z-index: 10000000;
}

.IC-select-model-dropdown .ant-select-dropdown-menu-item {
  font-size: 13px;
  padding: 0px 12px;
}

.IC-model-select-ul {
  padding: 0px;
  margin: 0px;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  font-weight: 500;
}

.IC-model-select-li {
  white-space: nowrap;
  display: block;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  padding: 0px 6px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
}


.IC-model-select-li:hover {
  background-color: #d5ecff;
}

.IC-model-select-li-selected {
  /* background-color: #abd7ff; */
  background-color: #b9ecff;
}

.IC-model-title-item {
  float: left;
  line-height: 24px;
  font-weight: 500;
  width: 100px;
  font-size: 13px;
}

.IC-apply-dialog .IC-select-model-name-input {
  float: left;
  width: calc(100% - 100px);
  height: 24px;
  font-size: 13px;
  line-height: 24px;
}

.IC-select-power-dialog {
  padding: 5px 9px;
  line-height: 24px;
  float: left;
}

.IC-select-power-dialog .IC-model-title-item {
  width: 80px;
  text-align: center;
}

.IC-power-off-switch::after {
  /* width: 9px;
  height: 9px; */
}

.IC-power-off-switch .IC-advanced-off-switch {
  min-width: 22px;
  height: 13px;
  line-height: 10px;
  margin-bottom: 3px;
  margin-left: 5px;
}

.IC-file-panel .panel-body {
  background: #f1f1f1;
}

.IC-file-panel .panel-body {
  position: relative;
}

.IC-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  height: 400px;
}

.IC-file-download {
  margin-left: 20px;
  font-size: 16px;
  color: #1890ff;
}

.IC-icon-tooltip {
  z-index: 2010;
}

.IC-icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.IC-icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

#template-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.IC-model-type-select .ant-select-arrow:hover {
  color: #1890ff;
  text-transform: none;
}

.IC-model-type-select .ant-select-arrow {
  text-transform: none !important;
  transform: none !important;
  transition: none !important;
}

.IC-apply-sub-dialog {
  padding: 5px 10px;
  padding-left: 24px;
  width: 100%;
  line-height: 24px;
  float: left;
}

.IC-sub-apply-box {
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
  margin-top: 8px;
  float: left;
}

.IC-select-model-panel .IC-select-model-content .ant-spin-nested-loading {
  float: left;
  width: 100%;
}

.IC-model-label {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.IC-model-checkbox {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  cursor: pointer;
}

.IC-model-checkbox-selected {
  background-color: #b9ecff;
}

.IC-model-label .IC-model-checkbox-group>.ant-checkbox+span {
  padding-inline-start: 5px;
  font-size: 12px;
}

.IC-model-search-input {
  display: block;
  border: none;
  outline: none;
  min-width: 500px;
}

.IC-model-select-li.IC-model-select-li-selected-disable {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

.IC-model-select-li.IC-model-select-li-selected-disable .IC-model-label {
  cursor: not-allowed;
}

.ic-model-divider {
  margin: 2px 0px;
}

.IC-select-model-content .IC-model-type-tree-select .aurora-select {
  height: 24px;
  line-height: 24px;
}

.IC-select-model-content .IC-model-type-tree-select .ant-select-selector {
  height: 22px;
  line-height: 22px;
}

.IC-select-model-content .IC-model-type-tree-select .ant-select-selector .ant-select-selection-selected-value {
  font-size: 13px;
  height: 22px;
  line-height: 22px;
}

.odt-value-validate {
  display: flex;
  padding: 6px 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 8px;
}

.IC-ami-model-select-tooltip {
  z-index: 9999;
}

.IC-select-dialog-ami-ignore-bits {
  display: inline-flex;
  align-items: center;
}

.IC-select-dialog-ami-ignore-bits-switch {
  margin-left: 10px;
}

.IC-select-ami-model-instantiation {
  width: 160px;
}

.IC-ami-model-instantiation-select.ant-select {
  width: calc(100% - 160px);
}

.IC-model-select-advanced {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 0;
}

.IC-model-select-advanced-title {
  font-weight: 500;
  width: 120px;
}

.IC-power-off-switch .IC-advanced-off-switch {
  margin-left: 5px;
}

.IC-model-select-advanced-item-title {
  width: calc(100% - 340px);
  font-weight: 500;
  padding-left: 15px;
}

.IC-model-select-advanced-item-input {
  width: 210px;
  padding-right: 10px;
  margin-left: 20px;
}

.IC-model-select-advanced-item-title i {
  font-size: 10px;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -4px;
}

.IC-model-select-name {
  font-weight: 500;
  margin-right: 25px;
  width: 240px;
  font-size: 13px;
  line-height: 28px;
  margin-left: 13px;
}

.IC-model-select-advanced-options {
  padding-left: 10px;
}

.IC-model-select-advanced-item {
  display: inline-flex;
  width: 100%;
  line-height: 28px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 2px;
}

.IC-adv-option-input-after {
  font-size: 13px;
  width: 56px;
}

.IC-adv-option-input-after-dropdown {
  z-index: 10000000;
}

.IC-advanced-wrap {
  margin-bottom: 15px;
  position: relative;
  float: left;
  width: 100%;
}

.IC-ami-model-collapse {
  border: none;
  background-color: #ffffff;
}

.IC-ami-model-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px 6px 20px;
  width: -moz-fit-content;
  width: fit-content;
}

.IC-ami-model-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 0;
}

.IC-ami-model-collapse-title {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.IC-ami-parameters-table .ant-table-body table {
  table-layout: fixed;
}

.ami-parameters-title {
  cursor: pointer;
}

.ami-parameters-title:hover {
  color: #696969;
}

.IC-ami-parameters-value {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.IC-model-type-select.ant-select-single.ant-select-sm .ant-select-selector {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
}
.spice-pin-model-subckt-select {
  width: calc(50% - 100px);
  font-size: 13px;
  height: 24px;
  line-height: 24px;
}

.pin-spice-model-select-dropdown {
  z-index: 10000000;
}

.pin-spice-model-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 13px;
  padding: 2px 12px;
}

.spice-pin-model-file-selection-content>.pin-spice-model-file-selection:first-child {
  width: calc(50% + 100px);
}

.pin-spice-model-file-selection {
  display: flex;
  width: calc(50% + 100px);
  justify-content: space-between;
  padding: 5px 10px;
}

.pin-spice-model-file-selection>span {
  display: block;
  line-height: 24px;
  font-weight: 500;
  width: 100px;
  height: 24px;
}

.pin-spice-model-file-selection>.aurora-select {
  width: calc(100% - 100px);
  height: 24px;
  line-height: 24px;
}

.pin-spice-model-file-selection>.aurora-select .ant-select-selection {
  height: 24px;
  line-height: 24px;
}

.pin-spice-model-file-selection>.aurora-select .ant-select-selection .ant-select-selector .ant-select-selection-selected-value {
  font-size: 13px;
}

.pin-spice-model-file-selection>.aurora-select .ant-select-selection .ant-select-selector {
  height: 22px;
  line-height: 22px;
}

.pin-spice-model-file-selection>.spice-pin-model-subckt-select {
  width: 100%;
}

.spice-pin-model-file-selection-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.spice-pin-model-assign-content {
  width: 100%;
}

/* .spice-pin-model-assign-content .spice-pin-die-box{
width: fit;
} */

.spice-pin-model-assign-content .spice-pin-list-ul {
  width: 100%;
}

.spice-pin-model-assign-content .spice-pin-connect-node-item {
  width: 100%;
}

.spice-pin-model-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
  min-width: 200px;
}

.spice-pin-model-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.spice-pin-model-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.spice-pin-model-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.spice-pin-model-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.spice-pin-model-port-list-body-with-search {
  height: 100%;
}

.spice-pin-model-port-list-body-search-wrapper {
  width: 100%;
}

.spice-pin-model-port-list-body-search-wrapper .ant-input-affix-wrapper,
.spice-pin-model-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.spice-pin-model-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.spice-pin-model-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.spice-pin-model-port-list-ul>li:hover {
  background-color: #cfedff;
}


.spice-pin-model-port-list-ul>.spice-pin-model-selected-port-li:hover {
  background-color: #93e6ff;
}

.spice-pin-model-port-list-ul>.spice-pin-model-port-li-selected,
.spice-pin-model-port-list-ul>.spice-pin-model-port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.spice-pin-model-port-list-ul>.spice-pin-model-selected-port-li {
  background-color: #93e6ff;
}

.spice-pin-model-assign-content .spice-pin-input,
.spice-pin-model-assign-content .spice-pin-input>span:not(.anticon) {
  max-width: 110px;
}

.spice-pin-model-assign-content .spice-pin-input {
  min-width: 42px;
}

.spice-pin-model-assign-content .spice-pin-input>span:not(.anticon) {
  margin-right: 12px;
  min-width: 30px;
}

.spice-pin-model-assign-content .spice-pin-node-delete-icon {
  right: 0px;
}

.spice-pin-model-assign-content .spice-pin-die-box {
  min-width: 20px;
}

.spice-pin-model-file-selection-content>.pin-spice-model-file-selection {
  width: calc(50% - 50px);
}

.spice-pin-model-file-selection-content>.pin-spice-model-file-selection:first-child {
  width: calc(50% + 50px);
}

.spice-top-box {
  position: relative;
  height: 100%;
}

.spice-top-box .spice-pin-circle {
  position: absolute;
  bottom: 0;
}

.spice-top-box .spice-pin-line {
  transform: rotate(90deg);
  position: absolute;
  bottom: 19px;
  left: -5px;
}

.spice-pin-vertical-line {
  width: 1px;
  flex: 0 0 auto;
  height: 20px;
  background-color: #a6a6a6;
}

.spice-pin-node-item-center.spice-pin-node-item-vertical-center {
  height: 74px;
}

.spice-vertical-pin-die {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.spice-pin-model-assign-content .spice-vertical-pin-die .spice-pin-input>span:not(.anticon) {
  margin-right: 0;
}

.spice-pin-model-assign-content .spice-vertical-pin-die .spice-pin-node-delete-icon {
  right: -12px;
}

.spice-pin-model-assign-content .spice-pin-input.ant-popover-open {
  padding: 4px 5px;
}
.probe-pins-panel {
  box-shadow: 0px 0px 8px 2px #969696;
}

.probe-pins-main {
  margin: 10px;
  min-height: 80px;
  border: 1px solid #eee;
  border-radius: 4px;
  height: calc(100% - 20px);
  overflow: auto;
}

.probe-pins-main>.probe-pins-main-div {
  min-width: 700px;
}

.probe-pins-component-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.probe-pins-component-box .probe-pins-close-icon {
  position: absolute;
  right: -24px;
  top: 50%;
  margin-top: -7px;
  font-size: 14px;
  color: #ccc;
  cursor: pointer;
}

.probe-pins-main-no-data {
  text-align: center;
  height: 80px;
  line-height: 80px;
  color: rgb(0, 0, 0, 0.45);
}

.probe-pins-select-components-box {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 16px;
  overflow: auto;
  max-height: 800px;
}

.probe-pins-select-components-close-icon {
  position: absolute;
  right: 8px;
  top: 6px;
  font-size: 14px;
  color: #939393;
  cursor: pointer;
}

/* tree */
.checkable-tree .tree-node .ant-tree-title>span:not(.anticon) {
  display: inline-block;
  width: 100%;
  line-height: 24px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* component box */
.probe-pins-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px 0px;
}

.probe-pins-content .probe-pins-component {
  background-color: #fcfbfb;
  border-radius: 4px;
  min-height: 32px;
  width: 220px;
  text-align: center;
  line-height: 28px;
  height: 100%;
  box-sizing: border-box;
  font-size: 14px;
  border: 2px solid #bdbdbd;
}

.probe-pins-content .probe-pins-component-pin {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  position: relative;
  font-size: 13px;
}

.probe-pins-content .probe-pins-component-pin-left .probe-pins-component-pin {
  margin-right: -90px;
  text-align: left;
}

.probe-pins-content .probe-pins-component-pin-right .probe-pins-component-pin {
  margin-left: -90px;
  text-align: right;
}

.probe-pins-content .probe-pins-component-pin .probe-pins-pin {
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.probe-pins-content .probe-pins-component-pin .probe-pins-pin>span {
  cursor: pointer;
  min-width: 26px;
  display: inline-block;
}

.probe-pins-content .probe-pins-component-pin-left .probe-pins-component-pin .probe-pins-pin>span {
  padding-left: 6px;
}

.probe-pins-content .probe-pins-component-pin-right .probe-pins-component-pin .probe-pins-pin>span {
  padding-right: 6px;
}

.probe-pins-content .probe-pins-component-pin .probe-pins-net {
  min-width: 130px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #005a9e;
}

.probe-pins-content .probe-pins-component-pin-left .probe-pins-component-pin .probe-pins-net {
  padding-right: 6px;
  text-align: left;
}

.probe-pins-content .probe-pins-component-pin-right .probe-pins-component-pin .probe-pins-net {
  text-align: right;
  padding-left: 6px;
}

.probe-pins-component-pin-left,
.probe-pins-component-pin-right {
  display: block;
  height: 100%;
  min-width: 150px;
}

.probe-pins-content .probe-pins-line {
  width: 22px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  color: rgb(0, 0, 0, 0.65);
  transform: scaleY(0.2);
}

.probe-pins-content .probe-pins-circle {
  width: 28px;
  height: 15px;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
  cursor: pointer;
}

.probe-pins-content .probe-pins-component-pin-selected .probe-pins-circle {
  background-color: rgb(0, 0, 0, 0.45);
  border: 1px solid rgb(0, 0, 0, 0);
}

.probe-pins-content .probe-pins-component-pin .probe-pins-pin-not-allowed>span,
.probe-pins-content .probe-pins-circle-not-allowed {
  cursor: not-allowed;
}
.connection-ports-list-file-dropdown {
  z-index: 1000000000;
}

.connection-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.connection-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.connection-port-list-body-with-search {
  height: 100%;
}

.connection-port-list-body-search-wrapper {
  width: 100%;
}

.connection-port-list-body-search-wrapper .ant-input-affix-wrapper,
.connection-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.connection-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.connection-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.connection-port-list-ul>li:hover {
  background-color: #cfedff;
}


.connection-port-list-ul>.current-pin-selected-port-li:hover {
  background-color: #93e6ff;
}

.connection-pin-port-content {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 64px;
}

.connection-pin-port-content .connection-pin-list-ul {
  height: 100%;
  margin: 0;
  /* padding: 8px 10px; */
  padding: 8px 0px;
  list-style: none;
}

.connection-pin-connect-port-item {
  width: 100%;
  min-width: 370px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.spice-pin-list-ul .connection-pin-connect-port-item-center {
  justify-content: center;
}

.connection-box {
  flex: 0 0 auto;
  width: 110px;
  background-color: #eeeeee;
  height: 100%;
  /* border-bottom: 1px solid #d9d9d9; */
  text-align: center;
  font-size: 12px;
  padding: 8px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.connection-box>.connection-box-net-text {
  display: block;
  line-height: 26px;
  color: #005a9e;
}

.connection-box-top {
  border-radius: 4px 4px 0px 0px;
}

.connection-box-bottom {
  border-radius: 0px 0px 4px 4px;
  border-bottom: none;
}

.connection-pin-l-box,
.connection-pin-net-box {
  padding: 0px 8px;
  flex: 0 0 auto;
  max-width: 180px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  z-index: 10;
}

.connection-pin-net-box {
  max-width: 150px;
}

.connection-pin-r-box {
  padding: 0px 8px;
  flex: 0 0 auto;
  max-width: 180px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  z-index: 10;
}

.connection-pin-line,
.connection-pin-long-line {
  width: 20px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  transform: scaleY(0.2);
}

.connection-pin-long-line {
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 0;
  top: 50%;
}

.connection-pin-circle {
  width: 8px;
  height: 8px;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
}

.connection-pin-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 80px;
  width: 50px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
  background: #ffffff;
}

.connection-pin-cable-value-box .connection-pin-input-value {
  background: #eee;
}

.spice-pin-input .ant-input:not(:last-child) {
  padding-right: 11px;
}

.spice-pin-input:hover>span:not(.anticon) {
  max-width: 65px;
}

.spice-pin-input>span:not(.anticon) {
  display: inline-block;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* node select model */
.connection-port-select-Popover {
  z-index: 100000000;
  display: flex;
  padding: 0px;
}

.connection-port-select-Popover .ant-popover-inner-content {
  padding: 0px 0px;
}

.connection-port-select-Popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}

.node-list-body-search-wrapper .ant-input {
  border-radius: 4px 4px 0px 0px;
}

.connection-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.connection-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.connection-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.connection-port-list-ul>.port-li-selected,
.connection-port-list-ul>.port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.connection-port-list-ul>.current-pin-selected-port-li {
  background-color: #93e6ff;
}

.connection-pin-l-value-box,
.connection-pin-r-value-box,
.connection-pin-cable-value-box {
  height: 50px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  width: 34%;
  position: relative;
  z-index: 20;
}

.connection-pin-cable-value-box {
  width: 32%;
  justify-content: space-around;
}

.connection-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
}

.connection-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.connection-pin-input:hover .connection-pin-node-delete-icon {
  display: inline-block;
}

.connection-ports-list-file-select {
  width: 100%;
  font-size: 12px;
  margin-bottom: 6px;
  height: 28px;
}

.connection-ports-list-file-select {
  height: 28px;
}

.connection-ports-list-file-select .ant-select-selector {
  line-height: 26px;
}

.spice-model-add-file-icon {
  margin-left: 10px;
  color: #3da4fa;
  font-size: 14px;
  vertical-align: text-bottom;
}

.spice-model-multi-select {
  display: flex;
  position: relative;
  width: 100%;
}

.spice-netlist-model-select-content {
  width: calc(100% + 10px);
}

.pkg-new-panel .spice-netlist-model-select-content {
  width: 100%;
}

.spice-model-single-select {
  position: relative;
  width: 100%;
}

.spice-model-file-error-msg {
  display: block;
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.spice-model-single-select:hover .spice-model-delete-file-icon,
.spice-model-multi-select:hover .spice-model-delete-file-icon {
  visibility: visible;
}

.spice-model-delete-file-icon {
  color: #3da4fa;
  font-size: 13px;
  visibility: hidden;
  position: absolute;
  right: 2px;
  top: 10px;
}

.spice-netlist-model-select .Subckt {
  width: 0px;
}

.spice-netlist-model-select .Subckt_select {
  width: 100%;
}

.spice-model-multi-select .spice-netlist-model-select {
  padding-left: 5px;
}

.spice-model-multi-select>*:first-child {
  padding-right: 5px;
  padding-left: 10px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-title {
  font-size: 14px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-list {
  background: #f2f2f2;
}

.connection-pin-list-signal {
  padding: 6px 0px;
  position: relative;
}

.connection-pin-list-channel1-signal-title,
.connection-pin-list-channel2-signal-title,
.connection-pin-list-connection-signal-title {
  height: 100px;
  line-height: 100px;
  color: #2d82d7;
  margin: auto;
  position: absolute;
  top: 50px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  text-align: center;
  z-index: -1;
}

.connection-pin-input-pinport {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}

.connection-pin-input {
  height: 26px;
  line-height: 26px;
}

.connection-net-name-box {
  position: relative;
}

.connection-pin-net-l-name {
  color: #2d82d7;
  position: absolute;
  top: -8px;
  z-index: 100;
  left: 0;
}

.connection-pin-net-r-name {
  color: #2d82d7;
  position: absolute;
  top: -8px;
  z-index: 100;
  right: 0px;
}

.connection-pin-connect-port-item .connection-pin-net-box {
  max-width: 31%;
}

.connection-pin-connect-port-item .connection-pin-net-l-name {
  max-width: 40%;
}

.connection-pin-map-edit-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #056fdb;
  vertical-align: middle;
}

.connection-pin-map-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.connection-pin-map-panel .connection-pin-map-content {
  padding: 8px 14px;
  min-width: 450px;
}

.connection-pin-map-panel .connection-pin-map-columns {
  width: 100%;
  height: 100%;
}

.connection-pin-map-columns-select-dropdown {
  z-index: 30000;
}
.connection-model-assign-content {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  position: relative;
}

.connection-left-channel {
  justify-items: flex-start;
  width: 36%;
  min-width: 300px;
  min-height: 100px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: relative;
}

.connection-right-channel {
  justify-items: flex-end;
  width: 36%;
  min-width: 300px;
  min-height: 100px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: relative;
}

.connection-model-assign-content .connection-component-name {
  font-size: 14px;
  font-weight: 600;
  max-width: 100%;
  padding: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.connection-model-assign-content .connection-pin-left-ul {
  position: absolute;
  /* right: -106px; */
  right: -75px;
  top: 100px;
}

.connection-model-assign-content .connection-pin-right-ul {
  position: absolute;
  /* left: -86px; */
  left: 0px;
  top: 100px;
}

.connection-component-select-content {
  width: calc(100% - 14px);
  height: 28px;
  margin: 6px 14px;
  position: relative;
  line-height: 28px;
  margin-left: 18px;
}

.connection-component-select-content>.connection-component-select-title {
  width: 140px;
  display: inline-block;
  color: #005a9e;
}

.connection-component-select-content .connection-single-select {
  width: calc(100% - 146px);
}

.connection-cable-model-content .connection-component-select-content>.connection-component-select-title {
  width: 120px;
}

.connection-cable-model-content .connection-component-select-content .connection-single-select {
  width: calc(100% - 95px);
}

.connection-cable-model-content {
  background: #eee;
  border: 1px solid #ccc;
  width: calc(28% - 20px);
  margin: 0px 10px;
  border-radius: 4px;
}

.connection-cable-model-none-content {
  background: #ffffff !important;
  border: none !important;
}

.connection-file-error .connection-single-select {
  color: #f30518 !important;
}

.connection-file-error {
  position: relative;
}

.connection-file-error .aurora-file-check-icon {
  right: 25px;
  font-size: 16px;
}

.connector-select-dropdown-menu {
  z-index: 10000000000;
}

.connector-model-add-file-icon {
  margin-left: 10px;
  color: #3da4fa;
  font-size: 14px;
}

.connection-component-select-main {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 38px;
}

.connector-model-close-file-icon {
  color: #3da4fa;
  font-size: 14px;
  position: absolute;
  right: -12px;
  top: 7px;
  display: none;
}

.connection-component-select-content:hover .connector-model-close-file-icon {
  display: inline-block;
}

.connection-model-assign-content .aurora-model-name-error-msg {
  margin: 0px 14px;
  margin-right: 24px;
  padding: 4px 10px;
}

.connection-model-assign-content .connector-model-box-left,
.connection-model-assign-content .connector-model-box-right {
  background: #ffffff;
  z-index: 10;
  width: 160px;
}

.connection-model-assign-content .connector-model-first-box {
  background: #ffffff;
  z-index: 40;
  width: 160px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  min-height: 135px;
  padding: 0px 5px;
}

.connector-model-first-box .connection-component-select-content {
  margin: 6px 4px;
  height: auto;
  font-size: 12px;
}

.connector-model-first-box .ant-select-sm.aurora-select.connection-single-select.connection-component-left-select.ant-select.ant-select-enabled.ant-select-allow-clear {
  width: 100%;
}

.connector-model-first-box .connection-component-select-content .connection-single-select {
  width: 100%;
}

.connector-model-first-box .connection-component-select-main {
  position: unset;
}

.connector-model-first-box .aurora-select .ant-select-selector {
  font-size: 12px;
}

.connection-model-assign-content .connection-cable-model-content .connection-component-select-content>.connection-component-select-title {
  font-size: 12px;
  width: 90px;
}

.connection-model-assign-content .connection-cable-model-content .connection-component-select-content .connection-single-select {
  font-size: 12px;
}

.connector-model-first-box>div {
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.connector-model-pinName-box {
  height: 26px;
  line-height: 26px;
  position: relative;
}

.connector-model-pinName-box .connection-pin-l-box {
  display: inline-block;
  margin-right: 10px;
}

.connector-model-pinName-box .connection-pin-r-box {
  display: inline-block;
  margin-left: 10px;
}

.connection-model-assign-content .connector-model-box-left {
  display: inline-block;
  margin: 0 10px;
}

.connection-model-assign-content .connector-model-box-right {
  display: inline-block;
  margin: 0 10px;
}

.connection-pin-l-value-box .connection-pin-net-box {
  text-align: left;
}
.end-to-end-channel-connection-content {
  padding: 8px 14px;
}

.end-to-end-channel-connection {
  min-width: 970px;
  padding: 10px;
  max-height: 100%;
  background: #ffffff;
}

.andes-connection-signal-map-content {
  padding: 10px;
}

.andes-connection-table .ant-table-small>.ant-table-content .ant-table-header {
  padding-right: 22px;
}

.andes-connection-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-connection-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 14px;
}

.andes-connection-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 22px;
}

.andes-connection-table tbody>tr>td:last-child {
  position: relative;
}

.andes-connection-table .ant-table-small>.ant-table-content .connection-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.andes-connection-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .connection-delete{
  color: #1890ff;
}

.andes-connection-signal-map-content .signal-add-icon {
  vertical-align: middle;
}
.connection-channel-title>div {
  font-size: 14px;
  margin: 0px 16px;
  min-width: 100px;
}

.connection-channel-title>div .connection-pcb-name {
  color: #0d82d7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding-right: 10px;
  line-height: 32px;
}

.connection-channel-title .connection-border {
  height: 20px;
  width: 1px;
  min-width: 1px;
  background: #ccc;
  margin-top: 26px;
}

.connection-channel-title>div .connection-channel-name {
  color: #67676f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  line-height: 32px;
}

.connection-channel-title {
  display: flex;
  justify-content: center;
  transition: 0.3s;
}

.connection-model-content-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  margin: 15px 0;
  padding: 10px 0;
}

.aurora-connector-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.connector-add-button {
  margin-left: 10px;
  cursor: pointer;
}

.connector-delete-button {
  font-size: 16px;
  /* color: #0d82d7; */
  color: #1890ff;
  position: absolute;
  cursor: pointer;
  top: 11px;
  right: 11px;
  transform: translate(50%, -50%);
}

.connection-model-content-wrapper .connector-delete-button.connector-delete-button-disable {
  color: #ccc;
  cursor: not-allowed;
}

.connection-model-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.connection-model-content .change-connection-icon {
  font-size: 16px;
  width: 26px;
  display: inline-block;
  cursor: not-allowed;
  padding-left: 7px !important;
  padding-right: 8px !important;
  line-height: 32px;
  height: 30px;
  margin-bottom: 2px;
}

.connection-model-content .change-connection-icon i {
  margin-left: -1.5px;
}

.connection-model-content .change-connection-icon:hover {
  background-color: #bbd6f0;
}

.connection-model-list {
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.connection-title-pcb {
  text-align: center;
  font-weight: 600;
}

.connection-model-main {
  transition: 0.3s;
  width: 100%;
}

.connection-name-content {
  text-align: center;
}

.connection-model-box {
  position: relative;
}

.connection-channel-tile-title {
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 20px;
  color: #005a9e;
}

.connection-channel-tile-title>div {
  margin: 0px 14px;
  width: 36%;
  text-align: center;
}

.connection-title-tile-pcb {
  /* width: 134px; */
  /* display: inline-block; */
  margin-right: 30px;
  color: #005a9e;
}

.connection-model-tile-list {
  width: 100%;
  display: inline-block;
}

.anticon.anticon-plus-circle.signal-add--connection-icon {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
}
.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.comp-model-dialog {
  bottom: 100%;
  width: 100%;
  overflow: auto;
  background-color: #fdfdfd;
  /* box-shadow: rgba(76, 77, 78, 0.14) 0px 0px 5px 8px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px; */
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px,
    rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin-bottom: 3px;
  margin-top: 3px;
  position: absolute;
  z-index: 1000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px;
  /* border: 1px solid #2692f6; */
  border: 1px solid #cdcfd2;
  /* min-width: 290px; */
}

.pintopin-model-title,
.spice-model-title {
  line-height: 24px;
  font-weight: bold;
}

.pintopin-model-select {
  margin-bottom: 0px;
  max-height: 150px;
}

.model-pin-tabs,
.model-pin-tabs .ant-tabs-nav {
  width: 100%;
  padding-bottom: 0px;
  padding-top: 10px;
}

.model-pin-tabs .ant-tabs-tab {
  margin: 0px;
  padding: 10px 0px;
  width: 50%;
  text-align: center;
}

.model-pin-tabs .ant-tabs-bar {
  margin: 0px;
}

.pintopin-model-select {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100px;
}

.pintopin-model-select > ul {
  margin: 0px;
  padding: 0 0 10px;
}

.pintopin-model-select > ul > li {
  line-height: 24px;
  list-style: none;
  cursor: pointer;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding-left: 5px;
}

.pintopin-model-select li:hover {
  background: #e9e9e9;
}

.pintopin-ibis-dialog {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.pintopin-model-list-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
  text-align: center;
  background: #c1c1c18f;
  font-weight: bold;
  border-bottom: 1px solid #cfcfcf;
}

.pintopin-model-select-box {
  margin-top: 10px;
  border-top: 1px solid #dfdfdf;
  background-color: #fff;
}

.border-right {
  border-right: 1px solid #c5c5c5;
}

.border-left {
  border-left: 1px solid #c5c5c5;
}

.pintopin-comment {
  float: left;
  width: 84%;
  padding-right: 10px;
  overflow: hidden;
}

.pintopin-comment > div,
.stimulus-comment > div {
  float: left;
  line-height: 20px;
  padding-left: 10px;
  font-size: 12px;
}

.pintopin-comment span,
.stimulus-comment span {
  font-weight: bold;
  padding-left: 5px;
}

.pintopin-model-ok {
  float: right;
  width: 40px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 0px;
}

.editable-cell-value-wrap {
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  line-height: 28px;
  margin: -4px;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  width: 100%;
}

.pintopin-model-footer {
  width: 100%;
  float: left;
  border-top: 1px solid #cfcfcf;
}

/* .stimulus-unit-change {
  margin-left: 10px;
}

.stimulus-unit-change .ant-select-selector {
  margin-right: 5px;
} */

.pintopint-model-upload {
  float: left;
  width: 150px;
}

.pintopin-modeltype-select {
  float: left;
  width: 150px;
}

.pintopint-model-upload-disable {
  color: rgba(0, 0, 0, 0.65);
  background: #ffffff;
}

.pintopint-model-upload-button {
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  color: #1890ff;
}

.pintopin-model-title,
.spice-model-title {
  float: left;
}

.pintopin-model-title {
  width: 50px;
}

.spice-model-title {
  width: 110px;
}

.spice_dialog {
  margin-bottom: 5px;
}

#library-file-view,
#template-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px,
    rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.file-display {
  height: 400px;
}

.file-panel .panel-body {
  background: #f1f1f1;
}

.aurora-model-dialog{
  z-index: 1000000;
}

.pkg-model-select-panel {
  top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.pkg-model-select-panel .panel-body {
  overflow: auto;
}

.pkg-model-select-main {
  padding: 8px 14px;
  min-height: 200px;
  min-width: 720px;
  overflow: auto;
  font-size: 13px;
}

.pkg-model-select-main .pkg-model-select-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.pkg-model-select-main .pkg-model-select-content .pkg-model-select-item {
  min-height: 28px;
  line-height: 28px;
  height: -moz-fit-content;
  height: fit-content;
}

.pkg-model-select-main .pkg-model-select-item>span {
  width: 134px;
}

.pkg-model-select-main .pkg-model-select-item>.pkg-model-select {
  width: calc(100% - 134px);
  height: -moz-fit-content;
  height: fit-content;
  min-height: 28px;
}

.pkg-model-select-main .pkg-model-select-item>.pkg-model-select.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 20px;
  line-height: 18px;
}

.pkg-model-select-main .pkg-model-select-item>.pkg-model-select>.ant-select-selector {
  height: auto;
}

.pkg-model-select-main .pkg-model-select-ports-main {
  display: block;
  min-height: 100px;
  border-bottom: 1px solid #eee;
  background-color: #eee;
  padding: 5px 10px;
  position: relative;
  font-size: 12px;
  margin: auto;
  width: 100%;
}

.pkg-model-select-main .pkg-model-select-ports-main-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.pkg-model-select-main .pkg-model-port-signal-main,
.pkg-model-select-main .pkg-model-port-copy-signal-main {
  width: 160px;
  min-height: 90px;
  text-align: center;
  color: #005a9e;
  background: #d5d5d5;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pkg-model-select-main .pkg-model-port-signal-main-cphy {
  min-height: 120px;
}

.pkg-model-select-main .pkg-model-port-signal,
.pkg-model-select-main .pkg-model-port-net {
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.pkg-model-select-main .pkg-model-port-signal {
  color: #575757;
}

.pkg-model-select-main .pkg-model-port-copy-signal .aurora-select {
  width: calc(100% - 10px);
  margin: 0px 5px;
}

.pkg-model-select-main .pkg-model-port-pins,
.pkg-model-select-main .pkg-model-port-copy-pins {
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pkg-model-select-main .pkg-model-port-pin {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
}

.pkg-model-select-main .pkg-model-port-pin-title {
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #005a9e;
  display: inline-block;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

.pkg-model-select-main .pkg-model-pin-input-div {
  flex: 0 0 auto;
  text-align: center;
  max-width: 100px;
  width: 90px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  /*  vertical-align: top; */
  background: #eee;
  z-index: 1;
}

.pkg-model-select-main .connection-pin-input-pin-port .connection-pin-input-pin-port-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

.pkg-model-select-main .pkg-model-pin-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 100px;
  width: 90px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
  background: #ffffff;
}

.pkg-model-select-main .pkg-model-select-pins-content {
  padding: 6px 10px;
  background-color: #eee;
  border-radius: 4px;
}

/* port popover */

.pkg-model-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.pkg-model-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.pkg-model-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.pkg-model-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.pkg-model-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.pkg-model-port-list-body-with-search {
  height: 100%;
}

.pkg-model-port-list-body-search-wrapper {
  width: 100%;
}

.pkg-model-port-list-body-search-wrapper .ant-input-affix-wrapper,
.pkg-model-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.pkg-model-port-list-body-search-wrapper .ant-input-affix-wrapper.pkg-model-port-list-body-with-search-input .ant-input {
  height: 18px;
}

.pkg-model-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.pkg-model-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.pkg-model-port-list-ul>li:hover {
  background-color: #cfedff;
}

.pkg-model-port-list-ul>.pkg-current-pin-selected-port-li:hover {
  background-color: #93e6ff;
}

.pkg-model-port-list-ul>.pkg-port-li-selected,
.pkg-model-port-list-ul>.pkg-port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.pkg-model-port-list-ul>.pkg-current-pin-selected-port-li {
  background-color: #93e6ff;
}

.pkg-model-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.pkg-model-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.pkg-model-pin-input-div:hover .pkg-model-pin-node-delete-icon {
  display: inline-block;
}

.pkg-model-select-copy-group {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 6px;
  margin: 6px 0px;
  position: relative;
}

.pkg-model-select-copy-icon {
  color: #3da4fa;
  margin-top: 7px;
  cursor: pointer;
  margin-left: 10px;
}

.pkg-model-select-item>.connector-model-select-span {
  width: 196px;
}

.pkg-model-select-delete-group-icon {
  position: absolute;
  right: 0px;
  top: calc(50% - 25px);
  height: 50px;
  width: 16px;
  line-height: 50px;
  background: #b6d9f6;
  color: #3da4fa;
  cursor: pointer;
  display: none;
  transition: 0.3s;
}

.pkg-model-select-copy-group:hover .pkg-model-select-delete-group-icon {
  display: block;
}

.pkg-model-port-pin .pkg-pin-long-line {
  right: 2px;
}

.pkg-selected-apply-signals {
  color: rgb(109, 109, 109);
  background-color: #ececec;
}

.pkg-model-pin-type-span {
  font-size: 16px;
  height: 28px;
  text-align: center;
  display: inline-block;
  line-height: 28px;
  color: #005a9e;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

.pkg-model-select-main .pkg-model-port-pin-content {
  width: 90px;
  height: 30px;
  background: #eee;
  position: relative;
  z-index: 1;
  padding-left: 4px;
  line-height: 30px;
}

.pkg-model-select-delete-icon {
  position: absolute;
  right: 0px;
  top: 8px;
  font-size: 12px;
}

.pkg-model-select-item:hover .pkg-model-select-delete-icon {
  color: #0d87f7;
}

.pkg-model-select-main .pkg-model-select-item>.pkg-model-select-delete-icon {
  width: 14px;
}

.pkg-model-select-main .multi-pkg-model-file-content>.pkg-model-select {
  width: calc(100% - 156px);
}

.pkg-select-dropdown-menu {
  z-index: 10000000000;
  max-height: 300px !important;
  overflow: auto;
}

.pkg-model-pin-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pkg-model-pin-item {
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 230px;
}

.pkg-model-long-line-content {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 5px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pkg-model-long-line-content .pkg-pin-long-line-item {
  width: 100%;
  height: 30px;
}

.pkg-model-long-line-content .pkg-pin-long-line {
  width: 100%;
  height: 5px;
  background-color: #a6a6a6;
  margin-top: 14.5px;
  transform: scaleY(0.2);
}

.pkg-model-port-pins-title {
  height: 32px;
  display: flex;
  justify-content: space-around;
  padding: 0px 10px;
}

.pkg-model-select-main .pkg-model-port-pins.pkg-model-port-pins-title {
  min-height: 32px;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.pkg-model-port-pins-title>.pkg-model-port-pins-div {
  color: #005a9e;
  line-height: 32px;
  height: 32px;
  text-align: center;
  width: 230px;
}

.pkg-model-port-pins-title>.pkg-model-port-pins-div:nth-of-type(2) {
  width: 160px;
}

.pkg-model-port-pins-title>.pkg-model-port-pins-net {
  width: 230px
}

.pkg-model-port-pins-title>.pkg-die-model-port-pins-net {
  width: 0px;
}

.pkg-model-select-panel .pkg-model-select-ports-main .pkg-model-port-pins .single-pin-port-content {
  justify-content: center !important;
}

.pkg-model-select-main .pkg-multi-model-port-pins .pkg-model-port-pin-content {
  background: unset;
}

.pkg-model-select-main .pkg-multi-model-port-pins .pkg-model-port-left-pin-content {
  width: 90px;
  height: 30px;
  background: unset;
  position: relative;
  z-index: 1;
  /* padding-left: 4px; */
  line-height: 30px;
}

.pkg-multi-model-port-pins .pkg-model-port-left-pin-content .pkg-model-port-left-pin-title {
  width: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #005a9e;
  display: inline-block;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

.pkg-multi-model-port-pins .pkg-model-pin-item {
  width: 200px;
}

.pkg-multi-model-port-pins.pkg-model-port-pins .pkg-model-pin-list {
  width: 305px;
}

.pkg-multi-model-port-pins.pkg-model-port-pins .pkg-model-pin-list:last-child {
  width: 230px;
}

.pkg-multi-model-port-pins-div {
  color: #005a9e;
  line-height: 32px;
  height: 32px;
  text-align: center;
}

.pkg-model-select-content .icon-xinpian_chip {
  cursor: pointer;
}

.pkg-model-select-content .pkg-multi-model-port-pins-div-model-type,
.pkg-model-select-content .pkg-model-port-pins-div-model-type {
  display: inline-block;
  padding-right: 10px;
}

.pkg-multi-model-port-pins-content {
  height: 32px;
  padding-left: 84px;
}

.pkg-model-select-content .pkg-model-port-pins-title .icon-xinpian_chip {
  color: #005a9e;
}

.pkg-model-select-main .pkg-model-select-subckt-item.pkg-model-select-item>.pkg-model-select {
  width: 40%;
}

.pkg-model-select-main .pkg-model-select-subckt-item.pkg-model-select-item.pkg-multi-model-select>.pkg-model-select {
  width: calc(40% - 12px);
}

.pkg-model-select-main .pkg-model-select-subckt-item.pkg-model-select-item>.pkg-model-select:nth-of-type(2) {
  margin-left: 2%;
}

.pkg-model-select-content .pkg-model-select-pins-content .pkg-model-port-pins.pkg-model-port-pins-title {
  height: 16px;
}

.pkg-model-select-main .pkg-model-select-ports-main.pkg-model-select-ports-item-flex {
  display: flex;
  align-items: center;
}

.pkg-model-select-rank-main .pkg-model-left-pin-list .pkg-model-pin-item .spice-pin-line-box {
  display: flex;
  align-items: center;
  height: 30px;
}

.pkg-model-select-rank-main .pkg-model-left-pin-list .pkg-model-pin-item {
  background-color: #eee;
  z-index: 1000;
}

.pkg-model-select-item>.model-title {
  font-weight: 500;
  font-size: 14px;
}

.pkg-die-model-pin-item {
  position: relative;
  justify-content: flex-end;
  width: 100px;
}

.pkg-die-model-pin-item .ant-input-affix-wrapper {
  width: 90px;
  border-radius: 6px;
}
.aurora-circle-tag {
  border-radius: 50%;
  line-height: 15px;
  font-size: 10px;
  padding: 0px 5px;
  margin-left: 4px;
  vertical-align: middle;
}
/* === package model panel === */
.pkg-new-panel .package-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 4px 10px;
}

.pkg-new-panel .package-model-select.package-die-model-select {
  font-weight: 400;
}

.pkg-new-panel .package-model-selection {
  width: calc(100% - 100px);
  height: 24px;
}

.pkg-new-panel .package-model-selection .ant-select-arrow {
  right: 11px !important;
}

.pkg-new-panel .package-model-select-dropdown {
  z-index: 10000000;
}

.pkg-new-panel .package-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 22px;
}

.pkg-new-panel .package-model-title {
  font-size: 14px;
  font-weight: 500;
}

.pkg-new-panel .package-model-select .package-model-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 0px;
  width: calc(100% - 111px);
  flex: 1 1;
}

.spice-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.spice-model-panel .panel-body {
  position: relative;
}

.spice-model-panel .spice-model-panel-title {
  font-size: 14px;
  font-weight: 500;
}

.pkg-new-panel .spice-model-content>div {
  min-width: 650px;
  /* width: fit-content; */
}

.pkg-new-panel .spice-pin-list-ul {
  padding: 3px 10px;
}

.pkg-new-panel .spice-model-single-select .pkg-model-select.pkg-model-select-exist {
  width: calc(100% - 10px);
}

.pkg-new-panel .pkg-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 4px 10px;
}

.pkg-new-panel .pkg-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

.pkg-new-panel .pkg-model-select>.pkg-model-selection-div {
  width: calc(100% - 130px);
  display: inline-block;
}

.pkg-new-panel .pkg-model-select-width>.pkg-model-selection-div {
  width: calc(100% - 100px);
}

.pkg-new-panel .pkg-model-select .pkg-model-selection-div .pkg-model-selection,
.pkg-new-panel .pkg-model-select .pkg-model-selection-div .pkg-model-selection .ant-select-selection {
  height: 24px;
  line-height: 22px;
}

.pkg-new-panel .pkg-model-select .pkg-model-selection-div .pkg-model-selection {
  width: 100%;
}

.pkg-new-panel .pkg-model-select .ant-select-selector,
.pkg-new-panel .pkg-model-select .spice-netlist-model-selection-item,
.pkg-new-panel .pkg-model-select .ant-select-selection,
.pkg-new-panel .spice-netlist-model-selection {
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.pkg-new-panel .spice-netlist-model-selection {
  height: 24px;
}

.pkg-new-panel .pkg-model-select .Subckt {
  width: 0px;
}

.pkg-new-panel .pkg-model-select .Subckt_select {
  width: 100%;
}

.pkg-new-panel .spice-model-multi-select .pkg-model-select {
  padding-left: 5px;
}

.pkg-new-panel .pkg-model-select .ant-switch {
  width: unset;
  display: inline-block;
}

.pkg-new-panel .spice-box {
  cursor: pointer;
}

.pkg-new-panel .spice-model-single-select .ant-select-disabled .ant-select-selection {
  background: #ffffff;
  cursor: text;
  color: rgba(0, 0, 0, 0.65);
}

.pkg-new-panel .spice-model-single-select .ant-select-arrow .ant-select-suffix {
  display: none;
}

.pkg-new-panel .pkg-pin-node-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 80px;
  width: 80px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
}

.pkg-new-panel .pkg-pin-node-input .ant-input:not(:last-child) {
  padding-right: 11px;
}

.pkg-new-panel .pkg-pin-node-input:hover span {
  max-width: 65px;
}

.pkg-new-panel .pkg-pin-node-input span {
  display: inline-block;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pkg-new-panel .pkg-pin-node-input:hover .spice-pin-node-delete-icon {
  display: inline-block;
}

.spice-model-single-select:hover .pkg-model-delete-file-icon,
.spice-model-multi-select:hover .pkg-model-delete-file-icon {
  visibility: visible;
}

.pkg-model-delete-file-icon {
  color: #3da4fa;
  font-size: 13px;
  visibility: hidden;
  position: absolute;
  right: 4px;
  top: 11px;
}

.pkg-new-panel .pkg-model-selection-div .spice-netlist-model-selection {
  width: 100%;
  height: 24px;
  line-height: 22px;
}

.pkg-new-panel .spice-box-signal {
  cursor: pointer;
  padding: 0px 4px;
  text-align: center;
}

.pkg-new-panel .apply-all-main {
  margin-left: 12px;
  margin-top: 12px;
}

.pkg-new-panel .component-apply-all-title {
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}

.pkg-new-panel .component-apply-all-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  height: 24px;
}

.pkg-new-panel .component-apply-all-checkbox .ant-checkbox {
  height: 16px;
  display: inline-block;
}

.spice-netlist-model-title-div .anticon.anticon-menu.ant-dropdown-trigger {
  cursor: pointer;
  color: #0d87f7;
  font-size: 15px;
  margin-left: 10px;
  vertical-align: text-bottom;
}

.circle-blank-box {
  width: 18px;
  padding: 0px 5px;
  margin-left: 4px;
  margin-right: 8px;
}

.spice-netlist-model-title>.spice-netlist-model-title-new {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spice-netlist-model-title .anticon.anticon-ellipsis.ant-dropdown-trigger {
  font-size: 17px;
  margin-left: 10px;
}

.spice-netlist-model-title .spice-netList-model-width-div {
  width: 200px;
  margin-right: 157px;
  position: relative;
  display: flex;
  justify-content: center;
}

.spice-netlist-model-title .spice-netList-model-width-div.spice-netlist-model-title-div .spice-netlist-model-title-pcb-add-icon {
  right: -168px;
}

.add-model-box {
  width: 200px;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #a8c4e6;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  margin: 34px 0 6px;
  z-index: 10;
  margin-left: 10px;
}

.add-model-box>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.add-model-box>div .plus-icon {
  font-size: 48px;
  color: #a8c4e6;
}

.spice-netlist-model-title-div .iconfont.icon-xinpian_chip {
  font-size: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.spice-netlist-model-title .port-icon-box {
  width: 200px;
  text-align: center;
  padding-left: 10px;
  color: #005a9e;
  cursor: pointer;
  line-height: 32px;
  height: 32px;
}

.pkg-model-disable-select .pkg-model-selection .ant-select-selector {
  color: #f30518;
}

.pkg-model-disable-select .anticon.anticon-question-circle.aurora-file-check-icon {
  font-size: 16px;
  top: 18px;
  right: 30px;
}

.pkg-model-disable-panel-select .anticon.anticon-question-circle.aurora-file-check-icon {
  right: 59px;
}

.spice-model-single-select .pkg-model-select.pkg-model-disable-select .pkg-model-selection-div:hover .aurora-file-check-icon {
  right: 50px;
}

.spice-model-single-select .pkg-model-select.pkg-model-disable-panel-select .pkg-model-selection-div:hover .aurora-file-check-icon {
  right: 79px;
}

.spice-model-single-select.spice-subckt-model-single-select {
  display: flex;
}

.spice-subckt-model-single-select .pkg-model-select>*:first-child {
  width: 0;
}

.spice-subckt-model-single-select .pkg-model-select>.pkg-model-selection-div {
  width: 100%;
}

.spice-pin-list-ul .spice-pin-connect-node-title {
  width: 100%;
  text-align: center;
  color: #005a9e;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  padding-left: 8px;
  position: relative;
}

.spice-pin-list-ul .spice-pin-connect-node-title .icon-xinpian_chip {
  cursor: pointer;
}

.spice-pin-list-ul .spice-pin-connect-node-title .anticon-delete {
  margin-left: 8px;
  position: absolute;
  color: #537fa8;
  right: 10px;
  top: 7px;
}

.pkg-new-panel .spice-box.spice-box-disable {
  cursor: default;
}

/* .spice-subckt-model-single-select-width {
  width: 100%;
} */

.pkg-new-panel .spice-subckt-model-single-select-width .pkg-model-select {
  width: 100%;
}

.pkg-panel .find-package-layout-errorMsg-box {
  margin: 4px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 7px;
  max-height: 130px;
  overflow: auto;
  font-size: 14px;
}

.pkg-panel .find-package-layout-errorMsg-box .errorMsg-box-div {
  padding-left: 5px;
}

.pkg-panel .find-package-layout-errorMsg-box .errorMsg-box-span {
  padding-left: 15px;
}

.spice-pin-list-li-left,
.spice-pin-list-li-right {
  width: min-content;
}

.spice-netlist-model-collapse {
  margin: 4px 10px;
}

.spice-netlist-model-collapse>.ant-collapse-item:last-child .ant-collapse-header {
  padding: 6px 6px;
  padding-left: 6px;
}

.spice-netlist-model-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 8px;
}

.spice-netlist-model-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px 8px 8px 8px;
}

.rocky-pkg-panel .spice-pin-list-ul .spice-pin-connect-node-item-center {
  justify-content: center;
  position: unset;
}

.spice-pin-line-die {
  margin-left: 8px;
}

.andes-die-model-DIE {
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.andes-die-model-termination {
  position: absolute;
  right: 20px;
}

.spice-netlist-model-title {
  position: relative;
  height: 32px;
}

.spice-netlist-model-title .spice-netList-model-width-div-termination {
  margin-right: 0px;
}

.spice-netList-model-width-div-die .anticon-delete {
  position: absolute;
  right: -20px;
  top: 7px;
  color: #537fa8;
  font-size: 16px;
}

.spice-netList-model-termination-box {
  position: relative;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.pkg-new-panel .pkg-model-select.pkg-die-model-select {
  font-weight: 400;
}

.model-row-item {
  display: flex;
  padding: 4px 10px;
  height: 32px;
}

.model-row-item>label {
  width: 130px;
}

.model-row-item>.model-title {
  font-weight: 500;
  font-size: 14px;
}

.model-row-item>div {
  flex: 1 1;
}

.spice-netList-model-termination-box {
  position: relative;
}

.spice-netList-model-termination-box>.model-termination-input-box {
  position: absolute;
  width: 80px;
  left: 50%;
  top: -8px;
  transform: translateX(-50%);
}
.copy-setup-panel-content {
  padding: 10px 12px;
}

.copy-setup-panel-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.copy-setup-panel-footer>span {
  width: 114px;
  line-height: 28px;
}

.copy-setup-panel-footer>.aurora-select {
  width: calc(100% - 114px);
  height: -moz-fit-content;
  height: fit-content;
  min-height: 28px;
}

.copy-setup-panel-select-dropdown-menu {
  z-index: 10000000000;
}
.virtual-component-table-main .virtual-component-add-icon {
  color: #1890ff;
  margin-left: 12px;
  cursor: pointer;
}

.virtual-component-table tbody>tr>td:last-child {
  position: relative;
}

#layout-setup-canvas-main {
  width: 100%;
  height: 100%;
  position: relative;
}

.layout-setup-canvas-net-name {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: #000000;
  width: 320px;
}

.layout-setup-canvas-net-name .un-show-switch {
  background-color: #999999;
}

.layout-setup-canvas-net-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.layout-setup-canvas-net-switch {
  margin-left: 10px;
  margin-top: -5px;
}

.layout-setup-canvas {
  height: 100%;
  padding-bottom: 0px;
  width: 100%;
  background-color: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: translateZ(0);
}

.virtual-component-edit-main {
  padding: 12px 14px;
}

.virtual-component-edit-table-content {
  margin-bottom: 10px;
}

.virtual-component-view-icon,
.virtual-component-clean-icon {
  cursor: pointer;
  margin-left: 10px;
  vertical-align: middle;
}

.virtual-component-view-selected-icon,
.virtual-component-clean-icon {
  color: #1890ff;
}

.virtual-component-comps {
  vertical-align: middle;
}

.virtual-component-edit-table tbody>tr>td:last-child {
  position: relative;
}

.virtual-component-edit-table .virtual-component-edit-cut-length {
  margin-right: 22px;
}

.virtual-component-edit-table .ant-table-content>.aurora-table-body .virtual-component-edit-cut-length:hover .signal-delete-icon {
  color: #1890ff;
}

.virtual-component-edit-main .aurora-model-name-error-msg {
  margin-top: 8px;
}

.virtual-component-layout-canvas-coord {
  position: absolute;
  left: 5px;
  bottom: 10px;
  color: #fff;
  float: right;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.virtual-component-selected-row,
.virtual-component-selected-row:hover,
.virtual-component-edit-table .virtual-component-selected-row:hover>td,
.virtual-component-edit-table .virtual-component-selected-row>td {
  background-color: #9cd8ff;
}

.virtual-component-edit-table .ant-table .ant-table-content .aurora-table-body>tr {
  cursor: pointer;
}

.virtual-component-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.virtual-comp-value-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
}

.virtual-comp-value-item>span {
  width: 114px;
}

.virtual-comp-value-item>.aurora-input {
  width: calc(100% - 114px);
}

.virtual-comp-value-item>.aurora-input .ant-input-group-addon {
  width: 60px;
}

.virtual-comp-cap-value-item {
  margin-bottom: 10px;
}

.virtual-component-edit-table .ant-table-row>td.virtual-components-disabled-td,
.virtual-component-table td.virtual-components-disabled-td,
.virtual-component-edit-table .ant-table-row:hover>td.virtual-components-disabled-td,
.virtual-component-table .ant-table-row:hover>td.virtual-components-disabled-td {
  background-color: #f3f3f3;
  cursor: not-allowed;
}
.dividingLine {
  z-index: 1000;
  width: 3px;
  height: 100%;
  cursor: col-resize;
}

.dividingLine:hover +.dividingLine-hover {
  display: block;
  transition: all 0.3s;
}

.dividingLine-hover {
  z-index: 998;
  width: 16px;
  height: 100%;
  background: #d0d0d045;
  top: 0px;
  display: none;
  transition: all 0.3s;
}

.dividingLine-border {
  z-index: 999;
  width: 1px;
  height: 100%;
  top: 0px;
  background: #e2dfdf;
}
.result-right {
  border-left: 1px solid #e8e8e8;
  background-color: #f9f9f9;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;
}


.result-right .result-setting-menu {
  margin: 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.result-right .result-setting-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}
.result-waveform-setting .waveform-setting-row {
  margin: 5px 0px;
}

.result-waveform-setting .axis-title,
.result-waveform-setting .input-lable {
  font-weight: bold;
  margin-right: 8px;
}

.result-waveform-setting .reload-btn {
  color: rgba(0, 0, 0, 0.65);
  border: 0px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-left: 4px;
}

.result-waveform-setting .setting-input {
  width: 60%;
  margin-left: 2px;
  color: #2e8bdd;
}

.result-waveform-setting .result-waveform-setting-select {
  width: calc(100% - 74px);
}

.result-waveform-setting .waveform-scale-label {
  display: inline-block;
  width: 40px;
}
.sparameter-display-mode {
  padding: 4px 12px;
  height: 35px;
}

.sparameter-display-mode label.ant-radio-wrapper {
  line-height: 30px;
}

.sparameter-result-file-name {
  transition: all 0.3s;
  font-weight: bold;
  margin-top: 5px;
  padding-left: 5px;
  cursor: pointer;
  line-height: 24px;
  height: 24px;
  position: unset !important;
}

.sparameter-result-file-name .ant-col {
  position: absolute !important;
}

/* .sparameter-result-file-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
} */

.sparameter-file-expand-icon {
  padding-right: 4px;
  font-size: 10px;
  padding-left: 7px;
  vertical-align: baseline;
}

.sparameter-file-error-icon {
  color: #f12727;
}

.sparameter-file-download-icon {
  position: absolute;
  right: 8px;
  font-size: 18px;
  color: #40a9ff;
  margin-top: 2px;
  display: none;
}

.result-opt-icon {
  margin-left: 10px;
  color: #40a9ff;
  font-size: 16px;
  vertical-align: text-bottom;
}

.sparameter-result-file-name:hover .sparameter-file-download-icon,
.sparameter-result-file-name:hover .sparameter-file-delete-icon,
.sparameter-compare-title:hover .sparameter-file-delete-icon {
  display: inline-block;
}

.sparameter-span-file-name {
  width: calc(100% - 55px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.sparameter-result-ports-list {
  padding-left: 10px;
  position: unset !important;
}

.sparameter-channel-port-name {
  line-height: 24px;
  height: 24px;
  padding-left: 4px;
  cursor: pointer;
  margin-left: 0px;
}

.sparameter-channel-port-name .ant-checkbox-wrapper {
  line-height: 24px;
  height: 24px;
}

.sparameter-channel-port-name-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #7b7b7b;
  border-color: #7b7b7b;
}

.sparameter-channel-port-name-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: #7b7b7b;
}

.sparameter-channel-port-name-general {
  padding-left: 6px;
  margin-left: 0px;
}

.sparameter-channel-port-name:hover {
  background: #efefef;
}

.sierra-waveform-result-list .sparameter-channel-port-name:hover {
  background: #f9f9f9;
}

.sparameter-result-checkbox-group-name-single {
  padding-left: 23px;
}

.sparameter-channel-display-row {
  display: inline-flex;
  width: calc(100% - 16px);
  position: absolute;
  align-items: center;
}

.sparameter-channel-display-row>span:not(.anticon) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: calc(100% - 26px);
}

.sparameter-channel-display-multi-row>span {
  font-weight: normal;
}

.sparameter-sweep-channel-display-row {
  background: #f9f9f9;
  display: flex;
  align-items: center;
}

.sierra-waveform-result-list .sparameter-channel-port-name .sparameter-channel-display-row.sparameter-sweep-channel-display-row {
  left: 40px;
  min-width: 200px;
  max-width: 200px;
}

.sparameter-channel-port-name .sparameter-channel-display-row:hover {
  background: #efefef;
}

.sparameter-result-checkbox-group-name {
  line-height: 20px;
}

.sparameter-result-checkbox-group-name>label {
  color: #000;
  vertical-align: middle;
  margin-top: 1px;
}

.sparameter-result-sweep-group>label:nth-child(2) {
  width: calc(100% - 20px);
}

.sparameter-result-sweep-group>label .checkbox-group-title {
  width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sparameter-result-checkbox-group-name .checkbox-group-usage {
  width: 90px;
  font-weight: bold;
  display: inline-block;
  flex-shrink: 0;
}

.sparameter-result-checkbox-group-name>.aurora-color-picker-small {
  vertical-align: middle;
}

.sparameter-result-checkbox-group {
  padding-left: 4px;
}

.sparameter-result-checkbox-group>.ant-checkbox {
  top: 2px;
}

.sparameter-channel-result-content {
  padding: 0 12px 6px 4px;
  overflow-x: auto;
}

.sparameter-result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
}

.result-curve-current {
  margin-left: 10px;
  vertical-align: middle;
  font-weight: bold;
}

.sparameter-channel-result-content .ant-divider {
  top: unset;
  left: 0;
  padding-left: 10px;
}

.sparameter-channel-result-content .ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 0%;
}

.sparameter-sweep-checkbox {
  width: 70px;
  min-width: 70px;
  margin-left: 0px;
  margin-right: 10px;
  text-align: left;
  position: relative;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  padding-right: 10px;
}

.sparameter-sweep-checkbox .ant-color-picker-trigger.aurora-color-picker-small {
  margin-right: 0px;
  margin-left: 5px;
}

.sparameter-sweep-result-group {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.sweep-color-select {
  position: absolute;
  top: 2px;
  border-radius: 4px;
}

.sparameter-result-sweep-group {
  width: 240px;
  min-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  background-color: #f9f9f9;
  z-index: 999;
  left: 0;
  padding-left: 35px;
}

.sparameter-sweep-checkbox-group {
  margin-top: -2px;
  padding-right: 5px;
}

.sparameter-result-checkbox-group-name .checkbox-group-pcb {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
  margin-left: 10px;
  vertical-align: middle;
  margin-top: -3px;
}

.sparameter-result-checkbox-group-name .checkbox-group-name {
  min-width: 80px;
  display: inline-block;
}

.sparameter-all-sweep-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #464646;
  border-color: #464646;
}

.sparameter-all-sweep-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background: #464646;
}

.sparameter-signal-sweep-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #7b7b7b;
  border-color: #7b7b7b;
}

.sparameter-signal-sweep-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background: #7b7b7b;
}

.sparameter-channel-display-inline-flex {
  display: inline-flex;
  /* display: flex; */
  align-items: center;
  width: 100%;
  padding-left: 0;
}

.sparameter-result-file-name .sparameter-result-file-name-checked-title-history {
  left: 10px;
}

.sparameter-result-file-name .sparameter-result-file-name-checked-title {
  min-width: 240px;
  width: unset;
  position: absolute;
  z-index: 999;
  background-color: #f9f9f9;
  left: 0;
  padding-left: 10px;
  max-width: 260px;
  height: 24px;
}

.sparameter-channel-result-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.sparameter-channel-display-inline-flex .sparameter-channel-result-vertically-divider {
  min-width: 240px;
  width: unset;
  position: absolute;
  z-index: 999;
  background-color: #f9f9f9;
}

.result-checkbox-group-name-flex {
  width: 100%;
  height: 24px;
}

.ant-divider-horizontal.ant-divider-with-text-left.sparameter-channel-result-post-divider {
  min-width: 255px;
  width: unset;
  position: absolute;
  background-color: #f9f9f9;
  z-index: 999;
}

.sparameter-result-menu-post-title {
  width: 80px;
  margin-left: 0;
  margin-right: 0px;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080;
  cursor: auto;
}

.sparameter-result-menu-post-title-divider {
  padding-left: 14px;
  /* borderLeft: '1px solid #e8e8e8' */
}

.sparameter-sweep-post-title-box {
  min-width: 160px;
}

/* .sparameter-sweep-post-title-box .sparameter-result-menu-post-title-divider {
  padding-left: 14px;
} */

.sparameter-result-checkbox-group-left {
  position: absolute;
  width: 270px;
  min-width: 260px;
  background-color: #f9f9f9;
}

.result-checkbox-group-name-flex .sparameter-result-checkbox-group-name .checkbox-group-pcb {
  width: 70px;
  display: inline-block;
}

.sparameter-post-process-checkbox {
  width: 80px;
  min-width: 80px;
  margin-left: 0px;
  margin-right: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080;
}

.sparameter-signal-sweep-checkbox .sparameter-post-process-checkbox {
  min-width: 50px;
}

.sparameter-channel-display-inline-flex .sparameter-post-process-checkbox {
  min-width: 50px;
  margin-right: 31px;
}

.sparameter-sweep-checkbox .sparameter-sweep-table-icon {
  position: absolute;
  width: 19px;
  height: 23px;
  top: -1px;
  border-radius: 4px;
  left: 44px;
}

.sparameter-file-delete-icon {
  font-size: 13px;
  color: #40a9ff;
  display: none;
}

.sparameter-file-upload-button {
  margin-left: 10px;
  margin-top: 0px;
  color: #40a9ff;
  font-size: 15px;
  cursor: pointer;
  padding-top: 4px
}

#sparameter-import-divider .ant-divider-inner-text {
  padding-right: 100px !important
}

.sparameter-file-icon-disabled {
  color: #ccc
}

.sparameter-file-compare-cascader {
  margin-left: 13px;
  margin-top: 5px;
  width: calc(100% - 50px);
}

.sparameter-cascader-close-icon {
  display: unset;
  margin-left: 10px;
}

.sparameter-compare-title {
  margin-left: 5px;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.sparameter-compare-title .sparameter-file-delete-icon {
  margin-left: 16px;
}

.sparameter-compare-content {
  margin-left: 10px;
  margin-top: -5px;
}

.sparameter-result-file-content {
  margin-left: 10px;
}

.anticon.anticon-table.aurora-result-highLight-table-icon {
  color: #1890ff;
}

.sparameter-result-curve-title-pcb1,
.end-to-end-sparameter-result .sparameter-result-curve-title-rl-pcb1 {
  color: #3689d6;
}

.sparameter-result-curve-title-pcb2,
.end-to-end-sparameter-result .sparameter-result-curve-title-rl-pcb2 {
  color: #20a34e;
}

.end-to-end-sparameter-result .sparameter-channel-display-row {
  position: static;
  cursor: pointer;
}

.end-to-end-sparameter-result .sparameter-result-checkbox-group,
.end-to-end-sparameter-result .sparameter-channel-port-name {
  padding-left: 0px;
}

.end-to-end-sparameter-result .sparameter-result-file-content {
  margin-top: 5px;
}

.end-to-end-sparameter-result {
  min-height: 50px;
}

.sparameter-channel-display-multi-row .sparameter-result-file-name .sparameter-result-file-name-checked-title {
  padding-left: 27px;
}

.sparameter-result-file-content .ant-checkbox-group {
  display: block;
}

.sparameter-result-file-name .ant-col {
  width: 95%;
}

.sparameter-sweep-exp-title-icon {
  margin-left: 4px;
  font-size: 12px;
}
.sierra-result-parameter-file-list {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 318px;
}

.sierra-result-parameter-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.sierra-result-parameter-left {
  background-color: #fafafa;
  border-left-color: #e8e8e8;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.font-bold {
  font-weight: bold;
}

.sierra-touchstone-result-main,
.sierra-touchstone-result-main .ant-spin-nested-loading,
.sierra-touchstone-result-main .ant-spin-container {
  height: 100%;
}

.sierra-touchstone-result-main,
.sierra-waveform-result-main {
  height: 100%;
}

.sierra-waveform-result-main .waveform {
  position: relative;
}

.sierra-touchstone-result-main .sierra-result-setting {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 50%;
  bottom: 0;
}

.sierra-result-parameter-file-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.sierra-result-parameter-file-list .ant-divider-inner-text {
  padding-left: 2px !important;
  padding-right: 30px !important;
}

.sierra-result-parameter-file-list .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.sierra-touchstone-result-main .sparameter-channel-result-content .sparameter-file-expand-icon {
  vertical-align: 7px;
}

.sierra-touchstone-result-main .sparameter-channel-result-content .sparameter-span-file-name {
  display: inline-block;
}

.sierra-touchstone-result-main .sparameter-result-file-name {
  color: rgba(0, 0, 0, 0.65);
}

.sweep-waveform-pcb-legend .sweep-waveform-pcb-legend-content {
  position: absolute;
  display: flex;
  align-items: center;
}

.sweep-waveform-pcb-legend .sweep-waveform-pcb-legend-title {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sparameter-result-checkbox-content .rocky-sparameter-result-checkout-blank {
  margin-top: 0;
  margin-bottom: 0;
  min-width: 240px;
  width: unset;
  position: absolute;
  z-index: 999;
  background-color: #f9f9f9;
  height: 20px;
  left: 0;
}

.sparameter-channel-port-name .sparameter-sweep-channel-display-row {
  min-width: 220px;
  width: unset;
  position: absolute;
  z-index: 999;
  left: 0;
}

.waveFrom-post-process-content .post-process-panel-content {
  padding: 10px 10px 0;
}

.waveFrom-post-process-content .post-process-panel-content .ant-table-wrapper.post-process-panel-table .ant-table-middle>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th {
  text-align: left;
}

.waveFrom-post-process-panel .post-process-panel-content {
  padding: 10px 10px 0;
}

.waveFrom-post-process-panel .post-process-panel-content .ant-table-wrapper.post-process-panel-table .ant-table-middle>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th {
  text-align: left;
}

.post-process-panel-compare-table .ant-table-bordered .aurora-table-body>table {
  border-top: none;
  border-radius: 0;
  padding-right: 15px;
}

.post-process-panel-data-table .ant-spin-container .ant-table-title {
  padding-top: 0;
  padding-bottom: 0;
  height: 38px;
  border-radius: 0;
  padding: 0;
  padding-right: 15px;
}

.post-process-panel-data-table .ant-table-title .post-process-panel-data-table-title {
  display: flex;
  height: 100%;
  color: #000000d9;
  font-size: 14px;
}

.post-process-panel-data-table .ant-table-title .post-process-panel-data-table-title .table-title-box {
  padding: 8px;
}

.post-process-panel-data-table .ant-table-title .post-process-panel-data-table-title .table-title-rising-box {
  width: 50%;
  border-right: 1px solid #e8e8e8;
  padding: 8px;
}

.post-process-panel-compare-table .ant-table.ant-table-small.ant-table-bordered.ant-table-scroll-position-left {
  border-top: none;
  border-radius: 0;
}

.post-process-panel-data-table .ant-table.ant-table-small.ant-table-bordered.ant-table-scroll-position-left {
  border-radius: 0;
}

.post-process-panel-data-table tr.ant-table-row.editable-row.ant-table-row-level-0 {
  cursor: pointer;
}

.post-process-content-title {
  border: 1px solid #e8e8e8;
  padding: 8px;
  border-bottom: none;
  color: #000000d9;
}

.result-checkbox-group-name .checkout-group-table-data-content {
  display: inline-flex;
  margin-left: 5px;
}

.result-checkbox-group-name .checkout-group-table-data-content .checkout-group-table-data-content-div {
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-checkbox-group-name span.checkout-group-table-data-content-span {
  display: inline-block;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.sparameter-channel-port-name .sparameter-sweep-channel-post-display-row {
  min-width: 215px;
  width: unset;
  position: absolute;
  z-index: 999;
  background-color: #f9f9f9;
  /* left: 0; */
}

.post-process-title-comp {
  color: #1890ff;
}

.waveFrom-post-process-content {
  height: 100%;
}

.post-process-panel-compare-table .ant-table-small>.ant-table-content>.aurora-table-body {
  margin: 0;
}

.post-process-panel-edge-compare-table .ant-table-small>.ant-table-content>.aurora-table-body {
  background-color: #edf7ff;
}

.waveform.sierra-result-waveform .sierra-waveform-result-list .waveform-setting-box {
  /* bottom: 230px; */
  bottom: 6px;
}

.waveform.sierra-result-waveform .sierra-waveform-result-list .waveform-post-process-box {
  position: absolute;
  bottom: 210px;
  left: 0px;
  right: 0px;
}

.sierra-result-waveform .sierra-waveform-result-list.sierra-waveform-result-post-process-list {
  height: calc(100% - 210px);
}

.sierra-result-waveform .sierra-waveform-result-list.sierra-waveform-result-post-process-list.sierra-waveform-result-sweep-list {
  height: calc(100% - 200px);
}

/* .sierra-waveform-result-post-process-list .sparameter-channel-result-content  .sparameter-channel-port-name {
  position: relative;
} */

.waveform.sierra-result-waveform .sierra-waveform-result-list.sierra-waveform-result-sweep-list .waveform-setting-box {
  bottom: 0px;
}

.post-process-panel-data-selected-row,
.post-process-panel-data-table .ant-table-tbody>.post-process-panel-data-selected-row:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: #9bcfff;
}

.post-process-panel-data-row {
  cursor: pointer;
}

.post-process-content-title>div:first-child {
  display: inline;
}

.post-process-content-title-divide {
  margin: 0 5px;
}

.post-process-content-title-unit {
  margin-right: 5px;
}

.post-process-content-title .ant-select-single.ant-select-sm.ant-select-show-arrow {
  width: 60px;
}

.sierra-measure-edit-icon {
  cursor: pointer;
  padding-left: 10px;
}

.sierra-measure-panel {
  top: 130px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-measure-progress {
  padding: 0px 12px;
  width: calc(100% - 78px);
}

.sierra-result-content .sierra-touchstone-result-main .sierra-sparameter-result-content {
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.post-process-panel-data-table .input-select-after {
  min-width: 40px;
}

.impedance-unit-selection {
  margin-left: 8px;
}

.impedance-unit-selection {
  height: 26px;
}

.impedance-unit-selection .ant-select-selector {
  line-height: 24px;
}

.impedance-unit-selection .ant-select-arrow {
  right: 6px;
}

.impedance-unit-option {
  z-index: 2000;
}

.sierra-waveform-result-list .sparameter-result-checkbox-group,
.sierra-waveform-result-list .sparameter-result-checkbox-group-name {
  display: flex;
  align-items: center;
}

/* 
.sierra-waveform-result-list .sparameter-result-checkbox-group-name {
  width: calc(100% - 36px);
}
 */
.sierra-waveform-result-list .result-checkbox-group-name .sparameter-result-checkbox-group>span:nth-child(2) {
  width: calc(100% - 16px);
}

.sierra-waveform-result-list .sparameter-result-checkbox-group-name>label {
  max-width: -moz-fit-content;
  max-width: fit-content;
  white-space: nowrap;
}

.sierra-waveform-result-list .result-curve-current {
  line-height: 24px;
  height: 22px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-left: 0px;
}

.sierra-waveform-result-list .checkbox-group-postProcess-icon {
  position: absolute;
  left: 264px;
  top: 5px;
  background-color: #fff;
  padding-right: 4px;
  vertical-align: middle
}

.sierra-waveform-result-list .sparameter-sweep-checkbox .result-curve-current,
.sierra-sweep-sparameter-result .sparameter-sweep-checkbox .result-curve-current {
  position: absolute;
  left: 40px;
}

.measurement-footer-apply-content {
  height: 28px;
  display: flex;
  flex-wrap: wrap;
}

.measurement-footer-item {
  width: 25%;
  display: flex;
  height: 28px;
  line-height: 28px;
}

.measurement-footer-item .aurora-input {
  width: calc(100% - 38px);
}

.measurement-footer-item>label {
  width: 38px;
  text-align: left;
}

.measurement-footer-item-button {
  width: 100%;
  height: 28px;
  line-height: 28px;
}

.measurement-footer-item-unit-select {
  width: 80px;
}

.measurement-footer-item-unit-select .ant-select-selector {
  font-size: 12px;
}

.measurement-footer-padding-left-item {
  padding-left: 14px;
}

.measurement-table-component-title {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.waveFrom-post-process-measure-content .input-with-select .input-select-after .ant-select-selector {
  margin-right: 18px;
  margin-left: 2px;
  line-height: 28px;
}

.waveFrom-post-process-measure-content .aurora-table .input-with-select .ant-select .ant-select-selector .ant-select-selection-item {
  padding-inline-end: 0px;
}

.waveFrom-post-process-measure-content .input-with-select .input-select-after .ant-select-selector .ant-select-selection-selected-value {
  font-size: 12px;
}

.waveFrom-post-process-measure-content .input-with-select .input-select-after .ant-select-arrow {
  right: 6px;
}

.waveFrom-post-process-measure-content {
  min-width: 1100px;
  padding: 10px;
}

.sierra-result-content-title {
  margin-bottom: 8px;
  color: #0b3b94;
}

.sierra-trace-capacitance-content .sierra-result-content-box .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td:nth-child(1) {
  max-width: 200px;
}

.sierra-result-content .result-waveform-setting .axis-title,
.sierra-result-content .result-waveform-setting .input-lable {
  color: rgba(0, 0, 0, 0.65);
}

.measurement-vdd-content .measurement-vdd-setting-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  color: #1890ff;
}

.post-process-panel-data-table .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th:nth-child(3) {
  position: relative;
}

.sierra-result-curve-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 20px;
  margin-left: 8px;
  right: 0px;
  width: 45px;
  height: 78px;
}

.sierra-result-curve-right .photo-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 6px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); */
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 26px;
  transition: all 0.3s;
}

.sierra-result-curve-right .photo-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.sierra-photo {
  width: 19px;
  margin-left: 1px;
}

.sierra-impedance-expand-row {
  background: #f2f7fa;
}

.measurement-footer-button-content {
  margin-top: 6px;
}
.waveFrom-post-process-measure-sweep-item {
  border-radius: 4px;
  margin: 4px 0px;
  padding: 0px 6px;
  padding-bottom: 4px;
}

.waveFrom-post-process-measure-sweep-item>.waveFrom-post-process-measure-sweep-item-title {
  font-weight: 500;
  font-size: 16px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.measurement-footer-apply-all-content {
  padding-top: 6px;
  height: 34px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
}

.measurement-footer-apply-all-title {
  width: 260px;
  text-align: left;
}

.measurement-footer-apply-all-content>.aurora-select {
  width: calc(100% - 330px);
}

.measurement-footer-apply-content .measurement-result-footer-apply-all-content>.aurora-select {
  width: calc(100% - 240px);
}

.measurement-footer-apply-content .measurement-result-footer-apply-all-content .measurement-footer-apply-all-title {
  width: 240px;
  text-align: left;
}

.measurement-footer-apply-all-content>.aurora-select.ant-select-multiple .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item {
  height: 22px;
  line-height: 20px;
}

.measurement-footer-apply-all-content>.aurora-select.ant-select-multiple .ant-select-selection-overflow-item-suffix {
  min-height: 20px;
}

.measurement-footer-apply-all-content>.aurora-select.ant-select-multiple .ant-select-selector {
  padding-block: 0px;
}

.measurement-footer-apply-all-content>.aurora-select.ant-select-multiple .ant-select-selection-wrap {
  height: 26px;
}

.waveFrom-post-process-measure-sweep-content {
  padding: 8px 12px;
  position: relative;
}

.waveFrom-post-process-measure-sweep-content .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px;
  color: rgba(0, 0, 0, 0.85);
}

.waveFrom-post-process-measure-sweep-content .ant-collapse {
  background: #ffffff;
}

.waveFrom-post-process-measure-sweep-content .ant-collapse-borderless>.ant-collapse-item {
  border-bottom: none;
  margin-bottom: 6px;
  background: #f1f1f1;
}

.waveFrom-post-process-measure-sweep-left {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 120px;
  min-height: 200px;
  border: 1px solid #eee;
  background-color: #fff;
}

.waveFrom-post-process-measure-sweep-checkbox {
  margin-right: 8px;
}

.waveFrom-post-process-measure-sweep-progress {
  display: inline-block;
  max-width: 60%;
  width: 300px;
  margin-left: 12px;
}

.measurement-footer-sweep-warning-icon {
  margin-left: 0px;
  margin-right: 12px;
  color: #ff6b00;
  font-size: 14px;
  cursor: pointer;
}
.color-hint {
  width: 12px;
  height: 12px;
  display: inline-block;
}

.wave-range {
  background-color: #fff;
  border: 1px solid #ccc;
  width: 80px;
}

.waveform {
  width: 100%;
  height: 100%;
}

.waveform-clear {
  zoom: 1;
}

.waveform-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.sierra-waveform-plot {
  float: left;
  width: 600px;
  height: 500px;
}

.sierra-waveform-setting>.ant-checkbox-group>.ant-checkbox-wrapper {
  margin-left: 0px;
  display: inline-block;
  width: 100%;
}

.sierra-waveform-setting .sierra-eyediagram {
  width: 100%;
}

.sierra-waveform-setting .sierra-eyediagram .ant-radio-group {
  width: 100%;
}

.sierra-waveform-setting .sierra-eyediagram>.ant-radio-group>.ant-radio-wrapper {
  width: 100%;
}

/* 2D curve plotting */

.plot .curve {
  fill: none;
}

.plot .tick line {
  stroke: #ccc;
}

.selector-slide {
  cursor: move;
  fill: #337ab7;
}

.curve-mark {
  fill: #ff0000;
}

.mark-text {
  font-size: 10px;
  text-anchor: middle;
}

tr.ant-table-expanded-row>td {
  padding: 0px 0px 0px 0px !important;
}

tr.ant-table-expanded-row table {
  padding: 0 !important;
}

tr.ant-table-expanded-row .ant-table {
  border-left-width: 0 !important;
}

.impedence .ant-tabs-bar {
  margin: 0px;
}

.sierra-input-start {
  position: absolute;
  bottom: 0;
  left: 0;
}

.sierra-input-end {
  position: absolute;
  bottom: 0;
  left: 700px;
}

.sierra-waveform-driver {
  margin-left: 10px;
  width: 100%;
}

.impedance-expand-table {
  margin: 0px !important;
}

.impedance-expand-table .ant-table-small>.ant-table-content>.aurora-table-body {
  padding: 0px;
  margin: 0px;
}

.impedance-expand-table .ant-table-small,
.impedance-expand-table .ant-table-small.ant-table-bordered .ant-table-content {
  border: 0px !important;
}

.sierra-waveform-design-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
}

.sierra-waveform-design-name {
  max-width: calc(100% - 230px);
}

.sierra-waveform-design-name-current {
  max-width: calc(100% - 290px)
}

.sierra-waveform-checkbox .ant-checkbox {
  float: left;
  margin-top: 4px;
  margin-right: 5px;
  margin-left: 5px;
}

.sierra-waveform-checkbox:hover .color-select {
  background-color: #dbdbdb;
}

.sierra-waveform-checkbox span {
  padding: 0px;
}

.sierra-waveform-checkbox-lable {
  width: 100%;
  height: 20px;
  display: block;
}

.sierra-waveform-tooltip {
  width: 100%;
  height: 20px;
  display: block;
}

.sierra-waveform-checkbox:hover {
  background: #dbdbdb;
}

.sierra-waveform-left {
  height: 100%;
  float: left;
  width: 100%;
}

.sierra-waveform-setting {
  border-left-color: #dcdcdc;
  border-left-width: 1px;
  border-left-style: solid;
  background-color: #ffffff;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;
  min-width: 320px;
}

.sierra-waveform-left .sierra-waveform-svg {
  height: 100%;
  width: 100%;
}

.axis-title,
.setting-input-lable,
.display-mode-title {
  font-weight: bold;
  margin-right: 8px;
}

.sierra-setting-input {
  width: 60%;
}

.sierra-waveform-checkboxgroup {
  padding-left: 10px;
  width: 100%;
  height: 56%;
  overflow-y: auto;
  background-color: #fafafa;
}

.sierra-waveform-setting-box {
  margin-left: 10px;
  max-width: 450px;
  height: 40%;
  overflow: auto;
}

.sierra-waveform-setting .dividingLine {
  height: 100%;
}

.sierra-result-main .ant-menu-item {
  margin-right: 10px;
}

.sierra-waveform-svg .domain {
  display: none;
}

.sierra-result-main .ant-spin-nested-loading,
.sierra-result-main .ant-spin-container,
.sierra-result-main {
  height: 100%;
  box-sizing: border-box;
}

.sierra-result-eye-diagram-select {
  float: left;
  margin: 8px 10px;
}

.sierra-result-eye-diagram-button {
  margin: 8px 10px;
  float: left;
}

.sierra-result-eye-diagram-progress {
  margin: 12px 10px;
  width: 240px;
  margin-left: 0;
  float: left;
}

.sierra-result-eye-diagram-btn {
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 100px;
  padding: 0;
}

.sierra-result-img {
  /*   margin-left: -10px;
  margin-top: -10px; */
  width: 100%;
  height: 100%;
}

.sierra-result-eye-diagram {
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.sierra-result-content-box {
  padding: 20px 20px;
}

/* Eye diagram */

.sierra-eye-diagram-main {
  padding: 10px;
  height: 100%;
  position: relative;
  overflow: auto;
  width: 100%;
}

.sierra-eye-diagram-left {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 400px;
  transition: 0.3s;
  min-width: 200px;
}

.sierra-eye-diagram-right {
  position: absolute;
  left: calc(100% - 400px);
  top: 0px;
  bottom: 0px;
  right: 0px;
  border-left: 1px solid #e8e8e8;
  background-color: #fafafa;
  transition: 0.3s;
}

.sierra-eye-diagram-button {
  margin: 8px 12px;
}

.sierra-eye-diagram-btn {
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 100%;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sierra-eye-diagram-config-main {
  padding-top: 14px;
  padding-right: 10px;
}

.sierra-eye-diagram-config-main .sierra-eye-diagram-config-content {
  min-width: 324px;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content>.ant-collapse-item {
  background: #fafafa;
  border-radius: 0px;
  margin-bottom: 0px;
  border: 0px;
  overflow: hidden;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content>.ant-collapse-item>.ant-collapse-header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  padding: 0px 10px;
  padding-left: 8px;
}

.sierra-eye-diagram-config-main .sierra-eye-diagram-right .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
  padding-inline-end: 8px;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content>.ant-collapse-item>.ant-collapse-header:hover {
  background: #dbdbdb;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  font-size: 10px;
  color: rgba(0, 0, 0, .44);
  left: 6px;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-content .ant-collapse-content>.ant-collapse-content-box {
  padding-bottom: 0px;
  padding-top: 0px !important;
  padding-right: 6px;
}

.sierra-eye-diagram-config-item {
  margin: 10px 0px;
  margin-left: 15px;
}

.sierra-eye-diagram-config-clock-item {
  margin: 8px 0px;
  margin-left: 33px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-span {
  display: inline-block;
  width: 140px;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  font-weight: 700;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-select {
  display: inline-block;
  width: calc(100% - 140px);
  line-height: 24px;
  font-size: 13px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-select .ant-select-selection--multiple {
  min-height: 24px;
  padding-bottom: 1px;
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-clock-item .sierra-eye-diagram-config-item-select {
  width: calc(100% - 122px);
}

.sierra-eye-diagram-right .sierra-eye-diagram-config-clock-item .sierra-eye-diagram-config-item-span {
  width: 122px;
  font-style: italic;
  color: rgb(0, 0, 0, 0.85);
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-input {
  display: inline-block;
  width: calc(100% - 140px);
  height: 24px;
  line-height: 24px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-sub-input {
  display: inline-block;
  width: calc((100% - 152px) / 2);
  height: 24px;
  line-height: 24px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-line {
  display: inline-block;
  width: 6px;
  margin: 0px 3px;
  height: 5px;
  background-color: #a6a6a6;
  margin-bottom: 4px;
  transform: scaleY(0.2);
}

.sierra-eye-diagram-config-sub-content-header {
  width: calc(100% - 13px);
  height: 12px;
  background-color: #b9b9b9;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  margin-left: 12px;
  margin-top: 6px;
}

.sierra-eye-diagram-config-sub-content-header .sierra-eye-diagram-config-sub-icon {
  font-size: 10px;
  position: absolute;
  left: 50%;
  top: 1px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-input .ant-input-group-addon,
.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-sub-input .ant-input-group-addon {
  font-size: 12px;
  padding: 0px 8px;
  /*   width: 68px; */
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-input-padding .ant-input-group-addon {
  padding: 0px 13px;
}

.sierra-eye-diagram-config-item .ant-input-group-addon .sierra-eye-diagram-down-select {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  margin: -2px -11px;
}

.sierra-eye-diagram-down-select .ant-select-arrow .ant-select-suffix {
  font-size: 10px;
}

.sierra-eye-diagram-down-select .ant-select-arrow {
  margin-top: -5px;
  right: 6px;
}

.sierra-eye-diagram-down-select .ant-select-selector {
  height: 22px;
  line-height: 22px;
  margin-right: 18px;
  margin-left: 8px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-down-select {
  margin: 0px;
  height: 24px;
  line-height: 24px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-select .ant-select-selection--multiple .ant-select-selector li {
  font-size: 12px;
  height: 18px;
  line-height: 16px;
}

.sierra-eye-diagram-btn-progress {
  background: #82c2fd;
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  right: 100%;
  transition: 0.3s;
}

.sierra-eye-diagram-btn-span {
  position: relative;
  line-height: 30px;
}

.sierra-eye-diagram-error-msg {
  display: block;
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.sierra-download-eye-diagram-log-panel {
  top: 46px;
}

.sierra-download-eye-diagram-log-panel .aurora-msg-dialog-item {
  color: rgba(0, 0, 0, 0.85);
}

.sierra-download-eye-diagram-log-panel .aurora-msg-dialog-main {
  padding: 8px;
}

.sierra-eye-diagram-log-icon {
  margin-left: 10px;
  color: #1896ff;
}

.sierra-eye-diagram-error-msg-content {
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.sierra-eye-diagram-error-msg-content>span {
  display: block;
}

.sierra-eye-diagram-config-main .sierra-eye-diagram-config-advanced {
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: -2px;
  margin-left: 15px;
  width: calc(100% - 16px);
  min-width: calc(100% - 16px);
}

.sierra-eye-diagram-config-main .sierra-eye-diagram-config-divider {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 10px;
  min-width: calc(100% - 10px);
  width: calc(100% - 10px);
}

.sierra-eye-diagram-main .setting-menu {
  margin: 8px;
  padding: 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 32px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.sierra-eye-diagram-main .setting-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.sierra-result-main .sierra-result-bar {
  width: 100%;
  display: flex;
}

.sierra-result-main .sierra-result-bar .ant-menu-horizontal {
  width: 100%;
  overflow: hidden;
}

.sierra_eye-diagram-config-sub-item {
  margin-left: 33px;
  font-style: italic;
}

.sierra-eye-diagram-main .sierra_eye-diagram-config-sub-item .sierra_eye-diagram-config-sub-span {
  width: 154px;
}

.sierra-eye-diagram-config-item-select-dropdown {
  right: 0px;
}

.sierra-touchstone-result-main {
  height: 100%;
}

.sierra-result-content {
  overflow-y: auto;
  overflow-x: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 45px;
}

.sierra-result-waveform .waveform-check-lable {
  width: 84px;
}

.sierra-result-waveform.waveform .waveform-current {
  width: 70px;
}

.sierra-result-waveform .sierra-waveform-comp-name {
  display: block;
  float: left;
  color: #3b70a7;
}

.sierra-result-experiment-display {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  /*  border-bottom: 1px solid #e8e8e8; */
  min-width: 350px;
  float: right;
}

.sierra-result-experiment-select {
  width: 330px;
  margin-right: 20px;
}

.sweep-delay-table-td,
.slerra-delay-table .aurora-table-body .ant-table-thead tr:nth-child(2) th {
  text-align: center;
}

.sierra-waveform-result-list {
  overflow: auto;
  height: calc(100% - 220px);
  margin: 5px 10px;
}

.sierra-waveform-result-list .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text {
  padding-left: 2px;
  padding-right: 10px;
}

.sierra-waveform-result-list .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text .sparameter-result-menu-title {
  padding-right: 30px;
}

.sierra-waveform-result-list .waveform-post-process-box .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text {
  padding-left: 8px;
}

/* .sierra-waveform-result-list .ant-divider-inner-text {
  padding-left: 2px !important;
  padding-right: 30px !important;
} */

.sierra-waveform-result-list .sparameter-channel-result-content .ant-divider {
  padding-left: 0px;
}

.sierra-waveform-result-list .sparameter-channel-result-content .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0px;
}

.sierra-waveform-result-list .sparameter-channel-result-content .sparameter-sweep-result-group .sparameter-result-sweep-group {
  padding-left: 25px;
}

.sierra-waveform-result-list .sparameter-channel-result-content .sparameter-result-checkbox-group>.ant-checkbox {
  top: 0px;
}

.sweep-waveform-pcb-legend {
  margin-left: 21px;
  height: 24px;
}

.sweep-waveform-pcb-legend .sweep-waveform-pcb-legend-color {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  margin-top: -2px;
  vertical-align: middle;
  display: inline-block;
}

.sierra-result-content .sparameter-channel-result-content {
  min-height: calc(100% - 50px);
}

.sparameter-sweep-result-group .checkbox-group-table-box {
  min-width: 160px;
}

.sparameter-result-sweep-group .checkout-group-table-data-content {
  display: flex;
}

.sparameter-sweep-result-group span.checkout-group-table-data-content-span {
  display: inline-block;
  width: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sierra-waveform-result-list .sparameter-result-checkbox-group-left .sparameter-result-checkbox-group-name .checkbox-group-name {
  width: calc(100% - 90px);
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
}

.sierra-waveform-result-list .sparameter-result-checkbox-group-name>label>span {
  display: flex;
  justify-content: left;
}

.sierra-waveform-result-list .sparameter-channel-port-name .sparameter-channel-port-name-checkbox {
  position: absolute;
  left: 4px;
}

.sierra-waveform-result-list .sparameter-channel-display-inline-flex .sparameter-channel-port-name-checkbox {
  left: 0;
  padding-left: 25px;
  z-index: 1000;
  background: #f9f9f9;
}

.sierra-waveform-result-list .sparameter-channel-port-name .sparameter-channel-display-row {
  left: 20px;
  max-width: 220px;
}

.sierra-touchstone-result-main .sparameter-channel-display-inline-flex .sparameter-channel-port-name-checkbox {
  z-index: 1000;
  position: absolute;
  left: 35px;
}

.result-checkbox-group-name .checkbox-group-table-box {
  display: inline-block;
  padding-left: 5px;
  margin-left: 279px;
}

.sparameter-sweep-result-group .checkout-group-table-data-content {
  height: 20px;
  line-height: 20px;
}

.sierra-result-sweep-content-box {
  padding: 20px;
}

.sierra-result-sweep-content-box .sierra-result-content-box {
  padding: 0px;
}

.sierra-result-sweep-content-box .sierra-result-sweep-content-title {
  margin-bottom: 8px;
}

.sierra-trace-capacitance-content {
  min-width: 788px;
  height: 100%;
}

.sierra-result-content .result-parameter-setting-box .result-sp-unit-select .ant-select-selection {
  color: rgba(0, 0, 0, 0.85);
}

.sierra-result-content .result-right {
  min-height: 640px;
  overflow-y: auto;
}

.result-top-menu .result-top-menu-content .sierra-top-menu-right-item {
  float: right;
}

.result-top-menu .result-top-menu-content .sierra-top-menu-right-item .menu-item-title {
  position: relative;
}

.result-top-menu .result-top-menu-content .sierra-top-menu-right-item .sierra-result-top-menu-setting-icon {
  margin: -3px 8px 0px 0px;
  font-size: 16px;
  vertical-align: middle;
}

.result-top-menu .sierra-result-post-processing-progress {
  width: 0px;
  height: 30px;
  background-color: #82c2fd;
  position: absolute;
  overflow: visible;
  top: 0;
  z-index: 1;
  opacity: 0.3;
  left: 0px;
}

.result-top-menu .sierra-result-post-processing-progress-span {
  height: 30px;
  display: block;
  float: right;
  line-height: 30px;
  margin-left: 6px;
}

.sierra-eye-diagram-select-dropdown {
  max-height: 500px;
}

.sierra-eye-diagram-select-dropdown .ant-select-tree li {
  margin: 2px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-tree-select .ant-select-selection__rendered {
  line-height: 24px;
}

.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-tree-select .ant-select-selection--multiple .ant-select-selection__clear,
.sierra-eye-diagram-config-item .sierra-eye-diagram-config-item-tree-select .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.pre-layout-relative {
  position: relative;
}

.pre-layout-center {
  text-align: center；
}

.pre-layout-section-img {
  width: 15px;
  height: 15px;
  margin-top: 7px;
  margin-left: 5px;
  position: absolute;
}

.pre-layout-section-title {
  min-width: 131px;
  display: inline;
}

.pre-layout-section-title>span {
  vertical-align: middle;
}

.pre-layout-section-title .pre-layout-add-section {
  margin-left: 6px;
  color: #3da4fa;
  cursor: pointer;
  font-size: 15px;
  vertical-align: middle;
}

.pre-layout-section-title .pre-layout-delete-section {
  float: right;
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}

.pre-layout-section-title:hover .pre-layout-delete-section {
  color: #3da4fa;
}

.pre-layout-fold {
  cursor: pointer;
  display: inline-flex;
  margin-left: -20px;
}

.pre-layout-fold-icon {
  margin-top: 12px;
  margin-right: 5px;
  font-size: 10px;
}

.pre-layout-fold:hover .pre-layout-fold-icon {
  color: #1890ff;
}

.pre-layout-fold-icon-transform svg {
  transform: rotate(270deg);
}

.pre-layout-library-select-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.pre-layout-library-select-panel .library-select-content {
  padding: 8px 14px;
}

.pre-layout-library-select-panel .library-edit-value-label {
  display: inline-block;
  width: 110px;
  vertical-align: middle;
}

.pre-layout-library-select-panel .library-item {
  display: flex;
  line-height: 28px;
  height: 28px;
}

.pre-layout-library-select-panel .library-select,
.pre-layout-library-select-panel .library-input {
  width: calc(100% - 110px);
}

.pre-layout-library-select-panel .library-config-content {
  display: flex;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 0;
  padding: 6px;
  border-radius: 4px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.pre-layout-library-select-panel .library-config-content>li {
  width: 50%;
  padding: 0px 10px;
}

.pre-layout-library-select-panel .library-select-content .library-item:nth-child(-n+2) {
  margin-bottom: 8px;
}

.pre-layout-library-row-background {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.pre-layout-library-error {
  margin: 10px 0px;
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
}

.pre-layout-center {
  text-align: center;
}

.pre-layout-input-model-checkbox {
  margin-left: 110px;
}

.pre-layout-input-model-copy {
  padding-top: 0px !important;
}

.pre-layout-input-model-copy span {
  font-size: 12px;
}

.pre-layout-input-model-copy .ant-checkbox-inner {
  border-radius: 4px;
}

.pre-layout-library-des {
  margin-top: 10px;
}

.pre-layout-library-des .ant-descriptions-item-label {
  font-size: 13px;
  white-space: nowrap;
}

.pre-layout-library-des .ant-descriptions-item-content {
  font-size: 13px;
  white-space: nowrap;
  padding: 8px;
}

.pre-layout-library-des .ant-descriptions-item-content>span {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}

.pre-layout-library-des .ant-descriptions-item-content .des-span {
  font-size: 13px;
  padding-left: 2px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input {
  min-width: 70px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input {
  padding: 4px 5px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input-group-addon {
  padding: 0px 5px;
  font-size: 12px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input-group-addon .ant-select {
  margin: -6px;
  width: 50px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input-group-addon .ant-select .ant-select-selector {
  margin-right: 10px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input-group-addon .ant-select .ant-select-arrow {
  font-size: 10px;
  margin-top: -4px;
}

.pre-layout-library-des .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.pre-layout-library-des .aurora-input .ant-select-disabled {
  color: rgba(0, 0, 0, 0.65);
}
.result-parameter-setting-box {
  width: 100%;
  height: auto;
  padding-bottom: 2px;
  background-color: #fafafa;
}

.result-parameter-setting-box .ant-row-flex-middle {
  margin: 2px 0;
}

.result-parameter-setting-box .ant-divider-inner-text {
  padding-left: 0px !important;
  padding-right: 10px !important;
}

.result-parameter-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.result-parameter-setting-box .result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  padding-right: 1px;
}

.result-parameter-setting-box .setting-row {
  line-height: 28px;
}

.result-parameter-setting-box .setting-input-lable {
  font-weight: bold;
  color: rgb(0, 0, 0, 0.65)
}

.result-parameter-setting-box .padding-left-42 {
  padding-left: 42px;
}

.result-parameter-setting-box .result-sp-setting-select {
  height: 28px;
  line-height: 28px;
  width: 110px;
}

.result-parameter-setting-box .result-sp-setting-select div {
  height: 28px;
}

.result-parameter-setting-box .result-sp-setting-select .ant-select-selection-selected-value {
  line-height: 26px;
  height: 26px;
}

.result-parameter-setting-box .result-sp-setting-select .ant-select-selector {
  line-height: 26px;
  height: 26px;
}

.result-parameter-setting-box .result-sp-setting-select .ant-select-arrow {
  right: 6px;
}

.result-parameter-setting-box .sp-axis-title {
  cursor: pointer;
}

.result-parameter-setting-box .sp-axis-content>div {
  margin: 3px 0;
}

.result-parameter-setting-box .sp-axis-content>div>div {
  display: flex;
}

.result-parameter-setting-box .sp-axis-content>div>div .ant-select {
  width: 75px;
  height: 28px;
}

.result-parameter-setting-box .sp-axis-content>div>div .sp-setting-select {
  width: 120px;
}

.result-parameter-setting-box .result-setting-expand-icon {
  font-size: 10px;
  padding-right: 10px;
  padding-left: 22px;
}

.result-parameter-setting-box .result-sp-setting-input {
  width: 180px;
  margin-left: 0px;
  height: 28px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  vertical-align: top;
}

.result-parameter-setting-box .result-sp-setting-input input {
  height: 28px;
}

.result-parameter-setting-box .result-sp-unit-select {
  vertical-align: top;
  width: 75px;
  height: 28px;
}

.result-parameter-setting-box .result-sp-unit-select .ant-select-selection {
  position: relative;
  padding: 0 10px;
  padding-left: 6px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 28px;
  border-left: none;
}

.result-parameter-setting-box .result-sp-unit-select .ant-select-arrow {
  right: 4px;
}

.result-parameter-setting-box .result-sp-unit-select .ant-select-selector {
  margin-right: 0;
  margin-left: 0;
  line-height: 26px;
}

.result-parameter-setting-box .result-sp-unit-select .ant-select-selector .ant-select-selection-selected-value {
  padding-right: 0px;
}

.result-parameter-setting-box .result-sp-y-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.result-right-menu {
  background-color: #fafafa;
  border-left-color: #e8e8e8;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.result-right-menu .result-file-list {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 318px;
  margin: 0 5px;
  position: relative;
}

.result-right-menu .result-file-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.result-right-menu .result-file-list .ant-divider-inner-text {
  padding-left: 2px !important;
  padding-right: 30px !important;
}

.result-right-menu .result-file-list .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.result-right-menu .result-setting {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 50%;
  bottom: 0;
  z-index: 1000;
}
.result-top-menu {
  height: 45px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background: #f3f2f1;
  z-index: 1000;
}

.result-top-menu .result-top-menu-content {
  padding-left: 10px;
  line-height: 44px;
  height: 45px;
  background: #f3f2f1;
  width: 100%;
}

.result-top-menu .result-top-menu-item {
  text-align: center;
  cursor: pointer;
  float: left;
  margin-right: 15px;
}

.result-top-menu .result-top-menu-item .menu-item-title,
.result-top-menu .result-top-menu-item .menu-item-title-disable {
  font-size: 14px;
  color: #005a9e;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  padding: 0 15px;
  background-color: #f3f2f1;
  transition: all 0.3s;
}

.result-top-menu .result-top-menu-item .menu-item-title:hover {
  background-color: #edebe9;
  transition: all 0.3s;
}

.result-top-menu .result-top-menu-item .menu-item-title-select,
.result-top-menu .result-top-menu-item .menu-item-title-select:hover {
  background-color: #c7e0f4;
}

.menu-item-title-disable {
  cursor: not-allowed;
}

.menu-item-title-icon {
  margin-right: 8px;
  font-size: 16px;
  line-height: 26px;
}

.result-top-menu .result-top-menu-item .menu-item-back-setup {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  line-height: 28px;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
}

.result-top-menu .result-top-menu-item .menu-item-back-setup:hover {
  color: #f87e3b;
  border: 1px solid #f87e3b;
  background-color: #f3f2f1;
}

.result-top-menu .result-top-menu-setup .aurora-top-bar-vertical-line {
  margin-left: 28px;
}

.result-top-menu .result-top-menu-setup {
  margin-right: 0px;
  display: flex;
  justify-content: space-between;
  line-height: 44px;
  height: 44px;
  align-items: center;
}

.result-top-menu .result-top-menu-list {
  overflow: hidden;
  height: 45px;
  line-height: 44px;
  float: left;
}

.result-top-menu .result-save-div .aurora-top-bar-vertical-line {
  vertical-align: text-top;
}

.result-top-menu-list .result-top-menu-item.result-download-content:hover {
  color: #1890ff;
}

.result-top-menu-list .result-top-menu-item.result-download-content .aurora-top-bar-vertical-line {
  vertical-align: text-top;
}

.result-export-button {
  height: 26px;
  padding: 0 8px;
}

.save-result-history-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.result-zoom-menu {
  width: 28px;
  height: 118px;
  position: absolute;
  top: 2px;
  right: 8px;
  visibility: visible;
  text-align: center;
  border-right: none;
}

.result-zoom-menu .ant-menu-item {
  padding: 0px;
  margin-right: 0px;
  border-radius: 50%;
  cursor: pointer;
  width: 28px;
  height: 28px;
  text-align: center;
  transition: all 0.3s;
  line-height: 28px;
}

.result-zoom-menu .ant-menu-item .layBtn {
  margin: 0px;
  color: #ffffff;
  font-size: 17px;
  background-color: #9191918c;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.result-zoom-menu .ant-menu-item .layBtn:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.result-zoom-menu .ant-menu-item-selected {
  background-color: none !important;
}

.rocky-top-bar-vertical-line {
  height: 16px;
  background-color: #bebebe;
  width: 1px;
  display: block;
}

.result-top-menu-item-line {
  padding: 12px 0px;
  margin: 2px 0px;
  height: 40px;
}

.result-top-menu-select.ant-select .ant-select-selection-item {
  flex: unset
}
.sierra-total-capacitance-freq-content {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  float: right;
  margin-right: 20px;
}

.sierra-total-capacitance-freq-content>span {
  width: 160px;
  font-weight: 500;
}

.sierra-total-capacitance-freq-content>.aurora-input {
  width: calc(100% - 160px);
}

.sierra-total-capacitance-main .aurora-error-msg-span {
  width: 50%;
}

.sierra-total-capacitance-main {
  /*  padding: 0px 20px; */
  height: 100%;
  position: relative;
}

.sierra-total-capacitance-table-main {
  overflow: auto;
  width: 100%;
  height: calc(100% - 30px);
  padding: 0px 20px;
}

.sierra-total-capacitance-main::after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.sierra-total-capacitance-main .sierra-result-content-box {
  padding: 0px;
  padding-top: 20px;
}

.sierra-total-capacitance-top-menu {
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 36px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.sierra-total-capacitance-main .sierra-total-capacitance-curve-right {
  background-color: #f9f9f9;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 10px;
  width: 100%;
}

.sierra-total-capacitance-main .sparameter-display-mode {
  width: calc(100% - 24px)
}

.sierra-total-capacitance-main .sparameter-display-mode label.ant-radio-wrapper {
  width: 100px;
  margin-right: 0px;
}

.sierra-total-capacitance-left {
  /*  width: 70%; */
  height: 100%;
}

.sierra-total-capacitance-left .total-capacitance-svg {
  width: 100%;
  height: 100%;
}

.sierra-total-capacitance-curve-design-item .total-capacitance-curve-design-title {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.sierra-total-capacitance-curve-design-item .total-capacitance-curve-design-title>span:not(.anticon) {
  font-weight: bold;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(0, 0, 0, 0.65);
}

.total-capacitance-curve-segment-checkbox {
  width: 100%;
}

.total-capacitance-curve-checkbox {
  padding-right: 5px;
}

.sierra-total-capacitance-curve-item {
  padding-left: 12px;
}

.sierra-total-capacitance-curve-design-item .sierra-total-capacitance-curve-item .color-select {
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
  border: 0px;
  width: 19px;
  margin-right: 2px;
  cursor: pointer;
  outline: none;
  height: 23px;
}

.total-capacitance-curve-title {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.total-capacitance-curve-title-span {
  font-weight: 500;
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 6px;
  width: -moz-fit-content;
  width: fit-content;
}

.sierra-total-capacitance-curve-item .total-capacitance-curve-signal-item {
  padding-left: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sierra-result-content-box .sierra-trace-capacitance-table .ant-table-small.ant-table-bordered .aurora-table-body>tr>td:nth-child(2) {
  border-right: 1px solid #e8e8e8;
}

.total-capacitance-unit-select {
  width: 80px;
}

.total-capacitance-tabs .ant-tabs-bar {
  margin-bottom: 0px;

}

.total-capacitance-tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}

.sierra-total-capacitance-main .result-right {
  top: 0px;
}

.sierra-total-capacitance-main .result-curve-current {
  margin-left: 8px;
  vertical-align: middle;
  font-weight: bold;
}

.sierra-total-capacitance-curve-right-setting {
  height: 284px;
}

.sierra-total-capacitance-curve-right-result {
  height: calc(100% - 284px);
  overflow: auto;
}

.sierra-title-expand-icon {
  padding-right: 6px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 10px;
  line-height: 18px;
}

.sierra-total-capacitance-table-content {
  padding-top: 36px;
  height: 100%;
  padding-bottom: 10px;
}

.sierra-total-capacitance-curve {
  height: calc(100% - 36px) !important;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 36px;
}

.sierra-total-capacitance-main .sierra-total-capacitance-curve .dividingLine,
.sierra-total-capacitance-main .sierra-total-capacitance-curve .dividingLine-hover {
  top: 36px;
  height: calc(100% - 36px);
}

.sierra-total-capacitance-curve-item .total-capacitance-curve-single-title {
  padding-left: 16px;
}

.sierra-total-capacitance-curve-item .total-capacitance-curve-single-title .ant-checkbox-wrapper {
  padding-right: 5px;
}

.sierra-total-capacitance-curve-design-item .sierra-total-capacitance-curve-item .total-capacitance-curve-single-title .color-select {
  margin-right: 0px;
}

.sierra-total-capacitance-curve-design-item .sierra-total-capacitance-curve-item .total-capacitance-curve-single-title .total-capacitance-curve-title-span {
  margin-left: 4px;
}

.sierra-total-capacitance-curve-right-setting .waveform-setting-title {
  margin: 0px;
  padding: 10px 0px;
}

.sierra-total-capacitance-curve-right-setting .result-waveform-setting .waveform-setting-row {
  margin: 0px;
  padding: 5px 0px;
}

.sierra-total-capacitance-curve>.result-left {
  height: calc(100% - 6px) !important;
}
.waveform {
  width: 100%;
  height: 100%;
}

.waveform-clear {
  zoom: 1;
}

.waveform .waveform-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.waveform .waveform-left {
  height: 100%;
  float: left;
  width: 100%;
}

.waveform .waveform-left-spin {
  height: 100%;
  max-height: 100%;
}

.waveform .waveform-svg {
  height: 100%;
}

.waveform .ant-spin-nested-loading,
.waveform .ant-spin-container {
  height: 100%;
  width: 100%;
}


.waveform .ant-spin-nested-loading>div>.ant-spin {
  height: 100%;
  width: 100%;
  max-height: 100%;
}

.waveform .waveform-setting {
  border-left: 1px solid #e8e8e8;
  background-color: #f9f9f9;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;
}

.waveform .waveform-setting .dividingLine {
  height: 100%;
}

.waveform .waveform-setting>.ant-checkbox-group>.ant-checkbox-wrapper {
  margin-left: 0px;
  display: inline-block;
  width: 100%;
  height: 22px;
  line-height: 22px;
}

/* Setting */
.waveform .waveform-setting-box {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.waveform .waveform-setting-box-setup {
  padding: 10px;
}

.waveform .display-mode-title {
  font-weight: bold;
  margin-right: 8px;
  color: rgb(0, 0, 0, 0.65);
}

.waveform .waveform-setting-title {
  margin: 0px;
}


/* waveform selection */
.waveform .waveform-checkboxgroup {
  width: 100%;
  overflow-y: auto;
  background-color: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding-left: 10px;
  padding-top: 10px;
  position: absolute;
  bottom: 210px;
  top: 0px;
  flex-direction: column;
  flex-wrap: nowrap;
}

.waveform .waveform-checkboxgroup .waveform-checkbox {
  margin-left: 0px;
  /* margin-bottom: 6px;
  margin-top: 4px; */
}

.waveform .waveform-checkboxgroup .waveform-checkbox .ant-checkbox {
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  top: -1px
}

.waveform .waveform-signal-name-down-icon {
  color: rgba(0, 0, 0, .44);
  font-size: 10px;
  padding-right: 9px;
  padding-left: 3px;
}

.waveform .waveform-color-select {
  padding: 0px;
  background-color: #fafafa;
  border: 0px;
  width: 18px;
  height: 22px;
  cursor: pointer;
  display: block;
  float: left;
  outline: none;
}

.waveform .waveform-checkbox span {
  padding: 0px;
}

.waveform .waveform-checkbox-lable {
  width: 100%;
  height: 22px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
}

.waveform .waveform-checkbox-lable .ant-color-picker-trigger {
  flex: none;
}

.waveform-sweep-checkbox-label {
  height: 22px;
  line-height: 22px;
  display: inline-flex;
}

.waveform .waveform-tooltip {
  width: 100%;
  height: 22px;
  line-height: 22px;
  display: block;
}

.waveform .waveform-checkbox {
  background: #fafafa;
}

.waveform .waveform-checkbox:hover {
  background: #dbdbdb;
}

.waveform .waveform-checkbox:hover .waveform-color-select {
  background-color: #dbdbdb;
}

.waveform .waveform-check-lable {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
  margin-left: 4px;
  margin-right: 2px;
}

.waveform .waveform-comp-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
  max-width: 150px;
  min-width: 70px;
}


.waveform .waveform-current {
  float: left;
  margin-left: 4px;
}

.waveform .waveform-signal-name {
  display: block;
  padding-left: 5px;
  cursor: pointer;
  background: #fafafa;
  transition: all 0.3s;
}

.waveform-net-name {
  display: block;
  padding-left: 15px;
  cursor: pointer;
  background: #fafafa;
  transition: all 0.3s;
}

.waveform-sweep-signal-name {
  display: block;
  padding-left: 25px;
  cursor: pointer;
  background: #fafafa;
  transition: all 0.3s;
}

.waveform-select-label {
  margin-bottom: 3px;
  margin-top: 3px;
}

.waveform .waveform-net-name .waveform-sweep-signal-name .waveform-signal-name:hover {
  background: #dbdbdb;
  transition: all 0.3s;
}

/* 2D curve plotting */
.plot .domain {
  display: none;
}

.plot .curve {
  fill: none;
}

.plot .tick line {
  stroke: #ccc;
}

.selector-backgroud {
  fill: #fff;
  stroke: #666;
}

.selector-slide {
  cursor: move;
  fill: #337ab7;
}

.selector-traveller {
  cursor: col-resize;
  fill: #444;
}

.range-text-style {
  font-size: 10px;
  color: #555;
}

.curve-mark {
  fill: #ff0000;
}

.mark-text {
  font-size: 10px;
  text-anchor: middle;
}

/* 2D curve plotting end  */
.pintopin-simulation-config-block {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: stretch;
  padding-bottom: 10px;
}

.pintopin-simulation-config-item {
  line-height: 32px;
  display: flex;
  justify-content: center;
}

.pintopin-simulation-config-item+.pintopin-simulation-config-item {
  margin-top: 10px;
}

.pintopin-simulation-config-name {
  font-weight: 500;
  margin-right: 10px;
  width: 80px;
  font-size: 13px;
  line-height: 28px;
  color: rgb(0, 0, 0, 0.65);
}

.pintopin-simulation-config-advanced-name {
  font-weight: 500;
  margin-right: 10px;
  width: 240px;
  font-size: 13px;
  line-height: 28px;
  margin-left: 13px;
}

.pintopin-simulation-config-value {
  width: calc(100% - 100px);
}

.pintopin-simulation-config-item .ant-input,
.pintopin-simulation-config-item .ant-select-selector,
.pintopin-simulation-config-item .ant-select-selection-selected-value,
.pintopin-simulation-config-item .ant-select-selection {
  height: 28px !important;
  line-height: 28px !important;
  font-size: 13px !important;
}

.pintopin-simulation-config-item .ant-select-selector {
  margin: 0 6px;
}

.pintopin-simulation-config-item .pintopin-simulation-config-value.ant-select .ant-select-selector {
  margin: 0px;
}

.pintopin-simulation-config-item .ant-select-arrow {
  right: 5px;
  top: calc(50% + 1px);
}

.pintopin-simulation-config-item .ant-input-group-addon .ant-select {
  width: 70px;
  height: 28px;
}

.pintopin-simulation-config-item .ant-input-group-addon .ant-select-selection-item {
  height: 28px;
}

.pintopin-simulation-config-item .ant-select-dropdown-menu-item {
  font-size: 13px;
  line-height: 22px;
}

.pintopin-simulation-config-error-msg {
  display: block;
  padding: 6px 10px;
  margin-top: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.pintopin-simulation-options-input-select {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 0px;
  border: none;
  box-shadow: 0px 0px 0px;
  width: 100%;
  height: 0px !important;
}

.pintopin-simulation-options-input-select .ant-select-selector {
  display: none;
}

.pintopin-simulation-options-input-select .ant-select-selection:focus,
.pintopin-simulation-options-input-select .ant-select-selection:active,
.pintopin-simulation-options-input-select .ant-select-selection {
  border: none;
  height: 0px;
  box-shadow: 0px 0px 0px;
}

.pintopin-simulation-options-select {
  z-index: 1000000;

}

.pintopin-simulation-options-select .ant-select-dropdown-menu-item {
  padding: 4px 11px;
  font-size: 13px;
  line-height: 20px;
}

.pintopin-simulation-option-mdlfm-content {
  width: calc(100% - 250px);
  display: inline-block;
  position: relative;
  line-height: 28px;
}

.pintopin-simulation-mdflm-input {
  z-index: 1000;
}

.pintopin-simulation-config-advanced {
  width: calc(100% - 90px);
  margin-left: 90px;
  margin-top: 10px;
}

.pintopin-simulation-config-advanced-title {
  font-size: 13px;
  cursor: pointer;
}

.pintopin-simulation-config-advanced-title i {
  font-size: 10px;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -4px;
}

.pintopin-simulation-config-advanced-name {
  font-weight: 500;
  margin-right: 10px;
  width: 240px;
  font-size: 13px;
  line-height: 28px;
  margin-left: 13px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-advanced-name {
  margin-left: 0px;
  width: 114px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item {
  justify-content: left;
  height: 28px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .cores-help-icon {
  margin-right: 5px;
  line-height: 32px;
  height: 28px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-value {
  width: 70px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-hpice-content {
  line-height: 28px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-hpice-content .ant-input-number-input-wrap {
  height: 28px;
  line-height: 28px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-hpice-content .ant-input-number-input-wrap .ant-input-number-input {
  height: 26px;
  line-height: 26px;
}

.pintopin-simulation-config-setting {
  color: #1890ff;
  cursor: pointer;
  width: 26px;
  margin-top: 2px;
  padding-left: 10px;
}

.pintopin-simulation-config-item .pintopin-simulation-config-time-step-value {
  width: calc(100% - 126px);
}

.pull-down-select-dropdown {
  z-index: 999999;
}
#sierra-top-bar,
#sierra-pre-layout-top-bar {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.sierra-top-bar-item {
  padding: 6px 10px;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sierra-top-bar-item:hover {
  background-color: #edebe9;
}

.sierra-top-bar-item .iconfont,
.sierra-top-bar-item .anticon-play-circle,
.sierra-top-bar-item .anticon-setting,
.sierra-top-bar-item .anticon-loading {
  margin-right: 8px;
  font-size: 16px;
}

.sierra-top-bar-item .icon-Debug {
  font-size: 19px !important;
  line-height: 20px;
}

.sierra-top-bar-item+.sierra-top-bar-item {
  margin-left: 10px;
}

#sierra-top-bar .sierra-top-bar-item+.sierra-top-bar-result-item {
  margin-left: 0px;
  cursor: unset;
}

#sierra-top-bar .sierra-top-bar-result-item:hover {
  background-color: #f3f2f1;
}

.sierra-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.sierra-top-bar-option-panel .panel-modal-header {
  padding-left: 14px !important;
}

.sierra-top-bar-option-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-top-bar-option-panel .pintopin-simulation-config-block {
  min-width: 460px;
}

#sierra-top-bar.hide .sierra-top-bar-item-title {
  display: none;
}

#sierra-top-bar .sierra-top-bar-item-title {
  height: 22px;
  line-height: 20px;
}

#sierra-top-bar.hide .sierra-top-bar-item {
  padding: 6px 8px;
}

#sierra-top-bar.hide .sierra-top-bar-item .iconfont,
#sierra-top-bar.hide .sierra-top-bar-item .anticon-play-circle,
#sierra-top-bar.hide .sierra-top-bar-item .anticon-setting {
  margin-right: 0px;
}

.sierra-option-crosstalk-item {
  display: inline-block;
  position: relative;
  padding: 0px 16px 16px 16px;
}

.sierra-coupling-option-name {
  font-weight: 500;
  line-height: 28px;
  width: 80px;
  display: inline-block;
  font-size: 13px;
}

.sierra-coupling-option-sub-item {
  margin: 0px 0px 0px 30px;
  position: relative;
  display: inline-block;
}

.sierra-coupling-option-sub-item .sierra-coupling-option-name {
  font-size: 13px;
}

.sierra-coupling-options-crosstalk {
  display: inline-block;
}

.sierra-coupling-options-crosstalk .ant-switch-small {
  min-width: 26px;
  height: 14px;
  line-height: 12px;
}

.sierra-coupling-options-crosstalk .ant-switch-small::after {
  width: 10px;
  height: 10px;
}

.sierra-top-bar-item .icon-icons8-circuit {
  font-size: 19px !important;
  line-height: 20px;
}

.sierra-simulation-option-content {
  height: -moz-fit-content;
  height: fit-content;
}

.sierra-top-bar-result-title {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
  cursor: pointer;
}

.sierra-top-bar-result-title .sierra-top-bar-result-icon {
  margin-left: 8px;
  font-size: 15px;
}

#sierra-top-bar.hide .sierra-top-bar-result-icon {
  margin-left: 0px;
}

#sierra-top-bar .sierra-top-bar-result:hover {
  background-color: #f3f2f1;
}

.sierra-top-bar-result-title:hover {
  color: #f87e3b;
  border: 1px solid #f87e3b;
}

.sierra-top-bar-right-item {
  position: absolute;
  right: 10px;
}

#sierra-top-bar .aurora-top-bar-vertical-line {
  margin-right: 20px;
}

.sierra-setup-measure-panel {
  top: 80px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-top-bar-report-item {
  position: relative;
  padding: 6px 0px;
}

.sierra-top-bar-report-item .sierra-top-bar-report {
  padding: 0px 14px;
}

.sierra-top-bar-report {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 22px;
}

.sierra-top-bar-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.sierra-top-bar-report-icon {
  height: 22px;
  line-height: 20px;
}
/* === Extraction ports panel === */
.extraction-ports-panel {
  margin-top: 100px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.extraction-ports-panel .panel-modal-header {
  padding-left: 14px !important;
}

.extraction-ports-panel .ant-divider-horizontal {
  margin: 10px 0px;
}

.extraction-ports-title {
  font-size: 16px;
  font-weight: 500;
}

.extraction-ports-content {
  padding: 10px 16px;
  min-width: 800px;
  min-height: 265px;
  height: max-content;
}

.extraction-ports-input-content {
  width: 65%;
  min-height: 28px;
  display: inline-block;
}

.extraction-ports-z0-content {
  width: 35%;
  display: inline-block;
  height: 28px;
}

.extraction-ports-z0-content label {
  width: 70px;
  display: inline-block;
  text-align: end;
  padding-right: 10px;
}

.extraction-ports-reference-main .extraction-ports-z0-content .aurora-input {
  width: calc(100% - 70px);
}

.extraction-ports-z0-content .extraction-ports-checkbox {
  width: 50px;
}

.extraction-ports-input-content label {
  width: 170px;
  vertical-align: middle;
  display: inline-block;
}

.extraction-ports-input-content .aurora-select,
.extraction-ports-input-content .port-select {
  width: calc(100% - 170px);
}

.extraction-ports-input-content .port-select,
.extraction-ports-input-content .port-select .ant-select-selector {
  min-height: 28px;
  height: auto;
}

.extraction-ports-input-content .port-select .ant-select-selector .ant-select-selection-wrap .ant-select-selection-overflow{
  display: block;
}

.extraction-port-button {
  overflow: hidden;
  margin: 10px 0px;
}

.ports-component-pin-add-content {
  display: inline-block;
}

.ports-component-pin-add-content>span {
  vertical-align: middle;
}

.ports-component-pin-add-content>.anticon {
  vertical-align: middle;
  margin-left: 10px;
  color: #188fff;
  font-size: 15px;
  cursor: pointer;
}

.extraction-port-edit {
  color: #188fff;
  cursor: pointer;
  text-align: center;
}

/* auto generation */
.extraction-ports-reference-main {
  padding-bottom: 10px;
}

.extraction-ports-auto-setup {
  border-radius: 5px;
  background: #f3f3f3;
  border: 1px solid #e8e8e8;
  position: relative;
  padding: 10px 0px;
}

.extraction-ports-content .extraction-ports-reference-main .extraction-ports-auto-title {
  font-size: 15px;
  font-weight: 600;
}

.extraction-ports-content .extraction-ports-reference-main .extraction-ports-auto-title .anticon {
  font-size: 13px;
  vertical-align: middle;
  margin-right: 10px;
}

.extraction-ports-reference-main>.extraction-ports-auto-setup {
  padding: 10px;
  line-height: 28px;
}

.extraction-ports-reference-main .extraction-ports-reference-sub-div {
  margin-left: 48px;
}

.extraction-ports-reference-main .extraction-ports-reference-sub-div .extraction-ports-radio,
.extraction-ports-reference-main .extraction-ports-reference-sub-div .extraction-ports-sub-radio {
  width: 242px;
}

.extraction-ports-reference-main .extraction-ports-radio {
  margin-right: 10px;
  display: block;
  line-height: 28px;
}

.extraction-ports-reference-main .extraction-ports-sub-radio {
  display: inline-block;
  vertical-align: middle;
  line-height: 28px;
}

.extraction-ports-reference-main .aurora-input {
  width: calc(100% - 170px);
}

.extraction-ports-generate-button {
  margin-top: 8px;
  width: 100%;
  height: 24px;
}

.extraction-ports-generate-button .ant-btn {
  float: right;
}

.extraction-ports-generate-button-active .ant-btn {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}

.extraction-ports-generate-button .ant-btn:hover,
.extraction-ports-generate-button .ant-btn:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #d9d9d9;
}

.extraction-ports-generate-button-active .ant-btn:hover,
.extraction-ports-generate-button-active .ant-btn:focus {
  border-color: #40a9ff;
}

.extraction-ports-z0-sub {
  vertical-align: sub;
  font-size: 10px;
}

.extraction-ports-ref-type-radio-main {
  margin-left: 26px;
}

.extraction-ports-disabled {
  color: #ccc;
}

.extraction-ports-panel .aurora-model-name-error-msg,
.extraction-ports-panel .aurora-model-name-warning-msg {
  padding: 2px 8px;
  margin-top: 6px;
}

.extraction-ports-panel .aurora-model-name-error-msg {
  border: 1px solid #ffa39e;
}

.extraction-ports-panel .aurora-model-name-warning-msg {
  margin-top: 6px;
}

.extraction-ports-table-reference {
  max-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.extraction-ports-table .ant-table-thead>tr>th,
.extraction-ports-table .ant-table-body .aurora-table-body tr>td {
  text-align: center;
}

.aurora-extraction-ports-input-main {
  margin: 6px 0px;
}

.aurora-extraction-ports-input-main>span {
  display: inline-block;
  width: 180px;
  vertical-align: middle;
}

.aurora-extraction-ports-input-main>.aurora-input {
  display: inline-block;
  width: calc(100% - 180px);
  vertical-align: middle;
}

.extraction-ports-components-checkbox {
  margin-right: 16px;
}

.extraction-ports-unit-select {
  width: 60px;
}

.extraction-ports-auto-setup .signal-port-edit-item>label {
  width: 180px;
}

.signal-port-edit-item>.aurora-select,
.signal-port-edit-item>.aurora-input {
  width: calc(100% - 180px);
  font-size: 13px;
}

.signal-port-edit-item>.aurora-select,
.signal-port-edit-item>ant-select-selector {
  min-height: 28px;
}

.extraction-ports-reference-main .extraction-ports-auto-checkbox-main .aurora-input {
  width: calc(100% - 180px);
}

.extraction-ports-auto-checkbox-main .extraction-ports-sub-checkbox {
  width: 180px;
}

.extraction-ports-auto-checkbox-1 {
  margin-bottom: 6px !important;
}

.signal-port-edit-item-checkbox {
  display: block;
}

.extraction-ports-auto-setup .signal-port-edit-item>.signal-port-edit-item-apply-label {
  width: 240px;
}

.extraction-ports-auto-setup .signal-port-edit-item>.signal-port-edit-item-switch {
  line-height: 24px;
}

.extraction-ports-auto-setup .extraction-ports-copy-content {
  display: inline-block;
  margin-left: 10px;
}

.extraction-ports-copy-content .extraction-port-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 240px;
  color: rgba(0, 0, 0, 0.65)
}

.aurora-select-nets-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
  line-height: 20px;
  vertical-align: middle;
}

.extraction-ports-reference-main .aurora-model-name-error-msg,
.extraction-ports-reference-main .aurora-model-name-warning-msg {
  margin-top: 6px;
}


.port-setup-edit-reference-icon {
  margin-left: 8px;
  color: #188fff;
  cursor: pointer;
}

/* edit reference pin panel */
.port-setup-reference-edit-main {
  padding: 10px 16px;
  height: max-content;
  min-width: 800px;
  display: flex;
  min-height: 100%;
}

.port-setup-reference-edit-table {
  width: 50%;
  padding-right: 10px;
}

.port-setup-reference-edit-canvas {
  width: 50%;
  position: relative;
}

.port-setup-reference-edit-main .aurora-extraction-tabs.ant-tabs-card .ant-tabs-top-content {
  border: none;
}

.ports-setup-edit-reference-dropdown {
  z-index: 999999;
}

.port-setup-reference-edit-canvas .port-setup-canvas-pin-name {
  left: 0px;
  width: 100%;
}

.port-setup-select-pin-main {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.port-setup-select-pin-ul {
  max-height: 200px;
  width: 100%;
  overflow: auto;
  padding-left: 10px;
  margin-bottom: 2px !important;
}

.port-setup-select-pin-net-li {
  line-height: 28px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
}

.port-setup-select-pin-li {
  line-height: 28px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
  margin-left: 14px;
}

.port-setup-select-pin-li:hover {
  background-color: rgb(223, 243, 255);
}

.port-setup-select-pin-selected {
  background-color: rgb(176, 226, 255);
  font-weight: 600;
}

.port-setup-select-pin-selected:hover {
  background-color: rgb(176, 226, 255);
}

.port-setup-select-pin-icon {
  margin-right: 6px;
  font-size: 13px;
  color: rgb(0, 0, 0, 0.65);
}

.port-setup-editing-port-row {
  background-color: #adddfd;
}

.port-setup-edit-port-index {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.aurora-table-body>.port-setup-editing-port-row:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: #adddfd;
}

.port-setup-edit-port-pin {
  cursor: pointer;
}

.port-setup-edit-eye-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #666;
  cursor: pointer;
  vertical-align: middle;
}

.port-setup-reference-edit-canvas .ant-spin-nested-loading,
.port-setup-reference-edit-canvas .ant-spin-container {
  height: 100%;
  width: 100%;
}

.port-setup-help-icon {
  margin: -3px 0px 0px 10px;
  font-size: 18px;
  vertical-align: middle;
  color: #188fff;
  cursor: pointer;
}

.port-setup-help-icon:hover {
  color: #40a9ff;
}

.aurora-extraction-ports-ball-material-input {
  cursor: pointer;
}

.aurora-extraction-ports-ball-material-main {
  position: relative;
}

.aurora-extraction-ports-question-circle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -8px;
  color: #f13140;
  font-size: 14px;
}

.user-default-setting-ports-content .aurora-model-name-error-msg {
  margin-top: 6px;
}

.extraction-ports-input-content .aurora-select.ant-select-multiple .ant-select-selection-item {
  height: 20px;
  margin-top: -2px;
  line-height: 18px;
}

.extraction-ports-auto-select{
  height: 28px;
}
.material-definition-panel {
  margin-top: 100px;
  min-width: 370px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.material-definition-panel .panel-body {
  min-width: 370px;
}

.material-definition-panel .panel-modal-header {
  padding-left: 14px !important;
}

.material-definition-title {
  font-size: 16px;
  font-weight: 500;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-definition-panel .material-definition-content {
  padding: 10px 16px;
  min-width: 370px;
}

.material-definition-panel .material-definition-item {
  margin: 6px 0px;
  line-height: 28px;
  height: 28px;
}

.material-definition-content .material-edit-value-label {
  display: inline-block;
  min-width: 168px;
  margin-right: 10px;
  vertical-align: middle;
  /*  text-align: end; */
}

.material-definition-panel .material-select,
.material-definition-panel .material-input {
  width: calc(100% - 178px);
  font-size: 13px;
  vertical-align: middle;
}

.material-definition-content .material-dielectric-item .material-edit-value-label {
  min-width: 168px;
}

.material-definition-content .material-metal-item .material-edit-value-label {
  width: 98px;
}

.material-definition-content .material-metal-item .material-select,
.material-definition-content .material-metal-item .material-input {
  width: calc(100% - 178px);
  font-size: 13px;
}

.material-definition-panel .material-definition-content .material-definition-item-half {
  width: calc(100% - 208px);
  display: inline-block;
}

.material-definition-panel .material-definition-content .material-definition-item-checkbox {
  width: 30px;
  vertical-align: middle;
}

.material-definition-panel .material-definition-content .aurora-model-name-error-msg {
  margin: 8px 0px;
}

.material-definition-panel .material-definition-content .material-freq-dep-option {
  margin-left: 16px;
}

.material-definition-panel .material-definition-content .material-edit-value-label-long {
  width: calc(100% - 30px);
  margin-right: 0px;
}

.material-definition-panel .material-definition-content .material-divider {
  margin-top: 12px;
  margin-bottom: 2px;
}

.material-definition-panel .material-definition-content .material-definition-item-apply {
  margin: 0px 0px;
}

.material-input .ant-input[disabled],
.material-input[disabled],
.material-metal-item .ant-select-disabled {
  color: rgba(0, 0, 0, 0.6);
}

.material-freq-dep-option:first-of-type .material-input .ant-input-wrapper {
  margin-top: 1px;
}
.signal-port-edit-item,
.signal-port-edit-sub-item,
.signal-port-edit-reference-sub-item {
  display: flex;
  line-height: 28px;
  margin: 6px 0px;
}

.signal-port-edit-ball-item {
  display: block !important;
}

.signal-port-edit-item>label {
  width: 196px;
}

.signal-port-edit-sub-item {
  margin-left: 110px;
}

.signal-port-edit-sub-item>label {
  width: 61px;
}

.signal-port-edit-sub-item>.aurora-input {
  width: calc(100% - 61px);
}

.signal-port-edit-reference-sub-item {
  display: block;
}

.signal-port-edit-reference-sub-item .extraction-ports-radio {
  display: block;
  line-height: 28px;
}

.signal-port-comp-pin-content {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 28px;
  line-height: 28px;
  padding: 0px 10px;
  width: calc(100% - 110px);
  font-size: 13px;
}

.signal-port-edit-reference-sub-item>.aurora-select {
  width: 100%;
  margin: 6px 0px;
  min-height: 28px;
  height: auto;
}

.signal-port-edit-reference-sub-item>ant-select-selector {
  height: auto;
  min-height: 28px;
  max-height: 120px;
  overflow-y: auto;
}

.signal-port-edit-reference-sub-item .signal-port-edit-sub-item {
  margin-left: 0px;
}

.signal-port-edit-reference-sub-item .signal-port-edit-sub-item>label {
  width: 140px;
}

.signal-port-edit-reference-sub-item .signal-port-edit-sub-item>.aurora-input {
  width: calc(100% - 140px);
}

.extraction-ports-auto-setup-port-radio {
  display: flex;
}

.extraction-circuit-ports-radio {
  margin-left: 80px;
}

.extraction-ports-auto-checkbox-main {
  margin: 0px;
}

/* reference component */
.signal-port-edit-reference-pins-content {
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 100px;
  min-height: 28px;
  line-height: 26px;
  padding: 0px 8px;
  width: calc(100% - 110px);
  overflow-y: auto;
  cursor: pointer;
  font-size: 13px;
}

.signal-port-edit-reference-pins-content:hover {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.signal-ports-edit-point .aurora-input {
  width: calc(50% - 98px);
}

.signal-ports-edit-point .aurora-select {
  width: 68px;
  margin-left: 4px;
}

.signal-ports-edit-point .signal-ports-edit-point-input {
  margin-right: 4px;
}

/* === Extraction ports panel === */
.extraction-ports-panel {
  margin-top: 100px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.extraction-ports-panel .panel-modal-header {
  padding-left: 14px !important;
}

.extraction-ports-title {
  font-size: 16px;
  font-weight: 500;
}

.extraction-ports-content {
  padding: 10px 16px;
}
.port-setup {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.port-tag-setup {
  height: 94%;
}

.port-setup-content {
  width: 50%;
  height: 100%;
}

.port-setup-canvas {
  height: 100%;
  padding-bottom: 0px;
}

.port-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.port-setup-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.port-setup-title-color {
  color: #0b3694
}

.port-setup-desc-gorup {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.port-setup-desc {
  width: 50%;
}

.port-setup-desc .desc-body {
  margin-top: 10px;
}

.port-setup-desc .desc-body .ant-descriptions-view .ant-descriptions-row {
  display: block;
  max-height: 300px;
  overflow-y: auto;
}

.port-setup-desc .desc-body .ant-descriptions-view .ant-descriptions-row th {
  display: block;
}

.port-setup-desc .desc-body .ant-descriptions-view .ant-descriptions-row .ant-descriptions-item-content {
  min-height: 40px;
}

.port-setup-desc .desc-body .ant-descriptions-view .ant-descriptions-row .ant-descriptions-item-content>span:empty {
  display: inline-block;
}

.port-setup-tag {
  margin: 4px;
  cursor: pointer;
  text-align: center;
  min-width: 32px;
}

.port-setup-canvas-pin-name {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: #000000;
  width: -moz-fit-content;
  width: fit-content;
  right: 0px;
  bottom: 0px;
}

.port-setup-layout-canvas-coord {
  position: absolute;
  padding: 5px 10px;
  bottom: 0px;
  color: #fff;
  float: right;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.port-setup-canvas-pin-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.port-setup-canvas-pin-switch {
  margin-left: 10px;
  margin-top: -5px;
}

.port-setup-canvas-pin-name .unshow-switch {
  background-color: #999999;
}

.port-canvas-context-menu {
  position: absolute;
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.ports-setup-table-icon-group {
  position: absolute;
  right: 5px;
  top: 8px;
}

.ports-setup-table-icon-group .anticon {
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  transition: .3s;
  box-shadow: 0 0 0 0 #fff;
  background: #fff;
  z-index: 1;
  border: 1px solid#dedede;
  cursor: pointer;
  margin: 0 5px;
  justify-content: center;
}

.ports-setup-table-icon-group .anticon:hover {
  color: #0d87f7;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
  background: #fafafa;
}

.ploc-icon {
  color: rgb(89, 89, 89)
}

.ports-group-setup-table .ant-table-small .aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 30px;
  position: relative;
  max-height: 200px;
  overflow-y: auto;
}

.ports-table-delete-icon {
  position: absolute;
  right: 0px;
  margin-top: 3px;
  color: #ccc;
  cursor: pointer;
}

.ports-table-delete-icon:hover {
  color: #3da4fa
}

.port-setup-table-index {
  cursor: pointer;
  position: relative;
}

.port-setup-table-eye-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
  vertical-align: middle;
}

#port-setup-canvas polygon:hover,
#port-setup-canvas cicle:hover,
#port-setup-canvas path:hover,
#port-setup-canvas polyline:hover {
  stroke: unset
}

#port-setup-canvas .lay_geom:hover {
  stroke: yellow;
}

#port-setup-canvas .layer_pin:hover {
  stroke: orange;
}

#port-setup-edit-ref-pin-menu {
  position: absolute;
  padding-right: 10px;
  padding-left: 8px;
  height: 32px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
}

.port-setup-close-selected-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -7px;
}

#port-setup-canvas-main {
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.pin-positive-port-box {
  fill: red;
  opacity: 1;
  display: unset;
}

.pin-negative-port-box {
  fill: blue;
  opacity: 1;
  display: unset;
}

.pin-opacity-port-1 {
  opacity: 1;
}

.pin-opacity-port-0-6 {
  opacity: 0.6;
}

.pin-opacity-port-0-2 {
  opacity: 0.2;
}

.port-setup-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.port-setup-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.port-setup-panel-content {
  padding-right: 15px;
  margin-bottom: 6px;
  overflow: hidden;
}

.port-setup-panel-main {
  margin: 15px;
}

.port-setup-panel-content .port-setup-panel-content-input {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.port-setup-panel-content .port-setup-panel-content-input.aurora-select {
  margin-top: 3px;
}

.port-setup-panel-content-body {
  padding-left: 15px;
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.port-setup-panel-button {
  margin-left: 6px;
  float: right;
}

.port-setup-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.port-setup-ploc-msg {
  display: block;
  padding: 8px;
  border-radius: 8px;
  font-weight: 500;
}

.port-setup-ploc-msg .anticon {
  margin-right: 10px;
}

.port-setup-ploc-error-msg {
  background: #ffdce0;
  color: #cb2431;
}

.port-setup-ploc-success-msg {
  background-color: #f6ffed;
  color: #1b8409;
}

.port-setup-ploc-loading-msg {
  background-color: #bae7ff;
  color: #0b3b94;
}

.port-setup-panel-content-slider {
  margin: 8px 0px -4px !important;
}

.port-setup-panel-advanced-body {
  padding-left: 0px;
}

.port-setup-panel-advanced-body .advanced-arrow-icon {
  font-size: 10px;
  padding-right: 5px;
}

.port-setup-panel-sub-span {
  padding-left: 30px;
}

.port-setup-panel-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.port-setup-panel-content-input .ant-input-group-addon {
  font-size: 13px;
}

.port-setup-panel-content-input-addonAfter .ant-input-group-addon {
  width: 65px;
}

.ploc-content-item {
  display: inline-block;
  width: 100%;
  margin: 3px 0px;
}

.ploc-content-item>span:not(.anticon) {
  width: 140px;
  display: inline-block;
}

.ploc-content-item .aurora-select {
  width: calc(100% - 200px);
}

.ploc-edit-content-item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  margin: 6px 0px;
}

.ploc-content-icon {
  cursor: pointer;
  color: #1890ff;
}

.ploc-content-body {
  margin: 10px 16px;
}

.port-setup-canvas-pin-show-text {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  width: 50%;
}

.port-setup-canvas-pin-show-span {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}

.port-setup-canvas-pin-here-span {
  text-decoration: underline;
  cursor: pointer;
}

.port-setup-canvas-pin-here-span:hover {
  color: #bae7ff;
}
.comp-extraction-option-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.comp-extraction-option-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.comp-extraction-option-sub-span {
  padding-left: 20px;
}

.comp-extraction-option-content .comp-extraction-option-content-input {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.comp-extraction-option-content .comp-extraction-option-content-input-with-switch {
  width: calc(55% - 50px);
  margin-left: 20px;
}

.comp-extraction-option-content-input {
  font-size: 13px;
}

.comp-extraction-option-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.comp-extraction-option-content-input .ant-input-group-addon,
.comp-extraction-option-content-input .ant-input-group-addon .ant-select-selection-selected-value {
  font-size: 13px;
}

.comp-extraction-option-content-core-input.comp-extraction-option-content-input {
  width: calc(27.5% - 27px);
}

.comp-extraction-option-input-or-span {
  display: inline-block;
  margin: 0 20px;
}

.comp-extraction-option-input-addonAfter .ant-input-group-addon {
  width: 65px;
}

.comp-extraction-option-content-body .span-msg-icon {
  color: #1890ff;
  margin-left: 5px;
}

.comp-extraction-option-next-sub-span {
  padding-left: 40px;
}

.comp-extraction-option-distance-switch {
  margin-right: 40px;
}

.comp-extraction-option-distance-input {
  width: 25% !important;
  margin-right: 15px;
}

.comp-extraction-option-multi-zone-button {
  height: 28px;
  width: 80px;
  float: right;
}

.comp-extraction-option-multi-zone-button:last-child {
  margin-right: 10px;
}

.comp-extraction-option-content .comp-extraction-option-content-input-both {
  width: calc(30% - 26px);
}

.comp-extraction-option-content .comp-extraction-option-content-input-both:not(:last-child) {
  margin-right: 15px;
}
.sierra-pre-layout-panel {
  top: 130px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-pre-layout-panel .pre-layout-setting .sub-extraction-input .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.sierra-pre-layout-panel .pre-layout-pcie-select .pre-layout-span {
  width: 240px;
}

.sierra-pre-layout-panel .pre-layout-pcie-select .pre-layout-select,
.sierra-pre-layout-panel .pre-layout-pcie-select .pre-layout-input {
  width: calc(100% - 180px);
  font-size: 14px;
}

.sierra-pre-layout-panel .pre-layout-pcie-select .ant-select-single .ant-select-selector {
  color: rgba(0, 0, 0, 0.85);
}

.sierra-pre-layout-body {
  padding: 20px;
  min-width: 1350px;
  width: -moz-fit-content;
  width: fit-content;
}

.sierra-pre-layout-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.ant-table-small tr.ant-table-expanded-row td>.sierra-pre-layout-pin-table.ant-table-wrapper,
.sierra-pre-layout-pin-table .ant-table-small tr.ant-table-expanded-row td>.ant-table-wrapper {
  margin: 0px;
}

.sierra-pre-layout-pin-table .ant-table-thead>tr>th,
.sierra-pre-layout-pin-table .aurora-table-body>tr>td {
  border-right: 0px;
}

.sierra-pre-layout-pin-table .ant-table {
  margin-block: 0px !important;
  margin-inline: 34px 0px !important;
}

.pre-layout-component-table .sierra-pre-layout-pin-table .aurora-table-body>tr>td:last-child {
  border-right: 0px !important;
}

.sierra-pre-layout-pin-table .ant-table-small .ant-table-content {
  border-right: 0px;
}

.sierra-pre-layout-pin-table .ant-table-small {
  border: 0px;
}

.sierra-pre-layout-component-table .ant-table .ant-table-thead>tr>th:first-child,
.sierra-pre-layout-component-table .ant-table .aurora-table-body>tr>td:first-child {
  border-right: 0px;
}

.sierra-pre-layout-component-table tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

.sierra-pre-layout-component-table tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.sierra-pre-layout-canvas {
  min-height: 80px;
  min-width: 1000px;
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  position: relative;
  margin-top: 10px;
}

.sierra-pre-layout-canvas .pre-layout-canvas {
  text-align: center;
  vertical-align: middle;
  min-width: 100%
}

.sierra-pre-layout-canvas .port-select {
  position: absolute;
  font-size: 12px;
}

.sierra-pre-layout-canvas .port-select .spice-pin-input {
  font-size: 12px;
  width: 50px;
  height: 20px;
  max-width: 50px;
  line-height: 20px;
}

.sierra-pre-layout-canvas .port-select .port-empty-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #afafaf;
}

.sierra-pre-layout-canvas .port-select .spice-pin-input .spice-pin-node-delete-icon {
  top: 5px;
  right: -10px;
}

/* === pcb model === */
.sierra-pre-layout-body .pcb-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 4px 10px;
  position: relative;
}

.sierra-pre-layout-body .pcb-model-selection {
  width: calc(100% - 100px);
  margin-right: 32px;
  height: 24px;
}

.sierra-pre-layout-body .pcb-model-select-dropdown {
  z-index: 10000000;
}

.sierra-pre-layout-body .pcb-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 22px;
}

.sierra-pre-layout-body .pcb-model-title {
  font-size: 14px;
  font-weight: 500;
}

.sierra-pre-layout-body .pcb-model-select .ant-select-selector,
.sierra-pre-layout-body .pcb-model-select .ant-select-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 7px;
}

.sierra-pre-layout-body .pcb-model-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 13px;
  padding: 2px 12px;
}

.sierra-pre-layout-pcb-model-select {
  float: left;
  width: calc(50% - 50px);
  padding-right: 10px;
  font-size: 13px;
}

.sierra-pre-layout-pcb-model-select-right {
  margin-right: 22px;
  height: 24px;
}

.sierra-pre-layout-pcb-model-select .ant-select-arrow:hover {
  color: #1890ff;
  text-transform: none;
}

.sierra-pre-layout-pcb-model-select .ant-select-arrow {
  text-transform: none !important;
  transform: none !important;
  transition: none !important;
  margin-right: 10px;
}

.sierra-pre-layout-model-delete-icon {
  position: absolute;
  right: 12px;
}

.sierra-pre-layout-cursor {
  cursor: pointer;
}

.sierra-pre-layout-value-box {
  display: inline-flex;
  align-items: baseline;
}

.sierra-pre-layout-empty-unit {
  margin-left: 5px;
}
.sierra-debug-download-panel {
  margin-top: 80px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-debug-download-title {
  font-size: 16px;
  font-weight: 500;
}

.sierra-debug-download-content {
  padding: 10px;
  border: 1px solid #f3f2f1;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  min-width: 580px;
  min-height: min-content;
}

.sierra-debug-download-content .sierra-debug-download-menu {
  border-radius: inherit;
  height: 32px;
  background: #f3f2f1;
  font-size: 14px;
  color: #005a9e;
  display: flex;
  justify-content: left;
  width: 100%;
}

.sierra-debug-download-content .sierra-debug-download-menu .sierra-debug-download-menu-btn {
  line-height: 23px;
  padding: 4px 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  border-radius: inherit;
  text-align: left;
  float: left;
  color: #005a9e;
  display: flex;
}

.sierra-debug-download-menu-btn:hover {
  background-color: #edebe9;
}

.sierra-debug-download-menu-btn>div {
  display: inline-block;
  white-space: nowrap;
  word-break: keep-all;
  width: -moz-fit-content;
  width: fit-content;
}

.sierra-debug-download-menu-btn:first-child>span {
  display: inline-block;
  width: max-content;
}

.sierra-debug-download-menu-select {
  font-size: 13px;
  height: 24px;
  color: #005a9e;
  width: 100%;
  max-width: 280px;
  min-width: 100px;
}

.sierra-debug-download-checkbox-group {
  flex-direction: column;
}

.sierra-debug-download-menu-select {
  height: 24px;
}

.sierra-debug-download-menu-select .ant-select-selector {
  line-height: 22px;
}

.sierra-debug-download-menu-select .ant-select-arrow {
  right: 6px;
  color: #005a9e;
}

.sierra-debug-download-menu-select .ant-input {
  height: 24px
}

.design-failed-Option {
  color: #ff0000;
}

.sierra-debug-download-menu-select-drop {
  z-index: 10000000;
}

.sierra-debug-download-list {
  margin-top: 6px;
  overflow: auto;
}

.sierra-debug-download-list .sierra-debug-download-checkbox,
.sierra-debug-download-list .sierra-debug-download-group-checkbox {
  margin: 0;
  padding: 5px 10px;
}

.sierra-debug-download-list .sierra-debug-download-group-checkbox .sierra-debug-download-checkbox {
  padding: 0px 6px;
}

.sierra-debug-download-content .sierra-debug-download-list .sierra-debug-download-sub-checkbox {
  padding-left: 28px;
}

.sierra-debug-download-list .sierra-debug-download-group-checkbox .anticon {
  font-size: 12px;
  vertical-align: baseline;
}

.sierra-debug-download-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 8px 10px;
}

.sierra-debug-download-footer-btn {
  font-size: 13px;
  line-height: 24px;
  width: 80px;
  height: 26px;
  padding: 0;
  margin: 2px 0 2px 5%;
}

.sierra-debug-download-footer-message {
  line-height: 28px;
  height: 30px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  flex-grow: 1;
}

.sierra-debug-download-footer-message.success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.sierra-debug-download-footer-message.error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.sierra-debug-download-footer-progress {
  padding: 2px 10px 2px 0;
  flex-grow: 1;
}

.sierra-debug-download-footer-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.sierra-debug-download-footer-progress .ant-progress-text {
  vertical-align: unset;
}

.sierra-debug-download-footer-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}
.rocky-library-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

.vector-value-box {
  margin: 16px;
}

.vector-input,
.vector-value-input {
  margin: 16px 0;
  display: block;
}

.vector-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.vector-input-tooltip {
  z-index: 3000;
}

.library-edit-upload-progress-bar {
  width: 100%;
}

.library-edit-upload-progress-bar .ant-progress-outer {
  padding-right: 40px;
}

.library-edit-upload-progress-bar .ant-progress-text {
  position: absolute;
  right: 0px;
  width: 30px;
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}

.vector-value-input .ant-input-group-addon {
  width: 50px;
}

.rocky-vector-radio-upload {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.rocky-vector-radio-edit {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.rocky-vector-radio-group {
  display: block;
  margin: 0 16px;
  height: 20px;
  margin-top: 16px;
}

.eye-mask-content-box {
  padding: 16px;
}

.eyemask-help-icon {
  color: #1890ff;
  font-size: 16px;
  float: right;
  margin-right: 26px;
  margin-top: 2px;
}

.pattern-edit-value-span {
  display: inline-block;
  width: 80px;
  margin: 8px 0;
  vertical-align: middle;
}

.pattern-edit-input {
  margin: 8px 0;
  display: inline-block;
  width: calc(100% - 80px);
  vertical-align: middle;
}

.pattern-library-content {
  margin: 16px;
}

.rocky-pattern-library-table .ant-table-small>.ant-table-content>.aurora-table-body {
  padding-right: 14px;
}

.rocky-pattern-library-table tbody>tr>td:last-child {
  position: relative;
}

.rocky-pattern-library-table .pattern-nets-delete {
  position: absolute;
  right: -15px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pattern-library-select-dropdown {
  z-index: 10000000;
}

.rocky-pattern-library-table .pattern-add-icon {
  color: #0d87f7;
  font-size: 13px;
  margin-left: 10px;
  cursor: pointer;
}

.rocky-pattern-library-table .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .pattern-nets-delete {
  color: #1890ff;
}

.rocky-spec-help-icon {
  color: #1890ff;
  font-size: 16px;
  float: right;
  margin-right: 26px;
  margin-top: 2px;
}
.report-template-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.report-template-content .report-template-upload-icon {
  float: unset;
  margin-left: -20px;
  line-height: 28px;
  position: relative;
  top: 2px;
}

.report-template-thumbnail {
  width: calc(100% - 92px);
  display: inline-block;
  vertical-align: middle;
  margin: 6px 0;
  margin-left: 10px;
}

.report-thumbnail-img {
  border: 2px solid #ffffff;
  opacity: 0.8;
}

.report-thumbnail-img-select {
  border: 1px solid #1890ff;
  box-shadow: 0px 0px 8px 2px #73bbfd;
}

.report-check-img {
  width: 100%;
  height: 100%;
}

.report-template-thumbnail .ant-radio-group {
  display: flex;
}

.report-template-thumbnail .ant-radio {
  display: none;
}

.report-template-thumbnail .change-thumbnail-icon {
  margin-top: 7%;
  font-size: 12px;
  display: inline-block;
  line-height: 50px;
  height: 50px;
  cursor: not-allowed;
}

.report-template-thumbnail .change-thumbnail-icon .anticon {
  margin-left: -1.5px;
}

.report-template-thumbnail .change-thumbnail-icon:hover {
  background-color: #bbd6f0;
}

.report-thumbnail-desc {
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.report-thumbnail-desc div {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 3px;
}

.report-thumbnail-main .anticon {
  color: #1890ff;
  margin: 0 3px;
}

.report-thumbnail-main .del-icon {
  position: absolute;
  right: 22px;
  top: 2px;
  background-color: #fff;
  display: none;
}

.report-thumbnail-main:hover .del-icon {
  display: block;
}

.report-check-img {
  width: 100%;
  height: 100%;
}

.report-template-thumbnail .change-thumbnail-icon.change-thumbnail-icon-pointer {
  cursor: pointer;
}

.change-thumbnail-icon-color {
  color: #1890ff;
}
/* === Report panel === */
.sierra-report-panel {
  margin-top: 80px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-report-panel .panel-modal-header {
  padding-left: 14px !important;
}

.sierra-report-panel .report-panel-title {
  font-size: 16px;
  font-weight: 500;
}

.sierra-report-panel .report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  height: 100%;
  min-width: 500px;
}

.sierra-report-panel .report-content .report-message-main {
  margin-top: 10px;
  position: relative;
  min-height: 27px;
}

.sierra-report-panel .report-content .report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.sierra-report-panel .report-content .report-select {
  width: calc(100% - 84px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
}

.sierra-report-select-dropdown {
  z-index: 10000000;
  max-height: 500px;
}

.sierra-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}

.sierra-report-panel .report-content .report-download-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: calc(100% - 110px);
  padding: 0px 8px;
}

.sierra-report-panel .report-content .report-download-button {
  position: absolute;
  right: 0px;
  bottom: 2px;
}

.sierra-report-panel .report-content .report-download-btn {
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.sierra-report-panel .report-content .report-progress {
  right: 40px;
  padding: 2px 0px;
  position: absolute;
  width: auto;
  left: 0px;
}

.sierra-report-panel .report-content .report-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.sierra-report-panel .report-content .report-progress .ant-progress-text {
  vertical-align: unset;
}

.sierra-report-panel .report-content .report-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.sierra-report-panel .report-content .download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.sierra-report-panel .report-content .download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.report-download-log-icon {
  margin-right: 10px;
  color: #1896ff;
}

.sierra-report-panel .sierra-download-report-log-panel {
  top: 46px;
}

.sierra-download-report-log-panel .aurora-msg-dialog-item {
  color: rgba(0, 0, 0, 0.85);
}

.sierra-report-panel .sierra-download-report-log-panel .aurora-msg-dialog-main {
  padding: 8px;
}

.sierra-report-advances-collapse {
  border: none;
  background-color: #fff;
  margin-bottom: 10px;
}

.sierra-report-advances-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px 6px 0px;
  width: -moz-fit-content;
  width: fit-content;
  color: #696969;
}

.sierra-report-advances-collapse>.ant-collapse-item {
  border-bottom: none;
}

.sierra-report-advances-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 70px;
}

.sierra-report-advances-collapse .report-advances-header {
  font-size: 13px;
  font-weight: 500;
  height: 22px;
  color: #000000a6;
}

.sierra-report-advances-collapse .ant-collapse-content {
  border-top: none;
}

.sierra-report-advances-collapse .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px 16px;
}

.sierra-report-advances-enable-title {
  font-size: 13px;
  font-weight: 500;
  width: 116px;
  line-height: 28px;
}

.sierra-report-advances-enable-options {
  display: flex;
  justify-content: space-between;
}

.sierra-report-advances-enable-select {
  width: calc(100% - 116px);
  min-height: 28px;
  line-height: 28px;
}

.sierra-report-advances-enable-select .ant-select-selection__rendered {
  line-height: 26px;
}

.sierra-report-advances-enable-select .ant-select-selection--multiple {
  min-height: 28px;
}

.sierra-report-advances-enable-select .ant-select-selection--multiple>ul>li,
.sierra-report-advances-enable-select .ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
  height: 22px;
  margin-top: 3px;
  line-height: 20px;
}

.sierra-report-advances-enable-select-dropdown {
  z-index: 1000000;
}
.sierra-sweeping-add-icon,
.sierra-sweeping-delete-icon {
  vertical-align: middle;
}

.sierra-sweeping-table .ant-table-thead>tr>th,
.sierra-sweeping-table .aurora-table-body>tr>td {
  text-align: center;
}

.sierra-sweeping-select-dropdown-menu {
  text-align: left;
}

/* columns */

.sweeping-columns-checkbox {
  padding-left: 6px !important;
}

.sierra-sweeping-pin-panel {
  margin-top: 126px;
  margin-left: -440px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.sierra-sweeping-pin-panel .sierra-sweeping-pin-table {
  margin: 10px;
}

.sierra-sweeping-table-title {
  position: relative;
}

.sierra-sweeping-table-title .sierra-sweeping-title-value {
  cursor: pointer;
}

.sierra-sweeping-table-title .sierra-sweeping-title-value i {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
  color: #1890ff;
  font-size: 12px;
}

.sierra-sweep-close-icon {
  visibility: hidden;
  position: absolute;
  margin-top: 5px !important;
  font-size: 14px !important;
  margin-left: 5px !important;
}

.sierra-sweeping-table .ant-table-thead>tr>th:hover .sierra-sweeping-table-title .sierra-sweep-close-icon {
  visibility: visible;
}


.sierra-sweeping-display-comp {
  position: absolute;
  width: 140px;
  background-color: #fff;
  margin-left: 20px;
  border-radius: 0 0 2px 2px;
  z-index: 999;
  overflow: auto;
  box-shadow: 1px 4px 6px rgb(0 0 0 / 31%);
}

.sierra-sweeping-display-comp ul {
  margin: 0;
  padding: 0;
}

.sierra-sweeping-display-comp ul li {
  width: 100%;
  text-align: left;
  padding: 4px 23px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sierra-sweeping-column-name {
  line-height: 24px;
  vertical-align: middle;
  position: relative;
}

.sierra-sweeping-column-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 16px;
}

.sierra-sweeping-column-name .anticon {
  color: #1890ff;
  position: absolute;
  visibility: hidden;
  top: 0px;
  right: 5px;
}

.sierra-sweeping-table .aurora-table-body tr:hover .sierra-sweeping-column-name .anticon {
  visibility: visible;
}

.sweeping-model-not-exsit td,
.sweeping-table-td-not-exsit {
  background-color: #fafafa;
  cursor: not-allowed;
}

.sweeping-table-td-not-used {
  background-color: #fafafa;
}

.sweeping-table-td-not-used .editable-cell-value-wrap {
  border: 1px solid #fafafa;
}

.sweeping-table-td-font-color {
  color: #1890ff;
}

.sweeping-model-exsit {
  background-color: #ffffff !important
}

.sierra-sweeping-table .ant-table-thead>tr>th {
  position: relative;
}

.sierra-sweep-table-add-icon {
  position: absolute;
  line-height: unset;
  margin-top: -4px;
  right: 10px;
  font-size: 16px !important;
  z-index: 20;
}

.sierra-sweeping-table .ant-table .aurora-table-body .ant-table-tbody tr .sweeping-table-td-padding {
  padding-left: 14px;
}

.sierra-sweeping-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr>td:last-child {
  text-align: center;
}

.sweep-pre-layout-schematic-setup-main {
  width: -moz-fit-content;
  width: fit-content;
  /*   padding: 10px 14px; */
  display: flex;
  justify-content: space-between;
}

.sweep-pre-layout-schematic-setup-left {
  min-width: 320px;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 100%;
  padding: 10px;
  padding-left: 14px;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: unset;
}

.sweep-pre-layout-schematic-setup-left .sweep-pre-layout-length-left-title {
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-weight: 600;
  color: #0b3b94;
  display: flex;
}

.sweep-pre-layout-length-left-add-icon {
  color: #1890ff;
  cursor: pointer;
  margin-left: 10px;
  font-size: 15px;
}

.sweep-pre-layout-schematic-setup-left .sweep-pre-layout-schematic-length-list {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
  padding-left: 8px;
}

.sweep-pre-layout-schematic-select-section {
  min-height: 33px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  color: #1890ff;
}

.sweep-pre-layout-schematic-select-section>span {
  width: 100px;
}

.sweep-pre-layout-schematic-select-section>.aurora-select,
.sweep-pre-layout-schematic-select-section>.aurora-input {
  width: calc(100% - 100px);
}

.sweep-pre-layout-schematic-select-section>.aurora-select {
  height: -moz-fit-content;
  height: fit-content;
  min-height: 28px;
}

/* .sweep-pre-layout-schematic-setup-canvas .sierra-pre-layout-canvas .pre-layout-canvas {
  transform: scale(0.9);
} */

.sweep-pre-layout-schematic-setup-left .sweep-pre-layout-length-left-title .sweep-create-btn {
  width: 75px;
}

.sweep-pre-layout-schematic-setup-left .ant-divider {
  margin: 10px 0px;
}

.sweep-pre-layout-length-left-button {
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}

.sierra-sweeping-pre-layout-schematic-panel {
  margin-top: 80px;
}

.sierra-sweep-content-setting .aurora-table.sierra-sweeping-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  overflow: auto;
}

.sweep-pre-layout-schematic-setup-canvas {
  padding: 10px;
  padding-right: 14px;
  padding-left: 0px;
}

.sweep-measurement-tooltip {
  /* white-space: pre; */
  max-width: 600px;
}

.sierra-sweep-content-setting .aurora-table.sierra-sweeping-table .aurora-table-body .ant-table-row>td.sierra-measurement-title-td>.editable-cell-value-wrap,
.sierra-sweep-content-setting .aurora-table.sierra-sweeping-table .ant-table-content>.aurora-table-body td.sierra-measurement-title-td .editable-cell-value-wrap {
  overflow: hidden;
  padding: 0px 0px;
}

.sierra-sweep-content-setting .sierra-sweep-measurement-title {
  width: 100%;
  height: 100%;
  max-height: 88px;
  overflow: auto;
  padding: 0px 5px;
  min-height: 30px;
}

.sierra-sweep-measurement-tooltip-title {
  font-weight: 600;
}

.sierra-sweep-measurement-tooltip-item {
  text-indent: 6px;
}
.schematic-canvas-component-content {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #fafafa;
  border-radius: 4px;
  width: 140px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: #d8d8d8 0px 0px 10px 4px;
}

.schematic-canvas-component-icon {
  width: 30px;
  height: 30px;
  /*  background-color: #e7e7e7; */
  position: absolute;
  right: -2px;
  top: 25px;
  /* calc(50% - 15px); */
  text-align: center;
  line-height: 30px;
  z-index: 2;
}

.schematic-canvas-component-content .schematic-canvas-component-right-icon {
  top: calc(50% - 15px);
  right: 0px;
}

.schematic-canvas-component-icon .anticon {
  font-size: 16px;
  color: #1890ff;
  cursor: pointer;
}

.sierra-pre-layout-comp-info-panel {
  position: absolute;
  left: 0px;
  top: 0px;
}

/* .sierra-pre-layout-comp-info-panel.sierra-pre-layout-comp-info-ele-panel {
  left: 0px;
}
 */
.sierra-pre-layout-comp-info-content,
.sierra-pre-layout-template-content {
  padding: 6px 10px;
  overflow: auto;
}

.sierra-pre-layout-comp-info-content .sierra-pre-layout-comp-info-item {
  display: flex;
  width: 100%;
  line-height: 34px;
  height: 34px;
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item {
  display: flex;
  margin: 8px;
  line-height: 28px;
  height: 28px;
}

.sierra-pre-layout-comp-info-content .sierra-pre-layout-comp-info-item>span {
  width: 80px;
}

.sierra-pre-layout-comp-info-content .sierra-pre-layout-comp-info-item>.aurora-input,
.sierra-pre-layout-comp-info-content .sierra-pre-layout-comp-info-item>.aurora-select {
  width: calc(100% - 80px);
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item .pre-layout-template-label {
  width: 90px;
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item>.aurora-input,
.sierra-pre-layout-template-content .sierra-pre-layout-template-item>.aurora-select {
  width: calc(100% - 90px);
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item .aurora-input .ant-input-group-addon .ant-select {
  width: 60px;
}

.sierra-pre-layout-template-content .sierra-pre-layout-template-item .aurora-input .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 16px;
}

.schematic-canvas-container-content {
  width: 100%;
  padding: 8px 10px;
  height: 72px;
  padding-bottom: 2px;
  position: absolute;
  left: 0px;
  top: 0px;
  padding-right: 26px;
}

.schematic-canvas-container-item {
  display: flex;
  justify-content: space-between;
  height: 28px;
  line-height: 28px;
  margin-bottom: 6px;
  width: 100%;
}

.schematic-canvas-container-content>.schematic-canvas-container-item>.aurora-input {
  width: calc(100% - 20px);
}

.schematic-canvas-container-item>span {
  width: 20px;
}

.sierra-pre-layout-template-panel.aurora-library-panel .library-png img {
  width: 80%;
}

.sierra-template-tooltip {
  width: 350px;
  max-width: 350px;
}

.sierra-pre-layout-signals-main {
  min-height: 28px;
  line-height: 28px;
  /*   background-color: #fafafa;
  border-radius: 4px; */
  width: 140px;
  height: -moz-fit-content;
  height: fit-content;
  /*   box-shadow: #d8d8d8 0px 0px 10px 4px; */
  position: absolute;
  left: 0px;
  top: 257px;
  /* min-height: 60px; */
  padding: 0px 10px;
  padding-bottom: 10px;
}

.sierra-pre-layout-signals-title {
  height: 28px;
  line-height: 28px;
  font-weight: 600;
  position: relative;
}

.sierra-report-pre-layout-schematic-setup-canvas .sierra-pre-layout-signals-title {
  width: 120px;
}

.sierra-report-pre-layout-schematic-setup-canvas .schematic-canvas-container-item {
  width: 104px;
}

.sierra-pre-layout-signals-del-icon {
  color: #1890ff;
  position: absolute;
  right: 0px;
  top: 7px;
  cursor: pointer;
}

.sierra-report-pre-layout-schematic-setup-canvas .sierra-pre-layout-signals-content {
  width: 120px;
}

.sierra-pre-layout-signals-content-names {
  min-height: 28px;
  line-height: 28px;
  padding-left: 4px;
  position: relative;
}

.sierra-pre-layout-signals-content-names>.sierra-pre-layout-signals-section-name {
  display: block;
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1890ff;
}

.sierra-pre-layout-signals-content-names>.sierra-pre-layout-signals-del-icon {
  color: rgb(0, 0, 0, 0.55);
  cursor: pointer;
  right: 0px;
  font-size: 12px;
}

.sierra-pre-layout-signals-content-names:hover .sierra-pre-layout-signals-del-icon {
  color: #1890ff;
}

.sierra-pre-layout-template-content .model-name-error-msg {
  height: 30px;
  padding: 4px;
  padding-left: 10px;
}
.bottomLineStyle {
  z-index: 1000;
  width: 100%;
  cursor: row-resize;
  left: 0;
  position: absolute;
}

.defaultClass {
  z-index: 1;
  width: 100%;
  cursor: row-resize;
  left: 0;
  position: absolute;
  top: -4px;
}

.bottomLineStyle:hover +.bottom-line-hover {
  display: block;
}

.bottom-line-hover {
  position: absolute;
  width: 100%;
  left: 0;
  height: 16px;
  z-index: 998;
  background: #d0d0d045;
  display: none;
  transition: all 0.3s;
}

.bottom-line-border {
  z-index: 999;
  height: 1px;
  width: 100%;
  left: 0;
  background: #e2dfdf;
}
.tab-footer {
  width: 100%;
  background: #e0e0e0;
  position: relative;
}

.tab-sider .tab-menu {
  position: relative;
  z-index: 20;
  line-height: 42px;
  font-weight: bold;
  box-sizing: border-box;
  background: #e0e0e0;
  color: #696969;
  border: none;
  transition: 0.3s;
  min-height: 200px;
}

.tab-menu .ant-menu-item-selected {
  width: 100%;
}

.tab-menu .ant-menu-item,
.tab-menu .ant-menu-submenu {
  border-radius: 8px 0 0 8px;
  border-bottom: none;
}

.tab-menu .ant-menu-item-active,
.tab-menu .ant-menu-item:hover,
.tab-sub-menu:hover,
.tab-menu .ant-menu-submenu-active,
.tab-menu .ant-menu-submenu:hover {
  border: none;
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.65);
}

.tab-right-close {
  float: right;
  line-height: 45px;
  height: 44px;
  font-size: 19px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  box-sizing: border-box;
  width: 34px;
}

.tab-button {
  float: right;
  margin-right: 14px;
}

.tab-header {
  height: 44px;
  /*  padding-top: 8px; */
}

.tab-sider .ant-menu-vertical .ant-menu-item-selected,
.tab-sider .ant-menu-vertical .ant-menu-submenu-selected {
  border-right: none;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
}

.tab-sider .ant-menu-vertical .ant-menu-submenu-title:active {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
}

.tab-sider .ant-menu-vertical .ant-menu-item-selected::after,
.tab-sider .ant-menu-vertical .ant-menu-submenu-selected::after {
  display: none;
}

.tab-sider {
  width: 88px;
  position: absolute;
  left: 2px;
  bottom: 14px;
  top: 40px;
  background: #e0e0e0;
  margin-left: 14px;
  overflow: hidden;
}

.tab-sider .ant-menu-vertical .ant-menu-item {
  padding: 0 8px;
}

.tab-subMenu-children>.ant-menu-sub {
  min-width: 88px;
}

.tab-menu .ant-menu-submenu-active,
.tab-menu .ant-menu-submenu-open {
  background: #f0f0f0;
}

.tab-subMenu-children .ant-menu-item:active,
.tab-subMenu-children .ant-menu-item:hover,
.tab-subMenu-children .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.tab-subMenu-children .ant-menu-submenu-title:active {
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.65);
}

.tab-subMenu-title {
  line-height: 35px;
  height: 40px;
  display: block;
}

.tab-sider .ant-menu-vertical .ant-menu-item:not(:last-child),
.tab-sider .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.tab-sider .ant-menu-vertical-right .ant-menu-item:not(:last-child),
.tab-sider .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.tab-sub-menu {
  border-radius: 8px 0 0 8px;
}

.tab-sub-menu .ant-menu-submenu-title,
.tab-sub-menu:hover .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 8px 0 0 8px;
}

.tab-sub-menu .ant-menu-item:active,
.tab-sub-menu .ant-menu-submenu-title:active {
  background: #f0f0f0;
  border-radius: 8px 0 0 8px;
}

.tab-sub-menu .ant-menu-item:hover,
.tab-sub-menu .ant-menu-item-active,
.tab-sub-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.tab-sub-menu .ant-menu-submenu-active,
.tab-sub-menu .ant-menu-submenu-title:hover {
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.65);
}

.tab-sider .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.tab-sider .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.tab-sider .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.tab-sider .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}

.tab-menu .ant-menu-submenu-active,
.tab-menu .ant-menu-submenu-title:hover {
  color: rgba(0, 0, 0, 0.65);
}

.tab-menu .tab-sub-menu .ant-menu-submenu-title:hover {
  background-color: #f0f0f0;
}
.search-box {
  width: 250px;
}

.search-box .ant-input-group-addon {
  color: #fff;
  background-color: #188fff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  cursor: pointer;
}

.search-box .ant-input-suffix>.anticon-close-circle {
  color: rgba(0, 0, 0, 0.35);
}

.search-result {
  /* max-height: 315px; */
  background-color: #fff;
  border-top: 2px solid #e4e6e7;
  border-radius: 0 0 2px 2px;
  overflow: auto;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.15);
}

.result-title {
  padding-left: 15px;
  /* cursor: pointer; */
  font-weight: 700;
}

.result-list {
  padding-left: 10px;
  margin-bottom: 0;
  overflow: hidden;
}

.result-list li {
  padding-left: 20px;
  cursor: pointer;
  list-style: none;
}

.result-list li:hover {
  background: #eee;
}

.signal-select .ant-checkbox-group {
  padding: 5px 15px;
  padding-top: 5px;
  max-height: 238px;
  overflow: auto;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.signal-select>.selected>.ant-checkbox-group {
  max-height: 125px;
  overflow: auto;
}

.signal-select .ant-checkbox-group>label {
  display: block;
}

.signal-confirm {
  text-align: right;
  padding: 0 10px 5px;
}

.signal-confirm>button+button {
  /* float: right; */
  margin-left: 10px;
}

.aurora-content .nav-content .ant-checkbox-wrapper {
  color: #fff;
}

.pdn-search {
  right: 36px !important;
}

.search-select-background,
.search-select-background:hover {
  background-color: #bae7ff !important;
}

.search-select-show {
  position: relative;
  text-align: center;
  height: 28px;
  padding-top: 4px;
  background: #188fff;
  color: #fff;
  cursor: pointer;
  font-weight: 800;
}

.input-search-button {
  color: #ffffff;
}
.layer-tab,
.layer-tab .ant-tabs-nav-container {
  line-height: 28px;
}

.layer-tab .ant-tabs-nav-container {
  position: relative;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  zoom: 1;
}

.layer-tab .ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}

.layer-tab .ant-tabs-bar,
.ant-tabs-nav-container {
  transition: padding .3s cubic-bezier(.645, .045, .355, 1);
}

.layer-tab .ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}

.layer-tab .ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}

.layer-tab .ant-tabs-nav {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
}

.layer-tab .ant-tabs-tab-next.ant-tabs-tab-arrow-show,
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}

.layer-tab .ant-tabs-tab-next {
  right: 2px;
}

.layer-tab .ant-tabs-tab-next,
.ant-tabs-tab-prev {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(0, 0, 0, .45);
  text-align: center;
  background-color: initial;
  border: 0;
  cursor: pointer;
  opacity: 0;
  transition: width .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1), color .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.layer-tab .ant-tabs-tab-prev {
  left: 0;
}

.layer-tab .ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, .25);
}

.layer-tab .ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}

.layer-tab .ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}

.layer-tab .ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  top: -.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}

.layer-tab .ant-checkbox+span {
  padding-right: 8px;
  padding-left: 8px;
}

.layer-tab .ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.layer-tab .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: initial;
  transition: all .3s;
}

.layer-tab .ant-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect .36s ease-in-out;
  animation-fill-mode: backwards;
  content: "";
}

.layer-tab .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.layer-tab .ant-checkbox-inner:after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  content: " ";
}

.layer-tab .ant-checkbox-checked .ant-checkbox-inner:after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  content: " ";
}
.selectionButton {
  margin-left: 6px;
  width: 75px;
}

.highlightobjects .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.highlightobjects .ant-tooltip-arrow::before {
  background-color: #fff;
}

.selection-panel {
  z-index: 1010;
  background: #fff;
  position: absolute;
  width: 300px;
  bottom: 34px;
  right: 5px;
}

.result-selected {
  padding: 6px 16px;
}

.seleted-icon {
  display: inline-block;
  margin-left: 8px;
}

.selection-panel-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.button-tiltes {
  font-size: 8px;
  font-weight: bold;
}

.selection-panel .ant-modal-header {
  padding: 8px 12px;
}

.selection-panel .ant-modal-close-x {
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

.delete-selection {
  float: right;
}

.result-titles.resultBtn {
  display: block;
  margin: 0 auto
}

.dispaly-selection {
  font-size: 16px;
  transform: translateY(5%);
}

.dispaly-selection+.dispaly-selection {
  margin-left: 12px;
}

.clear-selection {
  font-size: 16px;
}

.result-title-nothing {
  padding: 60px;
  font-size: 14px;
  text-align: center;
}

.result-list {
  padding: 0px 16px 6px 16px;
  margin-bottom: 0;
  overflow: auto;
}

.result-list .anticon-close:hover svg {
  fill: #00aaff !important;
}

.result-list>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.display-selection-switch-box {
  display: inline-block;
  margin-left: 12px;
}

.display-selection-switch-box .display-selection-switch {
  margin-left: 10px;
  margin-bottom: 2px;
}
.aurora-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: 400;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  user-select: text;
}

.aurora-popover-placement-bottom,
.aurora-popover-placement-bottomLeft,
.aurora-popover-placement-bottomRight {
  padding-top: 10px;
}

.aurora-popover-hidden {
  display: none;
}

.aurora-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}

.aurora-popover-placement-bottom>.aurora-popover-content>.aurora-popover-arrow,
.aurora-popover-placement-bottomLeft>.aurora-popover-content>.aurora-popover-arrow,
.aurora-popover-placement-bottomRight>.aurora-popover-content>.aurora-popover-arrow {
  top: 6px;
  border-color: #fff transparent transparent #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, .06);
}

.aurora-popover-placement-bottom>.aurora-popover-content>.aurora-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.aurora-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  box-shadow: 0 0 8px rgba(0, 0, 0, .15) \9;
}

.aurora-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, .65);
}

.context-menu {
  transform: translateX(-50%);
}

.context-menu .aurora-popover-inner {
  position: absolute;
  transform: translateX(-50%);
}

.context-menu .aurora-popover-inner-content {
  padding: 5px 15px;
}

.context-menu .aurora-popover-inner-content:hover {
  cursor: pointer;
  background-color: #eee;
  border-radius: 4px;
}
body {
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvas {
  width: 100%;
  /*  height: 100%; */
  height: 98%;
  background-color: #000000;
  padding-bottom: 28px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: translateZ(0);
  will-change: transform;
}

.lay_geom:hover {
  stroke: red;
}

svg {
  stroke-linecap: round;
  stroke-linejoin: round;
}

polygon:hover,
cicle:hover,
path:hover,
polyline:hover {
  stroke: red;
}

[fill="#333333"] .pad,
[fill="#333333"] .barrel {
  fill: #333333;
}

.pad {
  fill: #e1e1e1;
  opacity: 0.7;
}

.barrel {
  fill: #555555;
}

.selected-via .pad{
  fill: #bfad6f;
}

.selected-via .barrel{
  fill: #f2c056;
}

.antipad {
  fill: #000;
}

[layer="selection"] .antipad {
  opacity: 0;
}

.layer-tab {
  height: 28px;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  line-height: 28px;
}

.layer-tab .ant-tabs-nav-container {
  line-height: 28px;
}

.layer-tab .ant-select>.ant-select-selector {
  background-color: #1677ff;
  color: #fff;
}

.aurora-content .layer-tab {
  background-color: #1e1e1c;
  border-top: 1px solid #a0a0a0;
}

.aurora-content .layer-tab .ant-select>.ant-select-selector {
  border: none;
}

.aurora-content .layer-tab .ant-select>.ant-select-selector {
  line-height: 24px;
}

.serdes-select-version .ant-select-arrow svg {
  color: #fff;
}

.canvas-coord {
  position: absolute;
  left: 5px;
  bottom: 40px;
  color: #fff;
  float: right;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-group-vertical {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
}

.layBtn {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 1px 0;
  width: 100%;
  margin: 10px 5px;
  display: block;
  position: relative;
  font-size: 24px;
  color: #aaa;
}

.layBtn:hover {
  background-color: #dddddd;
  color: #333;
  cursor: pointer;
}

.layBtn .pintopin-bars {
  font-size: 28px;
}

.global-search {
  position: absolute;
  top: 5px;
  right: 5px;
  overflow: hidden;
}

.location-mark {
  display: none;
  position: absolute;
  height: 24px;
  width: 24px;
  fill: red;
  left: 50%;
  top: 50%;
  margin-top: -36px;
  margin-left: -12px;
  pointer-events: none;
}

.panel {
  position: absolute !important;
  top: 50px;
  width: 900px;
  left: 50%;
  margin-left: -450px;
}

.panel .panel-body {
  height: 450px;
  overflow-y: auto;
}

.result-panel .panel-body {
  height: 450px;
}

.layer-button {
  display: inline-block;
}

.coloredby .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.coloredby .ant-tooltip-arrow::before {
  background-color: #fff;
}

.verify-content>.ant-spin-nested-loading,
.verify-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.change-opacity {
  opacity: 0.5 !important;
}

.aurora-content .ant-tabs-tab-prev-icon,
.aurora-content .ant-tabs-tab-next-icon {
  color: #fff;
}

.aurora-content .layer-tab {
  height: 30px;
}

.pcb-explorer {
  height: 100%;
}

.pcb-explorer .ant-spin-nested-loading {
  height: 100%;
}

.pcb-explorer .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}

.pcb-explorer-layer-tab-col {
  overflow: hidden;
  padding: 0 2px
}

.pcb-explorer-layer-tab-content {
  display: flex;
  justify-content: right;
}

.layer-tab .pcb-explorer-layer-tab-col .ant-tabs-nav-container {
  float: right;
}
.menu-list>ul {
  padding: 14px;
  margin: 0px;
}

.menu-list li {
  list-style: none;
  cursor: pointer;
  line-height: 24px;
}

.modelIbis-list li {
  list-style: none;
  padding-inline-start: 0px;
}

.menu-list>ul>li>span {
  font-weight: bold;
}

.expand-icon {
  font-size: 12px;
  padding-right: 10px;
}

.menu-list-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.menu-panel>.ant-modal-close>.ant-modal-close-x {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.menu-panel>.ant-modal-header {
  padding: 12px 18px;
}

.menu-list-title:hover, .menu-list-pintopin-title a:hover {
  color: #1890ff;
  transition: .5s all;
}

.menu-list-pintopin-title a {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
}

.menu-list-pintopin-title {
  list-style: square inside !important;
  padding-left: 4px;
}
.stackup-table .ant-table-footer {
  padding: 8px 0;
}

.stackup-table .ant-table-footer div {
  margin-left: 76px;
  font-weight: bold;
}

.stackup-table .ant-table-container .ant-table-header>table>.ant-table-thead>tr>th {
  background: #fafafa;
}

.stackup-input-group {
  width: 160px;
  float: left;
}

.stackup-input-group .ant-select-selection-selected-value {
  padding-right: 0px;
}

.stackup-thickness-name {
  float: left;
  margin-left: 0px;
  margin-right: 8px;
}

.stackup-input-col,
.stackup-input-row {
  line-height: 32px;
}

.stackup-input-col {
  float: left;
}

.stackup-set-name {
  margin: 0px 8px 0 16px;
}

.stackup-footer-value-setting {
  height: 32px;
  line-height: 32px;
  width: 100%;
  text-align: left;
  margin: 0px;
}

.stackup-set-button {
  margin-left: 8px;
  margin-right: 8px;
}

.stackup-clear {
  zoom: 1
}

.stackup-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.stackup-dropdown-button .ant-dropdown-menu-item button {
  width: 140px;
  padding-left: 15px;
  text-align: left;
  border: 0px;
}

.stackup-menu-button {
  padding: 0px;
  vertical-align: middle;
}

.stackup-dropdown-button .ant-dropdown-menu-item {
  padding: 0px;
}

.stackup-dropdown-button .ant-dropdown-menu-item button:hover {
  background-color: #e6f7ff
}
.designInfo .ant-tabs-bar {
  margin: 0px;
}

.properties-title {
  background: #1890ff;
  color: #ffffff;
}

.properties-table .ant-table>.ant-table-content>.aurora-table-body {
  margin: 0px;
}

.properties-table .aurora-table-body > tr:hover > td{
  background:rgba(230, 247, 255, 0.784) ! important;
}

.properties-table .aurora-table-body > tr:hover .properties-title{
  background:#1890ff ! important;
}

.component-name,
.component-part,
.net-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.component-name{
  width: 64px;
}

.component-part {
  width: 150px;
}

.net-name {
  width: 150px
}

.designInfo {
  height: 300px;
}

.detail-info {
  padding: 5px 10px
}

.detail-info div {
  line-height: 21px
}

/* .general-info-table {

} */
.clear {
  zoom: 1
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.serdes-config-row {
  margin: 10px !important;
}

.font-bold {
  font-weight: bold;
}

.serdes-name-input,
.serdes-datarate-input {
  width: 130px;
  margin-left: 10px;
}

.serdes-select-version {
  min-width: 50px;
  margin-left: 10px;
  margin-bottom: 2px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.serdes-config-divider {
  margin: 0px;
}

.serdes-interface-icon {
  margin-left: 10px;
  font-size: 18px;
  transition: all 0.5s;
}

.serdes-interface-icon:hover {
  cursor: pointer;
  color: #1890ff;
  transition: all 0.5s;
}

.config-serdes-ground-nets {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  height: 32px;
  margin-left: 10px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.65);
  min-width: 150px;
  float: left;
}

.fl-l {
  float: left;
}

.serdes-ground-nets:hover {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  transition: all 0.3s;
}

.ground-nets {
  height: 32px;
  line-height: 32px;
}

.serdes-table {
  margin-top: 10px;
}

.serdes-table-auto .ant-table-middle .ant-table-container .aurora-table-body>table>.ant-table-thead>tr>th,
.serdes-table-auto .ant-table-middle .ant-table-container .aurora-table-body>table>.ant-table-tbody>tr>td {
  height: 38px !important;
  line-height: 38px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.col-padding-0 {
  padding: 0px !important;
}
.stackup-multi-zone {
  margin: 0px 6px;
  width: 65px;
}

.stackup-multi-zone-panel {
  z-index: 1010;
  background: #fff;
  position: absolute;
  /*  width: 300px; */
  bottom: 34px;
  right: 5px;
}

.stackup-zones-display-icon,
.stackup-zones-edit-icon {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.stackup-zones-display-highlight-icon,
.stackup-zones-edit-icon {
  color: #1890ff;
}

.stackup-multi-zone-content {
  display: flex;
}

.stackup-multi-zone-body {
  border: 1px solid #63b4ff;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
}

.stackup-multi-zone-body>span {
  font-weight: bold;
  padding-bottom: 10px;
  display: inline-block;
}

.stackup-multi-zone-panel .panel-body {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.designInfo .ant-tabs-bar {
  margin: 0px;
}

.properties-title {
  background: #1890ff;
  color: #ffffff;
}

.properties-table .ant-table>.ant-table-content>.aurora-table-body {
  margin: 0px;
}

.properties-table .aurora-table-body>tr:hover>td {
  background: rgba(230, 247, 255, 0.784) ! important;
}

.properties-table .aurora-table-body>tr:hover .properties-title {
  background: #1890ff ! important;
}

.designInfo {
  height: 300px;
}

.detail-info {
  padding: 5px 10px
}

.detail-info div {
  line-height: 21px
}

.aurora-menu-panel-net,
.aurora-menu-panel-component,
.aurora-menu-panel-padstack,
.aurora-menu-panel-layout-errors {
  min-width: 256px;
}

.aurora-menu-panel-net .panel-modal-header,
.aurora-menu-panel-component .panel-modal-header {
  border-bottom: none;
}

.net-virtual-header .ant-table-bordered {
  border-right: 0px;
}

.net-virtual-header .ant-table-small {
  border-radius: 0px;
}

.net-virtual-body {
  margin-top: -5px;
}

.net-virtual-body .ant-table-small {
  border-radius: 0px;
}

.component-virtual-header .ant-table-bordered {
  border-right: 0px;
}

.component-virtual-header .ant-table-small {
  border-radius: 0px;
}

.component-virtual-body {
  margin-top: -5px;
}

.component-virtual-body .ant-table-small {
  border-radius: 0px;
}

.components-name {
  cursor: pointer;
}

.nets-name {
  cursor: pointer;
}

.layout-padstack-content {
  margin: 15px 10px;
}

.layout-padstack-content-right {
  text-align: right;
}

.layout-padstack-content .plating-thickness-title {
  width: 190px;
  display: inline-block;
  text-align: left;
  line-height: 24px;
}

.layout-padstack-content .plating-thickness-input {
  width: calc(22% - 5px);
  line-height: 24px;
}

.layout-padstack-content-error {
  width: 100%;
  padding-left: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  line-height: 28px;
}

.layout-padstack-via-name {
  cursor: pointer;
}

.layout-padstack-panel .panel-body {
  overflow: auto !important;
}

.pad-stack-hole-size-unit {
  z-index: 9999;
}

.pad-stack-hole-size-body {
  margin: 10px;
}

.pad-stack-hole-size-content:not(:last-child) {
  margin-bottom: 10px;
}

.pad-stack-hole-size-content>.pad-stack-hole-size-title {
  display: inline-block;
  width: 90px;
  line-height: 24px;
}

.pad-stack-hole-size-content>.pad-stack-hole-size-input {
  width: calc(100% - 100px);
}

.layout-padstack-via-selected {
  background-color: rgb(176, 226, 255);
}

#pad-stack-dialog>div {
  z-index: 2001;
}

.layout-display-item-check-box {
  height: 16px;
  line-height: 16px;
}
.btn-group-vertical .ant-menu-vertical.aurora-explorer-menu {
  background: unset;
  border: 0px;
  margin: 5px;
  border-inline-end: 0px;
}

.btn-group-vertical .aurora-explorer-menu .layBtn {
  margin: 0px;
  /* padding: 0 8px; */
  width: 34px;
  height: 40px;
  padding: 0px;
}

.btn-group-vertical .ant-menu-vertical .ant-menu-item {
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
  width: 34px;
}

.btn-group-vertical .ant-menu-vertical .ant-menu-item {
  padding: 0px;
}

.btn-group-vertical .ant-menu-vertical .ant-menu-submenu-title {
  padding: 0px;
}

.btn-group-vertical .ant-menu-vertical .ant-menu-submenu-arrow {
  display: none;
}

.aurora-explorer-menu-dropdown {
  margin: 0px !important;
  width: 100% !important;
}

.aurora-explorer-menu-submenu .ant-menu-submenu-title {
  margin-left: 0px;
  margin-right: 8px;
}

.aurora-explorer-menu-submenu .ant-menu-vertical.ant-menu-sub {
  width: 120px;
  min-width: 120px;
}

.btn-group-vertical .aurora-explorer-menu .ant-menu-item-selected {
  background-color: unset;
}
.surface-roughness-panel {
  margin-top: 100px;
  min-width: 370px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.surface-roughness-panel .panel-modal-header {
  padding-left: 14px !important;
}

.surface-roughness-title {
  font-size: 16px;
  font-weight: 500;
}

.surface-roughness-panel .surface-roughness-content {
  padding: 10px 16px;
  padding-top: 2px;
}

.surface-roughness-panel .surface-roughness-item {
  margin: 6px 0px;
  line-height: 28px;
  height: 28px;
}

.surface-roughness-panel .surface-roughness-item-tabs {
  padding: 0px 8px;
}

.surface-roughness-content .surface-roughness-edit-value-label {
  display: inline-block;
  width: 164px;
  margin-right: 10px;
  vertical-align: middle;
}

.surface-roughness-panel .surface-roughness-select,
.surface-roughness-panel .surface-roughness-input {
  width: calc(100% - 174px);
  font-size: 13px;
  vertical-align: middle;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-edit-value-label-long {
  width: 300px;
  margin-right: 0px;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-item-half {
  width: calc(100% - 188px);
  display: inline-block;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-item-checkbox {
  width: 30px;
  vertical-align: middle;
}

.surface-roughness-panel .surface-roughness-content .aurora-model-name-error-msg {
  margin: 8px 0px;
}

/* tabs */
.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}

.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-content {
  border: 1px solid #E8E8E8;
  border-top: 0px;
  padding: 6px 0px;
}

.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 32px;
}

.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-card-bar .ant-tabs-tab {
  height: 32px;
  padding: 0px 10px;
  line-height: 30px;
}

.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 32px;
  padding: 0px 10px;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-divider {
  margin-top: 10px;
  margin-bottom: 2px;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-item-apply {
  margin: 0px 0px;
}

.surface-roughness-panel .surface-roughness-content .ant-tabs .ant-tabs-nav {
  margin: 0px;
}
.material-menu-panel {
  min-width: 200px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.material-menu-panel .panel-modal-header {
  padding-left: 14px !important;
}

.material-menu-panel .material-menu-content {
  padding: 10px;
  padding-top: 2px;
}

.material-menu-content .material-menu-item {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  padding: 0px 8px;
  margin: 2px 0px;
  position: relative;
}

.material-menu-content .material-menu-item>span:not(.anticon) {
  width: calc(100% - 10px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-menu-content .material-menu-item:hover {
  color: #1890ff;
  background-color: #e6f7ff;
}

.material-menu-content .material-menu-item-selected,
.material-menu-content .material-menu-item-selected:hover {
  color: #1890ff;
  background-color: #bae7ff;
}

.material-menu-content .material-menu-type-title {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
}

.material-menu-content .material-menu-type-title .anticon {
  margin-right: 10px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.85);
  vertical-align: middle;
}

.material-menu-content .material-menu-type-title>span {
  vertical-align: middle;
}

.material-menu-content .material-menu-type-content {
  margin-left: 10px;
}

.material-menu-item-delete-icon {
  position: absolute;
  right: 3px;
  top: 7px;
  font-size: 13px;
}

.material-menu-content .material-menu-item:hover .material-menu-item-delete-icon {
  color: #1890ff;
}

.material-add {
  color: #1890ff;
  margin-left: 13px;
  font-size: 15px;
}
.stackup-table .ant-table-footer {
  padding: 8px 0;
}

.stackup-table .aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap {
  background-color: rgba(0, 0, 0, 0);
}

.aurora_stackup_fullscreen {
  margin-top: 70px;
}

.stackup-table .ant-table-container .ant-table-header>table>.ant-table-thead>tr>th {
  background: #fafafa;
  position: relative;
}

.stackup-table .ant-table-content table>.ant-table-thead>tr>th {
  position: relative;
}

.stackup-input-group {
  width: 160px;
  float: left;
}

.stackup-input-group .ant-select-selection-selected-value {
  padding-right: 0px;
}

.stackup-thickness-name {
  float: left;
  margin-left: 0px;
  margin-right: 8px;
}

.stackup-input-col,
.stackup-input-row {
  line-height: 32px;
}

.stackup-input-col {
  float: left;
}

.stackup-set-name {
  margin: 0px 8px 0 16px;
}

.stackup-footer-value-setting {
  height: 32px;
  line-height: 32px;
  width: 100%;
  text-align: left;
  margin: 0px;
}

.stackup-set-button {
  margin-left: 8px;
  margin-right: 8px;
}

.stackup-clear {
  zoom: 1
}

.stackup-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.stackup-dropdown-button .ant-dropdown-menu-item button {
  width: 140px;
  padding-left: 15px;
  text-align: left;
  border: 0px;
}

.stackup-menu-button {
  padding: 0px;
  vertical-align: middle;
}

.stackup-subMenu {
  z-index: 2000;
}

.stackup-dropdown-button .ant-dropdown-menu-item {
  padding: 0px;
}

.stackup-dropdown-button .ant-dropdown-menu-item button:hover {
  background-color: #e6f7ff
}

.stackup-conductivity {
  width: 160px;
}

.stackup-unit-selection {
  height: 26px;
}

.stackup-unit-selection .ant-select-selector {
  line-height: 24px;
}

.stackup-unit-selection .ant-select-arrow {
  right: 6px;
}

.stackup-unit-option {
  z-index: 2000;
}

.stackup-table-edit div {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.stackup-table-edit div+div {
  margin-top: 4px;
}

.stackup-table-edit .anticon-vertical-align-top,
.stackup-table-edit .anticon-vertical-align-bottom {
  font-size: 16px;
}

.stackup-table-edit .anticon-close {
  font-size: 13px;
}

.stackup-table tr td:first-child>div {
  cursor: pointer;
}

.stackup-table-row-light {
  background-color: #f3eecf;
}

.stackup-table .open-material-icon {
  margin-left: 10px;
  color: #1890ff;
  font-size: 17px;
  vertical-align: middle;
}

.stackup-total-thickness-content {
  display: flex;
  width: 100%;
  margin-left: 0px !important;
  justify-content: space-around;
  font-weight: bold;
}

.download-stackup-menu .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  display: none;
}

.stackup-table .etch-help-icon {
  color: #1890ff;
  font-size: 16px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}

.etch-factor-help-panel .help-panel-content,
.etch-factor-help-panel .help-panel-content img {
  width: 100%;
  height: 100%;
}

.etch-factor-box {
  margin-left: 18px;
}


.stackup-out-dielectric-color {
  background-color: #cfeb7e;
}

.stackup-all-metal-color {
  background-color: #f9c236;
}

.stackup-in-dielectric-color {
  background-color: #fffecf;
}

.stackup-out-dielectric-color .editable-cell-value-wrap {
  border-color: #9b9b9b;
}

.stackup-all-metal-color .editable-cell-value-wrap {
  border-color: #d1d1d1;
}

.stackup-in-dielectric-color .editable-cell-value-wrap {
  border-color: #d8d8d8;
}

.stackup-all-layer>td {
  border-right: 1px solid #d3d3d3 !important;
  border-bottom: 1px solid #d3d3d3 !important;
}

.stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #cce779 !important;
}

.stackup-all-metal-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #fdcb4c !important;
}

.stackup-in-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #ffffbc !important;
}

.stackup-multi-zone-radio-group {
  padding: 8px 20px;
}

.multi-zone-stackup-checkbox-content {
  text-align: center;
}

/* .stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-PRIMARY-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-PRIMARY-td,
.stackup-table .stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td.multi-zone-stackup-checkbox-content-PRIMARY-td {
  background-color: #e2f3f9 !important;
}

.stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-FLEX-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-FLEX-td,
.stackup-table .stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td.multi-zone-stackup-checkbox-content-FLEX-td {
  background-color: #d9eddc !important;
}

.stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-RIGID-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-RIGID-td,
.stackup-table .stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td.multi-zone-stackup-checkbox-content-RIGID-td {
  background-color: #fcf0e5 !important;
} */
.stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-stackup-name-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-stackup-name-td,
.stackup-table .stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td.multi-zone-stackup-checkbox-content-stackup-name-td {
  background-color: #d9eddc !important;
}

.stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-stackup-name-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-stackup-name-td {
  border: 1px #ffffff solid !important;
  padding: 0px !important;
}

.aurora-multi-zone-stackup-icon {
  margin-right: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
  vertical-align: middle;
}

.stackup-name-td {
  width: calc(100% - 16px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* === debug panel === */
.aurora-debug-panel {
  margin-top: 100px;
  overflow: hidden;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.aurora-debug-panel .panel-modal-header {
  padding-left: 14px !important;
}

.aurora-debug-title {
  font-size: 16px;
  font-weight: 500;
}

.aurora-debug-panel .aurora-debug-content {
  margin: 10px;
  position: relative;
  border: 1px solid #f3f2f1;
  border-radius: 6px 6px 0px 0px;
  min-height: min-content;
  min-width: 740px;
}

.aurora-debug-menu {
  height: 32px;
  background: #f3f2f1;
}

.aurora-debug-menu-item,
.aurora-debug-menu-item-selected {
  float: left;
  line-height: 23px;
  padding: 4px 16px;
  cursor: pointer;
  border-radius: 6px 6px 0px 0px;
}

.aurora-debug-menu-item:hover {
  background-color: #edebe9;
}

.aurora-debug-menu-item-selected {
  background-color: #c7e0f4;
}

.aurora-debug-menu-item .debug-menu-item-span,
.aurora-debug-menu-item-selected .debug-menu-item-span {
  font-size: 14px;
  color: #005a9e;
}

.aurora-debug-select {
  font-size: 13px;
  height: 24px;
  display: inline-block;
  margin-left: 10px;
  color: #005a9e;
}

.aurora-debug-select-simulation-width {
  max-width: 160px;
}

.aurora-debug-select-sub-simulation-width {
  max-width: 106px;
}

.aurora-debug-select {
  height: 24px;
}

.aurora-debug-select .ant-select-selector {
  line-height: 22px;
}

.aurora-debug-select .ant-select-arrow {
  right: 6px;
  color: #005a9e;
}

.aurora-debug-select-down-drop {
  z-index: 10000000;
  max-width: 350px;
}

.aurora-debug-select-down-drop .ant-select-dropdown-menu-item {
  font-size: 13px;
  color: #005a9e;
}

.aurora-debug-select-down-drop .ant-select-dropdown-menu-item-selected {
  background-color: #e6f7ff;
}

.aurora-debug-list-content {
  margin-top: 6px;
  float: left;
  width: 100%;
}

.aurora-debug-list-content .debug-download-checkbox-group-box,
.aurora-debug-list-content .debug-download-checkbox-box {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
}

.aurora-debug-list-content .debug-download-checkbox-group {
  padding: 5px 10px;
}

.aurora-debug-list-content .debug-download-checkbox-group .debug-download-checkbox-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 644px;
  display: inline-block;
  vertical-align: middle;
}

.aurora-debug-list-content .debug-download-checkbox-group-content {
  margin-left: 12px;
}

.aurora-debug-list-content .debug-download-checkbox-group-content .debug-download-checkbox-group {
  padding-left: 10px;
}

.aurora-debug-list-content .debug-download-checkbox-group-content .anticon {
  font-size: 10px;
}

.aurora-debug-list-content .padding-left-34 {
  padding-left: 34px;
}

.aurora-debug-list-content .debug-download-checkbox-children-group {
  padding: 5px 10px;
  padding-left: 54px;
}

.aurora-debug-list-content .debug-select-list-item {
  padding: 5px 10px;
  margin-left: 4px;
  width: -moz-fit-content;
  width: fit-content;
}

.aurora-debug-list-content .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.aurora-debug-list-content .debug-download-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
}

.aurora-debug-list-content .debug-download-checkbox-group:hover .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
}

.aurora-debug-list-content .debug-download-checkbox-group .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #1890ff;
  border-top: 0;
  border-left: 0;
}

.aurora-debug-list-content .debug-download-checkbox-group .ant-checkbox+span {
  padding-left: 16px;
}

.aurora-debug-list-content .aurora-debug-download-message {
  position: absolute;
  left: 16px;
  right: 120px;
  bottom: 8px;
  height: 30px;
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.aurora-debug-list-content .aurora-debug-progress {
  padding: 2px 16px 2px 10px;
  margin: 10px 0px;
}

.aurora-debug-list-content .aurora-debug-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.aurora-debug-list-content .aurora-debug-progress .ant-progress-text {
  vertical-align: unset;
}

.aurora-debug-list-content .aurora-debug-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.aurora-debug-list-content .debug-download-btn {
  float: right;
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.aurora-debug-list-content .debug-download-button {
  margin: 10px 10px;
  float: right;
}

.aurora-debug-list-content .download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.aurora-debug-list-content .download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.aurora-debug-download-content {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.aurora-debug-download-content .aurora-debug-item-content {
  float: none;
  margin-top: 0px;
  border: 1px solid #e8e8e8;
  padding: 10px;
  position: relative;
}

.aurora-debug-download-menu-box .aurora-debug-download-menu-item {
  float: none;
  /* background: #f3f2f1; */
  text-align: left;
  padding-bottom: 4px;
  border-radius: 3px;
  text-align: center;
}

.aurora-debug-download-menu-box .aurora-debug-download-menu-item .aurora-debug-simulation-select {
  min-width: 100px;
  margin-left: 0px;
  width: 100%;
}

.debug-menu-item-title {
  text-align: center;
  font-size: 14px;
  color: #005a9e;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box {
  margin-bottom: 70px;
  /* max-height: 250px; */
  overflow: auto;
  width: 100%;
}

.aurora-debug-download-content .aurora-debug-list-content .aurora-debug-progress,
.aurora-debug-download-content .aurora-debug-list-content .debug-download-button {
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.aurora-debug-download-content .aurora-debug-list-content .aurora-debug-download-message {
  right: 20px;
  bottom: 50px;
  min-height: 30px;
  height: auto;
  padding: 0px 4px;
}

.aurora-debug-download-menu-item .aurora-debug-download-select {
  height: 28px;
}

.aurora-debug-download-menu-item .aurora-debug-download-select .ant-select-selector {
  line-height: 26px;
}

.aurora-debug-download-menu-item .aurora-multiple-select-box .aurora-debug-download-select {
  margin-top: 5px;
}

.aurora-debug-download-multi-select {
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
  width: 100%;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-group {
  width: calc(100% - 12px);
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-children-group {
  width: 100%;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-group>span:nth-child(2),
.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-children-group>span:nth-child(2) {
  width: calc(100% - 16px);
  display: inline-block;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-group .debug-download-checkbox-span {
  width: 100%;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-children-span {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-box {
  width: 100%;
}

.aurora-download-flex-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.aurora-download-flex-content-item-title {
  width: 100%;
  text-align: center;
  height: 28px;
  line-height: 28px;
  font-weight: 600;
}

.aurora-download-flex-content-item {
  border-right: 1px solid #cecece;
  width: calc(50% - 10px);
  min-width: calc(50% - 10px);
  margin-bottom: 10px;
}

.aurora-download-flex-content .aurora-download-flex-content-item .debug-download-checkbox-group-box {
  margin-bottom: 0px !important;
}
.pre-layout-component-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.pre-layout-component-table .ant-table-thead>tr>th:nth-child(2) .ant-table-header-column .ant-table-column-title,
.pre-layout-component-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title {
  padding-left: 4px
}

.pre-layout-component-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.pre-layout-component-table {
  background: #ffffff;
}

.pre-layout-unit-select {
  display: flex;
  width: 100%;
  line-height: 34px;
  height: 34px;
}

.pre-layout-span {
  display: inline-block;
  width: 180px;
  height: 28px;
  line-height: 28px;
}

.pre-layout-component-table .ant-table-thead>tr>th,
.pre-layout-component-table .aurora-table-body>tr>td {
  text-align: center;
}

/* line tip */
.pre-layout-tip {
  position: absolute;
  top: 25px;
  right: 10px;
}

.pre-layout-tip .pre-layout-img-main {
  width: 15px;
  height: 15px;
  float: left;
  vertical-align: middle;
  text-align: center;
  margin-right: 5px;
  margin-top: -2px;
}

.pre-layout-tip span {
  margin-right: 25px;
  float: left;
}

.pre-layout-tip .pre-layout-img-main img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

/* pre-layout setup table */

.pre-layout-component-table .ant-table .ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
}

.pre-layout-component-table .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.pre-layout-component-table .aurora-table-body>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.pre-layout-component-table .ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.pre-layout-component-table .aurora-table-body>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: transparent;
}

.pre-layout-component-table .ant-table .ant-table-content .aurora-table-body .ant-table-row td .editable-cell-value-wrap{
  display: grid;
}

.pre-layout-component-table .ant-table .ant-table-content .aurora-table-body .ant-table-row td:hover {
  background-color: #e6f7ff;
}

.pre-layout-component-table .ant-table-header-column .ant-table-column-title {
  padding-left: 0px !important;
}

.pre-layout-component-table .ant-table-thead>tr>th {
  text-align: center;
}

.pre-layout-component-table .aurora-table-body,
.pre-layout-signals-table .aurora-table-body  {
  overflow: auto !important;
}

.pre-layout-component-table .aurora-table-body>tr>td:last-child {
  border-right: 1px solid #e8e8e8 !important;
}

.pre-layout-component-table .ant-table .aurora-table-body .ant-table-row td {
  text-align: center;
}

.pre-layout-component-table .ant-table .aurora-table-body .ant-table-row td:hover .pre-layout-hover-border {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pre-layout-component-table .editable-row .ant-row,
.pre-layout-component-table .edit-form-item .ant-col,
.pre-layout-component-table .edit-form-item .ant-col .ant-form-item-control,
.pre-layout-component-table .edit-form-item .ant-col .ant-form-item-control .ant-form-item-children {
  position: unset !important;
}

.sierra-multi-setup-table .editable-cell-value-wrap,
.sierra-multi-buffer-table .editable-cell-value-wrap,
.sierra-multi-passive-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.sierra-multi-setup-table .aurora-table-body>tr>td,
.sierra-multi-buffer-table .aurora-table-body>tr>td:nth-child(1),
.sierra-multi-buffer-table .aurora-table-body>tr>td:nth-child(2),
.sierra-multi-buffer-table .aurora-table-body>tr>td:nth-child(3),
.sierra-multi-passive-table .aurora-table-body>tr>td:nth-child(1),
.sierra-multi-passive-table .aurora-table-body>tr>td:nth-child(2) {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.sierra-multi-setup-content .sierra-multi-setup-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th:nth-child(6),
.sierra-multi-setup-content .sierra-multi-setup-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th:nth-child(7) {
  padding-left: 12px;
}

.sierra-multi-setup-table .ant-table .interface-table-checkBox {
  width: 35px;
  text-align: center;
}

.sierra-multi-setup-table .multi-setup-setting-icon {
  cursor: pointer;
}

.sierra-multi-setup-table .multi-setup-setting-selected-icon {
  color: #1890ff;
}

.sierra-multi-setup-table .interface-table-setting-content {
  width: 100%;
  text-align: center;
}

/* buffer */
.sierra-multi-buffer-panel,
.sierra-multi-passive-panel {
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.multi-buffer-content,
.multi-passive-content {
  width: 100%;
  padding: 10px 12px;
  min-width: 1078px;
}

.sierra-multi-buffer-copy-usage {
  display: inline-block;
  line-height: 28px;
  height: 28px;
}

.sierra-multi-buffer-copy-usage>.sierra-multi-buffer-copy-usage-switch {
  margin-left: 10px;
}

.sierra-multi-setup-buffer-title {
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #0b3b94;
}

.sierra-content-group-selection {
  width: calc(100% - 300px);
  display: flex;
  line-height: 28px;
  height: 28px;
}

.sierra-content-group-selection>span {
  display: block;
  width: 90px;
}

.sierra-content-group-selection>.aurora-select {
  width: calc(100% - 90px);
}

.multi-setup-simulation-apply-content {
  width: 100%;
  display: flex;
  line-height: 28px;
  padding: 0px 16px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.multi-setup-simulation-apply-content>span {
  display: block;
  width: 172px;
  font-size: 13px;
}

.multi-setup-simulation-apply-content .aurora-select {
  width: calc(100% - 392px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
}

.sierra-simulation-option-content .multi-setup-simulation-option-apply-content>span {
  display: block;
  width: 100px;
  font-size: 13px;
}

.sierra-simulation-option-content .multi-setup-simulation-option-apply-content>.aurora-select {
  width: calc(100% - 100px);
}

.multi-setup-option-select-dropdown {
  z-index: 100000000;
  max-height: 500px;
}

.multi-setup-buffer-model-assigning {
  display: inline-block;
  margin-left: 20px;
  color: #1890ff;
}

.multi-setup-buffer-model-re-match-icon {
  color: #1890ff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}

.sierra-multi-setup-content .ant-table-pagination.ant-pagination,
.sierra-multi-buffer-content .ant-table-pagination.ant-pagination,
.sierra-multi-passive-content .ant-table-pagination.ant-pagination {
  margin-top: -36px;
  margin-bottom: 8px;
}

.sierra-multi-edit-content {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-setup-buffer-stimulus-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.multi-setup-buffer-stimulus-content .stimulus-copy-setup-icon {
  position: absolute;
  right: 2px;
  top: 50%;
  margin-top: -6px;
  display: none;
  color: #1890ff;
  cursor: pointer;
}

.multi-setup-buffer-stimulus-content .stimulus-copy-setup-icon-1 {
  margin-top: -7px;
}

.stimulus-paste-setup-icon {
  font-size: 15px;
  margin-right: 22px;
  float: right;
  display: none;
  color: #1890ff;
  cursor: pointer;
  padding-top: 2px;
  vertical-align: middle;
  /*   position: absolute;
  right: 22px;
  top: 50%;
  margin-top: -13px; */
}

.multi-setup-buffer-stimulus-content .stimulus-paste-setup-icon-1 {
  margin-right: 2px;
}

.multi-setup-buffer-stimulus-content:hover .stimulus-copy-setup-icon,
.multi-setup-buffer-stimulus-content:hover .stimulus-paste-setup-icon {
  display: inline-block;
}

.multi-setup-buffer-stimulus-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.multi-setup-buffer-stimulus-content:hover .multi-setup-buffer-stimulus-text-1 {
  width: calc(100% - 38px);
}

.multi-setup-buffer-stimulus-content:hover .multi-setup-buffer-stimulus-text-2 {
  width: calc(100% - 18px);
}

.sierra-extraction-content-apply-checkbox {
  vertical-align: middle;
}

.sierra-multi_setup-result-icon {
  font-size: 13px;
}

.sierra-multi_setup-result-icon:hover {
  color: #1890ff;
}

.sierra-multi-setup-table .aurora-table-tbody>tr>.sierra-multi-interface-result {
  text-align: center;
}

.sierra-multi-setup-content .sierra-multi-setup-table .ant-table-small.ant-table-bordered .aurora-table-body>tr>td {
  border-right: 1px solid #e8e8e8;
}

.sierra-multi-extraction-content-apply-item {
  width: 220px;
  padding-left: 10px;
  display: flex;
}

.sierra-multi-extraction-content-apply-item>.aurora-select {
  width: calc(100% - 52px);
}

.sierra-multi-extraction-content-apply-item>span {
  display: block;
  width: 52px;
  padding-right: 10px;
  text-align: center;
}
.sierra-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.aurora-content-right {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.aurora-content-right>.ant-spin-nested-loading,
.aurora-content-right>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.aurora-content-top {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.aurora-content-top>.ant-spin-nested-loading,
.aurora-content-top>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.sierra-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.aurora-content .dividingLine {
  top: 0px;
}

.aurora-content>.ant-spin-nested-loading,
.aurora-content>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.sierra-setup-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #4e4e4e;
  z-index: 20;
  opacity: 0.5;
  border-radius: 5px 0 0 5px;
}

.sierra-setup-close .setup-close-icon {
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  cursor: pointer;
  opacity: 1;
}

.sierra-setup-close:hover {
  transition: 0.6s;
  opacity: 0.8;
}

.sierra-pcb-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #fff;
  opacity: 0.8;
  border-radius: 5px 0 0 5px;
}

.sierra-pcb-close .sierra-close-icon {
  font-size: 16px;
  color: #000;
  cursor: pointer;
  opacity: 1;
}

.sierra-pcb-close:hover {
  transition: 0.6s;
  opacity: 1;
}

.select-model {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.select-model-content {
  margin-right: 16px;
}

.select-model-last {
  padding-bottom: 5px;
}

.select-model-selection,
.pintopin--buffer-selection {
  float: left;
  width: 100%;
}

.select-dialog-close {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.select-dialog-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.select-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.signal-delete-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pintopin-power-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pintopin-signal-name-cell {
  margin-right: 22px !important;
  width: auto !important;
}

.sierra-PG-table tbody>tr>td:last-child,
.sierra-signal-nets-table tbody>tr>td:first-child {
  position: relative;
}

.sierra-PG-table tbody>tr>td:last-child>.editable-cell-value-wrap {
  position: unset;
}

.sierra-PG-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .pintopin-power-delete
.sierra-signal-nets-table .ant-table-small .ant-table-container .aurora-table-body .pintopin-signal-name-cell:hover .signal-delete-icon {
  color: #1890ff;
}

.sierra-PG-table .ant-table-small .ant-table-container .ant-table-header.ant-table-hide-scrollbar {
  padding-right: 22px;
}

.pintopin-channel-select {
  margin: 0 20px;
  width: 94px;
}

.sierra-table-comp {
  cursor: pointer;
  width: 100%;
}

.sierra-re-extraction {
  display: inline-block;
  padding-left: 34px;
  font-size: 13px;
  height: 28px;
  line-height: 28px;
}

.sierra-extraction-options-main .sierra-powesi-re-extraction{
  padding-left: 20px;
}

.sierra-re-extraction-switch {
  margin-left: 24px;
  margin-top: -2px;
}

.sierra-extraction-button {
  margin: 0px 20px 0px 10px;
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-top: -2px;
}

.sierra-extraction-option-error-msg {
  display: block;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 10px 16px 10px 20px;
}

.text-point-span {
  display: block;
  padding-left: 4px;
}

.simulation_options_error_tooltip .ant-tooltip-inner {
  color: #cb2431;
  background-color: #ffdce0;
  padding: 5px;
}

.simulation_options_error_tooltip .ant-tooltip-arrow::before {
  background-color: #ffdce0;
}

.simulation_options_error_tooltip {
  z-index: 3000;
  max-width: 300px;
}

.sierra-corner-row-disabled {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f3f3f3;
  cursor: not-allowed;
  text-indent: 13px;
  line-height: 38px;
  color: rgb(0, 0, 0, 0.45);
}

.sierra-component-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title,
.sierra-component-table .ant-table-thead>tr>th:nth-child(4) .ant-table-header-column .ant-table-column-title,
.sierra-component-table .ant-table-thead>tr>th:nth-child(5) .ant-table-header-column .ant-table-column-title,
.sierra-pin-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title,
.sierra-pin-table .ant-table-thead>tr>th:nth-child(4) .ant-table-header-column .ant-table-column-title,
.sierra-pin-table .ant-table-thead>tr>th:nth-child(5) .ant-table-header-column .ant-table-column-title {
  padding-left: 4px
}

.sierra-component-arrow {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #969696;
  width: 0;
  height: 0;
  position: absolute;
  right: 26px;
  bottom: 6px;
}

.sierra-comp-usage-rlc {
  margin: -4px 0;
  padding: 0 5px;
}

.merge-part-popover .ant-popover-content {
  z-index: 1000;
}

.merge-part-popover .ant-popover-content .ant-popover-inner-content {
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid#ffadd2;
  color: #eb2f96;
  background: #fff0f6;
}

.merge-part-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
}

.merge-part-popover .ant-popover-inner-content:hover .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
  bottom: 6.2px;
}

.sierra-part-name {
  padding-left: 4px;
  word-break: break-word;
}

.sierra-component-split-row,
.sierra-component-split-row>td {
  background-color: #e6ffed !important;
}

.sierra-component-split-row:hover>td,
.sierra-component-split-row:hover .editable-cell-value-wrap {
  background-color: #e6ffed !important;
}

.sierra-component-split-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.sierra-component-select-row,
.sierra-component-select-row>td {
  background-color: #adddfd !important;
}

.sierra-component-select-row:hover>td,
.sierra-component-select-row:hover .editable-cell-value-wrap {
  background-color: #adddfd !important;
}

.sierra-component-select-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.sierra-component-merge-row,
.sierra-component-merge-row>td {
  background-color: #fffbdd !important;
}

.sierra-component-merge-row:hover>td,
.sierra-component-merge-row:hover .editable-cell-value-wrap {
  background-color: #fffbdd !important;
}

.sierra-component-merge-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.sierra-probe-pins-setting {
  padding: 4px 10px;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: inline-block;
  margin-left: 14px;
}

.sierra-probe-pins-setting>.sierra-probe-pins-setting-icon {
  margin-right: 8px;
  vertical-align: middle;
  padding-top: 0px;
  display: inline-block;
}

.sierra-probe-pins-setting>.sierra-probe-pins-setting-span {
  vertical-align: middle;
  height: 24px;
}

.sierra-probe-pins-setting:hover {
  background-color: #edebe9;
}

.sierra-probe-pins-setting-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.sierra_power-nets-loading-msg {
  margin: 10px 0px;
  padding: 6px;
  background: #eee;
  border-radius: 6px;
  line-height: 24px;
  position: relative;
}

.sierra_power-nets-loading-msg .sierra_power-nets-loading-icon {
  position: absolute;
  top: 11px;
  right: 4px;
  font-size: 13px;
  cursor: pointer;
}

.sierra_power-nets-loading-success-msg {
  color: #33c783;
  background: #ddf7ed !important;
}

.sierra_power-nets-loading-failed-msg {
  color: #cb2431;
  background: #ffdce0 !important;
}

.sierra_power-nets-update-button {
  margin-right: 10px;
  float: right;
  padding: 0px 6px;
  height: 26px;
  line-height: 24px;
  font-size: 13px;
}

.sierra_power-nets-ignore-button {
  float: right;
  padding: 0px 6px;
  height: 26px;
  line-height: 24px;
  font-size: 13px;
}


/* usage apply all */

.sierra-copy-usage {
  display: inline-block;
  margin-left: 20px;
  line-height: 28px;
  height: 28px;
}

.sierra-copy-usage>.sierra-copy-usage-switch {
  margin-left: 10px;
}

.aurora-content .sierra-setup-normal {
  top: 45px;
}

.sierra-touchstone-result-main,
.sierra-result-waveform {
  overflow: hidden;
}

.sierra-touchstone-result-main .result-right-menu .result-file-list {
  margin-right: 0px;
}

.sierra-result-waveform .sierra-waveform-result-list {
  margin-right: 0px;
}

.sierra-content-bottom {
  background: #ffffff;
}

.sierra-tb-setting-main {
  position: absolute;
  top: 45px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

/* Sierra connection setup */
.sierra-connector-content,
.sierra-connector-content .ant-select {
  color: rgb(0, 0, 0, 0.85) !important;
}

.sierra-connector-content .connection-pin-line,
.sierra-connector-content .connection-pin-long-line {
  height: 2px;
  background-color: #1a72b9;
  transform: scaleY(1);
}

.sierra-connector-content .connector-model-first-box .connection-component-select-content,
.sierra-connector-content .connector-model-pinName-box .connection-pin-l-box,
.sierra-connector-content .connector-model-pinName-box .connection-pin-r-box,
.sierra-connector-content .connector-model-first-box .aurora-select .ant-select-selector,
.sierra-connector-content .connection-model-assign-content .connection-cable-model-content .connection-component-select-content>.connection-component-select-title,
.sierra-connector-content .connection-model-assign-content .connection-cable-model-content .connection-component-select-content .connection-single-select,
.sierra-connector-content .connection-pin-connect-port-item {
  font-size: 14px;
}

.sierra-connector-content .connection-pin-net-r-name,
.sierra-connector-content .connection-pin-net-l-name,
.sierra-connector-content .connection-pin-list-connection-signal-title {
  color: rgb(0, 0, 0, 0.85);
}


.sierra-connector-content .connection-pin-l-value-box,
.sierra-connector-content .connection-pin-r-value-box,
.sierra-connector-content .connection-pin-cable-value-box {
  height: 48px;
}

.sierra-connector-content .connection-channel-tile-title {
  margin: 0px;
}

.sierra-connector-content .connection-left-channel,
.sierra-connector-content .connection-right-channel,
.sierra-connector-content .connection-cable-model-content,
.sierra-connector-content .connection-model-assign-content .connector-model-first-box {
  border-width: 2px;
}

.sierra-connector-content .connection-title-tile-pcb {
  font-weight: 600;
}

.sierra-connector-content .connection-pin-connect-port-item .connection-pin-net-box {
  padding: 0px 2px;
  max-width: 50%;
}

.sierra-connector-content .connection-model-assign-content .connector-model-first-box {
  border-color: #d4d4d4;
}

.sierra-connector-content .connector-model-first-box .connection-component-select-content {
  margin-top: 0px;
}
li {
  list-style: none;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.sierra-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

/* my sierra monitor */

.my-sierra-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
  font-weight: 500;
}

.my-sierra-monitor-ccc {
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
  font-weight: 500;
}

.my-sierra-monitor-title {
  font-weight: bold;
  line-height: 44px;
  padding-left: 20px;
  float: left;
}

.my-sierra-middle {
  position: relative;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
}

.my-sierra-monitor-progress {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.monitor-upload-progress-bar {
  width: 100%;
}

.monitor-upload-progress-bar .ant-progress-text {
  font-weight: bold;
  vertical-align: unset;
}

.my-sierra-download-btn {
  position: relative;
  z-index: 20;
}

.my-sierra-debug-btn {
  position: relative;
  z-index: 20;
}

.design-msg-style {
  color: #f5222d;
  margin: 0;
  text-align: center;
}

.sierra-simulation-holygrail {
  position: absolute;
  right: 40px;
  left: 0;
  box-sizing: border-box;
}

.sierra-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.sierra-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 40px;
  margin-right: 12px;
}

.sierra-simulation-msg {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-width: 160px;
  text-align: center;
  margin-left: 16px;
  height: 44px;
}

.sierra-simulation-span {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
}

.sierra-workflow-msg {
  position: relative;
  float: left;
  height: 44px;
  line-height: 42px;
  text-align: center;
  min-width: 80px;
  margin-left: 20px;
}

.workflow-msg {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 28px;
  font-size: 16px;
}

.sierra-simulation-project {
  margin-right: 10px;
}

.span-msg,
.span-msg-project {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
}

.sierra-simulation-button,
.sierra-upload-button,
.sierra-cancel-button,
.sierra-kill-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.sierra-cancel-button {
  line-height: 43px;
}

.sierra-kill-button {
  line-height: 45px;
  font-size: 19px;
  margin-left: 18px;
}

.sierra-simulation-progress .ant-progress-outer {
  width: 90%;
}

.sierra-simulation-progress .ant-progress-outer .ant-progress-inner {
  background-color: #fff;
}

.sierra-simulation-progress .ant-progress-text {
  margin-left: 4px;
}

.icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

.span-msg {
  text-indent: 8px;
}

.sierra-monitor-ul {
  margin: 0;
  padding: 0;
}

.sierra-button {
  font-size: 18px;
}

.sierra-button-cancel {
  transform: rotate(90deg);
}

.debug-mes-span {
  display: block;
}

.closed-message {
  display: block;
}

.sierra-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 44px;
  color: #1890ff;
}

.sierra-button-icon {
  font-size: 22px;
}

.sierra-simulation-title .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.sierra-simulation-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.sierra-span-msg-tooltip {
  display: block;
  text-indent: 8px;
}

.sierra-update-pcb-box {
  padding: 10px 20px;
  font-size: 14px;
  line-height: 22px;
}

.sierra-update-pcb-span {
  padding-left: 10px;
  display: block;
}

.sierra-update-library-box {
  padding: 0px 20px;
  font-size: 14px;
  line-height: 22px;
  margin: 0px;
}

.sierra-update-library-box .sierra-warning-dialog {
  padding: 0px;
  margin: 0px;
  color: #303030;
  padding-top: 10px;
}

.sierra-simulation-middle .simulation-waiting-title {
  line-height: 42px;
  font-weight: bold;
  float: right;
}

.sierra-simulation-middle .simulation-waiting-title .waiting-time-span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
}

.sierra-monitor-backbottom {
  position: absolute;
  font-size: 18px;
  right: 40px;
  bottom: 40px;
  opacity: 0.6;
  background-color: #1890ff;
  color: #fff;
  transform: rotate(90deg);
  padding: 4px;
  border-radius: 4px;
}

.sierra-monitor-backbottom:hover {
  opacity: 0.8;
  font-weight: bold;
}

.sierra-stackup-error-dialog {
  padding-top: 10px;
  padding-left: 20px;
  color: #ff4848;
}

.macro-modeling-error-span {
  display: block;
  color: #ff4848;
  line-height: 22px;
  padding: 0px 30px;
}

.sierra-stackup-error-check-main {
  padding: 10px 20px;
  color: #ff4848;
}

.sierra-stackup-error-check-main .aurora-stackup-error-monitor-content {
  margin-left: 10px;
}

/* multi setup monitor  */
.multi-setup-monitor-title {
  font-weight: 600;
}

.multi-setup-monitor-logs,
.multi-setup-monitor-sub-logs {
  padding: 0px;
  padding-bottom: 2px;
}

.multi-setup-monitor-content {
  padding-left: 12px;
  padding-bottom: 10px;
}

.multi-setup-monitor {
  padding: 10px 12px;
}

.multi-setup-monitor-content .sierra-warning-dialog {
  padding: 0px;
  padding-bottom: 2px;
  margin-bottom: 0px;
}

.sierra-monitor-ul .sierra-monitor-pre-log,
.sierra-monitor-ul .sierra-monitor-li>p {
  margin: 0;
}

.sierra-monitor-ul .sierra-monitor-li {
  list-style: none;
  width: 100%;
}

.sierra-monitor-ul .sierra-monitor-li>.error {
  color: #cb2431;
  font-weight: 600;
}

.sierra-monitor-ul .sierra-monitor-li>.warning {
  color: #fa8c16;
}

.sierra-monitor-ul .sierra-monitor-li>.bold {
  font-weight: 600;
}
.sierra-warning-title {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
  color: #ff4848;
  font-weight: bold;
}

.error-font-bold {
  font-weight: bold;
}

.sierra-warning-dialog {
  padding: 10px 20px;
  color: #ff4848;
  overflow: unset;
}
.error-check-warning-dialog {
  padding: 10px 20px;
  color: #ff4848;
  overflow: unset;
}
.profile-table {
  min-width: 1000px;
}

.aurora-profile-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}
.footer-monitor {
    width: 100%;
    background: #f4f4f4;
    position: relative;
  }
li {
  list-style: none;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.my-ccc-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.my-ccc-backend-log {
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.ccc-simulation-holygrail {
  position: absolute;
  right: 100px;
  left: 0;
  box-sizing: border-box;
}

.ccc-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.ccc-debug-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  box-sizing: border-box;
  margin-right: 16px;
}

.ccc-button-icon {
  font-size: 22px;
}

.ccc_monitor {
  padding: 14px 20px;
  margin: 0;
  overflow: unset;
}

.ccc-task-list {
  display: inline-block;
  line-height: 44px;
  margin-left: 10px;
  height: 42px;
}

.ccc-status-list {
  display: inline-block;
  line-height: 44px;
  margin-left: 10px;
  height: 42px;
}

.ccc-server-task-span {
  display: inline-block;
  margin-right: 10px;
  line-height: 44px;
}

.ccc-server-task-span-popover {
  display: inline-block;
  margin-right: 10px;
  line-height: 36px;
  width: 45px;
}

.ccc-server-task-title {
  display: inline-block;
  margin-left: 16px;
  line-height: 44px;
  font-weight: 600;
  cursor: pointer;
}

.ccc-server-task-title-popover {
  display: inline-block;
  margin-left: 16px;
  line-height: 44px;
  font-weight: 600;
  cursor: pointer;
}

.ccc-server-task-title-popover:hover {
  color: #1890ff;
}

.ccc-server-select {
  width: 230px;
}

.ccc-page-server-select {
  min-width: 340px;
}

.ccc-task-select {
  width: 100px;
}

.ccc-task-select ., .ccc-server-select ., .ccc-page-server-select . {
  height: 28px;
  line-height: 28px !important;
  box-sizing: border-box;
}

.ccc-task-select . .ant-select-selector, .ccc-server-select . .ant-select-selector, .ccc-page-server-select . .ant-select-selector {
  height: 26px;
  line-height: 26px !important;
  box-sizing: border-box;
}

.ccc-task-kill-popover-button {
  width: 70px;
  height: 28px;
  line-height: 27px;
  padding: 0 8px;
  float: right;
  margin-top: 7px;
}

.ccc-task-kill-button {
  width: 70px;
  height: 28px;
  line-height: 27px;
  padding: 0 8px;
}

.ccc-task-kill-div {
  line-height: 44px;
  display: inline-block;
  margin-left: 10px;
}

.ccc-task-list-popover {
  line-height: 36px;
  margin: 0 10px;
  height: 36px;
}

.ccc-server-select-popover . {
  height: 28px;
  line-height: 28px !important;
  box-sizing: border-box;
}

.ccc-server-select-popover . .ant-select-selector {
  height: 26px;
  line-height: 26px !important;
  box-sizing: border-box;
}

.ccc-server-select-popover {
  width: 180px;
}

.ccc-popover .ant-popover-title {
  display: none;
}

.ccc-popover .ant-popover-inner-content {
  padding: 8px;
  height: 126px;
}

.ccc-debug-button {
  margin-right: 16px;
}

.ccc-debug-log-item {
  display: inline-block;
  box-sizing: border-box;
  border-right: 2px solid #e2dfdf;
  border-left: 2px solid #e2dfdf;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ccc-log-ul {
  margin: 0;
  overflow: unset;
  padding: 16px;
  padding-top: 10px;
  white-space: nowrap;
}

.ccc-type-span {
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.ccc-type-daemon-span {
  font-size: 15px;
  font-weight: 600;
  display: block;
  padding-bottom: 10px;
}

.ccc-type-close-button {
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  position: absolute;
  right: 0px;
  top: 4px;
  z-index: 20;
  background: #4e4e4e;
  z-index: 20;
  opacity: 0.5;
  border-radius: 4px 0 0 4px;
}

.ccc-type-close-button .ccc-close-icon {
  font-size: 14px;
  color: #fff;
  line-height: 22px;
  cursor: pointer;
  opacity: 1;
}

.ccc-type-close-button:hover {
  transition: 0.6s;
  opacity: 0.8;
}

.ccc-daemon-title {
  margin: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  padding: 10px 16px
}

.ccc-worker-select {
  width: 112px;
}

.ccc-daemon-list {
  display: inline-block;
  line-height: 30px;
  margin: 6px 10px;
  height: 30px;
}

.ccc-daemon-list-switch {
  display: inline-block;
  line-height: 24px;
  height: 30px;
  vertical-align: middle;
  margin: 6px 10px;
}

.ccc-daemon-span {
  display: inline-block;
  line-height: 30px;
  height: 30px;
  width: 57px;
}

.ccc-worker-select . {
  height: 26px;
  line-height: 26px !important;
  box-sizing: border-box;
}

.ccc-worker-select . .ant-select-selector {
  height: 24px;
  line-height: 24px !important;
  box-sizing: border-box;
}

.ccc-log-div {
  overflow: auto;
  bottom: 0;
  top: 32px;
  position: absolute;
  left: 0;
  right: 0;
  border: 2px solid #e4e4e4;
  border-radius: 4px;
  color: #000000cc;
}

.ccc-daemon-box {
  height: 100%;
  margin-left: 10px;
  position: relative;
}

.ccc-daemon-div {
  overflow: auto;
  /* position: absolute; */
  bottom: 0;
  top: 72px;
  left: 0;
  right: 0;
  border: 2px solid #e4e4e4;
  border-radius: 4px;
  background: #ffffff;
  color: #000000cc;
  height: 100%;
}

.ccc-daemon-msg {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

.ccc-daemon-switch {
  vertical-align: text-top;
}

.task-table-status {
  display: inline-block;
  width: 100%;
  position: relative;
}

.task-table-status .icon-cancel9 {
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
  position: absolute;
  top: -3px;
  left: 40px;
}

.ccc-delete-pop-confirm .task-table-status-remove {
  display: block;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 6px;
}


.ccc-delete-title {
  display: block;
  font-size: 14px;
  color: #f12727;
}

.ccc-delete-confirm {
  color: #1890ff;
}

.ccc-delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.ccc-delete-pop-confirm .ant-popover-buttons {
  display: none;
}

.ccc-delete-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
}

.ccc-delete-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.ccc-delete-pop-confirm .ant-popover-message>.ccc-confirm-icon {
  display: none;
}

.ccc-delete-pop-confirm .ant-popover-message>.ccc-confirm-icon {
  display: none;
}

.ccc-delete-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.table-status-remove-content {
  width: 221px;
}
.specify-service-content {
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
  padding: 10px 20px;
  background: #ffffff;
  overflow: auto;
}

.specify-service-main {
  width: 100%;
  max-width: 1000px;
  min-width: 350px;
}

.specify-service-title {
  width: 100%;
  text-align: center;
  line-height: 32px;
  font-weight: 600;
}

.specify-service-project-title {
  height: 44px;
  width: 100%;
}

.specify-service-project-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.specify-service-project-title .span-msg-icon-div .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.specify-service-content .specify-service-item {
  width: 100%;
  margin: 5px 0px;
}

.specify-service-content .specify-service-item .aurora-select {
  width: calc(100% - 92px);
  vertical-align: middle;
}

.specify-service-content .specify-service-item .aurora-select,
.specify-service-content .specify-service-item .aurora-select .ant-select-selector {
  height: auto;
}

.specify-service-content .specify-service-item>span {
  display: inline-block;
  width: 92px;
  vertical-align: middle;
}

.specify-service-content .specify-service-button {
  width: 120px;
  height: 28px;
  line-height: 27px;
  padding: 0 8px;
}

.specify-service-content .specify-service-button-content {
  margin-top: 10px;
  height: 28px;
  text-align: center;
}

.specify-service-content .specify-service-task-content {
  position: relative;
}

.specify-service-content .ant-divider {
  margin: 10px 0px;
}

.specify-service-content .specify-service-task-content:hover .specify-service-delete-task-icon {
  color: #3da4fa;
}

.specify-service-content .specify-service-add-task-icon {
  margin-left: 8px;
  color: #3da4fa;
  font-size: 14px;
}

.specify-service-content .specify-service-delete-task-icon {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  right: -15px;
  top: 8px;
}

.specify-service-content .specify-service-message-success-content {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.specify-service-content .specify-service-message-failed-content {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.specify-service-content .specify-service-message-content {
  border-radius: 4px;
  padding: 4px 8px;
}

.specify-service-content .specify-service-task-content .specify-service-item>.server-title {
  width: 100%;
  font-weight: 600;
}

.specify-service-content .specify-service-task-content .specify-service-sub-item {
  padding-left: 20px;
}
/* download debug file */
.download-debug-ul-title {
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  background: #e0e0e0;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  padding-right: 30px;
}

.download-debug-ul-title:hover {
  background: #c5c5c5;
  transition: all 0.3s;
}

.download-debug-box {
  padding: 10px;
}

.download-debug-box ul {
  margin: 0px;
  padding: 0px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
}

.download-debug-box li {
  padding-left: 20px;
  height: 24px;
  line-height: 24px;
  padding-right: 10px;
  position: relative;
  background: #f9f9f9;
  transition: all 0.3s;
}

.download-debug-box li:hover {
  background: #eaeaea;
  transition: all 0.3s;
}

.debug-download-title-icon,
.debug-download-file-icon {
  position: absolute;
  right: 10px;
  top: 6px;
}

.download-debug-panel {
  top: 54px;
  margin-left: 0px;
  left: -9999px;
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
  box-sizing: content-box;
}

.download-debug-panel .panel-modal-header {
  padding: 4px 20px !important;
}

.download-debug-panel .panel-modal-close-x {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 10px;
}

.download-debug-panel .panel-body {
  max-height: 400px;
}

.pd-10 {
  padding: 0 10px;
}

.debug-download-file-name {
  min-width: 100px;
  width: 70%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.debug-download-size-name {
  width: 30%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.download-debug-box .anticon-check {
  color: rgb(77, 189, 22);
}

.download-debug-box .anticon-close {
  color: #ff4848;
}
.aurora-sider-footer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 42px;
  border-top: 1px solid #e2dfdf;
}

.aurora-sider-footer .view-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.aurora-sider-footer .layout-icon-disabled .aurora-layout-button-right,
.aurora-sider-footer .layout-icon-disabled .aurora-layout-button-top {
  background: #c5c5c5;
}

.aurora-sider-footer .layout-icon-disabled {
  border: 1px #c5c5c5 solid;
  color: #a0a0a0;
  cursor: not-allowed;
}

.aurora-sider-footer .sider-switch-icon {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  color: #737373;
}

.aurora-sider-footer .sider-switch-vertical-icon {
  padding: 0 17px;
  padding-bottom: 4px;
}

.aurora-sider-footer .pcb-icon {
  font-size: 25px;
  line-height: 32px;
  cursor: pointer;
}

.aurora-sider-footer .sider-switch-vertical-icon .pcb-icon {
  margin-left: -3px;
}

.aurora-sider-footer .sider-layout-div {
  display: inline-block;
}

.aurora-sider-footer .sider-layout-vertical-div {
  padding: 0 10px;
  padding-bottom: 4px;
}

.aurora-sider-footer .sider-layout-vertical-div .aurora-layout-button-1 {
  margin-bottom: 9px;
  margin-top: 3px;
}

.aurora-sider-footer .sider-layout-vertical-div .aurora-layout-button-2 {
  margin-bottom: 4px;
}

.aurora-switch-icon-selected {
  color: #0d87f7 !important;
}

.pcb-split-icon-box .aurora-layout-button-1 {
  width: 30px;
  height: 30px;
  line-height: 28px;
  margin-left: 3px;
  margin-right: 5px;
}

.pcb-split-icon-box .aurora-layout-button-2 {
  height: auto;
  width: 30px;
  margin-left: 3px;
  margin-right: 5px;
}

.pcb-split-icon-box .aurora-layout-button-left,
.pcb-split-icon-box .aurora-layout-button-right {
  line-height: 28px;
  color: #444444;
}

.pcb-split-icon-box .aurora-layout-button-bottom,
.pcb-split-icon-box .aurora-layout-button-top {
  line-height: inherit;
  color: #444444;
}

.pcb-split-icon-box .aurora-layout-button-3 {
  width: 30px;
  height: 30px;
  border: 1px #a5a5a5 solid;
  border-radius: 2px;
  line-height: 28px;
  text-align: center;
  margin-left: 3px;
  color: #444444;
  font-size: 10px;
}

.pcb-split-icon-box .aurora-layout-button-3.aurora-layout-selected {
  border-color: #63aef3;
  border-style: solid;
  background: #85bcff;
}
.unit-select {
  float: right;
  width: 70px;
}

.unit-span {
  line-height: 32px;
}

.decap-panel,
.VRM-panel,
.SPIM-panel,
.library-data-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}


.decap-rlc-box,
.vrm-content-box,
.library-data-panel-content-box {
  padding: 16px;
}

.model-input {
  display: block;
}

.rlc-input {
  margin: 16px 0;
  display: block;
}

.rlc-input .ant-input-group-addon .ant-select-selection {
  width: 68px !important;
}

.model-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.decap-panel {
  margin: 0 auto;
}
.my-sierra-layout {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.aurora-content {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 240px;
  right: 0px;
  bottom: 0px;
}

.my-sierra-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-sierra-content>.ant-spin-nested-loading,
.my-sierra-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.sierra-sider {
  position: relative;
  width: 240px;
  background: #f9f9f9;
  height: 100%;
  border-right: 1px solid #e2dfdf;
}

.sierra-sider-footer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 42px;
  border-top: 1px solid #e2dfdf;
}

.sierra-close-box {
  float: left;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  color: #0d87f7;
  text-align: center;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.sierra-sider-layout {
  line-height: 36px;
  font-size: 19px;
}

.sierra-sider-footer .sierra-close-box:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.sierra-sider-footer .sierra-sider-monitor {
  line-height: 34px;
  font-size: 22px;
}

.mg-r-8 {
  margin-right: 8px;
}

.project-name-input .ant-input-group {
  float: left;
  width: 100%;
}

.project-name-input .project-name {
  height: 24px;
}

.project-name-input .project-name .ant-input {
  height: 24px;
}

.tree-node .ant-tree-title>.verification-name-input {
  position: absolute;
  right: 8px;
  left: 0px;
  top: 8px;
  width: auto;
}

.verification-name-input .ant-input-group {
  float: left;
  width: 100%;
}

.verification-name-input .ant-input-group>input {
  height: 24px;
}

.sierra-sider-menu-tree .ant-tree-title .verification-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.project-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.sierra-tooltip-span {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.anticon.anticon-exclamation-circle.debug-design-icon {
  visibility: initial;
  font-size: 15px;
  line-height: 36px;
  color: #ff0000;
}

span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .debug-design-icon {
  right: 36px;
}

.aurora-design-tooltip .ant-tooltip-inner {
  color: #ff0000;
}

.sierra-debug-tooltip-span {
  display: inline-block;
  max-width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #ff0000;
}

.sierra-create-name {
  width: 100%;
}

.sierra-sider-menu-tree .ant-tree-title .ant-checkbox-wrapper {
  position: absolute;
  left: 3px;
  top: 2px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-project-title {
  padding-right: 72px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-sweep-title {
  padding-right: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sierra-sider-menu-tree .ant-tree-title .sierra-project-title,
.sierra-sider-menu-tree .ant-tree-title .sierra-sweep-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.sierra-sider-menu-tree .ant-tree-title:hover .simulation-btn-disable,
.sierra-sider-menu-tree .ant-tree-title:hover .simulation-btn {
  right: 38px;
}

.result-style {
  padding-left: 12px;
  font-style: italic;
}

.sierra-delete-content {
  width: 196px;
}

.sierra-delete-title {
  display: block;
  font-size: 14px;
  color: #f12727;
}

.sierra-delete-confirm {
  color: #1890ff;
}

.sierra-delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.sierra-delete-pop-confirm .ant-popover-buttons {
  display: none;
}

.sierra-delete-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
}

.sierra-delete-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.sierra-delete-pop-confirm .ant-popover-message>.sierra-confirm-icon {
  display: none;
}

.sierra-delete-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.sierra-delete-pop-confirm .sierra-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.sierra-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.sierra-clean-pop-confirm .ant-popover-buttons .ant-btn {
  display: none;
}

.sierra-clean-pop-confirm .ant-popover-buttons .ant-btn-primary {
  display: inline-block;
}

.sierra-clean-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
  width: 204px;
}

.sierra-clean-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.sierra-clean-pop-confirm .ant-popover-message>.sierra-confirm-icon {
  display: none;
}

.sierra-clean-pop-confirm .ant-popover-message-title {
  padding-left: 0;
  display: block;
  font-size: 14px;
  color: #f12727;
}

.sierra-clean-pop-confirm .sierra-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.sierra-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.sierra-sider-menu {
  margin: 12px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.sierra-sider-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.sierra-layout-msg {
  font-size: 12px;
  margin-top: 10px;
  vertical-align: middle;
}

.sierra-layout-msg>span {
  display: inline-block;
}

.sierra-layout-msg>span:nth-child(1) {
  margin-right: 6px;
}

.sierra-layout-popover .ant-popover-inner-content {
  padding: 6px;
}

.sierra-sider-menu-tree .ant-tree-title .anticon {
  position: absolute;
  right: 14px;
  visibility: hidden;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-status-icon {
  visibility: visible;
  position: absolute;
  right: 68px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-status-icon {
  right: 24px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-loading-icon {
  visibility: visible;
}

.sierra-sider-menu-tree .ant-tree-title:hover .copy-loading-icon {
  visibility: visible;
  right: 14px !important;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-loading-icon {
  visibility: visible;
  right: 36px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .anticon {
  visibility: visible;
}

.sierra-sider-menu-tree .ant-tree-title .project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.sierra-icon-color:hover {
  color: #1890ff;
}

.sierra-view-popover .ant-popover-inner-content {
  padding: 0;
}

.sierra-switch-icon {
  display: inline-block;
  font-weight: 500;
  padding: 0 6px;
  cursor: pointer;
  font-size: 18px;
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
  color: #737373;
}

#sierra-switch-icon-selected {
  color: #0d87f7;
}

.sierra-sider-footer .view-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.sierra-sider-footer .layout-icon-disabled {
  border: 1px #c5c5c5 solid;
  color: #a0a0a0;
  cursor: not-allowed;
}

.sierra-verification-name-span {
  cursor: pointer;
}

.sierra-verification-status {
  display: block;
}

.sierra-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-tree-info-icon {
  margin-right: 22px;
}

.sierra-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-status-span,
.sierra-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-status-icon {
  right: 58px;
}

.sierra-sider .aurora-status-span {
  right: 64px;
  height: 24px;
  line-height: 22px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-replace-icon {
  visibility: hidden;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-replace-icon {
  visibility: visible;
  margin-right: 0px;
}


.sierra-sider-menu-tree .ant-tree-title:hover .tree-library-content .aurora-replace-icon {
  visibility: visible;
  margin-right: 0px;
  right: 36px;
  position: absolute;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-icon {
  position: absolute;
  right: 21px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-icon {
  position: absolute;
  right: 21px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-icon {
  right: 48px;
}

.sierra-copy-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.sierra-copy-name {
  width: 283px;
}

.sierra-copy-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.sierra-copy-popover {
  width: 300px;
}

.sierra-copy-popover .ant-popover-inner-content {
  padding: 8px;
}

.sierra-sider-menu-tree .tree-node-interface-name .ant-tree-title {
  padding-left: 22px;
  padding-right: 22px;
}

.sierra-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover {
  padding-right: 24px;
}

.sierra-sider-menu-tree .tree-node-interface-name .tree-node-interface-result-name .ant-tree-title {
  padding-left: 2px;
}

.sierra-verification-tooltip {
  display: inline-block;
  width: 66%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
}

.sierra-sider-menu-tree .tree-node-experiment-name .ant-tree-title {
  padding-left: 0px;
  padding-right: 0px;
}

.sierra-sider .sierra-sider-menu-tree .tree-node-experiment-name .ant-tree-switcher {
  margin-left: 14px;
  line-height: 22px !important;
}

.sierra-sider-menu-tree .tree-node-experiment-name .ant-tree-title:hover {
  padding-right: 0px;
}

.sierra-sider-menu-tree .tree-node-experiment-result-name {
  font-style: oblique;
}

/* .sierra-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover .aurora-status-span {
  right: 38px;
} */

.sierra-sider-menu-tree .verification-create-node .ant-tree-title:hover,
.sierra-sider-menu-tree .experiments-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.sierra-sider-menu-tree .experiments-create-node .ant-tree-title {
  padding-left: 0px;
  padding-right: 0px;
}

.sierra-sider-menu-tree .project-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.sierra-sider-menu-tree .folder-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.sierra-sider-menu-tree .tree-node-pcb-name .tree-node-pcb-replace-icon {
  position: absolute;
  right: 24px;
}

.sierra-sider-menu-tree .tree-node-pcb-name .ant-tree-title:hover .tree-node-pcb-replace-icon {
  position: absolute;
  right: 60px;
}

.sierra-sider-menu-tree .tree-node-pcb-name .ant-tree-title:hover {
  padding-right: 90px;
}

.sierra-sider-menu-tree .ant-tree-title {
  cursor: pointer;
  height: 32px;
}

.sierra-sider-menu-tree .ant-tree-title .tree-library-content {
  height: 32px;
  line-height: 30px;
}

.sierra-sider-menu-tree .simulate-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.sierra-sider-menu-tree .ant-tree-title .anticon-play-circle {
  visibility: visible;
  transition: right .2s linear;
  right: 38px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-batch-delete-icon {
  visibility: visible;
  transition: right .2s linear;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-combine-interface-icon {
  visibility: visible;
  transition: right .2s linear;
  right: 63px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .anticon-play-circle {
  right: 63px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-batch-delete-icon {
  right: 38px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-combine-interface-icon {
  right: 88px;
}

.sierra-sider-menu-tree .ant-tree-title .tree-library-content .sierra-touchstone-status-icon {
  text-align: center;
  line-height: 31px;
  font-size: 15px;
  color: #f13140;
  position: absolute;
  right: 14px;
  visibility: visible;
  height: 31px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .tree-library-content .sierra-touchstone-status-icon {
  right: 62px;
}

.sierra-sider-menu-tree .ant-tree-title .sierra-pdn-tree-loading-icon {
  color: #1890ff;
  float: right;
}

.sierra-sider-menu-tree .ant-tree-title .tree-library-content .sierra-library-touchstone-file-span {
  padding-right: 30px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .tree-library-content .sierra-library-touchstone-file-span {
  padding-right: 42px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-library-file-span {
  padding-right: 47px;
}

.sierra-sider .sierra-sider-menu-tree .ant-tree-title:hover .sierra-default-library-file-span {
  padding-right: 68px;
}

.sierra-sider-menu-tree .ant-tree-title .tree-library-content .aurora-macro-icon {
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  color: #ff5500;
  position: absolute;
  right: 14px;
  visibility: visible;
}

.sierra-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-macro-icon {
  right: 64px;
}

/* folder */
.sierra-folder-title {
  font-weight: 600;
}

.sierra-library-folder .aurora-tree-add-icon {
  margin-right: 24px;
}

.sierra-library-folder .sierra-folder-icon {
  position: unset !important;
  visibility: visible !important;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.65);
}

.sierra-sider-menu-tree .tree-node-add-interface-name .ant-tree-node-content-wrapper .ant-tree-title:hover {
  padding-left: 0px;
  padding-right: 0px;
}

.sierra-sider-menu-tree .tree-node-add-interface-name .ant-tree-node-content-wrapper .ant-tree-title .aurora-add-interface-icon {
  position: static;
  visibility: visible;
  font-size: 11px;
  color: #ff610b;
  vertical-align: top;
  height: 20px;
  line-height: 22px;
}

.sierra-sider-menu-tree .tree-node-add-interface-name .ant-tree-node-content-wrapper {
  margin-left: -11px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-interface-type-tag {
  display: none;
}

.sierra-sider-menu-tree .aurora-interface-type-tag {
  right: 4px;
}

.sierra-sider-menu-tree .ant-tree-title .sierra-pcb-setting-icon {
  visibility: hidden;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-pcb-setting-icon {
  visibility: visible;
  margin-right: 0px;
  position: absolute;
  right: 84px;
}

.tree-node .ant-tree-title>span.sierra-add-interface-content {
  width: 43%;
  margin-right: 17px;
  line-height: 20px;
  font-size: 12px;
  height: 22px;
}

.tree-node .ant-tree-title>span.sierra-search-interface-content {
  width: 43%;
  line-height: 20px;
  font-size: 12px;
  height: 22px;
}

.sierra-sider-menu-tree .ant-tree .tree-node-add-interface-name .ant-tree-node-content-wrapper:hover {
  background-color: #f9f9f9;
}

.aurora-tree-create-loading-icon {
  margin-right: 15px;
  line-height: 18px;
}

.sierra-sider-menu-tree .tree-node-add-interface-name .ant-tree-title {
  cursor: unset;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-multi-interface-setup-icon {
  visibility: visible;
  transition: right .2s linear;
  right: 88px;
  color: #a0a0a0;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-multi-interface-setup-open-icon {
  color: #1890ff;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-multi-interface-setup-icon {
  right: 112px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-set-icon {
  right: 60px;
}

.sierra-sider-menu-tree .sierra-library-default-tag {
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -11px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-library-default-tag {
  display: none;
}

#sierra-content-main,
#sierra-top-bar,
.sierra-sider,
.my-sierra-layout,
.my-sierra-layout .ant-table,
.my-sierra-layout .ant-tree li .ant-tree-node-content-wrapper,
.my-sierra-layout .ant-tabs,
.my-sierra-layout .ant-dropdown-menu-item,
.sierra-panel .panel-ant-modal-body,
.sierra-panel .ant-input,
.my-sierra-layout .ant-input,
.my-sierra-layout .ant-select,
.sierra-panel .panel-ant-modal-body .ant-input,
.sierra-panel .panel-ant-modal-body .ant-select,
.sierra-panel .ant-modal-footer,
.sierra-panel .ant-tabs,
.sierra-panel .ant-dropdown-menu-item,
.sierra-panel .ant-table,
.sierra-panel .sierra-extraction-content-body,
.sierra-panel .sierra-extraction-panel-content,
.sierra-panel .ant-checkbox-wrapper,
.my-sierra-layout .ant-checkbox-wrapper,
.sierra-panel .ant-radio-wrapper,
.my-sierra-layout .ant-radio-wrapper,
.sierra-panel .sierra-debug-download-menu-btn,
.my-sierra-layout .ant-radio-group,
.sierra-panel .ant-radio-group,
.my-sierra-layout .ant-input-group-addon,
.sierra-panel .ant-input-group-addon,
.sierra-panel .ant-table-thead>tr>th,
.my-sierra-layout .ant-table-thead>tr>th,
.sierra-model-dialog .ant-input,
.sierra-model-dialog .ant-input-group-addon,
.sierra-model-dialog .ant-select,
.sierra-panel .ant-select-single.ant-select-sm .ant-select-selector,
.sierra-panel .ant-select .ant-select-selector {
  /*  font-weight: 600; */
  color: rgb(0, 0, 0, 0.85);
}

/* .my-sierra-layout .ant-btn-link,
.sierra-panel .ant-modal-body .ant-btn-link,
.my-sierra-layout .my-ccc-monitor {
  font-weight: 500;
} */

.my-sierra-layout .icon-Debug,
.my-sierra-layout .icon-icons8-circuit,
.my-sierra-layout .icon-setup,
.my-sierra-layout .pcb-icon,
.my-sierra-layout .icon-Monitor,
.my-sierra-layout .my-tree-switch-icon,
.my-sierra-layout .icon-cancel9 {
  font-weight: 500;
}

.my-sierra-layout .sierra-sider .my-tree>li>ul>li>span {
  color: rgb(0, 0, 0, 0.65);
}


.sierra-interface-type-input {
  width: 34%;
  height: 24px;
}

/* .sierra-interface-type-input .ant-select-selector {
  margin-left: 6px;
  margin-right: 16px;
} */

.sierra-interface-type-input .ant-select-arrow {
  right: 2px;
  font-size: 10px;
}

.sierra-interface-type-input .ant-select-arrow .anticon {
  visibility: visible;
  right: 3px;
}

.sierra-interface-type-input .ant-select-selector .ant-select-selection-item {
  font-size: 12px;
  padding-inline-end: 0px;
}

.sierra-sider-menu-tree .ant-tree-title .sierra-interface-create-input {
  width: 66%;
}

.sierra-sider-menu-tree .tree-node .ant-tree-title>.verification-name-input {
  top: 0px;
}

.sierra-panel.component-RLC-panel.component-PMIC-panel .classification-right .component-animation-classfication {
  height: 100%;
}
.stackup-table-prev .ant-table-footer {
  padding: 8px 0;
}

.stackup-table-prev .aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap {
  background-color: rgba(0, 0, 0, 0);
}

.stackup-table-prev .ant-table-footer div {
  margin-left: 76px;
  font-weight: bold;
}

.stackup-table-prev .ant-table-container .ant-table-header>table>.ant-table-thead>tr>th {
  background: #fafafa;
}

.stackup-input-group {
  width: 160px;
  float: left;
}

.stackup-input-group .ant-select-selection-selected-value {
  padding-right: 0px;
}

.stackup-thickness-name {
  float: left;
  margin-left: 0px;
  margin-right: 8px;
}

.stackup-input-col,
.stackup-input-row {
  line-height: 32px;
}

.stackup-input-col {
  float: left;
}

.stackup-set-name {
  margin: 0px 8px 0 16px;
}

.stackup-footer-value-setting {
  height: 32px;
  line-height: 32px;
  width: 100%;
  text-align: left;
  margin: 0px;
}

.stackup-set-button {
  margin-left: 8px;
  margin-right: 8px;
}

.stackup-clear {
  zoom: 1
}

.stackup-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.stackup-dropdown-button .ant-dropdown-menu-item button {
  width: 140px;
  padding-left: 15px;
  text-align: left;
  border: 0px;
}

.stackup-menu-button {
  padding: 0px;
  vertical-align: middle;
}

.stackup-dropdown-button .ant-dropdown-menu-item {
  padding: 0px;
}

.stackup-dropdown-button .ant-dropdown-menu-item button:hover {
  background-color: #e6f7ff
}

.stackup-conductivity {
  width: 160px;
}

.stackup-unit-selection {
  height: 26px;
}

.stackup-unit-selection .ant-select-selector {
  line-height: 24px;
}

.stackup-unit-selection .ant-select-arrow {
  right: 6px;
}

.stackup-table-edit div {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.stackup-table-edit div+div {
  margin-top: 4px;
}

.stackup-table-edit .anticon-vertical-align-top,
.stackup-table-edit .anticon-vertical-align-bottom {
  font-size: 16px;
}

.stackup-table-edit .anticon-close {
  font-size: 13px;
}

.stackup-table tr td:first-child>div {
  cursor: pointer;
}

.stackup-table-row-light {
  background-color: #f3eecf;
}

.stackup-total-thickness-content {
  display: flex;
  width: 100%;
  margin-left: 0px !important;
  justify-content: space-around;
  font-weight: bold;
}

.stackup-out-dielectric-color {
  background-color: #cfeb7e;
}

.stackup-all-metal-color {
  background-color: #f9c236;
}

.stackup-in-dielectric-color {
  background-color: #fffecf;
}

.stackup-out-dielectric-color .editable-cell-value-wrap {
  border-color: #9b9b9b;
}

.stackup-all-metal-color .editable-cell-value-wrap {
  border-color: #d1d1d1;
}

.stackup-in-dielectric-color .editable-cell-value-wrap {
  border-color: #d8d8d8;
}

.stackup-all-layer>td {
  border-right: 1px solid #d3d3d3 !important;
  border-bottom: 1px solid #d3d3d3 !important;
}

.stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #cce779 !important;
}

.stackup-all-metal-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #fdcb4c !important;
}

.stackup-in-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #ffffbc !important;
}
/*TODO*/

.simulation-progress .ant-progress-inner {
  background: #ddd;
}

.simulation-progress .ant-progress-text {
  color: rgba(0, 0, 0, 0.6);
}

.setting-signal-expand {
  margin: 0px !important;
}

.setting-signal-expand .ant-table-small > .ant-table-content > .aurora-table-body {
  padding: 0px;
  margin: 0px;
}

.setting-signal-expand .ant-table-small,
.setting-signal-expand .ant-table-small.ant-table-bordered .ant-table-content {
  border: 0px !important;
}

.component-pin-tabs .ant-tabs-bar {
  margin: 0px;
}

.pintopin-signal-row {
  margin-top: 20px;
}

.component-pin-tabs .ant-tabs-tab {
  font-weight: bold;
}

.pintopin-warning-dialog {
  padding: 10px;
  border: 2px solid #ff9898;
  border-radius: 4px;
  background: #f9f9f9;
  position: absolute;
  top: 45px;
  bottom: 6px;
  width: 98%;
}

.pintopin-warning-title {
  color: #ff4848;
  margin-top: 10px;
  display: inline-block;
}

.footer-monitor {
  width: 99%;
  background: #f4f4f4;
  min-width: 980px;
}

.log-box {
  float: right;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.monitor-log {
  overflow: auto;
  border: 2px solid rgb(182, 182, 182);
  margin-top: 10px;
  background: #fafafa;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  top: 35px;
  left: -2px;
  right: -10px;
}

.error-box {
  width: 49%;
  height: 100%;
  position: absolute;
}

.closed-message {
  display: inline-block;
  width: 100%;
}

.debugButton {
  position: relative;
  z-index: 2;
}

.logButton {
  position: relative;
  z-index: 2;
}

.button-content {
  text-indent: 4px;
}

.button-icon {
  font-size: 18px;
}

.span-tooltip {
  display: inline-block;
  width: 100%;
}

.verify-footer-name {
  font-weight: bold;
  line-height: 45px;
  display: block;
  float: left;
}

.icon-explores {
  color: #0055a2;
  font-size: 20px;
  font-weight: bolder;
  font-family: serif;
  display: block;
  float: right;
  line-height: 20px;
}

.andes-component-table .ant-table-thead > tr > th .ant-table-header-column,
.andes-pin-table .ant-table-thead > tr > th .ant-table-header-column {
  display: block !important;
}

.pintopin-pin-corner-box {
  margin: -4px;
}

.pintopin-pin-corner {
  padding: 0 5px;
  line-height: 36px;
}

.pintopin-pin-corner-explor {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 6px;
  transition: 0.5s all;
  height: 14px;
  line-height: 16px;
}

.andes-pin-table tbody > tr > td:nth-child(6),
.andes-pin-table tbody > tr > td:nth-child(5),
.andes-component-table tbody > tr > td:nth-child(4) {
  position: relative;
}

.pintopin-pin-corner-cell,
.pintopin-pin-stimulus-cell {
  margin-right: 26px !important;
  width: auto !important;
}

.component-pin-tabs .ant-tabs-tab,
.component-pin-tabs .ant-tabs-tab-active {
  font-weight: bold !important;
}

.log-button {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}

.download-button {
  text-indent: 24px;
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.info-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.title-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  float: left;
}

.pcb-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.verify-content-footer {
  overflow: auto;
}

.verify-footer-button {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.progress-footer-span {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
}

.debug-footer-col {
  padding-left: 30px !important;
  text-align: center;
}

.log-footer-col {
  padding-left: 5px !important;
  text-align: center;
}

.result-footer-col {
  padding-left: 0 !important;
  text-align: center;
}

.verify-footer-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.btn-width {
  width: 20%;
  max-width: 80px;
  margin-left: 10px;
  display: block;
  float: left;
}

.btn-width button {
  width: 100%;
}

.btn-width .btn-width-tooltip {
  width: 100%;
}

.monitor-button {
  min-width: 629px;
}

.monitor-top {
  margin: 0px;
  padding: 0px;
  line-height: 45px;
}

.monitor-top-col {
  padding: 0px;
}

.btn-width-12 {
  width: 12%;
}

.btn-width-max-120 {
  max-width: 110px;
}

.verify-cancel-btn {
  background: #797979;
  border: 0px;
  color: #f4f4f4;
}

.verify-cancel-btn:hover,
.verify-cancel-btn:focus {
  background: #969696;
  color: #ffffff;
}

.signal-expand-icon {
  position: absolute;
  right: 20px;
}

/* ============== PinToPin setting =================*/

.andes-content-setting {
  padding: 20px;
  min-width: 900px;
}

.simulation-config-item {
  display: inline-block;
  line-height: 32px;
}

.width-33 {
  width: 33%;
}

.width-25 {
  width: 25%;
}

.simulation-option-select {
  width: 50%;
}

.simulation-config-name {
  font-weight: bold;
  margin-right: 10px;
}

.verify-setup-input-select {
  width: 60%;
}

.verify-setup-input {
  width: 30%;
}

.andes-signal-nets-table {
  margin-top: 10px;
}

.andes-select-dropdown-menu .ant-select-dropdown-menu-item {
  position: relative;
  padding-left: 30px;
}

.andes-select-dropdown-menu
  .ant-select-dropdown-menu-item
  .ant-select-selected-icon {
  text-align: left !important;
  left: 10px !important;
  font-weight: bold;
}

.andes-signal-nets-table .editable-cell-value-wrap,
.andes-component-table .editable-cell-value-wrap,
.andes-PG-table .editable-cell-value-wrap,
.andes-power-comps-table .editable-cell-value-wrap,
.andes-pin-table .editable-cell-value-wrap,
.andes-connector-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-signal-nets-table .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-signal-nets-table tbody > tr > td > span {
  display: block;
  max-height: 80px;
  overflow: auto;
}

.andes-signal-nets-table tbody > tr > td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.andes-signal-nets-table
  tbody
  > tr
  > td
  .ant-select-selection--multiple
  .ant-select-selector
  > ul
  > li {
  height: 24px;
  line-height: 24px;
}

.margin-top-20 {
  margin-top: 20px;
}

.andes-pin-table .usage-repeater {
  padding: 0 5px;
}

.andes-decap-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.andes-decap-close {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.andes-decap-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.andes-model {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.andes-model-last {
  padding-bottom: 5px;
}

.andes-model-selection {
  float: left;
  width: 100%;
}

.andes-model-input {
  margin-top: 5px;
}

.andes-pcb-content {
  background: #f2f7fa;
  padding: 20px;
  padding-top: 0;
  position: relative;
  margin-top: 20px;
}

.andes-pcb-menu {
  background: #f2f7fa;
  margin-left: 48px;
}

.andes-component-table,
.andes-pin-table,
.andes-power-comps-table {
  background: #ffffff;
}

.andes-setup-pcb-title {
  position: absolute;
  left: 20px;
  top: 13px;
}

.padding-top-10 {
  padding-top: 10px;
}

.andes-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.aurora-content-right {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.aurora-content-right>.ant-spin-nested-loading,
.aurora-content-right>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.aurora-content-top {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.aurora-content-top>.ant-spin-nested-loading,
.aurora-content-top>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.andes-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.aurora-content .dividingLine {
  top: 0px;
}

.aurora-content>.ant-spin-nested-loading,
.aurora-content>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.andes-setup-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #4e4e4e;
  z-index: 20;
  opacity: 0.5;
  border-radius: 5px 0 0 5px;
}

.andes-setup-close .setup-close-icon {
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  cursor: pointer;
  opacity: 1;
}

.andes-setup-close:hover {
  transition: 0.6s;
  opacity: 0.8;
}

.andes-pcb-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #fff;
  opacity: 0.8;
  border-radius: 5px 0 0 5px;
}

.andes-pcb-close .andes-close-icon {
  font-size: 16px;
  color: #000;
  cursor: pointer;
  opacity: 1;
}

.andes-pcb-close:hover {
  transition: 0.6s;
  opacity: 1;
}

.select-model {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.select-model-content {
  margin-right: 16px;
}

.select-model-last {
  padding-bottom: 5px;
}

.select-model-selection,
.pintopin--buffer-selection {
  float: left;
  width: 100%;
}

.select-dialog-close {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.select-dialog-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.select-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.signal-delete-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pintopin-power-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.andes-signal-name-cell {
  margin-right: 22px !important;
  width: auto !important;
}

.andes-PG-table tbody>tr>td:last-child,
.andes-signal-nets-table tbody>tr>td:first-child {
  position: relative;
}

.andes-PG-table .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .pintopin-power-delete,
.andes-signal-nets-table .ant-table-small>.ant-table-content>.aurora-table-body .andes-signal-name-cell:hover .signal-delete-icon {
  color: #1890ff;
}

.andes-table-comp {
  width: 100%;
  cursor: pointer;
}

.andes-PG-net-box {
  margin-top: 10px;
}

.andes-re-extraction {
  display: inline-block;
  padding-left: 20px;
}

.andes-re-extraction-switch {
  margin: 0 20px;
  margin-top: -2px;
}

.andes-type-select {
  margin-left: 14px;
  margin-right: 30px;
  padding: 2px 6px;
  height: 28px;
}

.andes-type-select . {
  width: 94px;
  padding: 2px 6px;
  height: 28px;
}

.andes-type-select .ant-select-selector {
  width: 94px;
  height: 28px;
  margin-left: 6px;
  line-height: 21px;
}

.andes-channel-select {
  margin: 0 20px;
  padding: 2px 6px;
  height: 28px;
}

.andes-channel-select . {
  width: 94px;
  padding: 2px 6px;
  height: 28px;
}

.andes-channel-select .ant-select-selector {
  width: 94px;
  height: 28px;
  margin-left: 6px;
  line-height: 21px;
}

.andes-type-input {
  margin-left: 14px;
  margin-right: 6px;
  width: 94px;
  padding: 2px 6px;
  height: 28px;
}

.andes-select-dropdown-menu {
  padding: 0px;
  margin: 0px;
  max-height: 250px;
  margin-bottom: 0px !important;
}

.andes-select-nets-div {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.andes-select-nets-ul-1 {
  max-height: 138px;
  width: 100%;
  overflow: auto;
  padding-left: 15px;
  margin-bottom: 2px !important;
}

.andes-select-nets-ul-2 {
  max-height: 68px;
  width: 100%;
  overflow: auto;
  padding-left: 15px;
  margin-bottom: 4px !important;
}

.andes-select-span {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin: 0px;
  padding: 0px;
  padding-left: 8px;
  cursor: pointer;
}

.andes-select-nets-li {
  line-height: 28px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
}

.andes-select-nets-li:hover {
  background-color: rgb(223, 243, 255);
}

.andes-nets-selected {
  background-color: rgb(176, 226, 255);
  font-weight: 600;
}

.andes-nets-selected:hover {
  background-color: rgb(176, 226, 255);
}

.andes-nets-expand-icon {
  margin: 0 4px;
  font-size: 12px;
}

.andes-advance-content {
  margin-top: 10px;
}

.andes-advance-title,
.andes-optimization-title {
  font-weight: bold;
}

.andes-advance-option {
  display: inline-block;
  padding: 0 20px;
  vertical-align: middle;
}

.andes-extraction-button {
  margin: 0 20px;
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-top: -2px;
}

.andes-sub-extraction-box {
  margin-left: 26px !important;
}

.andes-debug-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
}

.andes-advance-panel {
  margin-top: 100px;
  min-width: 776px;
}

.andes-advance-panel .extraction-name {
  font-size: 16px;
  font-weight: bold;
}

.andes-advance-panel .extraction-main {
  margin-left: 20px;
  margin-bottom: 12px;
}

.andes-advance-panel .extraction-sub-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 8px;
}

.andes-advance-panel .extraction-content {
  line-height: 28px;
  margin-bottom: 5px;
}

.andes-advance-panel .extraction-content .extraction-input {
  display: inline-block;
  width: 44%;
  vertical-align: middle;
  height: 28px;
}

.andes-advance-panel .extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  padding-right: 10px;
}

.andes-advance-panel .sub-extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  text-align: 20px;
  font-style: italic;
}

.andes-advance-panel .sub-extraction-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.andes-advance-panel .extraction-option-error-msg {
  display: block;
  padding: 8px 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 10px 16px 10px 4px;
}
.andes-result-file-name {
  transition: all 0.3s;
  font-weight: 600;
  margin-top: 5px;
  padding-left: 8px;
  cursor: pointer;
  line-height: 24px;
}

.andes-result-file-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.andes-result-checkbox-group {
  width: 99%;
  padding-left: 10px;
}

.andes-file-expand-icon {
  padding-right: 4px;
  font-size: 10px;
  vertical-align: baseline;
}

.title-expand-icon {
  padding-right: 10px;
  float: left;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 10px;
}

.andes-result-parameter-file-list {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 318px;
  padding: 8px 0 8px 8px;
}

.andes-result-parameter-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.andes-result-parameter-left {
  background-color: #fafafa;
  border-left-color: #e8e8e8;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.font-bold {
  font-weight: bold;
}

.andes-result-checkbox-group>.ant-checkbox {
  top: 0px;
}

.andes-result-parameter-file-list .andes-result-file-name .span-file-name {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.andes-returnLoss-result-main {
  position: relative;
}

.andes-returnLoss-result-main,
.andes-returnLoss-result-main .ant-spin-nested-loading,
.andes-returnLoss-result-main .ant-spin-container {
  height: 100%;
}

.andes-returnLoss-result-main .andes-result-setting {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 50%;
  bottom: 0;
  background-color: #fafafa;
}

.andes-result-curve-current {
  margin-left: 10px;
  vertical-align: middle;
  font-weight: bold;
}

.andes-result-checkbox-group-name {
  display: inline-block;
  line-height: 20px;
}

.andes-result-checkbox-group-name .andes-result-checkbox-label {
  color: #000;
  vertical-align: middle;
  margin-left: 4px;
}

.andes-result-checkbox-group .ant-checkbox+span {
  height: 26px;
  display: inline-block;
  padding-left: 3px;
  padding-right: 0px;
}

.andes-result-parameter-file-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.andes-result-ports-list {
  padding-left: 10px;
}

.andes-file-download-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
}

.andes-result-file-content {
  /* overflow-y: auto; */
  width: 100%;
  overflow: auto;
}

.andes-result-history-file-content {
  width: 100%;
  overflow: auto;
  margin-left: 8px;
}

.andes-result-parameter-file-list .ant-divider-inner-text {
  padding-left: 2px !important;
  padding-right: 28px !important;
}

.andes-result-parameter-file-list .ant-divider-horizontal.ant-divider-with-text-left::before{
  width: 0%;
}

.andes-result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
}

.andes-channel-port-name {
  height: 24px;
  line-height: 24px;
  margin-left: 6px;
  padding-left: 6px;
  cursor: pointer;
}

.andes-channel-port-name-general {
  padding-left: 6px;
  margin-left: 0px;
}

.andes-channel-port-name:hover {
  background: #efefef;
}

.andes-result-ports-list .ant-checkbox-group {
  width: 100%;
}

.andes-download-single-file-div {
  position: absolute;
  top: 21px;
  right: 80px;
}

.andes-single-file-download-icon {
  font-size: 20px;
  color: #40a9ff;
}

.andes-result-save-icon {
  margin-left: 9px;
  margin-right: 12px;
  padding: 0 5px;
  color: #40a9ff;
  font-size: 17px;
}
.waveform {
  width: 100%;
  height: 100%;
}

.waveform-clear {
  zoom: 1;
}

.waveform-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.andes-result-main {
  position: relative;
}

.andes-waveform-svg .domain {
  display: none;
}

.andes-result-main .ant-spin-nested-loading,
.andes-result-main .ant-spin-container,
.andes-result-main {
  height: 100%;
  box-sizing: border-box;
}

.andes-waveform-component-name,
.andes-waveform-signal-name,
.andes-waveform-check-lable,
.andes-waveform-comp-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
}

.andes-waveform-signal-name {
  margin-left: 4px;
}

.andes-waveform-comp-name {
  max-width: 62px;
}

.andes-waveform-checkboxgroup {
  padding-left: 28px;
  padding-top: 10px;
  width: 100%;
  max-width: 450px;
  /*   height: 56%; */
  overflow-y: auto;
  background-color: #fafafa;
}

.andes-waveform-checkbox:hover .color-select {
  background-color: #dbdbdb;
}

.andes-waveform-checkbox-lable {
  width: 100%;
  height: 20px;
  display: block;
}

.andes-waveform-tooltip {
  width: 100%;
  height: 20px;
  display: block;
}

.andes-waveform-current {
  float: left;
  margin-left: 10px;
}

.andes-waveform-left {
  height: 100%;
  float: left;
  width: 100%;
}

.andes-waveform-setting {
  border-left-color: #dcdcdc;
  border-left-width: 1px;
  border-left-style: solid;
  background-color: #ffffff;
  height: 100%;
}

.andes-waveform-left .andes-waveform-svg {
  height: 100%;
  width: 100%;
}

.andes-waveform-checkbox .ant-checkbox {
  float: left;
  margin-top: 4px;
  margin-right: 5px;
  margin-left: 5px;
}

.andes-waveform-checkbox span {
  padding: 0px;
}

.andes-waveform-checkbox:hover {
  background: #dbdbdb;
}

.andes-waveform-check-lable {
  margin-left: 4px;
  margin-right: 2px;
}

.andes-setting-input {
  width: 60%;
}

.andes-waveform-axis-title,
.andes-waveform-setting-input-lable,
.andes-waveform-display-mode-title {
  font-weight: bold;
  margin-right: 8px;
}

.andes-waveform-setting .ant-checkbox-group>.ant-checkbox-wrapper {
  margin-left: 0px;
  display: inline-block;
  width: 100%;
}

.andes-waveform-setting-box {
  padding-left: 10px;
  padding-top: 10px;
  max-width: 450px;
  overflow: auto;
}

.andes-result-driver-title {
  cursor: pointer;
  padding-right: 1px;
}

.andes-waveform-setting .dividingLine {
  height: 100%;
}

.andes-result-delay-title {
  font-weight: bold;
  margin: 10px;
}

.andes-result-main-title {
  margin-right: 84px !important;
}

.andes-result-save-div {
  position: absolute;
  top: 12px;
  right: 26px;
}

.andes-result-save-icon {
  font-size: 19px;
  color: #40a9ff;
  cursor: pointer;
}

.andes-delay-table {
  margin: 10px 20px;
  margin-bottom: 30px;
}

.andes-result-delay-select {
  width: 30%;
  margin-bottom: 10px;
}

.andes-delay-result-select-dropdown {
  z-index: 100000000;
}

.andes-waveform-setting .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.andes-waveform-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 5%;
}


.andes-waveform-setting .andes-result-driver .ant-divider-inner-text {
  padding-left: 6px !important;
  padding-right: 28px !important;
}


.andes-result-history-title {
  transition: all 0.3s;
  margin-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  line-height: 24px;
  position: relative;
}

.andes-result-history-title:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.andes-result-waveform-selection {
  overflow: auto;
  height: calc(100% - 176px);
  padding-top: 10px;
}

.andes-result-popover {
  height: 100px;
}

.andes-result-popover .ant-popover-inner-content {
  padding: 8px;
}

.andes-result-history-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.andes-result-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.andes-result-name {
  width: 200px;
}

.andes-file-delete-icon {
  position: absolute;
  right: 8px;
  font-size: 17px;
  color: #40a9ff;
  margin-top: 3px;
  display: none;
}

.andes-result-history-title:hover .andes-file-delete-icon {
  display: inline-block;
}

.andes-result-content {
  position: relative;
}
li {
  list-style: none;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.andes-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

/* my andes monitor */

.my-andes-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.my-andes-monitor-ccc {
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.my-andes-monitor-title {
  font-weight: bold;
  line-height: 44px;
  padding-left: 20px;
  float: left;
}

.my-andes-middle {
  position: relative;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
}

.my-andes-monitor-progress {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.monitor-upload-progress-bar {
  width: 100%;
}

.monitor-upload-progress-bar .ant-progress-text {
  font-weight: bold;
}

.my-andes-download-btn {
  position: relative;
  z-index: 20;
}

.my-andes-debug-btn {
  position: relative;
  z-index: 20;
}

.design-msg-style {
  color: #f5222d;
  margin: 0;
  text-align: center;
}

.andes-simulation-holygrail {
  position: absolute;
  right: 100px;
  left: 0;
  box-sizing: border-box;
}

.andes-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.andes-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 80px;
  margin-right: 12px;
}

.andes-simulation-msg {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-width: 160px;
  text-align: center;
  margin-left: 16px;
  height: 44px;
}

.andes-simulation-span {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
}

.andes-workflow-msg {
  position: relative;
  float: left;
  height: 44px;
  line-height: 42px;
  text-align: center;
  min-width: 80px;
  margin-left: 20px;
}

.workflow-msg {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 28px;
  font-size: 16px;
}

.andes-simulation-project {
  margin-right: 10px;
}

.span-msg, .span-msg-project {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
}

.andes-simulation-middle {
  position: relative;
  float: left;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
  box-sizing: border-box;
  float: right;
}

.andes-simulation-progress {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 40px;
  width: 100%;
  line-height: 44px;
  box-sizing: border-box;
}

.simulation-progress-bar {
  width: 100%;
}

.andes-simulation-button, .andes-upload-button, .andes-cancel-button, .andes-kill-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.andes-cancel-button {
  line-height: 43px;
}

.andes-kill-button {
  line-height: 45px;
  font-size: 19px;
  margin-left: 18px;
}

.andes-simulation-progress .ant-progress-outer {
  width: 94%;
}

.andes-simulation-progress .ant-progress-outer .ant-progress-inner {
  background-color: #fff;
}

.icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

.span-msg {
  text-indent: 8px;
}

.andes-monitor-ul {
  margin: 0;
  padding: 0;
}

.andes-button {
  font-size: 18px;
}

.andes-button-cancel {
  transform: rotate(90deg);
}

.debug-mes-span {
  display: block;
}

.closed-message {
  display: block;
}

.andes-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 44px;
  color: #1890ff;
}

.andes-button-icon {
  font-size: 22px;
}

.andes-simulation-title .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.andes-simulation-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.andes-span-msg-tooltip {
  display: block;
  text-indent: 8px;
}

.andes-update-pcb-box {
  padding: 10px 20px;
  font-size: 14px;
  line-height: 22px;
}

.andes-update-pcb-span {
  padding-left: 10px;
  display: block;
}
.andes-warning-title {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
  color: #ff4848;
  font-weight: bold;
}

.error-font-bold {
  font-weight: bold;
}

.andes-warning-dialog {
  padding: 10px 20px;
  color: #ff4848;
}
.my-andes-layout {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.aurora-content {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 240px;
  right: 0px;
  bottom: 0px;
  /* overflow-x: auto !important; */
}

.my-andes-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-andes-content>.ant-spin-nested-loading,
.my-andes-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.andes-sider {
  position: relative;
  width: 240px;
  background: #f9f9f9;
  height: 100%;
  border-right: 1px solid #e2dfdf;
}

.andes-sider-footer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 42px;
  border-top: 1px solid #e2dfdf;
}

.andes-close-box {
  float: left;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  color: #0d87f7;
  text-align: center;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.andes-sider-layout {
  line-height: 36px;
  font-size: 19px;
}

.andes-sider-footer .andes-close-box:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.andes-sider-footer .andes-sider-monitor {
  line-height: 34px;
  font-size: 22px;
}

.mg-r-8 {
  margin-right: 8px;
}

.project-name-input .ant-input-group {
  float: left;
  width: 100%;
}

.project-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.andes-tooltip-span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.andes-create-name {
  width: 100%;
}

.ant-tree-title .ant-checkbox-wrapper-box {
  /* float: left;
  margin-right: 6px; */
  position: absolute;
  /* left: 28px; */
  top: 0px
}

.result-style {
  padding-left: 12px;
  font-style: italic;
}

.andes-delete-content {
  width: 196px;
}

.andes-delete-title {
  display: block;
  font-size: 14px;
  color: #f12727;
}

.delete-confirm {
  color: #1890ff;
  cursor: pointer;
}

.delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.andes-delete-pop-confirm .ant-popover-buttons {
  display: none;
}

.andes-delete-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
}

.andes-delete-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.andes-delete-pop-confirm .ant-popover-message>.andes-confirm-icon {
  display: none;
}

.andes-delete-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.andes-delete-pop-confirm .andes-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.andes-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.andes-clean-pop-confirm .ant-popover-buttons .ant-btn {
  display: none;
}

.andes-clean-pop-confirm .ant-popover-buttons .ant-btn-primary {
  display: inline-block;
}

.andes-clean-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
  width: 204px;
}

.andes-clean-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.andes-clean-pop-confirm .ant-popover-message>.andes-confirm-icon {
  display: none;
}

.andes-clean-pop-confirm .ant-popover-message-title {
  padding-left: 0;
  display: block;
  font-size: 14px;
  color: #f12727;
}

.andes-clean-pop-confirm .andes-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.andes-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.andes-sider-menu {
  margin: 12px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.andes-sider-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.andes-layout-msg {
  font-size: 12px;
  margin-top: 10px;
  vertical-align: middle;
}

.andes-layout-msg>span {
  display: inline-block;
}

.andes-layout-msg>span:nth-child(1) {
  margin-right: 6px;
}

.andes-sider-menu-tree .ant-tree-title i {
  position: absolute;
  right: 14px;
  visibility: hidden;
}

.andes-sider-menu-tree .ant-tree-title:hover i {
  visibility: visible;
}

.andes-sider-menu-tree .ant-tree-title:hover {
  padding-right: 32px;
}

.andes-sider-menu-tree .ant-tree-title .project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.andes-icon-color:hover {
  color: #1890ff;
}

.andes-view-popover .ant-popover-inner-content {
  padding: 0;
}

.andes-switch-icon {
  display: inline-block;
  font-weight: 500;
  padding: 0 6px;
  cursor: pointer;
  font-size: 18px;
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
  color: #737373;
}

#andes-switch-icon-selected {
  color: #0d87f7;
}

.andes-sider-footer .view-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.andes-sider-footer .layout-icon-disabled {
  border: 1px #c5c5c5 solid;
  color: #a0a0a0;
  cursor: not-allowed;
}

.andes-delete-confirm {
  color: #1890ff;
}

.andes-delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.andes-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-status-span {
  margin-right: 2px;
}

.andes-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-status-icon {
  margin-right: 2px;
}

.andes-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-replace-icon {
  margin-right: 2px;
}

.andes-sider-menu-tree .ant-tree-title .aurora-replace-icon {
  visibility: hidden;
}

.andes-sider-menu-tree .ant-tree-title:hover .aurora-replace-icon {
  visibility: visible;
}

.andes-sider-menu-tree .project-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.andes-verification-tooltip {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.andes-sider-menu-tree .tree-node-interface-name .ant-tree-title {
  padding-left: 22px;
  padding-right: 22px;
}

.andes-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover {
  padding-right: 48px;
}

.andes-sider-menu-tree .tree-node-interface-name .tree-node-interface-result-name .ant-tree-title {
  padding-left: 2px;
}

.andes-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover .aurora-status-span {
  right: 38px;
}

.andes-sider-menu-tree .verification-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.andes-sider-menu-tree .tree-node-pcb-name .tree-node-pcb-replace-icon {
  position: absolute;
  right: 36px;
}

.andes-sider-menu-tree .tree-node-pcb-name .ant-tree-title:hover {
  padding-right: 50px;
}

.andes-sider-menu-tree .ant-tree-title .aurora-tree-loading-icon {
  visibility: visible;
}

.andes-sider-menu-tree .ant-tree-title:hover .aurora-tree-loading-icon {
  visibility: visible;
  right: 36px;
}

.andes-sider-menu-tree .ant-tree-title:hover .andes-project-title {
  padding-right: 40px;
}

.andes-sider-menu-tree .ant-tree-title .andes-project-title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.andes-PCB-replace {
  position: absolute;
  right: 35px;
  display: inline;
}

.andes-sider-menu-tree .ant-tree-title i.simulate-icon-disabled,
.andes-sider-menu-tree .ant-tree-title i.aurora-tree-simulate-icon {
  visibility: visible !important;
  line-height: 36px;
  font-size: 15px;
}

.andes-sider-menu-tree .ant-tree-title i.simulate-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.andes-sider-menu-tree .ant-tree-title:hover i.simulate-icon-disabled,
.andes-sider-menu-tree .ant-tree-title:hover i.aurora-tree-simulate-icon {
  right: 38px;
}

.andes-sider-footer .pcb-icon {
  font-size: 25px;
  line-height: 32px;
  cursor: pointer;
}

.andes-sider-footer .icon-setup {
  font-size: 19px;
  cursor: pointer;
}

.andes-sider-footer .icon-Monitor {
  font-size: 20px;
  cursor: pointer;
}
.decap-radio-upload {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.decap-radio-edit {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.decap-radio-touchstone {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.decap-radio-group {
  display: block;
  margin: 0 16px;
  height: 40px;
  margin-top: 16px;
}

.VRM-table {
  margin: 8px 20px;
}

.model-name {
  display: inline-block;
  width: 200px;
  margin-left: 10px;
  font-size: 16px;
  margin-right: 10px;
}

.SPIM-input {
  width: 89%;
  margin: 16px;
  margin-bottom: 0px;
}

.SPIM-upload-icon {
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.SPIM-del-icon {
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.unit-select {
  float: right;
}

.unit-span {
  line-height: 32px;
}

.decap-table {
  position: absolute;
  width: 90%;
  left: 42px;
  top: 160px;
}

.disabled {
  opacity: 0.5 !important;
}

.under-line {
  width: 94%;
  height: 1px;
  background: #cdcfd2;
  margin: 0 10px;
}

#decap-file-view,
#powerdomain-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.decap-file-panel .panel-body {
  position: relative;
  min-height: 156px;
}

.decap-file-panel .dividingLine {
  top: 0px;
}

.decap-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  height: 400px;
  margin-bottom: 0;
}

.decap-response-icon {
  color: #1890ff;
  font-size: 18px;
  margin-left: 10px;
  vertical-align: middle;
}

.decap-res-progress-bar {
  width: 100%;
  max-width: 500px;
  margin-left: 20px;
}

.decap-graph-subckts-list {
  float: right;
  width: 26%;
  height: 100%;
  background: #f9f9f9;
  position: relative;
  z-index: 10;
  border-left: 1px solid #e2dfdf;
}

.decap-graph-right-content {
  height: 100%;
}

.decap-graph-subckts-list .decap-subckt-name {
  display: inline-block;
  max-width: 160px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.decap-graph-file-name {
  transition: all 0.3s;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
  line-height: 24px;
}

.decap-graph-file-name .ant-checkbox-group {
  width: 100%;
}

.decap-graph-file-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.decap-graph-checkbox-group>.ant-checkbox {
  top: 0px;
  left: 4px;
}

.decap-graph-checkbox-group-name {
  display: inline-block;
  line-height: 20px;
}

.decap-graph-checkbox-group .ant-checkbox+span {
  height: 26px;
  display: inline-block;
  padding-left: 6px;
  padding-right: 4px;
}

.decap-graph-checkbox-group {
  width: 100%;
}

.decap-graph-curve-current {
  display: inline-block;
  margin-left: 2px;
  vertical-align: middle;
  font-weight: 400;
}

.decap-graph-color-select {
  padding: 0px;
  margin: 0px;
  background-color: #f9f9f9;
  border: 0px;
  width: 19px;
  margin-right: 2px;
  cursor: pointer;
  vertical-align: middle;
}

.decap-graph-file-name:hover .decap-graph-color-select {
  background-color: #e0e0e0;
}

.decap-graph-right,
.decap-graph-right-100 {
  float: right;
  height: 100%;
  width: 70%;
  position: relative;
}

.decap-graph-right-100 {
  width: 100%;
}

.decap-file-svg {
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  right: 26%;
}

.decap-graph-svg-content {
  width: 100%;
  height: 100%;
}

.decap-graph-setting-box {
  width: 100%;
  height: auto;
}

.decap-graph-setting-box .ant-row-flex-middle {
  margin: 2px 0;
}

.decap-graph-select {
  z-index: 1000000;
}

.decap-graph-selector-slide {
  cursor: move;
  fill: #337ab7;
}

.selector-traveller {
  cursor: col-resize;
  fill: #444;
}

.decap-graph-curve-mark {
  fill: #ff0000;
}

.decap-graph-mark-text {
  font-size: 10px;
  text-anchor: middle;
}

.decap-file-display {
  float: left;
}

.decap-graph-setting-input {
  width: 80px;
  margin-left: 0px;
  height: 28px;
}

.decap-graph-setting-input input {
  height: 28px;
}

.decap-graph-setting {
  padding-top: 8px;
  overflow: auto;
}

.decap-graph-setting-title {
  cursor: pointer;
  padding-right: 14px;
}

.decap-graph-file-list {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.decap-graph-setting-content {
  min-width: 240px;
}

.decap-graph-setting {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
}

.decap-characterize-invisible {
  position: absolute;
  top: 16px;
  left: 30%;
  padding-left: 16px;
  z-index: 1;
}

.decap-characterize-visible {
  position: absolute;
  top: 18px;
  right: 40px;
  z-index: 1;
  font-size: 16px;
}

.netListFold {
  position: absolute;
  left: 6px;
  top: 30px;
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #ffffff 0px 0px 0px 0px;
  background: #ffffff;
  z-index: 1;
  border: 1px solid#dedede;
  justify-content: center;
}

.show-hide-plot {
  position: absolute;
  left: 6px;
  top: 60px;
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #ffffff 0px 0px 0px 0px;
  background: #ffffff;
  z-index: 1;
  border: 1px solid#dedede;
  justify-content: center;
}

.netListFold:hover,
.show-hide-plot:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
  background: #fafafa;
}

.decap-graph-maximize {
  color: #1890ff;
  font-size: 18px;
  margin-right: 26px;
  float: right;
  margin-top: 2px;
  cursor: pointer;
}

.decap-graph-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

#decap-file-view .panel-ant-modal-title,
#powerdomain-file-view .panel-ant-modal-title {
  line-height: 18px;
}

.sp-setting-select {
  height: 28px;
  line-height: 28px;
  width: 100px;
}

.sp-setting-select div {
  height: 28px;
}

.sp-setting-select .ant-select-selection-selected-value {
  line-height: 26px;
  padding-right: 14px;
}

.sp-setting-select .ant-select-selector {
  line-height: 26px;
}

.sp-setting-select .ant-select-arrow {
  right: 6px;
}

.sp-setting-select-value {
  width: 102px;
}

.PDN-show-models .panel-ant-modal-body.panel-body {
  padding: 16px !important;
}

.show-model-content {
  background: #f5f5f5;
  max-height: 300px;
  overflow: auto;
  padding: 12px 16px;
  margin: 0;
  border: 1px dashed #d9d9d9;
}

.pdn-show-log-content {
  background: #f5f5f5;
  max-height: 800px;
  overflow: auto;
  padding: 12px 16px;
  margin: 0;
  border: 1px dashed #d9d9d9;
}
.chip-selection,
.spim-selection {
  width: 100%;
}

.chip-dropdown-selection {
  z-index: 3000 !important;
}

.package-mode-box-setup {
  padding: 16px 16px 0px;
}

.package-mode-box-setup:nth-child(2) {
  padding-bottom: 16px;
}

.package-title {
  margin-right: 10px;
}

.package-panel {
  margin: 0 auto;
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.package-selection-tooltip {
  z-index: 2001 !important;
}

.pdn-package-setting-icon {
  padding-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
}

.pdn-package-setting-input {
  width: 266px;
  display: block;
}

.pdn-package-setting-input .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.pdn-package-setting-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.pdn-package-popover {
  z-index: 3000;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pdn-package-popover .ant-popover-content {
  box-shadow: rgba(76, 77, 78, 0.14) 0px 0px 4px 0px, rgba(0, 0, 0, 0.1) 0px 0px 4px 2px;
  border-radius: 4px;
}

.pdn-package-popover .ant-popover-inner-content {
  padding: 0px;
}

.pdn-package-popover .ant-popover-arrow {
  display: none;
}

.pdn-package-setting-span {
  font-size: 14px;
  display: inline-block;
  padding: 2px;
  margin-top: 4px;
  margin-bottom: 18px;
}

.pdn-package-setting-close {
  font-size: 16px;
  position: absolute;
  right: 12px;
  top: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.pdn-package-setting-close:hover {
  color: rgba(0, 0, 0, 0.75);
}

.pdn-package-setting-header {
  padding: 11px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 12px;
  position: relative;
}

.pdn-package-setting-content {
  padding: 16px 12px;
}

.pdn-package-save-panel {
  top: 200px;
}

.pdn-package-save-panel .rightBorder,
.pdn-package-save-panel .bottomBorder,
.pdn-package-save-panel .lowerRightCorner {
  cursor: unset;
}

.pdn-package-create-btn {
  margin-top: 10px;
}

.pdn-package-create-main {
  padding: 10px;
}

.pdn-package-create-content {
  padding: 10px 8px;
  line-height:25px;
}
.my-pdn-layout {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.aurora-content {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 240px;
  right: 0px;
  bottom: 0px;
}

.my-pdn-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-pdn-content-bottom {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-pdn-content-bottom>.ant-spin-nested-loading,
.my-pdn-content-bottom>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.my-pdn-content>.ant-spin-nested-loading,
.my-pdn-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.pdn-sider {
  position: relative;
  width: 240px;
  background: #f9f9f9;
  height: 100%;
  border-right: 1px solid #e2dfdf;
}

.pdn-sider-footer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 42px;
  border-top: 1px solid #e2dfdf;
}

.pdn-sider-footer .tab-close-box {
  float: left;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 19px;
  text-align: center;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.pdn-sider-footer .pdn-sider-monitor {
  line-height: 34px;
  font-size: 22px;
  cursor: pointer;
}

.pdn-sider-footer .pdn-sider-layout {
  line-height: 36px;
  font-weight: bold;
}

.pdn-sider-footer .tab-close-box:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.mg-r-8 {
  margin-right: 8px;
}

.project-name-input .ant-input-group {
  float: left;
  width: 100%;
}


.pdn-sider-menu-tree .tree-node .ant-tree-title>.project-rename-input {
  position: absolute;
  width: 95%;
}

.pdn-sider-menu-tree .tree-node .ant-tree-title>.project-rename-input .ant-input {
  height: 24px;
}

.pdn-sider-menu-tree .ant-tree-title .project-rename-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.pdn-sider-menu-tree .tree-node-pcb-name .tree-node-pcb-replace-icon {
  position: absolute;
  right: 0px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-replace-icon {
  visibility: hidden;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-replace-icon {
  visibility: visible;
  margin-right: 0px;
  position: absolute;
  right: 36px;
}

.project-rename-input .ant-input-group {
  float: left;
  width: 100%;
}

.project-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.pdn-tooltip-span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.pdn-preparing {
  width: 85%;
}

.pdn-create-name {
  width: 100%;
}

.pdn-sider-menu-tree .ant-tree-title .ant-checkbox-wrapper {
  /* float: left;
  margin-right: 6px; */
  position: absolute;
  left: 2px;
  line-height: 21px;
  top: 2px;
}

.pdn-simulate-button>.ant-btn {
  line-height: 18px;
  width: 68px;
  height: 20px;
  padding: 0 6px;
  margin-top: 6px;
  border: 1px solid #1890ff;
  color: #1890ff;
  position: absolute;
  right: 14px;
}

.result-style {
  padding-left: 12px;
  font-style: italic;
}

.pdn-delete-content {
  width: 208px;
}

.pdn-delete-title {
  display: block;
  font-size: 14px;
  color: #f12727;
}

.delete-confirm {
  color: #1890ff;
}

.delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.pdn-delete-pop-confirm .ant-popover-buttons {
  display: none;
}

.pdn-delete-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
}

.pdn-delete-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.pdn-delete-pop-confirm .ant-popover-message>.pdn-confirm-icon {
  display: none;
}

.pdn-delete-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.pdn-delete-pop-confirm .pdn-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.pdn-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.pdn-clean-pop-confirm .ant-popover-buttons .ant-btn {
  display: none;
}

.pdn-clean-pop-confirm .ant-popover-buttons .ant-btn-primary {
  display: inline-block;
}

.pdn-clean-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
  width: 204px;
}

.pdn-clean-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.pdn-clean-pop-confirm .ant-popover-message>.pdn-confirm-icon {
  display: none;
}

.pdn-clean-pop-confirm .ant-popover-message-title {
  padding-left: 0;
  display: block;
  font-size: 14px;
  color: #f12727;
}

.pdn-clean-pop-confirm .pdn-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.pdn-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.pdn-sider-menu {
  margin: 12px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.pdn-sider-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.pdn-layout-msg {
  font-size: 12px;
  margin-top: 10px;
  vertical-align: middle;
}

.pdn-layout-msg>span {
  display: inline-block;
}

.pdn-layout-msg>span:nth-child(1) {
  margin-right: 6px;
}

.pdn-layout-popover .ant-popover-inner-content {
  padding: 6px;
}

#switch-icon-selected {
  color: #0d87f7;
}

.pcb-icon {
  font-size: 25px;
  line-height: 32px;
  cursor: pointer;
}

.pdn-view-popover .ant-popover-inner-content {
  padding: 0;
}

.icon-Monitor {
  font-size: 20px;
  cursor: pointer;
}

.icon-setup {
  font-size: 19px;
  cursor: pointer;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-icon,
.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-icon {
  position: absolute;
  right: 21px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-icon {
  right: 48px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-layout-check-loading-icon {
  visibility: visible;
  right: 14px
}

.pdn-sider-menu-tree .ant-tree-title .ant-select-arrow .anticon {
  right: 0px;
}

.pdn-sider-menu-tree .ant-tree-title .anticon {
  position: absolute;
  right: 14px;
  visibility: hidden;
}

.pdn-sider-menu-tree .ant-tree-title:hover .anticon {
  visibility: visible;
}

.pdn-sider-menu-tree .ant-tree-title:hover {
  padding-right: 48px;
}

.pdn-sider-menu-tree .ant-tree-title .project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.pdn-icon-color:hover {
  color: #1890ff;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-simulate-icon {
  right: 38px;
}

.pdn-sider-menu-tree .anticon-play-circle {
  visibility: visible !important;
  transition: right .2s linear;
}

.pdn-sider-menu-tree .anticon-play-circle.simulate-icon-disabled {
  color: #a0a0a0;
}

.pdn-sider-footer .view-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.pdn-sider-footer .layout-icon-disabled {
  border: 1px #c5c5c5 solid;
  color: #a0a0a0;
  cursor: not-allowed;
}

.sider-switch-icon {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  color: #737373;
}

.sider-switch-vertical-icon {
  padding: 0 17px;
  padding-bottom: 4px;
}

.sider-switch-vertical-icon .pcb-icon {
  margin-left: -3px;
}

.sider-layout-div {
  display: inline-block;
}

.sider-layout-vertical-div {
  padding: 0 10px;
  padding-bottom: 4px;
}

.pdn-sider-result-spin {
  position: absolute;
  right: 16px;
  line-height: 32px;
}

.pdn-sider-result-spin i {
  visibility: visible !important;
  right: 0 !important;
}

.pdn-simulate-pop-confirm .ant-btn-sm {
  display: none;
}

.pdn-simulate-pop-confirm .ant-popover-buttons .ant-btn-primary {
  display: block;
  width: 60px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.pdn-simulate-pop-confirm .ant-popover-buttons:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.pdn-simulate-pop-confirm .ant-popover-buttons {
  margin-bottom: 0px;
  margin-top: 2px;
}

.pdn-simulate-pop-confirm .ant-popover-inner-content {
  padding: 8px 8px;
  width: 240px;
}

.pdn-simulate-pop-confirm .ant-popover-inner-content .ant-popover-message-title {
  font-size: 14px;
  color: #f12727;
}

.pdn-simulate-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.pdn-simulate-pop-confirm .ant-popover-message>.pdn-confirm-icon {
  display: none;
}

.pdn-simulate-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.pdn-sider-menu-tree .ant-tree-title:hover .pdn-project-title {
  padding-right: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdn-sider-menu-tree .ant-tree-title .pdn-project-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

/* PDN create hover */

.pdn-sider-menu-tree .pdn-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

/* PDN Tree name hover */

.pdn-sider-menu-tree .tree-node-pcb-name .ant-tree-title:hover,
.pdn-sider-menu-tree .tree-node-pdn-name .ant-tree-title:hover,
.pdn-sider-menu-tree .tree-node-pkg-name .ant-tree-title:hover,
.pdn-sider-menu-tree .tree-node-vrm-name .ant-tree-title:hover {
  padding-right: 28px;
}

.pdn-sider-menu-tree .tree-node-pdn-name .ant-tree-title {
  padding-left: 22px;
}

.pdn-sider-menu-tree .tree-node-pdn-name .tree-node-pdn-result-name .ant-tree-title {
  padding-left: 2px;
}

.pdn-sider-menu-tree .tree-node-spim-file-name .ant-tree-title:hover,
.pdn-sider-menu-tree .tree-node-power-domain-folder-name .ant-tree-title:hover {
  padding-right: 0px;
}

.pdn-sider-menu-tree .project-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.fastpi-library-span {
  display: inline-block;
  max-width: 86%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.pdn-sider-menu-tree .tree-library-file {
  position: relative;
}

.pdn-sider-menu-tree .download-progress-bar {
  height: 36px;
  width: 100%;
  float: left;
  overflow: visible;
  position: relative;
}

.pdn-sider-menu-tree .ant-tree-title .download-progress-bar i {
  right: 9px;
}

.pdn-sider-menu-tree .tree-library-file .ant-tree-title .download-progress-bar .tree-library-content {
  padding-right: 40px;
}

.pdn-sider-menu-tree .tree-library-file .ant-tree-title:hover .download-progress-bar .tree-library-content {
  padding-right: 20px;
}

.pdn-sider-menu-tree .tree-library-file .ant-tree-title:hover .tree-library-content {
  padding-right: 20px;
}

.pdn-sider-menu-tree .tree-library-file .ant-tree-title:hover {
  padding-right: 0px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .fastpi-library-span {
  width: 60%
}

.pdn-sider-menu-tree .ant-tree-title:hover.prepare-loading-icon {
  visibility: visible;
  right: 14px !important;
}


.pdn-sider-menu-tree .download-progress-bar::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #2486ff;
  text-align: right;
  white-space: nowrap;
  opacity: 0.4;
  overflow: hidden;
  color: #fff;
  counter-reset: progress var(--percent);
  content: "";
  transition: 0.6s;
  width: calc(1%*var(--percent));
}

.pdn-sider-menu-tree .tree-library-file .download-progress-bar-success::before {
  content: counter(progress) '%\2002';
}

.pdn-sider-menu-tree .ant-tree-title {
  cursor: pointer;
}

.decap-default-title {
  float: left;
  width: 68%;
}

.decap-default-title:hover {
  width: 100%;
}

.pdn-sider-menu-tree .tree-library-content {
  position: relative;
}

.pdn-sider-menu-tree .my-tree .tree-library-file .ant-tree-node-content-wrapper {
  width: calc(100% - 18px);
}

.fastpi-decap-default.ant-tag {
  position: absolute;
  top: 2px;
  right: 4px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
}

.pdn-sider-menu-tree .ant-tree-title:hover .fastpi-decap-default {
  display: none;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-warning-icon {
  visibility: visible !important;
  display: inline-block;
  line-height: 30px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-warning-icon {
  margin-right: 20px !important;
}

.pdn-warning-tooltip .ant-tooltip-inner {
  color: #ff0000;
}

.pdn-warning {
  max-width: 86%;
}

.PDN-debug-tooltip-span {
  display: inline-block;
  max-width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: red;
}

.pdn-success-tooltip-span {
  display: inline-block;
  max-width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.anticon.anticon-exclamation-circle.debug-exclamation-SPIM-icon {
  visibility: initial;
  font-size: 15px;
  line-height: 36px;
  color: #f39300;
  top: 9px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .debug-exclamation-SPIM-icon-hover {
  right: 82px;
}

.anticon.anticon-question-circle.debug-question-SPIM-children-icon {
  visibility: initial;
  font-size: 15px;
  line-height: 36px;
  color: red;
  top: 9px;
}

.fastpi-missing-spim-span {
  width: 80%;
}

.pdn-sider-menu-tree .ant-tree-title .anticon-play-circle {
  visibility: visible;
  transition: right .2s linear;
  right: 38px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-batch-delete-icon {
  visibility: visible;
  transition: right .2s linear;
}

.pdn-sider-menu-tree .ant-tree-title:hover .anticon-play-circle {
  right: 63px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-batch-delete-icon {
  right: 38px;
}

.pdn-package-library-error-span,
.pdn-sider-menu-tree .tree-node-pkg-name .ant-tree-title:hover .pdn-package-library-error-span {
  color: #f13140;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-file-text-icon {
  right: 58px;
}

.pdn-layout-check-icon {
  margin-top: -2px;
}

.pdn-sider-menu-tree .ant-tree-title .pdn-layout-check-icon {
  position: absolute;
  right: 36px;
  visibility: hidden;
}

.pdn-sider-menu-tree .ant-tree-title:hover .pdn-layout-check-icon {
  visibility: visible;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-layout-check-loading-icon {
  right: 65px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
  right: 65px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}
.pdn-setting {
  height: 100%;
  min-width: 1100px;
}

.pdn-setting>.ant-spin-nested-loading>.ant-spin-container {
  padding: 20px;
}

.space-10 {
  margin-top: 10px;
}

.space-16 {
  margin-top: 16px;
}

.voltage-input-box {
  margin-top: 10px;
  width: 50%;
  margin-left: 20px;
}

.pdn-components-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap,
.pdn-powerdomain-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap,
.pdn-vrm-tables .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  white-space: normal;
}

.pdn-vrm-tables .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  overflow: visible !important;
}

.pdn-components-table .ant-table-column-title {
  padding-left: 4px;
}

.pdn-components-part-name {
  margin-left: -4px;
}

.pdn-components-table .select-width .ant-select-selection,
.pdn-powerdomain-table .select-width .ant-select-selection,
.pdn-vrm-tables .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.pdn-components-table .editable-row:hover .editable-cell-value-wrap,
.pdn-powerdomain-table .editable-row:hover .editable-cell-value-wrap,
.pdn-vrm-tables .editable-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9;
  background: #e6f7ff;
}

.pdn-components-table tbody>tr>td>span,
.pdn-components-table tbody>tr>td>.pdn-component-td>span,
.pdn-powerdomain-table tbody>tr>td>span,
.pdn-vrm-tables tbody>tr>td>span {
  display: block;
  max-height: 80px;
  overflow: auto;
}

.pdn-components-table tbody>tr>td .ant-select-selection--multiple,
.pdn-powerdomain-table tbody>tr>td .ant-select-selection--multiple,
.pdn-vrm-tables tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.pdn-powerdomain-table tbody>tr>td .ant-select-selection--multiple .ant-select-selector>ul>li {
  height: 24px;
  line-height: 22px;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.components-pkg-name {
  color: #909090;
  padding-left: 5px;
}

.components-pkg-name:hover {
  cursor: not-allowed;
}

.pdn-components-ignore-name {
  color: #e65b5b;
  padding-left: 5px;
}

.pdn-components-table .editable-cell-value-wrap .pdn-components-ignore-name {
  padding-left: 0px;
}

.pdn-components-unused-name {
  color: #e65b5b;
}

.pdn-select-dropdown-menu .ant-select-dropdown-menu-item {
  position: relative;
  padding-left: 30px;
}

.pdn-select-dropdown-menu .ant-select-dropdown-menu-item .ant-select-selected-icon {
  text-align: left !important;
  left: 10px !important;
  font-weight: bold;
  /* color: #1890ff; */
}

.vrm-default-name {
  color: #939593;
}

.power-domain-name {
  color: rgba(0, 0, 0, 0.85);
}

.pdn-components-name {
  padding-left: 5px;
}

.decap-dialog-close {
  position: absolute;
  right: 12px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.decap-dialog-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.decap-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.VRM-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.VRM-setting-icon {
  color: #f39300;
  font-size: 17px;
  margin-left: 12px;
  cursor: pointer;
  vertical-align: middle;
  margin-top: -2px;
}

.pdn-component-prefix-prompt {
  display: inline-block;
  transform: translate(2px, -25px);
  font-size: 26px;
  vertical-align: middle;
  position: absolute;
  color: #f39300;
  background-color: #ffffff;
  cursor: pointer;
}

.pdn-components-table .ant-table>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  word-break: break-word;
}

.pdn-components-table td {
  position: relative;
}

.pdn-component-arrow {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #969696;
  width: 0;
  height: 0;
  position: absolute;
  right: 26px;
  bottom: 6px;
}

.extraction-main {
  margin-left: 20px;
}

.advanced-main {
  margin: 10px 20px;
}

.extraction-content {
  line-height: 28px;
  margin-bottom: 4px;
}

.extraction-content .extraction-input {
  display: inline-block;
  width: 44%;
  vertical-align: middle;
  height: 28px;
}

.extraction-input .ant-input-group,
.extraction-input .ant-input-group .ant-input,
.extraction-input .ant-input-group .ant-input-group-addon,
.extraction-vrm-input .ant-input-group,
.extraction-vrm-input .ant-input-group .ant-input,
.extraction-vrm-input .ant-input-group .ant-input-group-addon {
  height: 28px;
}

.extraction-vrm-input {
  width: 40%;
}

.extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  padding-right: 10px;
}

.advanced-vrm-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  padding-right: 10px;
}

.simulation-content-body,
.vrm-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 78%;
}

.sub-extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  text-align: 20px;
  font-style: italic;
}

.extraction-name {
  font-size: 16px;
  font-weight: bold;
}

.advanced-name {
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
}

.advanced-name span {
  cursor: pointer;
}

.extraction-option-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.extraction-tooltip {
  z-index: 10000;
}

.sub-extraction-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.pdn-component-split-panel {
  margin-top: 40px;
}

.model-color {
  color: #a5a5a5;
}

.pdn-component-advance {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 20px;
}

.pdn-component-split-part-select,
.pdn-component-split-table {
  margin: 8px;
}

.pdn-component-split-select-dropdown {
  z-index: 1000000;
}

.pdn-component-split-select {
  margin-left: 10px;
  margin-right: 20px;
  width: 200px;
}

.pdn-component-split-row,
.pdn-component-split-row>td {
  background-color: #e6ffed !important;
}

.pdn-component-split-row:hover>td,
.pdn-component-split-row:hover .editable-cell-value-wrap {
  background-color: #e6ffed !important;
}

.pdn-component-split-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.pdn-component-select-row,
.pdn-component-select-row>td {
  background-color: #adddfd !important;
}

.pdn-component-select-row:hover>td,
.pdn-component-select-row:hover .editable-cell-value-wrap {
  background-color: #adddfd !important;
}

.pdn-component-select-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.pdn-component-merge-row,
.pdn-component-merge-row>td {
  background-color: #fffbdd !important;
}

.pdn-component-merge-row:hover>td,
.pdn-component-merge-row:hover .editable-cell-value-wrap {
  background-color: #fffbdd !important;
}

.pdn-component-merge-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.pdn-component-advance-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.pdn-setup-title-color {
  color: #0b3694;
}

.pdn-setup-border {
  border: 1px solid #a8c4e6;
  /* border: 1px solid #c0d3eb;
  background: #f1f8ff; */
  padding: 10px;
  border-radius: 3px;
}

.pdn-setting .ant-table-small {
  border: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.pdn-setting .ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 0;
}

/* pdn top bar */
#pdn-top-bar-block {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.pdn-top-bar-item {
  padding: 6px 14px;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
}

.pdn-top-bar-item span {
  display: flex;
  align-items: center;
}

.pdn-top-bar-item:hover {
  background-color: #edebe9;
}

.pdn-top-bar-item .iconfont,
.pdn-top-bar-item .anticon-play-circle,
.pdn-top-bar-item .anticon-setting,
.pdn-top-bar-item .anticon-loading {
  margin-right: 8px;
  font-size: 16px;
}

.pdn-top-bar-item .icon-Debug {
  font-size: 19px;
  line-height: 20px;
}

.pdn-top-bar-item+.pdn-top-bar-item {
  margin-left: 20px;
}

.pdn-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.pdn-top-bar-item-hide {
  height: 20px;
  line-height: 20px;
}

.pdn-top-bar-tip .ant-tooltip-inner {
  color: #00aaff;
  background-color: #fff;
}

.pdn-top-bar-tip .ant-tooltip-arrow::before {
  background-color: #fff;
}

#pdn-top-bar-block.hide .pdn-top-bar-item .iconfont,
#pdn-top-bar-block.hide .pdn-top-bar-item .anticon-play-circle,
#pdn-top-bar-block.hide .pdn-top-bar-item .anticon-setting,
#pdn-top-bar-block.hide .pdn-top-bar-item .anticon-loading {
  margin-right: 0px;
}

#pdn-top-bar-block.hide .pdn-top-bar-item {
  margin-left: 10px;
}

#pdn-top-bar-block.hide .pdn-top-bar-item .pdn-top-bar-item-hide {
  display: none;
}

.pdn-include-extended-content {
  display: inline-block;
  margin-left: 30px;
}

.pdn-include-extended-content .pdn-include-extended-title {
  vertical-align: middle;
  font-weight: 600;
}

.pdn-include-extended-content .ant-switch-small {
  margin-left: 10px;
  vertical-align: middle;
}

.pdn-powerdomain-error-tip {
  padding: 4px 8px;
}

.pdn-powerdomain-error-tip>span:first-child {
  font-weight: 500;
}

.pdn-powerdomain-error-icon {
  color: #FF0000;
  font-size: 14px;
  margin: 0 0 0 14px;
  cursor: pointer;
}

.pdn-powerdomain-error-msg {
  color: #f39300;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.pdn-powerdomain-error-list,
.pdn-powerdomain-error-list li {
  margin: 0;
  padding: 0;
}

.pdn-powerdomain-error-list {
  margin-top: 8px;
}

.pdn-powerdomain-error-net {
  font-weight: 500;
}

.pdn-vrm-title {
  vertical-align: middle;
}

.pdn-part-name {
  padding-left: 4px;
}

.pdn-file-check-span {
  color: #f30518;
  width: 90%;
}

.pdn-file-check-icon {
  position: absolute;
  right: 14px;
  top: 50%;
  margin-top: -9px;
  color: #f13140;
  font-size: 18px;
}

.pdn-vrm-setting-panel {
  width: 400px;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.pdn-vrm-setting-content {
  height: 100%;
  width: 100%;
  padding: 16px;
}

.pdn-vrm-setting-item {
  width: 100%;
  padding: 5px;
  position: relative;
}

.pdn-vrm-setting-select {
  width: 65%;
  border-radius: 4px;
  vertical-align: middle;
}

.pdn-vrm-setting-title {
  font-size: 14px;
  display: inline-block;
  width: 15%;
  vertical-align: middle;
}

.pdn-vrm-setting-button {
  margin-left: 10px;
  vertical-align: middle;
}
.pdn-model {
  padding: 0px 10px 5px;
  width: 100%;
  line-height: 24px;
}

.pdn-model-pd-top-s {
  padding-top: 5px;
}

.pdn-model-last {
  padding-bottom: 5px;
}

.pdn-model-input {
  margin-top: 5px;
}

.decap-dialog-title {
  cursor: pointer;
  height: 40px;
  padding: 10px;
  border-bottom: solid 1px #e8e8e8;
}

.decap-dialog-title .decap-dialog-title-content {
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.decap-dialog-close {
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
  z-index: 99999;
}

.decap-dialog-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.decap-model-add {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 12px;
  text-align: right;
  line-height: 30px;
  transition: 0.3s;
  z-index: 99999;
}

.decap-model-add .anticon-plus-circle {
  font-size: 15px;
  color: #63aef3;
}

.decap-model-edit {
  position: absolute;
  right: 17px;
  top: 0px;
  cursor: pointer;
  width: 15px;
  height: 30px;
  border-radius: 12px;
  text-align: right;
  line-height: 30px;
  transition: 0.3s;
  z-index: 99999;
}

.decap-model-edit .anticon-edit {
  font-size: 12px;
  color: #63aef3;
}

.decap-model-delete {
  position: absolute;
  right: 2px;
  top: 0px;
  cursor: pointer;
  width: 15px;
  height: 30px;
  border-radius: 12px;
  text-align: right;
  line-height: 30px;
  transition: 0.3s;
  z-index: 99999;
}

.decap-model-delete .anticon-close {
  font-size: 12px;
  color: #63aef3;
}

.pdn-model-box-title {
  padding-left: 10px;
  width: 200px;
}

.pdn-model-box-title .pdn-model-box-title-content {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.pdn-model-selection {
  float: left;
  width: 190px;
}

.pdn-model-selection-dropdown {
  max-height: 200px;
  overflow: auto;
  /* margin-top: 3px; */
  width: 100%;
  background-color: #fff;
  overflow-anchor: none;
  border-radius: 4px;
}

.pdn-model-selection-dropdown .menu-list {
  margin: 2px;
  padding-inline-start: 0px;
}

.pdn-model-selection-dropdown .menu-list li {
  padding: 3px 10px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pdn-model-selection-dropdown .menu-list li:hover {
  background: #e6f7ff;
}

.pdn-model-selection-dropdown .menu-list li:hover .menu-item-check {
  visibility: visible;
}

.pdn-model-selection-dropdown .menu-list .menu-selected-item {
  background: #fafafa;
  font-weight: 700;
}

.pdn-model-value-error-msg {
  display: block;
  margin-top: 5px;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-bottom: 2px;
}

.pdn-apply-dialog {
  padding: 5px 10px;
  width: 100%;
  line-height: 24px;
  float: left;
  display: inline-flex;
}

.pdn-apply-model-title {
  width: 180px;
  margin-left: 5px;
}

.menu-no-result {
  padding-left: 15px;
  font-weight: 700;
  position: absolute;
  height: 30px;
  width: 100%;
  margin-top: 5px;
  z-index: 9999;
  line-height: 28px;
}

.system-library-search-title {
  margin: 5px 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
}

.system-library-search-item {
  line-height: 28px;
}

.system-library-search-span {
  padding-left: 5px;
  width: 130px;
  display: inline-block;
}

.system-library-search-input,
.system-library-search-select {
  width: calc(100% - 130px);
}

.system-library-search-clear {
  position: absolute;
  right: 8px;
  top: 5px;
  font-size: 12px;
  cursor: pointer;
}

.system-library-search-clear:hover {
  color: #63aef3;
}

.system-library-search-input .ant-input-group-addon {
  width: 30px;
}

.system-library-select-span {
  display: inline-block;
  width: 100%;
}

.model-select-content {
  padding: 0 10px;
  display: flex;
  overflow: hidden;
}

.pdn-model-select-dialog {
  width: 340px;
}

.model-select-menu-box .model-select-menu .model-select-menu-item-selectd {
  background-color: rgb(186, 231, 255);
}

.model-select-content .ant-menu-item {
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding: 0;
}

.model-select-content .model-select-menu-item {
  border-top: solid 1px #e8e8e8;
  text-align: left;
  padding: 0 5px;
}

.ant-menu-item .decap-model-name-list .decap-model-name-title {
  height: 100%;
  width: calc(100% - 25px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.decap-model-name-title .ant-input {
  padding: 0;
  height: 30px;
  border: none;
  background: none;
  border-radius: 0;
}

.model-select-content .decap-models-content {
  padding: 0 5px;
  text-align: left;
  font-size: 15px;
  color: #000;
  cursor: default;
}

.model-select-menu-box {
  padding: 5px 0;
  border-right: solid 1px #e8e8e8;
  width: 110px;
  min-height: 180px;
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}

.model-select-selection-box .aurora-error-msg-span {
  font-size: 14px;
  width: 190px;
  border-radius: 8px;
}

.model-select-menu-box .model-select-menu {
  padding-right: 10px;
  border: none;
}

.model-select-selection-box {
  padding-top: 10px;
}

.model-select-selection-box .ant-list-item {
  padding-top: 0 !important;
}

.model-select-selection-box .ant-list-item-meta-content {
  padding-bottom: 10px;
  text-align: center;
}
.custom-RLC-panel {
  width: 400px;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.custom-RLC-content {
  height: 100%;
  width: 100%;
  padding: 16px;
}

.custom-RLC-item {
  width: 100%;
  height: 33.33%;
  padding: 10px 0px;
  position: relative;
}

.custom-RLC-item-content {
  display: inline-block;
  width: 77%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  vertical-align: middle;
  padding: 0px 11px;
  cursor: pointer;
}

.custom-RLC-title {
  font-size: 14px;
  display: inline-block;
  width: 23%;
  vertical-align: middle;
}

.custom-RLC-item-select {
  width: 100%;
}

.custom-RLC-item-input {
  border: none;
  height: 30px;
  /* float: left; */
  position: static;
  padding: 0;
  width: .75em;
  max-width: 100%;
  float: left;
}

.custom-RLC-item-tag {
  cursor: pointer;
  margin-top: 4px;
  float: left;
}

.custom-RLC-tags {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.custom-RLC-item-input:hover,
.custom-RLC-item-input:focus {
  border-right-width: 0px !important;
  border-width: 0px;
  box-shadow: 0px 0px 0px #ffffff;
  border-color: #fff;
}

.custom-RLC-item-content .custom-RLC-tags .custom-RLC-item-tag .anticon-close {
  font-size: 10px;
}

.custom-RLC-item-input-box {
  width: auto;
  float: left;
  height: 30px;
}

.custom-RLC-sub-content {
  margin-left: 12px;
}

.custom-RLC-sub-title {
  font-weight: bold;
}

.custom-PMIC-panel {
  width: 700px;
}

.custom-PMIC-item-content {
  width: 77%;
  border-radius: 4px;
}

.custom-RLC-divider {
  margin: 10px 0px;
}

.custom-apply-title {
  font-size: 14px;
  display: inline-block;
  width: 265px;
  vertical-align: middle;
}

.custom-apply-checkbox {
  display: inline-block;
  vertical-align: middle;
}
.pdn-top-option-panel {
  margin-top: 60px;
}

.pdn-top-option-panel-name {
  font-size: 16px;
  font-weight: bold;
}

.pdn-top-option-panel-main {
  margin: 20px;
}

.pdn-top-extraction-panel-main {
  min-width: 650px !important;
  min-height: 440px;
}

.pdn-top-vrm-panel-main {
  min-width: 520px;
}

.pdn-top-opt-panel-main {
  min-width: 567px;
}

.pdn-top-option-panel-main .ant-divider-horizontal {
  margin: 10px 0px;
}

.pdn-top-option-panel-title {
  padding-left: 4px;
  font-weight: 600;
  margin-bottom: 4px;
}

.pdn-top-option-panel-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.pdn-top-option-panel-content-body,
.pdn-top-option-panel-content .comp-extraction-option-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 65%;
  line-height: 28px;
}

.pdn-top-option-panel-content .comp-extraction-option-content-body,
.pdn-top-option-panel-content-extraction-body {
  width: 55%;
}

.pdn-top-option-panel-content-sub-body {
  margin-left: 10px
}

.pdn-top-option-panel-content-title {
  font-weight: bold;
}

.pdn-top-option-panel-content.comp-extraction-option-content .comp-extraction-option-content-input,
.pdn-top-option-panel-content .pdn-top-option-panel-content-input.pdn-top-option-panel-content-extraction-input {
  width: 45%;
}

.pdn-top-option-panel-content .pdn-top-option-panel-content-input,
.pdn-top-option-panel-content .comp-extraction-option-content-input {
  display: inline-block;
  width: 35%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.pdn-top-option-panel-content-input {
  font-size: 13px;
}

.pdn-top-option-panel-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.pdn-top-option-panel-content-input .ant-input-group-addon,
.pdn-top-option-panel-content-input .ant-input-group-addon .ant-select-selection-selected-value {
  font-size: 13px;
}

.pdn-top-option-panel-content .comp-extraction-option-content-core-input.comp-extraction-option-content-input {
  width: calc(22% - 23px);
}

.pdn-top-option-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.pdn-top-option-panel-sub-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.pdn-top-option-panel-sub-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 65%;
  text-align: 20px;
  font-style: italic;
}

.pdn-top-option-panel-sub-content-extraction-body {
  width: 55%
}

.PDN-top-sus-report-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.PDN-top-sus-report-panel .top-sus-report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0 0;
  height: 100%;
  min-width: 500px;
}

.PDN-top-sus-report-panel button.panel-modal-minimize-button {
  right: 34px;
}

label.PDN-report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.PDN-report-select {
  width: calc(100% - 84px);
  font-size: 13px;
  margin: 6px 0;
}

.PDN-report-select-dropdown {
  z-index: 1000000;
}

.panel-ant-modal-header.panel-modal-header {
  padding-left: 14px !important;
}

.PDN-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}

.PDN-thumbnail {
  width: calc(100% - 92px);
  display: inline-block;
  vertical-align: middle;
  margin: 6px 0;
  margin-left: 10px;
}

.PDN-top-report-message-main {
  margin-top: 17px;
  position: relative;
  min-height: 27px;
}

.PDN-report-download-button {
  position: absolute;
  bottom: 2px;
  right: 0px;
}

.PDN-report-download-btn {
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.PDN-top-report-message-main .PDN-report-download-message.download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.PDN-top-report-message-main .PDN-report-download-message.download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.PDN-report-download-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: calc(100% - 90px);
  margin-bottom: 10px;
  padding: 0 8px;
}

.PDN-top-sus-item {
  margin: 6px 6px 6px 0px;
  float: left;
  text-align: center;
  cursor: pointer;
  background-color: #f3f2f1;
  transition: all 0.3s;
  color: #005a9e;
}

.PDN-top-sus-item:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.PDN-top-sus-report-item {
  position: relative;
  margin-right: 6px;
}

.PDN-top-sus-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.PDN-top-sus-report-progress {
  width: 0px;
  height: 100%;
  background-color: #82c2fd;
  float: left;
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
  z-index: 1;
}

.PDN-report-progress-span {
  height: 33px;
  display: block;
  float: left;
  line-height: 33px;
}

.PDN-top-sus-report-icon {
  z-index: 2;
}

.pdn-top-bar-item-hide {
  z-index: 2;
}

.simulate-content-select {
  width: 35%;
}

.simulate-content-select {
  height: 28px;
}

.simulate-content-select .ant-select-selector {
  line-height: 26px;
}

.PDN-simulate-select-dropdown-menu {
  z-index: 10000;
}

.fastpi-pdn-option-input-addonAfter .ant-input-group-addon {
  width: 65px;
}

.fastpi-simulate-select-dropdown .ant-select-dropdown-menu-item-selected {
  font-weight: 500;
}

.design-failed-Option {
  color: #ff0000 !important;
}

.pdn-sim-dropdown {
  padding: 0 0;
}

.pdn-sim-dropdown-checkbox {
  margin-right: 18px;
  margin-top: -5px;
}

.ant-dropdown .pdn-sim-dropdown-run.ant-dropdown-menu-item {
  text-align: center;
  background: #e6f7ff;
  color: #1890ff;
  margin-top: 5px;
  cursor: pointer;
}

.ant-dropdown .pdn-sim-dropdown-run.ant-dropdown-menu-item>span {
  justify-content: center;
}

.ant-dropdown .ant-dropdown-menu-item.pdn-sim-dropdown-run:hover {
  background: #1890ff;
  color: #ffffff !important;
}

.ant-dropdown .pdn-sim-dropdown-run-disabled.ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu-item.pdn-sim-dropdown-run-disabled:hover {
  background: #eeeeee;
  color: #555555 !important;
  cursor: not-allowed;
}

.pdn-extraction-panel-sub-span {
  padding-left: 20px;
}

.fastpi-extraction-option-panel {
  box-shadow: 0 -2px 7px 8px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.pdn-top-option-panel-content .fastpi-extraction-option-clipping-input {
  width: calc(45% - 134px);
}

.fastpi-extraction-option-panel .preview-button,
.fastpi-extraction-option-panel .preview-button-focus {
  height: 28px;
  float: right;
  width: 80px;
  margin-right: 0px;
}

.fastpi-extraction-option-panel .fastpi-clipping-btn {
  margin-right: 16px;
}

.pdn-top-option-panel-engine-option {
  width: 55%;
}

.fastpi-top-option-panel-advanced-body {
  color: #8c8c8c;
  cursor: pointer;
}

.fastpi-top-option-panel-advanced-body .advanced-arrow-icon {
  font-size: 10px;
  padding-right: 5px;
}

.fastpi-extraction-panel-next-sub-span {
  padding-left: 40px;
}

.pdn-top-option-panel-content .fastpi-option-advanced-mix-input {
  width: calc(45% - 52px) !important;
  margin-left: 24px;
}
.pdn-result-file-name {
  transition: all 0.3s;
  margin-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  line-height: 24px;
}

.pdn-result-file-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.file-close {
  width: 12px;
  height: 12px;
  display: inline-block;
}

.file-close:hover {
  cursor: pointer;
}

.pdn-result-checkbox-group {
  width: 100%;
  padding-left: 10px;
}

.file-expand-icon {
  padding-right: 4px;
  float: left;
  line-height: 28px;
  font-size: 10px;
  display: block;
  height: 24px;
  width: 24px;
}

.title-expand-icon {
  padding-right: 10px;
  float: left;
  color: rgba(0, 0, 0, 0.65);
  font-size: 10px;
  line-height: 18px;
}

.setting-box-sparam {
  width: 220px;
  height: auto;
}

.sp-axis-title {
  cursor: pointer;
}

.sp-axis-content>div {
  margin: 3px 0;
}

.sp-axis-content>div>div {
  display: flex;
}

.sp-axis-content>div>div .ant-select {
  width: 75px;
  height: 28px;
}

.sp-axis-content>div>div .sp-setting-select {
  width: 120px;
}

.sp-setting-input {
  width: 102px;
  margin-left: 0px;
  height: 28px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  vertical-align: top;
}

.sp-setting-input input {
  height: 28px;
}

.padding-left-42 {
  padding-left: 42px;
}

.parameter-setting-box {
  width: 100%;
  height: auto;
  padding-bottom: 2px;
}

.parameter-setting-box .ant-row-flex-middle {
  margin: 2px 0;
}

.clear-all {
  margin-top: 10px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  border-color: #40a9ff;
  font-size: 18px;
  padding: 0px;
  line-height: 32px;
}

.pdn-result-parameter-file-list {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 318px;
  padding: 12px 0 12px 12px;
}

.pdn-result-parameter-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 20px;
  left: 0px;
  margin-left: 8px;
}

.pdn-result-parameter-left {
  background-color: #fff;
  border-left-color: #dcdcdc;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 22%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tool-left-1 .tool-upload-btn {
  color: #40a9ff;
  border-color: #40a9ff;
  font-weight: bold;
  height: 32px;
  padding: 0px;
  line-height: 32px;
  width: 130px;
  margin-left: 10px;
  margin-right: 10px;
}

.font-bold {
  font-weight: bold;
}

.sp-select-row {
  line-height: 28px;
  flex-wrap: nowrap;
}

.sp-select-col {
  width: 54px;
}

.sp-select-box {
  overflow: auto;
}

.sp-file-select {
  width: 200px;
}

.pdn-result-checkbox-group>.ant-checkbox {
  top: 0px;
}

.pdn-result-parameter-file-list .pdn-result-file-name .span-file-name,
.pdn-result-parameter-file-list .pdn-result-file-name .span-import-file-name,
.pdn-result-parameter-file-list .pdn-result-file-name .span-history-file-name,
.pdn-result-parameter-file-list .pdn-result-file-name .span-file-name-opt {
  float: left;
  max-width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pdn-result-parameter-file-list .pdn-result-file-name .span-file-name {
  max-width: calc(100% - 60px);
}

.pdn-result-parameter-file-list .pdn-result-file-name .span-file-name-opt {
  font-weight: bold;
}

.pdn-result-parameter-file-list .pdn-result-file-name .span-history-file-name {
  max-width: 90%;
}

.pdn-result-main,
.pdn-result-main .ant-spin-nested-loading,
.pdn-result-main .ant-spin-container {
  height: 100%;
}

.pdn-result-main {
  padding-top: 44px;
}

.pdn-result-main .pdn-result-setting {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 50%;
  bottom: 0;
}

.sp-unit-select {
  vertical-align: top;
  width: 70px;
}

.sp-unit-select .ant-select-selection {
  position: relative;
  padding: 0 10px;
  padding-left: 6px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 28px;
  border-left: none;
}

.sp-unit-select .ant-select-arrow {
  right: 4px;
}

.sp-unit-select .ant-select-selector {
  margin-right: 0;
  margin-left: 0;
  line-height: 28px;
}

.sp-unit-select .ant-select-selector .ant-select-selection-selected-value {
  padding-right: 14px;
}

.pdn-result-curve-current {
  margin-left: 10px;
  vertical-align: middle;
  font-weight: bold;
}

.pdn-result-checkbox-group-name {
  display: inline-block;
  line-height: 20px;
}

.pdn-result-checkbox-group-name .pdn-result-checkbox-label {
  color: #000;
  vertical-align: middle;
  margin-left: 4px;
}

.pdn-result-checkbox-group .ant-checkbox+span {
  height: 26px;
  display: inline-block;
  padding-left: 3px;
}

.pdn-result-parameter-file-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.pdn-result-ports-list {
  padding-left: 10px;
}

.pdn-result-selector-slide {
  cursor: move;
  fill: #337ab7;
}

.pdn-result-curve-mark {
  fill: #ff0000;
}

.pdn-result-mark-text {
  font-size: 10px;
  text-anchor: middle;
}

.pdn-result-main .file-delete-icon {
  position: absolute;
  right: 8px;
  color: #40a9ff;
  margin-top: 4px;
  display: none;
  font-size: 16px;
}

.pdn-result-file-name:hover .file-download-icon,
.pdn-result-file-name:hover .import-file-download-icon,
.pdn-result-file-name:hover .file-delete-icon {
  display: inline-block;
}

.pdn-result-upload {
  position: relative;
  margin-top: 10px;
}

.result-upload-file-button {
  margin-left: 9px;
  margin-right: 12px;
  height: 26px;
  line-height: 23px;
  width: 84px;
  text-align: left;
  padding: 0 5px;
  color: #40a9ff;
  font-size: 17px;
}

/* .pdn-result-file-content {
  overflow-y: auto;
}

.pdn-result-upload-files {
  overflow-y: auto;
} */

.result-progress-bar {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
  vertical-align: top;
}

.result-progress-bar .ant-progress-inner {
  background: #e0e0e0;
}

.result-import-title {
  cursor: pointer;
  padding-right: 1px;
}

.result-setting-expand-icon {
  font-size: 10px;
  padding-right: 10px;
  padding-left: 22px;
}

.pdn-result-target-switch {
  height: 18px;
  line-height: 16px;
  min-width: 34px;
}

.pdn-result-target-switch.ant-switch::after {
  width: 14px;
  height: 14px;
}

.pdn-result-parameter-file-list .ant-divider-inner-text {
  padding-left: 0px !important;
  padding-right: 23px !important;
}

.pdn-result-upload .ant-divider-inner-text {
  padding-left: 0 !important;
  padding-right: 6px !important;
}

.parameter-setting-box .ant-divider-inner-text {
  padding-left: 0px !important;
  padding-right: 30px !important;
}

.pdn-result-save-icon {
  margin-left: 9px;
  margin-right: 12px;
  padding: 0 5px;
  color: #40a9ff;
  font-size: 17px;
}

.pdn-result-save-icon-disabled {
  cursor: not-allowed !important;
  color: #909294;
  margin-left: 9px;
  margin-right: 12px;
  padding: 0 5px;
  font-size: 17px;
}

.pdn-result-show-icon {
  margin-left: 10px;
  margin-right: 14px;
  color: #40a9ff;
  font-size: 17px;
  margin-top: 2px;
}

.pdn-result-history-select-box {
  margin: 6px 6px;
}

.pdn-result-history-select {
  width: 50%;
  margin-left: 10px;
}

.pdn-result-history-panel .panel-body {
  min-height: 157px;
  overflow: auto;
  position: relative;
}

.pdn-result-history-panel {
  box-shadow: 1px 1px 8px 6px rgba(0, 0, 0, 0.15);
}

.pdn-result-history-table {
  margin: 6px 5px;
  background-color: #fff;
}

.pdn-result-component-part {
  width: 100%;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdn-result-history-select-dropdown,
.pdn-result-history-dropdown {
  z-index: 100000000;
}

.pdn-result-view-icon {
  margin-left: 10px;
  margin-right: 14px;
  color: #40a9ff;
  font-size: 17px;
  vertical-align: text-bottom;
  cursor: pointer;
  margin-top: 2px;
}

.pdn-result-popover .ant-popover-inner-content {
  padding: 8px;
}

.pdn-result-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.pdn-result-name {
  width: 200px;
}

.pdn-result-history-span {
  font-weight: bold;
  display: inline-block;
  margin-left: 6px;
}

.pdn-result-history-title {
  transition: all 0.3s;
  margin-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  line-height: 24px;
}

.pdn-result-history-title:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.pdn-result-history-title:hover .file-delete-icon,
.pdn-result-history-title:hover .import-file-download-icon {
  display: inline-block;
}

.pdn-result-history-files {
  padding-left: 8px;
}

.pdn-result-history-model {
  display: inline-block;
  width: 100%;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdn-result-parameter-file-list .ant-divider-horizontal.ant-divider-with-text-left::before,
.parameter-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.pdn-history-panel-content-basic,
.pdn-history-panel-content-compare {
  padding: 0 10px 10px;
  float: left;
  width: 50%;
}

.pdn-result-history-panel-main {
  width: 100%;
  background: rgb(220, 241, 253);
  height: 100%;
}

.pdn-result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
}

.pdn-result-history-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
  width: 200px;
}

.photo-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 6px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); */
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 27px;
  transition: all 0.3s;
}

.photo-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.fastpi-photo {
  width: 19px;
  margin-left: 1px;
}

.pdn-result-history-panel-main .ant-table-footer {
  padding: 8px;
}

.pdn-result-opt-icon {
  margin-left: 10px;
  color: #40a9ff;
  font-size: 16px;
  margin-top: 5px;
}

.span-history-file-name-opt {
  font-weight: bold;
}

.pdn-result-ports-list .ant-checkbox-group {
  width: 100%;
}

.pdn-port-name {
  height: 24px;
  line-height: 24px;
  margin-left: 6px;
  padding-left: 6px;
  cursor: pointer;
  transition: all 0.3s;
}

.pdn-port-name:hover {
  background: #efefef;
  transition: all 0.3s;
}

.pdn-file-expand-icon {
  padding-right: 4px;
  font-size: 10px;
  vertical-align: baseline;
}

.pdn-opt-sum-title {
  margin-left: 10px;
}

.curve-table-box,
.curve-clear-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 44px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 30px;
  transition: all 0.3s;
}

.curve-clear-box {
  top: 83px;
}

.curve-table-box:hover,
.curve-clear-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.fastpi-curve-table-icon {
  font-size: 17px;
  color: #fff;
}

.curve-mark-rect,
.curve-mark-hover-rect {
  width: 30px;
  height: 20px;
  display: inline-block;
  background: #d9d9d9;
}

.curve-box-close,
.curve-mark-close {
  cursor: pointer;
  font-size: 12px;
  font-weight: 100;
  color: #666;
}

.pdn-result-main .file-download-icon,
.pdn-result-main .import-file-download-icon {
  margin-left: 10px;
  color: #40a9ff;
  line-height: 20px;
  display: none;
  font-size: 17px;
  position: static;
  margin-top: 1px;
}

.pdn-result-components-table tbody>tr>td>span,
.pdn-result-components-table tbody>tr>td>.pdn-component-td>span {
  display: inline-block;
}

.pdn-result-components-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.pdn-result-components-table .ant-table-column-title {
  padding-left: 4px;
}

.pdn-result-components-table .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.pdn-result-components-table .editable-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9;
  background: #e6f7ff;
}

.pdn-result-components-table tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.pdn-result-components-table tbody>tr>td .ant-select-selection--multiple .ant-select-selector>ul>li {
  height: 24px;
  line-height: 24px;
}


.pdn-result-components-table .editable-cell-value-wrap .pdn-components-ignore-name {
  padding-left: 0px;
}


.pdn-result-components-table .ant-table>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  word-break: break-word;
}

.pdn-result-components-table td {
  position: relative;
}

.pdn-result-panel-maximize {
  color: #1890ff;
  font-size: 18px;
  margin-right: 26px;
  float: right;
  margin-top: 2px;
  cursor: pointer;
}

.pdn-result-verticalAlign-middle-span {
  display: inline-block;
  width: 1px;
  vertical-align: middle;
}

.pdn-result-verticalAlign-middle-components-span {
  width: calc(100% - 2px);
  vertical-align: middle;
}

.pdn-cacl-table-edit {
  background: #ffffff;
  margin: 6px 0px;
}

.pdn-result-parameter-right .result-zoom-menu {
  top: 76px;
  width: 45px;
  right: -3px;
}

.pdn-result-parameter-right .result-zoom-menu.ant-menu-vertical .ant-menu-item {
  margin-bottom: 10px;
}
.PDN-result-decap-content {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 20px 30px;
}

.pdn-content-left .PDN-result-decap-content {
  overflow: auto;
}

.pdn-result-decap-span {
  display: inline-block;
  margin: 10px 0;
  font-weight: 700;
}

.pdn-table-unstuffed-span {
  margin-left: 10px;
}

.pdn-result-dc-content {
  height: 100%;
  position: absolute;
  top: 45px;
  width: 100%;
}

.dc-Result-Menu {
  height: 45px;
  position: absolute;
  top: 0px;
  background: #f3f2f1;
  z-index: 1000;
  width: 100%;
}

.PDN-dc-Result-Menu {
  margin-top: 44px;
  width: 100%;
}

.PDN-result-DC-content {
  padding: 15px 20px;
  /* overflow: auto; */
}

.PDN-result-DC-content .ant-spin-nested-loading .virtual-table-without-scroll-Y .ant-spin-container {
  padding-bottom: 20px;
}

.download-dc-icon {
  position: absolute;
  left: 160px;
  cursor: pointer;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}

.download-dc-icon:hover {
  color: #1890ff;
}

.PDN-download-icon-vertical-line {
  height: 16px;
  background-color: #c7c7c7;
  width: 1px;
  margin-right: 28px;
}

.ant-btn.dc-export-icon {
  height: 26px;
  padding: 0 8px;
}

.pdn-decap-switch-icon {
  color: #3da4fa;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.dc-result-current-row-red {
  color: #ff600c;
}

.dc-result-table-title-content {
  position: absolute;
  right: 30px;
  display: inline-block;
}

.result-tooltip-overlay {
  display: none;
}

.dc-result-table-title-content.ant-table-filter-icon.ant-dropdown-trigger:hover {
  background-color: #fff !important;
}

.dc-result-table-title-content.ant-table-filter-icon.ant-dropdown-trigger {
  background-color: #fff;
  width: 50px;
  height: 34px;
  font-size: 16px;
  line-height: 39px;
  /* cursor: default; */
}

.dc-result-table .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.dc-result-table-title-content {
  background-color: #fff;
}

.dc-result-table .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  background-color: #fff;
}

.dc-result-table th.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters.ant-table-row-cell-break-word:hover .ant-table-filter-icon.dc-result-table-title-content {
  background-color: #f5f5f5;
}

.dc-result-table-title-content .dc-result-table-title-img {
  height: 22px;
}

.dc-result-chart-plot .plot .domain {
  display: unset;
}

.dc-result-chart-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.dc-result-chart-body .dc-result-chart-item {
  flex: auto;
  border: 1px solid #a8c4e6;
  border-radius: 3px;
  margin-top: 20px;
}

.dc-result-chart-body-unset .dc-result-chart-item {
  max-width: unset;
}

.dc-result-chart-body .dc-result-chart-item-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dc-result-chart-content-table {
  float: left;
  text-align: center;
  max-width: 1100px;
  width: 60%;
  min-width: 687px;
  padding: 0 20px 20px;
}

.dc-result-chart-plot .plot .domain {
  display: unset;
}

.dc-result-chart-plot {
  float: left;
  min-width: 678px;
  max-width: 1100px;
  width: 40%;
  padding: 0px 20px;
  margin: 15px 0;
}

.dc-result-chart-box-border {
  border: 1px solid #e8e8e8;
}

.dc-result-chart-body .ant-table-small {
  border-radius: 0px 0px 4px 4px;
}

.dc-result-chart-body .rocky-result-title {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.dc-result-chart-content-table .overflew-box {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.dc-result-chart-item-flex .dc-result-chart-title {
  /* position: absolute;
  top: 0;
  width: 100%;
  text-align: center; */
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.dc-result-chart-item-flex .dc-result-chart-table-title {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.chart-content-tooltip .ant-tooltip-inner {
  width: 400px;
  overflow: auto;
  max-height: 500px;
}

/* 1024x768, 1280x720, 1366x768, 1440x900, 1680x1050, 1920x1080 1920x1200, 2560x1440 */

@media (max-width: 1840px) {
  .dc-result-chart-body .dc-result-chart-item-flex {
    flex-direction: column;
  }

  .dc-result-chart-body-unset .dc-result-chart-item-flex {
    flex-direction: unset;
  }

  .dc-result-chart-body .dc-result-chart-plot,
  .dc-result-chart-body .dc-result-chart-content-table {
    width: 100%;
    margin: 0;
  }
}

@media (max-width:2560px) {
  .dc-result-chart-body .dc-result-chart-item-flex {
    flex-direction: column;
  }

  .dc-result-chart-body-unset .dc-result-chart-item-flex {
    flex-direction: unset;
  }

  .dc-result-chart-body .dc-result-chart-plot,
  .dc-result-chart-body .dc-result-chart-content-table {
    width: 100%;
    margin: 0;
  }
}
.pdn-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.aurora-content-right {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.aurora-content-right>.ant-spin-nested-loading,
.aurora-content-right>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.aurora-content-top {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.aurora-content-top>.ant-spin-nested-loading,
.aurora-content-top>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.pdn-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #ffffff;
}

.my-pdn-content-tb {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-pdn-content-tb>.ant-spin-nested-loading,
.my-pdn-content-tb>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.my-pdn-content-tb .bottomLineStyle {
  top: auto;
}

.aurora-content .dividingLine {
  top: 0px;
}

.aurora-content>.ant-spin-nested-loading,
.aurora-content>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.pdn-setup-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #4e4e4e;
  z-index: 20;
  opacity: 0.5;
  border-radius: 5px 0 0 5px;
}

.pdn-setup-close .setup-close-icon {
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  cursor: pointer;
  opacity: 1;
}

.pdn-setup-close:hover {
  transition: 0.6s;
  opacity: 0.8;
}

.pdn-pcb-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #fff;
  opacity: 0.8;
  border-radius: 5px 0 0 5px;
}

.pdn-pcb-close .pcb-close-icon {
  font-size: 16px;
  color: #000;
  cursor: pointer;
  opacity: 1;
}

.pdn-pcb-close:hover {
  transition: 0.6s;
  opacity: 1;
}

.pdn-vrm-tables .vrm-model-seletion {
  position: relative;
  overflow: unset;
}

.pdn-vrm-tables .vrm-model-seletion>span:not(.anticon) {
  display: inline-block;
}

.VRM-delete-icon {
  position: absolute;
  right: -25px;
  top: 50%;
  margin-top: -6px;
  color: #ccc;
}

.pdn-vrm-tables .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap .VRM-delete-icon {
  right: -30px;
}

.pdn-vrm-tables .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .VRM-delete-icon {
  color: #1890ff;
}

.pdn-vrm-tables .ant-table-small>.ant-table-content>.aurora-table-body {
  margin-right: 20px;
}

.pdn-vrm-tables-array .ant-table-small>.ant-table-content>.aurora-table-body {
  margin-right: 8px;
}

.fastpi-tb-setup-main,
.fastpi-tb-result-main {
  position: absolute;
  top: 45px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

.fastpi-normal-setup-main {
  top: 45px !important;
}

.fastpi-tb-result-main {
  top: 0px;
}
li {
  list-style: none;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.pdn-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

/* my pdn monitor */

.my-pdn-monitor {
  /*  min-width: 340px; */
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.my-pdn-monitor-title {
  font-weight: bold;
  line-height: 44px;
  padding-left: 20px;
  float: left;
}

.my-pdn-middle {
  position: relative;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
}

.my-pdn-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  margin-right: 14px;
}

.my-pdn-monitor-progress {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.monitor-upload-progress-bar {
  width: 100%;
}

.monitor-upload-progress-bar .ant-progress-text {
  font-weight: bold;
}

.my-pdn-download-btn {
  position: relative;
  z-index: 20;
}

.my-pdn-debug-btn {
  position: relative;
  z-index: 20;
}

.design-msg-style {
  color: #f5222d;
  margin: 0;
  text-align: center;
}

.pdn-simulation-holygrail {
  position: absolute;
  right: 40px;
  left: 0;
  box-sizing: border-box;
  display: flex;
}

.pdn-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.pdn-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 40px;
  margin-right: 12px;
}

.pdn-simulation-msg {
  position: relative;
  float: left;
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-width: 160px;
  text-align: center;
  margin-left: 16px;
  display: flex;
}

.pdn-simulation-span {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
}

.pdn-workflow-msg {
  position: relative;
  float: left;
  height: 44px;
  line-height: 42px;
  text-align: center;
  min-width: 80px;
  margin-left: 20px;
}

.workflow-msg {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 28px;
  font-size: 16px;
}

.pdn-simulation-project {
  margin-right: 10px;
}

.pdn-simulation-title .span-msg,
.pdn-simulation-title .span-msg-project {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
}

.pdn-span-msg-tooltip {
  display: block;
  text-indent: 8px;
}

.pdn-simulation-middle {
  position: relative;
  flex: auto;
  margin-left: 30px;
}

.pdn-simulation-progress {
  position: absolute;
  top: 2px;
  right: 0px;
  left: 0px;
  height: 40px;
  width: 100%;
  line-height: 44px;
  box-sizing: border-box;
}

.simulation-progress-bar {
  width: 100%;
}

.pdn-simulation-button,
.my-pdn-btn,
.pdn-upload-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 20px;
  box-sizing: border-box;
}

.pdn-simulation-progress .ant-progress-outer {
  width: 90%;
}

.pdn-simulation-progress .ant-progress-outer .ant-progress-inner {
  background-color: #fff;
}

.pdn-simulation-progress .ant-progress-text {
  margin-left: 4px;
}

.icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.icon-tooltip .ant-tooltip-arrow {
  border-top-color: #fffdfd;
}

.icon-tooltip {
  z-index: 1000000;
}

.span-msg {
  text-indent: 8px;
}

.pdn-monitor-ul {
  margin: 0;
  padding: 0;
}

.pdn-button {
  font-size: 18px;
}

.pdn-button-icon {
  font-size: 22px;
}

.pdn-simulation-debug-button {
  line-height: 46px;
}

.pdn-button-cancel {
  transform: rotate(90deg);
}

.debug-mes-span {
  display: block;
}

.closed-message {
  display: block;
}

.simulation-waiting-title {
  line-height: 42px;
  font-weight: bold;
  float: right;
}

.pdn-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 45px;
  color: #1890ff;
}

.pdn-simulation-title .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.pdn-simulation-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.pdn-simulation-title .waiting-time-span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
}

.debug-icon-font {
  font-size: 22px;
}

.pdn-simulation-button,
.pdn-cancel-button,
.pdn-kill-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.pdn-cancel-button {
  line-height: 43px;
}

.pdn-kill-button {
  line-height: 45px;
  font-size: 19px;
  margin-left: 18px;
}

.pdn-stackup-error-dialog {
  padding-top: 10px;
  padding-left: 20px;
  color: #ff4848;
}

.pdn-monitor-backbottom {
  position: absolute;
  font-size: 18px;
  right: 40px;
  bottom: 40px;
  opacity: 0.6;
  background-color: #1890ff;
  color: #fff;
  transform: rotate(90deg);
  padding: 4px;
  border-radius: 4px;
}

.pdn-monitor-backbottom:hover {
  opacity: 0.8;
  font-weight: bold;
}
.pdn-warning-title {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
  color: #ff4848;
  font-weight: bold;
}

.error-font-bold {
  font-weight: bold;
}

.pdn-warning-dialog {
  padding: 10px 20px;
  color: #ff4848;
  overflow: unset;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.aurora-simulation-msg {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-width: 160px;
  text-align: center;
  margin-left: 10px;
  height: 44px;
}

.aurora-simulation-span {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
  padding: 0px 6px;
}

.aurora-simulation-project {
  min-width: 80px;
}

.aurora-span-msg,
.aurora-span-msg-project {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
}

.aurora-span-msg-project {
  font-weight: bold;
  margin-right: 10px;
}

.my-aurora-middle {
  position: relative;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
  max-width: 70%;
  flex: auto;
}

.my-aurora-monitor-progress {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.aurora-monitor-upload-progress-bar {
  width: 100%;
}

.aurora-monitor-upload-progress-bar .ant-progress-outer {
  width: 54%;
}

.aurora-monitor-upload-progress-bar .ant-progress-text {
  font-weight: bold;
  vertical-align: unset;
}

.aurora-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 80px;
  margin-right: 12px;
}

.aurora-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 44px;
  color: #1890ff;
}

.aurora-simulation-button,
.aurora-cancel-button,
.aurora-kill-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.aurora-cancel-button {
  line-height: 43px;
}

.aurora-upload-debug-button-icon {
  font-size: 22px;
}

.my-aurora-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.aurora-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.aurora-simulation-holygrail {
  position: absolute;
  right: 0px;
  left: 0;
  box-sizing: border-box;
}

.aurora-span-msg-tooltip {
  display: block;
  text-indent: 8px;
}

.aurora-simulation-title .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.aurora-simulation-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.aurora-simulation-middle {
  position: relative;
  float: left;
  height: 44px;
  overflow: hidden;
  padding-right: 0px;
  box-sizing: border-box;
  float: right;
  margin-top: 2px;
  flex: auto;
}

.aurora-simulation-middle .ant-progress .ant-progress-inner {
  background-color: #f9f9f9;
}

.aurora-simulation-progress {
  position: absolute;
  top: 0px;
  left: 10px;
  height: 40px;
  width: 100%;
  line-height: 44px;
  box-sizing: border-box;
}

.aurora-layout-simulation-progress {
  height: 40px;
  width: 100%;
  line-height: 44px;
  box-sizing: border-box;
  flex: 1 1;
}

.aurora-simulation-progress .simulation-progress-bar {
  width: 93%;
}

.aurora-layout-simulation-progress .simulation-progress-bar {
  width: 93%;
}

/* .aurora-simulation-progress .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 26px);
  padding-right: calc(2em + 28px);
} */

.aurora-simulation-progress .ant-progress-show-info .ant-progress-text {
  margin-left: 4px;
}

.aurora-simulation-middle .simulation-waiting-title {
  line-height: 42px;
  font-weight: bold;
  float: right;
  margin-right: 50px;
}

.aurora-simulation-title .waiting-time-span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
}

.aurora-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 40px;
  margin-right: 12px;
}

.aurora-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 44px;
  color: #1890ff;
}

.aurora-cancel-button {
  float: right;
  line-height: 43px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.aurora-monitor-ul {
  margin: 0;
  padding: 0;
  height: -moz-fit-content;
  height: fit-content;
}

.aurora-simulation-monitor-select {
  max-width: 100%;
  min-width: 54px;
}

.aurora-simulation-monitor-select .ant-select-selection .ant-select-arrow {
  right: 6px;
}

.debug-mes-span {
  display: block;
}

.aurora-stackup-error-dialog {
  padding-top: 10px;
  padding-left: 20px;
  color: #ff4848;
}

.macro-modeling-error-span {
  display: block;
  color: #ff4848;
  line-height: 22px;
  padding: 0px 30px;
}

.aurora-monitor-backbottom {
  position: absolute;
  font-size: 18px;
  right: 40px;
  bottom: 40px;
  opacity: 0.6;
  background-color: #1890ff;
  color: #fff;
  transform: rotate(90deg);
  padding: 4px;
  border-radius: 4px;
  z-index: 999;
}

.aurora-monitor-backbottom:hover {
  opacity: 0.8;
  font-weight: bold;
}

.simulation-waiting-title {
  line-height: 42px;
  font-weight: bold;
  float: right;
}

.aurora-simulation-monitor-select .ant-select-selection .ant-select-arrow {
  right: 6px;
}

.my-aurora-monitor-pre {
  margin: 0px;
  overflow: unset;
  padding: 0 20px;
  padding-top: 14px;
}

.aurora-simulation-monitor {
  padding-top: 14px;
}

.aurora-simulation-monitor .my-aurora-monitor-pre {
  padding-top: 0px;
}

.my-aurora-monitor-pre .error-check-warning-dialog {
  padding: 0px;
}

.aurora-monitor-ul li {
  list-style: none;
  width: 100%;
}

.aurora-monitor-ul li>p {
  margin: 0px;
}

.aurora-repeater-monitor-content,
.aurora-stackup-error-monitor-content {
  margin-bottom: 10px;
}

.aurora-repeater-monitor-content .ant-divider-dashed {
  border-color: #c9c9c9;
  margin: 6px 0;
}

.aurora-stackup-error-monitor-content {
  color: #ff0000;
}

.aurora-stackup-error-monitor-content .aurora-monitor-ul li {
  line-height: 24px
}

.aurora-stackup-error-monitor-content>span {
  display: block;
  line-height: 24px;
}

.aurora-stackup-error-span {
  font-weight: bold;
}

.aurora-monitor-error {
  margin-left: 10px;
}

.aurora-stackup-error-monitor-content .aurora-error-list-display-content {
  padding-left: 10px;
}

.aurora-monitor-list-main {
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.aurora-monitor-list-main .my-aurora-monitor {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 0px;
}

.aurora-monitor-list-content {
  position: absolute;
  top: 0px;
  left: 102px;
  right: 0px;
  bottom: 0px;
  padding-top: 14px;
  padding-left: 8px;
}

.aurora-repeater-monitor-children {
  margin-left: 10px;
}

.aurora-repeater-monitor-children .aurora-repeater-monitor-title {
  font-weight: 500;
}

.aurora-error-content {
  color: #ff4848;
}

.aurora-simulation-selection {
  max-width: 200px;
  min-width: 100px;
  line-height: 42px;
  margin-left: 10px;
}

.aurora-simulation-selection-dropdown {
  z-index: 999999999999;
}
.setup-input-group-flex-box {
  display: flex;
  justify-content: space-between;
}

.setup-input-group-select-item {
  width: 60px !important;
}

.setup-input-group-select-item>.ant-select-selection {
  background-color: #fafafa;
}

.setup-input-group-select-item .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 11px;
}

.setup-input-group-select-item-dropdown {
  z-index: 5000;
}

.setup-input-group-flex-box span.ant-input-group-addon {
  min-width: 60px;
  max-width: 60px;
  padding: 0px;
  text-align: center;
}

.setup-info-circle-icon {
  margin-left: 5px;
}

.setup-info-circle-icon:hover {
  color: rgb(24, 144, 255);
}

.setup-question-circle-icon {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translate(0, -50%);
  color: red;
}

.setup-select-width {
  width: 100%;
}

.setup-select-width-not-exist {
  width: calc(100% - 24px);
  color: red;
}
.andes-create-channel-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-create-channel-panel .create-channel-content {
  padding: 8px 14px;
  min-width: 450px;
}

.andes-create-channel-panel .channel-input-item,
.andes-create-channel-panel .channel-advanced-input-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  height: 28px;
}

.andes-create-channel-panel .channel-edit-input-label {
  width: 80px;
  height: 28px;
  line-height: 28px;
}

.andes-create-channel-panel .channel-input,
.andes-create-channel-panel .channel-select {
  width: calc(100% - 80px);
}

.channel-input-item .aurora-model-name-error-msg {
  width: 100%;
  padding: 0px 10px;
}

.andes-create-channel-panel .channel-advanced-input-item .channel-edit-input-label {
  width: 120px;
}

.andes-create-channel-panel .channel-advanced-input-item .channel-input {
  width: calc(100% - 120px);
}

.andes-create-channel-panel .channel-create-advanced-content {
  background-color: #fff;
}

.andes-create-channel-panel .channel-create-advanced-content>.ant-collapse-item {
  border: 0;
}

.andes-create-channel-panel .channel-create-advanced-content .ant-collapse-content-box {
  padding-bottom: 8px;
  padding-top: 0px;
  padding-right: 0px;
}

.create-channel-content .ant-select-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f8f8f8;
}

.create-channel-signals-table-content {
  margin: 7px 8px 8px 8px;
}

.create-channel-setting-icon {
  vertical-align: text-bottom;
}

.create-channel-component-part-span {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 13px;
}

.channel-create-component-table .cmc-components-usage-columns {
  font-size: 13px;
}
.andes-create-end-to-end-channel-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-create-end-to-end-channel-panel .create-end-to-end-channel-content {
  padding: 8px 14px;
}

.andes-create-end-to-end-channel-panel .end-to-end-channel-input-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  height: 28px;
}

.andes-create-end-to-end-channel-panel .end-to-end-channel-edit-input-label {
  width: 80px;
  height: 28px;
  line-height: 28px;
}

.andes-create-end-to-end-channel-panel .end-to-end-channel-input,
.andes-create-end-to-end-channel-panel .end-to-end-channel-select {
  width: calc(100% - 80px);
}

.end-to-end-channel-input-item .aurora-model-name-error-msg {
  width: 100%;
  padding: 0px 10px;
}
.andes_v2_result {
  padding-left: 22px;
}

.andes-v2-sider-menu-tree .my-tree .tree-node-pcb-name .ant-tree-node-content-wrapper .ant-tree-title>span>.tree-node-pcb-name {
  width: 100%;
}

.andes-v2-sider-menu-tree .my-tree .tree-node-pcb-name .ant-tree-node-content-wrapper .ant-tree-title>span>.andes-v2-tootip-failed {
  width: calc(100% - 36px);
}

.andes-v2-sider-menu-tree .my-tree .tree-node-pcb-name .ant-tree-node-content-wrapper .ant-tree-title:hover>span>.tree-node-pcb-name {
  width: calc(100% - 54px);
}

.andes-v2-sider-menu-tree .my-tree .tree-node-interface-name .ant-tree-node-content-wrapper .ant-tree-title:hover>span>.tree-node-interface-name,
.andes-v2-sider-menu-tree .my-tree .andes-pcb-channel-node-name .ant-tree-node-content-wrapper .ant-tree-title>span>.andes-pcb-channel-node-name {
  padding-right: 64px;
}

.andes-v2-sider-menu-tree .my-tree .tree-node-pcb-name .ant-tree-node-content-wrapper .ant-tree-title:hover>.aurora-project-create-name {
  padding-right: 0px;
}

.andes-v2-sider-menu-tree .my-tree .andes-pcb-channel-node-name .ant-tree-node-content-wrapper .ant-tree-title:hover>span>.andes-pcb-channel-node-name .aurora-tree-verification-span {
  width: 100% !important;
}

.andes-v2-sider-menu-tree .my-tree .andes-pcb-channel-node-name .ant-tree-node-content-wrapper .ant-tree-title:hover>span>.andes-pcb-channel-node-name {
  padding-right: 114px;
}

.andes-v2-sider-menu-tree .my-tree .tree-node-interface-name .ant-tree-node-content-wrapper .ant-tree-title>span>.tree-node-interface-name {
  padding-right: 58px;
}

.andes-v2-sider-menu-tree .my-tree .ant-tree-node-content-wrapper .ant-tree-title .andes-v2-tooltip-span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.andes-v2-tootip-failed {
  color: #ff0000;
}

.anticon.anticon-exclamation-circle.design-failed-icon {
  visibility: visible;
  visibility: initial;
  font-size: 15px;
  line-height: 36px;
  color: #ff0000;
}

.andes-v2-sider-menu-tree span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .design-failed-icon {
  right: 38px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-simulate-icon {
  visibility: visible;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-tree-simulate-icon {
  right: 38px;
}

.andes-v2-sider-menu-tree .ant-tree-title .andes-v2-status-check-icon {
  line-height: 28px;
  display: inline-block;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-status-icon {
  visibility: visible;
  position: absolute;
  right: 14px;
  top: 0px;
  font-size: 17px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-status-icon {
  right: 86px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-not-prelayout-status-icon {
  right: 38px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-experiment-status-icon {
  right: 64px;
}

.andes-v2-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover .aurora-status-icon {
  right: 86px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-replace-icon {
  visibility: hidden;
  position: absolute;
  right: -22px;
  margin-right: 0px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-PCB-replace>span {
  visibility: hidden;
  display: inline-block;
  height: 32px;
  width: 16px;
  position: absolute;
  right: 60px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-replace-icon {
  visibility: visible;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
  line-height: 24px;
  height: 22px;
}

.andes-v2-sider-menu-tree .ant-tree-title .tree-node-pcb-add-icon {
  position: absolute;
  right: 38px;
}

.andes-v2-sider-menu-tree .tree-node-project-name .aurora-tree-project-rename-icon {
  margin-right: 48px;
}

.andes-v2-sider-menu-tree .tree-node-pcb-name .aurora-tree-prelayout-rename-icon,
.andes-v2-sider-menu-tree .tree-node-project-name .aurora-tree-sweep-rename-icon {
  margin-right: 24px;
}

.andes-v2-sider-menu-tree .tree-library-file .ant-tree-title:hover .aurora-tooltip-span {
  width: calc(100% - 52px);
}

.andes-v2-sider-menu-tree .tree-library-folder-file .ant-tree-title:hover .aurora-tooltip-span {
  width: 100%;
}

.andes-v2-sider-menu-tree .tree-node-project-name .ant-tree-title:hover>span>.tree-node-project-name {
  padding-right: 70px;
}

.andes-v2-sider-menu-tree .aurora-copy-loading-tree {
  padding-right: 24px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .tree-node-pcb-name .ant-tree-title .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .tree-node-interface-name .ant-tree-title .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .tree-node-project-name .ant-tree-title:hover .aurora-tree-loading-icon {
  right: 36px;
}

.andes-v2-sider-menu-tree .tree-node-project-name .ant-tree-title .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .tree-node-interface-name .ant-tree-title .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .andes-pcb-channel-node-name .ant-tree-title .aurora-tree-loading-icon {
  right: 14px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .design-failed-icon {
  right: 38px;
}

.andes-v2-sider-menu-tree .my-tree .tree-node .ant-tree-node-content-wrapper .ant-tree-title .andes-end-to-end-channel-children,
.andes-v2-sider-menu-tree .my-tree .tree-node .ant-tree-node-content-wrapper .ant-tree-title:hover .andes-end-to-end-channel-children {
  margin-left: 0px;
  width: calc(100% - 30px);
}

.andes-v2-sider-menu-tree .my-tree div.andes-end-to-end-channel-children .ant-tree-node-content-wrapper .ant-tree-title:hover>span {
  padding-right: 0px;
}

.andes-v2-sider-menu-tree .my-tree div.andes-end-to-end-channel-children .ant-tree-title:hover .aurora-status-icon {
  right: 14px;
}

.andes-tree-menu .ant-dropdown-menu-item>.anticon:first-child {
  margin-right: 0px;
}

.andes-tree-menu .ant-dropdown-menu-item {
  padding: 5px 10px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-icon,
.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-icon {
  position: absolute;
  right: 21px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-icon {
  right: 48px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}

.andes-v2-sider-menu-tree .my-tree div.andes-v2-sweep-li .ant-tree-switcher.ant-tree-switcher-noop {
  padding-left: 25px;
}

.andes-v2-sider-menu-tree .my-tree div.andes-v2-sweep-li .ant-tree-node-content-wrapper {
  margin-left: 20px;
}

.andes-v2-sider-menu-tree .my-tree span.andes-v2-sweep-li .anticon-experiment.my-tree-switch-icon {
  color: rgb(151, 151, 151);
}

.andes-v2-sider-menu-tree .my-tree span.andes-v2-sweep-li.ant-tree-treenode-selected .anticon-experiment.my-tree-switch-icon {
  color: #3c5fa5;
}

.andes-v2-sider-menu-tree .my-tree .my-tree-switch-icon {
  margin-top: -5px;
}

.andes-v2-sider-menu-tree .my-tree .aurora-interface-type-tag {
  height: 19px;
}
.library-upload-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

.library-upload-tag-content {
  margin: 10px 20px;
}

.library-upload-tag-title {
  color: #0b3b94;
  font-weight: bold;
  width: 45px;
  display: inline-block;
}

.library-upload-tag-select {
  width: calc(100% - 50px);
}
.file-content-panel {
  box-sizing: content-box;
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.file-content-panel .panel-body {
  position: relative;
  padding: 16px!important;
}

.file-content-panel .dividingLine {
  top: 0px;
}

.file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}
.eye-mask-panel-section {
  display: flex;
  margin-left: 25px;
  margin-bottom: 12px;
}

.eye-mask-panel-name-input {
  width: calc(100% - 76px);
  margin-left: 30px;
  display: inline;
}

.aurora-input-disable.eye-mask-panel-section[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.eye-mask-time-voltage-plot {
  position: relative;
  border-left: 1px solid #e8e8e8;
  overflow: hidden;
  min-width: 400px;
  min-height: 400px;
}

.mask-panel .eye-mask-content-box {
  padding: 10px;
  height: 100%;
}

.mask-panel .eye-mask-content-box .eye-mask-panel-section {
  margin-left: 10px;
}

.mask-panel .eye-mask-content-box .eye-mask-panel-section:last-child {
  height: calc(100% - 40px);
}

.eye-mask-content-box .file-content {
  margin: 23px 20px 0 0;
  overflow: auto;
  max-height: calc(100% - 50px);
}

.eye-mask-table-box.aurora-setup-border {
  margin-right: 12px;
}

.eye-mask-data-table {
  margin-top: 10px;
  position: relative;
  margin-bottom: 16px;
}

.eye-mask-data-table .ant-table .aurora-table-body {
  overflow-x: clip;
}

.eye-mask-data-table .ant-table .aurora-table-body .editable-row td {
  height: 32px;
  position: relative;
}

.eye-mask-data-table .anticon.anticon-plus-circle {
  position: absolute;
  bottom: -19px;
  right: 4px;
}

.eye-mask-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 12px;
}

.eye-mask-delete-icon {
  color: #ccc;
  cursor: pointer;
  position: absolute;
  right: -16px;
  top: 12px;
}

.eye-mask-table-box .ant-table-fixed-header .ant-table-container .ant-table-header {
  padding-right: 10px;
}

.eye-mask-table-input-box span.ant-input-group-addon {
  width: 60px;
  padding: 0px;
}

.eye-mask-table-name-box {
  position: relative;
  display: inline-block;
  width: 100%;
}

.eye-mask-table-name-box>span {
  padding-right: 5px;
}

.eye-mask-table-name-box>.eye-mask-table-info-circle-icon {
  position: absolute;
  bottom: 0px;
}

.eye-mask-panel-left {
  width: 400px;
}

.eye-mask-panel-right {
  flex: 1 1;
  width: calc(100% - 400px);
  line-height: 28px;
}

.eye-mask-panel-right>label {
  margin-left: 8px;
}

.svg-text {
  position: absolute;
  color: #000;
  font-size: 13px;
  cursor: pointer;
}

.svg-text:hover {
  color: #1890ff;
}

.svg-tooltip {
  z-index: 2001;
}

.eye-mask-panel-error {
  color: red;
  font-size: 13px;
  font-weight: bold;
}
.save-result-popover {
  height: 100px;
}

.save-result-popover .ant-popover-inner-content {
  padding: 8px;
}

.result-save-div {
  width: 50px;
  line-height: 44px;
  text-align: center;
  margin-right: 40px;
  float: left;
  height: 45px;
}

.result-save-icon {
  font-size: 19px;
  color: #40a9ff;
  cursor: pointer;
  vertical-align: text-bottom;
}

.save-result-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.save-result-name {
  width: 250px;
}
.advance-mask-inputGroup {
  display: flex;
  align-items: center;
  margin: 5px;
  width: 535px;
}

.advance-mask-add-icon {
  margin: 8px 16px;
  color: #1ca4f3;
}

.advance-mask-add-icon:hover {
  color: #1ca4f3;
}

.advance-mask-dataRate {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding-left: 46px;
}

.advance-mask-dataRate-select {
  width: 420px;
}

.advance-mask-dataRate-divider {
  margin: 0px;
}

.mask-min-inputGroup>span {
  padding-left: 5px;
  padding-right: 5px;
}

.mask-max-inputGroup>span {
  padding-left: 5px;
  padding-right: 5px;
}

.advance-mask-delete-icon {
  margin: 10px 0px 10px 16px;
  color: #1ca4f3;
  font-size: 10px;
}

.advance-mask-delete-icon:hover {
  color: #1ca4f3;
}

.mask-min-inputGroup {
  display: flex;
  justify-content: space-around;
}

.mask-max-inputGroup {
  display: flex;
  justify-content: space-around;
}

.mask-link {
  width: 40px;
  border: 0.5px solid #d9d9d9;
  margin: 0px 10px;
}

.mask-input-show-noData {
  height: 28px;
}

.advance-mask-id {
  width: 100%;
  height: 100%;
}

.mask-input-show>ul {
  padding: 0px;
  margin: 0px;
}

.advance-mask-inputGroupList-box {
  display: flex;
  align-items: flex-start;
  margin: 10px 0px;
}

.advance-mask-inputGroupList {
  display: flex;
  flex-direction: column;
}

.advance-mask-dataRate-title {
  margin-right: 5px;
  width: 78px;
}

.advance-mask-dataRate-select-dropdown {
  z-index: 6000;
}
.result-reference-header {
  font-weight: 500;
  height: 22px;
}

.result-reference-icon {
  font-size: 16px;
  margin-left: 12px;
  transform: translateY(10%);
}

.result-reference-title {
  margin-right: 5px;
}

.result-reference-panel {
  padding: 0px 14px 14px;
}

.result-reference-table {
  margin-top: 10px;
}

.result-reference-input-box {
  padding: 10px 0px 10px 10px;
}

.result-reference-select-box {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.result-reference-flex-item {
  flex: 1 1;
}

.result-reference-label {
  display: inline-block;
  width: 100px;
}

.result-reference-flex-item-select {
  width: calc(100% - 100px);
}

.result-reference-frequency-flex-item-input {
  width: calc(100% - 84px);
}

.result-reference-frequency-flex-item-input .ant-input-group-addon {
  font-size: 13px;
}

.result-reference-divider {
  margin-top: 10px;
  margin-bottom: 0px;
}

.result-reference-collapse {
  border: none;
  background-color: #fff;
  margin-left: 10px;
}

.result-reference-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px 6px 0px;
  width: -moz-fit-content;
  width: fit-content;
  color: #696969;
}

.result-reference-collapse>.ant-collapse-item {
  border-bottom: none;
}

.result-reference-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 70px;
}

.result-reference-collapse .ant-collapse-content {
  border-top: none;
}

.result-reference-collapse .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 5px 16px;
}

.result-reference-frequency-title {
  display: inline-block;
  width: 84px;
}

.result-reference-frequency-title-prefix {
  font-size: 14px;
}

.result-reference-frequency-title-subscript {
  font-size: 10px;
  font-weight: 400;
}

.result-reference-info-circle {
  margin-left: 5px;
  color: #1890ff;
}
 .andes-v2-result-content .sparameter-span-file-name,
 .andes-v2-result-content .sparameter-file-expand-icon {
   vertical-align: middle;
 }

 .andes-v2-result-parameter-right {
   box-sizing: border-box;
   position: absolute;
   top: 0px;
   bottom: 20px;
   margin-left: 8px;
   right: 0px;
   width: 45px;
   height: 100px;
 }

 .andes-v2-result-parameter-right .reference-box {
   justify-content: center;
   align-items: center;
   width: 28px;
   height: 28px;
   background-color: #9191918c;
   position: absolute;
   top: 6px;
   right: 14px;
   cursor: pointer;
   border-radius: 50%;
   visibility: visible;
   box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
   text-align: center;
   line-height: 27px;
   transition: all 0.3s;
 }

 .andes-v2-result-parameter-right .reference-box:hover {
   background-color: #6e6e6e8c;
   transition: all 0.3s;
 }

 .andes-v2-reference-table-icon {
   color: aliceblue
 }

 .andes-v2-reference-line-box {
   pointer-events: none;
 }

 .port-setting-panel-content {
   padding: 10px;
 }

 .port-setting-signal-name {
   font-size: 14x;
   font-weight: 500;
 }

 .port-setting-box {
   margin-left: 10px;
   display: flex;
   justify-content: space-between;
 }

 .port-setting-list.ant-list .ant-list-item {
   display: inline-block;
   width: 100%;
 }

 /* The child element is centered vertically */
 .port-setting-box>.port-box {
   display: flex;
   align-items: center;
 }

 .port-setting-box .port-name {
   display: inline-block;
   max-width: 220px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
 }

 .port-setting-box .tag {
   margin-top: 1px;
   margin-left: 8px;
 }

 .port-setting-box .swap-icon {
   margin-right: 8px;
 }

 .port-setting-box .swap-icon:hover,
 .andes-result-port-icon-group>.port-view-text:hover {
   color: #1677ff;
 }

 .andes-result-port-icon-group {
   display: flex;
   justify-content: end;
 }

 .andes-result-port-icon-group>.port-view-text {
   cursor: pointer;
   margin-right: 10px;
   font-size: 12px;
 }

 .andes-result-port-icon-group>span:nth-child(2) {
   margin-top: 1px;
   margin-right: 16px;
   color: #1677ff;
   cursor: pointer;
 }

 .andes-result-port-icon-group .ant-switch.ant-switch-small {
   min-width: 24px;
   height: 14px;
   line-height: 14px;
   margin-left: 10px;
 }

 .andes-result-port-icon-group .ant-switch.ant-switch-small .ant-switch-handle {
   width: 10px;
   height: 10px;
 }

 .port-setting-panel-search .ant-input-group-addon {
   background-color: #1677ff;
   color: #fff;
 }
.result-generate-btn {
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 100%;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.result-generate-btn-progress {
  background: #82c2fd;
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  right: 100%;
  transition: 0.3s;
}

.result-generate-btn-span {
  position: relative;
  line-height: 30px;
}
/*right*/
/** top */
.aurora-result-mode-tab.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav .ant-tabs-tab-active {
  color: #005a9e;
  background: #c7e0f4;
  border-radius: 0px;
  border-color: #c7e0f4;
  height: 30px;
  /* 
  line-height: 30px; */
}

.aurora-result-mode-tab.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 30px;
}

.aurora-result-mode-tab.ant-tabs-card .ant-tabs-bar {
  margin: 0px;
}

.aurora-result-mode-tab.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav .ant-tabs-tab {
  line-height: 28px;
  border-radius: 0px;
  height: 30px;
}

.aurora-result-mode-tab.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:first-child {
  border-left: 0px;
}

/*** powerSumSetting  */
.tdr-sbr-setting {
  padding: 0px 12px 12px 12px;
}

.tdr-sbr-setting .ant-input-group-wrapper,
.tdr-sbr-setting input.duration-ratio-input {
  width: calc(100% - 120px);
  max-width: 200px;
}

.tdr-sbr-setting .powersum-target-lines-tags-input {
  width: calc(100% - 166px);
}

.tdr-sbr-setting .powersum-setting-tags-input {
  width: calc(100% - 200px) !important;
}

.tdr-sbr-setting .config-title {
  display: inline-block;
  width: 120px;
  line-height: 28px;
}

.tdr-sbr-setting .powersum-config-title {
  display: inline-block;
  width: 200px;
  line-height: 28px;
}

.tdr-sbr-setting .ant-row {
  margin-bottom: 6px;
}

.tdr-sbr-setting .ant-btn {
  width: 100%;
  margin-top: 2px;
}

.tdr-sbr-setting .ant-input-group-addon {
  min-width: 40px;
}

.tdr-sbr-setting .error-msg {
  padding: 6px 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 4px;
  margin-bottom: 6px;
}

/** list */
/** setting */
.aurora-v2-waveform-setting-css {
  padding: 0 10px 10px 10px;
}
.rocky-eye-diagram-main {
  padding: 10px;
  position: relative;
  height: auto;
}

.aurora-result-eyediagram .aurora-eye-item {
  flex: auto;
  border: 1px solid #a8c4e6;
  border-radius: 3px;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 1100px;
}

.aurora-result-eyediagram .aurora-eye-item-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.aurora-eye-item-flex .eye-title {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.aurora-result-eyediagram .signal-select {
  position: absolute;
  left: 76px;
  right: 10px;
  margin-top: 7px;
}

/* .aurora-result-eyediagram .signal-select . {
  height: 26px;
}

.aurora-result-eyediagram .signal-select . .ant-select-selector {
  margin-right: 14px;
  margin-left: 12px;
  line-height: 24px;
  height: 24px;
} */

.signal-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  padding: 4px 12px;
}

.aurora-result-eyediagram .signal-select .ant-select-arrow {
  right: 5px;
  font-size: 10px;
}

.aurora-result-eyediagram .eyediagram-signals,
.aurora-result-eyediagram .eyediagram-result-select {
  height: 40px;
  line-height: 40px;
  padding-left: 16px;
  background: #fafafa;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  border-radius: 4px 4px 0px 0px;
  position: relative;
}

.aurora-result-eyediagram .eyediagram-signals {
  height: 47px;
  line-height: 47px;
}
.andes-v2-result-main .aurora-eye-diagram-main {
  position: absolute;
  top: 44px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-width: 520px;
  overflow: auto;
  padding: 14px 0px;
}

.andes-v2-result-main .aurora-result-eyediagram {
  position: relative;
  width: 100%;
  padding-top: 10px;
}

.andes-v2-eye-close {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: none;
  background-color: #c7e0f4;
  z-index: 40;
  text-align: center;
  line-height: 30px;
  border-radius: 4px 0px 0px 4px;
}

.andes-v2-result-main .aurora-result-eyediagram:hover .andes-v2-eye-close {
  display: block;
}

.andes-v2-eye-close .andes-v2-eye-close-icon {
  font-size: 16px;
  color: #40a9ff;
}

.andes-v2-result-main .aurora-result-half-eyediagram {
  width: 50%;
  min-width: 390px;
}

.andes-v2-eye-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-left {
  width: 100%;
  text-align: center;
  min-height: 100px;
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-left .ant-spin-nested-loading,
.andes-v2-result-main .aurora-result-eyediagram .eyediagram-left .ant-spin-container {
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-setting {
  width: 90%;
  padding: 10px;
}

.andes-v2-result-main .aurora-result-eyediagram .aurora-eye-item-flex {
  padding: 0px 10px;
  padding-bottom: 10px;
}

.andes-v2-result-main .aurora-result-eyediagram .aurora-eye-item {
  margin-top: 0px;
  margin-right: 20px;
  height: 100%;
  max-width: 100%;
}

/* @media (max-width:950px) {
  .andes-v2-result-main .aurora-result-half-eyediagram {
    width: 100%;
  }

  .andes-v2-result-main .andes-v2-eye-list {
    display: block;
  }
} */

.andes-result-eyediagram-title {
  display: inline-block;
  margin: 10px 20px;
  font-weight: bold;
  margin-top: 16px;
}

.andes-v2-eye-select-div {
  height: 28px;
  line-height: 28px;
  margin: 0px 20px;
}

.andes-v2-eye-select-title {
  float: left;
  width: 100px;
  font-weight: bold;
}

.andes-v2-eye-select-div .aurora-select {
  width: calc(100% - 100px);
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-signals {
  border-top: none;
  border-radius: unset;
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-result-select .signal-select {
  color: rgba(0, 0, 0, 0.85);
}

.aurora-eye-diagram-add-div {
  margin: 0px 20px;
  width: 190px;
  padding: 0px 4px;
  font-size: 12px;
  height: 28px;
  line-height: 25px;
}

.aurora-eye-diagram-add-div>span {
  vertical-align: middle;
}

.aurora-eye-diagram-add-div .anticon {
  font-size: 12px;
  vertical-align: middle;
}

.andes-result-eye-param-spec {
  color: #0c5fa3;
}

.andes-eye-diagram-setup-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  word-break: break-word;
}

.eye-setting-table-title {
  color: rgba(0, 0, 0, 0.85);
}

.eye-setting-collapse-content>.ant-collapse-item:last-child>.ant-collapse-header {
  padding: 4px 12px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0px;
}

.eye-setting-collapse-content {
  border: none;
}

.eye-setting-collapse-content .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px;
}

.eye-setting-collapse-content>.ant-collapse-item {
  border-bottom: none;
}

.eye-setting-collapse-content .ant-collapse-content {
  border-top: none;
}

.eye-setting-collapse-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 10px;
}

.aurora-eye-diagram-main .andes-eye-img {
  max-width: 100%;
}

.andes-eye-params-ads-table .ant-table-small {
  border-radius: 0;
}

.andes-eye-diagram-setup-table .ant-table-small {
  border-radius: 0px 0px 4px 4px;
  border-top: none;
}

.andes-eye-params-compliance-table .ant-table-small {
  border-radius: 0px 0px 4px 4px;
}

.andes_eye-params-compliance-table .ant-table-small {
  border-radius: 0;
}

.andes-eye-params-compliance-collapse-table .ant-table-small {
  border-radius: 0;
  border-top: none;
}

.eye-compliance-collapse-content>.ant-collapse-item .ant-collapse-header {
  padding: 4px 12px;
  padding-left: 30px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0px;
}

.andes-eye-params-compliance-collapse-table .ant-table .ant-table-row-indent+.ant-table-row-expand-icon {
  display: none;
}

.eye-param-table-disable-td,
.eye-param-table-fail-td {
  color: #f12727;
}

.eye-param-table-pass-td {
  color: #52c41a;
}
.andes-v2-result-content {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 45px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.andes-v2-result-content .ant-spin-nested-loading,
.andes-v2-result-content .ant-spin-container,
.andes-v2-result-content .rocky-result-parameter-left {
  height: 100%;
}

.andes-v2-result-parameter-left {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.andes-v2-waveform-setting-css {
  padding: 0 10px 10px 10px;
}

.andes-v2-delay-table {
  margin: 10px 20px;
  margin-bottom: 30px;
  overflow: hidden;
}

.tdr-sbr-setting {
  padding: 0px 12px 12px 12px;
}

.tdr-sbr-setting .ant-input-group-wrapper,
.tdr-sbr-setting input.duration-ratio-input {
  width: calc(100% - 120px);
  max-width: 200px;
}

.tdr-sbr-setting .powersum-target-lines-tags-input {
  width: calc(100% - 166px);
}

.tdr-sbr-setting .powersum-setting-tags-input {
  width: calc(100% - 200px) !important;
}

.tdr-sbr-setting .config-title {
  display: inline-block;
  width: 120px;
  line-height: 28px;
}

.tdr-sbr-setting .powersum-config-title {
  display: inline-block;
  width: 200px;
  line-height: 28px;
}

.tdr-sbr-setting .ant-row {
  margin-bottom: 6px;
}

.tdr-sbr-setting .ant-btn {
  width: 100%;
  margin-top: 2px;
}

.tdr-sbr-setting .ant-input-group-addon {
  min-width: 40px;
}

.tdr-sbr-setting .error-msg {
  padding: 6px 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 4px;
  margin-bottom: 6px;
}

.andes-v2-delay-select-div {
  height: 28px;
  line-height: 28px;
}

.andes-v2-delay-table .aurora-select {
  float: left;
  width: calc(100% - 100px);
}

.andes-v2-delay-select-title {
  float: left;
  width: 100px;
  font-weight: bold;
}

.andes-v2-delay-name-item {
  display: inline-block;
  margin: 10px 0;
  font-weight: bold;
}

.andes-v2-delay-result-table {
  border: 1px solid #e8e8e8;
}

.andes-v2-delay-result-table .ant-table-thead tr>th:not(:last-child),
.andes-v2-delay-result-table .ant-table-tbody tr>td:not(.andes-v2-delay-result-column-skew) {
  border-right: 1px solid #e8e8e8;
}

.andes-v2-delay-result-table .ant-table-thead tr>th::before {
  width: 0px !important;
}

.andes-v2-delay-table .ant-table-thead tr>th.andes-v2-delay-result-column-comp {
  text-align: left;
}

.andes-v2-delay-table .ant-table-tbody tr>td.border-bottom-0 {
  border-bottom: 0px;
}
.clip-design-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.clip-design-panel .panel-modal-header {
  padding-left: 14px !important;
}

.clip-design-panel .ant-divider-horizontal {
  margin: 10px 0px;
}

.clip-design-panel-content {
  height: 30px;
  line-height: 26px;
}

.clip-design-panel-content .clip-title {
  font-size: 13px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 150px;
}

.clip-design-panel-content .clip-design-input {
  width: calc(100% - 690px) !important;
  height: 28px;
}

.clip-design-panel-content .clip-design-input .ant-input-group-addon {
  width: 36px !important;
}

.clip-design-panel-content .radio-clip-group {
  margin-left: 28px;
  margin-right: 6px;
}

.clip-design-panel-content .radio-clip-type-group {
  margin-left: 40px;
}

.clip-design-panel-content .ant-row {
  line-height: 28px;
}

.clip-design-panel-content .clip-design-value {
  margin-top: 10px;
}

.preview-button,
.preview-button-focus {
  height: 28px;
  float: right;
  margin-right: 12px;
  width: 80px;
}

.preview-button-focus {
  color: #005a9e;
  background-color: #c7e0f4;
  border-color: #c7e0f4;
}

.preview-button-row {
  text-align: right;
  margin-top: 10px;
}

.clip-design-panel-content .aurora-switch-small.ant-switch-disabled {
  opacity: 1;
}
/* === Extraction options panel === */
.andes_v2-channel-extraction-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes_v2-channel-extraction-panel .panel-modal-header {
  padding-left: 14px !important;
}

.andes_v2-channel-extraction-panel .ant-divider-horizontal {
  margin: 10px 0px;
}


.andes_v2-channel-extraction-option-title {
  font-size: 16px;
  font-weight: 500;
}

.andes_v2-channel-extraction-panel-content {
  padding: 10px 16px;
  min-width: 830px;
  /* min-height: 450px; */
  min-height: 360px;
}

/* === extraction options === */
.andes_v2-channel-extraction-main {
  margin-left: 14px;
  margin-bottom: 10px;
  font-size: 13px;
}

.extraction-setup-footer-options {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.extraction-setup-footer-options>div .extraction-setup-left-options .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.extraction-setup-footer-options>div>div {
  cursor: pointer;
  color: #036fd2;
  font-size: 13px;
}

.extraction-setup-footer-options>div>div:hover,
.extraction-setup-footer-options>.extraction-setup-middle-options:hover {
  color: #1890ff;
}

.extraction-setup-footer-options>div {
  width: 33.33%;
}

.extraction-setup-footer-options>.extraction-setup-middle-options {
  text-align: center;
  color: #036fd2;
  position: relative;
}

.extraction-setup-footer-options>div .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.extraction-setup-footer-options>div>.extraction-setup-left-options .anticon {
  margin-right: 4px;
}

.extraction-setup-footer-options>div>.extraction-setup-right-options .anticon {
  margin-left: 4px;
}

/* .extraction-setup-footer-options>div>div span {
  vertical-align: middle;
} */

.extraction-setup-footer-options>div .modeling-button {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  height: 24px;
  line-height: 23px;
  padding: 0px 12px;
  border-radius: 4px;
  border: 1px solid #1890ff;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  width: 144px;
  position: relative;
  z-index: 10;
}

.extraction-setup-footer-options>div .modeling-button:hover {
  color: #217ed4;
  border-color: #91d5ff;
  background: #e6f7ff;
}

.extraction-setup-footer-options>div>.extraction-setup-left-options {
  float: left;
}

.extraction-setup-footer-options>div>.extraction-setup-right-options {
  float: right;
}

.extraction-setup-footer-options>.extraction-setup-middle-options .andes-extraction-modeling-adet-icon-content .andes-extraction-modeling-adet-icon {
  color: #1890ff;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

.modeling-aedt-menu {
  z-index: 10000000000;
}

.andes-extraction-modeling-adet-icon-content {
  position: absolute;
  left: calc((100% - 144px) / 2);
  top: 0px;
  width: 170px;
  text-align: right;
}

.hybrid-extraction-panel-content .ant-table-placeholder {
  color: rgba(0, 0, 0, 0.45);
}

/* --- Hybrid extraction --- */
.hybrid-extraction-panel-content .hybrid-title {
  font-size: 13px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 150px;
}

.hybrid-extraction-table {
  /*  max-height: 200px; */
  padding-left: 10px;
  /* overflow: auto; */
}

.select-extraction-setting-content {
  margin-bottom: 10px;
}

.select-extraction-setting-content .aurora-select {
  width: 200px;
}

.andes_v2-channel-extraction-main .extraction-content .extraction-content-title {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}

.andes_v2-channel-extraction-main .extraction-content .extraction-content-advanced {
  color: #036fd2;
  cursor: pointer;
}

.andes_v2-channel-extraction-advanced-panel {
  margin-top: 150px;
}

.andes_v2-channel-extraction-advanced-panel .ant-divider-horizontal {
  margin: 10px 0;
}

.andes_v2-channel-extraction-advanced-panel .andes_v2-channel-extraction-advanced-content {
  padding: 10px 16px;
  min-width: 930px;
}

.andes_v2-channel-extraction-advanced-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  height: 50px;
  line-height: 30px;
}

.andes_v2-channel-extraction-advanced-footer button.ant-btn {
  height: 26px;
  font-size: 13px;
}

.andes_v2-channel-extraction-advanced-footer .extraction-advanced-footer-sides {
  cursor: pointer;
  color: #036fd2;
  font-size: 13px;
}

.SIwave-extraction-options-content .extraction-content-body {
  width: 310px;
}

.SIwave-extraction-setting-content .extraction-content .extraction-content-body {
  width: 130px;
}

.SIwave-extraction-setting-content>span.extraction-content-body {
  width: 310px;
}

.SIwave-extraction-setting-content .extraction-content,
.SIwave-extraction-options-content .extraction-content,
.SIwave-extraction-airbox-content .extraction-content {
  padding-left: 20px;
}

.extraction-SIwave-radio-item.ant-radio-wrapper {
  margin-right: 60px;
}

.SIwave-extraction-airbox-content>.extraction-content>div {
  display: inline-block;
  width: 50%;
}

.SIwave-extraction-setting-content .radio-hfss-type-group {
  margin-left: 30px;
  width: 98%;
}

.SIwave-extraction-setting-content .radio-hfss-type-group>label {
  width: 22%;
}

.extraction-content-body-span {
  margin-left: 28px;
}

.extraction-content .extraction-specify-switch {
  margin-right: 25px;
}

.extraction-content-back-frequency-input {
  display: inline-block;
  width: calc(100% - 158px);
  padding-left: 29px;
}

.SIwave-extraction-setting-content .extraction-content-back-frequency-input .extraction-content-body {
  width: 125px;
  padding-left: 25px;
}

.SIwave-extraction-setting-content .extraction-content-back-frequency-input .extraction-input {
  width: calc(100% - 133px);
}

.extraction-setup-footer-options>div>.extraction-restore-button {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  padding: 0 12px;
  margin: 4px 0;
  line-height: 23px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #1890ff;
}

.extraction-content.extraction-points-content {
  display: flex;
  justify-content: space-between;
}

.extraction-points-content .extraction-points-content-body {
  color: #036fd2;
  width: 173px;
}

.extraction-content.extraction-points-content>div {
  width: calc(100% - 180px);
}

.extraction-content.extraction-points-content>div .extraction-input-item {
  width: calc(35% - 42px);
}

.andes_v2-channel-extraction-Misc-content>.extraction-content>.extraction-content-body {
  width: 170px;
}

.andes_v2-channel-extraction-Misc-content .extraction-content .extraction-content-lic-wait-input,
.andes_v2-channel-extraction-Misc-content .extraction-content .extraction-content-time-out-input {
  width: calc(100% - 200px);
}


.andes_v2-channel-extraction-main .extraction-content.extraction-regions-disable-content .ant-spin-nested-loading {
  cursor: not-allowed;
}

.andes_v2-channel-extraction-main .extraction-content.extraction-regions-disable-content .ant-table-thead>tr>th,
.andes_v2-channel-extraction-main .extraction-content.extraction-regions-disable-content .aurora-table-body>tr>td {
  color: #00000040;
}

.andes_v2-channel-extraction-main .hybrid-extraction-panel-content .hybrid-title {
  font-size: 14px;
  font-weight: 600;
  padding-left: 0px;
}

.SIwave-extraction-setting-content .ant-collapse-borderless.ant-collapse-icon-position-left .ant-collapse-item {
  background: #fff;
  border-bottom: none;
}

.SIwave-extraction-setting-content .ant-collapse-borderless.ant-collapse-icon-position-left .ant-collapse-item .ant-collapse-header {
  padding: 4px 16px 4px 40px;
}

.SIwave-extraction-setting-content .ant-collapse-borderless.ant-collapse-icon-position-left .ant-collapse-item .ant-collapse-content-box {
  padding-bottom: 5px;
}

.SIwave-extraction-setting-content .ant-collapse-borderless .ant-collapse-item .ant-collapse-content-box .extraction-content-body {
  width: 260px;
}

.hybrid-extraction-panel-content .hybrid-extraction-radio-content {
  padding-left: 10px;
}

.hybrid-extraction-panel-content .hybrid-extraction-radio-content .ant-radio-group.ant-radio-group-outline {
  width: 100%;
}

.hybrid-extraction-radio-content .ant-radio-group.ant-radio-group-outline .channel-config-item.channel-config-sub-item {
  padding-top: 10px;
}

.hybrid-extraction-panel-content .hybrid-extraction-radio-content .channel-config-sub-item>.ant-spin-nested-loading {
  padding-top: 7px;
}

.andes_v2-channel-extraction-main .extraction-content .extraction-content-title.extraction-content-hfss-title {
  display: inline-block;
  width: 200px;
}

.extraction-content .extraction-input.extraction-hfss-input-width {
  width: calc(100% - 210px);
}

.extraction-content .extraction-input.extraction-hfss-input-width .ant-select-arrow{
  margin-top: -6px;
}

.andes_v2-channel-extraction-main .extraction-content .comp-extraction-option-content-body {
  width: 150px;
  padding: 0px 10px;
  font-size: 13px;
}

.andes_v2-channel-extraction-main .extraction-content .comp-extraction-option-content-body.comp-extraction-option-next-sub-span {
  padding-left: 40px;
}

.andes_v2-channel-extraction-main .comp-extraction-option-content {
  padding: 0px;
}
.andes-v2-channel-config-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-v2-channel-config-panel .panel-modal-header,
.andes-v2-channel-component-panel .panel-modal-header {
  padding-left: 14px !important;
}

.andes-v2-channel-config-title,
.andes-v2-channel-config-title {
  font-size: 16px;
  font-weight: 500;
}

.channel-config-main .ant-popover-inner-content {
  padding: 10px 12px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 8px 0px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.channel-component-main {
  width: 100%;
  min-width: 150px;
}

.andes-v2-channel-config-panel .channel-config-main {
  padding: 10px 16px;
  min-width: 636px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item {
  width: 100%;
  margin: 5px 0px;
  min-height: 28px;
  line-height: 28px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item .aurora-select,
.andes-v2-channel-config-panel .channel-config-content .channel-config-item .aurora-input {
  width: calc(100% - 330px);
  vertical-align: middle;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item>span {
  display: inline-block;
  width: 320px;
  vertical-align: middle;
  font-size: 13px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .ant-radio-group {
  width: 100%;
}

.andes-v2-channel-config-panel .channel-config-title {
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-item>span {
  /* display: inline-block; */
  width: 338px;
  /* vertical-align: middle; */
  /* font-size: 13px; */
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item>span {
  width: 338px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-item .aurora-select,
.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-item .aurora-input {
  width: calc(100% - 338px);
  /* vertical-align: middle; */
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item .aurora-select,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item .aurora-input {
  width: calc(100% - 315px);
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content {
  margin-bottom: 10px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content {
  border-radius: 5px;
  background: #f3f3f3;
  border: 1px solid #e8e8e8;
  padding: 4px 10px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-jitter-nui-item {
  width: calc(100% - 212px);
  margin: 5px 0px;
  margin-left: 212px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-jitter-nui-item>span {
  width: 64px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-jitter-nui-item .aurora-input {
  width: calc(100% - 64px);
}

.andes-v2-channel-config-panel .channel-config-options {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-around;
  /*  margin-top: 10px; */
}

.andes-v2-channel-config-panel .channel-config-options>div>div {
  cursor: pointer;
  color: #036fd2;
  font-size: 13px;
}

.andes-v2-channel-config-panel .channel-config-options>div>div:hover,
.andes-v2-channel-config-panel .channel-config-options>.channel-config-middle-options:hover {
  color: #1890ff;
}

.andes-v2-channel-config-panel .channel-config-options>div {
  width: 33.33%;
}

.andes-v2-channel-config-panel .channel-config-options>.channel-config-middle-options {
  text-align: center;
  color: #036fd2;
}

.andes-v2-channel-config-panel .channel-config-options>div>.channel-config-left-options {
  float: left;
}

.andes-v2-channel-config-panel .channel-config-options>div>.channel-config-right-options {
  float: right;
}

.andes-v2-channel-config-panel .channel-config-options>div .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.andes-v2-channel-config-panel .channel-config-options>div>.channel-config-left-options .anticon {
  margin-right: 4px;
}

.andes-v2-channel-config-panel .channel-config-options>div>.channel-config-right-options .anticon {
  margin-left: 4px;
}

.andes-v2-channel-config-panel .channel-config-options>div>div span {
  vertical-align: middle;
}

.andes-v2-channel-config-panel .channel-config-middle-options {
  text-align: center;
}

.andes-v2-channel-config-panel .channel-config-options>div .ant-btn {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  border-color: #1890ff;
  height: 24px;
  line-height: 23px;
  padding: 0px 12px;
}

.andes-v2-channel-config-panel .channel-config-options>div .ant-btn:hover {
  color: #217ed4;
  border-color: #91d5ff;
  background: #e6f7ff;
}

.andes-v2-channel-config-panel .channels-component-title .anticon {
  color: #1890ff;
  margin-left: 10px;
  cursor: pointer;
}

.channel-select-component-select {
  width: 100%;
}

.channel-config-channels-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.channel-config-channels-table tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

/* .channel-config-channels-table .ant-table-thead>tr>th:nth-child(2) .ant-table-header-column .ant-table-column-title,
.channel-config-channels-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title, */
.channel-config-channels-table .ant-table-thead>tr>th:nth-child(4) .ant-table-header-column .ant-table-column-title,
.channel-config-channels-table .ant-table-thead>tr>th:nth-child(5) .ant-table-header-column .ant-table-column-title {
  padding-left: 4px;
  display: block;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-sub-item {
  padding-left: 25px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-sub-children-item {
  padding-left: 66px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item>span {
  display: inline-block;
  width: 289px;
  vertical-align: middle;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-equalization-sub-item>span,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item>.channel-item-eq-tx-title {
  width: 166px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item.channel-config-sub-item.channel-equalization-sub-item>span {
  width: 313px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .aurora-select,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .aurora-input,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .aurora-tags-input-content {
  width: calc(100% - 349px);
  vertical-align: middle;
  height: auto;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-children-item>span {
  width: 272px
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-children-item .aurora-tags-input-content {
  width: calc(100% - 308px);
  min-height: 28px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-sub-item .channel-sub-item-title,
.andes-v2-channel-config-panel .channel-config-content .channel-config-item .channel-sub-item-title {
  display: inline-block;
  width: calc(100% - 24px);
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item .channel-config-input-suffix {
  width: 24px !important;
  margin-left: 10px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-sub-item>.channel-item-title {
  width: 166px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .channel-config-sub-half-input {
  width: calc(25% - 46px);
  margin-right: 5px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item-analysis-title {
  padding-left: 177px;
  text-align: right;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item-analysis-title>span {
  text-align: center;
  width: calc(25% - 5px);
  margin-right: 5px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item-analysis-title>span:nth-last-child(1) {
  margin-right: 0px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .channel-config-sub-half-input:nth-last-child(1) {
  margin-right: 0px;
}

.andes-v2-channel-config-panel .channel-advanced-collapse-content {
  background-color: #f3f3f3;
}

.andes-v2-channel-config-panel .channel-advanced-collapse-content>.ant-collapse-item>.ant-collapse-header {
  padding: 0px 0px 0px 20px;
}

.andes-v2-channel-config-panel .channel-advanced-collapse-content>.ant-collapse-item {
  border: 0;
}

.andes-v2-channel-config-panel .channel-advanced-collapse-content .ant-collapse-content-box {
  padding-bottom: 8px;
  padding-top: 0px;
  padding-right: 0px;
}

.andes-v2-channel-config-panel .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 0px;
  font-size: 11px;
}

.andes-v2-channel-config-panel .aurora-model-name-error-msg {
  margin-top: 10px;
  margin-bottom: 4px;
}

.andes-v2-channel-config-panel .modal-foot-position {
  height: 51px;
}

.andes-v2-channel-config-panel .ant-modal-footer {
  padding: 10px 16px;
}

.andes-seasim-type-title {
  color: #3b70a7;
}

.channel-config-basic-content {
  border-radius: 5px;
  background: #f3f3f3;
  border: 1px solid #e8e8e8;
  padding: 4px 10px;
}

.andes-seasim-channels-component-content {
  display: flex;
  justify-content: space-between;
}

.andes-seasim-channels-component-content>div {
  width: calc(50% - 16px);
}

.andes-seasim-channels-component-content>div .aurora-select {
  width: calc(100% - 70px);
  vertical-align: middle;
}

.andes-seasim-channels-component-content>div>span {
  width: 70px;
  display: inline-block;
  vertical-align: middle;
}

.andes-seasim-channels-component-content>.andes-seasim-channels-component-device>span {
  padding-left: 16px;
}

.andes-seasim-channels-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #e8e8e8;
  background: #f3f3f3;
  border-radius: 5px;
}

.channel-config-channels-table {
  background-color: #ffffff;
}

.channel-config-content .aurora-select . .ant-select-selector {
  font-size: 13px
}

.direction-setting-icon {
  color: #188fff;
  margin-left: 10px;
}

.andes-v2-channel-direction-panel {
  margin-top: 140px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-v2-channel-direction-panel .direction-setting-main {
  padding: 10px;
}

.direction-setting-main .direction-setting-checkbox-group-box .ant-checkbox-wrapper {
  margin: 8px 0px;
}

.channel-config-eq-children {
  margin-left: 24px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-eq-children>.channel-config-item>span {
  width: 314px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-eq-children>.channel-config-item .aurora-select {
  width: calc(100% - 315px);
}

.andes-v2-channel-config-panel .andes-compliance-error-content {
  margin-top: 10px;
  background: #ffdce0;
  border-radius: 4px;
  padding: 8px;
  color: #cb2431;
}

.andes-v2-channel-config-panel .andes-compliance-error-content .aurora-repeater-monitor-title {
  font-weight: 600;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item.channel-config-sub-leq-children-item .channel-config-sub-leq-input {
  width: calc(50% - 160px);
  margin-right: 12px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-leq-children-item .aurora-input,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-leq-children-item .aurora-select {
  width: calc(100% - 308px);
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .aurora-select.channel-config-sub-item-multiple-select {
  height: auto;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item-analysis-title.channel-config-item-leq-analysis-title {
  padding-left: 339px;
  padding-right: 37px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item-analysis-title.channel-config-item-leq-analysis-title>span {
  width: calc(50% - 3px);
}
.range-input-link-input {
  border-left: 0px;
  pointer-events: 'none';
  padding: 0px;
  text-overflow: inherit !important;
}

.range-input-show-noData {
  height: 28px
}
.report-advances-collapse {
  border: none;
  background-color: #fff;
  margin-bottom: 10px;
}

.report-advances-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px 6px 0px;
  width: -moz-fit-content;
  width: fit-content;
  /* color: #696969; */
}

.report-advances-collapse>.ant-collapse-item {
  border-bottom: none;
}

.report-advances-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 70px;
}

.report-advances-header {
  font-size: 13px;
  font-weight: 500;
  height: 22px;
  /* color: #000000a6; */
}

.report-advances-collapse .ant-collapse-content {
  border-top: none;
}

.report-advances-collapse .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px 16px;
}

.report-advances-collapse .ant-collapse-content-box .ant-table-small {
  background-color: #fff;
}

.report-advances-collapse .report-advances-title {
  padding-bottom: 6px;
  font-size: 13px;
}

.report-advances-collapse .report-advances-table-title .report-advances-switch {
  margin-left: 10px;
}

.report-advances-collapse .report-advances-table-title {
  font-size: 13px;
}

.report-advances-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td:nth-last-child(-n+2) {
  padding: 4px 8px;
}

.report-advances-table.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 13px;
}

.report-advances-collapse .ant-table-wrapper.report-advances-table {
  margin-bottom: 10px;
}

.report-advances-spin {
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0;
}
/* === Report panel === */
.andes-v2-report-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-v2-report-panel .panel-modal-header {
  padding-left: 14px !important;
}

.andes-v2-report-panel .report-panel-title {
  font-size: 16px;
  font-weight: 500;
}

.andes-v2-report-panel .report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  height: 100%;
  min-width: 500px;
}

.andes-v2-report-panel .report-content .report-message-main {
  margin-top: 10px;
  position: relative;
  min-height: 27px;
}

.andes-v2-report-panel .report-content .report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.andes-v2-report-panel .report-content .report-select {
  width: calc(100% - 84px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
}

.andes-v2-report-select-dropdown {
  z-index: 10000000;
  max-height: 500px;
}

.andes-v2-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}

.andes-v2-report-panel .report-content .report-download-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: calc(100% - 90px);
  padding: 0px 8px;
}

.andes-v2-report-panel .report-content .report-download-button {
  position: absolute;
  right: 0px;
  bottom: 2px;
}

.andes-v2-report-panel .report-content .report-download-btn {
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.andes-v2-report-panel .report-content .report-progress {
  right: 10px;
  padding: 2px 0px;
  position: absolute;
  width: auto;
  left: 0px;
}

.andes-v2-report-panel .report-content .report-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.andes-v2-report-panel .report-content .report-progress .ant-progress-text {
  vertical-align: unset;
}

.andes-v2-report-panel .report-content .report-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.andes-v2-report-panel .report-content .download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.andes-v2-report-panel .report-content .download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.report-bottom-severed {
  height: 5px;
}

.andes-v2-report-input-box {
  font-size: 13px;
  margin: 10px 0px;
}

.andes-v2-report-input-box .ant-input-sm,
.andes-v2-report-input-box .ant-input-group-addon,
.andes-v2-report-collapse .ant-input-sm,
.andes-v2-report-collapse .ant-input-group-addon {
  font-size: 13px;
}

.andes-v2-report-label {
  display: inline-block;
  width: 100px;
  line-height: 24px;
}

.andes-v2-report-input {
  width: calc(100% - 100px);
}

.andes-v2-report-frequency-input {
  width: 310px;
}

.andes-v2-report-frequency {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}

.andes-v2-report-frequency-title {
  margin-right: 5px;
  width: 84px;
}

.andes-v2-report-divider {
  margin: 8px 0px;
}

.andes-v2-report-collapse {
  border: none;
  background-color: #f5f5f5;
  font-size: 13px;
  margin-top: 10px;
}

.andes-v2-report-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0px;
  width: -moz-fit-content;
  width: fit-content;
  /* color: #696969; */
}

.andes-v2-report-collapse>.ant-collapse-item {
  border-bottom: none;
}

.andes-v2-report-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 70px;
}

.andes-v2-report-collapse .ant-collapse-content {
  border-top: none;
}

.andes-v2-report-collapse .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 0px 16px;
}

.andes-v2-report-frequency-title {
  display: inline-block;
  width: 54px;
}

.andes-v2-report-frequency-title-fMax,
.andes-v2-report-frequency-title-fLPMax {
  margin-left: 40px;
}

.andes-v2-report-frequency-title-prefix {
  font-size: 14px;
}

.andes-v2-report-frequency-title-subscript {
  font-size: 10px;
  font-weight: 400;
}

.andes-v2-report-select-box {
  display: flex;
  font-size: 13px;
}

.andes-v2-report-flex-item {
  flex: 1 1;
}

.andes-v2-report-flex-item-select {
  width: calc(100% - 100px);
}

.andes-v2-report-frequency-flex-item-input {
  width: calc(100% - 64px);
}
.andes-ads-model-setup-main {
  padding: 8px 14px;
  min-height: 200px;
  min-width: 700px;
  font-size: 12px;
}

.ami-model-select-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.ami-model-select-item>span {
  width: 100px;
}

.ami-model-select-item>.aurora-select {
  width: calc(100% - 100px);
}

.ami-model-select-checkbox {
  width: 50px;
}

.ami-select-model-pins-title,
.ads-select-model-pins-title {
  width: 60%;
  height: 56px;
  line-height: 56px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: space-around;
}

.ami-select-model-pins-title>.ami-select-model-pin-item-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 28px;
}

.ami-select-model-signal-title {
  width: 70%;
  height: 28px;
  line-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ami-select-model-pin-title {
  width: 30%;
  min-width: 80px;
  height: 28px;
  line-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ami-model-jitters-add-icon {
  margin-left: 20px;
  color: #2a99f3;
  cursor: pointer;
  vertical-align: middle;
}

.ami-model-corner-content>span {
  width: 70px;
}

.ami-model-jitters-table tbody>tr>td:last-child,
.ami-model-jitters-table tbody>tr>td:first-child .editable-cell-value-wrap {
  position: relative;
}

.ami-model-jitters-table .ami-model-jitter-table-delete-icon {
  position: absolute;
  right: -18px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.ami-model-jitters-table .ant-table-row:hover .ami-model-jitter-table-delete-icon {
  color: #1890ff;
}

.ami-model-jitters-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 14px;
}

.ami-model-jitters-table .ant-table-small .ant-table-container .ant-table-body .aurora-table-body {
  padding-right: 22px;
}

.ami-model-corner-content .aurora-select.ant-select-disabled .ant-select-selection {
  background: #f7f7f7;
}

.ami-model-corner-content>.aurora-select {
  width: calc(100% - 70px);
}

.andes-ami-parameters-value {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.ami-model-parameters-table .aurora-table-body table {
  table-layout: fixed;
}

.ami-parameters-range-span {
  color: #8d8d8d;
  text-indent: 20px;
}

.ami-parameters-title {
  cursor: pointer;
}

.ami-parameters-title:hover {
  color: #696969;
}

.ami-model-collapse-content .aurora-model-name-error-msg {
  margin-bottom: 6px;
}
.ads-select-model-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 8px;
}

.ibis-select-model-pins-title,
.ibis-select-model-name-title {
  width: 60%;
  height: 56px;
  line-height: 56px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: space-around;
  padding: 4px 0px;
}

.ibis-select-model-name-title {
  width: 40%;
  padding: 4px 0px;
}

.ibis-select-model-pins-title>.ibis-select-model-pin-item-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 28px;
}

.ibis-select-model-pins-title .ibis-select-model-signal-title,
.ibis-select-model-name-title .ibis-select-model-signal-title {
  width: 70%;
  height: 26px;
  line-height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ibis-select-model-pins-title .ibis-select-model-pin-title,
.ibis-select-model-name-title>.ibis-select-model-pin-title {
  width: 30%;
  min-width: 80px;
  height: 26px;
  line-height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ibis-select-model-name-title .ibis-select-model-name-select {
  width: 100%;
  min-width: 80px;
  height: 28px;
  line-height: 28px;
}

.ibis-select-model-name-title .ibis-select-model-name-select .aurora-select {
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.ibis-select-model-name-title .ibis-select-model-name-select .aurora-select {
  height: 26px;
}

.ibis-select-model-name-title .ibis-select-model-name-select .aurora-select .ant-select-selector {
  line-height: 24px;
}

.ibis-jitter-pdf-content {
  margin: 4px 0px;
}

.ibis-jitters-pdf-input-main {
  display: flex;
  justify-content: space-around;
}

.ibis-jitter-pdf-input-content>.ibis-jitter-pdf-input-title {
  display: inline-block;
  width: 50px;
  height: 28px;
  line-height: 28px;
  padding-right: 6px;
  text-align: right;
  font-size: 12px;
}

.ibis-jitter-pdf-input-content>.aurora-input {
  width: calc(100% - 50px);
}

.ibis-jitter-pdf-input-content>.aurora-input .ant-input-group-addon {
  font-size: 12px;
  padding: 0px 4px;
}

.ibis-jitter-pdf-random-input-content>.aurora-input .ant-input-group-addon {
  width: 70px;
}

.ami-model-collapse-content .ibis-advanced-select-item>span {
  width: 200px;
}

.ami-model-collapse-content .ibis-advanced-select-item>.aurora-input,
.ami-model-collapse-content .ibis-advanced-select-item>.aurora-select {
  width: calc(100% - 200px);
}

.ami-model-collapse-content .ibis-advanced-select-item .aurora-tags-input-content {
  width: calc(100% - 92px);
}

.ibis-advanced-select-item .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.65);
  /* background-color: #ffffff; */
}

.ibis-pin-and-selector-flex-box {
  display: flex;
  justify-content: space-between;
  margin: 6px 0px;
}

.ibis-pin-and-selector-flex-box>.ibis-pin-and-selector-flex-box-pin {
  flex: 3 1;
}

.ibis-pin-and-selector-flex-box-pin>label {
  display: inline-block;
  width: 100px;
}

.ibis-pin-and-selector-flex-box-pin>.ant-select {
  width: calc(100% - 100px);
}

.ibis-pin-and-selector-flex-box>.ibis-pin-and-selector-flex-box-select {
  flex: 6 1;
}

.ibis-pin-and-selector-flex-box-select>label {
  display: inline-block;
  width: 120px;
  text-align: center;
}

.ibis-pin-and-selector-flex-box-select>.ant-select {
  width: calc(100% - 120px);
}

.ibis-jitters-switch-box>label {
  display: inline-block;
  width: 150px;
}

.ibis-switch-top-7 {
  margin-top: 7px;
}

.ibis-waveform-advanced-collapse>.ant-collapse-item {
  background-color: #f5f5f5;
}

.andes-ads-model-setup-main .ami-model-collapse-content.ibis-waveform-advanced-collapse .ant-collapse-content-box {
  background-color: #fff;
}

.ibis-waveform-setup-title {
  font-weight: 500;
}

.ibis-model-waveform-risetime {
  margin-right: 10px;
}

.ibis-model-ibis-file-treeSelect .ant-select-tree {
  margin: 4px 0px;
}

.ibis-model-ibis-file-treeSelect .ant-select-tree li span.ant-select-tree-switcher,
.ibis-model-ibis-file-treeSelect .ant-select-tree li span.ant-select-tree-iconEle {
  width: auto;
}

.ibis-model-ibis-file-treeSelect .ant-select-tree li {
  margin: 0 0;
}

.ibis-model-ibis-file-treeIcon {
  margin-right: 5px;
}

.ibis-model-ibis-file-treeSelect-title {
  display: inline-block;
  width: 100%;
}
.andes-ads-model-setup-main .netlist-model-row .ant-select-selector,
.andes-ads-model-setup-main .netlist-model-row .netlist-model-selection,
.andes-ads-model-setup-main .netlist-model-row .ant-select-selection,
.andes-ads-model-setup-main .netlist-model-table-rows .editable-cell-value-wrap {
  height: 26px;
  line-height: 24px;
}

.andes-ads-model-setup-main .netlist-model-row {
  padding: 5px 0px;
}

.andes-ads-model-setup-main .netlist-model-content div>.netlist-model-simall-row:last-child {
  padding-left: 10px;
}
.andes-ads-model-setup-main .netlist-model-row .ant-select-selector,
.andes-ads-model-setup-main .netlist-model-row .netlist-model-selection,
.andes-ads-model-setup-main .netlist-model-row .ant-select-selection,
.andes-ads-model-setup-main .netlist-model-table-rows .editable-cell-value-wrap {
  height: 26px;
  line-height: 24px;
}

.andes-ads-model-setup-main .netlist-model-row {
  padding: 5px 0px;
}

.andes-ads-model-setup-main .netlist-model-content div>.netlist-model-simall-row:last-child {
  padding-left: 10px;
}

.ads-model-apply-all-content.ads-circuit-model-apply-all-content>span {
  width: 204px;
}
.andes-ads-component-content {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  /*   height: 30px;
  line-height: 30px; */
}

.andes-ami-model-main {
  padding: 10px 14px;
  min-width: 600px;
}

.andes-ads-component-content>.andes-ads-component-content-device>span {
  padding-left: 16px;
}

.andes-ami-model-signals-content,
.andes-ads-component-content {
  display: flex;
  justify-content: space-between;
  margin: 6px 0px;
  height: 90px;
}

.andes-ami-model-signals-content-cphy {
  height: 100px;
}

.andes-ads-component-content {
  height: 28px;
}

.andes-ami-model-signals-content .ami-model-pins,
.andes-ads-component-content .ami-model-pins {
  display: flex;
  justify-content: center;
  padding: 0px 4px;
}

.andes-ads-component-content .ami-model-pins {
  height: 28px;
}

.andes-ami-model-signals-content .ami-model-signal-main,
.andes-ads-component-content .ami-model-signal-main {
  width: 160px;
  text-align: center;
  color: #005a9e;
  background: #d5d5d5;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}

.andes-ads-component-content .ami-model-signal-main {
  height: 28px;
  background: #ffffff;
  line-height: 28px;
}

.andes-ads-component-content .andes-ads-component-content-controller,
.andes-ads-component-content .andes-ads-component-content-device {
  width: 120px;
}

.andes-ads-component-content .andes-ads-component-content-controller .aurora-select {
  width: 94px;
}

.andes-ads-component-content .andes-ads-component-content-device .aurora-select {
  width: 94px;
  float: right;
}

.andes-ads-component-content .andes-ads-component-content-controller .end-to-end-ads-comp-select,
.andes-ads-component-content .andes-ads-component-content-device .end-to-end-ads-comp-select {
  width: 120px;
}

.andes-ads-component-content .ami-model-setup-content {
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #005a9e;
  display: flex;
}

.andes-ads-component-content .ami-model-setup-title-left-content {
  justify-content: left;
}

.andes-ads-component-content .ami-model-setup-title-right-content {
  justify-content: right;
}

.andes-ads-component-content .ami-model-signal-aggressors {
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #005a9e;
}

.andes-ads-component-content .ami-model-setup-content>span:not(.anticon) {
  width: 80px;
}

.andes-ads-component-content .ami-model-setup-content>.aurora-select {
  width: 60px;
}

.andes-ads-component-content .ami-model-signal-aggressors>span {
  width: 160px;
  text-align: center;
  display: block;
}

.andes-ami-model-signals-content .ami-model-signal-main .ami-model-signal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.ami-model-pin-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 120px;
}

.ami-model-pin-item {
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 120px;
}

.ami-model-pin-item .ami-model-pin-content {
  width: 100px;
  height: 30px;
  background: #eee;
  position: relative;
  z-index: 1;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.ami-model-pin-item .ami-model-pin-title {
  width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #005a9e;
  display: inline-block;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
  font-size: 12px;
}

.andes-ami-model-signals-content-cphy .ami-model-pin-item .ami-model-pin-title {
  width: 100%;
  text-align: center;
}

.ami-model-pin-item .ami-model-pin-type-title {
  width: 20px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #005a9e;
  margin-right: 4px;
  font-size: 18px;
}

.ami-model-pin-item .ami-model-pin-type-device-title {
  margin-left: 4px;
  margin-right: 0px;
}

.ami-model-setup-content {
  display: flex;
  justify-content: space-between;
  /* height: 90px;
  line-height: 90px; */
  min-width: 206px;
}

.ami-model-direction-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 3px;
}

.ami-model-title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 90px; */
  width: 150px;
}

.ami-model-title {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 28px;
  line-height: 26px;
  width: 80px;
  cursor: pointer;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px 4px;
  font-size: 12px;
}

.ami-model-file-error-title {
  color: #f30518;
  position: relative;
  padding-right: 24px;
}

.ami-model-file-error-title .aurora-file-check-icon {
  right: 4px;
}

.ami-model-title-disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.ami-pin-long-line {
  height: 5px;
  background-color: #a6a6a6;
  margin-top: 14.5px;
  width: 30px;
  transform: scaleY(0.2);
}

.andes-ads-component-content .ami-model-setup-content .ads-model-type-select {
  width: 150px;
  color: #005a9e;
}

.andes-ads-component-content .ami-model-setup-content .ads-model-type-select .ant-select-selection-item {
  color: #005a9e;
}

.ami-advanced-select-item {
  min-height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.ami-advanced-select-item>span {
  width: 220px;
}

.ami-advanced-select-item>.aurora-select,
.ami-advanced-select-item>.aurora-input {
  width: calc(100% - 220px);
}

.andes-ami-type-title {
  color: #3b70a7;
}

.ami-setup-footer-options {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.ami-setup-footer-options>div .ami-setup-left-options .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.ami-setup-footer-options>div>div {
  cursor: pointer;
  color: #036fd2;
  font-size: 13px;
}

.ami-setup-footer-options>div>div:hover,
.ami-setup-footer-options>.ami-setup-middle-options:hover {
  color: #1890ff;
}

.ami-setup-footer-options>div {
  width: 33.33%;
}

.ami-setup-footer-options>.ami-setup-middle-options {
  text-align: center;
  color: #036fd2;
}

.ami-setup-footer-options>div .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.ami-setup-footer-options>div>.ami-setup-left-options .anticon {
  margin-right: 4px;
}

.ami-setup-footer-options>div>.ami-setup-right-options .anticon {
  margin-left: 4px;
}

.ami-setup-footer-options>div>div span {
  vertical-align: middle;
}

.ami-setup-footer-options>div .ant-btn {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  border-color: #1890ff;
  height: 24px;
  line-height: 23px;
  padding: 0px 12px;
}

.ami-setup-footer-options>div .ant-btn:hover {
  color: #217ed4;
  border-color: #91d5ff;
  background: #e6f7ff;
}

.ami-setup-footer-options>div>.ami-setup-left-options {
  float: left;
}

.ami-setup-footer-options>div>.ami-setup-right-options {
  float: right;
}

.andes-ami-signal-aggressors-content {
  margin-top: 10px;
  border: none;
  background-color: #ffffff;
}

.andes-ami-signal-aggressors-content .ant-collapse-content-box {
  padding: 8px;
}

.andes-ami-signal-aggressors-content .ami-model-parameters-title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.andes-ami-signal-aggressors-content>.ant-collapse-item {
  border-bottom: none;
}

.andes-ami-signal-aggressors-content>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px;
  padding-left: 20px;
  width: -moz-fit-content;
  width: fit-content;
}

.andes-ami-signal-aggressors-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 0px;
}

.andes-ami-signal-aggressors-content .ant-collapse-content {
  border-top: none;
}

.ami-advanced-sub-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
  margin-left: 220px;
}

.ami-advanced-sub-item>span {
  width: 120px;
}

.ami-advanced-sub-item .aurora-select,
.ami-advanced-sub-item .aurora-input {
  width: calc(100% - 120px);
}

.ami-advanced-select-item .ami-model-select-checkbox {
  width: 160px;
  text-align: right;
}

.ami-advanced-select-item .ami-model-select-checkbox .ant-checkbox {
  margin-right: 26px;
}

.ami-advanced-select-item>.ami-advanced-checkbox-span {
  width: calc(100% - 160px);
}

/* .ami-model-signal-main ant-select-selector {
  margin-top: 2px;
  max-height: 56px;
  overflow: auto;
  width: 100%;
}

.ami-model-signal-main .aurora-select {
  width: 100%;
} */

.ami-model-signal-aggressors .aurora-select {
  width: 100%;
  max-height: 90px;
  min-height: 28px;
  height: auto;
}

.ami-model-signal-aggressors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 160px;
  height: 90px;
  margin-left: 4px;
}

.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selector::after,
.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selection-wrap::after {
  line-height: 20px;
}

.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selection-overflow-item::after,
.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item {
  height: 20px;
}

.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selection-overflow-item-suffix {
  min-height: 20px;
}

.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item>.ant-select-selection-item {
  box-sizing: border-box;
  height: 18px;
  line-height: 16px;
}

.ami-model-collapse-content {
  border: none;
  background-color: #ffffff;
}

.ami-model-collapse-content .ant-collapse-content .ant-collapse-content-box {
  padding: 8px;
  padding-left: 20px;
  width: -moz-fit-content;
  width: fit-content;
}

.andes-ads-model-setup-main .ami-model-collapse-content .ant-collapse-content-box,
.andes-ads-model-setup-main .ami-model-collapse-content .ant-collapse-content {
  width: 100%;
}

.andes-ads-model-setup-main .ami-model-collapse-content .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.andes-ads-model-setup-main .jitter-collapse-content .ant-collapse-content-box .ant-table-small,
.andes-ads-model-setup-main .ami-model-parameters-table .ant-table-small {
  background-color: #ffffff;
}

.andes-ads-model-setup-main .ads-model-collapse-main .ant-collapse-content-box {
  background-color: #eee;
}

.ami-model-collapse-content .ami-model-collapse-title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ami-model-collapse-content>.ant-collapse-item {
  border-bottom: none;
}

.ami-model-collapse-content>.ant-collapse-item>.ant-collapse-header {
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.ami-model-collapse-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
  padding-inline-end: 5px;
}

.ami-model-collapse-content .ant-collapse-content {
  border-top: none;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.ami-model-simulation-content .ant-collapse-content,
.ami-model-simulation-content .ant-collapse-content .ant-collapse-content-box {
  width: 100%;
}

.ami-model-signal-aggressors ant-select-selector>ul>.ant-select-search,
.ami-model-signal-aggressors ant-select-selector .ant-select-selector>ul>.ant-select-search {
  width: 4px;
}

.ami-model-signal-aggressors ant-select-selector .ant-select-selection__choice {
  max-width: 98%;
  margin-right: 2px;
}

.andes-ami-model-main .ami-advanced-select-item .ant-input-wrapper .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.55);
}

.andes-ads-model-setup-panel .andes-ami-model-main .aurora-select,
.andes-ads-model-setup-panel .andes-ami-model-main .aurora-input {
  font-size: 13px;
}

.andes-ads-model-setup-panel .andes-ami-model-main .aurora-select .ant-select-selector {
  font-size: 13px;
}

.andes-ads-model-setup-panel .andes-ami-model-main .aurora-select .ant-select-arrow,
.andes-ads-model-setup-panel .andes-ami-model-main .aurora-select .ant-select-clear {
  right: 4px;
}

/****  Model panel *****/
.andes-ads-model-setup-panel {
  top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-ads-model-setup-panel .panel-body {
  overflow: auto;
}

.andes-ads-model-setup-main {
  padding: 8px 14px;
  min-height: 200px;
  min-width: 700px;
  font-size: 14px;
}

.ads-model-select-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.ads-model-select-item>span:not(.anticon) {
  width: 100px;
}

.ads-model-select-item>.aurora-select {
  width: calc(100% - 100px);
}

.andes-ads-model-setup-main .aurora-select,
.andes-ads-model-setup-main .aurora-input,
.andes-ads-model-setup-main .ami-model-select-item,
.andes-ads-model-setup-main .ami-advanced-sub-item>span {
  font-size: 14px;
}

.andes-ads-model-setup-main .ami-model-prbs-content .ami-model-select-item>span {
  width: 100px;
}

.andes-ads-model-setup-main .ami-model-prbs-content .ami-model-select-item .aurora-select,
.andes-ads-model-setup-main .ami-model-prbs-content .ami-model-select-item .aurora-input {
  width: calc(100% - 100px);
}

.andes-ads-model-setup-main .ami-advanced-sub-item {
  margin-left: 100px;
}

.ami-model-prbs-content .ant-collapse-content-box {
  padding-left: 20px;
}

.ami-model-jitter-title {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
}

.andes-ads-model-setup-main .ads-model-select-item>.ads-model-file-select {
  width: calc(100% - 124px);
}

.ads-model-file-content-icon {
  width: 24px;
  color: #1ca4f3;
  line-height: 24px;
  font-size: 16px;
  height: 24px;
  margin-left: 5px;
}

.ads-model-apply-all-content {
  margin-top: 10px;
  width: 100%;
}

.ads-model-apply-all-content>span {
  display: inline-block;
  margin-right: 15px;
  font-size: 13px;
}

.ads-select-model-content {
  width: 100%;
  padding: 4px 10px;
  background-color: #eee;
  border-radius: 4px
}

.ads-select-model-content .ads-select-model-header {
  border-bottom: 1px dashed #8b8b8b;
  margin-bottom: 10px;
  cursor: text;
  margin: 0px;
  padding-left: 34px;
}

.ads-select-model-content .ads-select-model-selected,
.ads-select-model-content .ads-select-model-selected:hover {
  background-color: #c6e4fc;
  border-radius: 4px;
}

.ads-select-model-content .ads-select-model-header:hover {
  background-color: #eee;
}

.ads-select-model-content .ads-select-model-header .ads-select-model-pins-title,
.ads-select-model-content .ads-select-model-header .ibis-select-model-pins-title {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0px;
}

.ads-select-model-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 8px;
  margin: 4px 0px;
}

.ads-select-model-item:hover {
  background-color: #ccc;
  border-radius: 4px;
}

.ads-select-model-item-title {
  width: 40%;
  height: 56px;
  line-height: 56px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ads-select-model-name-title {
  width: 40%;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}

.ads-model-jitters-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  width: 50%;
}

.ads-model-jitters-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td:last-child {
  padding: 4px 8px;
}

.ads-model-jitter-table-value-content {
  width: 100%;
}

.ads-model-jitter-table-value {
  width: 70%;
  display: inline-block;
}

.ads-model-jitter-input .ant-input-wrapper .ant-input-group-addon,
.ads-model-jitter-input .ant-input-wrapper .ant-input-group-addon .ant-select .ant-select-selection {
  width: 70px;
  font-size: 12px;
}

.ads-model-jitter-input .ant-input-wrapper .ant-input-group-addon .ant-select-selector {
  margin-right: 16px;
}

.ads-model-jitter-table-unit {
  width: 30%;
  padding-left: 10px;
  display: inline-block;
}

.ads-model-jitter-input {
  width: 100%;
}

.ads-model-jitter-title {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
}

.ads-model-jitter-title>span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.ads-model-collapse-main .ant-collapse-content .ant-collapse-content-box {
  padding-top: 0px;
}

.ads-model-jitters-table .ant-table>.ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
  font-size: 13px;
}

.ads-model-jitters-table .aurora-table-body table {
  table-layout: fixed;
}

.ads-model-checkbox {
  padding-right: 12px;
  line-height: 56px;
}

.ads-model-checkbox .ant-checkbox-inner {
  border-radius: 50%;
}

.ami-model-collapse-content .ant-collapse-content-box .ant-divider-horizontal {
  margin: 10px 0px;
  background: #ccc;
}

.ibis-model-eq-tags-input {
  margin-left: 10px;
  min-height: 28px;
}

.ibis-eq-dfe-setup-item .ibis-model-eq-tags-input {
  margin-left: 0px;
}

.ibis-eq-setup-item {
  margin-left: 26px !important;
}

.ibis-model-eq-tags {
  margin-left: 10px;
  display: flex;
}

.ibis-model-eq-tags>span {
  width: 82px;
  text-align: right;
}

.ibis-eq-dfe-setup-item.ibis-model-eq-tags>span {
  text-align: left;
}

.ami-advanced-select-item .ami-advanced-title-deepen-color {
  font-weight: 500;
  color: #000000d9;
}

.ami-advanced-input-combination-item>.aurora-input {
  margin-right: 30px;
}

/* 
.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label {
  margin-right: 0;
  width: 33%;
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label:last-child {
  width: 67%;
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label:last-child span.ant-radio+* {
  padding-right: 0;
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label>span>div.aurora-select {
  margin-left: 5px;
  width: calc(100% - 136px);
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label>span>.aurora-input {
  margin-left: 5px;
  width: calc(100% - 85px);
} */

.ibis-model-eq-tags.ibis-model-eq-input-hide-tags {
  margin-left: 0;
}

.ami-model-collapse-content .ibis-advanced-select-item .ibis-model-eq-input-hide-tags .aurora-tags-input-content {
  margin-left: 0;
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group .ami-combination-group-input-disable.ant-input[disabled] {
  background-color: #fff;
  color: #000000a6;
}

.andes-ads-component-content .ami-model-setup-circuit-content,
.andes-ami-model-signals-content .ami-model-setup-circuit-content {
  min-width: 160px;
  justify-content: center;
  padding: 0px 5px;
}

.andes-ami-model-circuit-delete-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -7px;
  font-size: 14px;
  display: none;
}

.andes-ami-model-circuit-delete-icon:hover {
  color: #1890ff;
}

.ami-model-setup-circuit-content .ami-model-title-content:hover .aurora-file-check-icon {
  right: 20px;
}

.ami-model-setup-circuit-content:hover .andes-ami-model-circuit-delete-icon {
  display: block;
}

.ami-model-setup-circuit-content:hover .ami-model-title-content .ami-model-title {
  padding-right: 22px;
}

.ami-model-setup-circuit-content {
  position: relative;
}

.andes-ami-model-circuit-add-icon {
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.andes-ami-model-main .andes-ads-component-content .ami-model-setup-circuit-disabled-content,
.andes-ami-model-main .andes-ami-model-signals-content .ami-model-setup-circuit-disabled-content {
  min-width: 26px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0px;
}

.andes-ads-eye-probe-content .ami-advanced-select-item {
  margin-left: 20px;
}

.andes-ads-eye-probe-content>.andes-ads-eye-probe {
  margin-left: 0px;
}

.ami-advanced-select-item .aurora-file-check-icon {
  font-size: 13px;
  top: 16px;
  right: 20px;
}

.ami-info-circle-icon {
  position: relative;
  left: 6px;
  top: 1px;
  text-align: center;
  line-height: 36px;
}

.ami-info-circle-icon:hover {
  color: rgb(24, 144, 255);
}

.ami-model-direction-dropdown {
  min-width: 260px !important;
}

.ami-advanced-select-item>.setup-question-circle-icon {
  width: 16px;
}
/* === Service option panel === */
.service-option-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.service-option-panel .panel-modal-header {
  padding-left: 14px !important;
}

.service-option-title {
  font-size: 16px;
  font-weight: 500;
}

.service-option-panel .service-option-content {
  padding: 10px 16px;
}

.service-option-item {
  position: relative;
}

.service-option-item+.service-option-item {
  margin-top: 10px;
}

.service-option-name {
  font-weight: 500;
  line-height: 28px;
  width: 110px;
  display: inline-block;
  font-size: 13px;
}

.service-option-input {
  width: calc(100% - 110px);
  font-size: 13px;
  height: 28px;
}

.service-option-input .ant-input {
  font-size: 13px;
  height: 28px;
}
#andes-v2-top-bar,
#andes-v2-prelayout-top-bar {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

.andes-v2-top-bar-item {
  padding: 6px 14px;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.andes-v2-top-bar-item:hover {
  background-color: #edebe9;
}

.andes-v2-top-bar-item .iconfont,
.andes-v2-top-bar-item .anticon-play-circle,
.andes-v2-top-bar-item .anticon-setting,
.andes-v2-top-bar-item .anticon-question-circle,
.andes-v2-top-bar-item .anticon-experiment,
.andes-v2-top-bar-item .anticon-download {
  margin-right: 8px;
  font-size: 16px;
  margin-top: -2px;
}

.andes-v2-top-bar-item+.andes-v2-top-bar-item {
  margin-left: 20px;
}

.andes-v2-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.andes-v2-top-bar-item .andes-v2-top-bar-debug-icon {
  margin: -2px 8px 0px 0px;
  font-size: 19px;
  display: inline-block;
  vertical-align: bottom;
  padding-top: 2px;
}

#andes-v2-top-bar.hide .andes-v2-top-bar-item-title {
  display: none;
}

#andes-v2-prelayout-top-bar.hide .andes-v2-top-bar-item-title {
  display: none;
}


#andes-v2-top-bar.hide .andes-v2-top-bar-item .iconfont,
#andes-v2-top-bar.hide .andes-v2-top-bar-item .anticon-play-circle,
#andes-v2-top-bar.hide .andes-v2-top-bar-item .anticon-setting {
  margin-right: 0px;
}

#andes-v2-prelayout-top-bar.hide .andes-v2-top-bar-item .anticon-setting {
  margin-right: 0px;
}

#andes-v2-top-bar .extraction-dropdown-button .ant-dropdown-menu-item:hover {
  color: #1890ff;
}

li.ant-select-dropdown-menu-item.aurora-debug-select-failed {
  color: #ff0000;
}

.andes-v2-top-bar-result-title {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
}

.andes_v2_top-bar-debug {
  position: absolute;
  right: 10px;
}

.andes-v2-top-bar-result-title .andes-v2-top-bar-result-icon {
  margin-left: 8px;
  font-size: 15px;
}

#andes-v2-top-bar.hide .andes-v2-top-bar-result-icon {
  margin-left: 0px;
}

#andes-v2-top-bar .andes-v2-top-bar-result:hover {
  background-color: #f3f2f1;
}

.andes-v2-top-bar-result-title:hover {
  color: #f87e3b;
  border: 1px solid #f87e3b;
}

.andes-v2-top-bar-item+.andes-v2-top-bar-result {
  margin-left: 0px;
}

.andes-v2-top-bar-item-disabled .andes-v2-top-bar-result-title,
.andes-v2-top-bar-item-disabled .andes-v2-top-bar-result-title:hover {
  color: #797979 !important;
  border: 1px solid #797979;
}

.andes-v2-top-bar-item .andes-v2-top-bar-circuit-icon {
  font-size: 20px;
}

.andes-v2-top-sus-report-item {
  position: relative;
  padding: 6px 0px;
}

.andes-v2-top-sus-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.andes-v2-top-sus-report {
  line-height: 21px;
  height: 21px;
  position: relative;
  z-index: 2;
}

.andes-v2-top-sus-report-icon {
  margin: -3px 8px 0px 0px;
  font-size: 16px;
  display: inline-block;
  vertical-align: bottom;
}

.andes-v2-top-sus-icon-diff {
  padding-top: 2px;
}

.andes-v2-top-sus-disabled {
  color: #797979;
  cursor: not-allowed;
}

.andes-v2-top-sus-report-progress {
  width: 0px;
  height: 33px;
  background-color: #82c2fd;
  float: left;
  position: absolute;
  overflow: visible;
  top: 0;
  z-index: 1;
}

.andes-v2-report-progress-span {
  height: 33px;
  display: block;
  float: left;
  line-height: 34px;
}

.andes-v2-top-sus-report-item .andes-v2-top-sus-report {
  padding: 0px 14px;
}

.andes-v2-simulation-radio {
  display: flex;
  margin: 0;
  align-items: center;
  padding: 6px;
}

.andes-v2-simulation-radio:first-of-type {
  margin: 8px 8px 0px 8px;
}

.andes-v2-simulation-radio:last-of-type {
  margin: 0px 8px 8px 8px;
}


.andes-v2-simulation-radio:hover {
  background-color: #e6f7ff;
}

.andes-v2-simulation-popover .ant-popover-inner-content {
  padding: 0px;
}
.pcb-channel-main .pcb-channel-signal-setting-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 12px;
}

.pcb-channel-main .pcb-channel-edit-signal-label {
  vertical-align: middle;
  margin-left: 8px;
}

.channel-signals-pins-table .ant-table-small {
  /*   min-width: 1250px; */
  table-layout: fixed;
}

.channel-component-table .ant-table-thead>tr>th .ant-table-header-column,
.channel-signals-pins-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.channel-component-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title,
.channel-component-table .pcb-channel-rlc-div {
  padding-left: 4px
}

.channel-component-table .editable-cell-value-wrap,
.channel-signals-pins-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-v2-PG-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 14px;
}

.andes-v2-PG-table .ant-table-small .ant-table-container .ant-table-body .aurora-table-body {
  padding-right: 22px;
}

.andes-v2-PG-table tbody>tr>td:last-child,
.andes-v2-PG-table tbody>tr>td:first-child .editable-cell-value-wrap {
  position: relative;
}

.andes-v2-PG-table .ant-table-small .ant-table-container .power-nets-delete {
  position: absolute;
  right: -25px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.andes-v2-PG-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .power-nets-delete,
.channel-signals-pins-table .ant-table-small .ant-table-container .aurora-table-body .channel-signal-name:hover .signal-delete-icon {
  color: #1890ff;
}

.channel-signals-pins-table .aurora-signal-background .aurora-group-background,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name,
.channel-signals-pins-table .aurora-signal-background .aurora-group-background .editable-cell-value-wrap,
.channel-signals-pins-table .aurora-signal-background .aurora-group-background .editable-cell-value-wrap.ant-table-cell,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name .editable-cell-value-wrap,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name .editable-cell-value-wrap.ant-table-cell {
  background-color: #edf7ff;
}

.aurora-table.channel-signals-pins-table .ant-table-row.aurora-group-background:hover>td,
.aurora-table.channel-signals-pins-table .ant-table-row:hover>td.channel-signal-name,
.aurora-table.channel-signals-pins-table .ant-table-row.aurora-group-background:hover>td>.editable-cell-value-wrap,
.aurora-table.channel-signals-pins-table .ant-table-row:hover>td.channel-signal-name>.editable-cell-value-wrap,
.aurora-table.channel-signals-pins-table .ant-table-row.aurora-group-background:hover>td>.editable-cell-value-wrap.ant-table-cell,
.aurora-table.channel-signals-pins-table .ant-table-row:hover>td.channel-signal-name>.editable-cell-value-wrap.ant-table-cell {
  background-color: #e6f7ff;
}

.channel-signals-pins-table .aurora-signal-background .aurora-group-background .editable-cell-value-wrap,
.channel-signals-pins-table .aurora-signal-background .aurora-group-background .editable-cell-value-wrap.ant-table-cell,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name .editable-cell-value-wrap,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name .editable-cell-value-wrap.ant-table-cell {
  border-color: #edf7ff;
}

.channel-signals-pins-table .aurora-table-body>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: inherit;
}

.channel-signals-pins-table tbody>tr .channel-signal-name {
  position: relative;
}

.channel-signals-pins-table tbody>tr .channel-signal-name .editable-cell-value-wrap {
  width: calc(100% - 14px);
}

.channel-signals-pins-table .ant-table-small .pcb-channel-table-checkBox {
  width: 35px;
  text-align: center;
}

.channel-signals-pins-table .ant-table-small .pcb-channel-table-checkBox .anticon {
  margin-left: 6px;
  color: #0d87f7;
}

.andes-v2-select-nets-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
  vertical-align: middle;
  margin-bottom: 2px;
}

.channel-add-signal-dropdown .ant-dropdown-menu-item {
  font-size: 13px;
}

.channel-add-signal-dropdown .ant-dropdown-menu-item:hover {
  color: #1890ff;
}

.channel-add-signal-icon {
  vertical-align: text-bottom;
}

.andes-v2-channel-select-dropdown-menu {
  padding: 0px;
  margin: 0px;
  max-height: 250px;
  margin-bottom: 0px !important;
}

.andes-v2-channel-select-dropdown-menu .ant-select-dropdown-menu-item {
  position: relative;
  padding-left: 30px;
}

.andes-v2-channel-select-dropdown-menu .ant-select-dropdown-menu-item .ant-select-selected-icon {
  text-align: left !important;
  left: 10px !important;
  font-weight: bold;
}

.channel-signals-pins-table .ant-table-thead>tr>th {
  white-space: nowrap;
}

.channel-signals-pins-table .aurora-table-body td {
  word-break: break-all;
}

.channel-component-table .ant-table>.ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
}

.cmc-connect-pins-icon {
  margin-left: 16px;
  color: #0d87f7;
  cursor: pointer;
}

.cmc-components-pins-columns {
  width: 100%;
  height: 100%;
}

.cmc-components-pins-columns-select-dropdown {
  z-index: 30000;
}

.pcb-channel-main {
  min-width: 1350px;
}

.setup-package-model-content .pkg-new-panel .spice-model-content>div {
  width: 100%;
}

.setup-package-model-content .spice-connect-content {
  overflow: auto;
  padding-bottom: 10px;
}

.package-model-columns-content {
  padding-right: 15px;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.package-model-columns-title {
  color: rgb(0, 0, 0, 0.35);
}

.package-model-jump-info-icon {
  width: 24px;
  color: #1ca4f3;
  font-size: 16px;
  position: absolute;
  right: 2px;
  top: calc(50% - 8px);
}

.setup-package-model-content {
  border: 1px solid #e8e8e8;
  padding: 10px 0;
  margin-bottom: 15px;
}

.setup-package-model-content:last-child {
  margin-bottom: 0px;
}

.setup-package-model-content .pkg-panel.pkg-new-panel {
  width: 100%;
}

.setup-package-model-content .package-setup-name-title {
  padding-left: 10px;
  color: #005a9e;
}

.setup-package-model-content .pkg-new-panel .spice-model-content .package-setup-name-divider {
  margin: 5px;
  width: 99%;
}

.setup-package-model-content .pkg-new-panel .pkg-model-select>*:first-child {
  position: relative;
}

.setup-package-model-content .spice-model-add-file-icon {
  position: absolute;
  right: 8px;
  top: 4px;
}

.setup-package-model-content .pkg-new-panel .pkg-model-select-width>.pkg-model-selection-div {
  width: calc(100% - 130px);
}

.andes-channel-diode-panel .create-channel-content {
  min-width: 400px;
}

.andes-channel-diode-panel .component-diode-pin-select {
  height: auto;
  min-height: 28px;
}

.andes-diode-component-pin-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-channel-diode-panel .diode-component-pins-apply-all-content {
  margin-top: 10px;
  width: 100%;
}

.andes-channel-diode-panel .diode-component-pins-apply-all-content>span {
  display: inline-block;
  width: 284px;
  font-size: 12px;
  vertical-align: middle;
}

.andes-channel-diode-panel .diode-component-pins-apply-all-content>.ant-checkbox-wrapper {
  vertical-align: middle;
}

.setup-package-model-content .pkg-new-panel .pkg-model-select-width>.pkg-model-selection-div {
  width: calc(100% - 130px);
}

.channel-advanced-title {
  color: #8c8c8c;
  font-size: 12px;
}

.channel-advanced-title>span {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.channel-advanced-title>i {
  cursor: pointer;
}

.channel-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.setup-package-model-content .pkg-new-panel .pkg-model-selection-div .pkg-model-selection.ant-select-disabled .ant-select-selector {
  color: rgb(0, 0, 0, 0.88);
  background-color: #fefefe
}

.aurora-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  overflow: hidden;
}
.component-CMC-model-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.component-CMC-model-panel .component-CMC-model-content {
  padding: 8px 14px;
}

.component-cmc-model-pin {
  width: 100%;
  height: 100%;
}

.component-cmc-model-port-select-dropdown {
  z-index: 30000;
}

.component-CMC-model-file-item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  display: flex;
  margin-bottom: 8px;
}

.component-CMC-model-file-item>span {
  width: 120px;
}

.component-CMC-model-file-item>.aurora-select {
  width: calc(100% - 120px);
}

.component-CMC-model-content .component-CMC-model-file-item>.component-cmc-file-select {
  width: calc(100% - 140px);
}

.component-cmc-file-content-icon {
  width: 24px;
  color: #1ca4f3;
  line-height: 34px;
  font-size: 16px;
  height: 28px;
}
.related-nets-select-div {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.related-nets-select-span {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin: 0px;
  padding: 0px;
  padding-left: 8px;
  cursor: pointer;
}

.related-nets-select-ul-1 {
  max-height: 138px;
  width: 100%;
  overflow: auto;
  padding-left: 15px;
  margin-bottom: 2px !important;
}

.related-nets-select-ul-2 {
  max-height: 68px;
  width: 100%;
  overflow: auto;
  padding-left: 15px;
  margin-bottom: 4px !important;
}

.related-nets-select-li {
  line-height: 28px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
}

.related-nets-select-li:hover {
  background-color: rgb(223, 243, 255);
}

.related-nets-selected {
  background-color: rgb(176, 226, 255);
  font-weight: 600;
}

.related-nets-selected:hover {
  background-color: rgb(176, 226, 255);
}

.related-nets-expand-icon {
  margin: 0 4px;
  font-size: 12px;
}

.additional-nets-radio {
  display: inline-block;
  margin-left: 20px;
}

.additional-nets-title-color {
  color: #0b3694;
}

.additional-nets-select {
  max-width: calc(100% - 440px);
  min-width: 300px;
}
.andes-v2-hspice-simulation-container {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 10px;
  font-weight: 500;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  color: black;
}

.andes-v2-hspice-simulation-input,
.andes-v2-hspice-simulation-rx,
.andes-v2-hspice-simulation-tx-net,
.andes-v2-hspice-simulation-tx-node,
.andes-v2-hspice-simulation-ctle {
  position: absolute;
  cursor: pointer;
}

.andes-v2-hspice-simulation-canvas-box {
  padding: 10px;
  position: relative;
}

.andes-v2-hspice-simulation-input .z0-empty-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #afafaf;
  width: 60px;
}

/* canvas ctle */
.andes-v2-hspice-simulation-ctle-item {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 200px;
  padding: 10px;
}

.andes-v2-hspice-simulation-ctle-item-zIndex {
  z-index: 100;
}

.andes-v2-hspice-simulation-ctle-item>.ant-input,
.andes-v2-hspice-simulation-canvas-input {
  width: 60px;
  padding: 4px;
  text-align: center;
}

.andes-v2-hspice-simulation-canvas-div,
.andes-v2-hspice-simulation-canvas-div-net,
.andes-v2-hspice-simulation-canvas-input.ant-input,
.andes-v2-hspice-simulation-canvas-signal-name {
  position: absolute;
  z-index: 100;
}

.andes-v2-hspice-simulation-canvas-div,
.andes-v2-hspice-simulation-canvas-div-net {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.andes-v2-hspice-simulation-canvas-div-net {
  width: 130px;
  cursor: text;
  text-align: center;
}

.andes-v2-hspice-simulation-canvas-signal-name {
  left: 320px;
  width: 150px;
  text-align: center;
}

.andes-v2-hspice-simulation-model-content {
  margin: 10px;
  overflow: hidden;
  font-size: 14px;
  padding: 0px 10px;
  font-weight: 500;
}

.andes-v2-hspice-simulation-model-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.andes-v2-hspice-simulation-model-row-item {
  flex: 1 1;
}

.andes-v2-hspice-simulation-model-row>.andes-v2-hspice-simulation-model-row-item:nth-of-type(1) {
  padding-right: 24px;
}

.andes-v2-hspice-simulation-model-row>span,
.andes-v2-hspice-simulation-model-row-item>span,
.andes-v2-hspice-simulation-title {
  display: inline-block;
  width: 100px;
  height: 24px;
  line-height: 24px;
  font-weight: 500;
}

.andes-v2-hspice-simulation-model-select,
.andes-v2-hspice-simulation-model-row-item>span.andes-v2-hspice-simulation-model-select,
.andes-v2-hspice-simulation-model-input,
.andes-v2-hspice-simulation-right-default-width {
  width: calc(100% - 100px);
}

.andes-v2-hspice-simulation-model-row-item>.andes-v2-hspice-simulation-model-subckt-label {
  width: 80px;
}

.andes-v2-hspice-simulation-model-subckt-select {
  width: calc(100% - 80px);
}

.andes-v2-hspice-simulation-node-input {
  width: 100%;
  text-align: center;
}

.andes-v2-hspice-simulation-z0-input {
  width: 80px;
}

.andes-v2-hspice-simulation-component-select {
  margin-left: 10px;
  min-width: 80px;
  max-width: 100px;
}

.andes-v2-hspice-simulation-model-select .ant-select-selection--single,
.andes-v2-hspice-simulation-model-select .ant-select-selection__rendered,
.andes-v2-hspice-simulation-model-select,
.andes-v2-hspice-simulation-model-input,
.andes-v2-hspice-simulation-component-select,
.andes-v2-hspice-simulation-component-select .ant-select-selection--single,
.andes-v2-hspice-simulation-component-select .ant-select-selection__rendered,
.andes-v2-hspice-simulation-rx-model-node-select,
.andes-v2-hspice-simulation-node-input,
.andes-v2-hspice-simulation-z0-input>.ant-input {
  height: 24px;
  line-height: 22px;
  /* font-weight: 500; */
  font-size: 14px;
}

.andes-v2-hspice-simulation-icon {
  color: rgba(0, 0, 0, 0.55);
  font-size: 13px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: text-top;
}

.andes-v2-hspice-simulation-rx-add-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #0d87f7;
}

.andes-v2-hspice-simulation-title {
  display: flex;
  align-items: center;
}

.andes-v2-hspice-simulation-title-box {
  margin: 10px 0px;
  display: flex;
}

.andes-v2-hspice-simulation-title-box>div {
  white-space: nowrap;
}

/* Tx */
.andes-v2-hspice-simulation-title-box>div:nth-of-type(1) {
  margin-left: 55px;
  width: 455px;
}

/* Rx */
.andes-v2-hspice-simulation-title-box>div:nth-of-type(2) {
  width: 190px;
}

.andes-v2-hspice-simulation-title-box>div:nth-of-type(2)>.andes-v2-hspice-simulation-add-icon {
  margin-left: 85px;
}

/* RxCpad */
/* .andes-v2-hspice-simulation-title-box>div:nth-of-type(3) {
  margin-left: 50px;
} */

/* termination */
.andes-v2-hspice-simulation-title-box>div:nth-of-type(4) {
  margin-left: 20px;
}

/* ctle */
.andes-v2-hspice-simulation-title-box>div:nth-of-type(5) {
  margin-left: 100px;
}

.andes-v2-hspice-simulation-title-box>div:nth-of-type(3)>.andes-v2-hspice-simulation-add-icon {
  margin-left: 40px;
}

.andes-v2-hspice-simulation-title:hover .andes-v2-hspice-simulation-icon,
.andes-v2-hspice-simulation-model-row:hover .andes-v2-hspice-simulation-icon {
  color: #0d87f7;
}

/* Tx model canvas position */
.andes-v2-hspice-simulation-tx-model-canvas-box {
  position: relative;
  margin-top: 20px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node,
.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node,
.andes-v2-hspice-simulation-tx-model-canvas-box>span {
  position: absolute;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node {
  left: 60px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node:nth-child(1) {
  top: 23px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node:nth-child(2) {
  top: 78px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node:nth-child(3) {
  top: 134px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>span {
  left: 50%;
  transform: translate(-50%, 0);
}

.andes-v2-hspice-simulation-tx-model-canvas-box>span:nth-of-type(1) {
  top: 12px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>span:nth-of-type(2) {
  top: 68px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>span:nth-of-type(3) {
  top: 124px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-signal-name {
  left: 490px;
  top: 78px;
  width: 140px;
  text-align: center;
}

/* Rx Capd model canvas position */
.andes-v2-hspice-simulation-rxCapd-model-canvas-box {
  position: relative;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node {
  left: 370px;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node:nth-child(1) {
  top: 40px;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node:nth-child(2) {
  top: 120px;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node:nth-child(3) {
  top: 200px;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-signal-name {
  position: absolute;
  left: 0px;
  top: 94px;
  width: 142px;
  text-align: center;
}

.andes-v2-hspice-simulation-rx-model {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}

/* ctle model */
.andes-v2-hspice-simulation-ctle-model {
  position: relative;
  background-color: #fff;
  border-radius: 2px;
  /* padding: 10px; */
  margin: auto;
  margin-top: 10px;
  width: 200px;
  height: 240px;
  border: 2px solid black;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.andes-v2-hspice-simulation-ctle-model>span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.andes-v2-hspice-simulation-ctle-model-item>hr {
  position: absolute;
  width: 300px;
  z-index: -1;
  left: -50px;
  height: 5px;
  background: #0e63b8;
  transform: scaleY(0.2);
}

.andes-v2-hspice-simulation-rx-model-item,
.andes-v2-hspice-simulation-rx-model-title-item,
.andes-v2-hspice-simulation-ctle-model-item {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.andes-v2-hspice-simulation-rx-model-title-item {
  margin-bottom: 10px;
}

/* .andes-v2-hspice-simulation-ctle-model>.andes-v2-hspice-simulation-ctle-model-item:nth-of-type(2), */
.andes-v2-hspice-simulation-rx-model>.andes-v2-hspice-simulation-rx-model-item:nth-of-type(3) {
  padding: 10px 0px;
}

.andes-v2-hspice-simulation-rx-model-item>hr,
.andes-v2-hspice-simulation-rx-model-title-item>hr {
  flex: 1 1;
  margin: auto 17px;
}

.andes-v2-hspice-simulation-rx-model-item>.andes-v2-hspice-simulation-rx-model-pin,
.andes-v2-hspice-simulation-rx-model-title-item>.andes-v2-hspice-simulation-rx-model-pin {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  padding: 0px 12px;
}

.andes-v2-hspice-simulation-rx-model-title-item>span,
.andes-v2-hspice-simulation-rx-model-title-item>.andes-v2-hspice-simulation-rx-model-node-select,
.andes-v2-hspice-simulation-rx-model-item>span {
  display: inline-block;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.andes-v2-hspice-simulation-rx-model-node-select {
  width: 100px;
}

.position-relative {
  position: relative;
}

.andes-v2-hspice-simulation-flex {
  display: flex;
  padding: 5px 0px;
}

/* node popover */
.andes-v2-hspice-simulation-node-box {
  width: 60px;
  text-align: center;
  cursor: pointer;
  color: #232323;
  background-color: #fff;
}

.andes-v2-hspice-simulation-rxCpad-node-box {
  background-color: transparent;
}

.andes-v2-hspice-simulation-node-popover-content {
  max-width: 300px;
  min-width: 200px;
  padding: 5px;
}

.andes-v2-hspice-simulation-node-select {
  width: 100%;
}

.andes-v2-hspice-simulation-node-span {
  display: inline-block;
  width: 100%;
}

.andes-v2-hspice-simulation-node-icon {
  position: relative;
  top: -5px;
  color: rgba(0, 0, 0, .5);
  font-size: 12px;
  margin-left: 5px;
  display: none;
}

.andes-v2-hspice-simulation-node-box:hover .andes-v2-hspice-simulation-node-span {
  width: 40px;
}

.andes-v2-hspice-simulation-node-box:hover .andes-v2-hspice-simulation-node-icon {
  display: inline-block;
}

/* Rx Model*/
.andes-v2-hspice-simulation-model-name-select-list {
  padding: 6px;
  overflow: auto;
  background-color: #eee;
  border-radius: 6px;
}

.andes-v2-hspice-simulation-model-name-select-list>div {
  display: block;
  width: 100%;
  height: 24px;
  line-height: 24px;
  padding: 0 6px;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
}

.andes-v2-hspice-simulation-model-name-select-list>.andes-v2-hspice-simulation-model-name-selected {
  background-color: #b9ecff;
}

.andes-v2-hspice-simulation-rx-tooltip {
  display: inline-block;
  margin: 10px 0px;
  font-style: italic;
  font-weight: 400;
}

.andes-v2-hspice-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-popover-hidden.spice-node-select-Popover {
  display: none;
}

.andes-v2-hspice-simulation-setting-button {
  display: flex;
  justify-content: center;
}

.andes-v2-hspice-simulation-setting-button>button {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  border-color: #1890ff;
  height: 24px;
  line-height: 23px;
  padding: 0px 12px;
}

.andes-v2-hspice-simulation-model-select-dropdown ul.ant-select-dropdown-menu-item-group-list {
  padding-left: 10px;
}

.andes-v2-hspice-simulation-file-not-exist {
  color: red;
}

.andes-v2-hspice-simulation-file-not-exist-icon {
  position: absolute;
  right: 5px;
  top: 6px;
  color: red;
}

.rx-model-ibis-table .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td {
  width: 50%;
}

.rx-model-ibis-table .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td:last-child {
  padding: 4px 8px;
}

.rx-model-ibis-table .ant-table>.ant-table-content .ant-table-body .ant-table-row td {
  position: relative;
  font-size: 14px;
}

.rx-model-ibis-table .ant-table-body table {
  table-layout: fixed;
}

.andes-v2-hspice-simulation-canvas-click-box {
  position: absolute;
  border-radius: 2px;
  cursor: pointer;
}

.andes-v2-hspice-simulation-canvas-tx-box {
  width: 160px;
  height: 200px;
}

.andes-v2-hspice-simulation-canvas-rx-box {
  width: 160px;
  height: 285px;
}

.andes-v2-hspice-simulation-canvas-rxCpad-box {
  width: 55px;
  height: 285px;
}

.andes-v2-hspice-simulation-canvas-ctle-box {
  width: 200px;
  height: 240px;
  border: 2px solid #404040;
}

.andes-v2-hspice-simulation-canvas-click-box:hover {
  border: 2px solid #404040;
}

.flex-box {
  display: flex;
}

.andes-v2-hspice-simulation-auto-match>span {
  cursor: pointer;
}

.andes-v2-hspice-simulation-auto-match {
  display: flex;
  justify-content: center;
  color: #005a9e;
}

.andes-v2-hspice-simulation-model-radio-group {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 50px;
  column-gap: 50px;
}

.andes-v2-hspice-simulation-model-radio-group>.ant-radio-wrapper {
  position: relative;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(33.33% - 50px);
  height: 90px;
}

.andes-v2-hspice-simulation-model-radio-group>.ant-radio-wrapper.radio-text {
  height: auto;
}

.position-absolute-left-10-top-0 {
  position: absolute;
  top: 0px;
  left: 10px
}

.andes-v2-hspice-simulation-vLine {
  width: 0px;
  height: 84px;
  border-left: 1px solid black;
}

.andes-v2-hspice-simulation-vLine-right,
.andes-v2-hspice-simulation-vLine-left {
  width: 10px;
  word-wrap: break-word;
  white-space: normal;
  margin: 0 15px;
}

.andes-v2-hspice-simulation-setting-model-content {
  margin: 10px;
  overflow: hidden;
  font-size: 14px;
  padding: 0px 10px;
}

.andes-v2-hspice-simulation-setting-model-content label {
  font-weight: 500;
}

.andes-v2-hspice-simulation-model-row>.andes-v2-hspice-simulation-icon {
  width: 6px;
}
.end-to-end-channel-select-content>div {
  padding: 5px 8px;
  width: 100%;
  line-height: 24px;
}

.end-to-end-channel-select-content .end-to-end-channel-select {
  width: calc(100% - 70px);
  margin: 5px 0px;
  display: inline-block;
}

.end-to-end-channel-select-content .end-to-end-channel-label {
  display: inline-block;
  width: 70px;
  line-height: 28px;
  height: 28px;
}

.end-to-end-channel-select-content .aurora-error-msg-span {
  margin: 5px 0px;
}
.end-to-end-channel-connection-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.end-to-end-channel-connection-table .ant-table-small .ant-table-container .aurora-table-body {
  margin: 0px;
}

.end-to-end-channel-connection-table .ant-table-small .ant-table-container .ant-table-body .aurora-table-body {
  padding-right: 22px;
}

.end-to-end-channel-connection-table tbody>tr>td:last-child {
  position: relative;
}

.end-to-end-channel-connection-table .ant-table-small>.ant-table-content .connection-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.end-to-end-channel-connection-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .connection-delete {
  color: #1890ff;
}

.end-to-end-channel-connection-table .ant-table .ant-table-container .aurora-table-body .ant-table-row td {
  position: relative;
}

.end-to-end-channel-connection-table tbody>tr .connection-signal-content {
  position: relative;
}

.end-to-end-channel-connection-table tbody>tr .connection-signal-content .editable-cell-value-wrap {
  width: calc(100% - 14px);
}

.pcb-connection-table-title {
  min-width: 131px;
  display: inline;
}

.pcb-connection-table-title>span {
  vertical-align: middle;
}

.pcb-connection-table-title .pcb-connection-add-section {
  margin-left: 6px;
  color: #3da4fa;
  cursor: pointer;
  font-size: 15px;
  vertical-align: middle;
}

.pcb-connection-table-title .pcb-connection-delete-section {
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  right: 6px;
  top: calc(50% - 7px);
}

.end-to-end-channel-connection-table .ant-table-row-cell-break-word:hover .pcb-connection-table-title .pcb-connection-delete-section {
  color: #3da4fa;
}

.end-to-end-channel-connection-table .ant-table-row-cell-break-word {
  position: relative;
}

.pcb-connections-schematic-main {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pcb-connections-schematic-item {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.pcb-connections-schematic-item .pcb-connections-schematic-pcb {
  padding: 0px 10px;
  font-size: 14px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  width: 100px;
}

.pcb-connections-schematic-item .pcb-connections-schematic-pcb .icon-tool-xinpian- {
  font-size: 23px;
  vertical-align: middle;
  margin-right: 6px;
  color: #2e85d7;
}

.pcb-connections-schematic-item .connection-pcb-signal1-line,
.pcb-connections-schematic-item .connection-pcb-signal2-line,
.pcb-connections-schematic-item .connection-pcb-signals-line {
  width: 80px;
  height: 20px;
  flex: 0 0 auto;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pcb-connections-schematic-item .connection-pcb-signals-line {
  font-size: 20px;
  line-height: 10px;
  color: #6095f3;
  margin: 0 auto;
}

.pcb-connections-schematic-item .connection-pcb-signal1-line {
  border-bottom: 1px solid #6095f3;
}

.pcb-connections-schematic-item .connection-pcb-signal2-line {
  border-top: 1px solid #6095f3;
}

.pcb-connections-schematic-main .pcb-connection-schematic-title-high-light .pcb-connections-schematic-pcb {
  background-color: #d3f1ff;
  border-color: #7ac4fb;
}

.pcb-connections-schematic-main .pcb-connections-schematic-signal-high-light .connection-pcb-signal1-line,
.pcb-connections-schematic-main .pcb-connections-schematic-signal-high-light .connection-pcb-signal2-line {
  border-width: 2px;
}

.end-to-end-channel-connection-table .ant-table-small .ant-table-container .ant-table-header {
  padding-right: 22px;
}
.connection-ports-list-file-dropdown {
  z-index: 1000000000;
}

.connection-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.connection-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.connection-port-list-body-with-search {
  height: 100%;
}

.connection-port-list-body-search-wrapper {
  width: 100%;
}

.connection-port-list-body-search-wrapper .ant-input-affix-wrapper,
.connection-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.connection-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.connection-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.connection-port-list-ul>li:hover {
  background-color: #cfedff;
}


.connection-port-list-ul>.current-pin-selected-port-li:hover {
  background-color: #93e6ff;
}

.connection-model-pin-port-content {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 52px;
}

.connection-model-pin-port-content .connection-pin-list-ul {
  height: 100%;
  margin: 0;
  padding: 8px 10px;
  list-style: none;
}

.connection-pin-connect-port-item {
  width: 100%;
  min-width: 370px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.connection-pin-list-signal>.connection-pin-connect-port-item:nth-child(2) {
  z-index: 0;
}

.spice-pin-list-ul .connection-pin-connect-port-item-center {
  justify-content: center;
}

.connection-box {
  flex: 0 0 auto;
  width: 110px;
  background-color: #eeeeee;
  height: 100%;
  /* border-bottom: 1px solid #d9d9d9; */
  text-align: center;
  font-size: 12px;
  padding: 8px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.connection-box>.connection-box-net-text {
  display: block;
  line-height: 26px;
  color: #005a9e;
}

.connection-box-top {
  border-radius: 4px 4px 0px 0px;
}

.connection-box-bottom {
  border-radius: 0px 0px 4px 4px;
  border-bottom: none;
}

.connection-pin-l-box,
.connection-pin-net-box {
  padding: 0px 8px;
  flex: 0 0 auto;
  max-width: 180px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  z-index: 10;
}

.connection-pin-net-box {
  max-width: 150px;
}

.connection-pin-r-box {
  padding: 0px 8px;
  flex: 0 0 auto;
  max-width: 180px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  z-index: 10;
}

.connection-pin-line,
.connection-pin-long-line {
  width: 20px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  transform: scaleY(0.2);
}

.connection-pin-long-line {
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 0;
  top: 50%;
}

.connection-pin-circle {
  width: 8px;
  height: 8px;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
}

.connection-model-pin-port-content .connection-pin-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 80px;
  width: 60px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: auto;
  line-height: 18px;
  position: relative;
  vertical-align: top;
  background: #ffffff;
}

.connection-model-pin-port-content .connection-pin-empty-input {
  flex: 0 0 auto;
  max-width: 80px;
  width: 60px;
  min-width: 50px;
  display: inline-block;
  cursor: text;
  height: auto;
  line-height: 18px;
  position: relative;
}

.connection-pin-cable-value-box .connection-pin-input-value {
  background: #eee;
}

.spice-pin-input .ant-input:not(:last-child) {
  padding-right: 11px;
}

.spice-pin-input:hover>span:not(.anticon) {
  max-width: 65px;
}

.spice-pin-input>span:not(.anticon) {
  display: inline-block;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* node select model */
.connection-model-port-select-Popover {
  z-index: 100000000;
  display: flex;
  padding: 0px;
}

.connection-model-port-select-Popover .ant-popover-inner-content {
  padding: 0px 0px;
}

.connection-model-port-select-Popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}

.node-list-body-search-wrapper .ant-input {
  border-radius: 4px 4px 0px 0px;
}

.connection-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.connection-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.connection-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.connection-port-list-ul>.port-li-selected,
.connection-port-list-ul>.port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.connection-port-list-ul>.current-pin-selected-port-li {
  background-color: #93e6ff;
}

.connection-model-pin-port-content .connection-pin-l-value-box,
.connection-model-pin-port-content .connection-pin-r-value-box,
.connection-model-pin-port-content .connection-pin-cable-value-box {
  height: 60px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  width: 34%;
  position: relative;
  z-index: 20;
}

.connection-model-pin-port-content .connection-pin-cable-value-box {
  width: 32%;
  justify-content: space-around;
}

.connection-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
}

.connection-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.connection-pin-input:hover .connection-pin-node-delete-icon {
  display: inline-block;
}

.connection-ports-list-file-select {
  width: 100%;
  font-size: 12px;
  margin-bottom: 6px;
  height: 28px;
}

.connection-ports-list-file-select {
  height: 28px;
}

.connection-ports-list-file-select .ant-select-selector {
  line-height: 26px;
}

.spice-model-add-file-icon {
  margin-left: 10px;
  color: #3da4fa;
  font-size: 14px;
  vertical-align: text-bottom;
}

.spice-model-multi-select {
  display: flex;
  position: relative;
  width: 100%;
}

.spice-netlist-model-select-content {
  width: calc(100% + 10px);
  ;
}

.spice-model-single-select {
  position: relative;
  width: 100%;
}

.spice-model-file-error-msg {
  display: block;
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.spice-model-single-select:hover .spice-model-delete-file-icon,
.spice-model-multi-select:hover .spice-model-delete-file-icon {
  visibility: visible;
}

.spice-model-delete-file-icon {
  color: #3da4fa;
  font-size: 13px;
  visibility: hidden;
  position: absolute;
  right: 2px;
  top: 10px;
}

.spice-netlist-model-select .Subckt {
  width: 0px;
}

.spice-netlist-model-select .Subckt_select {
  width: 100%;
}

.spice-model-multi-select .spice-netlist-model-select {
  padding-left: 5px;
}

.spice-model-multi-select>*:first-child {
  padding-right: 5px;
  padding-left: 10px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-title {
  font-size: 14px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-list {
  background: #f2f2f2;
}

.connection-pin-list-signal {
  padding: 6px 0px;
  position: relative;
}

.connection-model-pin-port-content .connection-pin-list-channel1-signal-title,
.connection-model-pin-port-content .connection-pin-list-channel2-signal-title,
.connection-model-pin-port-content .connection-pin-list-connection-signal-title {
  height: 120px;
  line-height: 120px;
  color: #2d82d7;
  margin: auto;
  position: absolute;
  top: 60px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  text-align: center;
  z-index: -1;
}

.connection-pin-list-connection-signal-title>span {
  display: inline-block;
  max-width: calc(100% - 26px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.connection-pin-list-channel-signal-span {
  display: inline-block;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0px 6px;
}

.connection-pin-list-channel-signal-span:hover {
  color: #3da4fa;
}

.connection-pin-input-pinport .connection-pin-input-pinport-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  display: inline-block;
  vertical-align: middle;
}
.connector-model-select-panel {
  top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.connector-model-select-panel .panel-body {
  overflow: auto
}

.connector-model-select-main {
  padding: 8px 14px;
  min-height: 200px;
  min-width: 750px;
  overflow: auto;
}

.connector-model-select-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.connector-model-select-content .connector-model-select-item {
  min-height: 28px;
  line-height: 28px;
  height: -moz-fit-content;
  height: fit-content;
}

.connector-model-select-item>span {
  width: 140px;
}

.connector-model-select-main .multi-connector-model-file-content>span {
  width: 154px;
}

.connector-model-select-main .multi-connector-model-file-content>.connection-model-select {
  width: calc(100% - 154px);
}

.connector-model-select-item>.connection-model-select {
  width: calc(100% - 140px);
  height: -moz-fit-content;
  height: fit-content;
  /* min-height: 28px; */
}

.connector-model-select-ports-main {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* height: 100px; */
  border-bottom: 1px solid #eee;
  background-color: #eee;
  padding: 10px;
}

.connector-model-port-signal,
.connector-model-port-copy-signal {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* height: 80px; */
  text-align: center;
  /* line-height: 80px; */
  color: #005a9e;
}

.connector-model-port-copy-signal .aurora-select {
  width: calc(100% - 20px);
  margin: 0px 10px;
}

.connector-model-port-pins,
.connector-model-port-copy-pins {
  /* height: 80px; */
  width: calc(100% - 200px);
  margin-left: 200px;
}

.connector-model-port-pin {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 28px;
  padding: 5px 0px;
  position: relative;
}

.connector-model-port-pin-title {
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-align: left;
  text-indent: 10px;
  line-height: 28px;
  color: #005a9e;
  display: inline-block;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
  max-width: 150px;
}

.connection-model-pin-input-div {
  flex: 0 0 auto;
  text-align: center;
  max-width: 100px;
  width: 90px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  /*  vertical-align: top; */
  background: #eee;
}

.connection-pin-input-pin-port .connection-pin-input-pin-port-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  width: calc(100% - 48px);
  display: inline-block;
  vertical-align: middle;
}

.connection-model-pin-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 100px;
  width: 90px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
  background: #ffffff;
}

.connector-model-select-pins-content {
  padding: 6px 10px;
  background-color: #eee;
  border-radius: 4px;
}

/* port popover */

.connection-model-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.connection-model-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.connection-model-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.connection-model-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.connection-model-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.connection-model-port-list-body-with-search {
  height: 100%;
}

.connection-model-port-list-body-search-wrapper {
  width: 100%;
}

.connection-model-port-list-body-search-wrapper .ant-input-affix-wrapper,
.connection-model-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  /* height: 28px;
  line-height: 28px; */
  font-size: 13px;
}

.connection-model-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.connection-model-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.connection-model-port-list-ul>li:hover {
  background-color: #cfedff;
}

.connection-model-port-list-ul>.connector-current-pin-selected-port-li:hover {
  background-color: #93e6ff;
}

.connection-model-port-list-ul>.connector-port-li-selected,
.connection-model-port-list-ul>.connector-port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.connection-model-port-list-ul>.connector-current-pin-selected-port-li {
  background-color: #93e6ff;
}

.connection-model-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
}

.connection-model-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.connection-model-pin-input-div:hover .connection-model-pin-node-delete-icon {
  display: inline-block;
}

.connector-model-select-copy-group {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 6px;
  margin: 6px 0px;
  position: relative;
}

.connector-model-select-copy-icon {
  color: #3da4fa;
  margin-top: 7px;
  cursor: pointer;
  margin-left: 10px;
}

.connector-model-select-item>.connector-model-select-span {
  width: 196px;
}

.connector-model-select-delete-group-icon {
  position: absolute;
  right: 0px;
  top: calc(50% - 25px);
  height: 50px;
  width: 16px;
  line-height: 50px;
  background: #b6d9f6;
  color: #3da4fa;
  cursor: pointer;
  display: none;
  transition: 0.3s;
}

.connector-model-select-copy-group:hover .connector-model-select-delete-group-icon {
  display: block;
}

.connector-model-port-pin .connection-pin-long-line {
  right: 2px;
}

.connector-selected-apply-signals {
  color: rgb(109, 109, 109);
  background-color: #ececec;
}

.connector-model-pin-type-span {
  font-size: 16px;
  height: 28px;
  text-align: center;
  display: inline-block;
  line-height: 28px;
  color: #005a9e;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

.connector-model-port-pin-content {
  min-width: 74px;
  height: 28px;
  background: #eee;
  position: relative;
  z-index: 1;
  padding-left: 4px;
}

.connector-model-select-delete-icon {
  position: absolute;
  right: 0px;
  top: 8px;
  font-size: 12px;
  width: 12px;
}

.connector-model-select-main .multi-connector-model-file-content>.connector-model-select-delete-icon {
  width: 12px;
}

.connector-model-select-item:hover .connector-model-select-delete-icon {
  color: #0d87f7;
}

.connector-model-select-main .multi-connector-model-file-content>.connection-model-select {
  width: calc(100% - 176px);
}

.connector-model-auto-match-icon {
  display: flex;
  justify-content: center;
  color: #005a9e;
}

.connector-model-auto-match-icon>.iconfont.icon-xinpian_chip {
  margin-left: 10px;
  cursor: pointer;
}

.connection-v1-model-select-content .aurora-circle-tag {
  bottom: 2px;
}
.connection-v1-model-assign-content {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  position: relative;
}

.connection-v1-left-channel {
  justify-items: flex-start;
  width: 36%;
  min-width: 300px;
  min-height: 100px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: relative;
}

.connection-v1-right-channel {
  justify-items: flex-end;
  width: 36%;
  min-width: 300px;
  min-height: 100px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: relative;
}

.connection-v1-model-assign-content .connection-v1-component-name {
  font-size: 14px;
  font-weight: 600;
  max-width: 100%;
  padding: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.connection-v1-model-assign-content .connection-v1-pin-left-ul {
  position: absolute;
  /* right: -106px; */
  right: -75px;
  top: 100px;
}

.connection-v1-model-assign-content .connection-v1-pin-right-ul {
  position: absolute;
  /* left: -86px; */
  left: 0px;
  top: 100px;
}

.connection-v1-component-select-content {
  margin: 6px 14px;
  position: relative;
  margin-right: 4px;
}

.connection-v1-component-select-content>.connection-v1-component-select-title {
  width: 134px;
  display: inline-block;
  color: #005a9e;
}

.connection-v1-component-select-content .connection-v1-model-select-div {
  width: calc(100% - 176px);
  display: inline-block;
  cursor: pointer;
}

.connection-v1-component-select-content .connection-v1-model-select-div .connection-v1-select {
  width: 100%;
  color: rgba(0, 0, 0, 0.65);
}

.connection-v1-component-select-content .ant-select-disabled .ant-select-selection {
  background: #ffffff;
  cursor: pointer;
}

.connection-v1-component-select-content .ant-select-arrow .ant-select-suffix {
  display: none;
}

.connection-v1-component-select-content .connection-v1-model-select-content>.connection-v1-model-select-title {
  width: 120px;
  display: inline-block;
  color: #005a9e;
}

.connection-v1-cable-model-content .connection-v1-component-select-content>.connection-v1-component-select-title {
  width: 106px;
}

.connection-v1-cable-model-content {
  background: #eee;
  border: 1px solid #ccc;
  width: calc(28% - 20px);
  margin: 0px 10px;
  border-radius: 4px;
}

.connection-v1-cable-model-none-content {
  background: #ffffff !important;
  border: none !important;
}

.connection-v1-file-error .connection-v1-select {
  color: #f30518 !important;
}

.connection-v1-file-error {
  position: relative;
}

.connection-v1-file-error .aurora-file-check-icon {
  right: 96px;
  top: 14px;
}

.connector-select-dropdown-menu {
  z-index: 10000000000;
  max-height: 300px !important;
  overflow: auto;
}

.connection-v1-model-component-select-content {
  position: absolute;
  left: 14px;
  right: 14px;
  top: 38px;
}

.connector-model-close-file-icon {
  color: #3da4fa;
  font-size: 14px;
  position: absolute;
  right: -12px;
  top: 8px;
  display: none;
}

.connection-v1-component-select-content:hover .connector-model-close-file-icon {
  display: inline-block;
}

.connection-v1-model-assign-content .aurora-model-name-error-msg {
  margin: 0px 14px;
  margin-right: 24px;
  padding: 4px 10px;
}

.connection-v1-select {
  width: calc(100% - 120px);
}

.connection-v1-model-component-select-content>span {
  width: 120px;
  display: inline-block;
  color: #005a9e;
}

.connection-v1-model-select-content {
  /* position: relative; */
  display: inline-block;
  width: 100%;
}

.connection-v1-model-select-subckt-div {
  display: inline-block;
  width: calc(100% - 108px);
  margin-left: 34px;
  margin-top: 6px;
}

.connection-v1-model-select-content:hover .connection-v1-model-select-add-div {
  right: 1px;
  z-index: 100;
}

.connection-v1-model-select-add-div {
  position: absolute;
  right: -30px;
  width: 30px;
  background: #c2eafd;
  top: 1px;
  height: 26px;
  line-height: 26px;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: -222;
  text-align: center;
}

.connection-v1-model-select-add-div .anticon {
  color: #005a9e;
  line-height: 26px;
}

.connector-model-delete-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  font-size: 12px;
  transform: translateY(-50%)
}

.connection-v1-component-select-content:hover .connector-model-delete-icon {
  color: #0d87f7;
}

.connection-v1-model-auto-match-icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: #005a9e;
  z-index: 10;
}

.connection-v1-model-auto-match-icon>.iconfont.icon-xinpian_chip {
  margin-left: 10px;
  cursor: pointer;
}
.end-to-end-channel-connection-content {
  padding: 8px 14px;
}

.end-to-end-channel-connection {
  min-width: 970px;
  padding: 10px;
  max-height: 100%;
  background: #ffffff;
}

.andes-connection-signal-map-content {
  padding: 10px;
}

.andes-connection-table .ant-table-small>.ant-table-content .ant-table-header {
  padding-right: 22px;
}

.andes-connection-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-connection-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 14px;
}

.andes-connection-table .ant-table-small .ant-table-container .ant-table-body .aurora-table-body {
  padding-right: 22px;
}

.andes-connection-table tbody>tr>td:last-child {
  position: relative;
}

.andes-connection-table .ant-table-small>.ant-table-content .connection-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.andes-connection-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .connection-delete {
  color: #1890ff;
}

.andes-connection-signal-map-content .signal-add-icon {
  vertical-align: middle;
}
.connection-v1-channel-title>div {
  font-size: 14px;
  margin: 0px 16px;
  min-width: 100px;
}

.connection-v1-channel-title>div .connection-v1-pcb-name {
  color: #0d82d7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding-right: 10px;
  line-height: 32px;
}

.connection-v1-channel-title .connection-v1-border {
  height: 20px;
  width: 1px;
  min-width: 1px;
  background: #ccc;
  margin-top: 26px;
}

.connection-v1-channel-title>div .connection-v1-channel-name {
  color: #67676f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  line-height: 32px;
}

.connection-v1-channel-title {
  display: flex;
  justify-content: center;
  transition: 0.3s;
}

.connection-v1-model-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.connection-v1-model-content .change-connection-v1-icon {
  font-size: 16px;
  width: 26px;
  display: inline-block;
  cursor: not-allowed;
  padding-left: 7px !important;
  padding-right: 8px !important;
  line-height: 32px;
  height: 30px;
  margin-bottom: 2px;
}

.connection-v1-model-content .change-connection-v1-icon i {
  margin-left: -1.5px;
}

.connection-v1-model-content .change-connection-v1-icon:hover {
  background-color: #bbd6f0;
}

.connection-v1-model-list {
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.connection-v1-title-pcb {
  text-align: center;
  font-weight: 600;
}

.connection-v1-model-main {
  transition: 0.3s;
  width: 100%;
}

.connection-v1-name-content {
  text-align: center;
}

#connector-left-models,
#connector-right-models {
  width: 100%;
}
.pre-layout-setting {
  padding: 10px 14px;
}

.pre-layout-extraction {
  min-width: 708px;
}

.andes-v2-pre-layout-panel {
  top: 130px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

/* Component table */

.andes-v2-content-setting .pre-layout-component-table .ant-table-thead>tr>th .ant-table-header-column,
.andes-v2-content-setting .pre-layout-signals-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.andes-v2-content-setting .pre-layout-component-table .ant-table-thead>tr>th:nth-child(2) .ant-table-header-column .ant-table-column-title,
.andes-v2-content-setting .pre-layout-component-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title {
  padding-left: 4px
}

.andes-v2-content-setting .pre-layout-component-table .editable-cell-value-wrap,
.andes-v2-content-setting .pre-layout-signals-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-v2-content-setting .pre-layout-component-table {
  background: #ffffff;
}

.pre-layout-select,
.pre-layout-input {
  width: calc(100% - 180px);
}

.pre-layout-span {
  display: inline-block;
  width: 180px;
  height: 28px;
  line-height: 28px;
}

.pre-layout-pcie-select {
  display: flex;
  width: 100%;
  line-height: 34px;
  height: 34px;
}

.pre-layout-spacing .pre-layout-span {
  width: 190px;
}

.pre-layout-spacing,
.pre-layout-unit {
  padding-left: 30px;
}

.pre-layout-unit .pre-layout-span {
  width: 38px;
}

/* interface type */

.pre-layout-serdes-type-content {
  width: calc(100% - 180px);
  display: inline-block;
  position: relative;
  line-height: 28px;
}

#pre-layout-serdes-type-width-input-id {
  height: 28px;
}

.pre-layout-serdes-type-width-input {
  width: 100%;
  font-size: 13px;
  height: 28px;
}

.pre-layout-serdes-type-width-select {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 0px;
  border: none;
  box-shadow: 0px 0px 0px;
}

.pre-layout-serdes-type-width-select .ant-select-selector {
  visibility: hidden;
}

.pre-layout-serdes-type-width-select .ant-select-selection:focus,
.pre-layout-serdes-type-width-select .ant-select-selection:active,
.pre-layout-serdes-type-width-select .ant-select-selection {
  border: none;
  height: 0px;
  box-shadow: 0px 0px 0px;
}

.pre-layout-signal-delete-icon {
  position: absolute;
  right: -5px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
  display: none;
}

.pre-layout-signals-table .aurora-table-body>tr.ant-table-row-selected td {
  background-color: inherit;
}

.pre-layout-signals-table .pre-layout-section-template {
  display: block;
  min-width: 72px;
}

.pre-layout-signals-table .pre-layout-section-length {
  display: block;
  min-width: 64px;
}

.pre-layout-signals-table .ant-table>.ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
}

.pre-layout-signals-table .ant-table-thead>tr>th,
.pre-layout-signals-table .aurora-table-body>tr>td {
  text-align: center;
}

.pre-layout-signals-table .ant-table>.ant-table-content .aurora-table-body .ant-table-row td .editable-cell-value-wrap {
  display: grid;
}

.andes-pre-layout-help {
  position: absolute;
  right: 10px;
}

.pre-layout-unit-main {
  position: absolute;
  right: 300px;
  top: 7px;
}

.pre-layout-unit-main .aurora-select {
  width: 80px;
}

.pre-layout-unit-main .pre-layout-span {
  width: 36px;
}

.andes-v2-content-setting .pre-layout-fold {
  cursor: pointer;
  display: inline-block;
  margin-left: 0px;
}

.andes-v2-content-setting .pre-layout-fold-icon {
  margin-top: 0px;
  vertical-align: middle;
}

.andes-v2-content-setting .pre-layout-fold>span {
  vertical-align: middle;
}

.andes-v2-pre-layout-panel .pre-layout-setting .sub-extraction-input .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.andes-v2-pre-layout-panel .pre-layout-pcie-select .pre-layout-span {
  width: 180px;
}

.andes-v2-pre-layout-panel .pre-layout-pcie-select .pre-layout-select,
.andes-v2-pre-layout-panel .pre-layout-pcie-select .pre-layout-input {
  width: calc(100% - 180px);
}

.pre-layout-lanes-number-select {
  width: 100%;
}

.andes-v2-pre-layout-panel .aurora-select .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item {
  height: 24px;
  align-self: flex-start;
}

.andes-v2-pre-layout-panel .aurora-select .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item>.ant-select-selection-item {
  box-sizing: border-box;
  height: 20px;
  line-height: 18px;
}
.pre-layout-help-panel {
  top: 130px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.pre-layout-help-panel .pre-layout-help-content,
.pre-layout-help-panel .pre-layout-help-content img {
  width: 100%;
}
.sweep-config-content {
  padding: 10px 16px;
}

.sweep-config-title {
  font-size: 16px;
  font-weight: 500;
}

.sweep-config-radio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.sweep-config-item {
  width: 100%;
  margin: 5px 0;
  min-height: 28px;
  line-height: 28px;
}

.sweep-config-item>span {
  display: inline-block;
  width: 120px;
  vertical-align: middle;
  /* font-size: 13px; */
}

.sweep-config-item>.aurora-select,
.sweep-config-item>.aurora-input {
  width: calc(100% - 130px);
  vertical-align: middle;
}

.andes-v2-sweep-add-icon {
  vertical-align: middle;
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.andes-v2-sweep-dropdown-icon {
  vertical-align: middle;
  color: #0d87f7;
  font-size: 12px;
  margin-right: 5px;
  cursor: pointer;
  margin-top: -2px;
}

.andes-v2-sweep-table .ant-table-thead>tr>th,
.andes-v2-sweep-table .aurora-table-body>tr>td {
  text-align: center;
}

/* .andes-v2-sweep-title-box {
  position: relative;
}

.andes-v2-sweep-title-box>.andes-v2-sweep-title-name {
  position: absolute;
} */

.andes-v2-sweep-title-checkbox {
  position: absolute;
  max-height: 200px;
  background-color: #fff;
  top: 90px;
  left: calc(50% - 60px);
  margin-top: 8px;
  border-radius: 0 0 2px 2px;
  z-index: 999;
  overflow: auto;
  box-shadow: 1px 4px 6px rgb(0 0 0 / 31%);
}

.andes-v2-sweep-title-checkbox ul {
  margin: 0;
  padding: 0;
}

.andes-v2-sweep-title-checkbox ul li {
  width: 100%;
  text-align: left;
  padding: 4px 23px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sweep-setting-panel {
  max-height: 600px;
}

.andes-v2-sweeping-column-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 16px;
}

.andes-v2-sweeping-column-name {
  line-height: 24px;
  vertical-align: middle;
  position: relative;
}

.andes-v2-sweeping-column-name>span {
  color: #1890ff;
  position: absolute;
  visibility: hidden;
  top: 0px;
  right: 5px;
}

.andes-v2-sweeping-column-name:hover>span {
  visibility: visible;
}

.andes-v2-sweep-variable-setting-icon {
  color: #005a9e;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  position: relative;
  top: 1px;
}

.andes-v2-sweep-apply-all-checkbox {
  padding: 4px 8px;
}

.andes-v2-sweep-select-dropdown-menu {
  text-align: left;
}

.andes-v2-sweep-select-dropdown-divider {
  margin: 3px 0px;
}

/* .andes-v2-content-setting {
  min-width: 1350px;
} */

.andes-v2-sweep-not-exist-net {
  color: red;
}

.andes-v2-sweep-icon-info-circle {
  position: relative;
  top: 2px;
  width: 24px;
  font-size: 16px;
}

.andes-v2-s-parameter-download-panel .aurora-debug-download-content {
  min-width: 260px;
}

.andes-v2-s-parameter-download-panel .aurora-debug-download-content .aurora-debug-item-content {
  width: 100%;
}

.sweep-config-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sweep-config-panel .sweep-config-panel-content {
  padding: 8px 14px;
}

.andes-v2-sweep-value-text {
  height: 28px;
  text-align: center;
}

.ant-select.ant-select-sm.sweep-trace-width-select {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 0px;
  border: none;
  box-shadow: 0px 0px 0px;
}

.ant-select.ant-select-sm.sweep-trace-width-select .ant-select-selector {
  visibility: hidden;
}

.sweep-trace-width-select .ant-select-selection:focus,
.sweep-trace-width-select .ant-select-selection:active,
.sweep-trace-width-select .ant-select-selection {
  border: none;
  height: 0px;
  box-shadow: 0px 0px 0px;
}

.sweep-trace-width-inputGroup {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}

.sweep-trace-width-inputGroup .ant-input {
  min-width: 50px;
  text-align: center;
}

.andes-v2-sweep-table,
.andes-v2-sweep-table .aurora-table-body {
  overflow-x: hidden !important;
}

.andes-v2-sweep-table-tdClass {
  white-space: nowrap;
}

.andes-v2-sweep-table .ant-table-row label.ant-checkbox-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.andes-v2-result-experiment-display {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  min-width: 350px;
  float: right;
}

.andes-v2-result-experiment-select {
  width: 300px;
  margin-right: 50px;
}

.andes-v2-result-experiment-select .ant-select-selection--multiple {
  height: 28px;
}
.my-aurora-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-aurora-content>.ant-spin-nested-loading,
.my-aurora-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.aurora-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}
.extraction-content {
  line-height: 28px;
  margin-bottom: 5px;
}

.extraction-content-body,
.extraction-content-sub-body {
  width: 45%;
}

.extraction-content-sub-body {
  margin-left: 88px;
  font-style: normal;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
}

.extraction-content-body {
  font-size: 13px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 150px;
}

.extraction-content-sub-body .extraction-content-body {
  padding-left: 0px;
}

.extraction-radio-group {
  margin-bottom: 8px;
}

.extraction-radio-group .ant-divider-horizontal {
  margin: 10px 0px;
}

.extraction-radio-group-span {
  display: inline-block;
  margin-right: 14px;
}

.extraction-gray {
  color: #9c9c9c;
}

.extraction-radio-clip-group {
  margin: 0px 10px;
  margin-left: 87px;
}

.extraction-radio-clip-group .extraction-clip-radio-item {
  vertical-align: middle;
  margin-left: 8px;
}

.extraction-content .extraction-input,
.extraction-content .sub-extraction-input {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  height: 28px;
}

.extraction-content .sub-extraction-input {
  margin-left: 10px;
}

.extraction-content .extraction-input .ant-input-group .ant-input,
.extraction-content .sub-extraction-input .ant-input-group .ant-input {
  height: 28px;
}

.extraction-content .extraction-input .ant-input-group-addon,
.extraction-content .sub-extraction-input .ant-input-group-addon {
  width: 65px;
  padding: 0px 6px;
  font-size: 13px;
}

.extraction-content .extraction-input .ant-input-group-addon .ant-select,
.extraction-content .sub-extraction-input .ant-input-group-addon .ant-select {
  font-size: 13px;
  width: 68px;
  margin: -5px -8px;
}

.extraction-content .extraction-input .ant-input-group-addon .ant-select,
.extraction-content .sub-extraction-input .ant-input-group-addon .ant-select {
  width: 68px;
}


.extraction-option-error-msg {
  display: block;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 10px 16px 4px 20px;
  font-size: 14px;
}

.extraction-content .extraction-input-width {
  width: calc(100% - 320px);
}

.extraction-content .extraction-input-item {
  width: calc((100% - 300px) * 0.35);
}

.extraction-content .extraction-input-item-sub {
  width: calc((100% - 300px) * 0.3);
}

.extraction-content .extraction-step-width {
  width: 112px;
}

.extraction-setting-icon {
  color: #188fff;
  vertical-align: middle;
}

.extraction-content-back-drill-input .extraction-content-body {
  width: 192px;
}

.extraction-content-back-drill-input,
.extraction-content-lic-wait-input,
.extraction-content-time-out-input {
  display: inline-block;
  width: calc(100% - 200px);
  padding-left: 29px;
}

.extraction-content-back-drill-input .extraction-input {
  width: calc(100% - 282px);
}

.extraction-content-lic-wait-input .extraction-input {
  width: calc(100% - 92px);
  margin-left: 81px;
}

.extraction-content-time-out-input {
  padding-left: 112px;
}

.extraction-content-time-out-input .extraction-input {
  width: calc((100% - 182px) / 2);
}

.extraction-content-time-out-input>.extraction-content-body {
  width: 160px;
  padding-right: 0px;
  padding-left: 0px;
}

.extraction-content .extraction-clip-design-input {
  width: calc(100% - 478px);
}

.extraction-content-hpc .extraction-content-body {
  width: 288px;
}

.extraction-content-hpc>.extraction-content-body {
  width: 150px;
}

.extraction-content-hpc .extraction-content {
  padding-left: 20px;
}

.extraction-content-hpc .extraction-content .extraction-input-width {
  width: calc(100% - 300px);
}

.extraction-content .extraction-content-log-sweep-body {
  width: 180px;
}
.andes-v2-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-v2-content-setting {
  padding: 20px;
  background: #ffffff;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
}

.andes-v2-prelayout-setting {
  overflow: auto;
}

.andes-v2-content-setting .andes-v2-content-setting-box {
  min-width: 1350px;
}

.andes-v2-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.andes-v2-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.andes-v2-select-dropdown-menu {
  z-index: 10000000000;
}

.andes-customized-title .design-name {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
}

.andes-customized-title .component-pin-name {
  color: #3b70a7;
  display: inline-block;
}

.andes-customized-title .signal-name {
  color: #2f7b35;
}

.andes-customized-title .port-mode {
  color: rgba(0, 0, 0, 0.65);
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #005a9e;
  background: #c7e0f4;
  border-radius: 0px;
  border-color: #c7e0f4;
  height: 30px;
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 30px;
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-bar {
  margin: 0px;
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-card-bar .ant-tabs-tab {
  line-height: 28px;
  border-radius: 0px;
  height: 30px;
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-card-bar .ant-tabs-tab:first-child {
  border-left: 0px;
}

.andes-v2-channel-setup {
  overflow: visible;
}

#andes-v2-content-main.andes-v2-lr-content-main,
#andes-v2-content-main.andes-v2-tb-content-main {
  top: 45px;
}

#andes-v2-content-main.andes-v2-lr-result-main {
  top: 0px;
}

.andes-v2-content-bottom .andes-v2-content-setting {
  top: 45px;
}

.pcb-channel-main {
  top: 0px !important;
}

.andes-v2-setup-normal {
  top: 45px;
}

.andes-v2-result-normal {
  top: 0px;
}

.andes-v2-result-content-channel,
.andes-v2-result-content-waveform {
  overflow: hidden;
}

.andes-v2-content-setting.pcb-channel-main .ant-spin-container>.space-10:last-child {
  margin-bottom: 22px;
  padding-bottom: 20px;
}

.andes-v2-prelayout-setting .pkg-new-panel .package-model-select .package-model-selection {
  margin-left: 0px;
  width: calc(100% - 131px);
}

.andes-v2-result-content-waveform .andes-v2-result-content-waveform-radio {
  padding-left: 16px;
  height: 40px;
  line-height: 40px;
}

.andes-customized-title .driver-or-receiver {
  display: inline-block;
  font-weight: 700;
  width: 80px;
}

.andes-content .aurora-select.ant-select-multiple .ant-select-selector::after,
.andes-content .aurora-select.ant-select-multiple .ant-select-selection-wrap::after {
  line-height: 20px;
}

.andes-content .aurora-select.ant-select-multiple .ant-select-selection-overflow-item::after,
.andes-content .aurora-select.ant-select-multiple .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item {
  height: 20px;
}

.andes-content .aurora-select.ant-select-multiple .ant-select-selection-overflow-item-suffix {
  min-height: 20px;
}

.andes-content .aurora-select.ant-select-multiple .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item>.ant-select-selection-item {
  box-sizing: border-box;
  height: 18px;
  line-height: 16px;
}
.himalayas-sider-menu-tree .ant-tree .ant-tree-title .aurora-project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
  line-height: 24px;
  height: 22px;
}

.himalayas-sider-menu-tree .ant-tree-title:hover .himalayas-library-title {
  width: calc(100% - 26px);
}

.himalayas-sider-menu-tree .himalayas-rename-icon {
  margin-right: 22px;
}

.himalayas-sider-menu-tree .aurora-tree-project-edit-icon {
  margin-right: 22px;
}

.himalayas-sider-menu-tree .ant-tree-title:hover .himalayas-library-edit-title {
  margin-right: 22px;
  width: calc(100% - 46px);
}
.himalayas-edit-library-content {
  width: 100%;
  padding: 16px;
}

.himalayas-edit-library-option-dropdown {
  z-index: 9999;
}

.himalayas-edit-library-item {
  width: 100%;
  display: flex;
}

.himalayas-edit-library-item>label {
  width: 100px;
  height: 28px;
  line-height: 28px;
}

.himalayas-edit-library-item>.aurora-select {
  width: calc(100% - 100px);
}

.himalayas-library-edit-panel-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top-bar-report-item {
  position: relative;
  padding: 6px 0px;
}

.top-bar-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.top-bar-report {
  line-height: 21px;
  height: 21px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin: 6px 0;
  padding: 0 14px;
}

.top-bar-item:hover {
  background-color: #e0e0e0;
  transition: all .3s;
}

.top-bar-report-icon {
  margin: -2px 8px 0px 0px;
  font-size: 14px;
  display: inline-block;
  vertical-align: bottom;
}

.top-bar-icon-diff {
  padding-top: 2px;
}

.top-bar-report-progress {
  width: 0px;
  height: 33px;
  background-color: #82c2fd;
  float: left;
  position: absolute;
  overflow: visible;
  top: 0;
  z-index: 1;
}

.top-bar-item {
  margin: 6px 6px 6px 0;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.report-message-main {
  margin-top: 10px;
  position: relative;
  min-height: 27px;
}

.report-download-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: calc(100% - 90px);
  padding: 0px 8px;
}

.report-download-button {
  position: absolute;
  right: 0px;
  bottom: 2px;
}

.report-download-btn {
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.report-progress {
  right: 10px;
  padding: 2px 0px;
  position: absolute;
  width: auto;
  left: 0px;
}

.report-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.report-progress .ant-progress-text {
  vertical-align: unset;
}

.report-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
#himalayas-top-bar {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
}

.himalayas-top-bar-left-item {
  display: flex;
  align-items: center;
}

.himalayas-top-bar-right-item {
  position: absolute;
  right: 0;
  display: flex;
}

.himalayas-top-bar-item {
  margin: 6px 6px 6px 0;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.himalayas-top-bar-item:hover {
  background-color: #e0e0e0;
  transition: all .3s;
}

.himalayas-top-bar-item .anticon-play-circle,
.himalayas-top-bar-item .anticon-loading,
.himalayas-top-bar-setting {
  margin-right: 8px;
  font-size: 16px;
  margin-top: -2px;
}

.himalayas-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.himalayas-top-bar-setup {
  display: flex;
  align-items: center;
  margin: 6px 0;
  padding: 0 14px;
}

.himalayas-top-bar-setup i.iconfont.icon-Debug {
  margin-right: 8px;
}

#himalayas-top-bar.hide .himalayas-top-bar-item-hide {
  display: none;
}

#himalayas-top-bar.hide .himalayas-top-bar-icon,
#himalayas-top-bar.hide .top-bar-report-icon {
  margin-right: 0px;
}

#himalayas-top-bar.hide .himalayas-top-bar-result-icon {
  margin-left: 0px;
}

.himalayas-top-bar-result-item {
  margin: 6px 6px 6px 0;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.himalayas-top-bar-result-vertical-line {
  height: 16px;
  background-color: #c7c7c7;
  width: 1px;
  margin-right: 28px;
}

.himalayas-top-bar-item-disabled .himalayas-top-bar-result-title,
.himalayas-top-bar-item-disabled .himalayas-top-bar-result-title:hover {
  color: #797979 !important;
  border: 1px solid #797979;
}

.himalayas-top-bar-result-title {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
}

.himalayas-top-bar-result-title .himalayas-top-bar-result-icon {
  margin-left: 8px;
  font-size: 15px;
}

.himalayas-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.himalayas-top-bar-item-disabled .himalayas-top-bar-result-title,
.himalayas-top-bar-item-disabled .himalayas-top-bar-result-title:hover {
  color: #797979 !important;
  border: 1px solid #797979;
}

/* debug panel */
.himalayas-debug-panel .aurora-debug-download-content .aurora-debug-item-content {
  width: 100%;
}

/* run menu */
.-sim-dropdown {
  padding: 0 0;
}

.himalayas-run-dropdown-checkbox {
  margin-right: 18px;
}

.himalayas-run-menu-content {
 padding-bottom: 0px;
}

.himalayas-run-menu-content .himalayas-run-dropdown-run {
  text-align: center;
  background: #e6f7ff;
  color: #1890ff;
  margin-top: 5px;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
}

.himalayas-run-menu-content .himalayas-run-dropdown-run:hover {
  background: #1890ff;
  color: #ffffff !important;
}

.himalayas-run-menu-content .himalayas-run-dropdown-run-disabled,
.himalayas-run-menu-content .himalayas-run-dropdown-run-disabled:hover {
  background: #eeeeee !important;
  color: #555555 !important;
  cursor: not-allowed;
}

#himalayas-run-dropdown {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  padding: 0
}

/* option panel **/
.himalayas-top-option-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.himalayas-top-option-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.himalayas-top-option-panel-title {
  padding-left: 4px;
  font-weight: 500;
  margin-bottom: 4px;
}

.himalayas-top-option-panel-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.himalayas-top-option-panel-main {
  margin: 20px;
}

.himalayas-top-option-panel-main .ant-divider-horizontal {
  margin: 10px 0px;
}

.himalayas-top-option-panel-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.himalayas-top-option-panel-sub-span {
  padding-left: 20px;
}

.himalayas-top-option-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.himalayas-top-option-panel-content .himalayas-top-option-panel-content-input {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.himalayas-top-option-panel-content-input {
  font-size: 13px;
}

.himalayas-top-option-panel-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.himalayas-top-option-panel-content-input .ant-input-group-addon,
.himalayas-top-option-panel-content-input .ant-input-group-addon .ant-select-selection-selected-value {
  font-size: 13px;
}

.himalayas-top-option-panel-apply {
  text-align: left;
  padding-left: 15px;
}

.himalayas-top-option-panel-apply-title {
  padding-right: 10px;
}
.himalayas-report-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.himalayas-report-panel .panel-modal-header {
  padding-left: 14px !important;
}

.himalayas-report-panel .report-panel-title {
  font-size: 16px;
  font-weight: 500;
}

.himalayas-report-panel .report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  height: 100%;
  min-width: 500px;
}

.himalayas-report-panel .report-content .report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.himalayas-report-panel .report-content .report-select {
  width: calc(100% - 84px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
}

.himalayas-report-select-dropdown {
  z-index: 10000000;
  max-height: 500px;
}

.himalayas-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}
.himalayas-spice-card-template-content {
  padding: 20px 20px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /*  width: 100%; */
}

.himalayas-spice-card-template-download-icon {
  color: #0d76e4;
  cursor: pointer;
  margin-left: 12px;
}

.himalayas-spice-card-file-box {
  float: left;
  padding-left: 20px;
  width: -moz-fit-content;
  width: fit-content;
  color: #003b91;
  line-height: 32px;
}

/* Table */
.himalayas-spice-card-table-content .himalayas-spice-card-table {
  margin-top: 10px;
}

.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .ant-table-thead>tr>th {
  color: #003b91;
}

.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .ant-table-thead>tr>th:last-child {
  border-right: none;
}

.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .aurora-table-body .himalayas-spice-card-split-line-div,
.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .ant-table-thead>tr>.himalayas-spice-card-split-line-div {
  border-right: 2px solid #c0d9f2;
}

.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .aurora-table-body .himalayas-spice-card-table-name-td,
.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .ant-table-thead>tr>.himalayas-spice-card-table-name-td {
  border-right: 1px solid #c0d9f2 !important;
}

.himalayas-spice-card-title-span {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.himalayas-spice-card-table .ant-table-header-column,
.himalayas-spice-card-table .ant-table-column-title {
  width: 100%;
}

.himalayas-spice-card-title-span>span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: max-content;
  max-width: calc(100% - 20px);
  vertical-align: middle;
  display: inline-block;
}

.himalayas-spice-card-fold-icon {
  margin-right: 5px;
  font-size: 10px;
  vertical-align: middle;
}

.himalayas-spice-card-title-span:hover .himalayas-spice-card-fold-icon {
  color: #1890ff;
}

.himalayas-spice-card-fold-icon-transform svg {
  transform: rotate(270deg);
}

.himalayas-spice-card-table .ant-table-small {
  border-radius: 0px 4px 4px 0px;
}

.himalayas-spice-card-table .ant-table-small tbody>tr>td {
  position: relative;
}

.himalayas-spice-card-table .aurora-table-row-disabled {
  background-color: #dcdcdc;
}

.himalayas-spice-card-table .spice-card-name-title {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  padding: 8px;
}

.himalayas-spice-card-table .spice-card-table-td {
  min-height: 22px;
}

.himalayas-spice-card-tabs .ant-tabs-tab-prev-icon,
.himalayas-spice-card-tabs .ant-tabs-tab-next-icon {
  color: #777
}

.himalayas-spice-card-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}
.himalayas-simulation-title .aurora-simulation-holygrail {
  display: flex;
}

.himalayas-monitor-title-box {
  display: flex;
  padding-right: 10px;
}

.himalayas-simulation-title .span-msg-icon-div {
  margin-right: 10px;
  margin-top: 13px;
}

.himalayas-simulation-title .aurora-simulation-progress {
  line-height: 42px
}

.himalayas-simulation-title .aurora-simulation-span {
  line-height: 42px;
}

.himalayas-monitor-title-box .aurora-simulation-span .aurora-span-msg {
  white-space: pre;
}
.himalayas-setup-normal {
  top: 45px;
}
.cascade-setup-normal {
  top: 45px;
}
#cascade-top-bar {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
}

.cascade-top-bar-item {
  margin: 6px 6px 6px 0;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.cascade-not-allow-pcb {
  color: #797979;
}

.cascade-top-bar-result-item {
  margin: 6px 6px 6px 0;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.cascade-top-bar-item:hover {
  background-color: #e0e0e0;
  transition: all .3s;
}

.cascade-top-bar-item .anticon-play-circle,
.cascade-top-bar-item .anticon-loading {
  margin-right: 8px;
  font-size: 16px;
  margin-top: -2px;
}

.cascade-top-bar-item .anticon-file-text {
  margin-right: 8px;
  font-size: 16px;
  margin-top: -2px;
}

.cascade-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.cascade-top-bar-tip .ant-tooltip-inner {
  color: #00aaff;
  background-color: #fff;
}

.cascade-top-bar-tip .ant-tooltip-arrow::before {
  background-color: #fff;
}

.cascade-sus-report-icon {
  margin-right: 8px;
  font-size: 16px;
}

.cascade-confirm-Tips-icon {
  font-size: 22px;
}

.cascade-top-bar-item-disable {
  cursor: not-allowed !important;
  color: #909294 !important;
}

.import-panel-content {
  text-align: center;
}

.cascade-top-bar-setup {
  display: flex;
  align-items: center;
  margin: 6px 0;
  padding: 0 14px;
}

.cascade-top-bar-result-vertical-line {
  height: 16px;
  background-color: #c7c7c7;
  width: 1px;
  margin-right: 28px;
}

.cascade-top-bar-result-vertical-line.mirror-line {
  margin-right: 0px;
  margin-left: 28px;
}

.cascade-top-bar-item-disabled .cascade-top-bar-result-title,
.cascade-top-bar-item-disabled .cascade-top-bar-result-title:hover {
  color: #797979 !important;
  border: 1px solid #797979;
}

.cascade-top-bar-result-title {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
}

.cascade-top-bar-result-title .cascade-top-bar-result-icon {
  margin-left: 8px;
  font-size: 15px;
}

.cascade-top-bar-result-title .cascade-top-bar-result-icon.mirror-icon {
  margin-right: 8px;
  margin-left: 0px;
}

.cascade-top-bar-setup i.iconfont.icon-Debug {
  margin-right: 8px;
}

#cascade-top-bar.hide .cascade-top-bar-item-hide {
  display: none;
}

#cascade-top-bar.hide .top-bar-report-icon {
  margin-right: 0px;
  margin-top: -1px;
}

#cascade-top-bar.hide .cascade-top-bar-item .iconfont,
#cascade-top-bar.hide .cascade-top-bar-item .anticon-play-circle,
#cascade-top-bar.hide .cascade-top-bar-item .anticon-setting,
#cascade-top-bar.hide .cascade-top-bar-item .cascade-download-icon,
#cascade-top-bar.hide .cascade-top-bar-item .cascade-schematics-icon {
  margin-right: 0px;
}

#cascade-top-bar.hide .cascade-top-bar-result-icon {
  margin-left: 0px;
  margin-right: 0px;
}

.cascade-setting-icon {
  margin-right: 8px;
}

.cascade-schematics-icon {
  transform: rotate(90deg);
}

.cascade-download-icon {
  margin-right: 8px;
  margin-top: -2px;
}

.cascade-top-option-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-top-option-panel .panel-body .ant-tabs .ant-tabs-nav {
  padding: 0 20px;
}

.cascade-top-option-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.cascade-top-option-panel-title {
  padding-left: 4px;
  font-weight: 500;
  margin-bottom: 4px;
}

.cascade-top-option-panel-main {
  margin: 20px;
}

.cascade-top-extraction-panel-main {
  min-width: 650px !important;
  min-height: 340px;
}

.cascade-top-opt-panel-main {
  min-width: 267px;
}

.cascade-top-option-panel-main .ant-divider-horizontal {
  margin: 10px 0px;
}

.cascade-top-option-panel-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.cascade-top-option-panel-content-sub-title {
  padding: 0 10px;
  margin-bottom: 6px;
  font-weight: 500;
}

.cascade-top-option-panel-content-title {
  font-weight: 700;
}

.cascade-top-option-panel-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.cascade-top-option-panel-extra-content-body {
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
}

.cascade-top-option-panel-content-pad-body {
  width: 25%;
}

.cascade-top-option-panel-content-longer-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 70%;
  line-height: 28px;
}

.cascade-top-option-panel-content-body-sub {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 16%;
  line-height: 28px;
}

.cascade-top-option-panel-content-sub-switch {
  display: inline-block;
  margin-left: 2%;
}

.cascade-top-config-panel .cascade-top-option-panel-content-body {
  width: 65%
}

.cascade-extraction-panel-sub-span {
  padding-left: 20px;
}

.cascade-extraction-panel-next-sub-span {
  padding-left: 40px;
}

.cascade-extraction-panel-next-next-sub-span {
  padding-left: 60px;
}

.cascade-top-option-panel-content .cascade-option-clipping-input {
  width: calc(45% - 80px) !important;
  margin-left: 20px;
}

.cascade-top-option-panel-content .cascade-option-clipping-input-siwave {
  width: calc(55% - 50px) !important;
  margin-left: 20px;
}

.cascade-top-option-panel-content .cascade-option-advanced-mix-input {
  width: 32% !important;
  margin-left: 20px;
}

.cascade-top-option-panel-content .preview-button,
.cascade-top-option-panel-content .preview-button-focus {
  margin-right: 0px;
}

.cascade-top-option-panel-content .cascade-top-option-panel-content-input {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.cascade-top-option-panel-content .cascade-top-option-panel-content-advanced-input {
  width: 28%;
}

.cascade-top-option-panel-content .cascade-top-option-panel-content-pad-input {
  width: 25%;
}

.cascade-top-option-panel-content .cascade-top-option-panel-content-mesh-input,
.cascade-top-option-panel-content-sub-title .cascade-top-option-panel-content-mesh-input {
  width: 22%;
  margin-left: 17px;
}

.cascade-top-option-panel-content-input {
  font-size: 13px;
}

.cascade-extraction-content {
  margin: 4px 0px;
  margin-left: 10px;
  line-height: 28px;
}

.cascade-top-option-panel-content .cascade-extraction-content-body {
  vertical-align: middle;
  display: inline-block;
  text-align: center;
}

.cascade-sweep-content {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 4px;
}

.cascade-top-option-panel-content .cascade-extraction-input-item {
  width: calc(24% - 26px);
}

.cascade-top-option-panel-content .cascade-extraction-select-item,
.cascade-top-option-panel-content .cascade-extraction-step-width {
  width: calc(14% - 6px);
}

.cascade-extraction-delete-icon {
  margin-left: 18px;
  color: #ccc;
  vertical-align: middle;
}

.cascade-extraction-delete-icon:hover {
  color: #3da4fa;
}

.cascade-extraction-empty {
  color: rgba(0, 0, 0, .25);
  font-size: 14px;
  text-align: center;
  padding: 4px;
}

.cascade-top-option-panel-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.cascade-top-option-panel-content-input .ant-input-group-addon,
.cascade-top-option-panel-content-input .ant-input-group-addon .ant-select-selection-selected-value {
  font-size: 13px;
}

.cascade-top-option-panel-content-input .ant-input-group-addon .ant-select .ant-select-selector {
  padding: 0px;
}

.cascade-top-option-panel-sub-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  text-align: 20px;
  font-style: italic;
}

.cascade-top-option-panel-sub-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.cascade-option-input-addonAfter .ant-input-group-addon {
  width: 65px;
}

.cascade-top-option-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.cascade-load-value-box {
  margin: 10px;
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-load-value-box .load-value-line {
  display: inline-block;
  margin: 5px 0;
}

.cascade-load-value-box .load-value-line .load-title {
  width: 35px;
  display: inline-block;
  text-align: center;
}

.cascade-load-value-box .load-value-line .load-input {
  width: calc(100% - 60px);
  display: inline-block;
}

.cascade-top-bar-right-item {
  position: absolute;
  right: 0;
  display: flex;
}

/* report panel */
.cascade-top-bar-report-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.cascade-top-bar-report-panel .top-bar-report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0 0;
  height: 100%;
  min-width: 500px;
}

.cascade-top-bar-report-panel button.panel-modal-minimize-button {
  right: 34px;
}

.cascade-top-bar-report-panel label.report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 116px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.cascade-top-bar-report-item {
  position: relative;
  padding: 6px 0px;
}

.cascade-top-bar-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.cascade-top-bar-report {
  line-height: 21px;
  height: 21px;
  position: relative;
  z-index: 2;
}

.cascade-report-select-dropdown {
  z-index: 1000000;
}

.cascade-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}

.cascade-report-select-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-list {
  padding-left: 14px;
}

.cascade-report-title-select {
  width: 110px;
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin-right: 6px;
}

.cascade-report-select {
  width: calc(100% - 116px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
}

#cascade-create-dropdown {
  padding: 0;
}

.dcr-dropdown-checkbox {
  margin-right: 18px;
  line-height: 14px;
}

.dcr-dropdown-run {
  text-align: center;
  background: #e6f7ff;
  color: #1890ff !important;
  margin-top: 5px;
  cursor: pointer;
  margin-bottom: 0 !important;
  padding: 5px 12px !important;
  line-height: 22px !important;
  height: unset !important;
}

.dcr-dropdown-run span {
  justify-content: center;
}

.dcr-dropdown-run:hover {
  background: #1890ff !important;
  color: #ffffff !important;
}

.dcr-dropdown-run-disabled,
.dcr-dropdown-run-disabled:hover {
  background: #eeeeee;
  color: #555555 !important;
  cursor: not-allowed;
}

.cascade-root-pcb-content {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.cascade-root-pcb-content .pcb-select {
  margin: 10px 20px;
}

.cascade-root-pcb-content .pcb-select>span {
  display: inline-block;
  width: 80px;
}

.cascade-root-pcb-content .pcb-select>.ant-select {
  width: calc(100% - 90px)
}

.cascade-root-pcb-table-border {
  margin: 10px 20px;
}

.cascade-root-pcb-pin-map-table .ant-table-small>.ant-table-content>.aurora-table-body {
  margin: 0 15px;
}

.cascade-root-pcb-pin-map-table td:empty {
  height: 32px;
}

.cascade-root-pcb-pin-map-table td {
  position: relative;
}

#cascade-root-pcb-dialog,
#cascade-root-pcb-dialog .ant-select-dropdown {
  z-index: 3000
}

.cascade-root-pcb-edit-icon {
  font-size: 16px;
  color: #1ca4f3;
  position: absolute;
  right: 3px;
  top: 6px;
}

.cascade-root-pin-edit-icon {
  right: -13px;
  top: 14px;
  font-size: 12px;
}

.cascade-root-pcb-library-cell {
  position: relative;
}

.cascade-root-pcb-library-value {
  display: block;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  position: relative;
}

.cascade-s-parameter-download-panel .aurora-debug-download-content {
  min-width: 260px;
}

.cascade-s-parameter-download-panel .aurora-debug-download-content .aurora-debug-item-content {
  width: 100%;
}

.cascade-top-option-panel-content .extraction-content-sub-body {
  margin-left: 30px;
  font-style: normal;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: calc(55% - 70px);
}

.cascade-top-option-panel-content .extraction-content-sub-body .cascade-top-option-panel-content-body {
  padding-left: 0px;
  width: 124px;
}

.create-menu-item {
  margin: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 5px 12px !important;
  line-height: 22px !important;
  height: unset !important;
}

#cascade-create-dropdown {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.cascade-top-option-panel-advanced-body {
  color: #8c8c8c;
  cursor: pointer;
}

.cascade-top-option-panel-advanced-body .advanced-arrow-icon {
  font-size: 10px;
  padding-right: 5px;
}


.cascade-top-option-panel-content-core-input.cascade-top-option-panel-content-input {
  width: calc(27.5% - 50px);
}

.cascade-option-input-or-span {
  display: inline-block;
  margin: 0 43px;
}

.cascade-top-option-panel-content-body .span-msg-icon {
  color: #1890ff;
  margin-left: 5px;
}

.cascade-top-option-extraction-apply {
  text-align: left;
}

.cascade-top-option-extraction-apply>label {
  margin: 0px 10px;
  vertical-align: middle;
}

.cascade-top-option-panel-content-body.cascade-extraction-panel-sub-span.treate-pad-span {
  width: 70%;
}

.cascade-top-option-panel-content-input.treate-pad-input {
  width: calc(30% - 52px);
  margin-left: 24px;
}

.cascade-top-option-panel-content-body.power-dc-span {
  width: 55%;
}

.cascade-top-option-panel-content-input.power-dc-input {
  width: 45%
}

.cascade-top-option-panel-content-input.power-dc-max-edge-input {
  width: calc(45% - 52px);
  margin-left: 24px;
}

.adaptive-dc-span {
  margin-left: 20px;
}

.cascade-top-option-panel-content-input.power-dc-input.adaptive-dc-input {
  width: calc(45% - 223px);
}

.cascade-top-bar-dropdown-menu {
  padding: 0px;
  padding-top: 4px;
}

.cascade-top-bar-dropdown-menu .create-menu-item>span {
  cursor: text;
}

.cascade-show-result-checkbox {
  margin-right: 8px;
  height: 31px;
}

.cascade-tree-plot-net-color {
  vertical-align: middle;
}

.cascade-tree-plot-net-color>input {
  height: 24px;
}

.cascade-extraction-panel-note-span {
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  opacity: 0.8;
}

.cascade-question-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: text-bottom;
}

.cascade-extraction-panel-tabs>.ant-tabs-nav {
  margin-bottom: 0px;
}

.cascade-extraction-panel-radio-group {
  width: 100%
}
.cascade-sign-off-template-content {
  padding: 20px 20px;
  min-width: 1610px;
  width: -moz-fit-content;
  width: fit-content;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body>table>.ant-table-thead>tr>th {
  color: #003b91;
}

.cascade-sign-off-template-table .cascade-sign-off-table-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: block;
  margin-left: 3px;
}

.cascade-sign-off-template-table .pre-layout-fold-icon {
  margin-top: 0px;
}

.cascade-sign-off-table-title-span {
  display: inline-block;
  cursor: pointer;
}

.cascade-sign-off-template-table .cascade-components-table tbody>tr>td .cascade-sign-off-impedance-value-span {
  display: inline-block;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-column-title {
  padding-left: 0px;
}

.cascade-sign-off-soc-span {
  color: #0d76e4;
  font-size: 12px;
}

.cascade-sign-off-pmic-span {
  color: #c98301;
  font-size: 12px;
}

.cascade-sign-off-file-box {
  float: left;
  padding-left: 20px;
  width: -moz-fit-content;
  width: fit-content;
  color: #003b91;
  line-height: 32px;
}

.cascade-sign-off-top-title {
  display: flex;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table table {
  border-collapse: collapse;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body .cascade-sign-off-pmic-comp-div {
  border-bottom: 1px solid #a8c4e6;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body .cascade-sign-off-pmic-comp-div>td,
.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body tr>.cascade-sign-off-pmic-comp-div-td {
  border-bottom: none;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body tr>td.cascade-sign-off-table-ground-pin-div,
.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body>table>.ant-table-thead>tr>.cascade-sign-off-table-ground-pin-title {
  border-right: 2px solid #d4d4d4;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .aurora-table-body>table>.ant-table-thead>tr th {
  text-align: center;
}

.cascade-template-add-icon {
  color: #0d76e4;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 50%;
  margin-top: -7px;
}

.cascade-sign-off-template-download-icon {
  color: #0d76e4;
  cursor: pointer;
  margin-left: 12px;
}

.cascade-sign-off-template-table .cascade-component-model-span {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cascade-top-template-option-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-top-template-option-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.cascade-top-template-option-panel-main {
  margin: 15px 20px;
}

.template-title-item-main {
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0px;
}

.template-title-item {
  line-height: 28px;
  font-size: 14px;
  width: 300px;
  display: inline-block;
  margin: 0px 20px 0px 0px;
}

.template-rl-spec-span {
  width: 100px;
}

.template-rl-spec-input {
  width: calc(100% - 120px)
}

.template-title-item-input {
  width: 200px;
}

.template-title-item-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.template-title-item-sub-span {
  padding-left: 20px;
}

.template-radio-item,
.template-option-item {
  margin: 5px 0px;
  margin-left: 20px;
}

.cascade-template-sparameter-download-icon {
  color: #1890ff;
  cursor: pointer;
}

.cascade-template-sparameter-download-td {
  text-align: center;
}

.cascade-template-add-button {
  width: 166px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
  margin: 2px 0px;
}

.cascade-template-add-PMIC-button {
  width: 200px;
  margin-left: 10px;
}

.cascade-template-add-button-row {
  margin-bottom: 10px;
}

.cascade-template-add-button-flex {
  display: flex;
  justify-content: space-between;
  width: 376px;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content .template-power-delete {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -6px;
  color: #ccc;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table-small .ant-table-content>.aurora-table-body .ant-table-row td:hover .template-power-delete {
  color: #1890ff;
}

.cascade-sign-off-template-table .cascade-components-table .ant-table>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td:first-child {
  padding-right: 22px;
  padding-left: 22px;
}

.cascade-sign-off-template-table .cascade-components-table .template-power-net-td {
  min-width: 180px;
  position: relative;
}

.template-impedance-add-icon {
  color: #1890ff;
  cursor: pointer;
  margin-left: 10px;
}

.impedance-freq-popover {
  min-width: 200px;
  max-width: 600px;
}

.impedance-freq-popover .ant-popover-inner-content {
  padding: 8px;
}

.impedance-freq-button-content {
  position: relative;
  width: 100%;
  height: 32px;
}

.template-coupling-result-popover .ant-popover-inner-content {
  padding: 8px;
}

.impedance-freq-popover .impedance-spec-rl-checkbox {
  margin-top: 8px;
}

.impedance-freq-popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}

.impedance-freq-tagsInput {
  width: calc(100% - 46px);
}

.impedance-freq-button {
  padding: 0px 10px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  position: absolute;
  right: 10px;
  bottom: 8px;
  top: 8px;
}

.template-impedance-delete-freq-icon {
  margin-left: 6px;
  cursor: pointer;
  color: #1890ff;
}

.impedance-rl-result-failed {
  color: #e51212;
  display: block;
  font-size: 18px;
}

.impedance-rl-result-passed {
  color: #03d703;
  display: block;
  font-size: 18px;
}

.template-coupling-descriptions {
  max-width: 300px;
}

.template-coupling-descriptions .ant-descriptions-row .ant-descriptions-item {
  display: block;
}

.template-coupling-descriptions .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.template-coupling-descriptions .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-label {
  vertical-align: middle;
}

.template-coupling-descriptions .ant-descriptions-row .template-coupling-descriptions-power .ant-descriptions-item-content {
  width: calc(100% - 90px);
}

.template-coupling-descriptions .ant-descriptions-row .template-coupling-descriptions-gnd .ant-descriptions-item-content {
  width: calc(100% - 98px);
}

/* .template-coupling-result-info-icon {
  color: #1890ff;
  cursor: pointer;
} */

.template-imp-target-radio {
  display: block;
  margin: 0 8px;
  height: 20px;
  margin-bottom: 8px;
  margin-left: 0px;
}

.cascade-top-template-option-item {
  margin-bottom: 6px;
}

.cascade-template-pdf-choose-content {
  min-height: 400px;
  padding: 5px;
}

.cascade-template-pdf-choose-table tbody>tr>td>span {
  display: block;
  max-height: 100px;
  overflow: auto
}

.cascade-signoff-stick-row {
  position: sticky;
  left: 20px;
  z-index: 200;
}
.file-content-panel {
  box-sizing: content-box;
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.file-content-panel .panel-body {
  position: relative;
  padding: 16px !important;
}

.file-content-panel .dividingLine {
  top: 0px;
}

.file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}

.file-content-display-body {
  float: left;
  padding-left: 20px;
}

.hide-show-netlist {
  position: absolute;
  left: 6px;
  top: 18px;
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #ffffff 0px 0px 0px 0px;
  background: #ffffff;
  z-index: 1;
  border: 1px solid#dedede;
  justify-content: center;
}

.hide-show-plot {
  position: absolute;
  left: 6px;
  top: 48px;
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #ffffff 0px 0px 0px 0px;
  background: #ffffff;
  z-index: 1;
  border: 1px solid#dedede;
  justify-content: center;
}

.hide-show-netlist:hover,
.hide-show-plot:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
  background: #fafafa;
}

.cpm-graph-right,
.cpm-graph-right-100 {
  float: right;
  height: 100%;
  width: 76%;
  position: relative;
}

.cpm-graph-right-100 {
  width: 100%;
}

.cpm-file-svg {
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  right: 34%;
}

.cpm-graph-list {
  float: right;
  width: 34%;
  height: 100%;
  background: #f9f9f9;
  position: relative;
  z-index: 10;
  border-left: 1px solid #e2dfdf;
}

.cpm-graph-right-content {
  height: 100%;
}

.cpm-graph-option-list {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.cpm-radio-group {
  display: block;
  margin: 0 14px;
  height: 20px;
  margin-top: 16px;
}

.cpm-radio-item {
  float: left;
  line-height: 20px;
  width: 30%;
  margin: 0 !important;
}

.cpm-radio-item-current {
  width: 40%;
}

.cpm-graph-option-name {
  transition: all 0.3s;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
  line-height: 24px;
}

.cpm-graph-option-name .ant-checkbox-group {
  width: 100%;
}

.cpm-graph-option-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.cpm-graph-checkbox-group>.ant-checkbox {
  top: 0px;
  left: 4px;
}

.cpm-graph-checkbox-group-name {
  display: inline-block;
  line-height: 20px;
}

.cpm-graph-checkbox-group .ant-checkbox+span {
  height: 26px;
  display: inline-block;
  padding-left: 6px;
  padding-right: 4px;
}

.cpm-graph-checkbox-group {
  width: 100%;
}

.cpm-graph-option-list .cpm-option-name {
  display: inline-block;
  max-width: 160px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.cpm-graph-curve-current {
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
  font-weight: 400;
}

.cpm-gragh-checkbox {
  display: block;
  margin: 10px;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cpm-graph-option-content {
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/* .cpm-graph-list .decap-subckt-name {
  display: inline-block;
  max-width: 160px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
} */
.cascade-sider-menu-tree .ant-tree-title .aurora-project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
  line-height: 24px;
  height: 22px;
}


.cascade-tooltip-failed {
  color: red;
}

.cascade-tooltip-warning {
  color: #fa8c16;
}

.cascade-default-tree-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.ant-tree-title:hover .cascade-default-tree-title {
  width: 80%;
}

.cascade-tree-pcb-name,
.cascade-tree-channel-name,
.cascade-tree-sign-off-title-name {
  display: inline-block;
  width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-tree-title:hover .cascade-tree-pcb-name {
  width: calc(100% - 60px);
}

.ant-tree-title:hover .cascade-tree-channel-name {
  width: calc(100% - 90px);
}

.cascade-tree-sign-off-name {
  width: calc(100% - 26px);
}

.ant-tree-title:hover .cascade-tree-sign-off-running-name {
  width: calc(100% - 66px) !important;
}

.ant-tree-title:hover .cascade-library-title {
  width: 75%
}

.cascade-library-default-decap-title {
  width: 70%;
}

.ant-tree-title:hover .cascade-library-default-decap-title {
  width: 75%
}

.cascade-rename-icon {
  margin-right: 22px !important;
}

.cascade-sign-off-download-icon {
  margin-right: 22px;
}

.cascade-rename-icon-hasCopy {
  margin-right: 44px !important;
}

.cascade-copy-icon {
  right: 12px !important;
}

.cascade-copy-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.cascade-copy-name {
  width: 283px;
}

.cascade-pcb-setting-icon {
  margin-top: -2px;
}

.cascade-sider-menu-tree .ant-tree-title .cascade-pcb-setting-icon {
  position: absolute;
  right: 32px;
  visibility: hidden;
}

.cascade-sider-menu-tree .ant-tree-title:hover .cascade-pcb-setting-icon {
  visibility: visible;
}

.cascade-tree-icon-location-0 {
  margin-right: 0px;
}

.cascade-tree-icon-location-1 {
  margin-right: 20px;
}

#cascade-pin-map-dialog .ant-select-dropdown {
  z-index: 2001 !important;
}

.cascade-pin-map-content {
  margin-top: 10px
}

.cascade-pin-map-add-row-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}


.cascade-pin-map-content-table .ant-table-small>.ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 28px;
  position: relative;
}


.cascade-pin-map-content-delete-icon {
  position: absolute;
  right: -30px;
  margin-top: -5px;
  top: 48%;
  color: #ccc;
  cursor: pointer;
}

.cascade-pin-map-content-delete-icon:hover {
  color: #3da4fa;
}

.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-icon,
.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-icon {
  position: absolute;
  right: 21px;
}

.cascade-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-icon {
  right: 48px;
}

.cascade-copy-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.cascade-sider-menu-tree .ant-tree-title .aurora-tree-layout-check-loading-icon {
  right: 14px;
  visibility: visible;
}

.cascade-sider-menu-tree .ant-tree-title:hover .aurora-tree-layout-check-loading-icon {
  right: 52px;
}

.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.cascade-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}


.cascade-tree-tag {
  position: absolute;
  top: 1px;
  right: 4px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
}

.cascade-sider-menu-tree .ant-tree-title:hover .cascade-tree-tag {
  display: none;
}

.cascade-sider-menu-tree .my-tree .my-tree-switch-icon {
  margin-top: -5px;
}
.cascade-connection-content {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  overflow: auto;
  align-items: center;
  position: relative;
  align-items: flex-start;
}

.cascade-connection-content .plot {
  padding: 5px;
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 7px;
}

.cascade-connection-column {
  min-width: 300px;
  margin: 20px;
  min-height: 94%;
  border: 1px solid #a8c4e6;
  border-radius: 4px;
  flex-shrink: 0;
  z-index: 2001;
}

.cascade-connection-column .delete-icon:hover {
  color: #1890ff
}

.cascade-connection-column .connector-setting {
  height: 30px;
  position: relative;
}

.cascade-connection-column .connector-setting .add-pcb-button {
  padding-top: 3px
}

.cascade-connection-column .connector-setting .delete-icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.cascade-connector-setup-pcb {
  margin: 0px 10px 10px 10px;
  padding: 5px 5px 10px 5px;
  background: #eaeaea;
  border-radius: 4px;
  position: relative;
}

.cascade-connector-setup-pcb .pcb-select {
  margin-top: 10px;
  position: relative;
}

.cascade-connector-setup-pcb .pcb-select>span:not(.anticon) {
  width: 100px;
  padding-left: 10px;
  display: inline-block;
}

.cascade-connector-setup-pcb .pcb-select .ant-select {
  width: calc(100% - 120px)
}

.cascade-connector-setup-pcb .pcb-select .delete-icon {
  position: absolute;
  right: 0px;
  top: -3px;
}

.cascade-connector-setup-pcb .connector-select .delete-icon {
  position: absolute;
  right: 1px;
  top: 6px;
  font-size: 12px;
}

.cascade-select-dropdown-list {
  z-index: 2000;
}

.cascade-connection-empty-column {
  text-align: center;
  background: #fafafa;
  border: 1px dashed #a8c4e6;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  height: 94%
}

.cascade-connection-empty-column>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cascade-connection-empty-column>div .plus-icon {
  font-size: 48px;
  color: #a8c4e6;
}

.cascade-connector-setup-pcb .connector-select .connection-button,
.cascade-net-connection-column-content-net .connection-net-button {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 3px solid #1890ff;
  border-radius: 50%;
  top: 7px;
  cursor: pointer;
}

.cascade-connector-setup-pcb .connector-select .prev-button {
  left: -15px;
}

.cascade-connector-setup-pcb .connector-select .next-button {
  right: -15px;
}

.cascade-net-connection-column-content-net .prev-button {
  left: -17px;
  top: 5px
}

.cascade-net-connection-column-content-net .next-button {
  right: -17px;
  top: 5px
}

.cascade-connect-draw-line-status {
  z-index: 2002 !important;
}

.cascade-connector-line:hover {
  stroke: red;
  cursor: pointer;
}

.cascade-nets-select {
  stroke: red;
}

.cascade-net-connection-content {
  justify-content: space-between;
  align-items: baseline;
}

#cascade-net-connection-content {
  overflow: hidden;
}

.cascade-net-connection-column {
  width: 40%;
  min-height: 92%;
}

.cascade-net-connection-column-content {
  text-align: center;
  padding: 5px;
  position: relative;
}

.cascade-net-connection-column-content-comp {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.cascade-net-connection-column-content-net {
  font-size: 12px;
  position: relative;
}

.cascade-net-connection-column-net-del {
  font-size: 8px;
  position: absolute;
  top: 4px;
  cursor: pointer;
  right: 0px;
}

.cascade-net-connection-delete {
  position: absolute;
  left: 44%;
  top: 40px;
  font-size: 12px;
  cursor: pointer;
  z-index: 2002;
}

.cascade-net-connection-delete:hover {
  color: #1890ff;
}

.cascade-net-connection-column-content-net .dashed {
  border-top: 1px dashed #000000;
  position: absolute;
  margin-top: 8px;
}

.cascade-schematic-content {
  width: calc(100% - 22px);
  height: calc(100% - 22px);
  border: 1px solid #a8c4e6;
  margin: 10px;
  border-radius: 4px;
  overflow: hidden;
}

.cascade-schematic-setting-icon {
  padding-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
}

.cascade-schematic-advanced {
  padding: 15px;
  width: 100%;
  height: 100%;
}

.cascade-schematic-advanced .advanced-item {
  width: 100%;
  padding: 0 5px;
  margin-bottom: 14px;
}

.cascade-schematic-advanced .advanced-item .advanced-title {
  width: 140px;
  display: inline-block;
}

.cascade-schematic-advanced .advanced-item .advanced-content {
  width: calc(100% - 150px);
  display: inline-block;
}

.cascade-schematic-advanced .advanced-item .advanced-content .font-button {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.cascade-schematic-advanced .advanced-item .advanced-content .schematic-download-button {
  height: 24px;
}

.cascade-schematic-loading {
  top: 40%
}

.cascade-additional-nets-radio {
  display: inline-block;
  margin-left: 20px;
}

.cascade-additional-nets-select {
  max-width: calc(100% - 440px);
  min-width: 300px;
}

.cascade-connection-nets-setting {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.cascade-connection-nets-setting:hover {
  color: #1890ff;
}

.cascade-connection-setting-option {
  margin: 20px;
}

.cascade-connection-setting-option-body {
  display: inline-block;
  width: 150px;
}

.cascade-connection-setting-option-select {
  width: calc(100% - 160px);
}

.cascade-connection-info-circle {
  color: #1890ff;
  padding-left: 5px;
}

.cascade-net-connection-column-content-input {
  padding: 10px;
}

.cascade-net-connection-column-content-auto-match-icon {
  vertical-align: middle;
}

.cascade-net-connection-column-content-auto-match-icon:hover {
  color: #1890ff;
}

.port-setup-tabs {
  width: 50%;
}

.port-setup-tabs-content {
  margin-left: 10px;
  margin-bottom: -15px;
  margin-top: 10px;
}

.cascade-rl-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.cascade-rl-model-title {
  font-size: 14px;
  font-weight: 500;
}

.cascade-rl-model-panel .cascade-rl-model-content {
  margin: 10px;
  overflow: hidden;
  font-size: 13px;
}

.cascade-rl-model-selection {
  width: 100%;
}

.cascade-rl-model-row .cascade-rl-model-selection .ant-select-selector,
.cascade-rl-model-row .cascade-rl-model-selection,
.cascade-rl-model-row .cascade-rl-model-selection .ant-select-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.cascade-rl-model-row .cascade-rl-model-selection .ant-select-selection--multiple {
  min-height: 24px;
}

.cascade-rl-model-row .ant-select-selection--multiple .ant-select-selection__choice__content {
  font-size: 12px;
}

.cascade-rl-model-row .cascade-rl-model-selection .ant-select-selection--multiple .ant-select-selector>ul>.ant-select-search--inline {
  margin-top: 0px;
  font-size: 14px;
}

.cascade-rl-model-select-dropdown {
  z-index: 10000000;
}

.sierra-package-model-item-select {
  width: 50%;
}

.cascade-rl-model-panel .ant-input {
  height: 24px;
}

.cascade-rl-model-row {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-weight: 500;
}

.cascade-rl-model-row>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
}

.cascade-rl-model-row>*:last-child {
  width: calc(100% - 100px);
}

.cascade-rl-sweep-create {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 0 10px;
  margin-top: 10px;
  justify-content: flex-end;
}

.cascade-rl-value-tagsInput {
  width: 100%;
}

.cascade-rl-sweep-create-btn {
  height: 28px;
}
.top-sus-wire-bond-panel .top-sus-wire-bond-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  height: 100%;
}

.top-sus-wire-bond-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.top-sus-wire-bond-title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.top-sus-wire-bond-item>span {
  width: 70px;
}

.top-sus-wire-bond-item>.aurora-select {
  width: calc(100% - 70px);
}

.wire-bond-geometry>span {
  display: inline-block;
}

.wire-bond-geometry>span:nth-child(1) {
  margin-right: 10px;
}

.wire-bond-img {
  width: 100%;
}

.wire-bond-help-icon {
  position: absolute;
  right: 10px;
  top: 2px;
  color: #1890ff;
  cursor: pointer;
}

.top-sus-wire-bond-help-content {
  padding: 0px;
  border-radius: 6px 6px 0px 0px;
  height: -moz-fit-content;
  height: fit-content;
}

.top-sus-wire-bond-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 28px;
  line-height: 28px;
  margin: 8px 0px;
}
.cascade-tree-list-container {
  position: relative;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, .05),
    0 4px 10px 0 rgba(0, 0, 0, .08),
    0 6px 20px 4px rgba(0, 0, 0, .05);
}

.cascade-tree-list-container .tree-list-phantom {
  width: 100%;
  background: #fff;
}

.cascade-tree-list-container .tree-list-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding-top: 6px;
}

.cascade-tree-list-container .tree-list-view .tree-list-content {
  padding: 0 6px 6px 6px;
}

.cascade-tree-list-container.tree-list-view .tree-list-content .ant-checkbox-wrapper {
  padding-left: 4px;
}

.cascade-tree-list-container .tree-list-view .tree-list-content .tree-list-content-net {
  padding-left: 6px;
  cursor: pointer;
}

.cascade-tree-list-container .tree-list-view .tree-list-dropdown {
  margin: 0;
}

.cascade-tree-list-container .tree-list-view .tree-list-dropdown .tree-list-content {
  padding-top: 0;
}
.cascade-DCR-content {
  padding: 20px;
  min-width: 1350px;
}

.resistance-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.dcr-point-select-table-panel {
  overflow: unset !important;
  margin-top: 100px;
}

.dcr-point-select-table-panel .panel-ant-modal-body.panel-body {
  overflow: unset !important
}

.point-panel-content {
  padding: 20px;
}

.cascade-resistance-tables .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .resistance-delete-icon {
  color: #1890ff;
}

.cascade-resistance-tables .ant-table-small>.ant-table-content>.aurora-table-body .path-r-index-td {
  margin-right: 20px;
}

.cascade-resistance-tables .input-with-select .ant-select .ant-select-selector .ant-select-selection-item {
  padding-inline-end: 18px
}

.path-r-index-content {
  position: relative;
  margin-right: 18px;
}

.resistance-delete-icon {
  position: absolute;
  top: 50%;
  right: -20px;
  margin-top: -8px;
  color: #ccc;
}

.dcr-select-dropdown-menu {
  z-index: 1000000000;
}

.dcr-point-table .aurora-table-body table {
  table-layout: fixed;
  width: 100%;
}

.point-pin-span {
  color: #1890ff;
}

.resistance-table-error-span {
  color: red;
}

.resistance-table-error-span .point-pin-span {
  color: red;
}

.cascade-resistance-tables .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap,
.dcr-point-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
  word-break: break-all;
}

.resistance-add-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.resistance-table-disable-span {
  cursor: not-allowed;
}

.path-r-point-net-selection-content {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.path-r-point-net-selection-content>span {
  height: 32px;
  line-height: 32px;
}

.path-r-point-net-selection.ant-select {
  line-height: 28px;
  width: calc(100% - 40px);
  height: auto;
}

.path-r-point-net-selection-dropdown {
  z-index: 1000000;
}

.tree-list-content-pin-copy-icon {
  color: #1890ff;
  margin-left: 10px;
  visibility: hidden;
}

.tree-list-content:hover .tree-list-content-pin-copy-icon,
.tree-list-content-net:hover .tree-list-content-pin-copy-icon {
  visibility: visible;
}

.path-r-index-checkbox {
  margin-right: 8px;
}

.cascade-resistance-tables .ant-table-small>.ant-table-content .aurora-table-body .path-r-row-dividing-line>td,
.cascade-resistance-tables .ant-table-small>.ant-table-content .aurora-table-body>tr>.path-r-row-dividing-line-td {
  /* border-bottom: 1px solid #a8c4e6; */
  border-bottom: 2px solid #bedbff;
}

.cascade-resistance-tables .path-r-pins-main {
  height: auto;
  max-height: 88px;
  overflow: auto;
}

.cascade-resistance-tables .path-r-pins-main:empty {
  height: 28px;
}

.cascade-dcr-point-add-button {
  width: 130px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
  margin-top: 10px;
}

.dcr-point-table .ant-table-small>.ant-table-content>.aurora-table-body {
  padding-right: 14px;
}

.dcr-point-table tbody>tr>td:last-child {
  position: relative;
}

.dcr-point-delete-icon {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.dcr-point-table .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .dcr-point-delete-icon {
  color: #1890ff;
}

.dcr-warning-color {
  color: orange;
}
.cpm-connection-edit-main {
  margin: 12px 0px;
  padding: 0px 14px;
  width: 100%;
  height: 100%;
}

.cpm-connection-canvas-content {
  height: 60%;
}

.cpm-connection-edit-table-content {
  padding-top: 10px;
  height: 40%;
}

.cpm-connection-edit-title {
  font-size: 16px;
  font-weight: 500;
}

.cpm-connection-edit-table {
  height: 100%;
}

/* canvas */
.cpm-setup-canvas {
  height: 100%;
  padding-bottom: 0px;
}

.cpm-setup-canvas-pin-name {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  width: -moz-fit-content;
  width: fit-content;
  bottom: 0px;
  right: 0px;
}

.cpm-setup-canvas-pin-title {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

.cpm-setup-canvas-pin-switch {
  margin-left: 10px;
  margin-top: -5px;
}

.cpm-setup-canvas-pin-name .unshow-switch {
  background-color: #999999;
}

.cpm-setup-canvas-node-radius-main {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  background-color: transparent;
  width: -moz-fit-content;
  width: fit-content;
  bottom: 0px;
  right: 140px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cpm-setup-canvas-node-radius-main>.cpm-setup-canvas-pin-title {
  font-weight: 500;
  width: 86px;
}

.cpm-setup-canvas-node-radius-main>.aurora-input {
  width: 110px;
}

.cpm-setup-canvas-node-radius-main .ant-input-group-addon {
  background-color: #ffffff;
}

#cpm-setup-design-canvas polygon:hover,
#cpm-setup-model-canvas polygon:hover,
#cpm-setup-design-canvas circle:hover,
#cpm-setup-model-canvas circle:hover,
#cpm-setup-design-canvas path:hover,
#cpm-setup-model-canvas path:hover,
#cpm-setup-design-canvas polyline:hover,
#cpm-setup-model-canvas polyline:hover {
  stroke: unset
}

#cpm-setup-design-canvas .lay_geom:hover,
#cpm-setup-model-canvas .lay_geom:hover {
  stroke: yellow;
}

#cpm-setup-canvas-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

#cpm-setup-design-canvas-main,
#cpm-setup-model-canvas-main {
  width: calc(50% - 1px);
  height: 100%;
  position: relative;
  /*  background-color: #000000; */
}

.node-layout-canvas-coord {
  position: absolute;
  left: 5px;
  bottom: 10px;
  color: #fff;
  float: right;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.node-canvas-btn-group-vertical {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 1;
}

.node-canvas-layBtn {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 1px 0;
  width: 100%;
  margin: 8px 4px;
  display: block;
  position: relative;
  font-size: 22px;
  color: #aaa;
}

.node-canvas-span-layBtn {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 1px 0;
  width: 100%;
  margin: 8px 4px;
  display: block;
  position: relative;
  color: #aaa;
}

.node-canvas-layBtn:hover,
.node-canvas-span-layBtn:hover {
  background-color: #dddddd;
  color: #333;
  cursor: pointer;
}

.node-canvas-span-layBtn .iconfont {
  font-size: 26px;
}

.cpm-connection-edit-table-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}

.cpm-connection-edit-table-delete-icon {
  color: #1890ff;
  margin-left: 10px;
}

/* #cpm-setup-canvas-main .cpm-edit-tri-mapping-panel .panel-modal-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
 */
#cpm-setup-canvas-main .cpm-edit-tri-mapping-panel {
  z-index: 10000 !important;
  top: 55px;
}

#cpm-setup-canvas-main .panel-modal-header {
  padding: 6px 10px !important;
}

#cpm-setup-canvas-main .panel-modal-close-x {
  width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
}

.cpm-edit-tri-mapping-table {
  margin: 6px 10px;
}

.cpm-tri-mapping-add-icon {
  color: #1890ff;
  /*  float: right; */
  margin-left: 10px;
}

.cpm-connection-match-button-content {
  width: 100%;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 10px;
}

.cpm-connection-match-button {
  height: 24px;
  font-size: 14px;
  color: #000000d9;
}

/* .aurora-select-dropdown {
  z-index: 100000000;
} */

.cpm-edit-tri-mapping-panel-clean-icon {
  color: #1890ff;
  float: right;
  margin-right: 0px;
  margin-top: 3px;
}

.cpm-edit-tri-mapping-panel-eye-icon {
  color: #1890ff;
  margin-left: 8px;
  margin-top: 3px;
}

.cpm-edit-tri-mapping-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th,
.cpm-edit-tri-mapping-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  padding: 6px 6px;
}

.cpm-connection-spice-download-content {
  margin: 6px 10px;
  margin-bottom: 0px;
}

.cpm-connection-spice-download-item>.cpm-connection-spice-download-label {
  width: 100px;
  display: block;
  height: 28px;
  line-height: 28px;
}

.cpm-connection-spice-download-item>.aurora-input {
  width: calc(100% - 100px);
}

.cpm-connection-spice-download-item>.aurora-select {
  height: auto;
  width: calc(100% - 100px);
  min-height: 28px;
  max-height: 120px;
}

.cpm-connection-spice-download-item>.aurora-select .ant-select-selector {
  max-height: 120px;
  overflow: auto;
}

.cpm-connection-spice-download-content .cpm-connection-spice-download-item {
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cpm-connection-spice-download-item>div {
  width: calc(100% - 100px);
  height: 28px;
  line-height: 28px;
}

.cpm-connection-edit-main .aurora-success-msg,
.cpm-connection-edit-main .aurora-model-name-error-msg {
  margin-top: 4px;
}

.cpm-connection-spice-download-select-dropdown {
  max-height: 400px !important;
}

.node-canvas-layBtn .icon-sanjiaoxing3 {
  font-size: 22px;
  font-weight: 600;
}

.match-error-log-panel {
  top: 200px;
  left: calc(50% - 400px) !important;
}

.match-error-log-content {
  height: 100%;
  padding: 5px 10px;
  /* position: relative; */
}

.match-error-log-content .match-error-log-box {
  overflow: auto;
  height: calc(100% - 85px);
  background-color: #f2f2f2;
  padding: 5px;
  width: 100%;
  margin-top: 10px;
}

.match-error-log-button-content {
  margin-top: 8px;
  text-align: center;
}

.match-error-log-button-content .match-error-log-button {
  height: 28px;
  padding: 0 10px;
}

.cpm-connection-edit-table-content .cpm-connection-edit-table .ant-table-content .ant-table-hide-scrollbar {
  min-width: 0 !important;
}

.cpm-connection-edit-table-content .cpm-connection-edit-table .ant-table-content .ant-table-hide-scrollbar>table {
  /* height: 40px; */
}

.cpm-connection-auto-match-pair-button {
  display: inline-block;
  margin: 5px 10px;
  color: #1890ff;
  cursor: pointer;
}
.csm-content-item {
    display: inline-block;
    width: calc(50% + 60px);
    margin: 3px 0px;
}

.csm-content-item>span {
    width: 120px;
    display: inline-block;
    line-height: 28px;
}

.csm-content-item .aurora-select {
    width: calc(100% - 120px);
    height: 28px;
}

.aurora-select.cpm-select-disable .ant-select-selection.ant-select-selection--single {
    color: #f30518;
    position: relative;
}

.cpm-select-disable .ant-select-selection .ant-select-arrow .aurora-file-check-icon {
    top: 6px;
}

.ant-select-selection.ant-select-selection--single:hover .ant-select-arrow .aurora-file-check-icon {
    right: 13px;
}

.csm-content-subckt-item {
    display: inline-block;
    width: calc(50% - 65px);
    margin-left: 5px;
}

.csm-content-subckt-item .aurora-select {
    width: calc(100%);
    height: 28px;
}

.csm-cpm-content-item {
    width: 100%;
    height: 28px;
    line-height: 28px;
    margin: 6px 0px;
}

.csm-cpm-content-item-flex {
    display: flex;
    justify-content: space-between;
}

.csm-cpm-content-item-flex>.aurora-select {
    width: calc(100% - 100px);
}

.csm-cpm-content .csm-cpm-content-item-flex>span {
    width: 100px;
}

.csm-cpm-content {
    margin: 10px 16px;
}

.csm-cpm-content-item>span {
    display: inline-block;
    width: 146px;
}

.csm-cpm-content-icon {
    cursor: pointer;
    color: #1890ff;
    margin-left: 10px;
}

.csm-connection-edit-main {
    padding: 12px 14px;
}

.csm-connection-edit-title {
    font-size: 16px;
    font-weight: 500;
}
.cascade-ir-content {
  padding: 20px;
  min-width: 1350px;
}

.cascad-content-box {
  border: 1px solid #a8c4e6;
  margin-top: 14px;
  padding: 10px;
  border-radius: 3px;
}

.ir-explorer-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.ir-explorer-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.ir-component-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 20px;
}

.ir-component-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.ir-load-title .ir-add-load {
  margin-left: 6px;
  color: #3da4fa;
  cursor: pointer;
  font-size: 15px;
  vertical-align: middle;
}

.ir-load-title:hover .ir-delete-load {
  color: #3da4fa;
}

.ir-load-title .ir-delete-load {
  float: right;
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}

.cascade-ir-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

span.extend-net-select-color {
  color: #a6a6a6;
}

.ir-table-Delete {
  position: relative;
  overflow: unset;
}

.ir-load-select {
  margin-left: 10px;
}

.cascade-ir-table .ant-table-small>.ant-table-content>.aurora-table-body {
  margin-right: 20px;
  margin-left: 20px;
}

.cascade-ir-table tr.ant-table-row.editable-row.ant-table-row-level-0 {
  position: relative;
}

.ir-load-title {
  min-width: 131px;
  display: inline;
}

.ir-delete-icon {
  position: absolute;
  color: #ccc;
  right: -36px;
  top: 50%;
  margin-top: -6px;
}

.ir-delete-icon:hover {
  color: #3da4fa;
}

.ir-load-title .ir-add-error-load {
  color: #b7babd;
}

.editable-cell-value-wrap.ir-maxBgaDv-tr {
  position: relative;
  overflow: unset;
}

.IR-constraints-panel {
  overflow: unset !important;
  margin-top: 100px;
}

.constraints-panel-content {
  padding: 10px 14px;
  overflow: auto;
  font-size: 13px;
  height: 100%;
}

.content-input {
  width: 65%;
  margin-left: 15px;
}

.content-dialog {
  margin: 10px 0;
}

.content-dialog-title {
  width: 130px;
  display: inline-block;
}

.cascade-components-ignore-name {
  color: #e65b5b;
}

.cascade-components-unused-name {
  color: #e65b5b;
}

.cascade-ir-detail-icon {
  position: absolute;
  left: -30px;
  top: calc(50% - 15px);
  line-height: unset;
}

.ir-table-power {
  position: relative;
}

.cascade-ir-table .ant-table-small .ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 30px;
  position: relative;
}

.ir-empty-vrm-delete-icon {
  right: -35px;
  top: 6px;
  position: absolute;
  color: #ccc;
}
.cascade-result-IR {
  height: 100%;
  position: 'relative'
}

.IR-Result {
  height: 100%;
}

.IR-Result-Menu {
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 300;
}

.ir-table-content {
  padding: 15px 20px;
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.I-select-box {
  display: inline-block;
}

.result-filter-divider {
  margin: 2px 0;
}

.result-filter-checkout-box {
  padding: 5px 12px;
}

.result-filter-box-footer {
  padding: 5px 16px;
}

.ir-table-content th.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters.ant-table-row-cell-break-word {
  position: relative;
}

.ir-table-content .ant-spin-nested-loading .virtual-table-without-scroll-Y .ant-spin-container{
  padding-bottom: 20px;
}
.cascade-impedance-content {
  padding: 20px;
  min-width: 1350px;
}

.cascade-imp-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.cascade-imp-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.cascade-imp-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.cascade-imp-component-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 20px;
}

.cascade-imp-component-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.cascade-impedance-relative {
  position: relative;
}

.cascade-imp-table .ant-table-small .ant-table-content>.aurora-table-body {
  margin: 0 20px;
}

.impedance-delete-icon {
  position: absolute;
  right: -30px;
  margin-top: -5px;
  top: 48%;
  color: #ccc;
  cursor: pointer;
}

.cascade-imp-table .ant-table-small .ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 30px;
  position: relative;
}

.impedance-delete-icon:hover,
.impedance-vrm-delete-icon:hover,
.imp-target-delete-icon:hover,
.imp-current-delete-icon:hover {
  color: #3da4fa;
}

.impedance-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}


.impedance-panel .panel-body {
  position: relative;
}

.impedance-panel .impedance-panel-title {
  font-size: 16px;
  font-weight: 600;
}

.impedance-vrm-table {
  margin: 10px;
}

.impedance-margin-left-3 {
  margin-left: 3px;
}

.impedance-comp-tabs .ant-tabs-tab-prev-icon,
.impedance-comp-tabs .ant-tabs-tab-next-icon {
  color: #777
}

.impedance-comp-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
}

.impedance-vrm-delete-icon {
  position: absolute;
  right: -24px;
  top: 3px;
  color: #ccc;
}

.impedance-include-extended-content {
  display: inline-block;
}

.impedance-include-extended-content .impedance-include-extended-title {
  vertical-align: middle;
  font-weight: bold;
}

.impedance-include-extended-content .impedance-include-extended-title:not(:first-child).impedance-show-full-title {
  margin-left: 10px;
  padding-left: 10px;
  display: inline-block;
  border-left: 1px solid #c7c7c7;
}

.impedance-include-extended-content .ant-switch-small {
  margin-left: 10px;
  vertical-align: middle;
}

.impedance-detail-icon {
  position: absolute;
  left: -25px;
  top: calc(50% - 15px);
  line-height: unset;
}

.imp-domain-advanced-icon {
  position: absolute;
  right: 22px;
  top: 12px;
  cursor: pointer;
}

.imp-domain-advanced-icon:hover {
  color: #0d87f7;
}

.imp-target-panel,
.imp-current-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

.imp-target-radio,
.imp-current-radio {
  display: block;
  margin: 0 16px;
  height: 20px;
  margin-top: 16px;
}

.imp-target-input,
.imp-target-value-input {
  margin: 8px 0;
  display: inline-block;
  width: calc(100% - 120px);
  margin-right: 5px;
  vertical-align: middle;
}

.imp-target-name-error-msg,
.imp-current-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 12px;
}

.imp-target-input-tooltip {
  z-index: 3000;
}

.imp-target-value-span,
.imp-current-value-span {
  display: inline-block;
  min-width: 20px;
  vertical-align: middle;
  position: relative;
  line-height: 18px;
  height: 18px;
}

.imp-target-value-span>span:first-child,
.imp-current-value-span>span:first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}

.imp-target-frequency,
.imp-target-RL-value,
.impedance-target-file,
.imp-current-pwl {
  margin: 12px;
}

.imp-target-frequency-table,
.imp-current-pwl-table {
  margin-top: 10px;
}

.imp-target-frequency-table .ant-table .aurora-table-body .editable-row td,
.imp-current-pwl-table .ant-table .aurora-table-tbody .editable-row td {
  height: 32px;
  position: relative;
}

.imp-target-frequency-table .ant-table .aurora-table-body .editable-row td .editable-cell-value-wrap,
.imp-current-pwl-table .ant-table .aurora-table-tbody .editable-row td .editable-cell-value-wrap {
  position: unset;
  padding: 0px 8px;
}

.imp-current-pwl-table {
  height: auto;
  max-height: 340px;
  overflow-y: auto;
}

.imp-target-table-icon {
  margin-left: 5px;
}

.imp-target-name-input {
  width: calc(100% - 65px);
}

.imp-target-delete-icon,
.imp-current-delete-icon {
  color: #ccc;
  cursor: pointer;
  position: absolute;
  right: -16px;
  top: 12px;
}

.imp-target-RL-content,
.imp-current-value-content {
  margin: 16px 10px;
  display: block;
}

.imp-target-RL-content .imp-target-RL-content-span {
  width: 100px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
}

.imp-current-value-content .imp-current-value-content-span {
  width: 80px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
}

.imp-current-pwl .imp-current-file-content {
  margin-left: 10px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}

.imp-current-pwl .imp-current-file-title {
  display: inline-block;
  width: 20%;
}

.imp-current-pwl .imp-current-select {
  width: 80%;
}

.imp-target-RL-content .ant-input-group-wrapper {
  width: calc(100% - 100px);
}

.imp-current-value-content .ant-input-group-wrapper {
  width: calc(100% - 80px);
}

.imp-target-RL-content .ant-input-group-wrapper .ant-input-group-addon .aurora-select {
  width: 65px;
  text-align: center;
}

.imp-current-value-content .ant-input-group-wrapper .ant-input-group-addon .imp-current-after,
.imp-transient-setting-content .ant-input-group-wrapper .ant-input-group-addon .imp-current-after {
  width: 30px;
  text-align: center;
}

.imp-target-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}

.imp-target-file-content-display {
  margin-top: 10px;
}

.cascade-imp-edit-icon {
  color: #0d87f7;
  font-size: 16px;
  position: absolute;
  right: -18px;
}

.imp-target-action-group,
.imp-current-action-group {
  position: absolute;
  right: 10px;
  top: -5px;
}

.imp-target-action-group>span,
.imp-current-action-group>span {
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  transition: .3s;
  box-shadow: 0 0 0 0 #fff;
  background: #fff;
  z-index: 1;
  border: 1px solid#dedede;
  cursor: pointer;
  margin: 0 5px;
  justify-content: center;
}

.imp-target-action-group>span:hover,
.imp-current-action-group>span:hover {
  color: #0d87f7;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
  background: #fafafa;
}

.impedance-target-setting-single,
.impedance-current-setting-single {
  position: relative;
  height: 100%;
  float: left;
  width: 100%;
  padding: 5px;
}

.impedance-target-setting,
.impedance-current-setting {
  border-right: 1px solid #e8e8e8;
  float: left;
  height: 100%;
  overflow-y: auto;
  position: relative;
  width: 50%;
  min-width: 300px;
  padding: 5px;
}

.imp-transient-setting-content {
  padding: 2px 6px 12px;
}

.imp-transient-setting-body {
  margin: 10px;
  display: block;
}

.imp-transient-setting-title {
  display: inline-block;
  width: 150px;
  height: 28px;
  line-height: 28px;
}

.imp-transient-setting-body .ant-input-group-wrapper {
  width: calc(100% - 150px);
}

.imp-transient-setting-body .ant-input {
  height: 28px;
  line-height: 28px;
}

.impedance-target-plot,
.impedance-current-plot {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 50%;
  min-width: 400px;
  min-height: 400px;
}

.imp-target-text {
  width: 100%;
  position: relative;
}

.imp-target-text-delete-icon {
  visibility: hidden;
  position: absolute;
  color: #c9c9c9;
  right: 0;
  font-size: 14px;
  top: 7px;
}

.imp-target-text:hover .imp-target-text-delete-icon {
  visibility: visible;
}

.imp-port-loading-content {
  line-height: 600px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.impedance-part-switch-icon {
  color: #3da4fa;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.cascade-component-model-span {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cascade-detail-sense-port-title {
  margin-top: 5px;
  font-weight: bold;
  cursor: default;
}

.cascade-display-detail-box .cascade-detail-sense-port-item {
  margin-left: 10px;
  position: relative;
}

.cascade-sense-detail-line {
  display: flex;
}

.cascade-detail-line .sense-delete-icon {
  font-size: 12px;
  position: relative;
  margin-left: 5px;
  top: 5px;
}

.cascade-detail-line .sense-delete-icon:hover {
  color: #3da4fa;
}

.cascade-sense-detail-line>div {
  margin: 0px !important;
}

.cascade-sense-detail-line-text {
  width: unset !important;
}

.impedance-package-ports-switch-icon {
  margin-top: -2px;
  cursor: pointer;
}

.cascade-imp-overview {
  min-height: 200px;
  min-width: 1000px;
  position: relative;
}

.cascade-imp-overview-button-group {
  display: inline;
  margin-left: 4px;
}

.cascade-imp-overview-button-group>i {
  margin-left: 10px;
}

.cascade-imp-multi-pcb-target {
  display: inline;
  margin-left: 10px;
}

.cascade-imp-multi-pcb-target>span,
.cascade-imp-decap-group-span {
  vertical-align: middle;
  font-size: 12px;
  border: 1px solid #0d87f7;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  padding: 1px 5px;
  color: #0d87f7;
}

.cascade-imp-decap-group-span {
  width: 86px;
  text-align: center;
  width: 100px;
  height: 28px;
  text-align: center;
  line-height: 24px;
}

.cascade-imp-multi-pcb-target:hover>span {
  color: #1890ff
}

.cascade-imp-overview-content {
  position: relative;
}

.cascade-imp-overview-layouts-box {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.cascade-imp-overview-column {
  width: 300px;
}

.cascade-imp-overview-layout {
  width: 100%;
  padding: 10px;
}

.cascade-imp-overview-layout .layout-title {
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.cascade-imp-overview-layout .layout-title>span:not(.anticon) {
  max-width: 230px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  padding-right: 5px;
}

.cascade-imp-overview-layout .layout-title>.anticon {
  margin-top: 2px;
}

.cascade-imp-overview-layout .layout-delete-icon,
.cascade-imp-overview-layout .layout-in-icon {
  font-size: 12px;
  vertical-align: middle;
}

.cascade-imp-overview-layout .layout-switch-icon {
  margin: 0px 6px;
  font-size: 14px;
  vertical-align: middle;
}

.cascade-imp-overview-layout .layout-delete-icon:hover,
.cascade-imp-overview-layout .layout-title:hover .layout-in-icon,
.cascade-imp-overview-layout .layout-switch-icon:hover {
  color: #3da4fa;
}

.cascade-imp-overview-column .layout-content {
  border: 1px solid #a8c4e6;
  border-radius: 3px;
  position: relative;
  min-height: 120px;
}

.impedance-overview-canvas {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.cascade-imp-overview-column .layout-content .layout-components {
  position: relative;
  height: calc(100% - 80px);
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item {
  position: absolute;
  width: 100px;
  height: 70px;
  /* line-height: 60px; */
  margin: 30px 0px;
  border: 1px solid gray;
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cascade-imp-overview-column .layout-content .layout-components .component-input {
  display: inline-block;
  max-width: 50px;
  min-width: 10px;
  height: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .component-value {
  font-size: 14px;
  font-weight: 400;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .cascade-component-value-title {
  vertical-align: middle;
  display: inline-block;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .layout-gnd-v-line {
  position: absolute;
  height: 20%;
  bottom: -20%;
  border-right: solid #202020 1px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .layout-gnd-h-line {
  position: absolute;
  width: 40%;
  bottom: -20%;
  border-top: solid #202020 2px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .layout-gnd-text {
  position: absolute;
  bottom: -50%;
  font-size: 12px;
  color: #202020;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-die-model,
.cascade-imp-overview-column .layout-content .layout-components .layout-die-model-reverse {
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 76px;
  width: 70px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-die-model {
  right: 136px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-die-model-reverse {
  left: 122px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-die-model .layout-rdie,
.cascade-imp-overview-column .layout-content .layout-components .layout-die-model .layout-cdie {
  margin-left: 32px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-cdie {
  margin-top: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-pairs,
.cascade-imp-overview-column .layout-content .layout-components .layout-model-pairs-reverse {
  position: absolute;
  height: 62px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border: 2px solid #889FBA;
  background-color: #fff;
  border-radius: 2px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-pairs {
  right: 172px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-pairs-reverse {
  left: 172px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-cpm-pairs-reverse,
.cascade-imp-overview-column .layout-content .layout-components .layout-model-cpm-pairs {
  position: absolute;
  height: 80px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  width: 180px;
  justify-content: center;
  align-items: center;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-cpm-pairs-reverse {
  left: 30px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-cpm-pairs {
  right: 30px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-cpm-item {
  position: absolute;
  display: flex;
  right: 10px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-cpm-item div {
  display: inline-block;
  margin-right: 5px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-node-2 {
  margin-top: 26px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rleak,
.cascade-imp-overview-column .layout-content .layout-components .layout-rleak-reverse {
  position: absolute;
  display: flex;
  flex-direction: row;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rdl,
.cascade-imp-overview-column .layout-content .layout-components .layout-rdl-reverse {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rleak,
.cascade-imp-overview-column .layout-content .layout-components .layout-rdl {
  left: 170px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rleak-reverse,
.cascade-imp-overview-column .layout-content .layout-components .layout-rdl-reverse {
  right: 170px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rdl-title {
  display: inline-block;
  vertical-align: middle;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-select,
.cascade-imp-overview-column .layout-content .layout-components .layout-port-select-reverse {
  position: absolute;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-select-box {
  width: 180px;
  margin-right: 6px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-title {
  margin: 0 10px;
}

.layout-port-treeSelect .ant-select-tree {
  margin: 4px 0px;
}

.layout-port-treeSelect .ant-select-tree-title {
  font-size: 13px;
}

.layout-port-treeSelect .ant-select-tree li span.ant-select-tree-switcher,
.layout-port-treeSelect .ant-select-tree li span.ant-select-tree-iconEle {
  width: auto;
}

.layout-port-treeSelect .ant-select-tree li {
  margin: 0 0;
}

.layout-interposer {
  position: absolute;
  background-color: #fff;
  top: 0px;
  left: 80px;
  width: 120px;
  height: 50px;
  margin: 40px 0;
  border: 2px solid #889FBA;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.layout-node-empty {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.layout-die-node,
.layout-pkg-node-reverse {
  position: absolute;
  top: 40px;
  left: 10px;
}

.layout-pkg-node,
.layout-die-node-reverse {
  position: absolute;
  top: 40px;
  right: 10px;
}

.layout-node-select {
  width: 60px;
  height: 28px;
  line-height: 28px;
}

.layout-bga-port-count {
  height: 40px;
  line-height: 40px;
  text-align: right;
  margin-right: 10px;
  font-weight: bold;
}

.layout-bga-port-count-reverse {
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin-left: 10px;
  font-weight: bold;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-count {
  position: absolute;
  right: 10px;
  font-weight: bold;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-count-reverse {
  position: absolute;
  left: 10px;
  font-weight: bold;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .delete-connector-icon {
  position: absolute;
  right: 3px;
  top: 3px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item-left {
  left: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item-right {
  right: 10px;
}

.cascade-imp-overview-column .layout-content .layout-add-connector {
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: right;
}

.cascade-imp-overview-column .layout-content .layout-add-connector .connector-button {
  margin-right: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon,
.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon-reverse {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #687A8F;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 86px;
  cursor: pointer;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon-disabled {
  cursor: auto;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon {
  left: 6px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon-reverse {
  right: 6px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-die,
.cascade-imp-overview-column .layout-content .layout-components .layout-unified-die-reverse {
  position: absolute;
  width: 180px;
  height: 100px;
  display: flex;
  cursor: pointer;
  margin-top: 16px;
  border: 2px solid #889FBA;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-die {
  left: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-die-reverse {
  right: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-node,
.cascade-imp-overview-column .layout-content .layout-components .layout-unified-node-reverse {
  position: absolute;
  font-weight: bold;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-node {
  right: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-node-reverse {
  left: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current {
  position: absolute;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid #687A8F;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-1 {
  top: 20px;
  left: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-2 {
  top: 30px;
  left: 56px;
  font-size: 16px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-3 {
  top: 40px;
  left: 92px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-1-reverse {
  top: 20px;
  right: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-2-reverse {
  top: 30px;
  right: 56px;
  font-size: 16px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-3-reverse {
  top: 40px;
  right: 92px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-name {
  font-size: 12px;
  margin-top: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-ic-current-icon-box {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #0d87f7;
  vertical-align: middle;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-ic-current-box {
  font-weight: 400;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-ic-current-box .ant-checkbox-inner {
  width: 12px;
  height: 12px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-ic-current-title {
  margin: 0 4px;
}

.layout-ic-current-icon {
  cursor: pointer;
  color: #0d87f7;
  font-size: 10px;
}

.layout-ic-current-icon-disabled {
  cursor: not-allowed;
  font-size: 10px;
}

.cascade-add-pcb-panel {
  box-shadow: 2px 4px 7px 5px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.cascade-add-pcb-content {
  margin: 20px;
}

.cascade-add-pcb-content>span {
  width: 150px;
  display: inline-block;
}

.cascade-add-pcb-content .pcb-select {
  width: calc(100% - 180px);
}

.cascade-add-pcb-interposer-content {
  margin: 20px;
}

.cascade-add-pcb-interposer-content>span {
  width: 220px;
  display: inline-block;
}

.cascade-add-pcb-interposer-content .pcb-select {
  width: calc(100% - 240px);
}

.cascade-add-pcb-connector-content {
  position: relative;
  margin: 20px;
}

.cascade-imp-add-connection {
  margin: 10px;
}

.cascade-imp-add-connection .add-connection-content>span {
  width: 140px;
  display: inline-block;
}

.cascade-imp-add-connection .add-connection-content .add-connection-item {
  width: calc(100% - 160px);
}

.cascade-imp-add-connection .add-connection-relationship>span {
  width: 100%;
  display: inline-block;
  margin: 20px 0px;
}

.cascade-conn-ship-content {
  margin-left: 10px;
}

.cascade-imp-add-connection .cascade-conn-ship-content .add-connection-content .add-connection-item {
  margin-bottom: 10px;
}

.cascade-imp-add-connection .ant-divider-horizontal {
  margin: 10px 0px;
}

.cascade-ship-connect-table {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  position: relative;
}

#cascade-add-connection-dialog,
#cascade-add-connection-dialog>div {
  z-index: 3000
}

.cascade-setup-border-position {
  position: relative;
}

.cascade-change-page-button {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 0px 10px;
  display: inline-block;
}

.cascade-change-page-button:not(:first-child) {
  margin-left: 15px;
}

.cascade-pcb-setup-content {
  line-height: 32px;
  vertical-align: middle;
}

.cascade-imp-add-connection #cascade-net-connection-content {
  overflow: hidden;
}

.impedance-target-setting .ant-tabs-content,
.impedance-target-setting-single .ant-tabs-content {
  position: relative;
}

.cascade-imp-pcb-table .aurora-table-body .editable-row td:not(:last-child) {
  position: relative;
}

.cascade-imp-decap-group-span.no-decap-group {
  border: 1px solid #85837d;
  color: #85837d;
}

.cascade-decap-group-panel {
  margin-top: 126px;
  margin-left: -440px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-decap-group-panel .cascade-decap-group-table {
  margin: 10px;
}

.cascade-cap-components-table .cascade-components-table .ant-table-small>.ant-table-content .aurora-table-body .cascade-cap-comp-group-border {
  border-bottom: 1px solid #a8c4e6;
}

.cascade-cap-components-table .cascade-components-table .ant-table-small>.ant-table-content .aurora-table-body .cascade-cap-comp-group-border>td,
.cascade-cap-components-table .cascade-components-table .ant-table-small>.ant-table-content .aurora-table-body>tr>.cascade-cap-comp-group-border-td {
  border-bottom: none;
}

.cascade-cap-components-table .cascade-components-table .ant-table table {
  border-collapse: collapse;
}

.cascade-cap-comp-pcb-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.impedance-decap-setting-main {
  margin: 10px;
}

.impedance-decap-setting-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.impedance-decap-setting-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.impedance-decap-setting-button {
  width: 80px;
}

.imp-current-apply {
  text-align: left;
}

.imp-current-apply>label {
  margin: 0px 10px;
  vertical-align: middle;
}

.interposer-content {
  padding: 10px;
}

.rleak-rdl-content {
  padding: 10px 16px;
}

.rleak-rdl-body {
  padding: 5px 0;
}

.rleak-rdl-input-title {
  width: 80px;
  display: inline-block;
  line-height: 28px;
}

.rleak-rdl-input {
  width: calc(100% - 80px);
}

.rleak-rdl-body .ant-input {
  line-height: 28px;
  height: 28px;
}

.apply-footer {
  text-align: left;
}

.apply-footer>label {
  margin-right: 10px;
  vertical-align: middle;
}

.cascade-decap-sweep-model-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 90%;
}
.decap-select-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.decap-select-model-title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.decap-select-model-content {
  width: 100%;
  padding: 0px 14px;
  font-size: 13px;
  margin-bottom: 6px;
}

.select-model-content-row {
  display: flex;
  justify-content: space-between;
}

.select-model-content-row .type-name {
  float: left;
  line-height: 24px;
  font-weight: 500;
  width: 100px;
}

.select-model-content-row .select-model-content-cal-custom .custom-span {
  width: 164px;
}

.select-model-content-row .ant-select {
  float: left;
  width: calc(100% - 100px);
  height: 24px;
  font-size: 13px;
  line-height: 24px;
}

.select-model-content-row .ant-input {
  float: left;
  width: calc(100% - 100px);
  height: 24px;
  font-size: 13px;
  line-height: 24px;
}

.select-model-content-row .select-model-content-cal-custom .ant-select,
.select-model-content-row .select-model-content-cal-custom .ant-input {
  width: calc(100% - 164px);
}

.select-model-content-row .voltageInput .ant-input {
  width: 100%;
}

.select-model-content-cal {
  width: 100%;
  padding: 5px 10px;
  line-height: 24px;

}

.select-model-select-dropdown {
  z-index: 5000;
}

.decap-select-model-content .select-model-name-box {
  width: 100%;
  padding: 6px;
  overflow: auto;
  background-color: #eee;
  border-radius: 6px;
  margin: 0px 10px 0px 10px;
}

.decap-select-model-content .select-model-ul {
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  overflow-anchor: none;
  position: relative;
}

.decap-select-model-content .select-model-li {
  white-space: nowrap;
  display: block;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
}

.decap-select-model-content .select-model-li-title {
  white-space: nowrap;
  display: block;
  height: 22px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  background-color: #eee;
  width: 100%;
  z-index: 10000;
  padding-right: 18px;
}

.decap-select-model-content .select-model-li:hover {
  background-color: #d5ecff;
}

.decap-select-model-content .select-model-li-selected {
  background-color: #b9ecff;
  width: 100%;
  z-index: 10000;
  padding-right: 17px;
}

.decap-select-model-content .hidden {
  padding-right: 0px;
}

.decap-select-model-content .select-model-li-loading {
  cursor: not-allowed;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.select-model-li .select-model-li-strong {
  font-weight: 600;
}

.select-model-li .ant-col>span,
.select-model-li-title .ant-col>span {
  font-size: 12px;
}


.decap-select-model-content .apply-model-title {
  width: 175px;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
}

.decap-select-model-content .ant-select {
  font-size: 13px;
}

/* .select-model-selection-dropdown {
  position: absolute;
  max-height: 200px;
  overflow: auto;
  margin-top: 3px;
  min-width: 100%;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.15);
  overflow-anchor: none;
} */

.select-model-selection-component {
  float: left;
  width: 100%;
}

.select-model-selection-dropdown .menu-list {
  margin: 2px;
  padding-inline-start: 0px
}

.select-model-selection-dropdown .menu-list li {
  padding: 3px 10px;
  width: 100%;
  white-space: nowrap;
}

.select-model-selection-dropdown .menu-list li:hover {
  background: #E6F7FF;
}

.select-model-selection-dropdown .menu-list li:hover .menu-item-check {
  visibility: visible;
}

.select-model-selection-dropdown .menu-list .menu-selected-item {
  background: #fafafa;
  font-weight: 800;
}

/* custom library list render */
.search-library-content-list {
  margin: 10px 16px;
  background: #eee;
  padding: 8px;
  border-radius: 6px;
  text-align: center;
  overflow: auto;
  width: calc(100% - 32px)
}

.search-library-content-no-data {
  font-size: 16px;
  color: #a3a3a3;
}

.search-library-list-title {
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.search-library-list-item {
  height: 24px;
  line-height: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.search-library-item-name {
  font-weight: bold;
}

.search-library-list-item>span {
  text-align: left;
  padding: 0px 10px;
  text-align: center;
}

.search-library-list-item .search-library-item-path {
  white-space: nowrap;
  text-align: left;
}

.search-library-content-list-main {
  width: -moz-fit-content;
  width: fit-content;
}

.decap-select-model-content .search-library-list-item {
  height: 22px;
  line-height: 22px;
  font-size: 12px;
}

.decap-select-model-content .search-library-list-item:hover {
  background-color: #d5ecff;
}

.decap-select-model-content .search-library-list-item-selected {
  background-color: #b9ecff;
}

.decap-select-model-content .search-library-content-list {
  margin: 10px 10px;
  width: calc(100% - 20px);
}

.select-model-input-component.ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  cursor: text;
}

.decap-model-content-layout {
  width: 100%;
  height: 100%;
}

.decap-model-content-sider,
.decap-model-content-menu {
  background-color: #f9f9f9;
}

.decap-model-content-menu {
  max-height: 500px;
  overflow-y: auto;
}

.decap-model-content {
  background-color: #fff;
  position: relative;
}

.decap-model-content-menu-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.decap-model-content-menu.ant-menu .ant-menu-item {
  margin-bottom: 0px;
  height: 32px;
  line-height: 32px;
  padding-left: 20px;
}

.decap-model-content-menu-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.decap-model-content-menu-label-text {
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.menu-icon {
  display: none;
  flex-shrink: 0;
}

.decap-model-content-menu-item:hover .menu-icon {
  display: block;
}

.menu-icon:hover {
  color: #1890ff;
}

.decap-model-content-menu-input {
  height: 28px;
  line-height: 28px;
}

.decap-model-content-add {
  border: dashed 1px #1890ff;
  text-align: center;
  width: 80%;
  max-width: 180px;
  min-width: 100px;
  height: 32px;
  line-height: 32px;
  margin: 12px auto;
  cursor: pointer;
  border-radius: 6px;
  color: #1890ff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.decap-model-content-add:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: #1677ff;
  border-color: #1677ff;
}


.decap-model-sweep-apply {
  display: flex;
  align-items: center;
  width: calc(100% - 44px);
  margin: 10px 20px 10px 24px;
  justify-content: space-between;
}

.decap-model-sweep-apply-title {
  flex: 1 1;
  min-width: 0;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.decap-model-sweep-apply .ant-switch {
  flex-shrink: 0;
}

.decap-model-sweep-divider {
  margin: 10px 0;
}
/* .ssn-centric-file-select-panel-content .netlist-model-content {
  margin-bottom: 16px;
} */

.ssn-centric-file-select-panel-content {
  height: -moz-fit-content;
  height: fit-content;
  max-height: 800px;
}

.ssn-die-radio-group {
  display: block;
  margin: 0 16px;
  height: 20px;
  margin-top: 16px;
}

.ssn-die-radio-value {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.ROCKY-ssn-centric-file-select-panel-content .rocky-ssn-die-radio-group .ssn-die-radio-value,
.CASCADE-ssn-centric-file-select-panel-content .cascade-ssn-die-radio-group .ssn-die-radio-value {
  width: 33%;
}

.ssn-value-box-content {
  margin: 11px 16px;
}

.ssn-file-box-content {
  margin: 16px 6px;
}

.ssn-value-box-content .ssn-pdn-content-value-input-item {
  padding: 5px 0;
}

.ssn-value-box-content .ssn-pdn-content-value-input-item .ssn-pdn-content-value-input-item-title {
  width: 60px;
  display: inline-block;
  line-height: 24px;
}

.ssn-value-box-content .ssn-pdn-content-value-input-item .ssn-pdn-content-value-input-item-input {
  width: calc(100% - 60px);
}

.CASCADE-ssn-value-box-content .ssn-pdn-content-value-input-item .ssn-pdn-content-value-input-item-title {
  width: 100px;
}

.CASCADE-ssn-value-box-content .ssn-pdn-content-value-input-item .ssn-pdn-content-value-input-item-input {
  width: calc(100% - 100px);
}

.ssn-file-box-content .netlist-model-row .ant-select-selector,
.ssn-file-box-content .netlist-model-row .netlist-model-selection,
.ssn-file-box-content .netlist-model-row .ant-select-selection,
.ssn-file-box-content .netlist-model-table-rows .editable-cell-value-wrap {
  height: 28px;
  line-height: 24px;
}

.ssn-file-box-content .netlist-model-table-rows {
  max-height: unset;
}

.ssn-file-box-content .aurora-select .ant-select-arrow {
  right: 11px;
}

.ssn-pdn-content-file-select-item {
  padding: 0px 10px 5px 10px;
}

.ssn-pdn-content-file-select-item .ssn-pdn-content-file-select-item-title {
  width: 100px;
  display: inline-block;
  line-height: 24px;
}

.ssn-pdn-content-file-select-item .aurora-select {
  width: calc(100% - 100px);
}

.model-apply {
  text-align: left;
}

.model-apply>label {
  margin: 0px 10px;
  vertical-align: middle;
}

.ssn-die-per-port {
  margin: 12px 16px;
}

.ssn-die-per-port-type {
  width: 100px;
  display: inline-block;
  line-height: 24px;
}

.ssn-die-per-port .ssn-die-radio-value {
  width: 50%;
}

.ssn-die-per-port-radio-group {
  width: 300px;
  vertical-align: middle;
}

.ssn-die-divider {
  margin: 10px 0;
}

.ssn-die-icon {
  color: #0d87f7;
  font-size: 16px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 6px;
}
.cascade-result-impedance {
  height: 100%;
  position: relative;
}

.cascade-result-impedance-result-content {
  position: absolute;
  width: 100%;
  top: 45px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.impedace-result-effective-layout {
  overflow: auto;
}

.cascade-result-impedance .ant-spin-nested-loading,
.cascade-result-impedance .ant-spin-nested-loading .ant-spin-container {
  height: calc(100% - 5px);
}

.cascade-result-parameter-left {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

.cascade-result-parameter-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 20px;
  margin-left: 8px;
  right: 0px;
  width: 45px;
  height: 78px;
}

.cascade-result-parameter-right .photo-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 6px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); */
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 27px;
  transition: all 0.3s;
}

.cascade-result-parameter-right .photo-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.cascade-photo {
  width: 19px;
  margin-left: 1px;
}

.cascade-result-parameter-right .curve-table-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 44px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 30px;
  transition: all 0.3s;
}

.cascade-result-parameter-right .curve-table-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.cascade-curve-table-icon {
  font-size: 17px;
  color: #fff;
}

.cascade-result-effect-title {
  display: inline-block;
  margin: 10px 0;
  font-weight: 700;
}

.cascade-result-effect-table {
  margin: 20px 0px;
  padding-top: 0px !important;
}

.cascade-result-effect .ant-spin-nested-loading {
  height: auto;
}

.cascade-result-effect-setting {
  margin: 10px 20px 0px 20px;
  text-align: left;
}

.cascade-result-effect-input {
  width: calc(100% - 200px);
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.cascade-customized-title {
  vertical-align: middle;
  height: 20px;
  display: inline-block;
  width: 100%;
}

.cascade-customized-title .design-name {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.cascade-customized-title .component-pin-name {
  color: #3b70a7;
  display: inline-block;
}

.cascade-customized-title .signal-name {
  color: #2f7b35;
}

.cascade-customized-title .port-mode {
  color: rgba(0, 0, 0, 0.65);
}

.cascade-result-impedance-result-content .sparameter-file-expand-icon,
.cascade-result-impedance-result-content .sparameter-span-file-name {
  vertical-align: middle;
}

.cascade-effective-result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
  min-width: 120px;
  padding-right: 20px
}

.cascade-effective-result-menu-title>span {
  display: inline-block;
}

.cascade-effective-result-vertically-divider {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  padding-right: 30px !important;
  margin-left: 10px !important;
  position: relative;
}

.cascade-effective-result-vertically-divider::before {
  width: 0% !important;
}

.cascade-effective-result-vertically-divider::after {
  width: 95% !important;
}

.cascade-effective-result-title {
  width: -moz-fit-content;
  width: fit-content;
}

.cascade-result-impedance-result-content .title-expand-icon {
  margin-top: 10px;
}

.cascade-effective-result-title .title-expand-icon {
  cursor: pointer;
  margin-left: 1px;
  margin-top: 10px;
}

.cascade-effective-result-checkout-box {
  margin-right: 5px;
}

.cascade-effective-result-next-divider {
  padding-right: 30px !important;
}

.cascade-effective-result-not-title-divider {
  margin-left: 10px !important;
  padding-right: 30px !important;
}

.cascade-effective-result-next-divider::after,
.cascade-effective-result-not-title-divider::after {
  border-top: 0px !important;
}

.cascade-result-effect-canvas {
  margin: 20px 0px;
  display: flex;
}

.cascade-result-effect-canvas .canvas-content {
  width: 49%;
}

.effect-bar-chart-xAxis .domain,
.effect-bar-chart-yAxis .domain {
  display: unset;
}

.cascade-result-effect-divider {
  height: 500px;
}

.cascade-result-effect-left {
  overflow: auto;
  height: 100%;
  min-width: 800px;
}

.cascade-result-effect-left-box {
  margin: 0px 20px;
  padding: 10px 0;
}

/* .cascade-result-effect-left-box:not(:last-child) {
  border-bottom: 1px solid #ccc;
} */

.cascade-effective-result-title .color-select {
  width: 16px;
  height: 21px;
  vertical-align: middle;
}

.cascade-effective-history-delete-icon {
  right: 40px;
  visibility: hidden;
  cursor: pointer;
}

.cascade-effective-result-vertically-divider:hover .cascade-effective-history-delete-icon {
  visibility: visible;
}

.cascade-effective-icon {
  font-size: 13px;
  color: #40a9ff;
  position: absolute;
  top: 6px;
}

.cascade-effective-map-icon {
  right: unset;
  margin-left: -10px;
  visibility: visible;
  z-index: 5;
}

.cascade-impedance-sparameter-current-result {
  overflow-y: hidden;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name,
.cascade-result-impedance-result-content .sparameter-result-checkbox-group {
  display: flex;
  align-items: center;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group .ant-checkbox {
  top: 0px;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name>label .checkbox-group-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name .checkbox-group-name {
  display: block;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name>label {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group .ant-checkbox+span {
  width: calc(100% - 16px);
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group>.ant-checkbox {
  height: 16px;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name>input {
  flex-shrink: 0;
}

.cascade-result-effective-compare-add-icon {
  margin-left: 5px;
  margin-top: 0px;
  color: #40a9ff;
  font-size: 15px;
  cursor: pointer;
  padding-top: 4px
}

.cascade-result-effective-compare-cascader {
  margin-left: 23px;
  margin-top: 5px;
  width: calc(100% - 50px);
}

.cascade-result-effective-compare-cascader-close-icon {
  margin-left: 10px;
  font-size: 13px;
  color: #40a9ff;
}

.cascade-result-effect-table-main .ant-table-content {
  overflow: auto;
}

.cascade-result-effect-table-main-pins {
  max-height: 200px;
  overflow: auto;
}

.cascade-effect-map-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-effect-map-name {
  font-size: 16px;
  font-weight: 500;
}

.cascade-effect-map-content {
  margin: 10px;
}

.cascade-effect-select-dropdown-menu {
  z-index: 1000000000;
}

.cascade-result-effective-color-tips {
  position: absolute;
  right: 0;
  top: 8px;
  text-align: right;
}

.cascade-result-effective-color-tips .tip-content {
  margin-right: 15px;
  display: inline-block;
}

.cascade-result-effective-color-tips .tip-content .tip-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px
}

.cascade-result-effect-title .camera-icon:hover {
  color: #1890ff;
}

.cascade-result-effect-title .camera-icon {
  margin-left: 8px;
}

.cascade-result-impedance-result-content .result-zoom-menu {
  top: 76px;
  width: 45px;
  right: -3px;
}

.cascade-result-impedance-result-content .result-zoom-menu.ant-menu-vertical .ant-menu-item {
  margin-bottom: 10px;
}

.cascade-waveform-setting-box {
  padding-left: 20px;
  padding-top: 10px;
  max-width: 450px;
  overflow: auto;
}

.cascade-waveform-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 5%;
}

.cascade-opt-result-panel-content-keep,
.cascade-opt-result-panel-content-opt {
  padding: 10px 10px
}

.cascade-opt-result-table {
  margin: 6px 5px;
  background-color: #fff;
}

.cascade-opt-sum-title {
  margin-left: 10px;
}
.cascade-decap-row {
  margin-top: 16px;
}

.cascade-decap-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-decap-main {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.cascade-decap-content {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 579px;
}

.cascade-decap-icon {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  margin-left: 14px;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.cascade-decap-eye-icon {
  margin-left: 10px;
  margin-right: 14px;
  color: #40a9ff;
  font-size: 17px;
  vertical-align: middle;
  cursor: pointer;
}

.cascade-decap-content .cascade-decap-eye-icon-disable {
  color: rgba(0, 0, 0, 0.65);
  cursor: not-allowed;
}
.cascade-decap-group-row {
  margin-top: 16px;
}

.cascade-decap-group-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-decap-group-main {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.cascade-decap-group-content {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 579px;
}

.cascade-decap-group-icon {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  margin-left: 14px;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.cascade-decap-group-eye-icon {
  margin-left: 10px;
  margin-right: 14px;
  color: #40a9ff;
  font-size: 17px;
  vertical-align: middle;
  cursor: pointer;
}

.cascade-decap-group-content .cascade-decap-group-eye-icon-disable {
  color: rgba(0, 0, 0, 0.65);
  cursor: not-allowed;
}
.cascade-power-tree-canvas {
  min-height: 80px;
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  position: relative;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.85)
}

.cascade-power-tree-canvas .power-tree-canvas {
  text-align: center;
  vertical-align: middle;
  z-index: 1;
}

.cascade-power-tree-main .power-tree-name-span .delete-tree-icon {
  right: -130px
}

.cascade-power-tree-main .power-tree-name-span .detail-tree-icon {
  right: -55px
}

.cascade-power-tree-main .power-tree-name-span .shrink-tree-icon {
  right: -30px
}

.cascade-power-tree-main .power-tree-name-span .camera-tree-icon {
  right: -105px
}

.cascade-power-tree-main .power-tree-name-span .experiment-tree-icon {
  right: -80px;
}

.cascade-power-tree-main .power-tree-name-span .experiment-tree-not-allowed-icon,
.cascade-power-tree-main .power-tree-name-span .experiment-tree-not-allowed-icon:hover {
  cursor: not-allowed;
  color: unset;
}

.cascade-power-tree-main .power-tree-name-span .name-icon {
  position: absolute;
  top: 1.5px;
  cursor: pointer;
}

.cascade-power-tree-main .power-tree-name-span .name-icon:hover {
  color: #1890ff;
}

.cascade-power-tree-name-edit {
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.cascade-power-tree-root-edit {
  margin: 10px 0px;
}

.cascade-power-tree-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.cascade-power-tree-edit-icon {
  color: #0d87f7;
  font-size: 16px;
  position: absolute;
  right: -28px;
  margin-left: 5px;
  margin-top: -1px;
}

.power-tree-name-span {
  display: inline-block;
  min-width: 20px;
  vertical-align: middle;
  position: relative;
  line-height: 18px;
  height: 18px;
}

.power-tree-name-span span:not(.anticon) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
}

.cascade-power-tree-canvas-component {
  position: absolute;
  z-index: 3;
}

.cascade-power-tree-canvas-component .tree-component-content {
  border: 3px solid #000000;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-component-name {
  text-align: center;
  font-weight: bold;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-component-value {
  text-align: center;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-multi-component-value {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 100%
}

.cascade-power-tree-canvas-component .tree-component-content .root-value {
  margin-top: 5px;
}

.cascade-power-tree-value-title {
  display: inline-block;
  vertical-align: middle;
}

.cascade-power-tree-canvas-component .tree-component-content .root-value .root-title {
  width: 30px;
  text-align: left;
}

.cascade-power-tree-canvas-component .tree-component-content .comp-value .component-span,
.cascade-power-tree-canvas-component .tree-component-content .tree-component-v-table .component-span {
  display: inline-block;
  min-width: 20px;
  max-width: 55px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.cascade-power-tree-canvas-component .tree-component-content .comp-value .component-input,
.cascade-power-tree-canvas-component .tree-component-content .tree-component-v-table .component-input {
  display: inline-block;
  max-width: 50px;
  min-width: 10px;
  height: 20px;
}

.cascade-power-tree-canvas-component .tree-component-content .root-value .component-span {
  display: inline-block;
  min-width: 20px;
  max-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.cascade-power-tree-canvas-component .tree-component-content .root-value .component-input {
  display: inline-block;
  max-width: 50px;
  min-width: 20px;
  height: 20px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-pin {
  position: absolute;
  border: 3px solid #9c9c9c;
  border-radius: 50%;
  padding: 3px;
  background-color: #ececec;
  top: calc(50% - 6px);
}

.cascade-power-tree-canvas-component .tree-component-content .tree-voltage {
  position: absolute;
  margin: 3px;
  font-size: 10px;
  top: calc(50% - 25px);
}

.cascade-power-tree-canvas-component .tree-component-content .tree-pin-left {
  left: 0px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-pin-right {
  right: 0px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-icon-box {
  position: absolute;
  right: 3px;
  top: 0px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-icon-box .tree-component-icon {
  color: #83f4ef;
  font-size: 14px;
  margin: 0 3px;
  cursor: pointer;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-component-v-table {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-weight: 600;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-result-box {
  position: absolute;
  left: 5px;
  top: 0px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-node-select {
  position: absolute;
  font-size: 10px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-node-select .ant-select-selection {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffffff
}

.cascade-power-tree-canvas-component .tree-component-content .tree-node-select-has-value .ant-select-selection {
  border: 0px;
}

.cascade-power-tree-v-value-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: left;
  overflow: hidden;
}

.cascade-power-tree-pair-result {
  position: absolute;
  font-size: 10px;
  z-index: 1000
}

.cascade-power-tree-pair-result span {
  display: inline-block;
  margin-right: 2px
}

.powertree-pair-result-detail-icon {
  position: absolute;
  cursor: pointer;
  z-index: 1000;
}

.powertree-pair-result-detail-tooltip {
  min-width: 100px;
}

.driver-model-content {
  padding: 20px;
  width: 100%;
}

.driver-model-select-file {
  margin-bottom: 10px;
}

.driver-model-select-file>span {
  display: inline-block;
  width: 80px;
}

.driver-model-select-file .aurora-select {
  width: calc(100% - 80px);
}

.driver-model-select-drowdown {
  z-index: 2000;
}

.cascade-power-tree-pcb-name-title {
  position: relative;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.cascade-power-tree-pcb-name-title .tree-tip {
  display: inline-block;
  margin-right: 10px;
  /* cursor: pointer; */
}

.cascade-power-tree-pcb-name-title .tree-tip .tree-tip-color-select {
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
  border: 0px;
  cursor: pointer;
  outline: none;
}


.cascade-power-tree-pcb-name-title .tree-tip .tree-tip-color {
  width: 14px;
  height: 18px;
  margin: 0 5px;
}

.cascade-power-tree-pcb-name-title .tree-tip .tree-tip-forbidden-color {
  cursor: not-allowed;
  width: 10px;
  height: 10px;
  border: 1px solid black;
  display: inline-block;
}


.cascade-power-tree-pcb-name-title .tree-select-tip>span {
  /* color: #0d87f7; */
}

.cascade-power-tree-canvas-select-pcb-component {
  /* box-shadow: #0d87f7 0px 0px 4px 1px; */
}

.cascade-power-tree-pcb-name-title .tree-forbid-tip {
  cursor: not-allowed;
}


.cascade-power-tree-pcb-name-title .tree-tip .tree-tip-name {
  padding: 2px 4px;
}

.cascade-power-tree-details-box pre {
  overflow: hidden;
  display: contents;
  margin-bottom: 0;
}

.cascade-power-tree-details {
  margin: 10px;
}

.cascade-power-tree-result-screen-shot-temp {
  position: absolute;
  text-align: center;
  z-index: 1999;
}

.tree-component-pcb-name {
  position: absolute;
  font-size: 10px;
  white-space: nowrap;
}

.cascade-power-tree-experiment-not-modify {
  color: #1890ff;
}

.cascade-power-tree-experiment-down-modify {
  color: #0bb929;
}

.cascade-power-tree-experiment-up-modify {
  color: #f32008;
}

.cascade-power-tree-experiment-voltage {
  color: #2700ef
}

.cascad-power-tree-pair-input {
  width: 150px;
}

.cascade-power-tree-pair-result .ant-input-group {
  display: table;
}

.cascade-power-tree-pair-result .ant-input-group .ant-input-group-addon {
  display: table-cell;
}
.cascade-design-tree-content {
  padding: 20px;
  min-width: 1350px;
  height: 100%;
}

.cascade-design-tree-content .design-tree-spin {
  text-align: center;
  width: 100%;
  height: 100%;
}

.cascade-design-tree-content .design-tree-spin .ant-spin {
  margin-top: 10%;
}

.cascade-power-tree-apply-to {
  position: absolute;
  top: 0;
  right: 0;
}

.cascade-power-tree-main-loading-no-canvas .ant-spin-spinning{
  top: 100% !important;
}
.cascade-pre-layout-body {
  padding: 20px;
  min-width: 1350px;
}

.cascade-pre-layout-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-pre-layout-decap-group-border {
  max-width: 800px;
}

.cascade-prelayout-setup {
  position: relative;
  overflow-x: auto;
}

.cascade-prelayout-setup .setup-component {
  position: absolute;
  width: 100px;
  margin: 20px 0;
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  background-color: #ffffff;
}

.cascade-prelayout-setup .setup-component-right {
  right: 20px
}

.cascade-prelayout-setup .setup-component-left {
  left: 20px
}

.cascade-prelayout-setup .setup-voltage {
  position: absolute;
  top: 55%;
  line-height: 28px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-weight: normal;
}

.cascade-prelayout-setup .setup-voltage>input {
  width: 100px
}

.cascade-prelayout-setup .setup-decap-group {
  position: absolute;
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  background-color: #ffffff;
}

.cascade-prelayout-setup .setup-decap-group-select {
  box-shadow: 0px 0px 5px 1px #f4b083;
}


.cascade-prelayout-setup .setup-decap-group .decap-name {
  position: absolute;
  font-weight: bold;
}

.cascade-prelayout-setup .setup-decap-groups .decap-name {
  transform: rotate(90deg);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  top: 40px;
  right: -60px;
  text-align: center;
}

.cascade-prelayout-setup .setup-decap-group .decap-number {
  position: absolute;
  top: 100px;
  right: 0px;
}

.cascade-prelayout-setup .setup-decap-group .decap-l {
  position: absolute;
  top: 60px;
  width: calc(100% - 10px);
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 5px;
}

.cascade-prelayout-setup .setup-decap-group .decap-r {
  position: absolute;
  top: 20px;
  width: calc(100% - 10px);
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 5px;
}


.cascade-prelayout-setup .setup-decap-item .decap-l {
  top: 35px;
}


.cascade-prelayout-setup .setup-decap-item .decap-r {
  top: 10px;
}

.cascade-prelayout-setup .setup-decap-item .decap-name {
  top: 65%;
  max-width: 120px;
  word-wrap: break-word;
  word-break: break-all;
}

.cascade-prelayout-setup .setup-decap-group .group-decaps>span {
  display: block;
}

.cascade-prelayout-setup .add-decap-group {
  position: absolute;
  left: calc(50% - 75px);
  top: 10px;
  color: #0d87f7;
  cursor: pointer;
}

.cascade-prelayout-setup .add-decap-group>i {
  margin-right: 10px;
}

.cascade-prelayout-decap-group-content {
  margin: 20px;
}

.cascade-prelayout-decap-group-content .decap-group-item {
  width: 100%;
  margin: 10px;
}

.cascade-prelayout-part-select .decap-group-item {
  width: 100%;
  margin: 10px 0px;
}

.cascade-prelayout-decap-group-content .decap-group-item .decap-group-item-title,
.cascade-prelayout-part-select .decap-group-item .decap-group-item-title {
  display: inline-block;
  width: 100px;
  font-weight: bold;
}

.cascade-prelayout-decap-group-content .decap-group-item .decap-group-item-input,
.cascade-prelayout-part-select .decap-group-item .decap-group-item-input {
  width: calc(100% - 110px);
}

.cascade-prelayout-decap-group-content .decap-group-item .decap-group-item-input .ant-input-group-addon {
  width: 50px;
}

.cascade-prelayout-decap-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.cascade-prelayout-decap-table .ant-table-small>.ant-table-content>.aurora-table-body {
  margin: 0 20px;
}

.cascade-prelayout-decap-delete-icon {
  position: absolute;
  right: -30px;
  margin-top: -5px;
  top: 48%;
  color: #ccc;
  cursor: pointer;
}

.cascade-prelayout-decap-delete-icon:hover,
.cascade-prelayout-decap-group-buttons .close-icon:hover {
  color: #0d87f7;
}

.cascade-prelayout-decap-table .ant-table-small>.ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 30px;
  position: relative;
}

.cascade-prelayout-decap-group-buttons {
  position: absolute;
  top: 10px;
  right: 15px;
}

.cascade-prelayout-decap-group-buttons .group-button {
  margin-left: 15px;
}

.cascade-prelayout-decap-group-buttons .hidden-button {
  visibility: hidden;
}

.cascade-prelayout-decap-group-buttons .close-icon {
  cursor: pointer;
}

.cascade-prelayout-part-select {
  margin: 20px;
}

.cascade-prelayout-setup .setup-ball .decap-l,
.cascade-prelayout-setup .setup-ball .decap-r,
.cascade-prelayout-setup .setup-ball .decap-c {
  position: absolute;
  top: 10px;
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 5px;
  font-size: 11px;
  z-index: 50
}

.cascade-prelayout-setup .setup-ball .select-decap-l,
.cascade-prelayout-setup .setup-ball .select-decap-r,
.cascade-prelayout-setup .setup-ball .select-decap-c {
  width: 120px;
  z-index: 100;
}

.cascade-prelayout-setup .setup-ball .decap-c {
  top: 50px;
}


.cascade-prelayout-setup .setup-ball .decap-l>input,
.cascade-prelayout-setup .setup-ball .decap-r>input,
.cascade-prelayout-setup .setup-ball .decap-c>input {
  width: 100px;
  height: 22px;
}

.cascade-prelayout-decap-error-name {
  color: #cb2431;
}

.cascade-prelayout-decap-error-icon {
  padding-left: 5px;
}

.cascade-prelayout-svg {
  width: 100%;
  height: calc(100% - 10px);
  margin-top: 5px;
  vertical-align: middle;
}

.cascade-prelayout-setup-dc {
  border: 1px solid #e9e9e9
}

.cascade-dc-pre-grid .grid-line {
  stroke: #e9e9e9;
  stroke-width: 1;
}

.cascade-dc-pre-comps .dc-comp .dc-comp-rect {
  stroke: rgba(0, 0, 0, 0.8);
  stroke-width: 3;
  cursor: pointer;
}

.cascade-dc-pre-comps .dc-comp .dc-comp-rect:hover,
.cascade-dc-pre-nets .dc-net:hover .net-line,
.cascade-dc-pre-nets .dc-gnd-net:hover .net-line {
  stroke: red;
}

.cascade-dc-pre-comps .dc-comp-name {
  font-size: 16px;
  font-weight: bold;
}

.cascade-dc-pre-nets .dc-net .net-line {
  stroke-width: 10;
  stroke: #b0b0e1;
  cursor: pointer;
}

.cascade-dc-pre-nets .dc-gnd-net .net-line {
  stroke-width: 10;
  stroke: #898989;
  cursor: pointer;
}

.cascade-dc-prelayout-drawer {
  position: absolute !important;
}

.cascade-dc-prelayout-drawer-content {
  padding: 0 10px;
  margin-bottom: 10px;
}

.cascade-dc-prelayout-drawer-content-title {
  display: inline-block;
  width: 100px;
  font-size: 14px;
  vertical-align: middle;
  line-height: 28px;
  margin-bottom: 5px;
}

.cascade-dc-prelayout-drawer-content-input {
  width: calc(100% - 110px);
}

.cascade-dc-prelayout-drawer .ant-drawer-body {
  padding: 16px;
}

.cascade-dc-pre-drawer-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.cascade-dc-prelayout-drawer-box {
  margin: 20px;
}
.cascade-top-template-create-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-top-template-create-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.cascade-top-template-create-panel-main {
  margin: 15px 20px;
}

.template-create-item,
.template-button-item {
  font-size: 14px;
  line-height: 28px;
  margin: 5px 0px;
  display: flex;
}

.template-create-item>span {
  width: 116px;
}

.template-create-item>.aurora-select {
  margin-left: 10px;
  width: calc(100% - 90px)
}

.template-button-item {
  justify-content: right;
}

.template-button-item .ant-btn {
  color: #2596f2;
  background-color: #fff;
  border-color: #2596f2;
}

.template-button-item .ant-btn:hover,
.template-button-item .ant-btn:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}
.monitor-error-msg {
  color: red;
}

.monitor-warning-msg {
  color: orange;
}

.monitor-error-main {
  margin-top: 10px;
}

.monitor-error-title {
  font-weight: bold;
  color: red;
}

.monitor-error-sub-content {
  margin-left: 12px;
}

.cascade-simulation-title .aurora-simulation-holygrail {
  display: flex;
}

.cascade-message-title {
  padding-left: 10px;
}

.cascade-monitor-title-box {
  display: flex;
  padding-right: 10px;
}

.cascade-simulation-title .span-msg-icon-div {
  margin-right: 10px;
  margin-top: 13px;
}

.cascade-simulation-title .aurora-simulation-progress {
  line-height: 42px
}

.cascade-simulation-title .aurora-simulation-span {
  line-height: 42px;
}

.cascade-monitor-title-box .aurora-simulation-span .aurora-span-msg {
  white-space: pre;
}
.cascade-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.cascade-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #ffffff;
}

.cascade-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.cascade-sider-menu-tree .tree-node .ant-tree-title {
  cursor: pointer;
}

.cascade-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-setup-title-color {
  vertical-align: middle;
  color: #0b3694;
}

.cascade-setup-title-right {
  float: right;
}

.cascade-setup-title-color-only {
  color: #0b3694;
}

.cascade-components-table .ant-table-content>.aurora-table-body tr>td {
  word-break: break-word;
}

.cascade-components-table td {
  position: relative;
}

.cascade-ir-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap,
.cascade-components-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap,
.cascade-decap-group-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  overflow: unset;
  height: auto;
  white-space: normal;
  padding: 0px 2px;
}

.cascade-components-table .ant-table-column-title {
  padding-left: 4px;
}

.cascade-components-table .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.cascade-components-table .editable-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9;
  background: #e6f7ff;
}

.cascade-components-table tbody>tr>td>span {
  display: block;
  max-height: 80px;
  overflow: auto;
}

.cascade-components-table tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.cascade-components-table .editable-cell-value-wrap .cascade-components-ignore-name {
  padding-left: 0px;
}

.cascade-component-split-row,
.cascade-component-split-row>td {
  background-color: #e6ffed !important;
}

.cascade-component-split-row:hover>td,
.cascade-component-split-row:hover .editable-cell-value-wrap {
  background-color: #e6ffed !important;
}

.cascade-component-split-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.cascade-component-select-row,
.cascade-component-select-row>td {
  background-color: #adddfd !important;
}

.cascade-component-select-row:hover>td,
.cascade-component-select-row:hover .editable-cell-value-wrap {
  background-color: #adddfd !important;
}

.cascade-component-select-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.cascade-component-merge-row,
.cascade-component-merge-row>td {
  background-color: #fffbdd !important;
}

.cascade-component-merge-row:hover>td,
.cascade-component-merge-row:hover .editable-cell-value-wrap {
  background-color: #fffbdd !important;
}

.cascade-component-merge-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.cascade-refresh-icon {
  color: #1890ff;
  margin-left: 15px;
  font-size: 15px;
  cursor: pointer;
}

.cascade-detail-tooltip,
.cascade-detail-tooltip .ant-tooltip-content .ant-tooltip-inner {
  width: -moz-fit-content;
  width: fit-content;
}

.cascade-text-editor-tooltip {
  max-height: 400px;
}

.cascade-detail-tooltip p {
  margin: 0;
  padding: 0;
}


.cascade-display-detail-box {
  padding: 10px;
  border: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  overflow: auto;
  max-height: 400px;
}

.cascade-display-detail-box>div {
  width: 100%;
  word-break: keep-all;
  white-space: nowrap;
  margin: 5px 0;
}

.cascade-display-detail-box>div:first-child {
  margin-top: 0;
}

.cascade-display-detail-box>div:last-child {
  margin-bottom: 0;
}

.cascade-detail-line {
  cursor: pointer;
}


.cascade-detail-line span,
.cascade-sense-detail-line>div span {
  line-height: 21px;
  height: 21px;
  display: inline-block;
}

.cascade-detail-line:hover,
.cascade-detail-line:hover span,
.cascade-sense-detail-line:hover>div span {
  background-color: #bae7ff
}

.cascade-power-select-panel {
  box-shadow: 2px 4px 7px 5px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.cascade-power-select-panel .power-select-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  width: 100%;
  display: inline-block;
}

.cascade-power-select-panel .power-select-content .power-select-apply {
  width: calc(100% - 100px);
  float: left;
  padding-top: 2px;
}

.cascade-power-select-panel .power-select-content .power-select-apply .power-select-apply-tip {
  margin-right: 20px
}

.cascade-power-select-panel .power-select-content .power-select-apply .power-select-apply-select {
  width: calc(100% - 85px);
}

.cascade-power-select-panel .power-select-comp-select {
  padding: 10px 16px;
  padding-bottom: 0px;
}

.cascade-power-select-panel .power-select-comp-select>span {
  display: inline-block;
  width: 150px
}

.cascade-power-select-panel .power-select-comp-select>.aurora-select {
  width: calc(100% - 160px);
}

.cascade-power-select-apply-select-dropdown {
  z-index: 2000;
}

.cascade-power-select-panel .ant-spin-nested-loading,
.cascade-power-select-panel .ant-spin-container {
  width: 100%;
  height: 100%;
}

.cascade-power-select-title {
  font-size: 16px;
  font-weight: 500;
}

.cascade-power-select-btn {
  height: 28px;
}

.cascade-power-select-panel .cascade-power-transfer-outside {
  display: inline-flex;
  width: 100%;
  height: 90%;
  padding: 16px;
}

.cascade-power-select-panel .cascade-power-transfer-outside-has-select {
  height: 80%
}

.cascade-power-transfer-outside .cascade-power-transfer {
  font-weight: 500;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.cascade-power-transfer-outside .ant-btn {
  margin: 10px 0px;
}

.cascade-power-select-panel .power-select-content-button {
  text-align: right;
  padding: 0;
  padding-bottom: 16px;
  padding-right: 16px;
}

.cascade-power-transfer .ant-transfer-operation {
  width: 25px;
}

.cascade-pcb-select-box {
  float: left;
  width: -moz-fit-content;
  width: fit-content;
}

.cascade-pcb-target-box {
  margin-left: 20px;
  display: inline-block;
  border-left: 1px solid #c7c7c7;
  padding-left: 20px;
}

.cascade-decap-default.ant-tag {
  position: absolute;
  top: 2px;
  right: 4px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
}

.cascade-custom-tag {
  position: absolute;
  top: 0px;
  right: -5px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
}

.cascade-library-default-decap-title-main:hover .cascade-decap-default,
.cascade-library-default-decap-title-main:hover .cascade-custom-tag {
  display: none;
}

.cascade-package-node {
  border-top: 1px solid;
  border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #e0e0e0, #e0e0e0, #e0e0e0, #ffffff) 1;
  padding-top: 2px !important;
}

.cascade-hidden-node {
  display: none;
}

.cascade-setup-normal {
  top: 45px;
}

.cascade-lr-content {
  top: 45px;
}

.cascade-lr-result {
  top: 0px;
}

.cascade-tb-setup-main,
.cascade-tb-result-main {
  position: absolute;
  top: 45px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.cascade-tb-result-main {
  top: 0px;
}

.cascade-tree-node-sign-off-template .cascade-sign-off-status-icon,
.tree-node-channel-name .cascade-sim-status-icon {
  position: absolute;
  right: 14px;
}

.cascade-tree-node-sign-off-template:hover .ant-tree-title .cascade-sign-off-status-icon,
.tree-node-channel-name .ant-tree-title:hover .cascade-sim-status-icon {
  right: 58px;
}

.cascade-tree-node-design-tree:hover .cascade-sim-status-icon,
.cascade-tree-node-design-tree .ant-tree-title:hover .cascade-sim-status-icon {
  right: 14px !important;
}

.cascade-tree-node-sign-off-template .cascade-sign-off-status-icon,
.tree-node-channel-name .cascade-sim-status-icon-hasCopy {
  position: absolute;
}

.cascade-tree-node-sign-off-template:hover .ant-tree-title .cascade-sign-off-status-icon,
.tree-node-channel-name .ant-tree-title:hover .cascade-sim-status-icon-hasCopy {
  right: 78px;
}

.cascade-tree-node-design-tree:hover .cascade-sim-status-icon-hasCopy,
.cascade-tree-node-design-tree .ant-tree-title:hover .cascade-sim-status-icon-hasCopy {
  right: 26px !important;
}

.cascade-tree-node-design-tree .ant-tree-title:hover .cascade-tree-channel-name {
  width: calc(100% - 30px);
}

/* == cascade-search-library-panel == */
.cascade-search-library-panel {
  box-shadow: 2px 4px 7px 5px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.cascade-search-library-panel .search-library-content {
  padding: 0px 16px;
  border-radius: 6px 6px 0px 0px;
}

.cascade-search-library-title {
  font-size: 16px;
  font-weight: 500;
}

.cascade-search-library-panel .search-library-content-item {
  margin: 10px 0px;
  height: 28px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
}

.cascade-search-library-panel .search-library-content-item>span {
  width: 50px;
}

.cascade-search-library-panel .search-library-content-item .search-library-input {
  width: calc(100% - 140px);
  height: 28px;
  line-height: 28px;
  margin: 0px 10px;
}

.cascade-search-library-panel .search-library-content-item .search-library-content-button {
  width: 70px;
  text-align: right;
}

.cascade-search-library-panel .search-library-content-item .search-library-content-button .search-library-btn {
  height: 28px;
}

.cascade-decap-re-match-icon {
  color: #1890ff;
  margin-left: 20px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 14px;
}

.cascade-decap-model-title {
  vertical-align: middle;
}

.cascade-decap-model-assigning {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  color: #1890ff;
}

.cascade-ball-size-content {
  width: 100%;
  padding: 10px;
}

.cascade-ball-size-line {
  width: 100%;
  padding: 5px;
}

.cascade-ball-size-line .cascade-ball-size-span {
  width: 120px;
  display: inline-block
}

.cascade-ball-size-line .cascade-ball-size-not-group-span {
  width: 200px;
  display: inline-block;
}

.cascade-ball-size-line .cascade-ball-size-question-circle-icon {
  margin-left: 6px;
  color: #f13140;
  font-size: 14px;
}

.cascade-ball-size-line .cascade-ball-size-input {
  width: calc(100% - 140px);
}

tbody>tr>td .cascade-target-actual-value-span {
  color: #0d76e4;
  text-indent: 4px;
}

tbody>tr>td .cascade-target-actual-error-value {
  color: #e51212;
}

.cascade-position-relative {
  position: relative;
}

.cascade-ignore-color {
  color: #e65b5b
}

.cascade-component-model-info-icon {
  position: absolute;
  right: 12px;
  top: calc(50% - 6px);
  color: #1890ff;
}

.cascade-power-tree-detail-tree-title {
  font-weight: bold;
  display: inline-block;
}

.cascade-power-tree-detail-tree-success {
  color: green
}

.cascade-power-tree-detail-tree-error {
  color: red
}

.cascade-power-tree-detail-tree-warning {
  color: orange
}

.cascade-power-tree-detail-tree-normal {
  color: rgba(0, 0, 0, 0.6)
}

.cascade-power-tree-detail-tree-empty {
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  height: 20px
}

.cascade-page-advanced-title {
  color: #8c8c8c;
  font-size: 12px;
}

.cascade-page-advanced-title>span {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.cascade-page-advanced-title>i {
  cursor: pointer;
}

.power-select-content-button .power-select-switch {
  float: left;
  margin-left: 18px;
  height: 28px;
  line-height: 28px;
}

.power-select-content-button .power-select-switch>span,
.power-select-content-button .power-select-switch>button {
  vertical-align: middle;
}

.cascade-table-row-disabled {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f3f3f3;
  cursor: not-allowed;
  text-indent: 13px;
  line-height: 38px;
  color: rgb(0, 0, 0, 0.45);
}

.cascade-pin-map-file-select-radio {
  display: flex;
  font-weight: bold;
  align-items: center;
  height: 32px;
  flex: none;
  width: 170px;
}

.cascade-pin-map-file-select-radio-div {
  display: inline-flex;
  align-items: center;
}

.cascade-pin-map-select-content {
  margin: 10px;
}

.cascade-pin-map-select-library-select {
  width: 300px !important;
}

.cascade-pin-map-select-library-select-dropdown {
  z-index: 2001;
}

.cascade-pin-map-file-select-table {
  padding: 0 20px;
}

.cascade-pin-map-select-library-span {
  width: 300px;
  display: inline-block;
  height: 26px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 0px 5px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cascade-pin-map-select-library-span:hover {
  border: 1px solid #4096ff;
}

.cascade-pin-map-select-library-span-disabled {
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
}

.cascade-pin-map-select-library-span-disabled:hover {
  border: 1px solid #d9d9d9;
}
.ant-dropdown {
  z-index: 2001;
}

.ant-popover .ant-popover-inner {
  padding: 0px;
}

.ant-color-picker .ant-popover-inner {
  padding: 10px
}

.ant-input-group-addon .ant-select .ant-select-selector {
  padding: 0 5px;
}
