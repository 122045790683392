.connector-model-select-panel {
  top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.connector-model-select-panel .panel-body {
  overflow: auto
}

.connector-model-select-main {
  padding: 8px 14px;
  min-height: 200px;
  min-width: 750px;
  overflow: auto;
}

.connector-model-select-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.connector-model-select-content .connector-model-select-item {
  min-height: 28px;
  line-height: 28px;
  height: fit-content;
}

.connector-model-select-item>span {
  width: 140px;
}

.connector-model-select-main .multi-connector-model-file-content>span {
  width: 154px;
}

.connector-model-select-main .multi-connector-model-file-content>.connection-model-select {
  width: calc(100% - 154px);
}

.connector-model-select-item>.connection-model-select {
  width: calc(100% - 140px);
  height: fit-content;
  /* min-height: 28px; */
}

.connector-model-select-ports-main {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* height: 100px; */
  border-bottom: 1px solid #eee;
  background-color: #eee;
  padding: 10px;
}

.connector-model-port-signal,
.connector-model-port-copy-signal {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* height: 80px; */
  text-align: center;
  /* line-height: 80px; */
  color: #005a9e;
}

.connector-model-port-copy-signal .aurora-select {
  width: calc(100% - 20px);
  margin: 0px 10px;
}

.connector-model-port-pins,
.connector-model-port-copy-pins {
  /* height: 80px; */
  width: calc(100% - 200px);
  margin-left: 200px;
}

.connector-model-port-pin {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 28px;
  padding: 5px 0px;
  position: relative;
}

.connector-model-port-pin-title {
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-align: left;
  text-indent: 10px;
  line-height: 28px;
  color: #005a9e;
  display: inline-block;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
  max-width: 150px;
}

.connection-model-pin-input-div {
  flex: 0 0 auto;
  text-align: center;
  max-width: 100px;
  width: 90px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  /*  vertical-align: top; */
  background: #eee;
}

.connection-pin-input-pin-port .connection-pin-input-pin-port-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  width: calc(100% - 48px);
  display: inline-block;
  vertical-align: middle;
}

.connection-model-pin-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 100px;
  width: 90px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
  background: #ffffff;
}

.connector-model-select-pins-content {
  padding: 6px 10px;
  background-color: #eee;
  border-radius: 4px;
}

/* port popover */

.connection-model-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.connection-model-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.connection-model-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.connection-model-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.connection-model-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.connection-model-port-list-body-with-search {
  height: 100%;
}

.connection-model-port-list-body-search-wrapper {
  width: 100%;
}

.connection-model-port-list-body-search-wrapper .ant-input-affix-wrapper,
.connection-model-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  /* height: 28px;
  line-height: 28px; */
  font-size: 13px;
}

.connection-model-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.connection-model-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.connection-model-port-list-ul>li:hover {
  background-color: #cfedff;
}

.connection-model-port-list-ul>.connector-current-pin-selected-port-li:hover {
  background-color: #93e6ff;
}

.connection-model-port-list-ul>.connector-port-li-selected,
.connection-model-port-list-ul>.connector-port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.connection-model-port-list-ul>.connector-current-pin-selected-port-li {
  background-color: #93e6ff;
}

.connection-model-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
}

.connection-model-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.connection-model-pin-input-div:hover .connection-model-pin-node-delete-icon {
  display: inline-block;
}

.connector-model-select-copy-group {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 6px;
  margin: 6px 0px;
  position: relative;
}

.connector-model-select-copy-icon {
  color: #3da4fa;
  margin-top: 7px;
  cursor: pointer;
  margin-left: 10px;
}

.connector-model-select-item>.connector-model-select-span {
  width: 196px;
}

.connector-model-select-delete-group-icon {
  position: absolute;
  right: 0px;
  top: calc(50% - 25px);
  height: 50px;
  width: 16px;
  line-height: 50px;
  background: #b6d9f6;
  color: #3da4fa;
  cursor: pointer;
  display: none;
  transition: 0.3s;
}

.connector-model-select-copy-group:hover .connector-model-select-delete-group-icon {
  display: block;
}

.connector-model-port-pin .connection-pin-long-line {
  right: 2px;
}

.connector-selected-apply-signals {
  color: rgb(109, 109, 109);
  background-color: #ececec;
}

.connector-model-pin-type-span {
  font-size: 16px;
  height: 28px;
  text-align: center;
  display: inline-block;
  line-height: 28px;
  color: #005a9e;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

.connector-model-port-pin-content {
  min-width: 74px;
  height: 28px;
  background: #eee;
  position: relative;
  z-index: 1;
  padding-left: 4px;
}

.connector-model-select-delete-icon {
  position: absolute;
  right: 0px;
  top: 8px;
  font-size: 12px;
  width: 12px;
}

.connector-model-select-main .multi-connector-model-file-content>.connector-model-select-delete-icon {
  width: 12px;
}

.connector-model-select-item:hover .connector-model-select-delete-icon {
  color: #0d87f7;
}

.connector-model-select-main .multi-connector-model-file-content>.connection-model-select {
  width: calc(100% - 176px);
}

.connector-model-auto-match-icon {
  display: flex;
  justify-content: center;
  color: #005a9e;
}

.connector-model-auto-match-icon>.iconfont.icon-xinpian_chip {
  margin-left: 10px;
  cursor: pointer;
}

.connection-v1-model-select-content .aurora-circle-tag {
  bottom: 2px;
}