.waveFrom-post-process-measure-sweep-item {
  border-radius: 4px;
  margin: 4px 0px;
  padding: 0px 6px;
  padding-bottom: 4px;
}

.waveFrom-post-process-measure-sweep-item>.waveFrom-post-process-measure-sweep-item-title {
  font-weight: 500;
  font-size: 16px;
  margin-left: 4px;
  margin-bottom: 4px;
}

.measurement-footer-apply-all-content {
  padding-top: 6px;
  height: 34px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
}

.measurement-footer-apply-all-title {
  width: 260px;
  text-align: left;
}

.measurement-footer-apply-all-content>.aurora-select {
  width: calc(100% - 330px);
}

.measurement-footer-apply-content .measurement-result-footer-apply-all-content>.aurora-select {
  width: calc(100% - 240px);
}

.measurement-footer-apply-content .measurement-result-footer-apply-all-content .measurement-footer-apply-all-title {
  width: 240px;
  text-align: left;
}

.measurement-footer-apply-all-content>.aurora-select.ant-select-multiple .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item {
  height: 22px;
  line-height: 20px;
}

.measurement-footer-apply-all-content>.aurora-select.ant-select-multiple .ant-select-selection-overflow-item-suffix {
  min-height: 20px;
}

.measurement-footer-apply-all-content>.aurora-select.ant-select-multiple .ant-select-selector {
  padding-block: 0px;
}

.measurement-footer-apply-all-content>.aurora-select.ant-select-multiple .ant-select-selection-wrap {
  height: 26px;
}

.waveFrom-post-process-measure-sweep-content {
  padding: 8px 12px;
  position: relative;
}

.waveFrom-post-process-measure-sweep-content .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px;
  color: rgba(0, 0, 0, 0.85);
}

.waveFrom-post-process-measure-sweep-content .ant-collapse {
  background: #ffffff;
}

.waveFrom-post-process-measure-sweep-content .ant-collapse-borderless>.ant-collapse-item {
  border-bottom: none;
  margin-bottom: 6px;
  background: #f1f1f1;
}

.waveFrom-post-process-measure-sweep-left {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 120px;
  min-height: 200px;
  border: 1px solid #eee;
  background-color: #fff;
}

.waveFrom-post-process-measure-sweep-checkbox {
  margin-right: 8px;
}

.waveFrom-post-process-measure-sweep-progress {
  display: inline-block;
  max-width: 60%;
  width: 300px;
  margin-left: 12px;
}

.measurement-footer-sweep-warning-icon {
  margin-left: 0px;
  margin-right: 12px;
  color: #ff6b00;
  font-size: 14px;
  cursor: pointer;
}