.result-waveform-setting .waveform-setting-row {
  margin: 5px 0px;
}

.result-waveform-setting .axis-title,
.result-waveform-setting .input-lable {
  font-weight: bold;
  margin-right: 8px;
}

.result-waveform-setting .reload-btn {
  color: rgba(0, 0, 0, 0.65);
  border: 0px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-left: 4px;
}

.result-waveform-setting .setting-input {
  width: 60%;
  margin-left: 2px;
  color: #2e8bdd;
}

.result-waveform-setting .result-waveform-setting-select {
  width: calc(100% - 74px);
}

.result-waveform-setting .waveform-scale-label {
  display: inline-block;
  width: 40px;
}