.pmic-part-search-layout {
  width: 100%;
  height: 100%;
}

.pmic-part-search-loading {
  width: 100%;
  height: 100%;
}

.pmic-part-search-sider {
  background-color: #f9f9f9;
}

.pmic-part-search-content {
  background-color: #fff;
  position: relative;
}

.pmic-part-search-body {
  height: 100%;
  overflow-x: auto;
}

.pmic-part-search-menu {
  height: calc(100% - 42px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f9f9f9;
}

.pmic-part-search-menu.ant-menu .ant-menu-item {
  margin-bottom: 0px;
  height: 32px;
  line-height: 32px;
}

.pmic-part-search-border {
  width: calc(100% - 20px);
  margin: 10px;
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.pmic-part-search-partname {
  height: fit-content;
  display: flex;
}

.pmic-part-search-partname-title {
  color: #0b3694;
  font-weight: bold;
  display: inline-block;
  width: 100px;
}

.pmic-part-search-partname-item {
  display: inline-block;
  width: calc(100% - 105px);
  font-weight: 500;
}

.pmic-part-search-pinmap {
  height: fit-content;
}

.pmic-part-search-collapse {
  margin: 15px 0px;
}

.pmic-part-panel-extra-icon .anticon {
  margin: 0 5px;
}

.pmic-part-panel-extra-icon .anticon:last-child {
  margin-right: 0px;
}

.pmic-part-panel-extra-icon .anticon:hover,
.pmic-part-add-pinmap-icon:hover,
.pmic-part-search-partname-delete-icon:hover {
  color: #40a9ff;
  cursor: pointer;
}

.pmic-part-click-tip-editable-cell {
  color: #9f9f9f;
}

.pmic-part-click-tip-editable-cell:hover {
  color: #40a9ff;
}

.pmic-part-power-transfer .ant-transfer-operation {
  margin: 0 5px;
  width: 25px
}

.pmic-part-power-select-panel {
  box-shadow: 2px 4px 7px 5px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.pmic-part-power-select-panel .power-select-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  width: 100%;
  display: inline-block;
}

.pmic-part-power-select-panel .power-select-content .power-select-apply {
  width: calc(100% - 100px);
  float: left;
  padding-top: 2px;
}

.pmic-part-power-select-panel .power-select-content .power-select-apply .power-select-apply-tip {
  margin-right: 20px
}

.pmic-part-power-select-panel .power-select-content .power-select-apply .power-select-apply-select {
  width: calc(100% - 85px);
}

.pmic-part-power-select-panel .power-select-comp-select {
  padding: 10px 16px;
  padding-bottom: 0px;
}

.pmic-part-power-select-panel .power-select-comp-select>span {
  display: inline-block;
  width: 150px
}

.pmic-part-power-select-panel .power-select-comp-select>.aurora-select {
  width: calc(100% - 160px);
}

.pmic-part-power-select-apply-select-dropdown {
  z-index: 2000;
}

.pmic-part-power-select-panel .ant-spin-nested-loading,
.pmic-part-power-select-panel .ant-spin-container {
  width: 100%;
  height: 100%;
}

.pmic-part-power-select-title {
  font-size: 16px;
  font-weight: 500;
}

.pmic-part-power-select-btn {
  height: 28px;
}

.pmic-part-power-select-panel .pmic-part-power-transfer-outside {
  display: inline-flex;
  width: 100%;
  height: 90%;
  padding: 16px;
}

.pmic-part-power-select-panel .pmic-part-power-transfer-outside-has-select {
  height: 80%
}

.pmic-part-power-transfer-outside .pmic-part-power-transfer {
  font-weight: 500;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* .pmic-part-power-transfer-outside .ant-btn {
  margin: 20px 0px;
} */

.pmic-part-power-select-panel .power-select-content-button {
  text-align: right;
  padding: 0;
  padding-bottom: 16px;
  padding-right: 16px;
}

.pmic-part-pin-map-content-table .ant-table-small>.ant-table-content>.ant-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 28px;
  position: relative;
}

.pmic-part-search-input-search {
  padding: 5px;
  border-right: 1px solid #e8e8e8;
}

.pmic-part-search-pinmap-title {
  width: 60px;
}

.pmic-part-panel-header-input {
  width: calc(100% - 120px);
}


.pmic-part-panel-header-title {
  display: inline-flex;
  width: calc(100% - 100px);
}

.pmic-part-panel-extra-icon {
  margin-top: 5px;
  display: inline-flex;
}

.pmic-part-panel-header-title>span {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pmic-part-pin-map-table {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  position: relative;
  margin: 20px;
}

.pmic-part-pin-map-table .pmic-part-table-content {
  margin-top: 10px;
}

.pmic-part-quick-setup-title-icon {
  font-size: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.pmic-part-quick-setup-title {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.pmic-part-quick-setup-radio {
  display: flex;
  align-items: center;
  flex: 1
}

.pmic-part-quick-setup-radio-content>span {
  display: inline-block;
  width: 40px;
  text-align: center;
}

.pmic-part-quick-setup-radio-content>span:last-child {
  border-left: 1px solid #9f9f9f;
}

.pmic-part-quick-setup-radio-group {
  display: inline-flex;
  margin-top: 10px;
  width: 100%;
}

.pmic-part-quick-setup-radio-group-button {
  text-align: right;
  margin-top: 10px;
  width: 100%;
}

.pmic-part-quick-setup-radio-group-button>button {
  margin: 0 5px;
}

.pmic-part-search-menu-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pmic-part-select-map-checkbox {
  margin-right: 5px;
}

.pmic-part-search-collapse .ant-collapse-header-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}