.connection-v1-model-assign-content {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  position: relative;
}

.connection-v1-left-channel {
  justify-items: flex-start;
  width: 36%;
  min-width: 300px;
  min-height: 100px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: relative;
}

.connection-v1-right-channel {
  justify-items: flex-end;
  width: 36%;
  min-width: 300px;
  min-height: 100px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: relative;
}

.connection-v1-model-assign-content .connection-v1-component-name {
  font-size: 14px;
  font-weight: 600;
  max-width: 100%;
  padding: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.connection-v1-model-assign-content .connection-v1-pin-left-ul {
  position: absolute;
  /* right: -106px; */
  right: -75px;
  top: 100px;
}

.connection-v1-model-assign-content .connection-v1-pin-right-ul {
  position: absolute;
  /* left: -86px; */
  left: 0px;
  top: 100px;
}

.connection-v1-component-select-content {
  margin: 6px 14px;
  position: relative;
  margin-right: 4px;
}

.connection-v1-component-select-content>.connection-v1-component-select-title {
  width: 134px;
  display: inline-block;
  color: #005a9e;
}

.connection-v1-component-select-content .connection-v1-model-select-div {
  width: calc(100% - 176px);
  display: inline-block;
  cursor: pointer;
}

.connection-v1-component-select-content .connection-v1-model-select-div .connection-v1-select {
  width: 100%;
  color: rgba(0, 0, 0, 0.65);
}

.connection-v1-component-select-content .ant-select-disabled .ant-select-selection {
  background: #ffffff;
  cursor: pointer;
}

.connection-v1-component-select-content .ant-select-arrow .ant-select-suffix {
  display: none;
}

.connection-v1-component-select-content .connection-v1-model-select-content>.connection-v1-model-select-title {
  width: 120px;
  display: inline-block;
  color: #005a9e;
}

.connection-v1-cable-model-content .connection-v1-component-select-content>.connection-v1-component-select-title {
  width: 106px;
}

.connection-v1-cable-model-content {
  background: #eee;
  border: 1px solid #ccc;
  width: calc(28% - 20px);
  margin: 0px 10px;
  border-radius: 4px;
}

.connection-v1-cable-model-none-content {
  background: #ffffff !important;
  border: none !important;
}

.connection-v1-file-error .connection-v1-select {
  color: #f30518 !important;
}

.connection-v1-file-error {
  position: relative;
}

.connection-v1-file-error .aurora-file-check-icon {
  right: 96px;
  top: 14px;
}

.connector-select-dropdown-menu {
  z-index: 10000000000;
  max-height: 300px !important;
  overflow: auto;
}

.connection-v1-model-component-select-content {
  position: absolute;
  left: 14px;
  right: 14px;
  top: 38px;
}

.connector-model-close-file-icon {
  color: #3da4fa;
  font-size: 14px;
  position: absolute;
  right: -12px;
  top: 8px;
  display: none;
}

.connection-v1-component-select-content:hover .connector-model-close-file-icon {
  display: inline-block;
}

.connection-v1-model-assign-content .aurora-model-name-error-msg {
  margin: 0px 14px;
  margin-right: 24px;
  padding: 4px 10px;
}

.connection-v1-select {
  width: calc(100% - 120px);
}

.connection-v1-model-component-select-content>span {
  width: 120px;
  display: inline-block;
  color: #005a9e;
}

.connection-v1-model-select-content {
  /* position: relative; */
  display: inline-block;
  width: 100%;
}

.connection-v1-model-select-subckt-div {
  display: inline-block;
  width: calc(100% - 108px);
  margin-left: 34px;
  margin-top: 6px;
}

.connection-v1-model-select-content:hover .connection-v1-model-select-add-div {
  right: 1px;
  z-index: 100;
}

.connection-v1-model-select-add-div {
  position: absolute;
  right: -30px;
  width: 30px;
  background: #c2eafd;
  top: 1px;
  height: 26px;
  line-height: 26px;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: -222;
  text-align: center;
}

.connection-v1-model-select-add-div .anticon {
  color: #005a9e;
  line-height: 26px;
}

.connector-model-delete-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  font-size: 12px;
  transform: translateY(-50%)
}

.connection-v1-component-select-content:hover .connector-model-delete-icon {
  color: #0d87f7;
}

.connection-v1-model-auto-match-icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: #005a9e;
  z-index: 10;
}

.connection-v1-model-auto-match-icon>.iconfont.icon-xinpian_chip {
  margin-left: 10px;
  cursor: pointer;
}