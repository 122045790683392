.component-RLC-content {
  height: 100%;
  width: 100%;
  padding: 16px;
}

.component-RLC-item {
  width: 100%;
  height: 33.33%;
  padding: 10px 0px;
  position: relative;
}

.component-RLC-item-content {
  display: inline-block;
  width: 75%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  vertical-align: middle;
  padding: 0px 11px;
  cursor: pointer;
}

.package-RLC-item-content {
  padding: 0px 5px;
}

.component-RLC-title {
  font-size: 14px;
  display: inline-block;
  width: 25%;
  vertical-align: middle;
}

.component-RLC-item-select {
  width: 100%;
}

.component-RLC-item-input {
  border: none;
  height: 30px;
  /* float: left; */
  position: static;
  padding: 0;
  width: .75em;
  max-width: 100%;
  float: left;
}

.component-RLC-item-tag {
  cursor: pointer;
  margin-top: 4px;
  float: left;
}

.component-RLC-tags {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.component-RLC-item-input:hover,
.component-RLC-item-input:focus {
  border-right-width: 0px !important;
  border-width: 0px;
  box-shadow: 0px 0px 0px #ffffff;
  border-color: #fff;
}

.component-RLC-item-content .component-RLC-tags .component-RLC-item-tag .anticon-close {
  font-size: 10px;
}

.component-RLC-item-input-box {
  width: auto;
  float: left;
  height: 30px;
}

.component-RLC-sub-content {
  margin-left: 12px;
}

.component-RLC-sub-title {
  font-weight: bold;
}

.component-PMIC-panel {
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.component-PMIC-item-content {
  width: 75%;
  border-radius: 4px;
}

.component-RLC-divider {
  margin: 10px 0px;
}

.component-apply-title {
  font-size: 14px;
  display: inline-block;
  width: 265px;
  vertical-align: middle;
}

.component-apply-checkbox {
  vertical-align: middle;
}

.component-pcb-passive-table,
.component-pcb-pin-map-table {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  position: relative;
}

.component-pcb-power-nets-table {
  height: 100%;
  min-width: 806px;
  position: relative;
}

.component-pcb-pin-map-table {
  margin: 20px;
}

.component-pmic-pin-library-value {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  position: relative;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px 5px;
  cursor: pointer;
  height: 28px;
}

.component-pmic-pin-library-value:not(.buck-converter):hover {
  border-color: #d9d9d9;
}

.component-pmic-pin-title-color {
  vertical-align: middle;
  color: #0b3694;
}

.comp-pmic-pin-edit-icon {
  font-size: 16px;
  color: #1ca4f3;
  position: absolute;
  right: 3px;
  top: 6px;
}

#components-setting-dialog,
#components-setting-dialog .ant-select-dropdown {
  z-index: 2000
}

.component-stuff-content {
  width: 100%;
  height: 90%;
  height: 472px;
}

.component-stuff-content .components-stuff-transfer,
.component-stuff-content .aurora-transfer {
  font-weight: 500;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.component-stuff-content .components-stuff-transfer .ant-transfer-list,
.component-stuff-content .aurora-transfer .ant-transfer-list {
  width: 100%;
  height: 100%;
}

.component-stuff-content .components-stuff-transfer.ant-transfer-customize-list .ant-transfer-list {
  width: 48%;
  height: 100%;
}

.component-stuff-content .ant-table-small .ant-table-container .aurora-table-body>table>.aurora-table-body>tr>td {
  background: white;
  padding: 6px 0 !important;
  border: none;
}

.component-stuff-content .anticon.component-stuff-upload-icon {
  margin-right: 10px;
  color: #1890ff;
}

#component-stuff-buck-content {
  position: absolute;
  height: 200px;
  width: 100%;
  overflow: auto;
  margin-top: 34px;
  z-index: 2002;
  background-color: #fff;
  overflow-anchor: none;
  box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.15);
}

.component-buck-list-item {
  display: inline-block;
  padding: 10px;
  width: 100%
}

.component-buck-list-select {
  width: 100px;
  display: inline-block;
}

.component-buck-list-divider {
  width: calc(100% - 240px);
  margin: 14px 5px;
  min-width: unset;
  display: inline-block;
  background-color: #1ca4f3;
}

.component-buck-list-select-dropdown {
  z-index: 100000;
}

.component-buck-list-item .component-buck-delete-icon {
  margin-left: 10px;
}

.component-buck-list-item .component-buck-delete-icon:hover {
  color: #1ca4f3;
}

.component-PMIC-panel .panel-body {
  position: relative;
}

.pin-connection-select-dropdown-menu {
  z-index: 2001 !important;
}

.component-connector-item-content .ant-select-selection--multiple .ant-select-selector {
  padding: 0 6px;
}

.component-classification-cascade-footer {
  width: 100%;
}

.component-classification-cascade-footer .left-button {
  position: absolute;
  left: 20px;
}

.component-animation-classfication-left {
  animation: moveLeft 0.5s;
}

@keyframes moveLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.component-animation-classfication-right {
  animation: moveRight 0.5s;
}

@keyframes moveRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.component-classification-cascade-footer .menu-button {
  position: absolute;
  left: 42%;
  padding-top: 4px
}

.component-classification-cascade-footer .menu-button .menu-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #cccccc;
  cursor: pointer;
  margin: 0 5px;
}

.component-classification-cascade-footer .menu-button .current-point {
  background-color: #1ca4f3;
}

.component-pin-map-add-row-icon {
  margin-left: 10px;
  cursor: pointer;
}

.component-pin-map-add-row-icon:hover {
  color: #1ca4f3;
}

.component-pmic-pin-library-cell {
  position: relative;
  height: 28px !important;
  overflow: hidden;
}

.component-pmic-pin-part-cell>div {
  margin-right: 30px;
}

.component-pmic-pin-part-cell .edit-part {
  color: #0b3694;
  cursor: pointer;
}

.component-pmic-pin-part-cell>.delete-icon {
  position: absolute;
  right: 2px;
  top: calc(50% - 6px);
  font-size: 12px;
  cursor: pointer;
}

.component-pmic-pin-part-cell>.add-icon {
  position: absolute;
  right: 22px;
  top: calc(50% - 7px);
  font-size: 13px;
  cursor: pointer;
}

.component-pmic-pin-part-cell>.delete-icon:hover,
.component-pmic-pin-part-cell>.add-icon:hover {
  color: #1ca4f3;
}

.component-pcb-passive-table tbody>tr>td,
.component-pcb-pin-map-table tbody>tr>td,
.component-pcb-power-nets-table tbody>tr>td {
  position: relative;
}

.classification-left {
  width: 205px;
}

.classification-left>ul {
  height: 100%;
}

.classification-right {
  width: calc(100% - 215px);
  margin-left: 15px;
  margin-bottom: 10px;
}

.classification-right .component-animation-classfication {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.classification-content {
  display: flex;
  height: 100%;
  max-height: 875px;
  overflow-x: hidden;
}

.component-pcb-power-search-net-content,
.component-pcb-transfer-search-net-content {
  width: 100%;
  display: flex;
  height: fit-content;
  padding-bottom: 8px;
}

.component-pcb-power-search-net-log {
  color: #1890ff;
  line-height: 26px;
  margin-left: 110px;
}

.component-pcb-transfer-search-net-content .aurora-tags-input-content {
  width: 100%;
  height: fit-content;
}

.component-pcb-power-search-net-content .ant-divider-vertical {
  margin: 0 4px;
  top: 8px;
}

.component-pcb-power-nets-table .ant-divider-vertical {
  margin: 0 8px;
}

.component-pcb-transfer-search-net-content .aurora-input {
  width: 100%;
  height: 28px;
}

.component-pcb-transfer-search-net-content .aurora-input .ant-input {
  font-size: 14px;
  height: 18px;
}

.component-pcb-transfer-voltage-content .aurora-input .ant-input {
  height: 28px;
}

.power-pcb-select-regular-input span.ant-input-group-addon:hover {
  cursor: pointer;
}

.power-pcb-select-regular-input span.ant-input-group-addon {
  background-color: #d6dadd;
}

.power-pcb-select-regular-input.ant-input-affix-wrapper .ant-input-suffix {
  right: 4px;
  background-color: #bee1ff;
  border-radius: 4px;
  font-size: 14px;
}

.power-pcb-select-regular-input.ant-input-affix-wrapper .ant-input-suffix div {
  padding: 0 6px;
}

.power-pcb-select-regular-input .regular-icon-selected {
  display: inline-block;
  position: absolute;
  right: 24px;
  height: 22px;
  line-height: 20px;
  padding: 0px 6px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 4px;
  background-color: #bee1ff;
}

.power-pcb-select-no-regular-input span.ant-input-group-addon {
  background-color: #fafafa;
}

.power-pcb-select-no-regular-input .regular-icon {
  display: inline-block;
  position: absolute;
  right: 24px;
  height: 20px;
  line-height: 20px;
  padding: 0px 6px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 4px;
}

.power-pcb-power-search-button {
  margin-left: 0;
  margin-top: 2px;
  font-size: 12px;
  min-width: 110px;
  line-height: 22px;
  padding: 0px 6px;
}

.power-pcb-power-search-log-button {
  margin-left: 2px;
  margin-top: 2px;
  font-size: 12px;
  min-width: 36px;
  line-height: 22px;
  padding: 0px 2px;
  margin-right: 4px;
}

.pcb-power-table-item .delete-icon {
  position: absolute;
  right: 2px;
  top: 12px;
  cursor: pointer;
  display: none;
}

.pcb-power-table-item .delete-icon:hover {
  color: #1890ff;
}

.pcb-power-nets-left-table .aurora-table-body>tr.ant-table-row-selected td,
.pcb-power-nets-right-table .aurora-table-body>tr.ant-table-row-selected td {
  background-color: #fff;
}

.pcb-power-nets-table .ant-table-container .aurora-table-body>table>.aurora-table-body>tr>td:nth-child(1) {
  text-align: center;
}

.pcb-power-nets-table .ant-table-container .aurora-table-body>table>.aurora-table-body>tr>td .pcb-power-table-item {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.component-RLC-content .custom-rlc-tabs .ant-tabs-ink-bar {
  width: 95px !important;
  margin-left: 22px;
}

.component-RLC-content .ant-tabs-nav .ant-tabs-tab {
  margin: 0 20px 0 0;
  padding: 12px 12px;
}

.component-RLC-content .aurora-top-bar-vertical-line {
  margin-right: 44px;
  vertical-align: text-bottom;
}

.component-pcb-power-nets-table .ant-table-content .ant-table-thead>tr>th {
  position: relative;
}

.power-nets-table-content {
  overflow: auto;
  height: 300px;
}

.power-nets-table-item {
  height: 28px;
  line-height: 28px;
}

.power-nets-table-target-item {
  height: 28px;
  line-height: 28px;
  border: 1px solid #ccc;
  border-top: none;
}

.component-pcb-power-nets-table .ant-transfer {
  height: 100%;
}

.ant-transfer.ant-transfer-customize-list .ant-transfer-list {
  width: 48%;
  height: 100%;
}

.component-pcb-power-nets-table .pcb-power-net-search-checkbox {
  line-height: 28px;
  font-size: 12px;
  min-width: 196px;
  padding-left: 10px;
}

.component-pcb-power-nets-table .pcb-power-net-search-checkbox .ant-checkbox+span {
  padding-right: 0px;
  padding-left: 6px;
}

.pcb-power-nets-filter-icon,
.pcb-power-nets-log-icon {
  height: 26px;
  line-height: 26px;
  margin-right: 10px;
  color: #1890ff;
}

.pcb-power-nets-log-icon {
  margin-right: 2px;
}

.pcb-power-nets-filter-icon-select,
.pcb-power-nets-log-icon-select {
  color: #2b98ff;
}

.pcb-power-nets-left-table .ant-table-small .ant-table-container .aurora-table-body>table>.aurora-table-body>tr>td {
  border: none;
}

.pcb-power-nets-clear-icon {
  position: absolute;
  right: 48px;
  height: 26px;
  line-height: 26px;
  margin: 2px;
  color: #bebebe;
  display: none;
}

.power-pcb-select-regular-input:hover .pcb-power-nets-clear-icon,
.power-pcb-select-no-regular-input:hover .pcb-power-nets-clear-icon {
  display: block;
  color: #9f9f9f;
}

/* .component-pcb-power-nets-table .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  border-right: 0;
} */

.pcb-power-nets-left-table .ant-table-bordered .ant-table-thead>tr>th {
  border-right: 0;
}

.component-pcb-power-nets-table.ant-transfer-customize-list .ant-table-wrapper .ant-table-small>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th {
  background-color: #fff;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list:nth-child(1) .ant-transfer-list-body-search-wrapper {
  display: none;
}

.pcb-power-nets-search-icon {
  position: absolute;
  right: 4px;
  height: 22px;
  line-height: 22px;
  margin: 2px;
  color: #bebebe;
}

.pcb-power-nets-search-icon:hover {
  color: #1890ff;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-search {
  height: 28px;
  line-height: 28px;
}

.component-pcb-transfer-search-net-content .aurora-input .ant-input-suffix {
  cursor: pointer;
  width: 20px;
  right: 4px;
}

.component-pcb-transfer-search-net-content .aurora-input .ant-input-suffix .regular-icon {
  width: 20px;
  text-align: center;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper,
.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  padding: 8px;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  padding-bottom: 0px;
}

.component-pcb-power-nets-table .ant-transfer-customize-list .ant-transfer-list-search-action {
  top: 6px;
}

.component-pcb-power-nets-table .ant-transfer-list .ant-transfer-list-header-selected>span {
  font-weight: 500;
}

.component-pmic-pin-error-value {
  color: red;
}

.component-pmic-tooltip-here-span {
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
}

.passive-table-part-title {
  vertical-align: middle;
}

.passive-table-part-name-text {
  word-break: break-all;
}