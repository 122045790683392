/*TODO*/

.simulation-progress .ant-progress-inner {
  background: #ddd;
}

.simulation-progress .ant-progress-text {
  color: rgba(0, 0, 0, 0.6);
}

.setting-signal-expand {
  margin: 0px !important;
}

.setting-signal-expand .ant-table-small>.ant-table-content>.aurora-table-body {
  padding: 0px;
  margin: 0px;
}

.setting-signal-expand .ant-table-small,
.setting-signal-expand .ant-table-small.ant-table-bordered .ant-table-content {
  border: 0px !important;
}

.component-pin-tabs .ant-tabs-bar {
  margin: 0px;
}

.pintopin-signal-row {
  margin-top: 20px;
}

.component-pin-tabs .ant-tabs-tab {
  font-weight: bold;
}

.pintopin-warning-dialog {
  padding: 10px;
  border: 2px solid #ff9898;
  border-radius: 4px;
  background: #f9f9f9;
  position: absolute;
  top: 45px;
  bottom: 6px;
  width: 98%;
}

.pintopin-warning-title {
  color: #ff4848;
  margin-top: 10px;
  display: inline-block;
}

.footer-monitor {
  width: 99%;
  background: #f4f4f4;
  min-width: 980px;
}

.log-box {
  float: right;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.monitor-log {
  overflow: auto;
  border: 2px solid rgb(182, 182, 182);
  margin-top: 10px;
  background: #fafafa;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  top: 35px;
  left: -2px;
  right: -10px;
}

.error-box {
  width: 49%;
  height: 100%;
  position: absolute;
}

.closed-message {
  display: inline-block;
  width: 100%;
}

.debugButton {
  position: relative;
  z-index: 2;
}

.logButton {
  position: relative;
  z-index: 2;
}

.button-content {
  text-indent: 4px;
}

.button-icon {
  font-size: 18px;
}

.span-tooltip {
  display: inline-block;
  width: 100%;
}

.verify-footer-name {
  font-weight: bold;
  line-height: 45px;
  display: block;
  float: left;
}

.icon-explores {
  color: #0055a2;
  font-size: 20px;
  font-weight: bolder;
  font-family: serif;
  display: block;
  float: right;
  line-height: 20px;
}

.sierra-component-table .ant-table-thead>tr>th .ant-table-header-column,
.sierra-pin-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.pintopin-pin-corner-box {
  margin: -4px;
}

.pintopin-pin-corner {
  padding: 0 5px;
  line-height: 36px;
}

.pintopin-pin-corner-explor {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 6px;
  transition: 0.5s all;
  height: 14px;
  line-height: 16px;
}

.sierra-pin-table tbody>tr>td:nth-child(6),
.sierra-pin-table tbody>tr>td:nth-child(5),
.sierra-pin-table tbody>tr>td:nth-child(4),
.sierra-component-table tbody>tr>td:nth-child(4),
.sierra-component-table tbody>tr>td:nth-child(5),
.sierra-component-table tbody>tr>td:nth-child(6) {
  position: relative;
}

.sierra-component-table .ant-table-content>.aurora-table-body tbody>tr>td:nth-child(6) .editable-cell-value-wrap {
  position: relative;
}

.sierra-component-table tbody>tr>td:nth-child(6) .editable-cell-value-wrap .sierra-component-probes-td {
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 0px;
  top: 0px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pintopin-pin-corner-cell,
.pintopin-pin-stimulus-cell {
  margin-right: 26px !important;
  width: auto !important;
}

.component-pin-tabs .ant-tabs-tab,
.component-pin-tabs .ant-tabs-tab-active {
  font-weight: bold !important;
}

.log-button {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}

.download-button {
  text-indent: 24px;
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.info-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.title-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  float: left;
}

.pcb-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.verify-content-footer {
  overflow: auto;
}

.verify-footer-button {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.progress-footer-span {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
}

.debug-footer-col {
  padding-left: 30px !important;
  text-align: center;
}

.log-footer-col {
  padding-left: 5px !important;
  text-align: center;
}

.result-footer-col {
  padding-left: 0 !important;
  text-align: center;
}

.verify-footer-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.btn-width {
  width: 20%;
  max-width: 80px;
  margin-left: 10px;
  display: block;
  float: left;
}

.btn-width button {
  width: 100%;
}

.btn-width .btn-width-tooltip {
  width: 100%;
}

.monitor-button {
  min-width: 629px;
}

.monitor-top {
  margin: 0px;
  padding: 0px;
  line-height: 45px;
}

.monitor-top-col {
  padding: 0px;
}

.btn-width-12 {
  width: 12%;
}

.btn-width-max-120 {
  max-width: 110px;
}

.verify-cancel-btn {
  background: #797979;
  border: 0px;
  color: #f4f4f4;
}

.verify-cancel-btn:hover,
.verify-cancel-btn:focus {
  background: #969696;
  color: #ffffff;
}

.signal-expand-icon {
  position: absolute;
  right: 20px;
}

/* ============== PinToPin setting =================*/
.sierra-content-setting {
  min-width: 1200px;
  padding: 20px;
}

.sierra-multi-setup-content-setting {
  padding-top: 30px;
}

.sierra-sweep-content-setting {
  position: absolute;
}

.sierra-setup-title-color {
  color: #0b3694;
}

.sierra-content-block-border {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #a8c4e6;
}

.width-33 {
  width: 33%;
}

.width-25 {
  width: 25%;
}

.sierra-signal-nets-table {
  margin-top: 10px;
}

.sierra-select-dropdown-menu {
  position: absolute;
}

.sierra-select-dropdown-menu .ant-select-dropdown-menu-item {
  position: relative;
  padding-left: 30px;
}

.sierra-select-dropdown-menu .ant-select-dropdown-menu-item .ant-select-selected-icon {
  text-align: left !important;
  left: 10px !important;
  font-weight: bold;
}

.sierra-signal-nets-table .editable-cell-value-wrap,
.sierra-component-table .editable-cell-value-wrap,
/* .sierra-PG-table .editable-cell-value-wrap, */
.sierra-power-comps-table .editable-cell-value-wrap,
.sierra-pin-table .editable-cell-value-wrap,
.sierra-connector-table .editable-cell-value-wrap,
.sierra-sweeping-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.sierra-signal-nets-table .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.sierra-signal-nets-table tbody>tr>td>span {
  display: block;
  max-height: 80px;
  overflow: auto;
}

.sierra-signal-nets-table tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.sierra-signal-nets-table tbody>tr>td .ant-select-selection--multiple .ant-select-selector>ul>li {
  height: 24px;
  line-height: 24px;
}

.sierra-signal-nets-table tbody>tr>td #nets {
  position: relative;
}

.margin-top-20 {
  margin-top: 20px;
}

.sierra-pin-table .usage-repeater {
  padding: 0 5px;
}

.sierra-decap-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.sierra-decap-close {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.sierra-decap-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.sierra-model {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.sierra-model-last {
  padding-bottom: 5px;
}

.sierra-model-selection {
  float: left;
  width: 100%;
}

.sierra-model-input {
  margin-top: 5px;
}

.sierra-advance-panel {
  margin-top: 30px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-pcb-content {
  background: #f2f7fa;
  padding: 20px;
  padding-top: 0;
  position: relative;
  margin-top: 20px;
}

.sierra-pcb-menu {
  background: #f2f7fa;
  margin-left: 48px;
}

.sierra-component-table,
.sierra-pin-table,
.sierra-power-comps-table,
.sierra-content-setting .virtual-component-table {
  background: #ffffff;
}

.sierra-setup-pcb-title {
  position: absolute;
  left: 20px;
  top: 13px;
}

.padding-top-10 {
  padding-top: 10px;
}

.extraction-sub-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 8px;
}

.sierra-advance-panel .extraction-input .ant-input-group-addon {
  width: 68px;
}

.sierra-sub-extraction-box {
  margin-left: 26px;
  display: inline-block;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.sierra-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

.sierra-advance-content {
  /*  margin: 0px 10px 0 10px; */
  min-width: 810px;
}

.sierra-advance-title,
.sierra-optimization-title {
  font-weight: bold;
}

.sierra-advance-option {
  display: inline-block;
  padding: 0 20px;
  vertical-align: middle;
}

.sierra-advance-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 18px;
}

.sierra-extraction-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 18px;
}

.space-10 {
  margin-top: 10px;
}

/* extraction options */
.sierra-extraction-main {
  margin-left: 20px;
}

.sierra-extraction-content {
  line-height: 28px;
  margin-bottom: 5px;
}

.sierra-extraction-content .extraction-input,
.sierra-extraction-content .extraction-select {
  display: inline-block;
  width: 44%;
  vertical-align: middle;
  height: 28px;
}

.sierra-extraction-content .extraction-select {
  width: 53%;
}

.sierra-extraction-main .extraction-port-setup {
  margin-bottom: 14px;
}

.sierra-extraction-select-dropdown {
  z-index: 100000000;
}

.extraction-input .ant-input-group,
.extraction-input .ant-input-group .ant-input,
.extraction-input .ant-input-group .ant-input-group-addon,
.extraction-vrm-input .ant-input-group,
.extraction-vrm-input .ant-input-group .ant-input,
.extraction-vrm-input .ant-input-group .ant-input-group-addon {
  height: 28px;
}

.extraction-vrm-input {
  width: 40%;
}

.sierra-extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  padding-right: 10px;
}

.sierra-extraction-name {
  font-size: 16px;
  font-weight: 500;
}

.sub-sierra-extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  text-align: 20px;
  font-style: italic;
}

.sider-switch-icon {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  color: #737373;
}

/* sider button */
.sider-switch-vertical-icon {
  padding: 0 17px;
  padding-bottom: 4px;
}

.sider-switch-vertical-icon .pcb-icon {
  margin-left: -3px;
}

.sider-layout-div {
  display: inline-block;
}

.sider-layout-vertical-div {
  padding: 0 10px;
  padding-bottom: 4px;
}

.sider-layout-vertical-div .pdn-layout-button-1 {
  margin-bottom: 9px;
  margin-top: 3px;
}

.sider-layout-vertical-div .pdn-layout-button-2 {
  margin-bottom: 4px;
}

.icon-setup {
  font-size: 19px;
  cursor: pointer;
}

.pcb-icon {
  font-size: 25px;
  line-height: 32px;
  cursor: pointer;
}

.pdn-view-popover .ant-popover-inner-content {
  padding: 0;
}

.icon-Monitor {
  font-size: 20px;
  cursor: pointer;
}

.sierra-file-check-div {
  color: #f30518;
  position: relative;
}

.sierra-file-text {
  color: rgba(0, 0, 0, 0.65);
}

.sierra-file-check-span {
  position: absolute;
  right: 0px;
  top: 4px;
  width: 20px;
  height: 20px;
  background-color: #e0e0e0;
  color: #f30518;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
}

.sierra-file-check-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -9px;
  color: #f13140;
  font-size: 18px;
}

/* === extraction options === */

.sierra-extraction-panel-content {
  padding: 10px 16px;
  min-width: 800px;
  padding-bottom: 0px;
}

.sierra-extraction-panel-content .ant-divider-horizontal {
  margin: 10px 0px;
}

.sierra-extraction-panel-content .extraction-radio-group .ant-divider-horizontal {
  margin: 10px 0px;
}

.sierra-extraction-panel-content .extraction-radio-group {
  margin: 8px 0px;
  display: block;
}

.sierra-exc-options-crosstalk {
  display: inline-block;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.sierra-extraction-option-crosstalk-item {
  display: inline-block;
  margin: 8px 20px;
  position: relative;
  margin-bottom: 0px;
  margin-left: 33px;
}

.sierra-extraction-option-sub-item {
  margin: 0px 0px 0px 30px;
  position: relative;
  display: inline-block;
}

.sierra-extraction-option-sub-item .sierra-extraction-option-name {
  font-size: 13px;
}

.sierra-extraction-option-name {
  font-weight: 500;
  line-height: 28px;
  width: 80px;
  display: inline-block;
  font-size: 13px;
  vertical-align: middle;
}

.sierra-extraction-options {
  display: inline-block;
}

.sierra-extraction-options-main {
  margin-left: 14px;
  margin-bottom: 10px;
  font-size: 13px;
}

.sierra-extraction-options-main .sierra-extraction-content {
  margin: 4px 0px;
  margin-left: 10px;
  line-height: 28px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-content-body,
.sierra-extraction-options-main .sierra-extraction-content .comp-extraction-option-content-body,
.sierra-extraction-options-main .comp-extraction-option-content .comp-extraction-option-content-body {
  font-size: 13px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 150px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  height: 28px;
}

.sierra-extraction-options-main .extraction-input .ant-input-group .ant-input,
.sierra-extraction-options-main .sub-extraction-input .ant-input-group .ant-input {
  height: 28px;
}

.sierra-extraction-options-main .extraction-radio-clip-group {
  margin: 0px 10px;
  margin-left: 87px;
}

.sierra-extraction-options-main .extraction-radio-clip-group .extraction-clip-radio-item {
  vertical-align: middle;
  margin-left: 8px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input .ant-input-group-addon,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon {
  width: 56px;
  padding: 0px 6px;
  font-size: 13px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input .ant-input-group-addon .ant-select,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon .ant-select {
  font-size: 13px;
  height: 28px;
}

.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon .ant-select .ant-select-arrow {
  font-size: 10px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input .ant-input-group-addon .ant-select,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon .ant-select {
  height: 28px;
  line-height: 28px;
}

.sierra-extraction-options-main .sierra-extraction-content .extraction-input .ant-input-group-addon .ant-select .ant-select-selector,
.sierra-extraction-options-main .sierra-extraction-content .sub-extraction-input .ant-input-group-addon .ant-select .ant-select-selector {
  line-height: 26px;
  height: 26px;
}

.sierra-extraction-options-main .sub-extraction-input {
  margin-left: 10px;
}

.sierra-extraction-panel-content .sierra-extraction-options-main .sierra-extraction-gray {
  color: #9c9c9c;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-width {
  width: calc(100% - 378px);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-step-width {
  width: 112px;
}


.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-item {
  width: calc((100% - 300px) * 0.35);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-item-sub {
  width: calc((100% - 300px) * 0.3);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-exact-dc {
  margin-left: 87px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-body-width {
  width: 394px;
}

.sierra-extraction-options-main .sierra-extraction-content .comp-extraction-option-content-body {
  padding-left: 0px;
  width: 340px;
}

.sierra-extraction-options-main .sierra-extraction-content .comp-extraction-option-next-sub-span {
  padding-left: 20px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-maxF-input {
  width: calc(100% - 264px);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-width .ant-select-selection__clear {
  margin-top: -8px;
}

.sierra-pcbloading-icon {
  color: #1890ff;
  margin-left: 15px;
}

.sierra-signal-nets-table .select-width .label-input .ant-select-selection {
  border: none;
  background-color: #fafafa;
  padding: 0;
}

.select-width .label-input .ant-select-selection .ant-select-selector {
  margin-right: 15px;
}

.sierra-signal-nets-table .select-width .label-input .ant-select-selection .ant-select-arrow {
  top: 15px;
  right: 0px;
}

.sierra-pcb-replace-panel .sierra-pcb-replace-content {
  padding: 16px 16px 10px;
}

.sierra-pcb-replace-content .pcb-replace-box-title {
  font-weight: 500;
  margin-right: 10px;
  width: 96px;
  font-size: 13px;
  line-height: 28px;
}

.sierra-pcb-replace-content .pcb-replace-input-box {
  line-height: 32px;
  justify-content: center;
}

.sierra-pcb-replace-content .pcb-replace-select {
  width: calc(100% - 96px);
  height: 28px;
  line-height: 28px;
}

.sierra-pcb-replace-content .pcb-replace-button-box {
  display: flex;
  padding: 10px 16px 0;
  justify-content: space-around;
}

.sierra-pcb-replace-content .pcb-replace-button-box .ant-btn {
  height: 28px;
  width: 75px;
}

.sierra-pcb-replace-msg-box {
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px;
  max-height: 200px;
  overflow: auto;
}

.sierra-pcb-replace-msg-box .pcb-replace-msg {
  margin-bottom: 4px;
}

.sierra-pcb-replace-msg-box .pcb-replace-previewInfo {
  white-space: pre;
}

.sierra-select-label-content {
  position: relative;
}

.sierra-select-label-content .aurora-option-label-icon {
  position: absolute;
  right: 0px;
  top: 0px;
}


.sierra-content-setting .aurora-setup-border {
  background-color: #f2f7fa;
}

.advanced-select-pcb-item {
  padding: 0 32px;
}

.sierra-advance-content .ant-tabs-bar {
  margin: 0;
}

.sierra-advance-content .ant-tabs.ant-tabs-top .ant-tabs-nav {
  margin: 0px 16px;
}

.sierra-advance-content .extraction-ports-auto-setup .ant-tabs.ant-tabs-top .ant-tabs-nav {
  margin: 0px 0px;
}

.sierra-advance-content .sierra-extraction-content-item {
  padding: 0px 16px 10px;
}

.sierra-advance-content .sierra-extraction-content-item label.ant-checkbox-wrapper {
  width: 30px;
  text-align: left;
  line-height: 28px;
}

.sierra-extraction-content-item>span {
  line-height: 28px;
}

.sierra-advance-content .sierra-advance-apply-diver {
  margin: 10px 0px;
}

.sierra-extraction-options-main .extraction-content-hpc .sierra-extraction-content {
  padding-left: 10px;
}

.sierra-extraction-options-main .extraction-content-hpc .sierra-extraction-content .sierra-extraction-body-width {
  width: 330px;
}

.sierra-extraction-options-main .extraction-content-hpc .sierra-extraction-content .sierra-extraction-input-width {
  width: calc(100% - 358px);
}

.sierra-component-setting-update-content {
  text-align: start;
}

.sierra-component-setting-update-content .component-type-title {
  font-weight: 600;
}

.sierra-component-setting-update-content .sierra-component-setting-update-component {
  padding-left: 14px;
}

.component-type-pcb {
  padding-left: 6px;
}

.sierra-component-setting-update-component-content {
  max-height: 412px;
  overflow: auto;
  border: 1px dashed #bfbfbf;
  border-radius: 4px;
  padding: 6px;
  background: #f7f7f7;
}

.sierra-component-setting-update-panel .aurora-confirm-content {
  padding-bottom: 0px;
}

.sierra-component-connector-update-panel .aurora-confirm-content {
  padding-bottom: 0px;
  text-align: left;
}

.sierra-extraction-options-main .sierra-extraction-content.extraction-content-passive {
  width: 430px;
}

.sierra-extraction-options-main .extraction-content-passive .sierra-extraction-content-body {
  width: 394px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-title-width {
  width: 394px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-sub-span {
  padding-left: 20px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-width {
  width: calc(100% - 422px);
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-step-width {
  width: 112px;
}

.sierra-extraction-options-main .sierra-extraction-content .sierra-extraction-input-advanced {
  width: calc(100% - 446px);
  padding-left: 20px;
}

.sierra-extraction-options-main .comp-extraction-option-content .comp-extraction-option-sub-span {
  width: 384px;
  padding-left: 20px;
  padding-right: 0;
}

.sierra-extraction-options-main .comp-extraction-option-content .comp-extraction-option-content-input {
  width: calc(50% - 227px);
}

.sierra-extraction-option-default-content {
  margin-bottom: 14px;
}

.auto-assign-model-loading-icon {
  margin-left: 8px;
  color: #1890ff;
  cursor: pointer;
}


.sierra-comp-table-part-switch-icon {
  color: #3da4fa;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.sierra-comp-table-part-title {
  vertical-align: middle;
}

#sierra-pcb-connections-content {
  position: relative;
}

#sierra-pcb-connections-content .camera-icon:hover {
  color: #1890ff;
}

#sierra-pcb-connections-content .camera-icon {
  position: absolute;
  right: 12px;
  top: 8px;
  font-size: 18px;
}

.signal-interface-design-info-icon {
  color: #cb2431;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.signal-interface-design-loading-info-icon {
  color: #1890ff;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.sierra-replace-tooltip {
  max-width: 600px;
}

.pcb-replace-button-box {
  text-align: center;
  padding-top: 8px;
}

.pcb-replace-button-box .ant-btn {
  width: 75px;
  height: 28px;
  padding: 0px 10px;
}

.pcb-replace-button-box .ant-btn:nth-child(1) {
  margin-right: 10px;
}

.sierra-replace-tooltip .sierra-pcb-replace-msg-box .pcb-replace-previewInfo {
  padding-left: 6px;
}