.sierra-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.aurora-content-right {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.aurora-content-right>.ant-spin-nested-loading,
.aurora-content-right>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.aurora-content-top {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.aurora-content-top>.ant-spin-nested-loading,
.aurora-content-top>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.sierra-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.aurora-content .dividingLine {
  top: 0px;
}

.aurora-content>.ant-spin-nested-loading,
.aurora-content>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.sierra-setup-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #4e4e4e;
  z-index: 20;
  opacity: 0.5;
  border-radius: 5px 0 0 5px;
}

.sierra-setup-close .setup-close-icon {
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  cursor: pointer;
  opacity: 1;
}

.sierra-setup-close:hover {
  transition: 0.6s;
  opacity: 0.8;
}

.sierra-pcb-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #fff;
  opacity: 0.8;
  border-radius: 5px 0 0 5px;
}

.sierra-pcb-close .sierra-close-icon {
  font-size: 16px;
  color: #000;
  cursor: pointer;
  opacity: 1;
}

.sierra-pcb-close:hover {
  transition: 0.6s;
  opacity: 1;
}

.select-model {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.select-model-content {
  margin-right: 16px;
}

.select-model-last {
  padding-bottom: 5px;
}

.select-model-selection,
.pintopin--buffer-selection {
  float: left;
  width: 100%;
}

.select-dialog-close {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.select-dialog-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.select-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.signal-delete-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pintopin-power-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pintopin-signal-name-cell {
  margin-right: 22px !important;
  width: auto !important;
}

.sierra-PG-table tbody>tr>td:last-child,
.sierra-signal-nets-table tbody>tr>td:first-child {
  position: relative;
}

.sierra-PG-table tbody>tr>td:last-child>.editable-cell-value-wrap {
  position: unset;
}

.sierra-PG-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .pintopin-power-delete
.sierra-signal-nets-table .ant-table-small .ant-table-container .aurora-table-body .pintopin-signal-name-cell:hover .signal-delete-icon {
  color: #1890ff;
}

.sierra-PG-table .ant-table-small .ant-table-container .ant-table-header.ant-table-hide-scrollbar {
  padding-right: 22px;
}

.pintopin-channel-select {
  margin: 0 20px;
  width: 94px;
}

.sierra-table-comp {
  cursor: pointer;
  width: 100%;
}

.sierra-re-extraction {
  display: inline-block;
  padding-left: 34px;
  font-size: 13px;
  height: 28px;
  line-height: 28px;
}

.sierra-extraction-options-main .sierra-powesi-re-extraction{
  padding-left: 20px;
}

.sierra-re-extraction-switch {
  margin-left: 24px;
  margin-top: -2px;
}

.sierra-extraction-button {
  margin: 0px 20px 0px 10px;
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-top: -2px;
}

.sierra-extraction-option-error-msg {
  display: block;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 10px 16px 10px 20px;
}

.text-point-span {
  display: block;
  padding-left: 4px;
}

.simulation_options_error_tooltip .ant-tooltip-inner {
  color: #cb2431;
  background-color: #ffdce0;
  padding: 5px;
}

.simulation_options_error_tooltip .ant-tooltip-arrow::before {
  background-color: #ffdce0;
}

.simulation_options_error_tooltip {
  z-index: 3000;
  max-width: 300px;
}

.sierra-corner-row-disabled {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f3f3f3;
  cursor: not-allowed;
  text-indent: 13px;
  line-height: 38px;
  color: rgb(0, 0, 0, 0.45);
}

.sierra-component-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title,
.sierra-component-table .ant-table-thead>tr>th:nth-child(4) .ant-table-header-column .ant-table-column-title,
.sierra-component-table .ant-table-thead>tr>th:nth-child(5) .ant-table-header-column .ant-table-column-title,
.sierra-pin-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title,
.sierra-pin-table .ant-table-thead>tr>th:nth-child(4) .ant-table-header-column .ant-table-column-title,
.sierra-pin-table .ant-table-thead>tr>th:nth-child(5) .ant-table-header-column .ant-table-column-title {
  padding-left: 4px
}

.sierra-component-arrow {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #969696;
  width: 0;
  height: 0;
  position: absolute;
  right: 26px;
  bottom: 6px;
}

.sierra-comp-usage-rlc {
  margin: -4px 0;
  padding: 0 5px;
}

.merge-part-popover .ant-popover-content {
  z-index: 1000;
}

.merge-part-popover .ant-popover-content .ant-popover-inner-content {
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid#ffadd2;
  color: #eb2f96;
  background: #fff0f6;
}

.merge-part-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
}

.merge-part-popover .ant-popover-inner-content:hover .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
  bottom: 6.2px;
}

.sierra-part-name {
  padding-left: 4px;
  word-break: break-word;
}

.sierra-component-split-row,
.sierra-component-split-row>td {
  background-color: #e6ffed !important;
}

.sierra-component-split-row:hover>td,
.sierra-component-split-row:hover .editable-cell-value-wrap {
  background-color: #e6ffed !important;
}

.sierra-component-split-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.sierra-component-select-row,
.sierra-component-select-row>td {
  background-color: #adddfd !important;
}

.sierra-component-select-row:hover>td,
.sierra-component-select-row:hover .editable-cell-value-wrap {
  background-color: #adddfd !important;
}

.sierra-component-select-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.sierra-component-merge-row,
.sierra-component-merge-row>td {
  background-color: #fffbdd !important;
}

.sierra-component-merge-row:hover>td,
.sierra-component-merge-row:hover .editable-cell-value-wrap {
  background-color: #fffbdd !important;
}

.sierra-component-merge-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.sierra-probe-pins-setting {
  padding: 4px 10px;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: inline-block;
  margin-left: 14px;
}

.sierra-probe-pins-setting>.sierra-probe-pins-setting-icon {
  margin-right: 8px;
  vertical-align: middle;
  padding-top: 0px;
  display: inline-block;
}

.sierra-probe-pins-setting>.sierra-probe-pins-setting-span {
  vertical-align: middle;
  height: 24px;
}

.sierra-probe-pins-setting:hover {
  background-color: #edebe9;
}

.sierra-probe-pins-setting-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.sierra_power-nets-loading-msg {
  margin: 10px 0px;
  padding: 6px;
  background: #eee;
  border-radius: 6px;
  line-height: 24px;
  position: relative;
}

.sierra_power-nets-loading-msg .sierra_power-nets-loading-icon {
  position: absolute;
  top: 11px;
  right: 4px;
  font-size: 13px;
  cursor: pointer;
}

.sierra_power-nets-loading-success-msg {
  color: #33c783;
  background: #ddf7ed !important;
}

.sierra_power-nets-loading-failed-msg {
  color: #cb2431;
  background: #ffdce0 !important;
}

.sierra_power-nets-update-button {
  margin-right: 10px;
  float: right;
  padding: 0px 6px;
  height: 26px;
  line-height: 24px;
  font-size: 13px;
}

.sierra_power-nets-ignore-button {
  float: right;
  padding: 0px 6px;
  height: 26px;
  line-height: 24px;
  font-size: 13px;
}


/* usage apply all */

.sierra-copy-usage {
  display: inline-block;
  margin-left: 20px;
  line-height: 28px;
  height: 28px;
}

.sierra-copy-usage>.sierra-copy-usage-switch {
  margin-left: 10px;
}

.aurora-content .sierra-setup-normal {
  top: 45px;
}

.sierra-touchstone-result-main,
.sierra-result-waveform {
  overflow: hidden;
}

.sierra-touchstone-result-main .result-right-menu .result-file-list {
  margin-right: 0px;
}

.sierra-result-waveform .sierra-waveform-result-list {
  margin-right: 0px;
}

.sierra-content-bottom {
  background: #ffffff;
}

.sierra-tb-setting-main {
  position: absolute;
  top: 45px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

/* Sierra connection setup */
.sierra-connector-content,
.sierra-connector-content .ant-select {
  color: rgb(0, 0, 0, 0.85) !important;
}

.sierra-connector-content .connection-pin-line,
.sierra-connector-content .connection-pin-long-line {
  height: 2px;
  background-color: #1a72b9;
  transform: scaleY(1);
}

.sierra-connector-content .connector-model-first-box .connection-component-select-content,
.sierra-connector-content .connector-model-pinName-box .connection-pin-l-box,
.sierra-connector-content .connector-model-pinName-box .connection-pin-r-box,
.sierra-connector-content .connector-model-first-box .aurora-select .ant-select-selector,
.sierra-connector-content .connection-model-assign-content .connection-cable-model-content .connection-component-select-content>.connection-component-select-title,
.sierra-connector-content .connection-model-assign-content .connection-cable-model-content .connection-component-select-content .connection-single-select,
.sierra-connector-content .connection-pin-connect-port-item {
  font-size: 14px;
}

.sierra-connector-content .connection-pin-net-r-name,
.sierra-connector-content .connection-pin-net-l-name,
.sierra-connector-content .connection-pin-list-connection-signal-title {
  color: rgb(0, 0, 0, 0.85);
}


.sierra-connector-content .connection-pin-l-value-box,
.sierra-connector-content .connection-pin-r-value-box,
.sierra-connector-content .connection-pin-cable-value-box {
  height: 48px;
}

.sierra-connector-content .connection-channel-tile-title {
  margin: 0px;
}

.sierra-connector-content .connection-left-channel,
.sierra-connector-content .connection-right-channel,
.sierra-connector-content .connection-cable-model-content,
.sierra-connector-content .connection-model-assign-content .connector-model-first-box {
  border-width: 2px;
}

.sierra-connector-content .connection-title-tile-pcb {
  font-weight: 600;
}

.sierra-connector-content .connection-pin-connect-port-item .connection-pin-net-box {
  padding: 0px 2px;
  max-width: 50%;
}

.sierra-connector-content .connection-model-assign-content .connector-model-first-box {
  border-color: #d4d4d4;
}

.sierra-connector-content .connector-model-first-box .connection-component-select-content {
  margin-top: 0px;
}