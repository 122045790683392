.sierra-result-parameter-file-list {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 318px;
}

.sierra-result-parameter-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.sierra-result-parameter-left {
  background-color: #fafafa;
  border-left-color: #e8e8e8;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.font-bold {
  font-weight: bold;
}

.sierra-touchstone-result-main,
.sierra-touchstone-result-main .ant-spin-nested-loading,
.sierra-touchstone-result-main .ant-spin-container {
  height: 100%;
}

.sierra-touchstone-result-main,
.sierra-waveform-result-main {
  height: 100%;
}

.sierra-waveform-result-main .waveform {
  position: relative;
}

.sierra-touchstone-result-main .sierra-result-setting {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 50%;
  bottom: 0;
}

.sierra-result-parameter-file-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.sierra-result-parameter-file-list .ant-divider-inner-text {
  padding-left: 2px !important;
  padding-right: 30px !important;
}

.sierra-result-parameter-file-list .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.sierra-touchstone-result-main .sparameter-channel-result-content .sparameter-file-expand-icon {
  vertical-align: 7px;
}

.sierra-touchstone-result-main .sparameter-channel-result-content .sparameter-span-file-name {
  display: inline-block;
}

.sierra-touchstone-result-main .sparameter-result-file-name {
  color: rgba(0, 0, 0, 0.65);
}

.sweep-waveform-pcb-legend .sweep-waveform-pcb-legend-content {
  position: absolute;
  display: flex;
  align-items: center;
}

.sweep-waveform-pcb-legend .sweep-waveform-pcb-legend-title {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sparameter-result-checkbox-content .rocky-sparameter-result-checkout-blank {
  margin-top: 0;
  margin-bottom: 0;
  min-width: 240px;
  width: unset;
  position: absolute;
  z-index: 999;
  background-color: #f9f9f9;
  height: 20px;
  left: 0;
}

.sparameter-channel-port-name .sparameter-sweep-channel-display-row {
  min-width: 220px;
  width: unset;
  position: absolute;
  z-index: 999;
  left: 0;
}

.waveFrom-post-process-content .post-process-panel-content {
  padding: 10px 10px 0;
}

.waveFrom-post-process-content .post-process-panel-content .ant-table-wrapper.post-process-panel-table .ant-table-middle>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th {
  text-align: left;
}

.waveFrom-post-process-panel .post-process-panel-content {
  padding: 10px 10px 0;
}

.waveFrom-post-process-panel .post-process-panel-content .ant-table-wrapper.post-process-panel-table .ant-table-middle>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th {
  text-align: left;
}

.post-process-panel-compare-table .ant-table-bordered .aurora-table-body>table {
  border-top: none;
  border-radius: 0;
  padding-right: 15px;
}

.post-process-panel-data-table .ant-spin-container .ant-table-title {
  padding-top: 0;
  padding-bottom: 0;
  height: 38px;
  border-radius: 0;
  padding: 0;
  padding-right: 15px;
}

.post-process-panel-data-table .ant-table-title .post-process-panel-data-table-title {
  display: flex;
  height: 100%;
  color: #000000d9;
  font-size: 14px;
}

.post-process-panel-data-table .ant-table-title .post-process-panel-data-table-title .table-title-box {
  padding: 8px;
}

.post-process-panel-data-table .ant-table-title .post-process-panel-data-table-title .table-title-rising-box {
  width: 50%;
  border-right: 1px solid #e8e8e8;
  padding: 8px;
}

.post-process-panel-compare-table .ant-table.ant-table-small.ant-table-bordered.ant-table-scroll-position-left {
  border-top: none;
  border-radius: 0;
}

.post-process-panel-data-table .ant-table.ant-table-small.ant-table-bordered.ant-table-scroll-position-left {
  border-radius: 0;
}

.post-process-panel-data-table tr.ant-table-row.editable-row.ant-table-row-level-0 {
  cursor: pointer;
}

.post-process-content-title {
  border: 1px solid #e8e8e8;
  padding: 8px;
  border-bottom: none;
  color: #000000d9;
}

.result-checkbox-group-name .checkout-group-table-data-content {
  display: inline-flex;
  margin-left: 5px;
}

.result-checkbox-group-name .checkout-group-table-data-content .checkout-group-table-data-content-div {
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-checkbox-group-name span.checkout-group-table-data-content-span {
  display: inline-block;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.sparameter-channel-port-name .sparameter-sweep-channel-post-display-row {
  min-width: 215px;
  width: unset;
  position: absolute;
  z-index: 999;
  background-color: #f9f9f9;
  /* left: 0; */
}

.post-process-title-comp {
  color: #1890ff;
}

.waveFrom-post-process-content {
  height: 100%;
}

.post-process-panel-compare-table .ant-table-small>.ant-table-content>.aurora-table-body {
  margin: 0;
}

.post-process-panel-edge-compare-table .ant-table-small>.ant-table-content>.aurora-table-body {
  background-color: #edf7ff;
}

.waveform.sierra-result-waveform .sierra-waveform-result-list .waveform-setting-box {
  /* bottom: 230px; */
  bottom: 6px;
}

.waveform.sierra-result-waveform .sierra-waveform-result-list .waveform-post-process-box {
  position: absolute;
  bottom: 210px;
  left: 0px;
  right: 0px;
}

.sierra-result-waveform .sierra-waveform-result-list.sierra-waveform-result-post-process-list {
  height: calc(100% - 210px);
}

.sierra-result-waveform .sierra-waveform-result-list.sierra-waveform-result-post-process-list.sierra-waveform-result-sweep-list {
  height: calc(100% - 200px);
}

/* .sierra-waveform-result-post-process-list .sparameter-channel-result-content  .sparameter-channel-port-name {
  position: relative;
} */

.waveform.sierra-result-waveform .sierra-waveform-result-list.sierra-waveform-result-sweep-list .waveform-setting-box {
  bottom: 0px;
}

.post-process-panel-data-selected-row,
.post-process-panel-data-table .ant-table-tbody>.post-process-panel-data-selected-row:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: #9bcfff;
}

.post-process-panel-data-row {
  cursor: pointer;
}

.post-process-content-title>div:first-child {
  display: inline;
}

.post-process-content-title-divide {
  margin: 0 5px;
}

.post-process-content-title-unit {
  margin-right: 5px;
}

.post-process-content-title .ant-select-single.ant-select-sm.ant-select-show-arrow {
  width: 60px;
}

.sierra-measure-edit-icon {
  cursor: pointer;
  padding-left: 10px;
}

.sierra-measure-panel {
  top: 130px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-measure-progress {
  padding: 0px 12px;
  width: calc(100% - 78px);
}

.sierra-result-content .sierra-touchstone-result-main .sierra-sparameter-result-content {
  min-height: fit-content;
}

.post-process-panel-data-table .input-select-after {
  min-width: 40px;
}

.impedance-unit-selection {
  margin-left: 8px;
}

.impedance-unit-selection {
  height: 26px;
}

.impedance-unit-selection .ant-select-selector {
  line-height: 24px;
}

.impedance-unit-selection .ant-select-arrow {
  right: 6px;
}

.impedance-unit-option {
  z-index: 2000;
}

.sierra-waveform-result-list .sparameter-result-checkbox-group,
.sierra-waveform-result-list .sparameter-result-checkbox-group-name {
  display: flex;
  align-items: center;
}

/* 
.sierra-waveform-result-list .sparameter-result-checkbox-group-name {
  width: calc(100% - 36px);
}
 */
.sierra-waveform-result-list .result-checkbox-group-name .sparameter-result-checkbox-group>span:nth-child(2) {
  width: calc(100% - 16px);
}

.sierra-waveform-result-list .sparameter-result-checkbox-group-name>label {
  max-width: fit-content;
  white-space: nowrap;
}

.sierra-waveform-result-list .result-curve-current {
  line-height: 24px;
  height: 22px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-left: 0px;
}

.sierra-waveform-result-list .checkbox-group-postProcess-icon {
  position: absolute;
  left: 264px;
  top: 5px;
  background-color: #fff;
  padding-right: 4px;
  vertical-align: middle
}

.sierra-waveform-result-list .sparameter-sweep-checkbox .result-curve-current,
.sierra-sweep-sparameter-result .sparameter-sweep-checkbox .result-curve-current {
  position: absolute;
  left: 40px;
}

.measurement-footer-apply-content {
  height: 28px;
  display: flex;
  flex-wrap: wrap;
}

.measurement-footer-item {
  width: 25%;
  display: flex;
  height: 28px;
  line-height: 28px;
}

.measurement-footer-item .aurora-input {
  width: calc(100% - 38px);
}

.measurement-footer-item>label {
  width: 38px;
  text-align: left;
}

.measurement-footer-item-button {
  width: 100%;
  height: 28px;
  line-height: 28px;
}

.measurement-footer-item-unit-select {
  width: 80px;
}

.measurement-footer-item-unit-select .ant-select-selector {
  font-size: 12px;
}

.measurement-footer-padding-left-item {
  padding-left: 14px;
}

.measurement-table-component-title {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.waveFrom-post-process-measure-content .input-with-select .input-select-after .ant-select-selector {
  margin-right: 18px;
  margin-left: 2px;
  line-height: 28px;
}

.waveFrom-post-process-measure-content .aurora-table .input-with-select .ant-select .ant-select-selector .ant-select-selection-item {
  padding-inline-end: 0px;
}

.waveFrom-post-process-measure-content .input-with-select .input-select-after .ant-select-selector .ant-select-selection-selected-value {
  font-size: 12px;
}

.waveFrom-post-process-measure-content .input-with-select .input-select-after .ant-select-arrow {
  right: 6px;
}

.waveFrom-post-process-measure-content {
  min-width: 1100px;
  padding: 10px;
}

.sierra-result-content-title {
  margin-bottom: 8px;
  color: #0b3b94;
}

.sierra-trace-capacitance-content .sierra-result-content-box .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td:nth-child(1) {
  max-width: 200px;
}

.sierra-result-content .result-waveform-setting .axis-title,
.sierra-result-content .result-waveform-setting .input-lable {
  color: rgba(0, 0, 0, 0.65);
}

.measurement-vdd-content .measurement-vdd-setting-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  color: #1890ff;
}

.post-process-panel-data-table .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th:nth-child(3) {
  position: relative;
}

.sierra-result-curve-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 20px;
  margin-left: 8px;
  right: 0px;
  width: 45px;
  height: 78px;
}

.sierra-result-curve-right .photo-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 6px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); */
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 26px;
  transition: all 0.3s;
}

.sierra-result-curve-right .photo-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.sierra-photo {
  width: 19px;
  margin-left: 1px;
}

.sierra-impedance-expand-row {
  background: #f2f7fa;
}

.measurement-footer-button-content {
  margin-top: 6px;
}